import React from 'react';
import './style.css';
import './navbar.css';
import Logo from "../../images/logo.png";
import Aboutus from "../../images/aboutus.png";
import Students from "../../images/students.png";

import Programming from "../../images/Gigeconome-13.png";
import Writing from "../../images/Gigeconome-14.png";
import Design from "../../images/Gigeconome-15.png";
import Adminstrative from "../../images/Gigeconome-16.png";
import Sales from "../../images/Gigeconome-17.png";
import Other from "../../images/Gigeconome-18.png";
import BannerImg from "../../images/New banner.png";
import Getmatched from "../../images/getmatched.png";
import image1 from "../../images/1.png";
import image2 from "../../images/2.png";
import image3 from "../../images/3.png";
import image4 from "../../images/4.png";
import image5 from "../../images/5.png";
import image7 from "../../images/7.png";
import image8 from "../../images/8.png";
import image9 from "../../images/9.png";
import image10 from "../../images/10.png";
import image11 from "../../images/11.png";
import Carieermobile from "../../images/carrertablet.png";
import Button1 from "../../images/button101.png";
import Button2 from "../../images/button111.png";
import Blog1 from "../../images/blog1.png";
import Blog2 from "../../images/blog2.png";
import Blog3 from "../../images/blog3.png";
import MobileBanner from "../../images/bannertabletnew.png";
import { Link } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha } from "@mui/material/styles";
import Header from "../Header/Header";
import Navone from './Navone';
import Footer from "../Footer/Footer";
import Nav from "./Nav";
import InputBase from "@mui/material/InputBase";
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { borderColor, fontFamily } from '@mui/system';
import Students1 from "../../images/student1.PNG";
import Empskills from "../../images/empskillpoint.png";
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import {useState,useEffect} from 'react'
import axios from 'axios'
import { DisplayCompany } from './DisplayCompany';
import StudentMscreen from './StudentMscreen';
import HomeNavMobile from './HomeNavMobile';
import Ring1 from "../../images/ring1.png";
import Ring2 from "../../images/ring2.png";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Accordion from './Accordion';
import Newhowitswork from './Newhowitswork';
import Gigvideo from './Gigvideo';
import NewUsp from './NewUsp';
import TalentImg from "../../images/talent.png";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "white",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "150%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));
function myFunction() {
  var x = document.getElementById("myTopna");
  if (x.className === "topnav") {
    x.className += " responsive";
  } else {
    x.className = "topnav";
  }
}
const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth' // for smoothly scrolling
  });
  sessionStorage.removeItem("SearchText");
  sessionStorage.setItem("SearchText","");
};
const handleStarted=()=>{
  window.location.href=`./signup`
}
const handleStudent=()=>{
  window.location.href=`./studentsignup_one`
}
const handleemp=()=>{
  window.location.href=`./emplyoabilityone`
  
}
const handleEmployee=()=>{
  window.location.href=`./companysignup`
}
const handleEducator=()=>{
window.open("https://calendly.com/gigeconome")
}
function Index() {
  
  
  //creating IP state
  const [ip, setIP] = useState('');

  //creating function to load ip address from the API
  const getData = async () => {
    const res = await axios.get('https://geolocation-db.com/json/')
    console.log(res.data.IPv4);
   // sessionStorage.setItem("IP Address",JSON.stringify(res.data.IPv4)) 
    setIP(res.data.IPv4)
  }
  
  useEffect( () => {
    //passing getData method to the lifecycle method
    getData()

  }, [])
  
  
  return (
    <div>
     
      {/* ======= Hero Section ======= */}
     
      <Header />
        
      <section >
   {/* <div className="secondanav"><Nav /></div> /*} 
      
        {/*}
        <nav
          className="navbar navbar-expand-sm navbar-light mt-3"
          data-toggle="affix"
          style={{
            paddingBottom: "0.2%",
            paddingTop: "0.3%",
            height: "50px",
            backgroundColor: "#565455",
          }}
        >
          <div className="mx-auto d-sm-flex d-block flex-sm-nowrap text-white ">
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarsExample11"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div
              className="collapse navbar-collapse text-center "
              id="navbarsExample11"
            >
              <ul className="navbar-nav">
                <li className="nav-item active">
                  <a
                    className="nav-link"
                    style={{
                      color: "#fff",
                      fontSize: "12px",
                      textDecoration: "None",
                    }}
                    href="#"
                  >
                    Programming &amp; Development &nbsp;&nbsp;&nbsp;
                    |&nbsp;&nbsp;{" "}
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    style={{ color: "#fff", fontSize: "12px" }}
                    href="#"
                  >
                    Writing &amp; Translation &nbsp;&nbsp;&nbsp; |&nbsp;&nbsp;
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    style={{ color: "#fff", fontSize: "12px" }}
                    href="#"
                  >
                    Design &amp; Art &nbsp;&nbsp;&nbsp; |{" "}
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    style={{ color: "#fff", fontSize: "12px" }}
                    href="#"
                  >
                    Administrative &amp; Secretary &nbsp;&nbsp;&nbsp;
                    |&nbsp;&nbsp;
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    style={{ color: "#fff", fontSize: "12px" }}
                    href="#"
                  >
                    Sales &amp; Marketing &nbsp;&nbsp;&nbsp; |&nbsp;&nbsp;
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    style={{ color: "#fff", fontSize: "12px" }}
                    href="#"
                  >
                    Other
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                  </a>
                </li>
                <li className="nav-item" id="search">
                  <div className="input-group">
                    <div className="form-outline">
                      <input
                        class="searchbar"
                        type="text"
                        placeholder="Search.."
                        name=""
                      />
                      <button class="serch-button" type="submit">
                        <i class="fa fa-search"></i>
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>
                  */}
      </section>
      {/* ======= Hero Section ======= */}
      {/* ======= Hero Section ======= */}
      <div >
        <img className="img-fluid bannerhomepage bigcompanysearch"  src={BannerImg} alt="..." />
        <img className="img-fluid bannerhomepage mscreenava"  src={MobileBanner} alt="..." />
        {/* <h1 class="centered" style="text-align: center;font-weight: 900;letter-spacing: 4px;width: 100%;" >STUDENTS: WORK WITH OUR STUDENT TALENTS</h1>
 */}
        <div className="container centered">
          
          <div className="row">
            <div className="col-xl-6 bannertxt">
            <div className='bigcompanysearch'>   <h5  style={{color: 'white', fontWeight: '400 !important',fontFamily:"GothamLight",fontWeight:"bold"}} className="bannertxtmscren"> Save Time.  
           <span style={{color:"white",fontFamily:"Gotham"}}> Recruit Smarter.</span>
            </h5></div>
           <div className='bigcompanysearch'>   <h1   className="bannertxtmscren wematchtxt"> We Match 
           <span style={{color:"white"}}> The Right</span>
            </h1></div>
            <div className='mscreenava'>   <h1  className="matchingtxt"> We Match  
           <span style={{color:"white"}}> The Right</span>
            </h1>
            <h1  className='shortandlongtermtxt' > Student To the Right  </h1>
              <h1  className="oppneww">Opportunities<span className="blackdot">•</span></h1>
            </div>
              <h1   className="bannertxtmscren bigcompanysearch studenttxt">Student To the Right</h1>
              <h1  className="bannertxtmscren newopptxt bigcompanysearch">Opportunities<span className="blackdot">•</span></h1>

              <h4 style={{color: 'white'}}  className="bannertxtmscren  bannrsubtextbscreen">
              Eliminate wasted resources by intelligently matching your opportunities with the right student from our talent pool.</h4>
              <a href="#about" className="btn-get-started scrollto"></a>              
              <div className="row bannerbtnm mscreenava">
                <table>
                
                  <tr>
                    <td> <button className="getmatchedstudent">Get Matched to Students</button></td>
                  <td> <button className="getjoinpoolbtn">Join Talent Pool</button></td>
                  </tr>
                </table>


              </div>
              <div style={{marginTop:"-2%"}} className="bigcompanysearch">
              <Link to="/postaproject">
                <button className="getmatchedstudent bigcompanysearch">Get Matched to Students</button> 
                 <img className="bannerimg" src={Button1} id="btnone" style={{cursor: 'pointer',display:"none"}} /></Link>
             <Link to="/signup">
             <button className="getjoinpoolbtn bigcompanysearch">Join Talent Pool</button>
              <img  className="bannerimg" src={Button2} id ="btntwo" style={{cursor: 'pointer',marginLeft:"3%",display:"none"}} /></Link>
              </div>
            
              
              <br /><br />
              
            </div>
          </div>
        </div>
      </div>
      {/* End Hero */}
           
      {/* ======= About Section ======= */}
      <section id="about" className="about section-bg">
      <div className="container-fluid" style={{padding: 'inherit', overflow: 'hidden',backgroundColor:"#00539C"}}>
        <div style={{border: '1px solid #fff', background: '#fff', borderRadius: '47px 0px 0px 0px', padding: 'inherit', marginLeft: '4%'}}>
          <div className="row">
            <div className="col-sm-6 mscreennewusp"><br /><br /><br />
            <h1 style={{ color: '#00539C', fontFamily: 'Gotham',textAlign:"left",marginLeft:"3%"}} className="mscreenava">For Talent.</h1>
            <h1 className="mscreenava" style={{color: 'black', fontFamily: 'Gotham',
      marginTop: '-1%',textAlign:"left",marginLeft:"3%"}}> We <span style={{color: '#00539C'}}>Promised</span></h1>
              <h4 className="bigcompanysearch" style={{marginLeft: '10%', color: '#00539C', fontFamily: 'Gotham'}}>For Talent.</h4>    
              <h4 className="bannertxtmscren bigcompanysearch" style={{color: 'black', fontFamily: 'Gotham', marginLeft: '10%', /* color: '#fff', */
     fontSize: '65px', marginTop: '-1%'}}> We <span style={{color: '#00539C'}}>Promised</span></h4>
              <h1 className="bannertxtmscren bigcompanysearch" style={{color: 'black', fontFamily: 'Gotham', marginLeft: '10%', /* color: '#fff', */
     fontSize: '65px', marginTop: '-2%'}}> four things </h1>
              <p className="usptalenttext">Our non-conventional selection process fosters diversity &amp; inclusion for all our students and ensures that all decisions are based purely on skills.Our non-conventional selection process fosters diversity &amp; inclusion for all our students and ensures that all decisions are based purely on skills. selection process fosters diversity &amp; based purely on skills.
              <br/>
              <button className='createprofilebtnn mt-3' onClick={()=>handleStudent()}>Create Profile →</button>
              </p>
             
            </div>
            <div className="col-sm-6">
              <br /><br />
              <img src={Ring1} style={{float: 'right'}} />
              <div className="parent" style={{background: '#ffffff'}}>
                <table className="table image2">
                  <thead>
                    <tr>
                      <th>
                        <div className='talent1th'> 
                          <img src={image1} />
                          <h6 style={{fontWeight: 'bold'}}>One time revisions.</h6><p style={{fontWeight: 'bold', fontSize: '12px', letterSpacing: '1px', lineHeight: '14px',fontFamily:"GothamLight"}}>Aperiam sapiente consectetur nemo. Voluptas consectetur tempora architecto adipisci. Dolorum beatae et earum enim ducimus aperiam. Est est nihil labore quia. Voluptatem.</p>
                        </div>
                      </th>
                      <th>
                        <div className='talent2th'> 
                          <img src={image2} />
                          <h6 style={{fontWeight: 'bold',fontSize:15}}>Good communication.</h6><p style={{fontWeight: 'bold', fontSize: '12px', letterSpacing: '1px', lineHeight: '14px',fontFamily:"GothamLight"}}>Aperiam sapiente consectetur nemo. Voluptas consectetur tempora architecto adipisci. Dolorum beatae et earum enim ducimus aperiam. Est est nihil labore quia. Voluptatem.</p>
                        </div>
                      </th>
                    </tr>
                    <tr>
                      <th>
                        <div className='talent3th'> 
                          <img src={image3} />
                          <h6 style={{fontWeight: 'bold'}}>Healty relation.</h6><p style={{fontWeight: 'bold', fontSize: '12px', letterSpacing: '1px', lineHeight: '14px',fontFamily:"GothamLight"}}>Aperiam sapiente consectetur nemo. Voluptas consectetur tempora architecto adipisci. Dolorum beatae et earum enim ducimus aperiam. Est est nihil labore quia. Voluptatem.</p>
                        </div>
                      </th>
                      <th>
                        <div className='talent4th'> 
                          <img src={image4} />
                          <h6 style={{fontWeight: 'bold'}}>Get, what you want.</h6><p style={{fontWeight: 'bold', fontSize: '12px', letterSpacing: '1px', lineHeight: '14px',fontFamily:"GothamLight"}}>Aperiam sapiente consectetur nemo. Voluptas consectetur tempora architecto adipisci. Dolorum beatae et earum enim ducimus aperiam. Est est nihil labore quia. Voluptatem.</p>
                        </div>
                      </th>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div style={{border: '1px solid #fff', background: '#fff', padding: 'inherit', marginLeft: '4%'}}>
          <div className="row">
            <div className="col-sm-5 bigcompanysearch" style={{height: '600px'}}>
              <img src={Ring2} className="ring2img" />
              {/*  <div style="    width: 35%;" class="parent3" > <span class="image2"><h6  style="font-weight: bold;">One time revisions.</h6><p>Our non-conventional selection process fosters diversity & inclusion for all our students and ensures that all decisions are based purely on skills.</p></span>   </div> */}
              <div className="parent1" style={{background: '#ffffff'}}>
              
                <table className="table image3 ">
                  <thead>
                    <tr>
                      <th>
                        <div style={{marginBottom: '10%', lineHeight: '16px', border: '1px solid #d4e5e1', padding: '4% 4% 4% 4%', width: '76%', borderRadius: '14px', background: '#fff', float: 'right', marginTop: '16%'}}> 
                          <img src={image5} />
                          <h6 style={{fontWeight: 'bold'}}>Debiased Selection Process</h6><p style={{fontWeight: 'bold', fontSize: '12px', letterSpacing: '1px', lineHeight: '14px',fontFamily:"GothamLight"}}>Our non-conventional selection process fosters diversity &amp; inclusion for all our students and ensures that all decisions are based purely on skills.</p>
                        </div>
                      </th>
                      <th>
                        <div style={{padding: '4% 4% 4% 4%', width: '77%', borderRadius: '12px', boxShadow: '8px 1px 23px 12px #f1f1f1', marginBottom: '12%', background: '#ffffff', marginLeft: '8%', marginTop: '-8%'}}> 
                          <img src={image2} />
                          <h6 style={{fontWeight: 'bold'}}>Next Generation Talent Pool</h6><p style={{fontWeight: 'bold', fontSize: '12px', letterSpacing: '1px', lineHeight: '14px',fontFamily:"GothamLight"}}>Our non-conventional selection process fosters diversity &amp; inclusion for all our students and ensures that all decisions are based purely on skills.</p>
                        </div>
                      </th>
                    </tr>
                    <tr>
                      <th>
                        <div style={{marginBottom: '10%', lineHeight: '16px', border: '1px solid #d4e5e1', padding: '4% 4% 4% 4%', width: '76%', borderRadius: '14px', background: '#fff', float: 'right'}}> 
                          <img src={image3}/>
                          <h6 style={{fontWeight: 'bold'}}>Smart Matching Technology</h6><p style={{fontWeight: 'bold', fontSize: '12px', letterSpacing: '1px', lineHeight: '14px',fontFamily:"GothamLight"}}>Our non-conventional selection process fosters diversity &amp; inclusion for all our students and ensures that all decisions are based purely on skills.</p>
                        </div>
                      </th>
                      <th>
                        <div style={{marginBottom: '10%', lineHeight: '16px', border: '1px solid #d4e5e1', padding: '4% 4% 4% 4%', width: '77%', borderRadius: '14px', background: '#fff', marginLeft: '8%'}}> 
                          <img src={image7} />
                          <h6 style={{fontWeight: 'bold'}}>Fully Varified Talents</h6><p style={{fontWeight: 'bold', fontSize: '12px', letterSpacing: '1px', lineHeight: '14px', marginBottom: '16%',fontFamily:"GothamLight"}}>Our non-conventional selection process fosters diversity &amp; inclusion for all our students and ensures that all decisions are based purely on skills.</p>
                        </div>
                      </th>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
            <div className="col-sm-7 uspemployee" style={{textAlign: 'right'}}><br /><br /><br />
            <h1 className='mscreenava' style={{ color: '#00539C', fontFamily: 'Gotham',marginRight:"1%",marginTop:"3%"}}>For Employers.</h1> 
            <h1 className="mscreenava" style={{color: 'black', fontFamily: 'Gotham',marginRight: '10%', /* color: '#fff', */
     fontSize: '40px', marginTop: '-1%',marginRight:"1%"}}> You'll get </h1>
            <h1 className="mscreenava" style={{color: '#00539C', fontFamily: 'Gotham', marginRight: '10%',  fontSize: '35px', marginTop: '-1%',marginRight:"1%"}}>  Energized skilled</h1>
            <h1 className="mscreenava" style={{color: 'black', fontFamily: 'Gotham',marginRight: '10%', /* color: '#fff', */
     fontSize: '40px', marginTop: '-1%',marginRight:"1%"}}> on demand. </h1>
              <h4 className='bigcompanysearch foremptxt' >For Employers.</h4>    
              <h1 className="bannertxtmscren bigcompanysearch" style={{color: 'black', fontFamily: 'Gotham',marginRight: '10%', /* color: '#fff', */
     fontSize: '65px', marginTop: '-1%'}}> You'll get </h1>
              <h4 className="bannertxtmscren bigcompanysearch" style={{color: '#00539C', fontFamily: 'Gotham', marginRight: '10%',  fontSize: '65px', marginTop: '-1%'}}>  Energized skilled</h4>
              <h1 className="bannertxtmscren bigcompanysearch" style={{color: 'black', fontFamily: 'Gotham',marginRight: '10%', /* color: '#fff', */
     fontSize: '65px', marginTop: '-1%'}}> on demand. </h1>
              <p   className='empusptxt'>Our non-conventional selection process fosters diversity &amp; inclusion for all our students and ensures that all decisions are based purely on skills.Our non-conventional selection process fosters diversity &amp; inclusion for all our students and ensures that all decisions are based purely on skills. selection process fosters diversity &amp; based purely on skills.
              <br/>
              <button className='createprofilebtnn mt-3' onClick={()=>handleEmployee()}>Create Profile →</button>
              </p>
            </div>
            <div className='col-sm-5 mscreenava'>
            <table className="table image3 " style={{marginTop:"90em",backgroundColor:"white"}}>
                  <thead>
                    <tr>
                      <th>
                        <div className='empuspbox1'> 
                          <img src="https://mainteccloud.com/gighome/images/1.png" />
                          <h6 style={{fontWeight: 'bold'}}>Debiased Selection Process</h6><p style={{fontWeight: 'bold', fontSize: '12px', letterSpacing: '1px', lineHeight: '14px',fontFamily:"GothamLight"}}>Our non-conventional selection process fosters diversity &amp; inclusion for all our students and ensures that all decisions are based purely on skills.</p>
                        </div>
                      </th>
                      <th>
                        <div className='empuspbox2'> 
                          <img src="https://mainteccloud.com/gighome/images/2.png" />
                          <h6 style={{fontWeight: 'bold'}}>Next Generation Talent Pool</h6><p style={{fontWeight: 'bold', fontSize: '12px', letterSpacing: '1px', lineHeight: '14px',fontFamily:"GothamLight"}}>Our non-conventional selection process fosters diversity &amp; inclusion for all our students and ensures that all decisions are based purely on skills.</p>
                        </div>
                      </th>
                    </tr>
                    <tr>
                      <th>
                        <div className='empuspbox3'> 
                          <img src="https://mainteccloud.com/gighome/images/3.png" />
                          <h6 style={{fontWeight: 'bold'}}>Smart Matching Technology</h6><p style={{fontWeight: 'bold', fontSize: '12px', letterSpacing: '1px', lineHeight: '14px',fontFamily:"GothamLight"}}>Our non-conventional selection process fosters diversity &amp; inclusion for all our students and ensures that all decisions are based purely on skills.</p>
                        </div>
                      </th>
                      <th>
                        <div className='empuspbox4'> 
                          <img src="https://mainteccloud.com/gighome/images/5.png" />
                          <h6 style={{fontWeight: 'bold'}}>Fully Varified Talents</h6><p style={{fontWeight: 'bold', fontSize: '12px', letterSpacing: '1px', lineHeight: '14px', marginBottom: '16%',fontFamily:"GothamLight"}}>Our non-conventional selection process fosters diversity &amp; inclusion for all our students and ensures that all decisions are based purely on skills.</p>
                        </div>
                      </th>
                    </tr>
                  </thead>
                </table>

            </div>
          </div>
        </div>
        <div className='whitebackground'>
          <div className="row">
            <div className="col-sm-6 bigcompanysearch">
           
              <h4 style={{marginLeft: '10%', color: '#00539C', fontFamily: 'Gotham'}}>For Educators.</h4>    
              <h1 className="bannertxtmscren" style={{color: 'black', fontFamily: 'Gotham', marginLeft: '10%', /* color: '#fff', */
    fontSize: '65px', marginTop: '-1%'}}> Let's Join </h1>
              <h4 className="bannertxtmscren" style={{color: 'black', fontFamily: 'Gotham', marginLeft: '10%', /* color: '#fff', */
    fontSize: '65px', marginTop: '-1%'}}> our <span style={{color: '#00539C'}}>ARMY!</span></h4>
              <p style={{marginLeft: '10%', width: '58%', borderLeft: '3px solid #00539C', paddingLeft: '2%',color:'black',fontFamily:"GothamLight",fontWeight:"bold",height:"6em",lineHeight:"18px",}}>Our non-conventional selection process fosters diversity &amp; inclusion for all our students and ensures that all decisions are based purely on skills.Our non-conventional selection process fosters diversity &amp; inclusion for all our students and ensures that all decisions are based purely on skills. selection process fosters diversity &amp; based purely on skills.
              <br/>
              <button className='createprofilebtnn mt-3' onClick={()=>handleEducator()}>Request Demo →</button>
              </p>
            </div>
            <div className="col-sm-6 mscreenava">
           
           <h1 style={{marginLeft: '3%', color: '#00539C', fontFamily: 'Gotham',textAlign:"left"}}>For Educators.</h1>    
           <h1  style={{color: 'black', fontFamily: 'Gotham', marginLeft: '3%', /* color: '#fff', */
 fontSize: '40px', marginTop: '-3%',textAlign:"left"}}> Let's Join </h1>
           <h4  style={{color: 'black', fontFamily: 'Gotham', marginLeft: '3%', /* color: '#fff', */
 fontSize: '40px', marginTop: '-3%',textAlign:"left"}}> our <span style={{color: '#00539C'}}>ARMY!</span></h4>
           <p className='educatorusptext'>Our non-conventional selection process fosters diversity &amp; inclusion for all our students and ensures that all decisions are based purely on skills.Our non-conventional selection process fosters diversity &amp; inclusion for all our students and ensures that all decisions are based purely on skills. selection process fosters diversity &amp; based purely on skills.
           <br/>
           <button className='createprofilebtnn mt-3' onClick={()=>handleEducator()}>Request Demo →</button>
           </p>
         </div>
            <div className="col-sm-6">
              <img src={Ring1} style={{float: 'right', marginTop: '-4%'}} />
              <div className="parent" style={{background: '#ffffff'}}>
                <table className="table image2">
                  <thead>
                    <tr>
                      <th>
                        <div className='educatorth1'> 
                          <img src={image8} />
                          <h6 style={{fontWeight: 'bold'}}>Clearing pathway to first jobs</h6><p style={{fontWeight: 'bold', fontSize: '12px', letterSpacing: '1px', lineHeight: '14px',fontFamily:"GothamLight"}}>Our non-conventional selection process fosters diversity &amp; inclusion for all our students and ensures that all decisions are based purely on skills.</p>
                        </div>
                      </th>
                      <th>
                        <div className='educatorth2'> 
                          <img src={image9} />
                          <h6 style={{fontWeight: 'bold'}}>One-Stop Platform</h6><p style={{fontWeight: 'bold', fontSize: '12px', letterSpacing: '1px', lineHeight: '14px',fontFamily:"GothamLight"}}>Our non-conventional selection process fosters diversity &amp; inclusion for all our students and ensures that all decisions are based purely on skills.</p>
                        </div>
                      </th>
                    </tr>
                    <tr>
                      <th>
                        <div className='educatorth3'> 
                          <img src={image10} />
                          <h6 style={{fontWeight: 'bold'}}>Real time connection.</h6><p style={{fontWeight: 'bold', fontSize: '12px', letterSpacing: '1px', lineHeight: '14px',fontFamily:"GothamLight"}}>Our non-conventional selection process fosters diversity &amp; inclusion for all our students and ensures that all decisions are based purely on skills.</p>
                        </div>
                      </th>
                      <th>
                        <div className='educatorth4'> 
                          <img src={image11} />
                          <h6 style={{fontWeight: 'bold'}}>Accessible</h6><p style={{fontWeight: 'bold', fontSize: '12px', letterSpacing: '1px', lineHeight: '14px',fontFamily:"GothamLight"}}>Our non-conventional selection process fosters diversity &amp; inclusion for all our students and ensures that all decisions are based purely on skills.</p>
                        </div>
                      </th>
                    </tr>
                  </thead>
                </table>
                
              </div>
            </div>
          </div>
         
          <div style={{height: '100px'}}> </div>
          <div className="row empusptablet">
           
           
            <div className="col-sm-6">
              <img src="https://mainteccloud.com/gighome/ring1.png" style={{float: 'right', marginTop: '-4%'}} />
              <div className="parent" style={{background: '#ffffff'}}>
                <table className="table image2">
                  <thead>
                    <tr>
                      <th>
                        <div className='educatorth1'> 
                          <img src={image8} />
                          <h6 style={{fontWeight: 'bold'}}>Clearing pathway to first jobs</h6><p style={{fontWeight: 'bold', fontSize: '12px', letterSpacing: '1px', lineHeight: '14px',fontFamily:"GothamLight"}}>Our non-conventional selection process fosters diversity &amp; inclusion for all our students and ensures that all decisions are based purely on skills.</p>
                        </div>
                      </th>
                      <th>
                        <div className='educatorth2'> 
                          <img src={image9} />
                          <h6 style={{fontWeight: 'bold'}}>One-Stop Platform</h6><p style={{fontWeight: 'bold', fontSize: '12px', letterSpacing: '1px', lineHeight: '14px',fontFamily:"GothamLight"}}>Our non-conventional selection process fosters diversity &amp; inclusion for all our students and ensures that all decisions are based purely on skills.</p>
                        </div>
                      </th>
                    </tr>
                    <tr>
                      <th>
                        <div className='educatorth3'> 
                          <img src={image10} />
                          <h6 style={{fontWeight: 'bold'}}>Real time connection.</h6><p style={{fontWeight: 'bold', fontSize: '12px', letterSpacing: '1px', lineHeight: '14px',fontFamily:"GothamLight"}}>Our non-conventional selection process fosters diversity &amp; inclusion for all our students and ensures that all decisions are based purely on skills.</p>
                        </div>
                      </th>
                      <th>
                        <div className='educatorth4'> 
                          <img src={image11} />
                          <h6 style={{fontWeight: 'bold'}}>Accessible</h6><p style={{fontWeight: 'bold', fontSize: '12px', letterSpacing: '1px', lineHeight: '14px',fontFamily:"GothamLight"}}>Our non-conventional selection process fosters diversity &amp; inclusion for all our students and ensures that all decisions are based purely on skills.</p>
                        </div>
                      </th>
                    </tr>
                  </thead>
                </table>
                
              </div>
            </div>
            <div className="col-sm-6" style={{marginTop:"17%"}}>
           
           <h1 style={{marginLeft: '3%', color: '#00539C', fontFamily: 'Gotham',textAlign:"left"}}>For Employers</h1>    
           <h1  style={{color: 'black', fontFamily: 'Gotham', marginLeft: '3%', /* color: '#fff', */
 fontSize: '40px', marginTop: '-3%',textAlign:"left"}}> You'll get </h1>
           <h4  style={{color: 'black', fontFamily: 'Gotham', marginLeft: '3%', /* color: '#fff', */
 fontSize: '40px', marginTop: '-3%',textAlign:"left",color: '#00539C'}}> Energized skilled </h4>
           <h4  style={{color: 'black', fontFamily: 'Gotham', marginLeft: '3%', /* color: '#fff', */
 fontSize: '40px', marginTop: '-3%',textAlign:"left"}}> on demand. </h4>
           <p className='educatorusptext'>Our non-conventional selection process fosters diversity &amp; inclusion for all our students and ensures that all decisions are based purely on skills.Our non-conventional selection process fosters diversity &amp; inclusion for all our students and ensures that all decisions are based purely on skills. selection process fosters diversity &amp; based purely on skills.
           <br/>
           <button className='createprofilebtnn mt-3' onClick={()=>handleEmployee()}>Create Profile →</button>
           </p>
         </div>
          </div>
          </div></div>
          <div style={{height: '100px'}}> </div>
         {/** New USP section starts */}
        <div style={{marginTop:"2%",marginBottom:"4%"}}>
        <NewUsp/>
        </div>
         
      {/**New USP section ends */}
      
       {/* <div className="container" data-aos="fade-up">
         
          <div className="row">
            <div className="col-lg-4">
              <span className="about-head">GigeconoMe - </span>
            </div>
            <div className="col-lg-8">
              <span className="about-content">
                Clearing Pathways to good first jobs by connecting school,
                students and employers.
              </span>
            </div>
          </div>
          <div className="row no-gutters">
            <div className="content col-xl-6 d-flex align-items-stretch">
              <div className="content">
                <img className="homegiglogo" src={Aboutus} />
              </div>
            </div>
            <div className="testinomialscreen">
            <Carousel
        showArrows={true}
        infiniteLoop={true}
        showThumbs={false}
        showStatus={false}
        autoPlay={true}
        interval={6100}
      >
        <div>
          
          <div className="myCarousel">
          <h4 style={{color:"rgb(0,147,69)",fontSize:16,fontFamily:"Gotham"}}>Debiased Selection Process</h4>
            <p style={{color:"black"}}>Our non-conventional selection process fosters diversity &amp; inclusion for all our students and ensures that all decisions are based purely on skills.</p>
          </div>
        </div>

        <div>
         
          <div className="myCarousel">
          <h4 style={{color:"rgb(0,147,69)",fontSize:16,fontFamily:"Gotham"}}>Next Generation Talent pool</h4>
            <p style={{color:"black"}}>Pool of diverse talent ready to complete one-off tasks, internships, gigs, short &amp; long-term projects, part time or long-term opportunities or address skills shortages.</p>
          </div>
        </div>

        <div>
          
          <div className="myCarousel">
          <h4 style={{color:"rgb(0,147,69)",fontSize:16,fontFamily:"Gotham"}}>Smart Matching Technology</h4>
            <p style={{color:"black"}}>Intelligent matching and searching functionality to unlock talents &amp; ensure that you match, screen and contact right students in minutes to build lasting relationships.</p>
          </div>
          </div>
         <div>
         <div className="myCarousel">
          <h4 style={{color:"rgb(0,147,69)",fontSize:16,fontFamily:"Gotham"}}>Fully Verified Talents</h4>
            <p style={{color:"black"}}>Thorough vetting process ensures all students have been fully screened to maintain integrity and signed code of conduct ensures that all our students can be fully trusted.</p>
          </div>
         </div>
          <div>
          <div className="myCarousel">
          <h4 id='clearingthepath'>Clearing pathways to good first jobs </h4>
            <p style={{color:"black"}}>Aligning study &amp; work by providing students ability to gain real life work experience, build portfolios and develop employability skills for linear paths to good first jobs.</p>
          </div>
          </div>
          <div>
          <div className="myCarousel">
          <h4 style={{color:"rgb(0,147,69)",fontSize:16,fontFamily:"Gotham"}}>One-Stop Platform</h4>
            <p style={{color:"black"}}>Easy to use & secure suite of cutting-edge tools in one platform to manage each step of the journey from reviewing profiles, shortlisting, contracts, payments & review.</p>
          </div>
        </div>
      </Carousel>
      <div className="cnterbtn" style={{marginTop:"5%",marginBottom:"5%"}} >
              
              <a href="https://calendly.com/gigeconome"
              target="_blank"
              className="btn btn-success"
             
              style={{
                background: "#009345",
                border: "none",
                borderRadius: "20px",
               padding:"10px 25px",
                letterSpacing: "1px",
                color:"white",
                
              }}>
      Schedule a Demo
              </a>
            
           
          </div>
            </div>
            <div className="col-xl-6 d-flex align-items-stretch uspcontentmscreen">
            <div className="icon-boxes d-flex flex-column justify-content-center">
        <div className="row">
          <div className="col-md-6 icon-box" data-aos data-aos-delay={100}>
            <h4 style={{color:"rgb(0,147,69)",fontSize:16,fontFamily:"Gotham"}}>Debiased Selection Process</h4>
            <p style={{color:"black"}}>Our non-conventional selection process fosters diversity &amp; inclusion for all our students and ensures that all decisions are based purely on skills.</p>
          </div>
          <div className="col-md-6 icon-box" data-aos data-aos-delay={200}>
            <h4 style={{color:"rgb(0,147,69)",fontSize:16,fontFamily:"Gotham"}}>Next Generation Talent pool</h4>
            <p style={{color:"black"}}>Pool of diverse talent ready to complete one-off tasks, internships, gigs, short &amp; long-term projects, part time or long-term opportunities or address skills shortages.</p>
          </div>
          <div className="col-md-6 icon-box" data-aos data-aos-delay={300}>
            <h4 style={{color:"rgb(0,147,69)",fontSize:16,fontFamily:"Gotham"}}>Smart Matching Technology</h4>
            <p style={{color:"black"}}>Intelligent matching and searching functionality to unlock talents &amp; ensure that you match, screen and contact right students in minutes to build lasting relationships.</p>
          </div>
          <div className="col-md-6 icon-box" data-aos data-aos-delay={400}>
            <h4 style={{color:"rgb(0,147,69)",fontSize:16,fontFamily:"Gotham"}}>Fully Verified Talents</h4>
            <p style={{color:"black"}}>Thorough vetting process ensures all students have been fully screened to maintain integrity and signed code of conduct ensures that all our students can be fully trusted.</p>
          </div>
          <div className="col-md-6 icon-box" data-aos data-aos-delay={300}>
            <h4 id='clearingthepath'>Clearing pathways to good first jobs </h4>
            <p style={{color:"black"}}>Aligning study &amp; work by providing students ability to gain real life work experience, build portfolios and develop employability skills for linear paths to good first jobs.</p>
          </div>
          <div className="col-md-6 icon-box" data-aos data-aos-delay={400}>
            <h4 style={{color:"rgb(0,147,69)",fontSize:16,fontFamily:"Gotham"}}>One-Stop Platform</h4>
            <p style={{color:"black"}}>Easy to use & secure suite of cutting-edge tools in one platform to manage each step of the journey from reviewing profiles, shortlisting, contracts, payments & review.</p>
          </div>
        </div>
        <div className="cnterbtn scheduleademo">
              
              <a href="https://calendly.com/gigeconome"
              target="_blank"
              className="btn btn-success"
             
              style={{
                background: "#009345",
                border: "none",
                borderRadius: "20px",
               padding:"5px 25px",
                letterSpacing: "1px",
                color:"white",
                
              }}>
      Schedule a Demo
              </a>
            
           
          </div>
      </div>
            
            </div>
          </div>
        </div>
      */}
      </section>

      <section id="about" className="about section-bg">
     {/* New How its work part starts*/ }
    <div className="bigcompanysearch">
              <Newhowitswork/>
              </div>
              
     {/* New How its work part ends*/ }
   
      </section>
        
      {/* End About Section */}
      {/* Mobile Section How it works section starts */}
       <div className="howitworksmobile">
       <StudentMscreen/>
       </div>
       {/* Mobile Section How it works ends */}

     
      <hr className="about-bb" style={{borderBottom: '1px solid #fff!important',display:"none"}} />
      {JSON.parse(sessionStorage.getItem("UserType"))=="Student"?<>
      <h3 className id="ihead/* boing" style={{textAlign: 'center', fontSize: '18px', color: 'rgb(255, 213, 99)', fontWeight: 700, fontFamily: '"Gotham"', clear: 'both',marginTop:"2%"}}><span style={{border: '1px solid #00539C', padding: '10px 45px', background: '#00539C', borderRadius: '20px', fontSize: '14px', letterSpacing: '2px'}}>
        <Link  style={{color:"white"}} onClick={()=>scrollToTop()} to="/signup">Get Started</Link>
        </span></h3>
      </>:<>
      {JSON.parse(sessionStorage.getItem("UserType"))=="Company"||JSON.parse(sessionStorage.getItem("UserType"))=="Employee"?<>
      
      
      </>:<> <h3 className id="iheadboing" style={{textAlign: 'center', fontSize: '18px', color: 'rgb(255, 213, 99)', fontWeight: 700, fontFamily: '"Gotham"', clear: 'both',marginTop:"2%"}}><span style={{border: '1px solid #00539C', padding: '10px 45px', background: '#00539C', borderRadius: '20px', fontSize: '14px', letterSpacing: '2px'}}>
      <Link style={{color:"rgb(255, 213, 99)"}}  onClick={()=>scrollToTop()} to="/signup">Get Started</Link>
        </span></h3></>}
     
      </>}
     
 {/** Carrier Readiniess part starts */}
      <br/><br/><br/>   
      <div className="container" style={{marginTop:"4%"}}>
                <div className="row">
                <div className="col-xl-6 d-flex align-items-stretch mscreenava">
                    <img src={TalentImg} style={{width:"80%",margin:"auto",marginTop:"-4%"}} />
                    <button onClick={()=>handleStarted()}  class="getstartedbtnnew bigcompanysearch">Get Started</button>&nbsp;&nbsp;
                   <button onClick={()=>handleemp()} class="gradbtn bigcompanysearch" >Take Our Career Readiness Test</button>
                    </div>
                    <div className="col-xl-5" style={{color:"black",marginTop:"-5%"}}>
                    <h2 className="bigcompanysearch">The one-stop early talent <span style={{color:"#00539c",fontWeight:"bold"}}>matching</span>
                   &nbsp;platform
                   </h2>
                   <h4 className="mscreenava text-center" style={{padding:"2%",marginTop:"10%"}}>The one-stop early talent <span style={{color:"#00539c",fontWeight:"bold"}}>matching</span>
                   &nbsp;platform
                   </h4>
                   <br/>
                  <table>
                    <tr>
                      <td>
                        <span style={{fontFamily:"GothamLight",fontWeight:"bold"}}>Traditional days of post and pray, hoping the right candidates see them over.</span>
                      </td> <br/> 
                      
                    </tr><br/> 
                    <tr>
                    <td>
                        <span style={{fontFamily:"GothamLight",fontWeight:"bold"}}>Automatically match to those young talents searching through many job boards to find the right opportunities and</span> </td>
                      </tr><br/> 
                      <tr>
                        <td>
                        <span style={{fontFamily:"GothamLight",fontWeight:"bold"}}>Everything you need to unlock the right new talents, connect to a global diverse pool who are develop real life skills and gain on the job experiences.</span>
                        </td>
                      </tr>
                  </table>
                  <button className='createprofilebtnn mt-5 bigcompanysearch' onClick={()=>handleEducator()}>Request a Demo</button>
                   <div className='mscreenava text-center'>
                   <button className='createprofilebtnn mt-3' onClick={()=>handleEducator()}>Request a Demo</button>
                   </div>
                 
                   
                    </div>
                    <div className="col-xl-6 d-flex align-items-stretch bigcompanysearch" style={{marginLeft:"5%"}}>
                    <img src={TalentImg} className="newuspimg" />
                    </div>
                    
                </div>
           
            </div>
       {/** <div className='text-center' style={{marginTop:"3%"}}>
        <button onClick={()=>handleStarted()}  class="getstartedbtnnew bigcompanysearch">Get Started</button> &nbsp;&nbsp;
                   <button onClick={()=>handleemp()} class="gradbtn bigcompanysearch" >Take Our Career Readiness Test</button>
        </div> */}
     
     {/** Carrier Readiniess part ends */}
     
         <br/><br/>
             <hr className="about-bb " style={{borderBottom: '1px solid #fff!important',visibility:"hidden"}} />
    
     {/* Gig Youtube Video Integration starts */}
         <Gigvideo/>
     {/* Gig Youtube Video Integration ends */}
     
     <hr className="about-bb" style={{borderBottom: '1px solid #fff!important',marginTop:"2%",visibility:"hidden"}} />
             <DisplayCompany/>
      

             <div class="homepage-section__header homepage-section__header--no-margin container"><div class="employers__header" style={{marginTop:"-5%"}} >
    <h2  style={{fontFamily:"Gotham",letterSpacing:"1px",marginBottom:"2%"}} id="iheading">Latest 
    <span style={{fontWeight:"bold",color:"#00539C",marginLeft:"1%"}}>Resources</span> and <span style={{fontWeight:"bold",color:"#00539C"}}>insights</span> <br/>
    Discover tools and <span style={{fontWeight:"bold",color:"#00539C"}}>contents</span>  to Succeed
    
     </h2></div></div>
  
    <div className='container mt-5'>
            <div className='row'>
              <div className='col-sm-4'>
              <div class="card blogcard">
  <img src={Blog1} class="card-img-top" alt="..." />
  <div class="card-body">
  <button class="card-title blogbtn">Blog</button>
    <h6 style={{marginTop:"7%"}}>
      33+ examples of US Universities using community marketing for student recruitment.
    </h6>
    <br/>
    <p>
      <Link to="/blogreadmore" onClick={()=>scrollToTop()}>
      <h5 style={{color:"#00539C"}}>Read More&nbsp;<i class="fa fa-arrow-circle-right" aria-hidden="true" style={{textAlign:"bottom"}}></i></h5>
      </Link>
      </p>
  </div>
</div>
              </div>
              <div className='col-sm-4'>
              <div class="card blogcard">
  <img src={Blog2} class="card-img-top" alt="..." />
  <div class="card-body">
  <button class="card-title blogbtn">Event</button>
   <h6 style={{marginTop:"7%"}}>Unibuddy Conference - The Unmissable online Higher Ed event of 2021.</h6>
   <br/>
    <p>
      <Link to="/eventpage" onClick={()=>scrollToTop()}>
      <h5 style={{color:"#00539C"}}>Read More&nbsp;<i class="fa fa-arrow-circle-right" aria-hidden="true" style={{textAlign:"bottom"}}></i></h5>
      </Link>
     </p>
  </div>
</div>
              </div>
              <div className='col-sm-4'>
              <div class="card blogcard" >
  <img src={Blog3} class="card-img-top" alt="..." />
  <div class="card-body">
    <button class="card-title blogbtntwo">Free Template</button>
   <h6 style={{marginTop:"7%"}}>4 tips for writing digital ambassador job descriptions that attract top talent.</h6>
   <br/>
    <p><h5 style={{color:"#00539C"}}>Download&nbsp;<i class="fa fa-arrow-circle-right" aria-hidden="true" style={{textAlign:"bottom"}}></i></h5></p>
  </div>
</div>
              </div>
            </div>
    </div>
    <h3 className id="iheadboing" style={{textAlign: 'center', fontSize: '18px', color: '#fff', fontWeight: 700, fontFamily: '"Gotham"', clear: 'both',marginTop:"1%",marginBottom:"3%"}}><span style={{border: '1px solid green', padding: '10px 45px', background: '#00539C', borderRadius: '20px', fontSize: '14px', letterSpacing: '2px'}}>
      <Link style={{color:"rgb(255, 213, 99)"}}  onClick={()=>scrollToTop()} to="/resourcepage">More Resources</Link>
        </span></h3>
      <section id="pricing" className="pricing section-bg" style={{display:"none"}} >
       
        <div className="container" data-aos  >
          <div className="section-title">
          <h2
            style={{
              fontSize: "54px",
              letterSpacing: "4px",
              textAlign: "center",
              color: "#000",
              textTransform:'none',
             fontFamily:'GothamLight'
            }}
          >
            
            Job Categories{" "}
          </h2>
          </div>
        
          <div className="row"  >
            <div className="col-lg-4 col-md-6 job-margin" >
          
              <div className="box box1" data-aos data-aos-delay={100} style={{backgroundColor: '#c0e0cf'}}>
                <img src={Programming} height="260px" width="250px" />
                <p className="cat-head"><b>Programming &amp; Development</b></p>
                <a href="#" className="categories-view">View</a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 job-margin">
              <div className="box box1" data-aos data-aos-delay={100} style={{backgroundColor: '#c0e0cf'}}>
                <img src={Writing} height="260px" width="250px" />
                <p className="cat-head"><b>Engineering &amp; Architecture</b></p>
                <a href="#" className="categories-view">View</a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 job-margin">
              <div className="box box1" data-aos data-aos-delay={100} style={{backgroundColor: '#c0e0cf'}}>
                <img src={Design} height="260px" width="250px" />
                <p className="cat-head"><b>Design and Creatives</b></p>
                <a href="#" className="categories-view">View</a>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 job-margin">
              <div className="box box1" data-aos data-aos-delay={100} style={{backgroundColor: '#c0e0cf'}}>
                <img src={Adminstrative} height="260px" width="250px" />
                <p className="cat-head"><b>Legal</b></p>
                <a href="#" className="categories-view">View</a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 job-margin">
              <div className="box box1" data-aos data-aos-delay={100} style={{backgroundColor: '#c0e0cf'}}>
                <img src={Sales} height="260px" width="250px" />
                <p className="cat-head"><b>Accounting, Banking &amp; Finance</b></p>
                <a href="#" className="categories-view">View</a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 job-margin">
              <div className="box box1" data-aos data-aos-delay={100} style={{backgroundColor: '#c0e0cf'}}>
                <img src={Other} height="260px" width="250px" />
                <p className="cat-head"><b>Others</b></p>
                <a href="#" className="categories-view">View</a>
              </div>
            </div>
          </div>
             
        </div>
      </section>
      
      
      
     
      {/*
      <div className="container">
        <div className="row">
          <div className="col-sm-3">
          <Card style={{ width: '18rem',height:100,backgroundColor:'white',border:'none' }}>
              <Card.Img variant="top" src={Students1} />
              <Card.Body>
                <Card.Title className="text-center"> <Button variant="primary">Go somewhere</Button></Card.Title>
                
               
              </Card.Body>
            </Card>
            </div>
            <div className="col-sm-3">
            <Card style={{ width: '18rem',height:100,backgroundColor:'white',border:'none' }}>
              <Card.Img variant="top" src={Students1} />
              <Card.Body>
                <Card.Title className="text-center mt-0"> <Button variant="primary">Go somewhere</Button></Card.Title>
                
               
              </Card.Body>
            </Card>
            </div>
            <div className="col-sm-3">
            <Card style={{ width: '18rem',height:100,backgroundColor:'white',border:'none' }}>
              <Card.Img variant="top" src={Students1} />
              <Card.Body>
                <Card.Title className="text-center mt-0"> <Button variant="primary">Go somewhere</Button></Card.Title>
                
               
              </Card.Body>
            </Card>
            </div>
            <div className="col-sm-3">
            <Card style={{ width: '18rem',height:100,backgroundColor:'white',border:'none' }}>
              <Card.Img variant="top" src={Students1} />
              <Card.Body>
                <Card.Title className="text-center mt-0"> <Button variant="primary">Go somewhere</Button></Card.Title>
                
               
              </Card.Body>
            </Card>
            </div>
        </div>
        </div>
      */}
      {/* 
     <div id="tabs" className="tabs">
        <div className="container" data-aos="fade-up">
          <hr style={{border: '1px solid green', margin: '42px 0px'}} /> 
          <div style={{textAlign:'center'}}>
            <h1 >Selected Seller Profile</h1>
          </div>
          <ul className="nav nav-tabs row d-flex mobile-tab mt-5">
            <li className="nav-item col-3" id="mobile_tab">
              <a className="nav-link active show" data-bs-toggle="tab" data-bs-target="#tab-1" style={{border: 'none'}}>
                <h4 className="d-none d-lg-block" id="tab_text">Mobile App Developers</h4>
              </a>
            </li>
            <li className="nav-item col-2" id="mobile_tab">
              <a className="nav-link" data-bs-toggle="tab" data-bs-target="#tab-2" style={{border: 'none'}}>
                <h4 className="d-none d-lg-block" id="tab_text">Graphic Designers</h4>
              </a>
            </li>
            <li className="nav-item col-2" id="mobile_tab">
              <a className="nav-link" data-bs-toggle="tab" data-bs-target="#tab-3" style={{border: 'none'}}>
                <h4 className="d-none d-lg-block" id="tab_text">Digital Marketing</h4>
              </a>
            </li>
            <li className="nav-item col-3" id="mobile_tab">
              <a className="nav-link" data-bs-toggle="tab" data-bs-target="#tab-4" style={{border: 'none'}}>
                <h4 className="d-none d-lg-block" id="tab_text">Product Designers</h4>
              </a>
            </li>
            <li className="nav-item col-2"  id="mobile_tab" >
              <a className="nav-link" data-bs-toggle="tab" data-bs-target="#tab-5" style={{border: 'none'}}>
                <h4 className="d-none d-lg-block" id="tab_text">Legal Researchers</h4>
              </a>
            </li>
          </ul>
          <div className="tab-content">
            <div className="tab-pane active show" id="tab-1">          
            
              <div className="row">
                <div className="container mt-5" data-aos="zoom-in">
                  <div className="row">
                    <div className="col-lg-4 col-sm-6 mb-4">
                      <div className="card1">
                        <a href="#"><img className="card-img-top" src="assets/image/gigeconome25-26.jpg" alt="" /></a>
                        <div className="card-body">
                          <h4 className="card-title">
                            <a href="#" style={{color: '#009345'}}>Amanda Gabriel</a>
                          </h4>
                          <small>Java Developer</small>
                          <p className="card-text" style={{fontSize: '12px', marginTop: '2%'}}>Amanda Gabriel dolor sit amet, consectetur adipiscing elit. Nam viverra euismod odio, gravida pellentesque urna varius .</p>
                          <p>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Javascript </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>HTML </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Wordpress </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>PHP </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Java </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Python </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Javascript </span>      <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Javascript </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>HTML </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Wordpress </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>PHP </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Java </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Python </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Javascript </span>
                          </p>  
                        </div>
                      </div>
                      <div style={{background: '#009345', textAlign: 'center', fontSize: '18px', padding: '2%', fontFamily: '"Raleway"', boxShadow: '1px 1px 4px 1px'}}>
                        <span style={{color: '#fff', cursor: 'pointer'}}>View Amanda</span>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 mb-4">
                      <div className="card1 h-80">
                        <a href="#"><img className="card-img-top" src="assets/image/gigeconome25-27.jpg" alt="" /></a>
                        <div className="card-body">
                          <h4 className="card-title">
                            <a href="#" style={{color: '#009345'}}>Justin Michela</a>
                          </h4><small>Python Developer</small>
                          <p className="card-text" style={{fontSize: '12px', marginTop: '2%'}}>Justin Michela dolor sit amet, consectetur adipiscing elit. Nam viverra euismod odio, gravida pellentesque urna varius vitae.</p>
                          <p>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Javascript </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>HTML </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Wordpress </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>PHP </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Java </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Python </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Javascript </span>      <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Javascript </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>HTML </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Wordpress </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>PHP </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Java </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Python </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Javascript </span>
                          </p>  
                        </div>
                      </div>
                      <div style={{background: '#009345', textAlign: 'center', fontSize: '18px', padding: '2%', fontFamily: '"Raleway"', boxShadow: '1px 1px 4px 1px'}}>
                        <span style={{color: '#fff', cursor: 'pointer'}}>View Justin</span>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 mb-4">
                      <div className="card1  h-80">
                        <a href="#"><img className="card-img-top" src="assets/image/gigeconome25-28.jpg" alt="" /></a>
                        <div className="card-body">
                          <h4 className="card-title">
                            <a href="#" style={{color: '#009345'}}>Adrien Valesa</a>
                          </h4><small>PHP Developer</small>
                          <p className="card-text" style={{fontSize: '12px', marginTop: '2%'}}>Adrien Valesa dolor sit amet, consectetur adipiscing elit. Nam viverra euismod
                            odio, gravida pellentesque urna varius vitae.</p>
                          <p>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Javascript </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>HTML </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Wordpress </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>PHP </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Java </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Python </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Javascript </span>      <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Javascript </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>HTML </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Wordpress </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>PHP </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Java </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Python </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Javascript </span>
                          </p>  
                        </div>
                      </div>
                      <div style={{background: '#009345', textAlign: 'center', fontSize: '18px', padding: '2%', fontFamily: '"Raleway"', boxShadow: '1px 1px 4px 1px'}}>
                        <span style={{color: '#fff', cursor: 'pointer'}}>View Adrien</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
             
            </div>
            <div className="tab-pane" id="tab-2">
              
              <div className="row">
                <div className="container mt-5" data-aos="zoom-in">
                  <div className="row">
                    <div className="col-lg-4 col-sm-6 mb-4">
                      <div className="card1">
                        <a href="#"><img className="card-img-top" src="assets/image/gigeconome25-26.jpg" alt="" /></a>
                        <div className="card-body">
                          <h4 className="card-title">
                            <a href="#" style={{color: '#009345'}}>Harshit Nema</a>
                          </h4>
                          <small>Java Developer</small>
                          <p className="card-text" style={{fontSize: '12px', marginTop: '2%'}}>Amanda Gabriel dolor sit amet, consectetur adipiscing elit. Nam viverra euismod odio, gravida pellentesque urna varius .</p>
                          <p>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Javascript </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>HTML </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Wordpress </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>PHP </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Java </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Python </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Javascript </span>      <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Javascript </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>HTML </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Wordpress </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>PHP </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Java </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Python </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Javascript </span>
                          </p>  
                        </div>
                      </div>
                      <div style={{background: '#009345', textAlign: 'center', fontSize: '18px', padding: '2%', fontFamily: '"Raleway"', boxShadow: '1px 1px 4px 1px'}}>
                        <span style={{color: '#fff', cursor: 'pointer'}}>View Amanda</span>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 mb-4">
                      <div className="card1 h-80">
                        <a href="#"><img className="card-img-top" src="assets/image/gigeconome25-27.jpg" alt="" /></a>
                        <div className="card-body">
                          <h4 className="card-title">
                            <a href="#" style={{color: '#009345'}}>Justin Michela</a>
                          </h4><small>Python Developer</small>
                          <p className="card-text" style={{fontSize: '12px', marginTop: '2%'}}>Justin Michela dolor sit amet, consectetur adipiscing elit. Nam viverra euismod odio, gravida pellentesque urna varius vitae.</p>
                          <p>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Javascript </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>HTML </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Wordpress </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>PHP </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Java </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Python </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Javascript </span>      <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Javascript </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>HTML </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Wordpress </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>PHP </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Java </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Python </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Javascript </span>
                          </p>  
                        </div>
                      </div>
                      <div style={{background: '#009345', textAlign: 'center', fontSize: '18px', padding: '2%', fontFamily: '"Raleway"', boxShadow: '1px 1px 4px 1px'}}>
                        <span style={{color: '#fff', cursor: 'pointer'}}>View Justin</span>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 mb-4">
                      <div className="card1  h-80">
                        <a href="#"><img className="card-img-top" src="assets/image/gigeconome25-28.jpg" alt="" /></a>
                        <div className="card-body">
                          <h4 className="card-title">
                            <a href="#" style={{color: '#009345'}}>Adrien Valesa</a>
                          </h4><small>PHP Developer</small>
                          <p className="card-text" style={{fontSize: '12px', marginTop: '2%'}}>Adrien Valesa dolor sit amet, consectetur adipiscing elit. Nam viverra euismod
                            odio, gravida pellentesque urna varius vitae.</p>
                          <p>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Javascript </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>HTML </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Wordpress </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>PHP </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Java </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Python </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Javascript </span>      <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Javascript </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>HTML </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Wordpress </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>PHP </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Java </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Python </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Javascript </span>
                          </p>  
                        </div>
                      </div>
                      <div style={{background: '#009345', textAlign: 'center', fontSize: '18px', padding: '2%', fontFamily: '"Raleway"', boxShadow: '1px 1px 4px 1px'}}>
                        <span style={{color: '#fff', cursor: 'pointer'}}>View Adrien</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane" id="tab-3">
             
              <div className="row">
                <div className="container mt-5" data-aos="zoom-in">
                  <div className="row">
                    <div className="col-lg-4 col-sm-6 mb-4">
                      <div className="card1">
                        <a href="#"><img className="card-img-top" src="assets/image/gigeconome25-26.jpg" alt="" /></a>
                        <div className="card-body">
                          <h4 className="card-title">
                            <a href="#" style={{color: '#009345'}}>Amanda Gabriel</a>
                          </h4>
                          <small>Java Developer</small>
                          <p className="card-text" style={{fontSize: '12px', marginTop: '2%'}}>Amanda Gabriel dolor sit amet, consectetur adipiscing elit. Nam viverra euismod odio, gravida pellentesque urna varius .</p>
                          <p>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Javascript </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>HTML </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Wordpress </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>PHP </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Java </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Python </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Javascript </span>      <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Javascript </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>HTML </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Wordpress </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>PHP </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Java </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Python </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Javascript </span>
                          </p>  
                        </div>
                      </div>
                      <div style={{background: '#009345', textAlign: 'center', fontSize: '18px', padding: '2%', fontFamily: '"Raleway"', boxShadow: '1px 1px 4px 1px'}}>
                        <span style={{color: '#fff', cursor: 'pointer'}}>View Amanda</span>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 mb-4">
                      <div className="card1 h-80">
                        <a href="#"><img className="card-img-top" src="assets/image/gigeconome25-27.jpg" alt="" /></a>
                        <div className="card-body">
                          <h4 className="card-title">
                            <a href="#" style={{color: '#009345'}}>Justin Michela</a>
                          </h4><small>Python Developer</small>
                          <p className="card-text" style={{fontSize: '12px', marginTop: '2%'}}>Justin Michela dolor sit amet, consectetur adipiscing elit. Nam viverra euismod odio, gravida pellentesque urna varius vitae.</p>
                          <p>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Javascript </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>HTML </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Wordpress </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>PHP </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Java </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Python </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Javascript </span>      <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Javascript </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>HTML </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Wordpress </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>PHP </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Java </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Python </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Javascript </span>
                          </p>  
                        </div>
                      </div>
                      <div style={{background: '#009345', textAlign: 'center', fontSize: '18px', padding: '2%', fontFamily: '"Raleway"', boxShadow: '1px 1px 4px 1px'}}>
                        <span style={{color: '#fff', cursor: 'pointer'}}>View Justin</span>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 mb-4">
                      <div className="card1  h-80">
                        <a href="#"><img className="card-img-top" src="assets/image/gigeconome25-28.jpg" alt="" /></a>
                        <div className="card-body">
                          <h4 className="card-title">
                            <a href="#" style={{color: '#009345'}}>Adrien Valesa</a>
                          </h4><small>PHP Developer</small>
                          <p className="card-text" style={{fontSize: '12px', marginTop: '2%'}}>Adrien Valesa dolor sit amet, consectetur adipiscing elit. Nam viverra euismod
                            odio, gravida pellentesque urna varius vitae.</p>
                          <p>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Javascript </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>HTML </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Wordpress </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>PHP </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Java </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Python </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Javascript </span>      <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Javascript </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>HTML </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Wordpress </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>PHP </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Java </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Python </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Javascript </span>
                          </p>  
                        </div>
                      </div>
                      <div style={{background: '#009345', textAlign: 'center', fontSize: '18px', padding: '2%', fontFamily: '"Raleway"', boxShadow: '1px 1px 4px 1px'}}>
                        <span style={{color: '#fff', cursor: 'pointer'}}>View Adrien</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane" id="tab-4">
              
              <div className="row">
                <div className="container mt-5" data-aos="zoom-in">
                  <div className="row">
                    <div className="col-lg-4 col-sm-6 mb-4">
                      <div className="card1">
                        <a href="#"><img className="card-img-top" src="assets/image/gigeconome25-26.jpg" alt="" /></a>
                        <div className="card-body">
                          <h4 className="card-title">
                            <a href="#" style={{color: '#009345'}}>Amanda Gabriel</a>
                          </h4>
                          <small>Java Developer</small>
                          <p className="card-text" style={{fontSize: '12px', marginTop: '2%'}}>Amanda Gabriel dolor sit amet, consectetur adipiscing elit. Nam viverra euismod odio, gravida pellentesque urna varius .</p>
                          <p>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Javascript </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>HTML </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Wordpress </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>PHP </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Java </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Python </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Javascript </span>      <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Javascript </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>HTML </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Wordpress </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>PHP </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Java </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Python </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Javascript </span>
                          </p>  
                        </div>
                      </div>
                      <div style={{background: '#009345', textAlign: 'center', fontSize: '18px', padding: '2%', fontFamily: '"Raleway"', boxShadow: '1px 1px 4px 1px'}}>
                        <span style={{color: '#fff', cursor: 'pointer'}}>View Amanda</span>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 mb-4">
                      <div className="card1 h-80">
                        <a href="#"><img className="card-img-top" src="assets/image/gigeconome25-27.jpg" alt="" /></a>
                        <div className="card-body">
                          <h4 className="card-title">
                            <a href="#" style={{color: '#009345'}}>Justin Michela</a>
                          </h4><small>Python Developer</small>
                          <p className="card-text" style={{fontSize: '12px', marginTop: '2%'}}>Justin Michela dolor sit amet, consectetur adipiscing elit. Nam viverra euismod odio, gravida pellentesque urna varius vitae.</p>
                          <p>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Javascript </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>HTML </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Wordpress </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>PHP </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Java </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Python </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Javascript </span>      <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Javascript </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>HTML </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Wordpress </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>PHP </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Java </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Python </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Javascript </span>
                          </p>  
                        </div>
                      </div>
                      <div style={{background: '#009345', textAlign: 'center', fontSize: '18px', padding: '2%', fontFamily: '"Raleway"', boxShadow: '1px 1px 4px 1px'}}>
                        <span style={{color: '#fff', cursor: 'pointer'}}>View Justin</span>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 mb-4">
                      <div className="card1  h-80">
                        <a href="#"><img className="card-img-top" src="assets/image/gigeconome25-28.jpg" alt="" /></a>
                        <div className="card-body">
                          <h4 className="card-title">
                            <a href="#" style={{color: '#009345'}}>Adrien Valesa</a>
                          </h4><small>PHP Developer</small>
                          <p className="card-text" style={{fontSize: '12px', marginTop: '2%'}}>Adrien Valesa dolor sit amet, consectetur adipiscing elit. Nam viverra euismod
                            odio, gravida pellentesque urna varius vitae.</p>
                          <p>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Javascript </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>HTML </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Wordpress </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>PHP </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Java </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Python </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Javascript </span>      <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Javascript </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>HTML </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Wordpress </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>PHP </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Java </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Python </span>
                            <span style={{background: '#f2f2f2', color: '#009345', fontSize: '10px', padding: '1%', marginRight: '4%'}}>Javascript </span>
                          </p>  
                        </div>
                      </div>
                      <div style={{background: '#009345', textAlign: 'center', fontSize: '18px', padding: '2%', fontFamily: '"Raleway"', boxShadow: '1px 1px 4px 1px'}}>
                        <span style={{color: '#fff', cursor: 'pointer'}}>View Adrien</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <Link to="/signup"> <img style={{display: 'block', marginLeft: 'auto', marginRight: 'auto'}} src="assets/image/SECTION4.png" /></Link>
             
            </div>
          </div>
        
        </div>
        
      </div> */}
     
     
      <div className="container">
       
        {/*<div className="row " style={{ display: "flex" }}>
         <div className="maccordion">
         <Accordion/>
         </div>
         
          <div className="col-md-3 boxxlr bigaccord">
            <div
              className="icon-box aos-init aos-animate downsec"
              data-aos="fade-up"
              data-aos-delay={100}
              style={{
                padding: "6%",
                marginBottom: "35px",
                paddingBottom: "42px"
              }}
              id="box"
            >
              <i className="fa fa-briefcase fa-5x" id="fa"/>
              <h4 className="boxclrtitle" id="text">Internship</h4>
              <p className="boxclrtitle" id="text" style={{ fontWeight: "normal" }}>
                In an ever-competitive marketplace, secure the best internship
                opportunities and gain real industry and valuable hands-on
                experience across all sectors and geographical locations.
              </p>
             
            </div>
          </div>
          <div className="col-md-3 boxxlr bigaccord">
            <div
              className="icon-box aos-init aos-animate downsec" 
              data-aos="fade-up"
              data-aos-delay={100}
              style={{
                padding: "6%",
                marginBottom: "35px",
                paddingBottom: "39px"
              }}
              id="box"
            >
              <i
                style={{ marginBottom: "4px" }}
                className="fa fa-id-card-o fa-5x" id="fa"
              />
              <h4 className="boxclrtitle" id="text">Work Experience</h4>
              <p className="boxclrtitle" id="text" style={{ fontWeight: "normal" }}>
                <div id="work">
                Access to both newly formed Startups and Large enterprises,
                provides the perfect opportunity to acquire new skills and build
                experience either through voluntary or paid positions.
                </div>
              </p>
            </div>
          </div>
          <div className="col-md-3 boxxlr bigaccord">
            <div
              className="icon-box aos-init aos-animate downsec" 
              data-aos="fade-up"
              data-aos-delay={100}
              style={{ padding: "6%", marginBottom: "35px" }}
              id="box"
            >
              <i className="fa fa-american-sign-language-interpreting fa-5x" id="fa" />
              <h4 className="boxclrtitle" id="work" id="text">Work Placement</h4>
              <p className="boxclrtitle" id="text" style={{ fontWeight: "normal" }}>
                <div id="work_one">
                Gain significant on-the-job experience as well as apply your
                knowledge and skills in a work setting of your chosen line of
                study or industry and increase competencies sought after by
                employers.
                </div>
              </p>
            </div>
          </div>
          <div className="col-md-3 boxxlr bigaccord">
            <div
              className="icon-box aos-init aos-animate downsec"
              data-aos="fade-up"
              data-aos-delay={100}
              style={{
                padding: "6%",
                marginBottom: "35px",
                paddingBottom: "38px"
              }}
              id="box"
            >
              <i
                style={{ marginBottom: "4px" }}
                className="fa fa-envelope-open fa-5x" id="fa"
              />
              <h4 className="boxclrtitle" id="text">Gig Opportunities</h4>
              <p className="boxclrtitle" id="text" style={{ fontWeight: "normal" }}>
                Wide range of flexible Gig opportunities to make the most of
                spare times, earn money and gain valuable real-life experiences
                by securing freelance works or short-term day-rate contracts.
              </p>
            </div>
          </div>
        </div>
        <div className="row bigaccord" style={{ display: "flex" }}>
          <div className="col-md-3 boxxlr">
            <div
              className="icon-box aos-init aos-animate downsec" id="fa"
              data-aos="fade-up"
              data-aos-delay={100}
              style={{ padding: "6%", marginBottom: "35px", }}
              id="box"
            >
              <i className="fa fa-clock-o fa-5x" id="fa" />
              <h4 className="boxclrtitle" id="text">Part Time Roles</h4>
              <p className="boxclrtitle" id="text" style={{ fontWeight: "normal" }}>
                GigeconoMe provides you the opportunity to not only gain
                financial protection during your studies but also gain a variety
                of employability find part-time opportunities directly relevant
                to definite career plans.
              </p>
            </div>
          </div>
          <div className="col-md-3 boxxlr">
            <div
              className="icon-box aos-init aos-animate downsec" id="fa"
              data-aos="fade-up"
              data-aos-delay={100}
              style={{ padding: "6%", marginBottom: "35px" }}
              id="box"
            >
              <i className="fa fa-cog fa-5x"id="fa" />
              <h4 className="boxclrtitle" id="text">Course Work Related Projects</h4>
              <p className="boxclrtitle" id="text" style={{ fontWeight: "normal" }}>
                <div id="course">
                Interested in inspiring real-life projects than those in current
                curriculum? Use GigeconoMe to find specific work-related
                activities and develop an understanding of work and develop
                skills employers want.
                </div>
              </p>
            </div>
          </div>
          <div className="col-md-3 boxxlr">
            <div
              className="icon-box aos-init aos-animate downsec" id="fa"
              data-aos="fade-up"
              data-aos-delay={100}
              style={{ padding: "6%", marginBottom: "35px" }}
              id="box"
            >
              <i className="fa fa-folder-open fa-5x" id="fa"/>
              <h4 className="boxclrtitle" id="text" >Portfolio Building</h4>
              <p className="boxclrtitle" id="text" style={{ fontWeight: "normal",padding:"4%" ,marginLeft:"-4%"}}>
              Impress future employers and build an impressive portfolio of work while studying to showcase learning growth as well as demonstrate your creativity, skills and ability.

              </p>
            </div>
          </div>
          <div className="col-md-3 boxxlr">
            <div
              className="icon-box aos-init aos-animate downsec" id="fa"
              data-aos="fade-up"
              data-aos-delay={100}
              style={{ padding: "6%", marginBottom: "35px" }}
              id="box"
            >
              <i className="fa fa-lightbulb-o fa-5x" id="fa"/>
              <h4 className="boxclrtitle" id="text">Graduate Opportunities</h4>
              <p className="boxclrtitle" id="text" style={{ fontWeight: "normal" }}>
              In your final year of study and ready for the job market? Use GigeconoMe to navigate the first role of your career ladder and explore the best available graduate jobs across the widest range of sectors and employers.
              </p>
            </div>
          </div>
        </div> */}
      </div>
<div className="newbackdiv text-center">
<h2 className='helpingtxt' style={{color:"#00539C"}}>Helping Students Become the most Employable<br/>
              Graduates by Connecting them.
</h2>
<br/>
<button to="/signup" onClick={()=>handleEducator()}  class="createprofilebtnn ">Request Demo</button>
</div>

      
     <Footer />
    
       
    </div>
  );
  
}

export default Index;
