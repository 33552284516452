import React, { useState } from "react";
import Invite from "../../images/invitenew.png";
import EmptyIcon from "../../images/emptyicon.PNG";
import SelectIcon from "../../images/selecticon.PNG";
import { Link } from "react-router-dom";
function close(){
  window.location.reload();
}
export default class InviteModal extends React.Component {
  constructor() {
    super();
    let comments = JSON.parse(sessionStorage.getItem("someData"));
   
   console.log(comments)
    this.state = {
      
    
     
        showModal: false,
      userid: comments,
     
      dropdownlist_input : "recommendation",
      addstudata:"recommendation",
      selectOptions : [],
      email:"",
      rtype:"",
      description:"",
      success:"",
      charLimit: 0,
      error:""
  
  }
  this.handleSubmit = this.handleSubmit.bind(this);
  this.cancel=this.cancel.bind(this)
}
cancel(){
  window.location.reload();
}
openModal = () => {
    this.setState({ showModal: true });
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };
  handleSubmit = (event) => {
    event.preventDefault()
    if(this.state.description.length>=300){
     this.setState({error:"You have exceeded the maximum number of 300 characters in that field"})
    }
   else{
    const data = {

      userid: this.state.userid,
      addstudata: this.state.addstudata,
      email:this.state.email,
      rtype:this.state.rtype,
      description:this.state.description,
     
      
     

    }
  
    let BaseUrl = JSON.parse(sessionStorage.getItem("baseurl"));

      fetch( "https://www.gigeconome.com:5200/api/addstu_profiledata", {
        method: "POST",

        body: JSON.stringify(data), // data can be `string` or {object}!

        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())

        .catch((error) => console.error("Error:", error))

        .then((response) => {
          console.log(response)
         
          this.setState({
            success:response.message
          })
          window.location.reload();
        });
      }
  }




  componentDidMount(){
    this.getOptions()
}
async getOptions(){
  const data_one={dropdownlist_input:this.state.dropdownlist_input,
  
  }

  let BaseUrl = JSON.parse(sessionStorage.getItem('baseurl'));

  fetch( 'https://www.gigeconome.com:5200/api/getdropdownlist', { method: 'POST', 

   body: JSON.stringify(data_one), // data can be `string` or {object}!

   headers:{ 'Content-Type': 'application/json' } })

   .then(res => res.json())

   .catch(error => console.error('Error:', error))

   .then((json)=>{
    const data = json.result
    
    this.setState({selectOptions: data})
   })
  
   
 
 
 
  
  }
    handleChange = (e) => {
      this.setState({
        [e.target.name]: e.target.value,
       
      });
      
    };
render(){

  return (
    <>
    {this.state.showModal && (
      <div class="ft-modal" id="ft-invitenon-modal">
        <div class="ft-modal-content" style={{width:"47%"}}>
          <div class="ft-modal-header">
            <div class="header">
            
              
            </div>
          </div>
          <div class="ft-modal-body">
            <form onSubmit = {this.handleSubmit}>
            <div class="row mt-2">
              <div class="col-sm-1"></div>
              <div class="col-sm-10" style={{textAlign:"center",marginTop:"10%"}}>
                <span style={{fontSize:25,fontFamily:"Gotham"}}>Please Sign in and access the invite Button</span>
              </div>
              <div class="col-sm-1"></div>
            </div>
         
          
            <div class="row mt-5" >
            <div className="col-sm-4">

            </div>
            <div className="col-sm-4">
              <Link to="/Signin" className="btn btn-success"
              style={{fontFamily:"Gotham",backgroundColor:"rgb(0,147,69)",width:"100%"}}
              >Sign In</Link>  <br/><br />
              <span   onClick={this.closeModal} 
              style={{fontFamily:"GothamLight",color:"transparent",cursor:"pointer",fontWeight:"bold",marginLeft:"35%"}}>No Thanks</span>
            </div>
            <div className="col-sm-4">
                
            </div>
            </div>
          
            </form>
          </div>
          <div class="ft-modal-footer">
            <a class="ft-modal-close" style={{cursor:"pointer"}}  onClick={this.closeModal}>
              [&#10006;]{" "}
            </a>
          </div>
        </div>
      </div>
 )}
      <a
        onClick={this.openModal} 
        href="#ft-invitenon-modal"
      >
        <img src={Invite} style={{float:"right",width:"31%",cursor:"pointer"}} />
      </a>
    </>
  );
}
}