import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import StudentMessage from "./StudentMessage";
function StudentSettingMenu() {
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);
  return (
    <div class="my-0" style={{ marginLeft: -15 }}>
      <nav
        class="navbar navbar-expand-xl navbar-dark"
      
      >
        <div className="container d-flex align-items-left">
          <a href="#" class="navbar-brand"></a>
          <button
            class="custom-toggler navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarCollapse6"
            aria-controls="navbarsExample09"
            aria-expanded={!isNavCollapsed ? true : false}
            aria-label="Toggle navigation"
            onClick={handleNavCollapse}
          >
            <span class="navbar-toggler-icon bg-dark"></span>
          </button>
          <div
            class={`${isNavCollapsed ? "collapse" : ""} navbar-collapse`}
            id="navbarCollapse6"
          >
            <div class="navbar-nav" id="programming">
              <Link to="/studentdashboard" class="nav-item" >
                Profile ⏷
              </Link>
             
              <Link to="/studentopportunity"  style={{ marginLeft: 7 }}>

Opportunities ⏷
</Link>

              <Link
                to="/studentmessage"
                class="nav-item"
                style={{ marginLeft: 7 }}
               
              >
                Message ⏷
              </Link>
             
              <a
                href="#"
                class="nav-item"
                style={{ marginLeft: 7 }}
              >
                Analytics
              </a>
              <a
                href="#"
                class="nav-item"
                style={{ marginLeft: 7 }}
              >
                Community
              </a>
              <a
                href="#"
                class="nav-item"
                style={{ marginLeft: 7 }}
                id="c_text"
              >
                Settings ⏷
              </a>
            </div>
            
          </div>
        </div>
      </nav>
    </div>
  );
}

export default StudentSettingMenu;
