import React, { useState } from "react";
// import './Header.css';
import Logo from "../../images/logo.png";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
function PostaProjectNav() {
  let history = useHistory();
  let login = () => {
    history.push("/signin");
  };
 
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);
  return (
    <div className="my-4" style={{ marginLeft: 0 }}>
      <nav id="example" className="navbar navbar-expand-xl navbar-light bg-white fixed-top d-flex align-items-center">
        <div className="container d-flex align-items-left">
          <a className="navbar-brand logo me-auto">
            <Link to="/">
              <img src={Logo} />
            </Link>
          </a>
          {/* Uncomment below if you prefer to use an image logo */}
          {/* <a href="index.html" className="logo me-auto"><img src="assets/img/logo.png" alt=""></a>*/}
          {/* <a className="navbar-brand" href="#">Navbar</a> */}
          <button
            className="custom-toggler navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarCollapse1"
            aria-controls="navbarsExample09"
            aria-expanded={!isNavCollapsed ? true : false}
            aria-label="Toggle navigation"
            onClick={handleNavCollapse}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className={`${isNavCollapsed ? "collapse" : ""} navbar-collapse`}
            id="navbarCollapse1"
          >
            <div className="navbar-nav">
              <div className="navbar-nav me-auto mb-2 mb-lg-0" style={{marginLeft:150}}>
              <Link to="/aboutus" className="nav-link scrollto">
                   About Us
                   </Link>
                <li className="nav-item dropdown mt-1" style={{marginLeft:10}}>
               
                  <Link
                    className="nav-link dropdown-toggle"
                    to="#"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Students
                  </Link>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    <li>
                      <Link
                        className="dropdown-item mt-1"
                        style={{ color: "#009345" }}
                        to="studentHowitswork"
                      >
                        How it Works
                      </Link>
                    </li>
                  </ul>
                </li>

                <li className="nav-item dropdown mt-1" style={{marginLeft:10}}>
                  <Link
                    className="nav-link dropdown-toggle"
                    to="#"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Companies
                  </Link>
                  <ul
                    className="dropdown-menu "
                    aria-labelledby="navbarDropdown"
                  >
                    <li>
                      <Link to="company" style={{ color: "#009345" }}>
                        How it Works
                      </Link>
                    </li>
                  </ul>
                </li>

                <li className="nav-item dropdown mt-1" style={{marginLeft:10}}>
                  <Link
                    className="nav-link dropdown-toggle"
                    to="#"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Universities
                  </Link>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    <li>
                      {" "}
                      <Link to="/universites" style={{ color: "#009345" }}>
                        How it Works
                      </Link>
                    </li>
                  </ul>
                </li>

                <li className="nav-item dropdown mt-1" style={{marginLeft:10}}>
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Startups
                  </a>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    <li>
                      {" "}
                      <Link to="/company" style={{ color: "#009345" }}>
                        How it Works
                      </Link>
                    </li>
                  </ul>
                </li>
              </div>
              <Link
            to="/landingpage"
            className="get-started-btn scrollto"
            className="btn mt-2"
            id="project"
            style={{
              /* padding: '8px 25px', */
              /* height: '7px', */
              marginLeft: "60px",
              background: "#009345",
              color: "#fff",
              borderRadius: "10px",
              padding: "5px 25px",
              whiteSpace: "nowrap",
              transition: "0.3s",
              fontSize: "14px",
              fontWeight: 600,
              display: "inline-block",
              cursor: "pointer",
            }}
          >
            Post a Project
          </Link>
          
          <a
            
            className="get-started-btn scrollto"
            className="btn mt-2"
          
          
          >
            
          
          </a>
         
   <AccountCircleIcon   style={{fontSize:40,color:'rgb(0, 147, 69)',marginLeft:40,marginTop:5}} />
        
          
    
       
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}
export default PostaProjectNav;
