import React, { useState } from "react";
import "./modal.css";


function close(){
  window.location.reload();
}
export default class  CompanyInclusion extends React.Component{
  
  constructor() {
    super();
    let comments = JSON.parse(sessionStorage.getItem("someData"));
   
   console.log(comments)
    this.state = {
      
    
     
     
      userid: comments,
      addcpydata :"cpyinclusion",
      nationality:"",
      ethnicity:"",
     message:"",
      religion:"",
      gender:"",
      freeschoolmeal:"",
      namesecschool:"",
      schooloutuk:"",
      parentsatdUsity:"",
      occupation:"",
      schoolages:"",
      dropdownlist_input : "nationality",
      isChecked: false,
      selectOptions : [],
      selectSchool:[],
      showModal: false,
  
  }
  this.handleSubmit = this.handleSubmit.bind(this);
  this.handleChecked = this.handleChecked.bind(this); 
}

  handleSubmit = (event) => {
    event.preventDefault()
   
    const data = {

      userid: this.state.userid,
      addcpydata: this.state.addcpydata,
      nationality:this.state.nationality,
      ethnicity:this.state.ethnicity,
      religion:this.state.religion,
      gender:this.state.gender,
      freeschoolmeal:this.state.freeschoolmeal,
      schooloutuk:this.state.schooloutuk,
      parentsatdUsity:this.state.parentsatdUsity,
      occupation:this.state.occupation,
      schoolages:this.state.schoolages,
      namesecschool:this.state.namesecschool
      
     

    }
  
    let BaseUrl = JSON.parse(sessionStorage.getItem("baseurl"));

      fetch( "https://www.gigeconome.com:5200/api/addcpy_profiledata", {
        method: "POST",

        body: JSON.stringify(data), // data can be `string` or {object}!

        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())

        .catch((error) => console.error("Error:", error))

        .then((response) => {
          console.log(response)
          window.location.reload();
         this.setState({message:response.message})
          
        });
    
  }
  componentDidMount(){
    this.getOptions()
}
async getOptions(){
  const data_one={dropdownlist_input:this.state.dropdownlist_input,
  
  }



  fetch( 'https://www.gigeconome.com:5200/api/getdropdownlist', 
  { method: 'POST', 

   body: JSON.stringify(data_one), // data can be `string` or {object}!

   headers:{ 'Content-Type': 'application/json' } })

   .then(res => res.json())

   .catch(error => console.error('Error:', error))

   .then((json)=>{
    const data = json.result
    
    this.setState({selectOptions: data})
   })


   const data_three={userid:this.state.userid,
  
   }
 
 
 
   fetch( 'https://www.gigeconome.com:5200/api/cpyprofileinfo', 
   { method: 'POST', 
 
    body: JSON.stringify(data_three), // data can be `string` or {object}!
 
    headers:{ 'Content-Type': 'application/json' } })
 
    .then(res => res.json())
 
    .catch(error => console.error('Error:', error))
 
    .then((response)=>{
    this.setState({
      nationality:response.user.nationality,
      religion:response.user.religion,
      gender:response.user.gender,
      freeschoolmeal:response.user.freeschoolmeal,
      disorlearndiff:response.user.disorlearndiff,
      namesecschool:response.user.namesecschool,
      schooloutuk:response.user.schooloutuk,
      parentsatdUsity:response.user.parentsatdUsity,
      occupation:response.user.occupation,
      schoolages:response.user.schoolages,
      ethnicity:response.user.ethnicity



    })
    })
 











   const data_two={dropdownlist_input:"schoolname"
  
   }
 
   //let BaseUrl = JSON.parse(sessionStorage.getItem('baseurl'));
 
   fetch( 'https://www.gigeconome.com:5200/api/getdropdownlist', { method: 'POST', 
 
    body: JSON.stringify(data_two), // data can be `string` or {object}!
 
    headers:{ 'Content-Type': 'application/json' } })
 
    .then(res => res.json())
 
    .catch(error => console.error('Error:', error))
 
    .then((json)=>{
     const data = json.result
     
     this.setState({selectSchool: data})
    })
 
  

    
  }
    handleChange = (e) => {
      this.setState({
        [e.target.name]: e.target.value,
       
      });
     
    };

    openModal = () => {
      this.setState({ showModal: true });
    };
  
    closeModal = () => {
      this.setState({ showModal: false });
    };
  
    handleChecked () {
      this.setState({isChecked: !this.state.isChecked});
    }
  
render(){
  const{geomessage}=this.state
  const{selectOptions}=this.state
  const{selectSchool}=this.state
  var txt;
  if (this.state.isChecked) {
    txt = 'checked'
  } else {
    txt = 'unchecked'
  }

  return (
    <>
    
    {this.state.showModal && (
      <div class="ft-modal" id="ft-companyinc-modal">
        <div class="ft-modal-content" >
          <div class="ft-modal-header">
            <div class="header">
            <h5
                  class="modal-title"
                  id="exampleModalLabel"
                  style={{
                    textAlign: "center",
                    fontSize: 25,
                    fontWeight: "bold",
                  }}
                >
                  Inclusion
                </h5>
              <hr />
              <h5 style={{ textAlign: "center", color: "rgb(0, 147, 69)" }}>
                  Please
                  <br /> Fill the Following
                </h5>
            </div>
          </div>
          <div class="ft-modal-body">
          <form onSubmit = {this.handleSubmit}>
         
          
               
                <div class="form-group row" style={{marginTop:"3%"}}>
                  <div class="col-sm-2"></div>
    <label for="inputPassword" class="col-sm-2 col-form-label" style={{fontSize:19}}>Nationality</label>
    <div class="col-sm-5 cpyinclusiontxt" style={{marginTop:"1%"}}>
      
    {selectOptions.length == 0
                    ? "Loading Nationalities..."
                    : 

                    <select class="form-select"  name ="nationality" 
                    onChange = {this.handleChange}>
                      <option>{this.state.nationality}</option>
                    {selectOptions.map(fbb =>
                    
                      <option key={fbb.key} value={fbb.name}>{fbb.name}</option>
                      
                    )};
                  </select>
                    } 
                    
                   
    </div>
  </div>
  <div class="form-group row" style={{marginTop:"3%"}}>
                  <div class="col-sm-2"></div>
    <label for="inputPassword" class="col-sm-2 col-form-label" style={{fontSize:19,marginTop:"2%"}}>Ethnicity</label>
    <div class="col-sm-5" style={{marginTop:"2%"}}>
      
    <select
                  
                    class="form-select cpyinclusiontxt"
                    name ="ethnicity" 
                    onChange = {this.handleChange}
                  >
                    <option selected>{this.state.ethnicity}</option>
                    <option >Asian or Asian British</option>
                    <option >Indian</option>
                    <option >Pakistani</option>
                    <option >Bangladeshi</option>
                    <option >Chinese</option>
                    <option >Any Other Asian Background</option>
                    <option >Arab</option>
                    <option >Black, African, Caribbean or Black British</option>
                    <option>African</option>
                    <option >American</option>
                    <option >Caribbean</option>
                 
                    <option >Any other Black, African or Caribbean background</option>
                    <option >Hispanic or Latino</option>
                    <option >White</option>
                    <option >English, Welsh, Scottish, Northern Irish or British</option>
                  
                  
                    <option >Irish</option>
                  
                    <option >Gypsy or Irish Traveller</option>
                    <option >Any other White background</option>
                    <option >White and Black Carribean</option>
                    <option >White and Black American</option>
                    <option >White and Asian</option>
                    <option >Any Other Mixed / Multiple Ethnic Background</option>
                    <option >Native American or Alaskan Native</option>
                    <option >Other</option>
                    <option >Prefer Not to Say</option>
                  </select>
                    
                   
    </div>
  </div>
                
                    
  <div class="form-group row" style={{marginTop:"2%"}}>
                  <div class="col-sm-2"></div>
    <label for="inputPassword" class="col-sm-2 col-form-label" style={{fontSize:19,marginTop:"2%"}}>
      Religion
    </label>
    <div class="col-sm-5 cpyinclusiontxt">
      
    <select
                   
                    class="form-select"
                    name ="religion" 
                    onChange = {this.handleChange}
                  >
                    <option selected>{this.state.religion}</option>
                    <option >Christianity</option>
                    <option >Islam</option>
                    <option>Judaism</option>
                    <option >Budaism</option>
                    <option >Sikhism</option>
                    <option >No Religion</option>
                    <option >Prefer Not to Say</option>
                    <option >Other</option>
                  </select>
                   
    </div>
  </div>           
                    
                        
  <div class="form-group row" style={{marginTop:"3%"}}>
                  <div class="col-sm-2"></div>
    <label for="inputPassword" class="col-sm-2 col-form-label" style={{fontSize:19,marginTop:"2%"}}>
    Gender
    </label>
    <div class="col-sm-5 cpyinclusiontxt">
      
    <select
                    
                    class="form-select"
                    name ="gender" 
                    onChange = {this.handleChange}
                  >
                    <option selected>{this.state.gender}</option>
                    <option >Male</option>
                    <option >Non Binary</option>
                    <option >Transgender</option>
                    <option >Agender</option>
                    <option >Bigender</option>
                    <option >Gender Variant</option>
                    <option >Intersex</option>
                    <option >Prefer Not to Say</option>
                  </select>
                   
    </div>
  </div>           
                    
                             
  <div class="form-group row" style={{marginTop:"2%"}}>
                  <div class="col-sm-2"></div>
    <label for="inputPassword" class="col-sm-3 col-form-label" style={{fontSize:19,marginTop:"2%"}}>
    Free School Meal
    </label>
    <div class="col-sm-5" style={{marginTop:"3%"}}>
      
    <select
                    
                    class="form-select"
                    name ="freeschoolmeal" 
                    onChange = {this.handleChange}
                  >
                    <option selected>{this.state.freeschoolmeal}</option>
                    <option >Yes</option>
                    <option >No</option>
                    <option >Prefer Not to Say</option>
                  </select>
                   
    </div>
  </div>             
                         
   
                
              <div class="row mt-4">
                <div class="col-sm-2"></div>
                <div class="col-sm-8">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      onChange={ this.handleChecked }
                    />
                    <label class="form-check-label"  style={{fontSize:19}}>
                      School outside of UK
                    </label>
                  </div>
                  <div class="col-sm-2"></div>
                </div>
                <p>
                <div class="row mt-4">
                <div class="col-sm-2"></div>
                <div class="col-sm-8">
               
                  {txt=='checked'?null:
                  <>
                   <label
                    style={{
                      fontSize: 19,
                    marginTop:"3%"
                    }}
                  >
                    Name of Secondary School
                  </label>{" "}
                  <br />
                    
                <div className="mt-3">
                  {selectSchool.length == 0
                    ? "Loading School Name..."
                    : 

                    <select class="form-select"  name ="namesecschool" 
                    onChange = {this.handleChange}>
                      <option>{this.state.namesecschool}</option>
                    {selectSchool.map(fbb =>
                    
                      <option key={fbb.key} value={fbb.name}>{fbb.name}</option>
                      
                    )};
                  </select>
                    } 
                 </div></>
                  
                  }
                </div>
                <div class="col-sm-2"></div>
              </div>  
                  
               </p>
              </div>  
              <div class="row mt-4">
                <div class="col-sm-2"></div>
                <div class="col-sm-8">
                  <label
                    style={{
                      fontSize: 19,
                      fontWeight: "bold",
                    }}
                  >
                    Did any of your parents attend University?
                  </label>{" "}
                  <br />
                  <select
                    class="form-select mt-3"
                    name ="parentsatdUsity" 
                    onChange = {this.handleChange}
                  >
                   <option selected>{this.state.parentsatdUsity}</option>
                    <option >Yes</option>
                    <option >No</option>
                    <option >Prefer Not to Say</option>
                  </select>
                </div>
                <div class="col-sm-2"></div>
              </div>
              <div class="row mt-4">
                <div class="col-sm-2"></div>
                <div class="col-sm-8">
                  <label
                    style={{
                      fontSize: 19,
                      fontWeight: "bold",
                    }}
                  >
                    Occupation of your main household earner when aged about 14?
                  </label>{" "}
                  <br />
                  <select
                    class="form-select mt-3"
                    name ="occupation" 
                    onChange = {this.handleChange}
                  >
                    <option selected>{this.state.occupation}</option>
                    <option >Modern professional and traditional professional occupations</option>
                    <option >Senior, middle or junior managers or administrators</option>
                    <option >Clerical and intermediate occupations
Technical and craft occupations</option>
                    
                    <option >Long-term unemployed</option>
                    <option >Small business owners</option>
                    <option >Other</option>
                    <option >I prefer Not to Say</option>
                  </select>
                </div>
                <div class="col-sm-2"></div>
              </div>
              <div class="row mt-4">
                <div class="col-sm-2"></div>
                <div class="col-sm-8">
                  <label
                    style={{
                      fontSize: 19,
                      fontWeight: "bold",
                    }}
                  >
                    Type of school attended between the ages to 11 and 16?
                  </label>{" "}
                  <br />
                  <select
                    class="form-select mt-3"
                    name ="schoolages" 
                    onChange = {this.handleChange}
                  >
                    <option selected>{this.state.schoolages}</option>
                    <option >State-run or state-funded school</option>
                    <option >Independent or fee-paying school</option>
                    
                    <option >Independent or fee-paying school (including Bursary)</option>
                    <option >Attended school outside the UK</option>
                    <option >I don’t know</option>
                    <option >Prefer not to say</option>
                  </select>
                </div>
                <div class="col-sm-2"></div>
              </div>
              <span 
              style={{color:"rgb(0,147,69)",
              fontSize:15,marginTop:"5%",fontWeight:"bold",textAlign:"center"}}>{this.state.message}</span>

              <div class="row mt-4">
              <div class="col-sm-5"></div>
                <div class="col-sm-7">
                <button
                    style={{ backgroundColor: "rgb(0, 147, 69)",width:100 }}
                    className="btn btn-success"
                    type="submit"
                  >
                    Save
                  </button>
                </div>
                <div class="col-sm-1"></div>
              </div>
             
              </form>
           
            
           
           
          
              
             
              <div class="mt-4">
               
              </div>
            <div class="row mt-4">
              <div class="col-sm-2"></div>
              <div class="col-sm-8">
               
              </div>
              <div class="col-sm-2"></div>
            </div>
            <div class="mt-4">
              <div class="col-sm-4"></div>
              <div class="col-sm-7">
               
              </div>
              <div class="col-sm-1"></div>
            </div>
          </div>
        
          <div class="ft-modal-footer">
            <a class="ft-modal-close" data-dismiss="modal"
                     onClick={this.closeModal}>
              [&#10006;]{" "}
            </a>
          </div>
        </div>
      </div>
    )}
      
      <a
          
          className="btn-success btn-sm editbtncategory"
          href="#ft-companyinc-modal"
          style={{
            float:"right",
            width: 100,
            backgroundColor: "rgb(0, 147, 69)",
            fontFamily: "GothamLight",
            textAlign: "center",
            
          }}
          onClick={this.openModal}
        >
          Edit
        </a>
    </>
  );
}
}
