import React, { useState } from "react";
import "./navbar.css";
import { Link } from "react-router-dom";
import Login from "../../images/Login-36.png";
export default function NonRegisterModal() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div class="ft-modal" id="ft-reg-modal">
        <div class="ft-modal-content">
          <div class="ft-modal-header">
           
          </div>
          <div class="ft-modal-body">
            <div className="text-center mt-5">
        <img src={Login} />
        <div style={{color:"rgb(0, 147, 69)",marginTop:20,fontSize:20,
            fontFamily:"GothamLight",fontWeight:"bold"
    }}>To Join Our Community</div> <br />
    <div style={{fontSize:12,marginTop:-10,fontFamily:"GothamLight",fontWeight:"bold",}}>Sign Up to create an account Or Sign in to Join existing GigeconoMe Profile </div>
            </div>
           <div class="row mt-5">


           <div class="col-sm-2"></div>
              
              <div class="col-sm-2" style={{marginLeft:20}}>
              <Link to="/signup" target="_blank"
                  style={{ backgroundColor: "rgb(0, 147, 69)",
                   width: 150,fontFamily:"GothamLight" }}
                  className="btn btn-success"
                >
                  Sign Up
                </Link>
              </div>
              <div class="col-sm-2"></div>
              <div class="col-sm-2">
              <Link to="/signin" target="_blank"
                  style={{ backgroundColor: "rgb(0, 147, 69)",
                   width: 150,fontFamily:"GothamLight" }}
                  className="btn btn-success"
                >
                  Login
                </Link>
              </div>
              <div class="col-sm-2"></div>
            </div>
            <div class="mt-5">
              
            </div>
          </div>
          <div class="ft-modal-footer">
            <a class="ft-modal-close" href="#">
              &#10006;{" "}
            </a>
          </div>
        </div>
      </div>

      
      <a id="addatopic" className="btn btn-success"  href="#ft-reg-modal">Add a Topic</a>
    </>
  );
}
