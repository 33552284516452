import React, { Component } from "react";
import Logo from '../../images/logo.png';
import About_us from '../../images/AboutUs/aboutus-05.png';
import About_usfour from '../../images/AboutUs/aboutus-04.png';
import aboutusBanner from '../../images/AboutUs/aboutusbanner.jpg';
import About_usthree from '../../images/AboutUs/aboutus-03.png';
import { Link } from "react-router-dom";
import "./style.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Educatorfaq from "../Company/Educatorfaq";
const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth' // for smoothly scrolling
  });
}
class Aboutus extends Component {
  render() {
    return (
      <div>
        {/* ======= Header ======= */}
          <Header />
        {/* ======= Hero Section ======= */}
        <section
          className="d-flex align-items-center"
          style={{ clear: "both" }}
        >
          <style
            type="text/css"
            dangerouslySetInnerHTML={{
              __html:
                "\n  .centered {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n}\n",
            }}
          />
        
       
        </section>
        {/* End Hero */}
        <main id="main" style={{ backgroundImage:`url('${aboutusBanner}')`,
      backgroundRepeat:"no-repeat",backgroundSize:"contain"}} className="aboutusbannertop">
       
          {/* ======= Clients Section ======= */}
          <section id="clients" className="clients">
            <div className="container" data-aos="zoom-in">
            
              <div class="row text-center">
              <h1 className="aboutusheadingg">ABOUT US : GIGECONOME</h1>
            </div>
              <style
                type="text/css"
                dangerouslySetInnerHTML={{
                  __html:
                    "\n  .cnterbtn{\n    display: flex;\n  justify-content: center;\n  align-items: center;\n  padding-top: 4%;\n  }\n\n",
                }}
              />
               <div
                  className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0 aos-init aos-animate"
                  data-aos="fade-up"
                  data-aos-delay={100}
                >
              <img src={About_usthree} className="aboutusbannermscreen" />
                </div>
              <div className="row aboutuscontainer">
                <div
                  className="col-lg-6 order-1 order-lg-2 text-center aos-init aos-animate"
                  data-aos="fade-up"
                  data-aos-delay={200}
                >
                  <div className="row" style={{ marginTop: "4%", }}>
                    <div className="col-sm-12 aboutustxtmviewalign" style={{lineHeight:"20px"}}>
                    
                      <p className="aboutusmviewtxt" style={{ textAlign: "justify",fontFamily:"GothamLight",fontWeight:"bold" }}>
                      GigeconoMe is a debiased platform bridging the gap between academic learning and employability skills by aligning study and work. By connecting educators, students and employers via a one stop platform, we aim to create a clearer pathway to good first jobs by providing our students with the tools needed to succeed in tomorrow’s workplace.
                      </p>
                      <p className="aboutusmviewtxt" style={{ textAlign: "justify",fontFamily:"GothamLight",fontWeight:"bold"}}>
                      GigeconoMe offers the largest choices of fixed term, long term and project related opportunities including internships, work placements, Gig Opportunities, coursework related projects and real-life portfolio building tasks for our students to gain real industry and valuable hands-on experience, build network and get paid while still studying.
                      </p>
                      <p className="aboutusmviewtxt" style={{ textAlign: "justify",fontFamily:"GothamLight",fontWeight:"bold" }}>
                      We have created a platform with non-conventional selection process to foster diversity & inclusion and at the same time, leading the shift from traditional learning experiences towards a work integrated learning (WIL) model, where student talents are able to develop and demonstrate the wide range of employability skills through academic study, work experience, and extra-curricular activities.
                      </p>
                      <div className="cnterbtn">
                       
                          <Link to="/signup"
                           onClick={()=>scrollToTop()}
                          className="btn btn-success"
                          style={{
                            background: "rgb(0,147,69)",
                            border: "none",
                            borderRadius: "10px",
                            
                            letterSpacing: "1px",
                            fontFamily: "Gotham",
                           
                            color:"white"
                          }}
                          > Sign Up</Link>
                         
                   
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0 aos-init aos-animate"
                  data-aos="fade-up"
                  data-aos-delay={100}
                >
              <img src={About_usthree} className="aboutusbanner" />
                </div>
              </div>
              <hr style={{ border: "1px solid #009345", opacity: "inherit" }} />
            </div>
          </section>
          {/* End Clients Section */}
          {/* ======= About Section ======= */}
          <section id="clients" className="clients aboutuscontentpage">
            <div className="container" data-aos="fade-up">
              <style
                type="text/css"
                dangerouslySetInnerHTML={{
                  __html:
                    "\n    .boxxlr{\n      border: 0px solid green;\n      /*background: #dcdedd;*/\n      margin-right: 1%;\n    margin-bottom: 1%;\n    color: #000;\n    }\n\n\n.boxclrtitle {\nfont-size: 12px;font-weight: bold;\n}\n.boxxlr .boxclrtitle:hover {\nfont-size: 12px;font-weight: bold;\n}\n\n\n  ",
                }}
              />
              <div className="row no-gutters">
                <div className="content col-xl-12 d-flex align-items-stretch">
                  <div className="content aboutuscontentpage">
                    <h2
                      style={{
                       
                        letterSpacing: "2px",
                        textAlign: "center",
                        color: "#000",
                        fontWeight:"bold"
                        
                      }}
                    >
                      {" "}
                     Our Values{" "}
                    </h2>
                    <h6 style={{ textAlign: "center",fontFamily:"Gotham",color:"rgb(0,147,69)" }}>
                    What brings us together at GigeconoMe.{" "}
                    </h6>
                    <br />

                    <div className="row">
                    <div className="col-md-3 aboutusmainbannermscreen" id="about_two">
                        <img
                          style={{ width: "100%" }}
                          src={About_usfour} 
                        />
                      </div>
                      <div className="col-md-8 aboutususptext">
                        <div className="row">
                          <div className="col-md-5">
                            <h3 style={{ color: "#009147",fontFamily:"Gotham",fontSize:18 }}>Satisfaction</h3>
                            <p
                              style={{
                                textAlign: "revert",
                                
                                fontFamily:"GothamLight",
                                fontWeight:"bold"
                              }}
                            >
                             We adopt a relentless approach to delivering value to all our users through the use of innovative technology. 
                            </p>
                          </div>
                          <div className="col-md-1"></div>
                          <div className="col-md-5">
                            <h3 style={{ color: "#009147",fontFamily:"Gotham",fontSize:18 }}>Partnerships</h3>
                            <p
                              style={{
                                textAlign: "revert",
                               
                                fontFamily:"GothamLight",
                                fontWeight:"bold"
                              }}
                            >
                              We believe building strong relationships and collaboration is key to the development of tomorrow’s workforce.
                            </p>
                          </div>
                        </div>
                        <div className="row mt-4">
                          <div className="col-md-5">
                            <h3 style={{ color: "#009147", fontFamily:"Gotham",fontSize:18}}>Self-awareness</h3>
                            <p
                              style={{
                                textAlign: "revert",
                               
                                fontFamily:"GothamLight",
                                fontWeight:"bold"
                              }}
                            >
                              We embrace uncomfortable truths and facts about us as individuals to help expediate our development as a team.
                            </p>
                          </div>
                          <div className="col-md-1"></div>
                          <div className="col-md-5">
                            <h3 style={{ color: "#009147",fontFamily:"Gotham" ,fontSize:18}}>Inclusivity</h3>
                            <p
                              style={{
                                textAlign: "revert",
                                
                                fontFamily:"GothamLight",
                                fontWeight:"bold"
                              }}
                            >
                              We value equality, diversity and inclusion and committed to eliminating biased & discriminatory selection processes.
                            </p>
                          </div>
                        </div>
                        <div className="cnterbtn seebtn">
                         
                            <Link to="/companysearch"      onClick={()=>scrollToTop()}
                          className="btn btn-success"
                          style={{
                            background: "rgb(0,147,69)",
                            border: "none",
                            borderRadius: "10px",
                           
                            letterSpacing: "1px",
                            fontFamily: "Gotham",
                           
                            color:"white"
                          }}>
                          See Our Employers
                              </Link>
                           
                       
                        </div>
                      </div>
                      <div className="col-md-3 aboutusmainbanner" id="about_two">
                        <img
                          style={{ width: "100%" }}
                          src={About_usfour} 
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr style={{ border: "1px solid #009345",marginTop:"4%" }} />
            </div>
          </section>
         
          {/* ======= About Section ======= */}
          <section id="clients" className="clients aboutusfaqtop">
            <div className="container" data-aos="fade-up">
              <style
                type="text/css"
                dangerouslySetInnerHTML={{
                  __html:
                    "\n    .boxxlr{\n      border: 0px solid green;\n      /*background: #dcdedd;*/\n      margin-right: 1%;\n    margin-bottom: 1%;\n    color: #000;\n    }\n\n\n.boxclrtitle {\nfont-size: 12px;font-weight: bold;\n}\n.boxxlr .boxclrtitle:hover {\nfont-size: 12px;font-weight: bold;\n}\n\n\n  ",
                }}
              />
              <div className="row no-gutters">
                <div className="content col-xl-12 d-flex align-items-stretch">
                  <div className="content">
                    <h2
                      style={{
                       
                        letterSpacing: "2px",
                        textAlign: "center",
                        color: "#000",
                        fontWeight:"bold"
                       
                      }}
                    >
                      {" "}
                      Frequently Asked Questions{" "}
                    </h2>
                    <br />
                    <br />
                    <div className="mviewfaqpage">
                     <Educatorfaq/>
                    </div>
                    <div className="row mscreeneducatorfaq">
                   
                      <div className="col-md-5">
                        <h3 className="aboutusheading">How is GigeconoMe different from other marketplaces?</h3>
                        <p  style={{ marginTop:"1%",fontFamily:"GothamLight",fontWeight:"bold" }}>
                        GigeconoMe is a student talent marketplace that aligns study and work. Our focus is to match students to companies and Startups based on employability gaps as well as various preferences including Opportunity Types, role types, Industry, organisation size and skills. 
                        </p>
                       
                      </div>
                      <div className="col-md-1"></div>
                      <div className="col-md-5 aboutusfaqtwo">
                        <h3 className="aboutusheading">What do you mean by Employability skills?</h3>
                        <p  style={{  marginTop:"1%",fontFamily:"GothamLight",fontWeight:"bold" }}>
                        Employability Skills can be defined as the transferable/essential, personal qualities skills needed by an individual to make them ‘employable’. Employability skills are the essential skills, personal qualities and values that enable you to thrive in any workplace.
                        </p>
                       
                      </div>
                    </div>
                    <div className="row mt-5 mscreeneducatorfaq">
                  
                      <div className="col-md-5">
                        <h3 className="aboutusheading">What is your Debiased Selection Process? </h3>
                        <p style={{  marginTop:"5%",fontFamily:"GothamLight",fontWeight:"bold" }}>
                        Our non-conventional selection process fosters diversity & inclusion for all our students and ensures that all decisions are based purely on skills. Our debiased selection process ensures that the names and pictures of our student talents are not visible on their profile, therefore making the hiring process more equitable and inclusive.
                        </p>
                      </div>
                      <div className="col-md-1"></div>
                      <div className="col-md-5 aboutusfaqtwo">
                        <h3 className="aboutusheading">What are the Employability skills needed in today’s job market?</h3>
                        <p style={{  marginTop:"1%",fontFamily:"GothamLight",fontWeight:"bold" }}>
                        These are skills include Communication and interpersonal skills, Problem solving skills, using your initiative and being self-motivated, working under pressure and to deadlines, Organisational skills, Team working, Ability to learn and adapt, Numeracy, Valuing diversity and difference and Negotiation skills.
                        </p>
                      
                      </div>
                    </div>
                    <div className="row mt-5 mscreeneducatorfaq">
               
                      <div className="col-md-5">
                        <h3 className="aboutusheading">What are the 3 different kinds of opportunities that GigeconoMe offers to Students? </h3>
                        <p style={{  marginTop:"1%",fontFamily:"GothamLight",fontWeight:"bold" }}>
                        GigeconoMe offers our students 3 different work integrated learning (WIL) opportunities: <span style={{fontFamily:"Gotham"}}>Project Related Opportunities</span>  (Gig Opportunities, Coursework and study related projects and portfolio building Assignments) <span style={{fontFamily:"Gotham"}}>Fixed Term Opportunities</span> (Internship, Work Placement and Experience, Part Time Roles) and <span style={{fontFamily:"Gotham"}}>Long-Term Opportunities</span> (Graduate roles and schemes).
                        </p>
                      </div>
                      <div className="col-md-1"></div>
                      <div className="col-md-5 aboutusfaqtwo">
                        <h3 className="aboutusheading">Why do I need to complete my Profile?</h3>
                        <p style={{ marginTop:"5%",fontFamily:"GothamLight",fontWeight:"bold"}}>
                        To get the best out of GigeconoMe and for our matching to fully work, its important that you complete your profile correctly and accurately. GigeconoMe’s matching engine will only match you and alert you to those opportunities that matches your preference based on the information you provide on your student profile.
                        </p>
                      
                      </div>
                    </div>
                    <div className="row mt-5 mscreeneducatorfaq">
                 
                      <div className="col-md-5">
                        <h3 className="aboutusheading">Is GigeconoMe Free to Students? </h3>
                        <p style={{  marginTop:"1%",fontFamily:"GothamLight",fontWeight:"bold" }}>
                        Yes, GigeconoMe is free of charge to all students using the platform. We believe all our students should have access to all our features and functionalities at zero cost.
                        </p>
                      </div>
                      <div className="col-md-1"></div>
                      <div className="col-md-5 aboutusfaqtwo">
                        <h3 className="aboutusheading">Why do I need to sign GigeconoMe’s Code of Conduct?</h3>
                        <p style={{  marginTop:"1%",fontFamily:"GothamLight",fontWeight:"bold" }}>
                        Please email our dedicated student support team (admin@gigeconome.com ) or use our Contact US form to get your university or educator added.
                        </p>
                      
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="cnterbtn">
              
                  <Link to="/faq"
                   onClick={()=>scrollToTop()}
                   className="btn btn-success"
                   style={{
                     background: "rgb(0,147,69)",
                     border: "none",
                     borderRadius: "10px",
                     
                     letterSpacing: "1px",
                     fontFamily: "Gotham",
                    
                     color:"white"}}
                  
                  >More FAQs</Link>
                  
             
              </div>
            </div>
          </section>
        </main>
        {/* End #main */}
        {/* ======= Footer ======= */}
         {/* ======= Footer ======= */}
      <Footer />
      {/* End Footer */}
        
          
      </div>
    );
  }
}
export default Aboutus;
