import React, { useState } from "react";

import "./modal.css";

function close(){
  window.location.reload();
}
export default class AskforRecomm extends React.Component {
  constructor() {
    super();
    let comments = JSON.parse(sessionStorage.getItem("someData"));
   
   console.log(comments)
    this.state = {
      
    
     
     
      userid: comments,
     
      dropdownlist_input : "recommendation",
      addstudata:"recommendation",
      selectOptions : [],
      email:"",
      rtype:"",
      description:"",
      success:"",
      charLimit: 0,
      error:""
  
  }
  this.handleSubmit = this.handleSubmit.bind(this);
  this.cancel=this.cancel.bind(this)
}
cancel(){
  window.location.reload();
}

  handleSubmit = (event) => {
    event.preventDefault()
    if(this.state.description.length>=300){
     this.setState({error:"You have exceeded the maximum number of 300 characters in that field"})
    }
   else{
    const data = {

      userid: this.state.userid,
      addstudata: this.state.addstudata,
      email:this.state.email,
      rtype:this.state.rtype,
      description:this.state.description,
     
      
     

    }
  
    let BaseUrl = JSON.parse(sessionStorage.getItem("baseurl"));

      fetch( "https://www.gigeconome.com:5200/api/addstu_profiledata", {
        method: "POST",

        body: JSON.stringify(data), // data can be `string` or {object}!

        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())

        .catch((error) => console.error("Error:", error))

        .then((response) => {
          console.log(response)
         
          this.setState({
            success:response.message
          })
          window.location.reload();
        });
      }
  }




  componentDidMount(){
    this.getOptions()
}
async getOptions(){
  const data_one={dropdownlist_input:this.state.dropdownlist_input,
  
  }

  let BaseUrl = JSON.parse(sessionStorage.getItem('baseurl'));

  fetch( 'https://www.gigeconome.com:5200/api/getdropdownlist', { method: 'POST', 

   body: JSON.stringify(data_one), // data can be `string` or {object}!

   headers:{ 'Content-Type': 'application/json' } })

   .then(res => res.json())

   .catch(error => console.error('Error:', error))

   .then((json)=>{
    const data = json.result
    
    this.setState({selectOptions: data})
   })
  
   
 
 
 
  
  }
    handleChange = (e) => {
      this.setState({
        [e.target.name]: e.target.value,
       
      });
      
    };
render(){
const {selectOptions}=this.state;
const {success}=this.state;
const{error}=this.state;
  return (
    <>
      <div class="ft-modal" id="ft-ask-modal">
        <div class="ft-modal-content">
          <div class="ft-modal-header">
            <div class="header">
              <h5
                class="modal-title"
                id="exampleModalLabel"
                style={{
                  textAlign: "center",
                  fontSize: 25,
                  fontWeight: "bold",
                }}
              >
                Request Recommendation
              </h5>
              <hr style={{marginRight:"-4.2%",height:2,marginLeft:"-4%"}}/>
            </div>
          </div>
          <div class="ft-modal-body">
            <form onSubmit = {this.handleSubmit}>
            <div class="row mt-2">
              <div class="col-sm-1"></div>
              <div class="col-sm-10">
                <input
                  class="form-control mt-3"
                  placeholder="Email Address"
                  type="email"
                  name ="email" 
                  onChange = {this.handleChange}
                />
              </div>
              <div class="col-sm-1"></div>
            </div>
            <div class="row mt-3">
              <div class="col-sm-1"></div>
              <div class="col-sm-10">
              {selectOptions.length == 0
                    ? "Loading Nationalities..."
                    : 

                    <select class="form-select"    name ="rtype" 
                    onChange = {this.handleChange}>
                      <option>Recommendation Type</option>
                    {selectOptions.map(fbb =>
                    
                      <option key={fbb.key} value={fbb.id}>{fbb.name}</option>
                      
                    )};
                  </select>
                    } 
              </div>
              <div class="col-sm-1"></div>
            </div>
            <div class="row mt-4">
              <div class="col-sm-1"></div>
              <div class="col-sm-10">
                <div class="form-group">
                  <textarea
                    class="form-control"
                    name ="description" 
                    
                    onChange = {this.handleChange}
                    rows="4"
                  >
                   
                  </textarea>
                  <b style={{float:'right',color:'rgb(0,147,69)'}}>{this.state.charLimit + this.state.description.length} / 300</b>
                </div>
              </div>
              <div class="col-sm-1"></div>
            </div>
           
            <div class="mt-4 askforstubtn" >
              <button type="button" class="btn btn-secondary askcancelbig"
              onClick={this.cancel}
             >
                Cancel
              </button>
              <button
                type="button"
                class="btn btn-success sendbtnask"
       
            
              >
                Send
              </button>
            </div>
            <div style={{marginTop:"5%"}}>  <span style={{color:"rgb(0,147,69)",fontWeight:"bold",fontSize:12}}>
                    {success}
                  </span>
                  <span style={{color:'red',marginTop:"2%",
                  fontFamily:"Gotham",fontWeight:'bold',fontSize:12}}>{error}</span>
                  </div>
            </form>
          </div>
          <div class="ft-modal-footer">
            <a class="ft-modal-close" href="#">
              [&#10006;]{" "}
            </a>
          </div>
        </div>
      </div>

      <a
        style={{ color: "rgb(0, 147, 69)",fontFamily:"Gotham" }}
        href="#ft-ask-modal"
      >
        Ask for Recommendation
      </a>
    </>
  );
}
}