import React from "react";
import { Link } from "react-router-dom";
export default class MobileFaq extends React.Component{
    constructor(){
        super();
        this.state={
            question1:0,
            question2:0,
            question3:0,
            question4:0,
            question5:0,
            question6:0,
            question7:0,
            question8:0,
            question9:0,
            question10:0,
            question11:0,
            question12:0,
            question13:0,
            question14:0,
            question15:0,
            question16:0,
            question17:0,
            question18:0,
            question19:0,
            question20:0,
            question21:0,
            question22:0,
            question23:0,
            question24:0,
            question25:0,
            question26:0,
            question27:0,
            question28:0,
            question29:0,
            question30:0,
            question31:0,
            question32:0,
            question33:0,
            question34:0,
            question35:0,
            question36:0,
            question37:0,
            question38:0,
            question39:0,
            question40:0
        }
        this.handleQuestion1=this.handleQuestion1.bind(this)
        this.handleQuestion1close=this.handleQuestion1close.bind(this)
        this.handleQuestion2=this.handleQuestion2.bind(this)
        this.handleQuestion2close=this.handleQuestion2close.bind(this)
        this.handleQuestion3=this.handleQuestion3.bind(this)
        this.handleQuestion3close=this.handleQuestion3close.bind(this)
        this.handleQuestion4=this.handleQuestion4.bind(this)
        this.handleQuestion4close=this.handleQuestion4close.bind(this)
        this.handleQuestion5=this.handleQuestion5.bind(this)
        this.handleQuestion5close=this.handleQuestion5close.bind(this)
        this.handleQuestion6=this.handleQuestion6.bind(this)
        this.handleQuestion6close=this.handleQuestion6close.bind(this)
        this.handleQuestion7=this.handleQuestion7.bind(this)
        this.handleQuestion7close=this.handleQuestion7close.bind(this)
        this.handleQuestion8=this.handleQuestion8.bind(this)
        this.handleQuestion8close=this.handleQuestion8close.bind(this)
        this.handleQuestion9=this.handleQuestion9.bind(this)
        this.handleQuestion9close=this.handleQuestion9close.bind(this)
        this.handleQuestion10=this.handleQuestion10.bind(this)
        this.handleQuestion10close=this.handleQuestion10close.bind(this)
        this.handleQuestion11=this.handleQuestion11.bind(this)
        this.handleQuestion11close=this.handleQuestion11close.bind(this)
        this.handleQuestion12=this.handleQuestion12.bind(this)
        this.handleQuestion12close=this.handleQuestion12close.bind(this)
        this.handleQuestion13=this.handleQuestion13.bind(this)
        this.handleQuestion13close=this.handleQuestion13close.bind(this)
        this.handleQuestion14=this.handleQuestion14.bind(this)
        this.handleQuestion14close=this.handleQuestion14close.bind(this)
        this.handleQuestion15=this.handleQuestion15.bind(this)
        this.handleQuestion15close=this.handleQuestion15close.bind(this)
        this.handleQuestion16=this.handleQuestion16.bind(this)
        this.handleQuestion16close=this.handleQuestion16close.bind(this)
        this.handleQuestion17=this.handleQuestion17.bind(this)
        this.handleQuestion17close=this.handleQuestion17close.bind(this)
        this.handleQuestion18=this.handleQuestion18.bind(this)
        this.handleQuestion18close=this.handleQuestion18close.bind(this)
        this.handleQuestion19=this.handleQuestion19.bind(this)
        this.handleQuestion19close=this.handleQuestion19close.bind(this)
        this.handleQuestion20=this.handleQuestion20.bind(this)
        this.handleQuestion20close=this.handleQuestion20close.bind(this)
        this.handleQuestion21=this.handleQuestion21.bind(this)
        this.handleQuestion21close=this.handleQuestion21close.bind(this)
        this.handleQuestion22=this.handleQuestion22.bind(this)
        this.handleQuestion22close=this.handleQuestion22close.bind(this)
        this.handleQuestion23=this.handleQuestion23.bind(this)
        this.handleQuestion23close=this.handleQuestion23close.bind(this)
        this.handleQuestion24=this.handleQuestion24.bind(this)
        this.handleQuestion24close=this.handleQuestion24close.bind(this)
        this.handleQuestion25=this.handleQuestion25.bind(this)
        this.handleQuestion25close=this.handleQuestion25close.bind(this)
        this.handleQuestion26=this.handleQuestion26.bind(this)
        this.handleQuestion26close=this.handleQuestion26close.bind(this)
        this.handleQuestion27=this.handleQuestion27.bind(this)
        this.handleQuestion27close=this.handleQuestion27close.bind(this)
        this.handleQuestion28=this.handleQuestion28.bind(this)
        this.handleQuestion28close=this.handleQuestion28close.bind(this)
        this.handleQuestion29=this.handleQuestion29.bind(this)
        this.handleQuestion29close=this.handleQuestion29close.bind(this)
        this.handleQuestion30=this.handleQuestion30.bind(this)
        this.handleQuestion30close=this.handleQuestion30close.bind(this)
        this.handleQuestion31=this.handleQuestion31.bind(this)
        this.handleQuestion31close=this.handleQuestion31close.bind(this)
        this.handleQuestion32=this.handleQuestion32.bind(this)
        this.handleQuestion32close=this.handleQuestion32close.bind(this)
        this.handleQuestion33=this.handleQuestion33.bind(this)
        this.handleQuestion33close=this.handleQuestion33close.bind(this)
        this.handleQuestion34=this.handleQuestion34.bind(this)
        this.handleQuestion34close=this.handleQuestion34close.bind(this)
        this.handleQuestion35=this.handleQuestion35.bind(this)
        this.handleQuestion35close=this.handleQuestion35close.bind(this)
        this.handleQuestion36=this.handleQuestion36.bind(this)
        this.handleQuestion36close=this.handleQuestion36close.bind(this)
        this.handleQuestion37=this.handleQuestion37.bind(this)
        this.handleQuestion37close=this.handleQuestion37close.bind(this)
        this.handleQuestion38=this.handleQuestion38.bind(this)
        this.handleQuestion38close=this.handleQuestion38close.bind(this)
        this.handleQuestion39=this.handleQuestion39.bind(this)
        this.handleQuestion39close=this.handleQuestion39close.bind(this)
        this.handleQuestion40=this.handleQuestion40.bind(this)
        this.handleQuestion40close=this.handleQuestion40close.bind(this)
    }
    handleQuestion33(){
        this.setState({question33:1})
    }
    handleQuestion33close(){
        this.setState({question33:0})
    }
    handleQuestion34(){
        this.setState({question34:1})
    }
    handleQuestion34close(){
        this.setState({question34:0})
    }
    handleQuestion35(){
        this.setState({question35:1})
    }
    handleQuestion35close(){
        this.setState({question35:0})
    }
    handleQuestion36(){
        this.setState({question36:1})
    }
    handleQuestion36close(){
        this.setState({question36:0})
    }
    handleQuestion37(){
        this.setState({question37:1})
    }
    handleQuestion37close(){
        this.setState({question37:0})
    }
    handleQuestion38(){
        this.setState({question38:1})
    }
    handleQuestion38close(){
        this.setState({question38:0})
    }
    handleQuestion39(){
        this.setState({question39:1})
    }
    handleQuestion39close(){
        this.setState({question39:0})
    }
    handleQuestion40(){
        this.setState({question40:1})
    }
    handleQuestion40close(){
        this.setState({question40:0})
    }
    handleQuestion9(){
        this.setState({question9:1})
    }
    handleQuestion9close(){
        this.setState({question9:0})
    }
    handleQuestion10(){
        this.setState({question10:1})
    }
    handleQuestion10close(){
        this.setState({question10:0})
    }
    handleQuestion11(){
        this.setState({question11:1})
    }
    handleQuestion11close(){
        this.setState({question11:0})
    }
    handleQuestion12(){
        this.setState({question12:1})
    }
    handleQuestion12close(){
        this.setState({question12:0})
    }
    handleQuestion13(){
        this.setState({question13:1})
    }
    handleQuestion13close(){
        this.setState({question13:0})
    }
    handleQuestion14(){
        this.setState({question14:1})
    }
    handleQuestion14close(){
        this.setState({question14:0})
    }
    handleQuestion15(){
        this.setState({question15:1})
    }
    handleQuestion15close(){
        this.setState({question15:0})
    }
    handleQuestion16(){
        this.setState({question16:1})
    }
    handleQuestion16close(){
        this.setState({question16:0})
    }
    handleQuestion17(){
        this.setState({question17:1})
    }
    handleQuestion17close(){
        this.setState({question17:0})
    }
    handleQuestion18(){
        this.setState({question18:1})
    }
    handleQuestion18close(){
        this.setState({question18:0})
    }
    handleQuestion19(){
        this.setState({question19:1})
    }
    handleQuestion19close(){
        this.setState({question19:0})
    }
    handleQuestion20(){
        this.setState({question20:1})
    }
    handleQuestion20close(){
        this.setState({question20:0})
    }
    handleQuestion21(){
        this.setState({question21:1})
    }
    handleQuestion21close(){
        this.setState({question21:0})
    }
    handleQuestion22(){
        this.setState({question22:1})
    }
    handleQuestion22close(){
        this.setState({question22:0})
    }
    handleQuestion23(){
        this.setState({question23:1})
    }
    handleQuestion23close(){
        this.setState({question23:0})
    }
    handleQuestion24(){
        this.setState({question24:1})
    }
    handleQuestion24close(){
        this.setState({question24:0})
    }
    handleQuestion25(){
        this.setState({question25:1})
    }
    handleQuestion25close(){
        this.setState({question25:0})
    }
    handleQuestion26(){
        this.setState({question26:1})
    }
    handleQuestion26close(){
        this.setState({question26:0})
    }
    handleQuestion27(){
        this.setState({question27:1})
    }
    handleQuestion27close(){
        this.setState({question27:0})
    }
    handleQuestion28(){
        this.setState({question28:1})
    }
    handleQuestion28close(){
        this.setState({question28:0})
    }
    handleQuestion29(){
        this.setState({question29:1})
    }
    handleQuestion29close(){
        this.setState({question29:0})
    }
    handleQuestion30(){
        this.setState({question30:1})
    }
    handleQuestion30close(){
        this.setState({question30:0})
    }
    handleQuestion31(){
        this.setState({question31:1})
    }
    handleQuestion31close(){
        this.setState({question31:0})
    }
    handleQuestion32(){
        this.setState({question32:1})
    }
    handleQuestion32close(){
        this.setState({question32:0})
    }
    handleQuestion8(){
        this.setState({question8:1})
    }
    handleQuestion8close(){
        this.setState({question8:0})
    }
    handleQuestion7(){
        this.setState({question7:1})
    }
    handleQuestion7close(){
        this.setState({question7:0})
    }
    handleQuestion5(){
        this.setState({question5:1})
    }
    handleQuestion5close(){
        this.setState({question5:0})
    }
    handleQuestion6(){
        this.setState({question6:1})
    }
    handleQuestion6close(){
        this.setState({question6:0})
    }
    handleQuestion4(){
        this.setState({question4:1})
    }
    handleQuestion4close(){
        this.setState({question4:0})
    }
    handleQuestion3(){
        this.setState({question3:1})
    }
    handleQuestion3close(){
        this.setState({question3:0})
    }
    handleQuestion1(){
        this.setState({question1:1})
    }
    handleQuestion1close(){
        this.setState({question1:0})
    }
    handleQuestion2(){
        this.setState({question2:1})
    }
    handleQuestion2close(){
        this.setState({question2:0})
    }
    render(){
        return(<>
            <h1 style={{ textAlign: "center",
                            fontFamily:"Gotham",fontSize:20,marginTop:"5%",color:"rgb(0,147,69)"}}>
                              General
                            </h1>
                            <br/>
        {this.state.question1==0?<>
            <div style={{paddingRight:"3%"}} onClick={this.handleQuestion1}>
        <h3 className="educatorfaqpagetwo">How does GigeconoMe align study and work?
        <i className="fa fa-caret-down" style={{fontSize:20,float:"right"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>:<>
        <div style={{paddingRight:"3%"}} onClick={this.handleQuestion1close}>
        <h3 className="educatorfaqpagetwo">How does GigeconoMe align study and work?
        <i className="fa fa-caret-up" style={{fontSize:20,float:"right"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>}
       
       {this.state.question1==1?<>
       <div>
       <p style={{ marginTop:"-3%",fontFamily:"GothamLight",fontWeight:"bold",fontSize:12,marginLeft:"2%" }}>
                        	By connecting educators, students and employers via a one stop platform, we aim to create a clearer pathway to good first jobs by providing our students with the tools needed to succeed in tomorrow’s workplace.
                        </p>
       </div>
       </>:<></>}

       {this.state.question2==0?<>
            <div style={{paddingRight:"3%"}} onClick={this.handleQuestion2}>
        <h3 className="educatorfaqpagetwo">What is GigeconoMe?
        <i className="fa fa-caret-down" style={{fontSize:20,float:"right"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>:<>
        <div style={{paddingRight:"3%"}} onClick={this.handleQuestion2close}>
        <h3 className="educatorfaqpagetwo">What is GigeconoMe?
        <i className="fa fa-caret-up" style={{fontSize:20,float:"right"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>}
       
       {this.state.question2==1?<>
       <div>
       <p style={{ marginTop:"-3%",fontFamily:"GothamLight",fontWeight:"bold",fontSize:12,marginLeft:"2%" }}>
       GigeconoMe is a debiased student talent platform bridging the gap between academic learning and employability skills by aligning study and work.
                        </p>
       </div>
       </>:<></>}

       {this.state.question3==0?<>
            <div style={{paddingRight:"3%"}} onClick={this.handleQuestion3}>
        <h3 className="educatorfaqpagetwo">How long does it take to match to our student talents?
        <i className="fa fa-caret-down" style={{fontSize:20,float:"right",marginTop:"-5%"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>:<>
        <div style={{paddingRight:"3%"}} onClick={this.handleQuestion3close}>
        <h3 className="educatorfaqpagetwo">How long does it take to match to our student talents?
        <i className="fa fa-caret-up" style={{fontSize:20,float:"right",marginTop:"-5%"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>}
       
       {this.state.question3==1?<>
       <div>
       <p style={{ marginTop:"-3%",fontFamily:"GothamLight",fontWeight:"bold",fontSize:12,marginLeft:"2%" }}>
       Our intelligent matching and searching functionality ensure that employers can be matched to the right student talents in just a matter of minutes
                        </p>
       </div>
       </>:<></>}
       {this.state.question4==0?<>
            <div style={{paddingRight:"3%"}} onClick={this.handleQuestion4}>
        <h3 className="educatorfaqpagetwo">I change my e-mail address?
        <i className="fa fa-caret-down" style={{fontSize:20,float:"right"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>:<>
        <div style={{paddingRight:"3%"}} onClick={this.handleQuestion4close}>
        <h3 className="educatorfaqpagetwo">I change my e-mail address?
        <i className="fa fa-caret-up" style={{fontSize:20,float:"right"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>}
       
       {this.state.question4==1?<>
       <div>
       <p style={{ marginTop:"-3%",fontFamily:"GothamLight",fontWeight:"bold",fontSize:12,marginLeft:"2%" }}>
       Yes, you can change your email address. Please Log-in to your account, click the “Profile” tab and then “settings”, located in the right of your screen. Click on Email & Notification, add the new email address and your existing password. Scroll to the bottom and “Update Email Address”. 
                        </p>
       </div>
       </>:<></>}

       {this.state.question5==0?<>
            <div style={{paddingRight:"3%"}} onClick={this.handleQuestion5}>
        <h3 className="educatorfaqpagetwo">Can I use GigeconoMe on my phone?
        <i className="fa fa-caret-down" style={{fontSize:20,float:"right"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>:<>
        <div style={{paddingRight:"3%"}} onClick={this.handleQuestion5close}>
        <h3 className="educatorfaqpagetwo">Can I use GigeconoMe on my phone?
        <i className="fa fa-caret-up" style={{fontSize:20,float:"right"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>}
       
       {this.state.question5==1?<>
       <div>
       <p style={{ marginTop:"-3%",fontFamily:"GothamLight",fontWeight:"bold",fontSize:12,marginLeft:"2%" }}>
       The answer is Yes. Our website works on mobiles (Apple and Android) and tablets as well as desktops and laptops. We are currently working on the App Version of our platform and expected to launch at the end of 2022.  Please sign up to our newsletter to be the first to be notified. 
                        </p>
       </div>
       </>:<></>}

       {this.state.question6==0?<>
            <div style={{paddingRight:"3%"}} onClick={this.handleQuestion6}>
        <h3 className="educatorfaqpagetwo">What is GigeconoMe’s Privacy Policy?
        <i className="fa fa-caret-down" style={{fontSize:20,float:"right"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>:<>
        <div style={{paddingRight:"3%"}} onClick={this.handleQuestion6close}>
        <h3 className="educatorfaqpagetwo">What is GigeconoMe’s Privacy Policy?
        <i className="fa fa-caret-up" style={{fontSize:20,float:"right"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>}
       
       {this.state.question6==1?<>
       <div>
       <p style={{ marginTop:"-3%",fontFamily:"GothamLight",fontWeight:"bold",fontSize:12,marginLeft:"2%" }}>
       Our privacy policy including all our documentation can be at the bottom of our home page under “Legal”. Please click on the 
                              link
                               below to access our Privacy Policy. 
                        </p>
       </div>
       </>:<></>}


       {this.state.question7==0?<>
            <div style={{paddingRight:"3%"}} onClick={this.handleQuestion7}>
        <h3 className="educatorfaqpagetwo">	Can I create a two or more companies on GigeconoMe using Previously registered email address? 
        <i className="fa fa-caret-down" style={{fontSize:20,float:"right",marginTop:"-8%"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>:<>
        <div style={{paddingRight:"3%"}} onClick={this.handleQuestion7close}>
        <h3 className="educatorfaqpagetwo">Can I create a two or more companies on GigeconoMe using Previously registered email address? 
        <i className="fa fa-caret-up" style={{fontSize:20,float:"right",marginTop:"-8%"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>}
       
       {this.state.question7==1?<>
       <div>
       <p style={{ marginTop:"-3%",fontFamily:"GothamLight",fontWeight:"bold",fontSize:12,marginLeft:"2%" }}>
       The answer is No. GigeconoMe does not support multiple company profiles using previously registered email addresses.
                        </p>
       </div>
       </>:<></>}

       {this.state.question8==0?<>
            <div style={{paddingRight:"3%"}} onClick={this.handleQuestion8}>
        <h3 className="educatorfaqpagethree" >	Is GigeconoMe currently available in my Country?
        <i className="fa fa-caret-down" style={{fontSize:20,float:"right",marginTop:"-4%"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>:<>
        <div style={{paddingRight:"3%"}} onClick={this.handleQuestion8close}>
        <h3 className="educatorfaqpagethree">Is GigeconoMe currently available in my Country? 
        <i className="fa fa-caret-up" style={{fontSize:20,float:"right",marginTop:"-4%"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>}
       
       {this.state.question8==1?<>
       <div>
       <p style={{ marginTop:"-3%",fontFamily:"GothamLight",fontWeight:"bold",fontSize:12,marginLeft:"2%" }}>
       GigeconoMe is still in Beta therefore we only currently support companies, Startups, educators and student talents in 2 international markets only (United Kingdom and Nigeria). Please sign up to our newsletter to be the first to be notified or drop us an email at
                                &nbsp;<span style={{fontFamily:"Gotham",textDecoration:"underline",color:"rgb(0,147,69)"}}>admin@gigeconome.com</span> &nbsp;  to get you added to our waitlist for when we launch in your country. 
                        </p>
       </div>
       </>:<></>}
       <hr className="generalhr" />


       {/* Student Section Faq starts */}
       <h1 style={{ textAlign: "center",
                            fontFamily:"Gotham",fontSize:20,marginTop:"5%",color:"rgb(0,147,69)"}}>
                              Students
                            </h1>
                            <br/>
        {this.state.question8==0?<>
            <div style={{paddingRight:"3%"}} onClick={this.handleQuestion8}>
        <h3 className="educatorfaqpagetwo">Is GigeconoMe Free to Students?
        <i className="fa fa-caret-down" style={{fontSize:20,float:"right"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>:<>
        <div style={{paddingRight:"3%"}} onClick={this.handleQuestion8close}>
        <h3 className="educatorfaqpagetwo">Is GigeconoMe Free to Students?
        <i className="fa fa-caret-up" style={{fontSize:20,float:"right"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>}
       
       {this.state.question8==1?<>
       <div>
       <p style={{ marginTop:"-3%",fontFamily:"GothamLight",fontWeight:"bold",fontSize:12,marginLeft:"2%" }}>
       Yes, GigeconoMe is free of charge to all students using the platform. We believe all our students should have access to all our features and functionalities at zero cost. 
                        </p>
       </div>
       </>:<></>}

       {this.state.question9==0?<>
            <div style={{paddingRight:"3%"}} onClick={this.handleQuestion9}>
        <h3 className="educatorfaqpagetwo">How long does it take to Create a Student Profile?
        <i className="fa fa-caret-down" style={{fontSize:20,float:"right",marginTop:"-4%"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>:<>
        <div style={{paddingRight:"3%"}} onClick={this.handleQuestion9close}>
        <h3 className="educatorfaqpagetwo">How long does it take to Create a Student Profile?
        <i className="fa fa-caret-up" style={{fontSize:20,float:"right",marginTop:"-4%"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>}
       
       {this.state.question9==1?<>
       <div>
       <p style={{ marginTop:"-3%",fontFamily:"GothamLight",fontWeight:"bold",fontSize:12,marginLeft:"2%" }}>
       It takes a matter of minutes to create a student profile. To get the best out of GigeconoMe and for our matching to fully work, it’s important that you complete your profile correctly and accurately 
                        </p>
       </div>
       </>:<></>}

       {this.state.question10==0?<>
            <div style={{paddingRight:"3%"}} onClick={this.handleQuestion10}>
        <h3 className="educatorfaqpagetwo">Why do I need to complete my Profile?
        <i className="fa fa-caret-down" style={{fontSize:20,float:"right"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>:<>
        <div style={{paddingRight:"3%"}} onClick={this.handleQuestion10close}>
        <h3 className="educatorfaqpagetwo">Why do I need to complete my Profile?
        <i className="fa fa-caret-up" style={{fontSize:20,float:"right"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>}
       
       {this.state.question10==1?<>
       <div>
       <p style={{ marginTop:"-3%",fontFamily:"GothamLight",fontWeight:"bold",fontSize:12,marginLeft:"2%" }}>
       To get the best out of GigeconoMe and for our matching to fully work, it’s important that you complete your profile correctly and accurately. GigeconoMe’s matching engine will only match you and alert you to those opportunities that matches your preference based on the information you provide on your student profile. 
                        </p>
       </div>
       </>:<></>}
       {this.state.question11==0?<>
            <div style={{paddingRight:"3%"}} onClick={this.handleQuestion11}>
        <h3 className="educatorfaqpagetwo">Do I have to choose one opportunity to be considered for or can I select more than one type of Opportunity?
        <i className="fa fa-caret-down" style={{fontSize:20,float:"right",marginTop:"-10%"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>:<>
        <div style={{paddingRight:"3%"}} onClick={this.handleQuestion11close}>
        <h3 className="educatorfaqpagetwo">Do I have to choose one opportunity to be considered for or can I select more than one type of Opportunity?
        <i className="fa fa-caret-up" style={{fontSize:20,float:"right",marginTop:"-10%"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>}
       
       {this.state.question11==1?<>
       <div>
       <p style={{ marginTop:"-3%",fontFamily:"GothamLight",fontWeight:"bold",fontSize:12,marginLeft:"2%" }}>
       Our platform has been designed to allow our students talents to select all different kind of opportunities type we offer. This provides flexibility to gain experience and build your network in ways that suits your studies and availability.
                        </p>
       </div>
       </>:<></>}

       {this.state.question12==0?<>
            <div style={{paddingRight:"3%"}} onClick={this.handleQuestion12}>
        <h3 className="educatorfaqpagetwo">My university or Educator is not listed, how do I get it added?
        <i className="fa fa-caret-down" style={{fontSize:20,float:"right",marginTop:"-5%"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>:<>
        <div style={{paddingRight:"3%"}} onClick={this.handleQuestion12close}>
        <h3 className="educatorfaqpagetwo">My university or Educator is not listed, how do I get it added?
        <i className="fa fa-caret-up" style={{fontSize:20,float:"right",marginTop:"-5%"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>}
       
       {this.state.question12==1?<>
       <div>
       <p style={{ marginTop:"-3%",fontFamily:"GothamLight",fontWeight:"bold",fontSize:12,marginLeft:"2%" }}>
       Please email our dedicated student support team &nbsp;(<span  style={{fontFamily:"Gotham",color:"rgb(0,147,69)"}}>admin@gigeconome.com</span>) or use our Contact US form to get your university or educator added. 
                        </p>
       </div>
       </>:<></>}

       {this.state.question13==0?<>
            <div style={{paddingRight:"3%"}} onClick={this.handleQuestion13}>
        <h3 className="educatorfaqpagetwo">How does GigeconoMe align study and work?
        <i className="fa fa-caret-down" style={{fontSize:20,float:"right"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>:<>
        <div style={{paddingRight:"3%"}} onClick={this.handleQuestion13close}>
        <h3 className="educatorfaqpagetwo">How does GigeconoMe align study and work?
        <i className="fa fa-caret-up" style={{fontSize:20,float:"right"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>}
       
       {this.state.question13==1?<>
       <div>
       <p style={{ marginTop:"-3%",fontFamily:"GothamLight",fontWeight:"bold",fontSize:12,marginLeft:"2%" }}>
       By connecting educators, students and employers via a one stop platform, we aim to create a clearer pathway to good first jobs by providing our students with the tools needed to highlight strengths and weaknesses as well as real industry and valuable hands-on experience.  
                        </p>
       </div>
       </>:<></>}


       {this.state.question14==0?<>
            <div style={{paddingRight:"3%"}} onClick={this.handleQuestion14}>
        <h3 className="educatorfaqpagetwo">	What do you mean by Employability skills? 
        <i className="fa fa-caret-down" style={{fontSize:20,float:"right"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>:<>
        <div style={{paddingRight:"3%"}} onClick={this.handleQuestion14close}>
        <h3 className="educatorfaqpagetwo">What do you mean by Employability skills? 
        <i className="fa fa-caret-up" style={{fontSize:20,float:"right"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>}
       
       {this.state.question14==1?<>
       <div>
       <p style={{ marginTop:"-3%",fontFamily:"GothamLight",fontWeight:"bold",fontSize:12,marginLeft:"2%" }}>
       Employability Skills can be defined as the transferable/essential, personal qualities skills needed by an individual to make them ‘employable’. Employability skills are the essential skills, personal qualities and values that enable you to thrive in any workplace.
                        </p>
       </div>
       </>:<></>}

       {this.state.question15==0?<>
            <div style={{paddingRight:"3%"}} onClick={this.handleQuestion15}>
        <h3 className="educatorfaqpagethree" >Why Should I make my profile Public?
        <i className="fa fa-caret-down" style={{fontSize:20,float:"right"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>:<>
        <div style={{paddingRight:"3%"}} onClick={this.handleQuestion15close}>
        <h3 className="educatorfaqpagethree">Why Should I make my profile Public?
        <i className="fa fa-caret-up" style={{fontSize:20,float:"right"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>}
       
       {this.state.question15==1?<>
       <div>
       <p style={{ marginTop:"-3%",fontFamily:"GothamLight",fontWeight:"bold",fontSize:12,marginLeft:"2%" }}>
       Making your profile public means companies and Startups can find you. If your profile is public, you’ll show up whether companies and start-ups use our manual search functionality or matching tool, when they’re recruiting for projected related opportunities or Fixed term opportunities. 
                        </p>
       </div>
       </>:<></>}
       <hr className="generalhr" />

       {/* Student section faq ends*/}


 {/* Companies Section Faq starts */}
 <h1 style={{ textAlign: "center",
                            fontFamily:"Gotham",fontSize:20,marginTop:"5%",color:"rgb(0,147,69)"}}>
                              Companies
                            </h1>
                            <br/>
        {this.state.question16==0?<>
            <div style={{paddingRight:"3%"}} onClick={this.handleQuestion16}>
        <h3 className="educatorfaqpagetwo">I created an Opportunity or Project, what happens Next?
        <i className="fa fa-caret-down" style={{fontSize:20,float:"right"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>:<>
        <div style={{paddingRight:"3%"}} onClick={this.handleQuestion16close}>
        <h3 className="educatorfaqpagetwo">I created an Opportunity or Project, what happens Next?
        <i className="fa fa-caret-up" style={{fontSize:20,float:"right"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>}
       
       {this.state.question16==1?<>
       <div>
       <p style={{ marginTop:"-3%",fontFamily:"GothamLight",fontWeight:"bold",fontSize:12,marginLeft:"2%" }}>
       Once you have created a project or opportunity, our intelligent matching gets to work to find the right student talents who are a good fit for your project or opportunity. Those student talents matched as a good fit, then create a custom proposal to explain why they are the best student talents for the project or opportunity. 
                        </p>
       </div>
       </>:<></>}

       {this.state.question17==0?<>
            <div style={{paddingRight:"3%"}} onClick={this.handleQuestion17}>
        <h3 className="educatorfaqpagetwo">	Why can’t I see the real profile pictures or the name of the Student Talents during Selection Process?
        <i className="fa fa-caret-down" style={{fontSize:20,float:"right",marginTop:"-4%"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>:<>
        <div style={{paddingRight:"3%"}} onClick={this.handleQuestion17close}>
        <h3 className="educatorfaqpagetwo">	Why can’t I see the real profile pictures or the name of the Student Talents during Selection Process?
        <i className="fa fa-caret-up" style={{fontSize:20,float:"right",marginTop:"-4%"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>}
       
       {this.state.question17==1?<>
       <div>
       <p style={{ marginTop:"-3%",fontFamily:"GothamLight",fontWeight:"bold",fontSize:12,marginLeft:"2%" }}>
       We pride ourselves on being inclusive, therefore to foster diversity and inclusion, we have chosen a non-conventional selection process to ensure that all selection decisions are based purely on skills and rather than employers’ misconceptions of ‘ideal’ applicants and outdated selection practices. 
                        </p>
       </div>
       </>:<></>}

       {this.state.question18==0?<>
            <div style={{paddingRight:"3%"}} onClick={this.handleQuestion18}>
        <h3 className="educatorfaqpagetwo">How do I create a project?
        <i className="fa fa-caret-down" style={{fontSize:20,float:"right"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>:<>
        <div style={{paddingRight:"3%"}} onClick={this.handleQuestion18close}>
        <h3 className="educatorfaqpagetwo">How do I create a project?
        <i className="fa fa-caret-up" style={{fontSize:20,float:"right"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>}
       
       {this.state.question18==1?<>
       <div>
       <p style={{ marginTop:"-3%",fontFamily:"GothamLight",fontWeight:"bold",fontSize:12,marginLeft:"2%" }}>
       It’s really simple. Click the post a project button at the top of your screen, select the category which your project falls into and then provide the relevant information so we can match you with students. 
                        </p>
       </div>
       </>:<></>}
       {this.state.question19==0?<>
            <div style={{paddingRight:"3%"}} onClick={this.handleQuestion19}>
        <h3 className="educatorfaqpagetwo">What are the Universities and Educators on GigeconoMe?
        <i className="fa fa-caret-down" style={{fontSize:20,float:"right",marginTop:"-4%"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>:<>
        <div style={{paddingRight:"3%"}} onClick={this.handleQuestion19close}>
        <h3 className="educatorfaqpagetwo">What are the Universities and Educators on GigeconoMe?
        <i className="fa fa-caret-up" style={{fontSize:20,float:"right",marginTop:"-10%"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>}
       
       {this.state.question19==1?<>
       <div>
       <p style={{ marginTop:"-3%",fontFamily:"GothamLight",fontWeight:"bold",fontSize:12,marginLeft:"2%" }}>
       Please click on 
                                &nbsp;<Link  to="/companysearch" style={{fontFamily:"Gotham",textDecoration:"underline"}}>Our Educators link</Link>
                                , to see the list and Profile of all Universities currently using GigeconoMe, to help their students bridge the gap between academic learning and employability skills. 
                        </p>
       </div>
       </>:<></>}

       {this.state.question20==0?<>
            <div style={{paddingRight:"3%"}} onClick={this.handleQuestion20}>
        <h3 className="educatorfaqpagetwo">Can I contact student talents off of the GigeconoMe platform?
        <i className="fa fa-caret-down" style={{fontSize:20,float:"right",marginTop:"-5%"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>:<>
        <div style={{paddingRight:"3%"}} onClick={this.handleQuestion20close}>
        <h3 className="educatorfaqpagetwo">Can I contact student talents off of the GigeconoMe platform?
        <i className="fa fa-caret-up" style={{fontSize:20,float:"right",marginTop:"-5%"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>}
       
       {this.state.question20==1?<>
       <div>
       <p style={{ marginTop:"-3%",fontFamily:"GothamLight",fontWeight:"bold",fontSize:12,marginLeft:"2%" }}>
       The first contact with our student talents needs to be on GigeconoMe - after that, the selected candidates will continue to go through your normal recruiting process. If you have questions about a student’s background, work history, experience, etc., please use our internal messaging system that’s embedded into every profile to contact the relevant student talent. 
                        </p>
       </div>
       </>:<></>}

       {this.state.question21==0?<>
            <div style={{paddingRight:"3%"}} onClick={this.handleQuestion21}>
        <h3 className="educatorfaqpagetwo">What is the difference between “Post a Project or Opportunity” and the Search Functionality process?
        <i className="fa fa-caret-down" style={{fontSize:20,float:"right"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>:<>
        <div style={{paddingRight:"3%"}} onClick={this.handleQuestion21close}>
        <h3 className="educatorfaqpagetwo">What is the difference between “Post a Project or Opportunity” and the Search Functionality process?
        <i className="fa fa-caret-up" style={{fontSize:20,float:"right"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>}
       
       {this.state.question21==1?<>
       <div>
       <p style={{ marginTop:"-3%",fontFamily:"GothamLight",fontWeight:"bold",fontSize:12,marginLeft:"2%" }}>
       The Search functionality allows you to search our student talent marketplace using generic keywords, browse and filter the results based on your choice of filter and directly contact our student talents whereby our Post a project or Opportunity uses our Intelligent matching and searching functionality to unlock talents & ensure that you match, screen and contact right students in minutes without the need to manually search and browse.
                        </p>
       </div>
       </>:<></>}


       {this.state.question22==0?<>
            <div style={{paddingRight:"3%"}} onClick={this.handleQuestion22}>
        <h3 className="educatorfaqpagetwo">	Why do I need to sign up as an employee on GigeconoMe? 
        <i className="fa fa-caret-down" style={{fontSize:20,float:"right"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>:<>
        <div style={{paddingRight:"3%"}} onClick={this.handleQuestion22close}>
        <h3 className="educatorfaqpagetwo">Why do I need to sign up as an employee on GigeconoMe?
        <i className="fa fa-caret-up" style={{fontSize:20,float:"right"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>}
       
       {this.state.question22==1?<>
       <div>
       <p style={{ marginTop:"-3%",fontFamily:"GothamLight",fontWeight:"bold",fontSize:12,marginLeft:"2%" }}>
       To ensure that you are able to get in contact with our student talents and post opportunities on our platform including your Startup or company information, we ask for some basic information which allows us to identify you on your next visits and to send you notifications when you receive a response or a quote from our student talents. 
                        </p>
       </div>
       </>:<></>}

       {this.state.question23==0?<>
            <div style={{paddingRight:"3%"}} onClick={this.handleQuestion23}>
        <h3 className="educatorfaqpagethree" >I am unable to invite my Team Members to GigeconoMe?
        <i className="fa fa-caret-down" style={{fontSize:20,float:"right"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>:<>
        <div style={{paddingRight:"3%"}} onClick={this.handleQuestion23close}>
        <h3 className="educatorfaqpagethree">I am unable to invite my Team Members to GigeconoMe?
        <i className="fa fa-caret-up" style={{fontSize:20,float:"right"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>}
       
       {this.state.question23==1?<>
       <div>
       <p style={{ marginTop:"-3%",fontFamily:"GothamLight",fontWeight:"bold",fontSize:12,marginLeft:"2%" }}>
       Yes, this functionality to invite team members is not currently part of our Beta Phase. However, this functionality is scheduled to be launched in Q4, 2022. Please be aware, when an employee signs up and they have selected an existing company and choose the same department as you, they are automatically linked to your profile as a team member. 
                        </p>
       </div>
       </>:<></>}
       <hr className="generalhr" />

       {/* Companies section faq ends*/}



       {/* Startups Section Faq starts */}
 <h1 style={{ textAlign: "center",
                            fontFamily:"Gotham",fontSize:20,marginTop:"5%",color:"rgb(0,147,69)"}}>
                              Startups
                            </h1>
                            <br/>
        {this.state.question24==0?<>
            <div style={{paddingRight:"3%"}} onClick={this.handleQuestion24}>
        <h3 className="educatorfaqpagetwo">I am a Startup founder, can I use GigeconoMe?
        <i className="fa fa-caret-down" style={{fontSize:20,float:"right"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>:<>
        <div style={{paddingRight:"3%"}} onClick={this.handleQuestion24close}>
        <h3 className="educatorfaqpagetwo">I am a Startup founder, can I use GigeconoMe?
        <i className="fa fa-caret-up" style={{fontSize:20,float:"right"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>}
       
       {this.state.question24==1?<>
       <div>
       <p style={{ marginTop:"-3%",fontFamily:"GothamLight",fontWeight:"bold",fontSize:12,marginLeft:"2%" }}>
       The simple answer is Yes. GigeconoMe has been developed to support founders on their Startup growth journey as well as to provide student talent with the opportunity develop various employability skills while contributing to the growth of the next batch of unicorns. As a Startup ourselves, we understand Startup companies are very dynamic and complex to build and founders require various kind of resources to achieve success and we are happy to support the development and help attract the best student talents. 
                        </p>
       </div>
       </>:<></>}

       {this.state.question25==0?<>
            <div style={{paddingRight:"3%"}} onClick={this.handleQuestion25}>
        <h3 className="educatorfaqpagetwo">	How do I get matched to Student Talents? 
        <i className="fa fa-caret-down" style={{fontSize:20,float:"right"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>:<>
        <div style={{paddingRight:"3%"}} onClick={this.handleQuestion17close}>
        <h3 className="educatorfaqpagetwo">	How do I get matched to Student Talents? 
        <i className="fa fa-caret-up" style={{fontSize:20,float:"right"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>}
       
       {this.state.question25==1?<>
       <div>
       <p style={{ marginTop:"-3%",fontFamily:"GothamLight",fontWeight:"bold",fontSize:12,marginLeft:"2%" }}>
       To start getting matched to student Talents, Click on Post a Project on the top right-hand corner on the Homepage or Get Matched to Students on the banner on the Homepage. Select which of our 2 types of work integrated learning (WIL) opportunities your opportunity falls into. Then complete the details of your project or opportunities in the form. Once posted, your project undergoes some automated checks to make sure that it meets GigeconoMe policies before being sent to all student talents that matches your preferences and in email notifications. 
                        </p>
       </div>
       </>:<></>}

       {this.state.question26==0?<>
            <div style={{paddingRight:"3%"}} onClick={this.handleQuestion26}>
        <h3 className="educatorfaqpagetwo">How do I get my Startup added as a company on GigeconoMe?
        <i className="fa fa-caret-down" style={{fontSize:20,float:"right"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>:<>
        <div style={{paddingRight:"3%"}} onClick={this.handleQuestion26close}>
        <h3 className="educatorfaqpagetwo">How do I get my Startup added as a company on GigeconoMe?
        <i className="fa fa-caret-up" style={{fontSize:20,float:"right"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>}
       
       {this.state.question26==1?<>
       <div>
       <p style={{ marginTop:"-3%",fontFamily:"GothamLight",fontWeight:"bold",fontSize:12,marginLeft:"2%" }}>
       Once you have created an opportunity, please log in and click on your profile to access your dashboard. Click on Opportunities and under “All Opportunities”, should be the list of all the projects and opportunities posted.
                        </p>
       </div>
       </>:<></>}
       {this.state.question27==0?<>
            <div style={{paddingRight:"3%"}} onClick={this.handleQuestion27}>
        <h3 className="educatorfaqpagetwo">How do I know my Job has been posted Successfully?
        <i className="fa fa-caret-down" style={{fontSize:20,float:"right",marginTop:"-4%"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>:<>
        <div style={{paddingRight:"3%"}} onClick={this.handleQuestion27close}>
        <h3 className="educatorfaqpagetwo">How do I know my Job has been posted Successfully?
        <i className="fa fa-caret-up" style={{fontSize:20,float:"right",marginTop:"-10%"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>}
       
       {this.state.question27==1?<>
       <div>
       <p style={{ marginTop:"-3%",fontFamily:"GothamLight",fontWeight:"bold",fontSize:12,marginLeft:"2%" }}>
       Once you have created an opportunity, please log in and click on your profile to access your dashboard. Click on Opportunities and under “All Opportunities”, should be the list of all the projects and opportunities posted. 
                        </p>
       </div>
       </>:<></>}

       {this.state.question28==0?<>
            <div style={{paddingRight:"3%"}} onClick={this.handleQuestion28}>
        <h3 className="educatorfaqpagetwo">Why do I need to sign up as an employee on GigeconoMe?
        <i className="fa fa-caret-down" style={{fontSize:20,float:"right",marginTop:"-5%"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>:<>
        <div style={{paddingRight:"3%"}} onClick={this.handleQuestion28close}>
        <h3 className="educatorfaqpagetwo">Why do I need to sign up as an employee on GigeconoMe?
        <i className="fa fa-caret-up" style={{fontSize:20,float:"right",marginTop:"-5%"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>}
       
       {this.state.question28==1?<>
       <div>
       <p style={{ marginTop:"-3%",fontFamily:"GothamLight",fontWeight:"bold",fontSize:12,marginLeft:"2%" }}>
       To ensure that you are able to get in contact with our student talents and post opportunities on our platform including your Startup or company information, we ask for some basic information which allows us to identify you on your next visits and to send you notifications when you receive a response or a quote from our student talents. 
                        </p>
       </div>
       </>:<></>}

       {this.state.question29==0?<>
            <div style={{paddingRight:"3%"}} onClick={this.handleQuestion29}>
        <h3 className="educatorfaqpagetwo">I am unable to invite my Team Members to GigeconoMe?
        <i className="fa fa-caret-down" style={{fontSize:20,float:"right"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>:<>
        <div style={{paddingRight:"3%"}} onClick={this.handleQuestion29close}>
        <h3 className="educatorfaqpagetwo">I am unable to invite my Team Members to GigeconoMe?
        <i className="fa fa-caret-up" style={{fontSize:20,float:"right"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>}
       
       {this.state.question29==1?<>
       <div>
       <p style={{ marginTop:"-3%",fontFamily:"GothamLight",fontWeight:"bold",fontSize:12,marginLeft:"2%" }}>
       Yes, this functionality to invite team members is not currently part of our Beta Phase. However, this functionality is scheduled to be launched in Q4, 2022. Please be aware, when an employee signs up and they have selected an existing company and choose the same department as you, they are automatically linked to your profile as a team member. 
                        </p>
       </div>
       </>:<></>}


       {this.state.question30==0?<>
            <div style={{paddingRight:"3%"}} onClick={this.handleQuestion30}>
        <h3 className="educatorfaqpagetwo">	As a Start-up founder; do I need to pay to Register on GigeconoMe? 
        <i className="fa fa-caret-down" style={{fontSize:20,float:"right"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>:<>
        <div style={{paddingRight:"3%"}} onClick={this.handleQuestion30close}>
        <h3 className="educatorfaqpagetwo">As a Start-up founder; do I need to pay to Register on GigeconoMe?
        <i className="fa fa-caret-up" style={{fontSize:20,float:"right"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>}
       
       {this.state.question30==1?<>
       <div>
       <p style={{ marginTop:"-3%",fontFamily:"GothamLight",fontWeight:"bold",fontSize:12,marginLeft:"2%" }}>
       The Simple answer is No. To register and sign up as a start-up founder on GigeconoMe is free of charge. 
                        </p>
       </div>
       </>:<></>}

       {this.state.question31==0?<>
            <div style={{paddingRight:"3%"}} onClick={this.handleQuestion31}>
        <h3 className="educatorfaqpagethree" >How does the Invite to Apply Works?
        <i className="fa fa-caret-down" style={{fontSize:20,float:"right"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>:<>
        <div style={{paddingRight:"3%"}} onClick={this.handleQuestion31close}>
        <h3 className="educatorfaqpagethree">How does the Invite to Apply Works?
        <i className="fa fa-caret-up" style={{fontSize:20,float:"right"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>}
       
       {this.state.question31==1?<>
       <div>
       <p style={{ marginTop:"-3%",fontFamily:"GothamLight",fontWeight:"bold",fontSize:12,marginLeft:"2%" }}>
       Our “Invite to Apply” button allows to contact upto 10 student talents directly to receive custom proposals, if you do not want to use our matching engine. 
                        </p>
       </div>
       </>:<></>}
       <hr className="generalhr" />

       {/* Startups section faq ends*/}


       

       {/* Educators Section Faq starts */}
 <h1 style={{ textAlign: "center",
                            fontFamily:"Gotham",fontSize:20,marginTop:"5%",color:"rgb(0,147,69)"}}>
                              Educators
                            </h1>
                            <br/>
        {this.state.question32==0?<>
            <div style={{paddingRight:"3%"}} onClick={this.handleQuestion32}>
        <h3 className="educatorfaqpagetwo">Which of our students can take part in Work Based Projects? 
        <i className="fa fa-caret-down" style={{fontSize:20,float:"right",marginTop:"-5%"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>:<>
        <div style={{paddingRight:"3%"}} onClick={this.handleQuestion32close}>
        <h3 className="educatorfaqpagetwo">Which of our students can take part in Work Based Projects? 
        <i className="fa fa-caret-up" style={{fontSize:20,float:"right",marginTop:"-5%"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>}
       
       {this.state.question32==1?<>
       <div>
       <p style={{ marginTop:"-3%",fontFamily:"GothamLight",fontWeight:"bold",fontSize:12,marginLeft:"2%" }}>
       We welcome Undergraduates, Apprentices and Postgraduate students to take part in work-based projects on GigeconoMe.
                        </p>
       </div>
       </>:<></>}

       {this.state.question33==0?<>
            <div style={{paddingRight:"3%"}} onClick={this.handleQuestion33}>
        <h3 className="educatorfaqpagetwo">	I would like to discuss type of Work Based projects on GigeconoMe? 
        <i className="fa fa-caret-down" style={{fontSize:20,float:"right",marginTop:"-4%"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>:<>
        <div style={{paddingRight:"3%"}} onClick={this.handleQuestion33close}>
        <h3 className="educatorfaqpagetwo">	I would like to discuss type of Work Based projects on GigeconoMe?
        <i className="fa fa-caret-up" style={{fontSize:20,float:"right",marginTop:"-4%"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>}
       
       {this.state.question33==1?<>
       <div>
       <p style={{ marginTop:"-3%",fontFamily:"GothamLight",fontWeight:"bold",fontSize:12,marginLeft:"2%" }}>
       Please email our dedicated team (admin@gigeconome.com) OR use our Contact US form to get drop us a message and we would be happy to discuss. 
                        </p>
       </div>
       </>:<></>}

       {this.state.question34==0?<>
            <div style={{paddingRight:"3%"}} onClick={this.handleQuestion34}>
        <h3 className="educatorfaqpagethree">Can our International Students use GigeconoMe?
        <i className="fa fa-caret-down" style={{fontSize:20,float:"right",marginTop:"-4%"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>:<>
        <div style={{paddingRight:"3%"}} onClick={this.handleQuestion34close}>
        <h3 className="educatorfaqpagethree">Can our International Students use GigeconoMe?
        <i className="fa fa-caret-up" style={{fontSize:20,float:"right",marginTop:"-4%"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>}
       
       {this.state.question34==1?<>
       <div>
       <p style={{ marginTop:"-3%",fontFamily:"GothamLight",fontWeight:"bold",fontSize:12,marginLeft:"2%" }}>
       The simple answer is Yes. International students taking part in paid opportunities on GigeconoMe must possess a work visa or relevant work authorisation status. However International students without work visa can take part on non-paid opportunities on GigeconoMe.
                        </p>
       </div>
       </>:<></>}
       {this.state.question35==0?<>
            <div style={{paddingRight:"3%"}} onClick={this.handleQuestion35}>
        <h3 className="educatorfaqpagetwo">Would my student be able to Complete a Work Based Projects for Academic Credits?
        <i className="fa fa-caret-down" style={{fontSize:20,float:"right",marginTop:"-4%"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>:<>
        <div style={{paddingRight:"3%"}} onClick={this.handleQuestion35close}>
        <h3 className="educatorfaqpagetwo">Would my student be able to Complete a Work Based Projects for Academic Credits?
        <i className="fa fa-caret-up" style={{fontSize:20,float:"right",marginTop:"-10%"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>}
       
       {this.state.question35==1?<>
       <div>
       <p style={{ marginTop:"-3%",fontFamily:"GothamLight",fontWeight:"bold",fontSize:12,marginLeft:"2%" }}>
       The Simple answer is Yes.  We have designed our work Based projects to form an integral part of our student’s degree programme for which they cannot only develop employability skills and gain commercial awareness but most importantly gain academic credits also. 
                        </p>
       </div>
       </>:<></>}

       {this.state.question36==0?<>
            <div style={{paddingRight:"3%"}} onClick={this.handleQuestion36}>
        <h3 className="educatorfaqpagetwo">	Can I invite my colleagues to GigeconoMe?
        <i className="fa fa-caret-down" style={{fontSize:20,float:"right",marginTop:"-1%"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>:<>
        <div style={{paddingRight:"3%"}} onClick={this.handleQuestion36close}>
        <h3 className="educatorfaqpagetwo">	Can I invite my colleagues to GigeconoMe?
        <i className="fa fa-caret-up" style={{fontSize:20,float:"right",marginTop:"-1%"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>}
       
       {this.state.question36==1?<>
       <div>
       <p style={{ marginTop:"-3%",fontFamily:"GothamLight",fontWeight:"bold",fontSize:12,marginLeft:"2%" }}>
       The simple answer is Yes. However, this functionality to invite team members directly from the platform is not currently part of our Beta Phase. However, this functionality is scheduled to be launched in Q4, 2022. Please be aware, when a colleague signs up and they have selected an existing University and choose the same department or Faculty as you, they are automatically linked to your profile as a team member or Educator. 
                        </p>
       </div>
       </>:<></>}

       {this.state.question37==0?<>
            <div style={{paddingRight:"3%"}} onClick={this.handleQuestion37}>
        <h3 className="educatorfaqpagetwo">What do you mean by Work Based Projects?
        <i className="fa fa-caret-down" style={{fontSize:20,float:"right"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>:<>
        <div style={{paddingRight:"3%"}} onClick={this.handleQuestion37close}>
        <h3 className="educatorfaqpagetwo">What do you mean by Work Based Projects?
        <i className="fa fa-caret-up" style={{fontSize:20,float:"right"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>}
       
       {this.state.question37==1?<>
       <div>
       <p style={{ marginTop:"-3%",fontFamily:"GothamLight",fontWeight:"bold",fontSize:12,marginLeft:"2%" }}>
       These are short term assignments that can be carried out by students for any of our established enterprises and Startups. Projects will help our Startups or established enterprise resolve a particular business issue or case or help to develop a new process, product or procedure in the workplace. Some of the common related projects include conducting market research, performing financial analyses and creating financial models, landing page development, creating prototypes in Figma. The students would be expected to take the lead on work-based projects with support offered by existing employees of our Industry partners. 
                        </p>
       </div>
       </>:<></>}


     
       
       
       {this.state.question38==0?<>
            <div style={{paddingRight:"3%"}} onClick={this.handleQuestion38}>
        <h3 className="educatorfaqpagethree" >How does the Invite to Apply Works?
        <i className="fa fa-caret-down" style={{fontSize:20,float:"right"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>:<>
        <div style={{paddingRight:"3%"}} onClick={this.handleQuestion38close}>
        <h3 className="educatorfaqpagethree">How does the Invite to Apply Works?
        <i className="fa fa-caret-up" style={{fontSize:20,float:"right"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>}
       
       {this.state.question38==1?<>
       <div>
       <p style={{ marginTop:"-3%",fontFamily:"GothamLight",fontWeight:"bold",fontSize:12,marginLeft:"2%" }}>
       Our “Invite to Apply” button allows to contact upto 10 student talents directly to receive custom proposals, if you do not want to use our matching engine. 
                        </p>
       </div>
       </>:<></>}
      

       {/* Startups section faq ends*/}
        </>)
    }
}