import React, { useState } from "react";
import Resizer from "react-image-file-resizer";
import "./modal.css";

function close(){
    window.location.reload();
  }
export default class AlumniAddMore extends React.Component{
  
  constructor() {
    super();
    let comments = JSON.parse(sessionStorage.getItem("someData"));
   
   console.log(comments)
    this.state = {
      
    
     
        userid: comments,
        addcpydata :"cpyalumniinfo",
        universityid:"",
        universitylogo:"",
        showModal: false,
        message:"",
        geomesage:"",
        selectUniversity:[],
       
     
    
  
  }
  this.handleSubmit = this.handleSubmit.bind(this);
}

  handleSubmit = (event) => {
    event.preventDefault()
   
    const data = {
        userid: this.state.userid,
        addcpydata: this.state.addcpydata,
        universityid:this.state.universityid,
        universitylogo:this.state.universitylogo,
       
     
    }
  
   

    fetch( "https://www.gigeconome.com:5200/api/addcpy_profiledata", {
      method: "POST",

      body: JSON.stringify(data), // data can be `string` or {object}!

      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())

      .catch((error) => console.error("Error:", error))

      .then((response) => {
         
      if(response.message=="request entity too large"){
        this.setState({message:"Please Upload a logo Width 120px & Height 120px"}) 
      }
      else{
        this.setState({geomessage:response.message})
        window.location.reload();
      }
        
       
        
      });
  
}
getBase64 = (file) => {
  return new Promise((resolve) => {
    let fileInfo;
    let baseURL = "";
    // Make new FileReader
    let reader = new FileReader();

    // Convert the file to base64 text
    reader.readAsDataURL(file);

    // on reader load somthing...
    reader.onload = () => {
      // Make a fileInfo Object
      console.log("Called", reader);
      baseURL = reader.result;
      console.log(baseURL);
      resolve(baseURL);
    };
    console.log(fileInfo);
  });
};
handleImage = (e) => {
  // let file = new FileReader();
  console.log(e.target.files[0]);
  let { file } = this.state;

  file = e.target.files[0];

  this.getBase64(file)
    .then((result) => {
      file["base64"] = result;
      console.log("File Is", file);
      this.setState({
        universitylogo: result,
        file,
      });
    })
    .catch((err) => {
      console.log(err);
    });

  this.setState({
    //  file: e.target.files[0]
  });

  this.setState({
    //companylogo: e.target.files[0]
  });
  var fileInput = false;
  if (e.target.files[0]) {
    fileInput = true;
  }
  
  if(fileInput) {
    try {
      Resizer.imageFileResizer(
        e.target.files[0],
        120,
        120,
        "JPEG",
        100,
        0,
        (uri) => {
          console.log(uri);
          this.setState({ newImage: uri });
        },
        "base64",
        120,
        120
      );
    } catch (err) {
      console.log(err);
    }
  }
};
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
     
    });
    
  };
  openModal = () => {
    this.setState({ showModal: true });
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };


  componentDidMount(){
    const data_school={dropdownlist_input:"university"}
    //let BaseUrl = JSON.parse(sessionStorage.getItem("baseurl"));

    fetch( "https://www.gigeconome.com:5200/api/getdropdownlist", {
      method: "POST",

      body: JSON.stringify(data_school), // data can be `string` or {object}!

      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())

      .catch((error) => console.error("Error:", error))

      .then((json) => {
        const data = json.result;
        
        this.setState({ selectUniversity: data });
        
      });
  }
render(){

  const{geomessage}=this.state
  return (
    <>
   {this.state.showModal && (
         <div class="ft-modal" id="ft-alumniaddmore-modal" aria-hidden="true">
         <div class="ft-modal-content" >
           <div class="ft-modal-header">
             <div class="header">
               <h5
                 class="modal-title"
                 id="exampleModalLabel"
                 style={{
                   textAlign: "center",
                   fontSize: 25,
                   fontWeight: "bold",
                 }}
               >
                 Add Alumini Affliations
               </h5>
               <hr />
             </div>
           </div>
           <div class="ft-modal-body">
           <form onSubmit = {this.handleSubmit}>
             <div class="row">
               <div class="col-sm-2"></div>
               <div class="col-sm-8">
                
               <label
                   style={{
                     fontSize: 15,
                     fontFamily:"Gotham",
                     marginTop:"3%"
                   }}
                 >
                   Add a Logo
                 </label>{" "}
               <br />
               <input type="file"
                  name ="universitylogo" 
                  onChange = {this.handleImage}
                 class="form-control mt-3"  />  
               </div>
               <div class="col-sm-2"></div>
             </div>
             <div class="row mt-2">
                   <div class="col-sm-2" > 
                   
                   </div>
                   <div class="col-sm-8">
                   <label
                   style={{
                     fontSize: 15,
                     fontFamily:"Gotham",
                     marginTop:"4%"
                   }}
                 >
                   Name of University
                 </label>{" "}
                 <br />
                 {this.state.selectUniversity.length == 0
                    ? "Loading Universities Name..."
                    : 

                    <select class="form-select mt-2"  name ="universityid" 
                    onChange = {this.handleChange}>
                      <option>Select</option>
                    {this.state.selectUniversity.map(fbb =>
                    
                      <option key={fbb.key} value={fbb.id}>{fbb.name}</option>
                      
                    )};
                  </select>
                    } 
                      </div>
<div class="col-sm-2" > 
                   
                   </div>
               </div>
               <div style={{textAlign:'center'}}>
 
 <button
   style={{ backgroundColor: "rgb(0, 147, 69)", width: 100,
 marginTop:"8%",
 }}
   className="btn btn-success"
   type="submit"
 >
     
   Save
 </button>
 
 </div>
 
 
   <span style={{color:"rgb(0,147,69)",fontSize:15,marginTop:"5%",fontWeight:"bold"}}>{geomessage}</span><br/>
 
 <span style={{color:"red",fontSize:15,marginTop:"5%",fontWeight:"bold"}}>{this.state.message}</span>
 
             </form>{" "}
             <div class="row mt-4">
               <div class="col-sm-2"></div>
               <div class="col-sm-8">
                
               </div>
               <div class="col-sm-2"></div>
             </div>
             <div class="mt-4">
               <div class="col-sm-4"></div>
               <div class="col-sm-7">
                
               </div>
               <div class="col-sm-1"></div>
             </div>
           </div>
           <div class="ft-modal-footer">
           <a class="ft-modal-close"  data-dismiss="modal"
                     onClick={this.closeModal}>
                 [&#10006;]{" "}
               </a>
           </div>
         </div>
       </div>
 
 
 
        )}
      

      
      <a
          id="add_btn"
          className="btn-success btn-sm mt-2 bigcompanysearch"
          href="#ft-alumniaddmore-modal"
          style={{
            margin:'auto',
            width: 100,
            backgroundColor: "rgb(0, 147, 69)",
            fontFamily: "GothamLight",
            textAlign: "center",
          }}
          onClick={this.openModal}
        >
          Add More
        </a>
        <a
          id="add_btntwo"
          className="btn-success btn-sm mscreenava"
          href="#ft-alumniaddmore-modal"
          style={{
            margin:'auto',
            width: 100,
            backgroundColor: "rgb(0, 147, 69)",
            fontFamily: "GothamLight",
            textAlign: "center",
          }}
          onClick={this.openModal}
        >
          Add More
        </a>
    </>
  );
}
}
