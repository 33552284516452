import React, { useState } from "react";
import Swal from 'sweetalert2';
import "./modal.css";

function close(){
  window.location.reload();
}
export default class  WorkexperienceAdd extends React.Component{
    constructor() {
        super();
        let comments = JSON.parse(sessionStorage.getItem("someData"));
       
       console.log(comments)
        this.state = {
          
        
         
         
          userid: comments,
          addstudata :"workexperience",
         
          dropdownlist_input : "nationality",
         
          selectOptions : [],
          selectSchool:[],
          selectQualification:[],
          title:"",
          companyname:"",
          degree:"",
          roletype:"",
          startdate:"",
          enddate:"",
          description:"",
          currentcompany:"",
          geomessage:"",
          showModal: false,
    
      
      }
      this.handleSubmit = this.handleSubmit.bind(this);
    }
    
      handleSubmit = (event) => {
        event.preventDefault()
       
        if(this.state.currentcompany==null){
          this.setState({currentcompany:"No"})
          const data = {
    
            userid: this.state.userid,
            addstudata: this.state.addstudata,
            title:this.state.title,
            companyname:this.state.companyname,
            roletype:this.state.roletype,
            startdate:this.state.startdate,
            enddate:this.state.enddate,
            currentcompany:"No",
            description:this.state.description,
            currentcompany:this.state.currentcompany
           
      
          }
        console.log(data)
  
      
          let BaseUrl = JSON.parse(sessionStorage.getItem("baseurl"));
      
            fetch( "https://www.gigeconome.com:5200/api/addstu_profiledata", {
              method: "POST",
      
              body: JSON.stringify(data), // data can be `string` or {object}!
      
              headers: { "Content-Type": "application/json" },
            })
              .then((res) => res.json())
      
              .catch((error) => console.error("Error:", error))
      
              .then((response) => {
                  this.setState({geomessage:response.message})
                window.location.reload();
                
                
              });

        }
        else{
          const data = {
    
            userid: this.state.userid,
            addstudata: this.state.addstudata,
            title:this.state.title,
            companyname:this.state.companyname,
            roletype:this.state.roletype,
            startdate:this.state.startdate,
            enddate:this.state.enddate,
            currentcompany:this.state.currentcompany,
            description:this.state.description,
            currentcompany:this.state.currentcompany
           
      
          }
        console.log(data)
  
      
          let BaseUrl = JSON.parse(sessionStorage.getItem("baseurl"));
      
            fetch( "https://www.gigeconome.com:5200/api/addstu_profiledata", {
              method: "POST",
      
              body: JSON.stringify(data), // data can be `string` or {object}!
      
              headers: { "Content-Type": "application/json" },
            })
              .then((res) => res.json())
      
              .catch((error) => console.error("Error:", error))
      
              .then((response) => {
                  this.setState({geomessage:response.message})
                window.location.reload();
                sessionStorage.removeItem("CurrentCompany");
                
              });

        }
        
      }
      componentDidMount(){
        this.getOptions()
    }
    async getOptions(){
      const data_one={dropdownlist_input:this.state.dropdownlist_input,
      
      }
    
      let BaseUrl = JSON.parse(sessionStorage.getItem('baseurl'));
    
      fetch( 'https://www.gigeconome.com:5200/api/getdropdownlist', { method: 'POST', 
    
       body: JSON.stringify(data_one), // data can be `string` or {object}!
    
       headers:{ 'Content-Type': 'application/json' } })
    
       .then(res => res.json())
    
       .catch(error => console.error('Error:', error))
    
       .then((json)=>{
        const data = json.result
        
        this.setState({selectOptions: data})
       })
       const data_two={dropdownlist_input:"country"
      
       }
     
       //let BaseUrl = JSON.parse(sessionStorage.getItem('baseurl'));
     
       fetch( 'https://www.gigeconome.com:5200/api/getdropdownlist', { method: 'POST', 
     
        body: JSON.stringify(data_two), // data can be `string` or {object}!
     
        headers:{ 'Content-Type': 'application/json' } })
     
        .then(res => res.json())
     
        .catch(error => console.error('Error:', error))
     
        .then((json)=>{
         const data = json.result
         
         this.setState({selectSchool: data})
        })
    
      
    
    
        const qual_data={dropdownlist_input:"qualification"
      
    }
    
    //let BaseUrl = JSON.parse(sessionStorage.getItem('baseurl'));
    
    fetch( 'https://www.gigeconome.com:5200/api/getdropdownlist', { method: 'POST', 
    
     body: JSON.stringify(qual_data), // data can be `string` or {object}!
    
     headers:{ 'Content-Type': 'application/json' } })
    
     .then(res => res.json())
    
     .catch(error => console.error('Error:', error))
    
     .then((json)=>{
      const data = json.result
      
      this.setState({selectQualification: data})
     })
      
      }
        handleChange = (e) => {
          this.setState({
            [e.target.name]: e.target.value,
           
          });
          const target = e.target;
          var value = target.value;
          
          if(target.checked){

           
              this.setState({currentcompany:value})
              console.log(this.state.currentcompany)
            
          }else{
             
          }


          
        };

        openModal = () => {
          this.setState({ showModal: true });
        };
      
        closeModal = () => {
          this.setState({ showModal: false });
        };
      
       
render(){
  const{geomessage}=this.state
  return (
    <>
    {this.state.showModal && (
      <div class="ft-modal" id="ft-workadd-modal">
        <div class="ft-modal-content" >
          <div class="ft-modal-header">
            <div class="header">
              <h5
                class="modal-title"
                id="exampleModalLabel"
                style={{
                  textAlign: "center",
                  
                  fontWeight: "bold",
                }}
              >
                Add Work Experience
              </h5>
              <hr />
            </div>
          </div>
          <div class="ft-modal-body">


          <form onSubmit={this.handleSubmit} style={{fontSize:15}}>
   <div class="row">
                      <div class="col-sm-1"></div>
                      <div class="col-sm-5">
                        Title
                        <div>
                          <input
                            type="select"
                            placeholder="Enter Your Job Title"
                            className="form-control mt-1"
                            name ="title" 
                            onChange = {this.handleChange}
                          />
                        </div>
                      </div>
                      <div class="col-sm-5">
                        Company
                        <div>
                          <input
                            type="select"
                            placeholder="Enter Your Comapny Name"
                            className="form-control mt-1"
                            name ="companyname" 
                            onChange = {this.handleChange}
                          />
                        </div>
                      </div>
                      <div class="col-sm-1"></div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-sm-1"></div>
                      <div class="col-sm-10">
                        Role Type
                        <select
                                className="form-select mt-2"
                                name ="roletype" 
                                onChange = {this.handleChange}
                              >
                                <option selected>Select</option>
                                <option value="1">Opportunities</option>
                                <option  value="2">Internship</option>
                                <option value="3">Externship</option>
                                <option value="4">Work Experience</option>
                                <option value="5">Work Placement</option>
                                <option value="6">Part Time Roles</option>
                                <option value="7">Side Gig Opportunities</option>
                                <option value="8">Course Work Related</option>
                                <option value="9">Portfolio Building</option>
                                <option value="10">Other</option>
             
                              </select>
                      </div>

                      <div class="col-sm-1"></div>
                    </div>

                    <div class="row mt-3">
                      <div class="col-sm-1"></div>
                      <div class="col-sm-3">
                        Start
                        <div>
                        <input type="text" class="form-control mt-2"
                       name ="startdate" 
                       onChange = {this.handleChange}
                      placeholder="Select Year"
                      onFocus={(e) =>
                        (e.currentTarget.type = "month")
                      }
                      onBlur={(e) =>
                        (e.currentTarget.type = "text")
                      }
                    
                      />
                  
                        </div>
                      </div>
                      <div class="col-sm-3">
                        End
                        <div class="mt-2">
                      <input type="text" class="form-control mt-1"
                       name ="enddate" 
                       onChange = {this.handleChange}
                      placeholder="Select Year"
                      onFocus={(e) =>
                        (e.currentTarget.type = "month")
                      }
                      onBlur={(e) =>
                        (e.currentTarget.type = "text")
                      }
                     
                      />
                        </div>
                      </div>
                      <div class="col-sm-5"></div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-sm-1"></div>
                      <div class="col-sm-6">
                        <input
                          type="checkbox"
                          id="vehicle1"
                          name ="currentcompany" 
                          onChange = {this.handleChange}
                          value="Yes"
                        />
                        <label for="vehicle1" id="checkbox_text">
                          {" "}
                         &nbsp; I am Currently Working Here
                        </label>
                      </div>

                      <div class="col-sm-5"></div>
                    </div>
                      <div class="row mt-3">
                        <div class="col-sm-1"></div>
                        <div class="col-sm-10">
                          <form>
                            <div class="form-group">
                              <label for="comment">Description</label>
                              <textarea
                                class="form-control mt-2"
                                rows="5"
                                id="comment"
                                name ="description" 
                                onChange = {this.handleChange}
                              ></textarea>
                            </div>
                          </form>
                        </div>
                        <div class="col-sm-2"></div>
                      </div>
                      <div class="col-sm-5"></div>

                    <div class="row mt-4">
                      <div class="col-sm-8"></div>
                      <div class="col-sm-2">
                        <button className="btn-secondary" onClick={()=>close()} id="education_btn">
                          Cancel
                        </button>
                      </div>
                      <div class="col-sm-1">
                        <button
                          style={{ backgroundColor: "rgb(0, 147, 69)" }}
                          className="btn-success"
                          id="education_btn"
                          type="submit"
                        >
                          Save
                        </button>
                      </div>
                      <div class="col-sm-1"></div>
                    </div>
                    <span style={{color:"rgb(0,147,69)",fontSize:15,marginTop:"5%",fontWeight:"bold"}}>{geomessage}</span>
                    <div class="ft-modal-footer">
            <a class="ft-modal-close" data-dismiss="modal"
                     onClick={this.closeModal}>
              [&#10006;]{" "}
            </a>
          </div>     </form>
        </div>
        </div>
      </div>

    )}
    <div className="bigcompanysearch">

    <a
         
          className="btn-success btn-sm mt-2"
          href="#ft-workadd-modal"
          style={{
            margin:'auto',
            width: "24%",
            backgroundColor: "rgb(0, 147, 69)",
            fontFamily: "GothamLight",
            textAlign: "center",
            
          }}
          onClick={this.openModal}
        >
          Add Work Experience
        </a>
    </div>
     
        <div className="mscreenava">
        <a
          id="add_btnthree"
          className="btn btn-sm mt-2 stuworkbtn"
          href="#ft-workadd-modal"
         
          onClick={this.openModal}
        >
          Add Work Experience
        </a>
          </div>
    </>
  );
}
}
