import React from "react";
import Header from "../Header/Header";
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Link } from "react-router-dom";
import Slide1 from "../../images/blog5.png";
import Blog1 from "../../images/blog1.png";
import Blog2 from "../../images/blog2.png";
import Blog3 from "../../images/blog3.png";
import Footer from "../Footer/Footer";
export default class Event extends React.Component{
    render(){
        return(<div>
                <Header/>
                <img className="img-fluid" src={Slide1} style={{marginTop:"4%"}} />
    
                    <div className="container" style={{marginBottom:"5%"}}>
                    <div className="row">
                        
                        
                        <div className="col-sm-5">
                             <h2 style={{color:"white",marginTop:"7%",color:"#00674C"}} >
                           London Met Postgraduate <br/>
                           Research Student Induction 
                            </h2>
                            <p style={{fontWeight:"bold",fontFamily:"GothamLight",color:"black",fontSize:18}}>
                           Eyerything you need to know to get you started as you embark on your journey as a Postgraduate Research Student at London Met.
                            </p>
                            
                            </div>
                           <div className="col-sm-2"></div> 
                      
                           <div className="col-sm-4 text-center">
                          
                            <div style={{height:"11em",background:"#f1f7f6",border:"3px solid #00674C",borderRadius:30,marginTop:"7%"}}>
                            <h3 style={{color:"#00674C",marginTop:"10%"}}>Click here to learn</h3>
                            <button className="eventbtn">Register</button>
                            </div>
                            </div>
                    </div>

                    <div className="row mt-4">
                        
                        
                        <div className="col-sm-10">
                             <h2 style={{color:"#00674C"}} >
                            In this time,you can't over-communicate.   
                            </h2>
                            <p style={{fontWeight:"bold",fontFamily:"GothamLight"}}>
                            In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.
                            </p>
                            <p style={{fontWeight:"bold",fontFamily:"GothamLight"}}>
                            In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.
                            </p>
                            </div>
                            
                      
                       
                    </div>
                    <div className="row mt-4">
                        
                        
                        <div className="col-sm-10">
                             <h2 style={{color:"#00674C"}} >
                             Different Students will be affected differently. 
                            </h2>
                            <p style={{fontWeight:"bold",fontFamily:"GothamLight"}}>
                            In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.
                            </p>
                            <p style={{fontWeight:"bold",fontFamily:"GothamLight"}}>
                            In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.
                            </p>
                            </div>
                            
                      
                       
                    </div>
                    <div className="row mt-4">
                        
                        
                        <div className="col-sm-10">
                             <h2 style={{color:"#00674C"}} >
                            Reduce melt by making up for missed opportunities.  
                            </h2>
                            <p style={{fontWeight:"bold",fontFamily:"GothamLight"}}>
                            In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.
                            </p>
                            <p style={{fontWeight:"bold",fontFamily:"GothamLight"}}>
                            In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.
                            </p>
                            </div>
                            
                      
                       
                    </div>
                    </div>
                  
         
      
    
<Footer/>
            </div>)
    }
}