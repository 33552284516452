import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
function CompanySettingMenu() {
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);
  return (
    <div class="my-0" style={{ marginLeft: -15 }}>
      <nav
        class="navbar navbar-expand-xl navbar-dark"
      
      >
        <div className="container d-flex align-items-left">
          <a href="#" class="navbar-brand"></a>
          <button
            class="custom-toggler navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarCollapse12"
            aria-controls="navbarsExample09"
            aria-expanded={!isNavCollapsed ? true : false}
            aria-label="Toggle navigation"
            onClick={handleNavCollapse}
          >
            <span class="navbar-toggler-icon bg-dark"></span>
          </button>
          <div
            class={`${isNavCollapsed ? "collapse" : ""} navbar-collapse`}
            id="navbarCollapse12"
          >
            <div class="navbar-nav" id="programming">
              <Link to="/companyprofile" class="nav-item">
                Profile ⏷
              </Link>
           
              <Link
                to="/companyseconddashboard"
                class="nav-item"
                style={{ marginLeft: 7 }}
              
                
              >
                Company
                </Link>
                <i class="fa fa-caret-down mt-2" 
                style={{fontSize:15,paddingTop:8,marginLeft:5}}></i> 
               
             
               <Link
                to="/companyopportunity"
                class="nav-item"
                style={{ marginLeft: 7 }}
          
              >
               
                 Opportunities ⏷
               
              </Link>
              <Link
                to="/companymessage"
                class="nav-item"
                style={{ marginLeft: 7 }}
              >
               
                Message ⏷
               
              </Link>
              <a
                href="#"
                class="nav-item"
                style={{ marginLeft: 7 }}
              >
                Analytics
              </a>
              <Link
               to="/companysetting"
                class="nav-item"
                style={{ marginLeft: 7 }}
               id="c_text"
              >
                Settings ⏷
              </Link>
            </div>
            
          </div>
        </div>
      </nav>
    </div>
  );
}

export default CompanySettingMenu;
