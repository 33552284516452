import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import LinkedIN from "../../../images/company1.png";
import Instagram from "../../../images/insta.png";
import Twitter from "../../../images/twitter.png";
import Facebook1 from "../../../images/fb.png";
import GraduationYear from "../../Signup/GraduationYear";
import "./modal.css";
function close(){
  window.location.reload();
}
export default class  GeoModal extends React.Component{
  
  constructor() {
    super();
    let comments = JSON.parse(sessionStorage.getItem("someData"));
   
   console.log(comments)
    this.state = {
      
    
     
     
      userid: comments,
      addstudata :"geograpical",
      geograpical_office:"",
      geograpical_remote:"",
      geomessage:"",
      showModal: false,
    
  
  }
  this.handleSubmit = this.handleSubmit.bind(this);
}

  handleSubmit = (event) => {
    event.preventDefault()
   
    const data = {

      userid: this.state.userid,
      addstudata: this.state.addstudata,
      geograpical_office:this.state.geograpical_office,
      geograpical_remote:this.state.geograpical_remote,
     

    }
  
    let BaseUrl = JSON.parse(sessionStorage.getItem("baseurl"));

      fetch("https://www.gigeconome.com:5200/api/addstu_profiledata", {
        method: "POST",

        body: JSON.stringify(data), // data can be `string` or {object}!

        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())

        .catch((error) => console.error("Error:", error))

        .then((response) => {
          console.log(response.message)
       
          this.setState({geomessage:"The user geograpical info has been successfully Added."})
          window.location.reload();
          
        });
    
  }
    handleChange = (e) => {
      this.setState({
        [e.target.name]: e.target.value,
       
      });
      
    };

    openModal = () => {
      this.setState({ showModal: true });
    };
  
    closeModal = () => {
      this.setState({ showModal: false });
    };
render(){
  const{geomessage}=this.state
  return (
    <>
     {this.state.showModal && (
      <div class="ft-modal" id="ft-geo-modal">
        <div class="ft-modal-content" style={{width:"50%"}}>
          <div class="ft-modal-header">
            <div class="header">
              <h5
                class="modal-title"
                id="exampleModalLabel"
                style={{
                  textAlign: "center",
                  fontSize: 25,
                  fontWeight: "bold",
                }}
              >
                Geographical Scope
              </h5>
              <hr />
            </div>
          </div>
          <div class="ft-modal-body">
            <div class="row mt-4">
              <div class="col-sm-2"></div>
              <div class="col-sm-8">
                <form onSubmit = {this.handleSubmit}>
                <label
                  style={{
                    fontSize: 20,
                    fontWeight: "bold",
                  }}
                  className="mviewgeographicaltxt"
                >
                  Can Work at Client's office?
                </label>{" "}
               
                <select
                  class="form-select"
                  name ="geograpical_office" 
                  onChange = {this.handleChange}
                >
                   <option selected>Select</option>
                  <option>Full Time</option>
                  <option>Part time</option>
                  <option>Hybrid</option>
                  <option>No</option>
                </select>
                <label
                  style={{
                    fontSize: 20,
                    fontWeight: "bold",
                    marginTop:"5%"
                  }}
                  className="mviewgeographicaltxt"
                >
                  Avaliable to Work Remotely?
                </label>{" "}
               
                <select
                  class="form-select"
                  name ="geograpical_remote" 
                  onChange = {this.handleChange}
                >
                 <option selected>Select</option>
                  <option>Yes</option>
                  <option>No</option>
                  <option>Flexible</option>
                </select>
                <div style={{textAlign:'center'}}>

                <button
                  style={{ backgroundColor: "rgb(0, 147, 69)", width: 100,
                marginTop:"5%",
                }}
                  className="btn btn-success"
                >
                  Save
                </button>
                
                </div>
                <span style={{color:"rgb(0,147,69)",fontSize:15,marginTop:"5%",fontFamily:"Gotham"}}>{geomessage}</span>
                </form>
              </div>
              <div class="col-sm-2"></div>
            </div>{" "}
            <div class="row mt-4">
              <div class="col-sm-2"></div>
              <div class="col-sm-8">
               
              </div>
              <div class="col-sm-2"></div>
            </div>
            <div class="mt-4">
              <div class="col-sm-4"></div>
              <div class="col-sm-7">
               
              </div>
              <div class="col-sm-1"></div>
            </div>
          </div>
          <div class="ft-modal-footer">
            <a class="ft-modal-close"  data-dismiss="modal"
                     onClick={this.closeModal}>
              [&#10006;]{" "}
            </a>
          </div>
        </div>
      </div>

     )}
      <a
          
          className="btn-success btn-sm"
          href="#ft-geo-modal"
          style={{
            margin:"auto",
            width: 100,
            backgroundColor: "rgb(0, 147, 69)",
            fontFamily: "GothamLight",
            textAlign: "center",
            marginTop:"4%",
            display:'block'
          }}
          onClick={this.openModal}
        >
          Add
        </a>
    </>
  );
}
}
