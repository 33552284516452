import React from "react";
import "./Companysignuptwo";
import "./company.css";
import { Link } from "react-router-dom";
import Logo from '../../images/logo.png';
import Header from "../Header/Header"; 
import Footer from "../Footer/Footer";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Select from 'react-select';
const style = {
  control: (base, state) => ({
    ...base,
   
    borderRadius:"1px",
   height:"44px",
   
    // This line disable the blue border
    boxShadow: state.isFocused ? 0 : 0,
    "&:hover": {
    
      borderRadius:"5px",
      height:"44px",
      
    }
  }),
 
};
class Companysignuptwo extends React.Component {
  constructor() {
    super();
    let comments = JSON.parse(sessionStorage.getItem('someData'));
    let SignupAS = JSON.parse(sessionStorage.getItem('signupAS'));
    console.log(SignupAS)
    this.state = {
      firstname:" ",
      lastname:" ",
      mobile:" ",
      jobtitle:" ",
      jobdepartment:" ",
      
      companywebsite:" ",
      location:" ",
      countryid:"",
      userid:comments,
      reg_level:2,
      dropdownlist_input : "country",
      DataisLoaded: false,
      selectOptions : [],
      id: "",
      name: '',
     selectCountry:[],
     selectLocation:[],
     jobDepartment:[],
     jobdepartmentname:"",
     signupfor:SignupAS

      };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount(){
    this.getOptions()
}
async getOptions(){
  const data_three={dropdownlist_input:this.state.dropdownlist_input,
  
  }

  let BaseUrl = JSON.parse(sessionStorage.getItem('baseurl'));

  fetch( 'https://www.gigeconome.com:5200/api/getdropdownlist', { method: 'POST', 

   body: JSON.stringify(data_three), // data can be `string` or {object}!

   headers:{ 'Content-Type': 'application/json' } })

   .then(res => res.json())

   .catch(error => console.error('Error:', error))

   .then((response)=>{
    const data = response.result

  
const options = data.map(d => ({
    "value" : d.id,
    "label" : d.name

  })) 
 
  this.setState({selectLocation: options})
    
   })
 
   const jobdata={dropdownlist_input:"jobdepartment",
  
   }
 
 
 
   fetch( 'https://www.gigeconome.com:5200/api/getdropdownlist', { method: 'POST', 
 
    body: JSON.stringify(jobdata), // data can be `string` or {object}!
 
    headers:{ 'Content-Type': 'application/json' } })
 
    .then(res => res.json())
 
    .catch(error => console.error('Error:', error))
 
    .then((json)=>{
     const data = json.result
     this.setState({jobDepartment:data})
     
    })
  


  }
  handleDrop = (e) => {
    
    this.setState({location:e.value})
   
      }
  handleSubmit(event) {
    event.preventDefault();
    if (this.state. StudyStatus === "") {
     alert("Plese Fill !");
    }
    else{
    const data = { firstname:this.state.firstname, 
      lastname:this.state.lastname, 
      mobile:this.state.mobile, 
      jobtitle:this.state.jobtitle
      ,jobdepartment:this.state.jobdepartment,
       
       signupfor:this.state.signupfor,
       location:this.state.location,
      
      userid:this.state.userid, reg_level:this.state. reg_level }

     

      console.log(data)
      fetch('https://www.gigeconome.com:5200/api/cpuserinfo', { method: 'POST', 

    body: JSON.stringify(data), // data can be `string` or {object}!

    headers:{ 'Content-Type': 'application/json' } })

    .then(res => res.json())

    .catch(error => console.error('Error:', error))

    .then((response)=>{
        console.log('Success:', response)
         if(response.signupfor==1){
          window.location.href=`./Companysignupfour`
         }
           else{
          window.location.href=`./Companysignupthree`
           }
            //this.setState({ data: response.userid });
        console.log(response)
    })
    
  }
  this.setState({
    DataisLoaded: true 
  })     
    
       
    
   }
   handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
   
  }
  handleCountry = (e) => {

    var Country = JSON.stringify(e.label);
      sessionStorage.setItem('Country',Country);
     this.setState({name:e.label})
   
   
 }

  render() {
   
  const {selectCountry}=this.state
    return (
      <div>
         {/* ======= Header ======= */}
     <Header />
      {/* End Header */}
        <main id="main" style={{ background: "#303030", clear: "both" }}>
          <br />
          <br />
          {/* ======= About Section ======= */}
          <section id="clients" className="clients">
            <div className="container" data-aos="fade-up">
              <style
                type="text/css"
                dangerouslySetInnerHTML={{
                  __html:
                    "\n    .boxxlr{\n     border: 0px solid green;\n    background: #edf8f2;\n    margin-right: 2.5%;\n    margin-bottom: 7%;\n    color: #000;\n    margin-left: 9%;\n    width: 100%;\n    }\n/*.boxxlr:hover {\n    border: 0px solid green;\n    background: green;\n    margin-right: 1%;\n    margin-bottom: 1%;\n     color: #fff;\n}*/\n\n.boxclrtitle {\nfont-size: 12px;font-weight: bold;\n}\n\n.boxclrtitle1 {\nfont-size: 18px;font-weight: bold;\n}\n/*.boxxlr .boxclrtitle:hover {\nfont-size: 12px;font-weight: bold;\n}*/\n\n\n  ",
                }}
              />
              <div className="row no-gutters">
                <div className="content col-xl-12 d-flex align-items-center">
                  <div className="content col-xl-12 d-flex align-items-center">
                    <div
                      className="row"
                      style={{
                        display: "flex",
                        justifyContent: "center" /* alignItems: 'center', */,
                        background: "#fff" /* width: '80%', */,
                        /* marginLeft: '50%', */
                        borderRadius: "20px",
                        margin: "auto",
                      }}
                    >
                      <h2
                        style={{
                          fontSize: "20px",
                          textAlign: "center",
                          color: "#fff",
                          fontWeight: 600,
                          background: "#00674C",
                          padding: "1%",
                          width: "70%",
                          margin: "6%",
                        }}
                      >
                        {" "}
                        Basic Information{" "}
                      </h2>
                      <h6
                        style={{
                          textAlign: "center",
                          fontSize: "16px",
                          fontWeight:"bold",
                          marginTop: "-3%",
                          fontFamily:"GothamLight",
                          color:"rgb(0,147,69)"
                          
                        }}
                      >
                       Please Tell Us a bit about You
                      </h6>
                      <div className="row h-100 row align-items-center">
                        <div className="col-md-10" style={{ margin: "auto" }}>
                          <form onSubmit={this.handleSubmit}>
                          
                            <div
                              className="row"
                              style={{ padding: "2%", borderRadius: "1px",
                            marginTop:"5%"
                            }}
                            >
                              <div className="col">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="First Name"
                                  required
                                  autoComplete="off"
                                  onChange={this.handleChange} 
                                  id="firstname"
                                  name="firstname"
                                  //value={this.state.FirstName}
                                 style={{padding:"4%"}}
                                />
                              </div>
                              <div className="col">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Last Name"
                                  required
                                  //value={this.state.LastName}
                                  onChange={this.handleChange} 
                                  id="lastname"
                                  name="lastname"
                                  autoComplete="off"
                                  style={{padding:"4%"}}
                                />
                              </div>
                            </div>

                            <div
                              className="row"
                              style={{ padding: "2%", borderRadius: "1px",
                           
                            }}
                            >
                              <div className="col-md-3">
                              <PhoneInput
  
                        value={this.state.phone}
                        country={'gb'}
                        className="form-control"
                        placeholder="Telephone Number"
                        
                                                      
                                                    
                      />
        
                              </div>
                              <div className="col-md-9">
                              <input
                                  type="text"
                                  className="form-control cpysignuptelephone"
                                  placeholder="Telephone Number"
                                  required
                                  //value={this.state.LastName}
                                  onChange={this.handleChange} 
                                 
                                  name="mobile"
                                  autoComplete="off"
                                 
                                />
                              </div>
                            </div>
                           
                            <div
                              className="form-group"
                              style={{ padding: "2%", borderRadius: "1px" }}
                            >
                              <input
                                style={{ padding: "2%" }}
                                type="text"
                                className="form-control"
                                placeholder="Job Title"
                                required
                                //value={this.state.JobTitle}
                                onChange={this.handleChange} 
                                id="jobtitle"
                                name="jobtitle"
                                autoComplete="off"
                              />
                            </div>
                            <div
                              className="form-group"
                              style={{ padding: "2%", borderRadius: "1px" }}
                            >
                               {this.state.jobDepartment.length == 0
                    ? "Loading Job Department Name..."
                    : 

                    <select class="form-select" style={{
                      width: "100%",
                      height: "48px",
                      marginBottom: "2%",
                      marginTop: "1%",
                    }} name ="jobdepartment" 
                    onChange = {this.handleChange}>
                     
                      <option>Job Department</option>
                    {this.state.jobDepartment.map(fbb =>
                   
                      <option key={fbb.key} value={fbb.id}>{fbb.name}</option>
                      
                    )};
                  </select>
                    } 
                            </div>
                         

                           
                            


                            <div
                              className="row"
                              style={{ padding: "2%", borderRadius: "1px",
                           
                            }}
                            >
                              
                              <div className="col-md-12">
                              <Select options={this.state.selectLocation}
                              components={{  IndicatorSeparator:() => null }}
                              styles={style} placeholder="Select Location" onChange={this.handleDrop}   /> 
                              </div>
                            </div>


                  
                            <div
                              className="form-group"
                              style={{ padding: "2%", }}
                            >
                               
                            </div>
                            <div
                              className="container"
                              style={{ marginBottom: "2%" }}
                            >
                              <Link to='/companysignup'
                                style={{ color: "#fff" }}
                               
                              >
                               
                              </Link>
                            
                                <button
                                  className="btn btn-primary"
                                  style={{
                                    padding: "2%",
                                    marginLeft: "34%",
                                    marginTop: "7%",
                                    color: "#fff",
                                    background: "#00674C",
                                    width: "26%",
                                    border: "none",
                                    borderRadius:
                                      "1px" ,
                                    height: "40px",
                                    paddingTop: "1%",
                                    marginBottom: "6%",
                                  }}
                                >
                                  Next{" "}
                                
                              </button>
                            </div>
                          </form>
                         
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        {/* End #main */}
       {/* ======= Footer ======= */}
    <Footer />
      </div>
    );
  }
}
export default Companysignuptwo;
