import React from "react";
import './Footer.css';
import Logo from "../../images/footerlogo.png";
import Footerimg from "../../images/footer.PNG";
import { Link } from "react-router-dom";
const handleRetake=()=>{
  window.scrollTo({
    top: 0,
    behavior: 'smooth' // for smoothly scrolling
  });
  const retakedata = { userid: sessionStorage.getItem("someData") ,

};
if(sessionStorage.getItem("someData")==null){
  window.location.href=`./emplyoabilityone`
}
else{
  console.log(retakedata)
fetch("https://www.gigeconome.com:5200/api/getstEOAdaysvalidinfo", {
method: "POST",

body: JSON.stringify(retakedata), // data can be `string` or {object}!

headers: { "Content-Type": "application/json" },
})
.then((res) => res.json())

.catch((error) => console.error("Error:", error))

.then((response) => {
  console.log(response)
if(response.validdays==90 || response.validdays>=90){
  window.location.href=`./emptestdescription`
}
else if(response.validdays<90){
  window.location.href=`./retakeemptest`
}

else{
    console.log("error")
}
})
}

  
}
const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth' // for smoothly scrolling
  });
  sessionStorage.removeItem("SearchText");
  sessionStorage.setItem("SearchText","");
};
class Footer extends React.Component{
  constructor(){
    super()
    this.state={

    }
    this.handleTwitter=this.handleTwitter.bind(this)
    this.handleFacebook=this.handleFacebook.bind(this)
    this.handleLinkedin=this.handleLinkedin.bind(this)
  }
  handleTwitter(){
    
    window.open("https://twitter.com/gigeconome1","_blank")
  }
handleFacebook(){
  window.open("https://www.facebook.com/gigeconome2/","_blank")
}
handleLinkedin(){
  window.open("https://www.linkedin.com/company/gigeconome/about/","_blank")
}
    render(){
        return(
            <div>
                     {/* ======= Footer ======= */}
      <footer id="footer">
        <div className="footer-top">
          <div className="container">
            <div className="row footer-logo">
              <div className="col-lg-3 col-sm-12 alignlogo">
                <Link to="/">
                <img src={Logo} style={{width:"85%"}} />
                </Link>
                
              </div>
              <div className="col-md-4 newslettertxt" style={{marginTop:"2.5%"}}>
              <span  className="newslettertext">Sign up to our Newsletter</span>
              </div>
              <div class="col-lg-5 newsletter" style={{marginTop:"2.5%"}}>
    
    <div class="form-search" method="get" action="#">
            <input type="search" name="search" placeholder="Enter Your Email" />
            <button type="submit">Submit</button>
            
        </div></div>
             
                {/*  
                 <div className="col-lg-5 newsletter">
                <span  className="newslettertext">Sign up to our Newsletter</span> <input type="text" className="email-news" placeholder="email@email.com" name />
        <input className="news-submit" type="submit" defaultValue="submit" name /> 
            </div>
        */}
       
  
            </div>
            <div className="row footermarginleft">
            {/**  <div className="col-lg-2 col-md-6 footer-links" id="socialmediaacc">
                <h4>Follow Us On</h4>
               <div className="row">
                <a href="https://twitter.com/gigeconome1" target="_blank" class="fa fa-twitter"></a>
                <a href="https://www.facebook.com/gigeconome2/" target="_blank" class="fa fa-facebook"></a>
                <a href="https://www.instagram.com/gigeconome/?hl=en-gb" target="_blank" class="fa fa-instagram"></a>
                <a href="https://www.linkedin.com/company/gigeconome/about/" target="_blank" class="fa fa-linkedin"></a>
                <a href="#" class="fa fa-youtube"></a>
                <a href="https://www.tiktok.com/@gigeconome" target="_blank" class="bi bi-tiktok" id="tiktoklogo"></a>
               
               </div>
              </div> */}
              <div className="col-lg-2 col-md-6 footer-links">
                <h6 >For Talents</h6>
                <ul className="mviewfooterheding">
                  <li>
                    {" "}
                    <Link to="/studentHowitswork" onClick={()=>scrollToTop()}>How it works</Link>
                  </li>
                  <li>
                    {" "}
                   <Link to="/companysearch" onClick={()=>scrollToTop()}>Who’s hiring</Link>
                  </li>
                  <li>
                    {" "}
                    <a onClick={handleRetake} style={{cursor:"pointer"}} className="exploretext">Career Readiness</a>
                    
                  </li>
                  <li>
                    {" "}
                    <Link to="/faq" onClick={()=>scrollToTop()}>FAQ</Link>
                  </li>
                  <li>
                    {" "}
                    <Link to="/home">Communities</Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-2 col-md-6 footer-links">
                <h6 >Employers</h6>
                <ul className="mviewfooterheding">
                  <li>
                    {" "}
                    <Link to="/company" onClick={()=>scrollToTop()}>How it works</Link>
                  </li>
                  <li>
                    {" "}
                    <Link to="/studentsearch" onClick={()=>scrollToTop()} className="exploretext" >
                      Explore Student Talents</Link>
                  </li>
                  <li>
                    {" "}
                    <a href="#">Our Educators</a>
                  </li>
                  <li>
                    {" "}
                    <Link to="/faq" onClick={()=>scrollToTop()}>FAQ</Link>
                  </li>
                  <li>
                    {" "}
                    <Link to="/home">Communities</Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-2 col-md-6 footer-links">
                <h6>About</h6>
                <ul className="mviewfooterheding">
                  <li>
                    {" "}
                    <Link to="/code"  onClick={()=>scrollToTop()}>Code of Conduct</Link>
                  </li>
                  <li>
                    {" "}
                    <Link to="/cookiepolicy"  onClick={()=>scrollToTop()}>Cookies Policy</Link>
                  </li>
                  <li>
                    {" "}
                    <Link to="/termsandcondition" onClick={()=>scrollToTop()}>Terms of Conditions</Link>
                  </li>
                  <li>
                    {" "}
                    <Link to="/privacy" onClick={()=>scrollToTop()}>Privacy Policy</Link>
                  </li>
                  
                  <li>
                    {" "}
                    <Link to="/inclusion" onClick={()=>scrollToTop()} className="inctext" >
                      Inclusion &amp; Diversity Policy
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-2 col-md-6 footer-links" style={{display:"none"}}>
                <h6 >Educators</h6>
                <ul className="mviewfooterheding">
                  <li>
                    {" "}
                    <Link onClick={()=>scrollToTop()} to="/educators">How its work</Link>
                  </li>
                  <li>
                    {" "}
                    <Link onClick={()=>scrollToTop()} to="/requestademo">Request Demo</Link>
                   
                  </li>
                  <li>
                    {" "}
                    <Link to="/home" onClick={()=>scrollToTop()}>Our Partners</Link>
                    
                  </li>
                  <li>
                    {" "}
                    <Link to="/faq" onClick={()=>scrollToTop()}>FAQ</Link>
                  </li>
                  <li>
                    {" "}
                    <Link to="/home" >Communities</Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-6 footer-links" style={{visibility:"hidden"}}>
                <h6 >Company</h6>
                <ul className="mviewfooterheding">
                  <li>
                    {" "}
                    <Link to="/aboutus"  onClick={()=>scrollToTop()}>About Us</Link>
                  </li>
                  <li>
                    {" "}
                    <a target="_blank" href="https://calendly.com/gigeconome">Book a Demo</a>
                   
                  </li>
                  <li>
                    {" "}
                    <Link to="/faq" onClick={()=>scrollToTop()}>FAQ</Link>
                  </li>
                  <li>
                    {" "}
                    <a href="#">Payment & Fees</a>
                  </li>
                  <li>
                    {" "}
                    <Link to="/contactus"  onClick={()=>scrollToTop()}>Contact Us</Link>
                  </li>
                </ul>
              </div>
              
              <div className="col-lg-3 col-md-6 footer-links">
                <h6 >Join our Community</h6>
                <ul className="mviewfooterheding">
                  <li>
                    {" "}
                    <Link to="/home" onClick={()=>scrollToTop()}>info@graduana.com</Link>
                  </li>
                  <li>
                    {" "}
                    <Link to="/home" onClick={()=>scrollToTop()} className="exploretext" >
                      LinkedIn</Link>
                  </li>
                  <li>
                    {" "}
                    <a href="#">Facebook</a>
                  </li>
                  <li>
                    {" "}
                    <Link to="/home" onClick={()=>scrollToTop()}>Instagram</Link>
                  </li>
                  <li>
                    {" "}
                    <Link to="/home">Tiktok</Link>
                  </li>
                </ul>
               {/** <div className="social-links text-center text-md-end pt-3 pt-md-0" style={{marginTop:"-4%"}} >
                 <div className="row">
                   <div className="col-sm-2" >
                   <a href="https://twitter.com/gigeconome1"  target="_blank" class="fa fa-twitter"></a>
                   </div>
                   <div className="col-sm-1" style={{cursor:"pointer"}} onClick={this.handleTwitter}></div>
                   <div className="col-sm-2">
                   <a href="https://www.facebook.com/gigeconome2/" target="_blank" class="fa fa-facebook"></a>
                   </div>
                   <div className="col-sm-1"  style={{cursor:"pointer"}} onClick={this.handleFacebook}></div>
                   <div className="col-sm-2">
                   <a href="https://www.instagram.com/gigeconome/?hl=en-gb" target="_blank" class="fa fa-instagram"></a>
                   </div>
                 </div>
                 <div className="row">
                   <div className="col-sm-2">
                   <a href="https://www.linkedin.com/company/gigeconome/about/" target="_blank" class="fa fa-linkedin"></a>
                   </div>
                   <div className="col-sm-1" style={{cursor:"pointer"}} onClick={this.handleLinkedin}></div>
                   <div className="col-sm-2">
                   <a href="#" class="fa fa-youtube"></a>
                   </div>
                   <div className="col-sm-1"></div>
                   <div className="col-sm-2 mt-1" style={{marginLeft:"2%"}} >
                   <a href="https://www.tiktok.com/@gigeconome" target="_blank" class="bi bi-tiktok"></a>
                   </div>
                 </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="container py-4 text-center">
          <div className="me-md-auto text-center text-md-start">
            <div className="copyright text-center">
              © Copyright{" "}
              <strong>
                <span> 2023. </span>
              </strong>{" "}
              All Rights Reserved
            </div>
          </div>
        </div>
      </footer>
      {/* End Footer */}
            </div>
        )
    }
}
export default Footer;