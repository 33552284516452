import React, { useState } from "react";

import "./modal.css";

function close(){
    window.location.reload();
  }
export default class  PublicationAddModal extends React.Component{
  
  constructor() {
    super();
    let comments = JSON.parse(sessionStorage.getItem("someData"));
   
   console.log(comments)
    this.state = {
      
    
     
        userid: comments,
        addstudata :"publisher",
       
        dropdownlist_input : "nationality",
       
        selectOptions : [],
        selectSchool:[],
        selectQualification:[],
        title:"",
        publisher:"",
        year:"",
        area:"",
        linktopublish:"",
        synopsis:"",
        showModal: false
    
  
  }
  this.handleSubmit = this.handleSubmit.bind(this);
}

  handleSubmit = (event) => {
    event.preventDefault()
   
    const data = {
        userid: this.state.userid,
        addstudata: this.state.addstudata,
        title:this.state.title,
        publisher:this.state.publisher,
        year:this.state.year,
        startdate:this.state.startdate,
        area:this.state.area,
        linktopublish:this.state.linktopublish,
        description:this.state.description,
        synopsis:this.state.synopsis
    }
  
    let BaseUrl = JSON.parse(sessionStorage.getItem("baseurl"));

    fetch( "https://www.gigeconome.com:5200/api/addstu_profiledata", {
      method: "POST",

      body: JSON.stringify(data), // data can be `string` or {object}!

      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())

      .catch((error) => console.error("Error:", error))

      .then((response) => {
         
        this.setState({geomessage:response.message})
        window.location.reload();
       
        
      });
  
}
componentDidMount(){
  this.getOptions()
}
async getOptions(){
const data_one={dropdownlist_input:this.state.dropdownlist_input,

}

let BaseUrl = JSON.parse(sessionStorage.getItem('baseurl'));

fetch('https://www.gigeconome.com:5200/api/getdropdownlist', { method: 'POST', 

 body: JSON.stringify(data_one), // data can be `string` or {object}!

 headers:{ 'Content-Type': 'application/json' } })

 .then(res => res.json())

 .catch(error => console.error('Error:', error))

 .then((json)=>{
  const data = json.result
  
  this.setState({selectOptions: data})
 })
 const data_two={dropdownlist_input:"areaofpublish"

 }

 //let BaseUrl = JSON.parse(sessionStorage.getItem('baseurl'));

 fetch( 'https://www.gigeconome.com:5200/api/getdropdownlist', { method: 'POST', 

  body: JSON.stringify(data_two), // data can be `string` or {object}!

  headers:{ 'Content-Type': 'application/json' } })

  .then(res => res.json())

  .catch(error => console.error('Error:', error))

  .then((json)=>{
   const data = json.result
   
   this.setState({selectSchool: data})
  })




  const qual_data={dropdownlist_input:"qualification"

}

//let BaseUrl = JSON.parse(sessionStorage.getItem('baseurl'));

fetch('https://www.gigeconome.com:5200/api/getdropdownlist', { method: 'POST', 

body: JSON.stringify(qual_data), // data can be `string` or {object}!

headers:{ 'Content-Type': 'application/json' } })

.then(res => res.json())

.catch(error => console.error('Error:', error))

.then((json)=>{
const data = json.result

this.setState({selectQualification: data})
})

}
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
     
    });
    
  };

  openModal = () => {
    this.setState({ showModal: true });
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

render(){
const {selectSchool}=this.state;
  const{geomessage}=this.state
  return (
    <>
     {this.state.showModal && (
      <div class="ft-modal" id="ft-pubadd-modal">
        <div class="ft-modal-content" >
          <div class="ft-modal-header">
            <div class="header">
              <h5
                class="modal-title"
                id="exampleModalLabel"
                style={{
                  textAlign: "center",
                  fontSize: 25,
                  fontWeight: "bold",
                }}
              >
                Add Publications / Research Paper
              </h5>
              <hr />
            </div>
          </div>
          <div class="ft-modal-body">
            <div class="row mt-4">
              <div class="col-sm-2"></div>
              <div class="col-sm-8">
                <form onSubmit = {this.handleSubmit} style={{fontSize:15}}>
                <div class="row">
                      
                      <div class="col-sm-12">
                        Title
                        <div>
                          <input
                            type="text"
                            placeholder="Enter the Title"
                            className="form-control mt-1"
                            name ="title" 
                            onChange = {this.handleChange}
                          />
                        </div>
                      </div>

                      <div class="col-sm-1"></div>
                    </div>

                    <div class="row">
                     
                      <div class="col-sm-5">
                        Publisher
                        <div>
                          <input
                            type="text"
                            placeholder="Enter the Publisher"
                            className="form-control mt-1"
                            name ="publisher" 
                            onChange = {this.handleChange}
                          />
                        </div>
                      </div>

                      <div class="col-sm-2"></div>
                      <div class="col-sm-5">
                        Year of Publish
                        <div>
                        <input type="text" class="form-control mt-1"
                       name ="year" 
                       onChange = {this.handleChange}
                      placeholder="Select Year"
                      onFocus={(e) =>
                        (e.currentTarget.type = "month")
                      }
                      onBlur={(e) =>
                        (e.currentTarget.type = "text")
                      }
                     
                      />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      
                      <div class="col-sm-5">
                        Area
                        <div>
                        {selectSchool.length == 0
                    ? "Loading Area of Publish..."
                    : 

                    <select class="form-select"  name ="area" 
                    onChange = {this.handleChange}>
                      <option>Select</option>
                    {selectSchool.map(fbb =>
                    
                      <option key={fbb.key} value={fbb.id}>{fbb.name}</option>
                      
                    )};
                  </select>
                    } 
                        </div>
                      </div>

                      <div class="col-sm-2"></div>
                      <div class="col-sm-5">
                        Link to Publish
                        <div>
                          <input
                            type="text"
                            placeholder="Link to Publications "
                            className="form-control mt-1"
                            name ="linktopublish" 
                    onChange = {this.handleChange}
                          />
                        </div>
                      </div>
</div>
                      <div class="row mt-2">
                       
                        <div class="col-sm-12">
                          <form>
                            <div class="form-group">
                              <label for="comment">Synopsis</label>
                              <textarea
                                class="form-control mt-1"
                                rows="5"
                                name ="synopsis" 
                    onChange = {this.handleChange}
                              ></textarea>
                            </div>
                          </form>
                        </div>
                        <div class="col-sm-1"></div>
                      </div>
                      
                   
                      <div class="col-md-12" style={{marginTop:"2%"}}>
<button style={{float:"right"}} class="btn-success" 
id="education_btncancel" type="submit" >Save</button>
<button style={{float:"right",marginRight:"2%"}}
onClick={()=>close()}
class="btn-secondary" id="education_btn">Cancel</button>



</div>
                <span style={{color:"rgb(0,147,69)",fontSize:15,marginTop:"5%",fontWeight:"bold"}}>{geomessage}</span>
                </form>
              </div>
              <div class="col-sm-2"></div>
            </div>{" "}
            <div class="row mt-4">
              <div class="col-sm-2"></div>
              <div class="col-sm-8">
               
              </div>
              <div class="col-sm-2"></div>
            </div>
            <div class="mt-4">
              <div class="col-sm-4"></div>
              <div class="col-sm-7">
               
              </div>
              <div class="col-sm-1"></div>
            </div>
          </div>
          <div class="ft-modal-footer">
            <a class="ft-modal-close" data-dismiss="modal"
                     onClick={this.closeModal}>
              [&#10006;]{" "}
            </a>
          </div>
        </div>
      </div>

     )}



      <a
          id="add_btnfour"
          className="btn btn-sm mt-2 stuworkbtn"
          href="#ft-pubadd-modal"
        
          onClick={this.openModal}
        >
          Add Publications / Research
        </a>
    </>
  );
}
}
