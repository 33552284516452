import React, { Component } from "react";
import Header from "../Header/Header";
import Login1 from "../../images/Login-28.png";
import Login2 from "../../images/Login-29.png";
import Login3 from "../../images/Login-30.png";
import Login4 from "../../images/Login-31.png";
import LoadingIcon from "../../images/loading2.gif";
import Footer from "../Footer/Footer";
import swal from "sweetalert";
import { GoogleLogin } from "react-google-login";
import Studentsignup_two from "./Studentsignup_two.js";
import api from "./../../config.js";
const responseGoogle = (response) => {
  console.log(response);
};
class Studentsignup_one extends React.Component {
  constructor() {
    super();

    this.state = {
      name: "First name",
      lastname:"Last name",
      logintype: "Normal",
      email: "",
      data: undefined,
      usertype: "Student",
      companyname: "",
      companustatus: "",
      DataisLoaded: true,
      emailerror: "",
      guestemail:""
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
componentDidMount(){
 if( JSON.parse(sessionStorage.getItem('Guest Id'))==null && (sessionStorage.getItem('someData'))==null ){
  this.setState({guestemail:0})
  console.log("error")
 }
 else{
  
  var guestemail=JSON.parse(sessionStorage.getItem('Guest Email'))
  this.setState({guestemail:guestemail,email:guestemail})
 }
}
  handleSubmit(event) {
    event.preventDefault();
    var emailregx="^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$"
    if (this.state.email === "") {
      this.setState({ emailerror: "Please Enter a Email ID " });
    } else if (this.state.email.match(emailregx)) {
      const data = {
        name: this.state.name,
        email: this.state.email,
        logintype: this.state.logintype,
        usertype: this.state.usertype,
        companyname: this.state.companyname,
        companustatus: this.state.companustatus,
      };
console.log(data)
     
      fetch("https://www.gigeconome.com:5200/api/register", {
        method: "POST",

        body: JSON.stringify(data), // data can be `string` or {object}!

        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())

        .catch((error) => console.error("Error:", error))

        .then((response) => {
          console.log("Success:", response);

          if(response.status==0){
            this.setState({emailerror:response.message})
          }
          else if(response.status==2){
            var dataToStore = JSON.stringify(response.userid);
            sessionStorage.setItem("someData", dataToStore);
            window.location.href = `./Studentsignup_two`;
          }
        else if(response.message=="The user for Student has been successfully inserted.") {
          var dataToStore = JSON.stringify(response.userid);
          sessionStorage.setItem("someData", dataToStore);
          window.location.href = `./Studentsignup_two`;
        }
         
          
        });
    }
    else{
      this.setState({emailerror:"Please Enter a Valid Email ID"})
    }
  }
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    const { DataisLoaded } = this.state;
    const { emailerror } = this.state;
    if (!DataisLoaded)
      return (
        <div>
           <Header />
          <div class="text-center">
            <img src={LoadingIcon} className="loadingicon" />{" "}
          </div>
          <Footer />
        </div>
      );

    return (
      <div>
   
          <Header />
    
        <main id="main" style={{ background: "#303030", clear: "both" }}>
          <br />
          <br />
          {/* ======= About Section ======= */}
          <section id="clients" className="clients">
            <div className="container" data-aos="fade-up">
              <style
                type="text/css"
                dangerouslySetInnerHTML={{
                  __html:
                    "\n    .boxxlr{\n     border: 0px solid green;\n    background: #edf8f2;\n    margin-right: 2.5%;\n    margin-bottom: 7%;\n    color: #000;\n    margin-left: 9%;\n    width: 100%;\n    }\n/*.boxxlr:hover {\n    border: 0px solid green;\n    background: green;\n    margin-right: 1%;\n    margin-bottom: 1%;\n     color: #fff;\n}*/\n\n.boxclrtitle {\nfont-size: 12px;font-weight: bold;\n}\n\n.boxclrtitle1 {\nfont-size: 18px;font-weight: bold;\n}\n/*.boxxlr .boxclrtitle:hover {\nfont-size: 12px;font-weight: bold;\n}*/\n\n\n  ",
                }}
              />
              <div className="row no-gutters">
                <div className="content col-xl-12 d-flex align-items-center">
                  <div className="content col-xl-12 d-flex align-items-center" id="s_signupstep1" >
                    <div
                      className="row signupbox"
                      style={{
                        display: "flex",
                        justifyContent: "center" /* alignItems: 'center', */,
                        background: "#fff" /* width: '80%', */,
                        /* marginLeft: '50%', */
                        borderRadius: "20px",
                        margin: "auto",
                      }}
                    >
                      <h2
                        style={{
                          fontSize: "20px",
                          textAlign: "center",
                          color: "#fff",
                         
                          background: "rgb(0, 147, 69)",
                          padding: "1%",
                          width: "70%",
                          margin: "6%",
                          fontWeight:"bold"
                        }}
                      >
                        {" "}
                        Create an Account{" "}
                      </h2>
                      <div style={{textAlign:"center"}} >
                        <span className="signup1subheading" style={{fontFamily:"Gotham",color:"rgb(0,147,69)",padding:"1%"}} >Join the GigeconoMe Community! Gain Experience. Build Your Network. Get Paid.</span>
                      </div>
                      <div className="row h-100 row align-items-center">
                        <div className="col-md-10" style={{ margin: "auto" }}>
                         
                          
                           <input
                              id="name"
                              name="name"
                              type="hidden"
                              onChange={this.handleChange}
                             
                            />
                            <div
                              className="form-group"
                              style={{ padding: "2%", borderRadius: "1px",marginTop:"5%" }}
                            >
                               {this.state.guestemail==0?<>
                                <input
                              
                              name="email"
                      
                              className="form-control signupinput"
                              placeholder="Email Id"
                              onChange={this.handleChange}
                             
                            />
                            </>:<>
                            <input
                              
                              name="email"
                              value={this.state.guestemail}
                              className="form-control signupinput"
                              placeholder="Email Id"
                              onChange={this.handleChange}
                              disabled
                            />
                            </>}
                             
                             
                            </div>
                            <span style={{fontWeight:"bold",color:"red",marginLeft:"2%"}}>{emailerror}</span>
                            <input
                              id="logintype"
                              name="logintype"
                              type="hidden"
                              onChange={this.handleChange}
                            />
                            <input
                              id="usertype"
                              name="usertype"
                              type="hidden"
                              onChange={this.handleChange}
                            />
                            <input
                              id="companyname"
                              name="companyname"
                              type="hidden"
                              onChange={this.handleChange}
                            />
                            <input
                              id="companustatus"
                              name="companustatus"
                              type="hidden"
                              onChange={this.handleChange}
                            />

                            <button
                            onClick={this.handleSubmit}
                              value="validate"
                              style={{
                                padding: "2%",
                                marginLeft: "38%",
                                marginBottom: "4%",
                                color: "#fff",
                                background: "rgb(0, 147, 69)",
                                width: "26%",
                                border: "none",
                                borderRadius: "1px",
                                marginTop: "5%",
                                height: "40px",
                              }}
                            >
                              Create
                            </button>
                          <br/>
                          <div
                            style={{
                              float: "left",
                              width: "45%",
                              marginRight: "3%",
                            }}
                          >
                            <hr />
                          </div>
                          <div style={{ float: "right", width: "44%" }}>
                            <hr />
                          </div>
                          Or
                          <h6
                            style={{
                              textAlign: "center",
                              fontFamily: "GothamLight",
                              fontWeight: "bold",
                            }}
                          >
                            With Your Social Network
                          </h6>
                          <div className="signupsmediaicon">
                            <span
                              style={{
                                fontSize: "20px",
                                marginTop: 2,
                                letterSpacing: "1px",
                                marginRight: "5%",
                                padding: "1%",
                                marginLeft: "-1%",
                              }}
                            >
                              <GoogleLogin
                                clientId="65947149520-ali3h7qt2v8b6eu5vm1nrtuhkkhq3bmu.apps.googleusercontent.com"
                                render={(renderProps) => (
                                  <button
                                    onClick={renderProps.onClick}
                                    disabled={renderProps.disabled}
                                  >
                                    <img src={Login1} />{" "}
                                  </button>
                                )}
                                onSuccess={responseGoogle}
                                onFailure={responseGoogle}
                                cookiePolicy={"single_host_origin"}
                              />
                            </span>
                            <span
                              className="btn btn-primary"
                              style={{
                                background: "#fff",
                                borderColor: "white",
                                marginTop: "-1%",
                                borderRadius: "1px",
                                fontSize: "22px",
                                letterSpacing: "1px",
                                padding: "0%",
                                marginRight: "5%",
                              }}
                            >
                              <img src={Login2} />
                            </span>
                            <span
                              className="btn btn-primary"
                              style={{
                                background: "#fff",
                                borderColor: "white",
                                marginTop: "-1%",
                                borderRadius: "1px",
                                fontSize: "22px",
                                letterSpacing: "1px",
                                padding: "0%",
                                marginRight: "5%",
                              }}
                            >
                              <img src={Login3} />
                            </span>
                            <span
                              className="btn btn-primary"
                              style={{
                                background: "#fff",
                                marginTop: "-1%",
                                borderColor: "white",
                                borderRadius: "1px",
                                fontSize: "22px",
                                letterSpacing: "1px",
                                padding: "0%",
                                marginRight: "5%",
                              }}
                            >
                              <img src={Login4} />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        {/* End #main */}

        <Footer />
      </div>
    );
  }
}

export default Studentsignup_one;
