import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import './navbar.css';
import { Search } from "./Search";

 function search(){
  alert("suces")
}

function Nav() {
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);
  return (
    <div class="my-0" style={{ marginLeft: "0%" }}>
      <nav
        class="navbar navbar-expand-xl navbar-dark"
        style={{ backgroundColor: "#565455" }}
      >
        <div className="container-fluid d-flex align-items-left" id="second_nav">
        
          <button
            class="custom-toggler navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarCollapse14"
            aria-controls="navbarsExample09"
            aria-expanded={!isNavCollapsed ? true : false}
            aria-label="Toggle navigation"
            onClick={handleNavCollapse}
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div
            class={`${isNavCollapsed ? "collapse" : ""} navbar-collapse`}
            id="navbarCollapse14"
            
          >
            <div class="navbar-nav" id="programming">
              <a href="#" class="nav-item nav-link active">
              Intelligent Role Matching 
              </a>
              <a
                href="#"
                class="nav-item nav-link active subheadingnav"
               
              >
                Connect with Employers
              </a>
              <a
                href="#"
                class="nav-item nav-link active subheadingnav"
                
              >
                Apply in Minutes 
              </a>
              <a
                href="#"
                class="nav-item nav-link active subheadingnav"
               
              >
               No Job Board
              </a>
            
            </div>
           
            <Search />
           
          
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Nav;
