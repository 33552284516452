import React, { useState } from "react";
import "./navbar.css";
export default function SpaceModal() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div class="ft-modal" id="ft-space-modal">
        <div class="ft-modal-content">
          <div class="ft-modal-header">
            <div class="header">
              <h5
                class="modal-title"
                id="exampleModalLabel"
                style={{
                  textAlign: "center",
                  fontSize: 25,
                 
                }}
              >
                Create a Space<br />
                <div style={{fontFamily:"GothamLight",fontSize:15}}>Sub Text</div>
              </h5>
              <hr style={{color:"rgb(0, 147, 69)",fontWeight:"bold",padding:"0.1%"}} />
            </div>
          </div>
          <div class="ft-modal-body">
            
            <div class="row mt-4">
              <div class="col-sm-1"></div>
              <div class="col-sm-10">
                <label
                  style={{
                    fontSize: 18,
                    fontWeight: "bold",
                   
                  }}
                >
                 Name
                </label>{" "}
                
               <input type="text" 
               className="form-control mt-1"
               
               style={{border:"2px solid rgb(0, 147, 69)",
               backgroundColor:"hsl(0, 0%, 90%)"
               }} />
               <br />
               <label
                  style={{
                    fontSize: 18,
                    fontWeight: "bold",
                   
                  }}
                >
               Brief Description <br />
               <span style={{fontSize:12,marginTop:-10}}>Sub text</span>
                </label>{" "}
                <textarea class="form-control mt-2" style={{border:"2px solid rgb(0, 147, 69)",
                 backgroundColor:"hsl(0, 0%, 90%)"}} 
                id="exampleFormControlTextarea1" rows="5"></textarea>

              </div>
              <div class="col-sm-1"></div>
            </div>
            <div class="modal-footer mt-4">
              <div class="col-sm-4"></div>
              <div class="col-sm-6">
              
              </div>
              <div class="col-sm-2">
              <button
                  style={{ backgroundColor: "rgb(0, 147, 69)",
                   width: 150,fontFamily:"GothamLight" }}
                  className="btn btn-success"
                >
                 Create
                </button>
              </div>
              <div class="col-sm-2"></div>
            </div>
          </div>
          <div class="ft-modal-footer">
            <a class="ft-modal-close" href="#">
              [&#10006;]{" "}
            </a>
          </div>
        </div>
      </div>

      
      <a id="v-pills-more-tab" className="btn btn-success"   href="#ft-space-modal">More Subjects</a>
    </>
  );
}
