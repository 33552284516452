import React from "react";
import { Link } from "react-router-dom";
import Logo from '../../images/logo.png'
import Login from '../../images/Login-32.png';
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import LoadingIcon from '../../images/loading2.gif';
import axios from "axios";

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth' // for smoothly scrolling
  });
  
};
class Studentsignup_orgsize extends React.Component {
  constructor() {
    super();
   
    let comments = JSON.parse(sessionStorage.getItem('someData'));
    //let Intrestss = sessionStorage.getItem('Intrest');
   

    this.state = {
    
     
      stuinterest:[],
      userid:comments,
      reg_level:42,
      
      DataisLoaded: false,
      error:"",
      pass:"",
      selectOptions : [],
      booleanChecked: false,
      opp:"",
      selectOpportunity:[],
      showModal: false,
      formattedString:"",
      inputdata:"opportunitiestype",
      oppid:[],
      opportunitiestypeid:[],

      opportunitiestype: {
        None: false,
        "1 person": false,
         "2-10 ppl": false,
         "11-49 ppl": false,
         "50 - 249 ppl": false,
         "250 - 999 ppl":false,

      },
      
      };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount(){
    this.getOptions()
}
async getOptions(){
  const data_one={dropdownlist_input:this.state.dropdownlist_input,
  
  }

  let BaseUrl = JSON.parse(sessionStorage.getItem('baseurl'));

  fetch( 'https://www.gigeconome.com:5200/api/getdropdownlist', { method: 'POST', 

   body: JSON.stringify(data_one), // data can be `string` or {object}!

   headers:{ 'Content-Type': 'application/json' } })

   .then(res => res.json())

   .catch(error => console.error('Error:', error))

   .then((json)=>{
    
    
    const data = json.result
   
    
    this.setState({selectOptions: data})
    this.setState({
      DataisLoaded: true 
    })
   })
}
  handleSubmit(event) {
  
    const data = {  orgsize:this.state.opportunitiestypeid.toString(),
     
      userid:this.state.userid, reg_level:this.state.reg_level }

      console.log(data)
      fetch('https://www.gigeconome.com:5200/api/stuserinfo', { method: 'POST', 

    body: JSON.stringify(data), // data can be `string` or {object}!

    headers:{ 'Content-Type': 'application/json' } })

    .then(res => res.json())

    .catch(error => console.error('Error:', error))

    .then((response)=>{
        console.log( response.message)
         if(response.message=='Data has been updated successfully.'){
          window.location.href=`./stusignupindustry`
         }
         else{
          // alert(response.message)
           window.location.href=`./stusignupindustry`
         }  
            this.setState({ data: response.userid });
        
    })
   
          
  

    
       
    
   }
   componentDidMount() {
 
          
          
          
  }
  handleChange = (event) => {

    const { name, checked } = event.target;
    //console.log("====>", name, checked);
  
    this.setState((prevState) => {
      const opportunitiestype = prevState.opportunitiestype;
     // console.log("============>", opportunitiestype);
      opportunitiestype[name] = checked;
      return opportunitiestype;
    });
   
  };

  render(){
    this.state.opportunitiestypeid = Object.keys(this.state.opportunitiestype).filter(
      (key) => this.state.opportunitiestype[key]
    );
    return (
    <div>
      <div>
       
       
     <Header />
      {/* End Header */}
        <main id="main" style={{ background: "#303030", clear: "both" }}>
          <br />
          <br />
          {/* ======= About Section ======= */}
          <section id="clients" className="clients">
            <div className="container" data-aos="fade-up">
              <style
                type="text/css"
                dangerouslySetInnerHTML={{
                  __html:
                    "\n    .boxxlr{\n     border: 0px solid green;\n    background: #edf8f2;\n    margin-right: 2.5%;\n    margin-bottom: 7%;\n    color: #000;\n    margin-left: 9%;\n    width: 100%;\n    }\n/*.boxxlr:hover {\n    border: 0px solid green;\n    background: green;\n    margin-right: 1%;\n    margin-bottom: 1%;\n     color: #fff;\n}*/\n\n.boxclrtitle {\nfont-size: 12px;font-weight: bold;\n}\n\n.boxclrtitle1 {\nfont-size: 18px;font-weight: bold;\n}\n/*.boxxlr .boxclrtitle:hover {\nfont-size: 12px;font-weight: bold;\n}*/\n\n\n  ",
                }}
              />
              <div className="row no-gutters">
                <div className="content col-xl-12 d-flex align-items-center">
                  <div className="content col-xl-12 d-flex align-items-center">
                    <div
                      className="row ssignupstepthree"
                      style={{
                        display: "flex",
                        justifyContent: "center" ,
                        background: "#fff",
                      
                        borderRadius: "20px",
                        margin: "auto",
                      }}
                    >
                 
                      <div className="row h-100 row align-items-center">
                 
                        <div className="col-md-10" style={{ margin: "auto" }}>
                          
                            <h2
                              style={{
                                fontSize: "20px",
                                textAlign: "center",
                                color: "#fff",
                                fontWeight:"bold",
                                background: "rgb(0, 147, 69)",
                                padding: "1%",
                                marginTop: "10%",
                              }}
                            >
                              {" "}
                              Preferred Organisation Size{" "}
                            </h2>
                          
                        </div>
                        <h6
                         className="tellustxt"
                        >
                         Please select which company size you are interested in working at
                        </h6>
                        
                       
 
                        <div class="col-md-12" style={{textAlign:"left",margin:"4%",paddingTop:"4%",paddingBottom:"4%"}}>
<div class="row bigscrencheckbox">
{
 
 Object.entries(this.state.opportunitiestype).map((cname) => (
   

       <div key={cname[0]} className="columnstylestudent">
                <input
                  checked={cname[1]}
                  onChange={this.handleChange}
                  type="checkbox"
                  name={cname[0]}
                  id="company_check"
                />
              <span>{cname[0]}</span>
              </div>



   ))}
  
</div>
<div class="row mscreencheckbox">
{
 
 Object.entries(this.state.opportunitiestype).map((cname) => (
   

       <div key={cname[0]} className="columnstylestudentmscreen">
                <input
                  checked={cname[1]}
                  onChange={this.handleChange}
                  type="checkbox"
                  name={cname[0]}
                  id="company_check"
                />
              <span>{cname[0]}</span>
              </div>



   ))}
  
</div>
</div>                
                       
                       
                       
                       
                        <div
                          className="container"
                          style={{ marginBottom: "2%" }}
                        >
                          <Link
                            style={{ color: "#fff" }}
                            to="/Studentsignup_four"
                          >
                            <span
                              className="btn"
                              style={{
                              
                                   marginLeft:"10%",
                                    marginTop: "6.1%",
                                    color: "#fff",
                                    background: "#000",
                                    width: "34%",
                                    border: "none",
                                    borderRadius:
                                      "1px" /* marginTop: '-13%', */,
                                    height: "40px",
                                  
                                    marginBottom: "6%",
                              }}
                              onClick={()=>scrollToTop()}
                            >
                              {" "}
                              Back
                            </span>
                          </Link>
                         
                            <button
                             onClick={this.handleSubmit}
                              style={{
                                marginLeft: "8%",
                                marginTop: "6%",
                                color: "#fff",
                                background: "rgb(0, 147, 69)",
                                width: "40%",
                                border: "none",
                                borderRadius:
                                  "1px" /* marginTop: '-13%', */,
                                height: "40px",
                                
                                marginBottom: "5%",
                              }}
                            >
                              
                              Next
                           </button>
                           
                        </div>
                      </div>
                    

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        {/* End #main */}
        {/* ======= Footer ======= */}
   <Footer />
         
      </div>
    </div>
  );
};
}

export default Studentsignup_orgsize;
