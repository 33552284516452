import React from "react";
import USPIMG from "../../images/career.png";
import Tickmark from "../../images/tickmark.png";
export default class Gigvideo extends React.Component{
    constructor(){
        super();
        this.state={

        }
        this.handleStarted=this.handleStarted.bind(this)
        this.handleBook=this.handleBook.bind(this)
    }
    handleStarted(){
        window.location.href=`./signup`
    }
    handleBook(){
        window.location.href=`./emplyoabilityone`
    }
    render(){
        return(
            <>
            <div className="container" style={{marginTop:"8%"}}>
                <div className="row">
                    <div className="col-xl-6 d-flex align-items-stretch bigcompanysearch">
                    <img src={USPIMG} className="newuspimg" />
                    </div>
                    <div className="col-xl-6 d-flex align-items-stretch mscreenava">
                    <img src={USPIMG} style={{width:"85%",margin:"auto",marginTop:"-2em"}} />
                    </div>
                    <div className="col-xl-5 uspcontent" style={{color:"black"}}>
                   <h2 className="bigcompanysearch">Career Readiness starts with <span style={{color:"#00539c",fontWeight:"bold"}}>Gaining real Experience and Skills</span>
                 
                   </h2>
                   <h4 className="mscreenava text-center" style={{padding:"2%"}}>Career Readiness starts with <span style={{color:"#00539c",fontWeight:"bold"}}>Gaining real Experience and Skills</span>
                  
                   </h4>
                   <br/>
                   <table className="bigcompanysearch"> 
                    <tr>
                       
                        <td> <span className="bigcompanysearch"style={{fontFamily:"GothamLight",fontWeight:"bold"}}>
                   
                        Our Career-readiness tool helps our students uncover strength and gaps in-line with the Graduate market which helps us match them to the right opportunity to improve future career prospects
                  </span></td>
                    </tr>
                    <br/>
                    <tr>
                       
                        <td> <span className="bigcompanysearch"style={{fontFamily:"GothamLight",fontWeight:"bold"}}>
                   
                        Our Career-readiness tool helps our Students uncover strengths and gaps in-line with the Graduate market.
                  </span></td>
                    </tr>
                    <br/>
                    <tr>
                       
                        <td> <span className="bigcompanysearch"style={{fontFamily:"GothamLight",fontWeight:"bold"}}>
                   
                        We Clear pathways
                  </span></td>
                    </tr>
                   </table>
          
                   
                   <div className="mscreenava text-center" style={{marginTop:"-10%"}}>
                   <span  style={{fontFamily:"GothamLight",fontWeight:"bold",padding:"2%",fontSize:15}}>
                        Our Career-readiness tool helps our students uncover strength and gaps in-line with the Graduate market which helps us match them to the right opportunity to improve future career prospects
                   </span>
                   </div>
                   <div className="mscreenava text-center" style={{marginTop:"2%"}}>
                   <span  style={{fontFamily:"GothamLight",fontWeight:"bold",padding:"2%",fontSize:15}}> Our Career-readiness tool helps our Students uncover strengths and gaps in-line with the Graduate market.</span>
                    </div>
                    <div className="mscreenava text-center" style={{marginTop:"2%"}}>
                   <span  style={{fontFamily:"GothamLight",fontWeight:"bold",padding:"2%",fontSize:15}}>  We Clear pathways</span>
                    </div>
                    <button className="mscreenava getstartedbtnm text-center mt-5" onClick={this.handleStarted} style={{margin:"auto"}}>Get Started</button>&nbsp;&nbsp;
                    <button className="mscreenava gradbtnm text-center "  onClick={this.handleBook}>Take Career Readiness Test</button>
                    <br/>
                    <br/>
                  
                   <button onClick={this.handleStarted} class="getstartedbtnnew bigcompanysearch">Get Started</button> &nbsp;&nbsp;
                   <button  class="gradbtn bigcompanysearch" onClick={this.handleBook}>Take Career Readiness Test</button>
                    </div>
                </div>
           
            </div>
            </>
        )
    }
}