import React from "react";
import Login5 from '../../images/Login-36.png';
import { Link } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth' // for smoothly scrolling
  });
  sessionStorage.removeItem("SearchText");
  sessionStorage.setItem("SearchText","");
};
class EmpThanksPage extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
   
     
    };
  this.handleHome=this.handleHome.bind(this)
  }
handleHome(){
  window.location.href=`./`
}
    componentDidMount(){
    
    }
  
  render() {

    
    return (
      <div>
         {/* ======= Header ======= */}
      <Header />
      <div>
    

      </div>
      {/* End Header */}
        <main id="main" style={{ background: "#303030", clear: "both" }}>
          <br />
          <br />
          {/* ======= About Section ======= */}
          <section id="clients" className="clients">
            <div
              className="container col-md-5 col-xs-12 col-sm-12 col-lg-6"
              data-aos="fade-up"
            >
              <style
                type="text/css"
                dangerouslySetInnerHTML={{
                  __html:
                    "\n    .boxxlr{\n     border: 0px solid green;\n    background: #edf8f2;\n    margin-right: 2.5%;\n    margin-bottom: 7%;\n    color: #000;\n    margin-left: 9%;\n    width: 100%;\n    }\n/*.boxxlr:hover {\n    border: 0px solid green;\n    background: green;\n    margin-right: 1%;\n    margin-bottom: 1%;\n     color: #fff;\n}*/\n\n.boxclrtitle {\nfont-size: 12px;font-weight: bold;\n}\n\n.boxclrtitle1 {\nfont-size: 18px;font-weight: bold;\n}\n/*.boxxlr .boxclrtitle:hover {\nfont-size: 12px;font-weight: bold;\n}*/\n\n\n  ",
                }}
              />
              <div className="row no-gutters">
                <div className="content col-xl-12 d-flex align-items-center">
                  <div className="content col-xl-12 d-flex align-items-center" style={{width:"108%",marginLeft:"-5%"}}>
                    <div
                      className="row"
                      style={{
                        display: "flex",
                        justifyContent: "center" /* alignItems: 'center', */,
                        background: "#fff",
                        width: "100%" /* marginLeft: '50%', */,
                        borderRadius: "20px",
                        margin: "auto",
                      }}
                    >
              
                      <div className="row h-100 row align-items-center">
                        <div
                          className="col-md-10"
                          style={{ margin: "auto", marginBottom: "6%" }}
                        >
                          
                            <img
                              src={Login5}
                              className="empguestsignuplogo"
                            />
                            <h4 className="empguestsignupmainheading">
                            👏 Test Complete! 👏
                            </h4>
                          <br/><br/>
                            <h4 style={{ textAlign: "center" ,fontFamily:"Gotham",fontSize:20 }}>
                            Thank you for completing the employability assessment,your results have been sent to your email .
                            </h4>
                            <br/><br/>
                            <h4 style={{ textAlign: "center" ,fontFamily:"Gotham",fontSize:20 }}>
                            Ready to boost your skills ?Sign up to GigeconoMe today !
                            </h4>
                       <div className="row mt-5">
                                <div className="col-sm-2"></div>
                                <div className="col-sm-4">
                                    <button onClick={this.handleHome} style={{backgroundColor:"rgb(0,147,69)" ,width:"80%"}} className="btn btn-success" > Back to Home </button></div>
                                <div className="col-sm-4"><Link to="/studentsignup_one" style={{backgroundColor:"rgb(0,147,69)",width:"80%"}} className="btn btn-success" >Sign Up</Link></div>
                       </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        {/* End #main */}
        {/* ======= Footer ======= */}
 <Footer />
      </div>
    );
  }
}
export default EmpThanksPage;
