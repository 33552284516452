import React, { useState } from "react";
import "./modal.css";

function close(){
  window.location.reload();
}
export default class OpportunityModal extends React.Component {
  constructor() {
    super();
    let comments = JSON.parse(sessionStorage.getItem("someData"));
    this.state = {
      isOpen: false,

      selectOptions: [],
      id: "",
      name: "",
      addstudata : "stu_industry",
      userid: comments,
      opportunitiestypeid:[],
      opp:"",
      success:"",
    
      isChecked:true,
      Checked:true,
      dropdownlist_input: "oportunitiestypes",
      selectOpportunity:[],
      showModal: false,
      formattedString:"",
      inputdata:"opportunitiestype",
      oppid:[],

      industryinfo: {
        Accounting: false,
        Advertising: false,
        Agriculture: false,
        Automobile: false,
        Banking: false,
        Biotechnology:false,
        "Business and Information":false,
        Charity:false,
        Construction:false,
        eCommerce:false,
        Education:false,
        Energy:false,
        Engineering:false,
        Environmental:false,
        "Financial Services":false,
        Financials:false,
        Fintech:false,
        Food:false,
        "Food Production":false,
        Government:false,
        "Health and Beauty":false,
        "Health Care":false,
        Hospitality:false,
        "Human Resources":false,
        Industrial:false,
        "Information Services":false,
        "Information Technology/IT":false,
        Insurance:false,
        Legal:false,
        "Management Consulting":false,
        Manufacturing:false,
        Marketing:false,
        "Media Production":false,
        NGO:false,
       "Non-Profit":false,
       Other:false,
        "Pharmaceuticals":false,
        "Public Relations/PR":false,
        "Real Estate":false,
        "Recruitment":false,
         Retail:false,
         "Retail Industry":false,
         Staffing:false,
         Technology:false,
         "Telecommunication":false,
         Transportation:false,
         Travel:false,
         Utilities:false,
         "Venture Capital":false,
        
       
      },
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state.array = [this.state.formattedString]
  
  }
 

    handleSubmit = (event) => {
    
   
const data = {

  userid: this.state.userid,
  addstudata: this.state.addstudata,
  industry:this.state.opportunitiestypeid.toString()
  
 

}

let BaseUrl = JSON.parse(sessionStorage.getItem("baseurl"));

  fetch(BaseUrl + "addstu_profiledata", {
    method: "POST",

    body: JSON.stringify(data), // data can be `string` or {object}!

    headers: { "Content-Type": "application/json" },
  })
    .then((res) => res.json())

    .catch((error) => console.error("Error:", error))

    .then((response) => {
      console.log(response)
    this.setState({success:response.message})
    window.location.reload();
      
    });
    







  
  
  }
  
componentDidMount(){
   
          



        const data_one = {

          userid: this.state.userid,
         
         
        
        }
        
        let BaseUrl = JSON.parse(sessionStorage.getItem("baseurl"));
        
          fetch(BaseUrl + "stuprofileinfo", {
            method: "POST",
        
            body: JSON.stringify(data_one), // data can be `string` or {object}!
        
            headers: { "Content-Type": "application/json" },
          })
            .then((res) => res.json())
        
            .catch((error) => console.error("Error:", error))
        
            .then((response) => {
        if(response.user.industryinfo!==null)
          {

            
            response.user.industryinfo.split(",").map((n) => {
                this.setState((prevState) => {
                  const industryinfo = prevState.industryinfo;
                  console.log("******************", industryinfo);
                  industryinfo[n] = true;
                  return industryinfo;
                });
              });
            //console.log(response.user.opportunitiestype.split(","))
            //this.setState({ formattedString:response.user.opportunitiestype.split(",")})
          }
          else{
           console.log("empty")
          }
              
            });
            
        
        
        
        
        
        

}
 
  handleChange = (event) => {

    const { name, checked } = event.target;
    console.log("====>", name, checked);
    this.setState((prevState) => {
      const industryinfo = prevState.industryinfo;
      console.log("============>", industryinfo);
      industryinfo[name] = checked;
      return industryinfo;
    });
  };
  
  openModal = () => {
    this.setState({ showModal: true });
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  render() {

    this.state.opportunitiestypeid = Object.keys(this.state.industryinfo).filter(
      (key) => this.state.industryinfo[key]
    );
    console.log(this.state.opportunitiestypeid.toString());
    
    return (
      <>
       {this.state.showModal && (
        <div class="ft-modal" id="ft-industry-modal">
          <div class="ft-modal-content"style={{paddingLeft:"5%"}}>
            <div class="ft-modal-header">
              <div class="header">
                <h5
                  class="modal-title"
                  id="exampleModalLabel"
                  style={{
                    textAlign: "center",
                    fontSize: 18,
                    fontWeight: "bold",
                  }}
                >
                  Preferences - Opportunities Types
                </h5>
                <hr style={{marginLeft:"-10.2%",marginRight:"-3%",border:"1px solid"}} />
              </div>
            </div>
          
            <div class="ft-modal-body">
            
              <h5
                style={{
                  textAlign: "center",
                  color: "rgb(0, 147, 69)",
                  fontWeight: "bold",fontSize: 18,
                }}
              >
                {" "}
                Preferences - Industry
              </h5>






  <div class="row" >
   {
 
 Object.entries(this.state.industryinfo).map((cname) => (
     <div class="col-sm-4 oppcheckbox">
       

       <div key={cname[0]}>
                <input
                  checked={cname[1]}
                  onChange={this.handleChange}
                  type="checkbox"
                  name={cname[0]}
                />
               &nbsp;&nbsp; <span style={{fontFamily:"GothamLight",fontWeight:"bold"}}>{cname[0]}</span> 
              </div>


</div> 

   ))}
 
</div>






           <div className="row"> 
           <div class="col-sm-4"></div>
                <div class="col-sm-7">
                  <button
                    style={{ backgroundColor: "rgb(0, 147, 69)", width: 100,
                  marginTop:"8%",marginLeft:"10%"
                  }}
                    className="btn btn-success"
                   onClick={this.handleSubmit}
                  >

                    Save
                  </button>
                </div>
                <div class="col-sm-1"></div>
              </div>
          
               <span style={{color:"rgb(0,147,69)",fontFamily:"Gotham",marginTop:"5%",fontWeight:"bold"}}>{this.state.success}</span>
                
                 
        
            </div>

            <div class="ft-modal-footer">
              <a class="ft-modal-close" data-dismiss="modal"
                     onClick={this.closeModal}>
                [&#10006;]{" "}
              </a>
            </div>
          </div>
        </div>
       )}
        <a
          style={{ marginLeft: 70, color: "green" }}
          href="#ft-industry-modal"
          id="co_text"
          onClick={this.openModal}
          className="industrymodalstu"
        >
          Edit
        </a>
      </>
    );
  }
}
