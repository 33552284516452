import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import LinkedIN from "../../../images/company1.png";
import Instagram from "../../../images/insta.png";
import Twitter from "../../../images/twitter.png";
import Facebook1 from "../../../images/fb.png";
import GraduationYear from "../../Signup/GraduationYear";
import "./modal.css";

function close(){
  window.location.reload();
}
export default class  CodeVerify extends React.Component {
  constructor() {
    super();
  let comments = JSON.parse(sessionStorage.getItem('someData'));
  this.textInput = React.createRef();
  this.state = {
    
      userid:comments,
      FirstName:"",
      LastName:"",
      Studystatus:"",
      selectGraduation:[],
      GraduationYear:"",
      subCourseName:[],
      Course:"",
      selectUniversity:[],
      firstname:"",
      lastname:"",
      StudyStatus:"",
      graduationyear:"",
      Degree:"",
      university:"",
      location:"",
      instagramlink:"",
      linkedinlink:"",
      facebooklink:"",
      twitterlink:"",
      value: '',
      college_University:"",
      showModal: false,
      message:""



      };
    this.handleSubmit = this.handleSubmit.bind(this);
   
   
  }

  openModal = () => {
    this.setState({ showModal: true });
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  componentDidMount(){
 
    const data_one={userid:this.state.userid,
    
    }
  
    let BaseUrl = JSON.parse(sessionStorage.getItem('baseurl'));
  
    fetch(BaseUrl + 'stuprofileinfo', { method: 'POST', 
  
     body: JSON.stringify(data_one), // data can be `string` or {object}!
  
     headers:{ 'Content-Type': 'application/json' } })
  
     .then(res => res.json())
  
     .catch(error => console.error('Error:', error))
  
     .then((json)=>{
      
     
    this.setState({
      firstname:json.user.firstname,
      lastname:json.user.lastname,
      StudyStatus:json.user.StudyStatus,
      GraduationYear:json.user.GraduationYear,
      Course:json.user.Course,
      college_University:json.user.college_University,
      location:json.user.location
    })
     })
     const data_graduation={dropdownlist_input: "graduationyear"}
     fetch( 'https://www.gigeconome.com:5200/api/getdropdownlist', { method: 'POST', 
  
     body: JSON.stringify(data_graduation), // data can be `string` or {object}!
  
     headers:{ 'Content-Type': 'application/json' } })
  
     .then(res => res.json())
  
     .catch(error => console.error('Error:', error))
  
     .then((json)=>{
      
      
      const data = json.result
    
      this.setState({selectGraduation: data})
      this.setState({
        DataisLoaded: true 
      })
     })

     const data_six={dropdownlist_input:"coursenew"}
     fetch( 'https://www.gigeconome.com:5200/api/getdropdownlist', { method: 'POST', 
  
     body: JSON.stringify(data_six), // data can be `string` or {object}!
  
     headers:{ 'Content-Type': 'application/json' } })
  
     .then(res => res.json())
  
     .catch(error => console.error('Error:', error))
  
     .then((json)=>{
      
     let  data=json.result
    this.setState({subCourseName:data})
      
      
    
     })

     const data_seven={dropdownlist_input:"university"}
     fetch( 'https://www.gigeconome.com:5200/api/getdropdownlist', { method: 'POST', 
  
     body: JSON.stringify(data_seven), // data can be `string` or {object}!
  
     headers:{ 'Content-Type': 'application/json' } })
  
     .then(res => res.json())
  
     .catch(error => console.error('Error:', error))
  
     .then((json)=>{
      
     let  data=json.result
    this.setState({selectUniversity:data})
      
      
    
     })
    }

    handleSubmit = (event) => {
      event.preventDefault()
     
     
  const data = {
  
  firstname:this.state.firstname,
  lastname:this.state.lastname,
  StudyStatus:this.state.StudyStatus,
  GraduationYear:this.state.GraduationYear,
  Course:this.state.Course,
  college_University:this.state.college_University,
  location:this.state.location,
  instagramlink:this.state.instagramlink,
  linkedinlink:this.state.linkedinlink,
  facebooklink:this.state.facebooklink,
  twitterlink:this.state.twitterlink,
  userid:this.state.userid
   
    
   
  
  }
  
  let BaseUrl = JSON.parse(sessionStorage.getItem("baseurl"));
  
    fetch(BaseUrl + "editstubasicinfo", {
      method: "POST",
  
      body: JSON.stringify(data), // data can be `string` or {object}!
  
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
  
      .catch((error) => console.error("Error:", error))
  
      .then((response) => {
        console.log(response)
      this.setState({success:response.message})
         window.location.reload();
        
      });
      
    }
    handleChange = (e) => {
      this.setState({
        [e.target.name]: e.target.value,
       
      });
     
      
    };

    handleCode=(e)=>{
      const code_data={
        
        addstudata:"verifyinclusion",
        verifyinclusion:"Yes",
        userid:this.state.userid
      }
      fetch( 'https://www.gigeconome.com:5200/api/addstu_profiledata', { method: 'POST', 
   
      body: JSON.stringify(code_data), // data can be `string` or {object}!
   
      headers:{ 'Content-Type': 'application/json' } })
   
      .then(res => res.json())
   
      .catch(error => console.error('Error:', error))
   
      .then((response)=>{
       
     
      // console.log(response)
       this.setState({message:response.message})
       window.location.reload();
      })
     
    }
render(){
  return (
    <>
     {this.state.showModal && (
      <div class="ft-modal" id="ft-codeofconduct-modal">
        <div class="ft-modal-content">
          <div class="ft-modal-header">
            <div class="header">
              <h5
                class="modal-title"
                id="exampleModalLabel"
                style={{
                  textAlign: "center",
                  fontSize: 25,
                  fontWeight: "bold",
                }}
              >
                 Code of Conduct
              </h5>
              <hr />
            </div>
          </div>
          <div class="ft-modal-body">
          <div
                      className="row"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        background: "#fff",
                        margin: "auto",
                        padding: "5%",
                      }}
                    >
                      <p style={{ textAlign: "center" }}>
                        GigeconoMe Code of Conduct
                      </p>
                      <p>
                        <strong>
                          The following Conduct of Conduct, govern your access
                          to and use of the GigeconoMe web and mobile
                          application, including any content, functionality and
                          services offered on or through www.gigeconome.com by
                          XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX. (Address, Country).{" "}
                        </strong>
                      </p>
                      <p>
                        <strong>
                          GigeconoMe referred hereto as "GigeconoMe" "we" or
                          "us" and “you” or “user” means you as a user of the
                          Site.{" "}
                        </strong>
                      </p>
                      <p></p>
                      <ol style={{ listStyle: "disc" }}>
                        <li>
                          You will act ethically and with integrity and comply
                          with all of GigeconoMe 's policies
                        </li>
                        <li>
                          {" "}
                          You will respect the rights of all Users and not abuse
                          confidential information or participate in any illegal
                          practice
                        </li>
                        <li>
                          You will have regard for all Users' interests, rights
                          and safety and not harass bully or discriminate
                        </li>
                        <li>
                          {" "}
                          You will have regard for all Users' interests, rights
                          and safety and not harass bully or discriminate
                        </li>
                        <li>
                          You will not seek to communicate or receive or try to
                          initiate payments off platform or underbid to avoid
                          Platform fees.{" "}
                        </li>
                        <li>
                          {" "}
                          You will not post content that infringes upon any
                          copyright or other intellectual property rights across
                          any jurisdictions in which GigeconoMe operates
                        </li>
                        <li>
                          You will not post content that contains any viruses or
                          programming routines intended to damage our platform
                          or our users XXXXXXXXXXXXXXXXXX
                        </li>
                        <li>
                          {" "}
                          You will always comply with all obligations after the
                          all projects or work has been completed
                        </li>
                        <li>
                          You will respect all confidentiality and Privacy
                          agreement and not disclose information or documents
                          acquired, other than as required by law or where
                          authorization is given.
                        </li>
                        <li>
                          {" "}
                          You will not post any content that would create
                          liability for GigeconoMe or cause reputational damage
                          to its business operations
                        </li>
                        <li>
                          You will not participate in projects involving illegal
                          behaviour or chose to take on projects that you plan
                          to complete.
                        </li>
                        <li>
                          When on GigeconoMe, you will avoid exaggeration,
                          derogatory remarks, and inappropriate references.
                        </li>
                        <li>
                          When on GigeconoMe, you will not engage in personal
                          attacks, negative, unfair or unprofessional conduct.
                        </li>
                        <li>
                          {" "}
                          You will not use GigeconoMe to generate false feedback
                          about any person, company or services
                        </li>
                        <li>
                          When on GigeconoMe, you will not post content that
                          violates any law or regulation across any
                          jurisdictions in which GigeconoMe operates.
                        </li>
                        
                      </ol>
                      <p />
                      <div class="row">
                      <div class="col-sm-1">
                        </div>
                        <div class="col-sm-11">
                          <input type="checkbox" id="vehicle1"
                           name="currentcompany" value="Yes" />
                           <label for="vehicle1" id="checkbox_text"> 
                           &nbsp; Click here to indicate that you have read and agree to GigeconoMe's Code of Conduct.</label></div>
                          </div>
                           <div class="row">
                      <div class="col-sm-4">
                        </div>
                        <div class="col-sm-5">
                        <button
                  style={{ backgroundColor: "rgb(0, 147, 69)",marginTop:"5%", width: 150 }}
                  className="btn btn-success"
                  type="submit"
               onClick={this.handleCode}
                >
                  Save
                </button> <br/> <br/>

                <span style={{color:"rgb(0,147,69)",fontFamily:"Gotham",marginTop:"5%",fontWeight:"bold"}}>{this.state.message}</span>
                         </div>
                           <div class="col-sm-3"></div></div>

                    </div>

                   
          </div>
          <div>
            <a class="ft-modal-close" data-dismiss="modal"
                     onClick={this.closeModal}>
              [&#10006;]{" "}
            </a>
          </div>
        </div>
      </div>
     )}
      <a  
        id="a"
       onClick={this.openModal} 
       style={{display:"flex"}}
       href="#ft-codeofconduct-modal">
        Verify
      </a>
    </>
  );
}
}