
import React from "react";
import { Link } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

class Studentsignup_newfour extends React.Component {
  constructor() {
    super();
    
    
    let comments = JSON.parse(sessionStorage.getItem('someData'));
    this.state = {
      StudyStatus:" ",
      college_University:" ",
      Course:" ",
      QualificationType:"",
      Lengthofcourse:" ",
      GraduationYear:"",
      userid:comments,
      reg_level:2,
      selectGraduation : [],
      id: "",
      name: '',
      dropdownlist_input : "graduationyear",
      DataisLoaded: false,
      error:"",
      selectQualification:[],
      selectUniversity:[],
      selectCourse:[],
      subCourseName:[],
      LocationData:[],
      location:""

      };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
 componentDidMount(){
    this.getOptions()
}
async getOptions(){
  const data_one={dropdownlist_input:this.state.dropdownlist_input,
    
  }
  const data_three={dropdownlist_input:"qualification"}

  let BaseUrl = JSON.parse(sessionStorage.getItem('baseurl'));

  fetch('https://www.gigeconome.com:5200/api/getdropdownlist', { method: 'POST', 

   body: JSON.stringify(data_three), // data can be `string` or {object}!

   headers:{ 'Content-Type': 'application/json' } })

   .then(res => res.json())

   .catch(error => console.error('Error:', error))

   .then((json)=>{
    
    
    const data = json.result
    console.log(data)
    
    
    this.setState({selectQualification: data})
    this.setState({
      DataisLoaded: true 
    })
   })
   const data_university={dropdownlist_input:"university"}
   fetch( 'https://www.gigeconome.com:5200/api/getdropdownlist', { method: 'POST', 

   body: JSON.stringify(data_university), // data can be `string` or {object}!

   headers:{ 'Content-Type': 'application/json' } })

   .then(res => res.json())

   .catch(error => console.error('Error:', error))

   .then((json)=>{
    
    
    const data = json.result
    //console.log(json)
    
  const options = data.map(d => ({
      "value" : d.id,
      "label" : d.name

    })) 
   
    this.setState({selectUniversity: options})
    
   })

   const locationdata={dropdownlist_input:"country"}
   fetch( 'https://www.gigeconome.com:5200/api/getdropdownlist', { method: 'POST', 

   body: JSON.stringify(locationdata), // data can be `string` or {object}!

   headers:{ 'Content-Type': 'application/json' } })

   .then(res => res.json())

   .catch(error => console.error('Error:', error))

   .then((json)=>{
    
    
    const data = json.result
    //console.log(json)
    
  const options = data.map(d => ({
      "value" : d.id,
      "label" : d.name

    })) 
   
    this.setState({LocationData:options})
    
   })
   fetch( 'https://www.gigeconome.com:5200/api/getdropdownlist', { method: 'POST', 

   body: JSON.stringify(data_one), // data can be `string` or {object}!

   headers:{ 'Content-Type': 'application/json' } })

   .then(res => res.json())

   .catch(error => console.error('Error:', error))

   .then((json)=>{
    
    
    const data = json.result
    console.log(data)
    
    
    this.setState({selectGraduation: data})
   
   })

   const data_six={dropdownlist_input:"coursenew"}
   fetch( 'https://www.gigeconome.com:5200/api/getdropdownlist', { method: 'POST', 

   body: JSON.stringify(data_six), // data can be `string` or {object}!

   headers:{ 'Content-Type': 'application/json' } })

   .then(res => res.json())

   .catch(error => console.error('Error:', error))

   .then((json)=>{
    
   let  data=json.result
    const options = data.map(d => ({
      "value" : d.id,
      "label" : d.subcategoryname

    })) 
    
    this.setState({subCourseName:options})
    
    //console.log(data)
  
   })
   fetch( 'https://www.gigeconome.com:5200/api/getdropdowncourseinfo', { method: 'GET', 



   headers:{ 'Content-Type': 'application/json' } })

   .then(res => res.json())

   .catch(error => console.error('Error:', error))

   .then((json)=>{
    
    
    const dataa = json.result
    console.log(dataa)
    this.setState({selectCourse:dataa})
   dataa.map(element=>{
     element.subcategory.map(e=>{
        
       
     

       
     })
   
   })
   
   })
    

  }
  handleSubmit(event) {
    event.preventDefault();
    if (this.state.StudyStatus === "") {
     alert("Plese Fill !");
    }
    else{
    const data = { StudyStatus:this.state. StudyStatus, college_University:this.state.college_University , 
      Course:this.state.Course, QualificationType:this.state. QualificationType
      ,Lengthofcourse:this.state.Lengthofcourse, GraduationYear:this.state. GraduationYear,
      userid:this.state.userid, reg_level:this.state. reg_level,location:this.state.location }
     console.log(data)
      let BaseUrl = JSON.parse(sessionStorage.getItem('baseurl'));
    fetch(BaseUrl +'stuserinfo', { method: 'POST', 

    body: JSON.stringify(data), // data can be `string` or {object}!

    headers:{ 'Content-Type': 'application/json' } })

    .then(res => res.json())

    .catch(error => console.error('Error:', error))

    .then((response)=>{
        
        this.setState({
          DataisLoaded: true 
        })
          window.location.href=`./studentsignup_four`
          console.log(response)
    })
  }
        
    
       
    
   }
  

 handleChange = (e) => {

this.setState({
     [e.target.name]: e.target.value

    })
  
 }

  render(){
   
   
  return (
    <div>
    
     
      <Header />



     
        <main id="main" style={{ background: "#303030", clear: "both" }}>
          <br />
          <br />
          {/* ======= About Section ======= */}
          <section id="clients" className="clients">
            <div className="container" data-aos="fade-up">
              <style
                type="text/css"
                dangerouslySetInnerHTML={{
                  __html:
                    "\n    .boxxlr{\n     border: 0px solid green;\n    background: #edf8f2;\n    margin-right: 2.5%;\n    margin-bottom: 7%;\n    color: #000;\n    margin-left: 9%;\n    width: 100%;\n    }\n/*.boxxlr:hover {\n    border: 0px solid green;\n    background: green;\n    margin-right: 1%;\n    margin-bottom: 1%;\n     color: #fff;\n}*/\n\n.boxclrtitle {\nfont-size: 12px;font-weight: bold;\n}\n\n.boxclrtitle1 {\nfont-size: 18px;font-weight: bold;\n}\n/*.boxxlr .boxclrtitle:hover {\nfont-size: 12px;font-weight: bold;\n}*/\n\n\n  ",
                }}
              />
              <div className="row no-gutters">
                <div className="content col-xl-12 d-flex align-items-center">
                  <div className="content col-xl-12 d-flex align-items-center">
                    <div
                      className="row ssignupsteptwo"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        borderRadius: "25px" /* alignItems: 'center', */,
                        background: "#fff",
                        /* marginLeft: '50%', */
                        margin: "auto",
                        
                      }}
                      
                    >
                      <h2
                        className="currenteducationtxt"
                      >
                        {" "}
                        More About You{" "}
                      </h2>
                      <h5
                        
                        className="moraboututxt"
                      >
                        Please add more information for our companies
                      </h5>
                      <div className="row h-100 row align-items-center">
                        <div className="col-md-8" style={{ margin: "auto" }}>
                          
                      
                          <form onSubmit={this.handleSubmit}>
                                      
                            <div
                              className="form-group"
                              style={{ padding: "2%", borderRadius: "1px",marginTop:"10%" }}
                            >
                             <span style={{fontFamily:"GothamLight",fontWeight:"bold",color:"rgb(0,147,69)"}}>Profile Summary : </span>
                             <div style={{marginTop:"2%"}}>
                                <textarea rows="4" placeholder="Please include more details about you" className="form-control"></textarea>
                             </div>
                            </div>
                      
                            
                           
                            <div
                              className="form-group"
                              style={{ padding: "2%", borderRadius: "1px",marginTop:"5%" }}
                            >
                             <span style={{fontFamily:"GothamLight",fontWeight:"bold",color:"rgb(0,147,69)"}}>Interests & Hobbies : </span>
                             <div style={{marginTop:"2%"}}>
                                <textarea rows="4" placeholder="Please include more details about your interests and hobbies" className="form-control"></textarea>
                             </div>
                            </div>
                           
                           
                          
                           
                               
                            <div
                              className="container"
                              style={{ marginBottom: "5%" }}
                            >
                              <Link
                                style={{ color: "#fff" }}
                                to='/Studentsignup_three'
                              
                                  className="btn btn-primary studentbackbtn"
                                
                                >
                                  {" "}
                                  Back
                               {" "}
                              </Link>
                              
                                <button
                                  className="btn btn-primary"
                                  style={{
                                    
                                    marginLeft: "8%",
                                    marginTop: "6%",
                                    color: "#fff",
                                    background: "rgb(0, 147, 69)",
                                    width: "44%",
                                    border: "none",
                                    borderRadius:
                                      "1px" /* marginTop: '-13%', */,
                                    height: "40px",
                                    
                                    marginBottom: "5%",
                                  }}
                                >
                                  Next{" "}
                               {" "}
                              </button>
                            </div>
                          </form>
                         
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="container"></div>
        </main>
        {/* End #main */}
        {/* ======= Footer ======= */}
       <Footer />
      </div>
   
  );
};
}

export default Studentsignup_newfour;
