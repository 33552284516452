import React from "react";
import USPIMG from "../../images/longtermimg.png";
import Tickmark from "../../images/tickmark.png";
export default class NewUsp extends React.Component{
    constructor(){
        super();
        this.state={

        }
        this.handleStarted=this.handleStarted.bind(this)
        this.handleBook=this.handleBook.bind(this)
    }
    handleStarted(){
        window.location.href=`./signup`
    }
    handleBook(){
        window.location.href=`./gradopportunity`
    }
    render(){
        return(
            <>
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 d-flex align-items-stretch bigcompanysearch">
                    <img src={USPIMG} className="newuspimg" />
                    </div>
                    <div className="col-xl-6 d-flex align-items-stretch mscreenava">
                    <img src={USPIMG} style={{width:"75%",margin:"auto",marginTop:"-5em"}} />
                    </div>
                    <div className="col-xl-5 uspcontent" style={{color:"black"}}>
                   <h2 className="bigcompanysearch">Short- and Long-term <span style={{color:"#00539c",fontWeight:"bold"}}>Opportunities</span>
                   &nbsp;Supported on<br/> one Platform
                   </h2>
                   <h4 className="mscreenava text-center" style={{padding:"2%"}}>Short- and Long-term <span style={{color:"#00539c",fontWeight:"bold"}}>Opportunities</span>
                   &nbsp;Supported on one Platform
                   </h4>
                   <br/>
                   <table className="bigcompanysearch"> 
                    <tr>
                        <th style={{width:"8%"}} > <img src={Tickmark} style={{width:"100%",marginTop:"-3em",marginLeft:"-0.6em"}}/></th>
                        <td> <span className="bigcompanysearch"style={{fontFamily:"GothamLight",fontWeight:"bold"}}>
                   
                        We connect students and graduates to both short and long term opportunities at both established industries and exciting startups.
                  </span></td>
                    </tr>
                    <br/>
                    <tr>
                        <th style={{width:"8%"}} > <img src={Tickmark} style={{width:"100%",marginTop:"-3em",marginLeft:"-0.6em"}}/></th>
                        <td> <span className="bigcompanysearch"style={{fontFamily:"GothamLight",fontWeight:"bold"}}>
                   
                      
                        The best Internship, work placements and graduate opportunities across the widest range of industries and location .
                  </span></td>
                    </tr>
                    <br/>
                    <tr>
                        <th style={{width:"8%"}} > <img src={Tickmark} style={{width:"100%",marginTop:"-3em",marginLeft:"-0.6em"}}/></th>
                        <td> <span className="bigcompanysearch"style={{fontFamily:"GothamLight",fontWeight:"bold"}}>
                   
                        Recommendations and matches according to the preferences and needs of both employers and young talents.<span style={{visibility:"hidden"}}>talents.</span>
                  </span></td>
                    </tr>
                   </table>
          
                   
                   <div className="mscreenava" style={{marginTop:"-6%"}}>
                   <table style={{marginLeft:"5%",textAlign:"left"}}> 
                    <tr>
                        <th style={{width:"8%"}} > <img src={Tickmark} style={{width:"100%",marginTop:"-3em",marginLeft:"-0.6em"}}/></th>
                        <td> <span style={{fontFamily:"GothamLight",fontWeight:"bold"}}>
                   
                        We connect students and graduates to both short and long term opportunities at both established industries and exciting startups.
                  </span></td>
                    </tr>
                    <br/>
                    <tr>
                        <th style={{width:"8%"}} > <img src={Tickmark} style={{width:"100%",marginTop:"-3em",marginLeft:"-0.6em"}}/></th>
                        <td> <span style={{fontFamily:"GothamLight",fontWeight:"bold"}}>
                   
                      
                        The best Internship, work placements and graduate opportunities across the widest range of industries and location .
                  </span></td>
                    </tr>
                    <br/>
                    <tr>
                        <th style={{width:"8%"}} > <img src={Tickmark} style={{width:"100%",marginTop:"-3em",marginLeft:"-0.6em"}}/></th>
                        <td> <span style={{fontFamily:"GothamLight",fontWeight:"bold"}}>
                   
                        Recommendations and matches according to the preferences and needs of both employers and young talents.<span style={{visibility:"hidden"}}>talents.</span>
                  </span></td>
                    </tr>
                   </table>
                   </div>
                 
                    <button className="mscreenava getstartedbtnm" onClick={this.handleStarted}>Get Started</button>&nbsp;&nbsp;
                    <button className="mscreenava gradbtnm" onClick={this.handleBook}>Grad Opportunities</button>
                    <br/>
                  
                  
                   <button onClick={this.handleStarted} class="getstartedbtnnew bigcompanysearch">Get Started</button> &nbsp;&nbsp;
                   <button  class="gradbtn bigcompanysearch" onClick={this.handleBook}>Grad Opportunities</button>
                    </div>
                </div>
           
            </div>
            </>
        )
    }
}