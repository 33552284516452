import React, { useState } from "react";
import "./modal.css";

export default class BillingAddMore extends React.Component {
  constructor() {
    super();
  let comments = JSON.parse(sessionStorage.getItem('someData'));
  this.textInput = React.createRef();
  this.state = {
    
      userid:comments,
      addcpydata:"cpybillinginfo",
      cpylegalname:"",
      cptregnumber:"",
      cpyVATnumber:"",
      cpybilladdress1:"",
      cpybilladdress2:"",
      cpypincode:"",
      cpycityid:"",
      cpycountryid:"",
      selectOptions:[],
      cpycityname:"",
      message:""




      };

   
   
  }
  openModal = () => {
    this.setState({ showModal: true });
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };
  

  
handleProof=(e)=>{
  const data_proof={
userid:this.state.userid,
addcpydata:this.state.addcpydata,
cpylegalname:this.state.cpylegalname,
cptregnumber:this.state.cptregnumber,
cpyVATnumber:this.state.cpyVATnumber,
cpybilladdress1:this.state.cpybilladdress1,
cpybilladdress2:this.state.cpybilladdress2,
cpypincode:this.state.cpypincode,
cpycountryid:this.state.cpycountryid,
cpycityname:this.state.cpycityname

  }

  fetch( 'https://www.gigeconome.com:5200/api/addcpy_profiledata', { method: 'POST', 

  body: JSON.stringify(data_proof), // data can be `string` or {object}!

  headers:{ 'Content-Type': 'application/json' } })

  .then(res => res.json())

  .catch(error => console.error('Error:', error))

  .then((response)=>{
  this.setState({message:response.message})

    window.location.reload();
 
  })
 

}

handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
     
    });
   
    
  };
componentDidMount(){
    const data_one = { dropdownlist_input: "country" };
   

    fetch( "https://www.gigeconome.com:5200/api/getdropdownlist", {
      method: "POST",

      body: JSON.stringify(data_one), // data can be `string` or {object}!

      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())

      .catch((error) => console.error("Error:", error))

      .then((json) => {
        const data = json.result;
       
        this.setState({ selectOptions: data });
        
      });

}
 render(){
  return (
    <>
      {this.state.showModal && (
      <div class="ft-modal" id="ft-billingaddressaddmore-modal">
        <div class="ft-modal-content" style={{width:"50%"}}>
          <div class="ft-modal-header">
            <div class="header">
            <h5
                  class="modal-title"
                  id="exampleModalLabel"
                  style={{
                    textAlign: "center",
                    fontSize: 25,
                    fontWeight: "bold",
                  }}
                >
                Billing Information
                </h5>
                <hr style={{marginLeft:"-4%",marginRight:"-4%",height:2}} />
             
            </div>
          </div>
          <div class="ft-modal-body">
         
          <h5
                style={{
                  textAlign: "center",
                  color: "green",
                }}
              >
                Please Update Details 
              </h5>
              <div class="row mt-4">
                <div class="col-sm-2"></div>
                <div class="col-sm-8">
                  <label
                    style={{
                      fontSize: 16,
                      fontWeight: "bold",
                    }}
                  >
                    Company Legal Name
                  </label>{" "}
                  <br />
                  <input
                    type="text"
                    placeholder="Enter"
                    className="form-control mt-2"
                    name="cpylegalname"
                    onChange={this.handleChange}
                  />
                </div>
                <div class="col-sm-2"></div>
              </div>
              <div class="row mt-4">
                <div class="col-sm-2"></div>
                <div class="col-sm-8">
                  <label
                    style={{
                      fontSize: 16,
                      fontWeight: "bold",
                    }}
                  >
                    Company Registration Number
                  </label>{" "}
                  <br />
                  <input
                    type="text"
                    placeholder="Enter"
                    className="form-control mt-2"
                    name="cptregnumber"
                    onChange={this.handleChange}
                  />
                </div>
                <div class="col-sm-2"></div>
              </div>
              <div class="row mt-4">
                <div class="col-sm-2"></div>
                <div class="col-sm-8">
                  <label
                    style={{
                      fontSize: 16,
                      fontWeight: "bold",
                    }}
                  >
                    VAT Number
                  </label>{" "}
                  <br />
                  <input
                    type="text"
                    placeholder="Enter"
                    className="form-control mt-2"
                    name="cpyVATnumber"
                    onChange={this.handleChange}
                  />
                </div>
                <div class="col-sm-2"></div>
              </div>
              <div class="row mt-4">
                <div class="col-sm-2"></div>
                <div class="col-sm-8">
                  <label
                    style={{
                      fontSize: 16,
                      fontWeight: "bold",
                    }}
                  >
                    Bill Address 1
                  </label>{" "}
                  <br />
                  <textarea class="form-control mt-2"
                   name="cpybilladdress1"
                   onChange={this.handleChange}
                  id="exampleFormControlTextarea1" rows="3"></textarea>
                </div>
                <div class="col-sm-2"></div>
              </div>
              <div class="row mt-4">
                <div class="col-sm-2"></div>
                <div class="col-sm-8">
                  <label
                    style={{
                      fontSize: 16,
                      fontWeight: "bold",
                    }}
                  >
                   Bill Address 2
                  </label>{" "}
                  <br />
                  <textarea class="form-control mt-2"
                   name="cpybilladdress2"
                   onChange={this.handleChange}
                  id="exampleFormControlTextarea1" rows="3"></textarea>
                </div>
                <div class="col-sm-2"></div>
              </div>   <div class="row mt-4">
                <div class="col-sm-2"></div>
                <div class="col-sm-8">
                  <label
                    style={{
                      fontSize: 16,
                      fontWeight: "bold",
                    }}
                  >
                    City
                  </label>{" "}
                  <br />
                  <input
                    type="text"
                    placeholder="Enter"
                    className="form-control mt-2"
                    name="cpycityname"
                    onChange={this.handleChange}
                  />
                </div>
                <div class="col-sm-2"></div>
              </div>   <div class="row mt-4">
                <div class="col-sm-2"></div>
                <div class="col-sm-8">
                  <label
                    style={{
                      fontSize: 16,
                      fontWeight: "bold",
                    }}
                  >
                    Post Code/Zip
                  </label>{" "}
                  <br />
                  <input
                    type="text"
                    placeholder="Enter"
                    className="form-control mt-2"
                    name="cpypincode"
                    onChange={this.handleChange}
                  />
                </div>
                <div class="col-sm-2"></div>
              </div>   <div class="row mt-4">
                <div class="col-sm-2"></div>
                <div class="col-sm-8">
                  <label
                    style={{
                      fontSize: 16,
                      fontWeight: "bold",
                    }}
                  >
                    Country
                  </label>{" "}
                  <br />
                  {this.state.selectOptions.length == 0
                    ? "Loading School Name..."
                    : 

                    <select class="form-select"  name ="cpycountryid" 
                    onChange = {this.handleChange}>
                      <option>Select Country</option>
                    {this.state.selectOptions.map(fbb =>
                    
                      <option key={fbb.key} value={fbb.id}>{fbb.name}</option>
                      
                    )};
                  </select>
                    } 
                </div>
                <div class="col-sm-2"></div>
              </div>
             
              
 
             
                
              
              
               
              
            
        

        
           
         
              <div class="modal-footer mt-4">
                <div class="col-sm-4"></div>
                <div class="col-sm-7">
                  <button
                    style={{ backgroundColor: "rgb(0, 147, 69)",width:100 }}
                    className="btn btn-success"
                    onClick={this.handleProof}
                  >
                    Save
                  </button> <br /> <br />
                  <span style={{color:"rgb(0,147,69)",fontFamily:"Gotham",marginTop:"5%",fontWeight:"bold"}}>{this.state.message}</span>
                </div>
                <div class="col-sm-1"></div>
              </div>
           
          </div>
          <div class="ft-modal-footer">
            <a class="ft-modal-close" data-dismiss="modal"
                     onClick={this.closeModal} style={{cursor:"pointer"}}>
              [&#10006;]{" "}
            </a>
          </div>
        </div>
      </div>
      )}
     
     <a href="#ft-billingaddressaddmore-modal" 
       onClick={this.openModal}
      class="btn btn-success mt-2 cpybillingcontactaddmore"
      style={{
        
        
        backgroundColor: "rgb(0, 147, 69)",
        fontFamily: "GothamLight",
        textAlign: "center",
        marginTop:"2%",
        float:"right",
        height:36
      }}

                    >Add More</a>
               
    </>
  );
}
}