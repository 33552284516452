import React from "react";
import Login5 from '../../images/Login-36.png';
import { Link } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import swal from 'sweetalert';
const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth' // for smoothly scrolling
  });
};
class RetakeEmpTest extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
    secondquestion:0,
    thirdquestion:0
     
    };
   this.handleNo=this.handleNo.bind(this)
   this.handleThird=this.handleThird.bind(this)
   this.handleYes=this.handleYes.bind(this)
   this.handleFourth=this.handleFourth.bind(this)
  }
  handleNo(){
    this.setState({secondquestion:1})
  }
 handleThird(){
    this.setState({thirdquestion:1,secondquestion:3})
 }
handleYes(){
    window.location.href=`./emptestdescription`
}   
handleFourth(){
    window.location.href=`./noemptest`
} 
   
  


  

 
  
  render() {

    
    return (
      <div>
         {/* ======= Header ======= */}
      <Header />
      <div>
    

      </div>
      {/* End Header */}
        <main id="main" style={{ background: "#303030", clear: "both" }}>
          <br />
          <br />
          {/* ======= About Section ======= */}
          <section id="clients" className="clients">
            <div
              className="container col-md-5 col-xs-12 col-sm-12 col-lg-6"
              data-aos="fade-up"
            >
              <style
                type="text/css"
                dangerouslySetInnerHTML={{
                  __html:
                    "\n    .boxxlr{\n     border: 0px solid green;\n    background: #edf8f2;\n    margin-right: 2.5%;\n    margin-bottom: 7%;\n    color: #000;\n    margin-left: 9%;\n    width: 100%;\n    }\n/*.boxxlr:hover {\n    border: 0px solid green;\n    background: green;\n    margin-right: 1%;\n    margin-bottom: 1%;\n     color: #fff;\n}*/\n\n.boxclrtitle {\nfont-size: 12px;font-weight: bold;\n}\n\n.boxclrtitle1 {\nfont-size: 18px;font-weight: bold;\n}\n/*.boxxlr .boxclrtitle:hover {\nfont-size: 12px;font-weight: bold;\n}*/\n\n\n  ",
                }}
              />
              <div className="row no-gutters">
                <div className="content col-xl-12 d-flex align-items-center">
                  <div className="content col-xl-12 d-flex align-items-center" id="mainbox">
                    <div
                      className="row"
                      style={{
                        display: "flex",
                        justifyContent: "center" /* alignItems: 'center', */,
                        background: "#fff",
                        width: "100%" /* marginLeft: '50%', */,
                        borderRadius: "20px",
                        margin: "auto",
                      }}
                    >
              
                      <div className="row h-100 row align-items-center">
                        <div
                          className="col-md-10"
                          style={{ margin: "auto", marginBottom: "6%" }}
                        >
                          
                          <img
                              src={Login5}
                             className="empguestsignuplogo"
                            />
                           
                           <div className="row" style={{marginTop:"5%"}} >  
                           <h4 style={{fontFamily:"Gothic A1",color:"rgb(0,147,69)",textAlign:"center"}}>
                             Retake Employability Test
                            </h4>
                                
                           </div>
                         
                           <div className="row mt-4">
                               <div className="col-sm-12" style={{height:100}}>
                                 <br/>
                                 {this.state.secondquestion==0?<>
                                    <h4 className="empguestquesheadingretake">
                                 Have you recently completed a work placement or any work integrated opportunities ? 
                               </h4>
                                 </>:null}
                                 {this.state.secondquestion==1?<>
                                    <h4 className="empguestquesheadingretake">
                                 Have you completed any employability courses or learning ?
                               </h4>
                                 </>:null}
                                 {this.state.thirdquestion==1?<>
                                    <h4 className="empguestquesheadingretake">
                                 Have you completed a semester or end of year?
                               </h4>
                                 </>:null}
                               </div>
                              
                           </div>
                          <table className="retakenintybtn">
                            <tr>
                                <th className="empnottestyes">
                                    <button onClick={this.handleYes} className="btn empnottestbtns">Yes</button>
                                </th>
                                <th className="empnottestno">
                                    {this.state.secondquestion==0?<>
                                        <button className="btn empnottestbtns"  onClick={this.handleNo}>No</button>
                                    </>:null}
                                    {this.state.secondquestion==1?<>
                                        <button className="btn empnottestbtns"  onClick={this.handleThird}>No</button>
                                    </>:null}
                                    {this.state.thirdquestion==1?<>
                                        <button className="btn empnottestbtns"  onClick={this.handleFourth}>No</button>
                                    </>:null}
                                </th>
                            </tr>
                          </table>
                         
                        
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        {/* End #main */}
        {/* ======= Footer ======= */}
 <Footer />
      </div>
    );
  }
}
export default RetakeEmpTest;
