import React, { useState } from "react";
import "./modal.css";

export default class BillingContactAdd extends React.Component {
  constructor() {
    super();
  let comments = JSON.parse(sessionStorage.getItem('someData'));
  this.textInput = React.createRef();
  this.state = {
    
      userid:comments,
      addcpydata:"cpybillingcontact",
      firstname:"",
      lastname:"",
      email:"",
      message:""




      };

   
   
  }
  openModal = () => {
    this.setState({ showModal: true });
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };
  

  
handleProof=(e)=>{
  const data_proof={
userid:this.state.userid,
firstname:this.state.firstname,
lastname:this.state.lastname,
email:this.state.email,
addcpydata:this.state.addcpydata

  }

  fetch( 'https://www.gigeconome.com:5200/api/addcpy_profiledata', { method: 'POST', 

  body: JSON.stringify(data_proof), // data can be `string` or {object}!

  headers:{ 'Content-Type': 'application/json' } })

  .then(res => res.json())

  .catch(error => console.error('Error:', error))

  .then((response)=>{
   console.log(response)
   this.setState({message:response.message})
    window.location.reload();
 
  })
 

}

handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
     
    });
   
    
  };

 render(){
  return (
    <>
      {this.state.showModal && (
      <div class="ft-modal" id="ft-billingcontactadd-modal">
        <div class="ft-modal-content" style={{width:"50%"}}>
          <div class="ft-modal-header">
            <div class="header">
            <h5
                  class="modal-title"
                  id="exampleModalLabel"
                  style={{
                    textAlign: "center",
                    fontSize: 25,
                    fontWeight: "bold",
                  }}
                >
                Billing Contact
                </h5>
                <hr style={{marginLeft:"-4%",marginRight:"-4%",height:2}} />
             
            </div>
          </div>
          <div class="ft-modal-body">
         
          <h5
                style={{
                  textAlign: "center",
                  color: "green",
                }}
              >
                Please Update Details of Person to Contact
              </h5>
              <div class="row mt-4">
                <div class="col-sm-2"></div>
                <div class="col-sm-8">
                  <label
                    style={{
                      fontSize: 18,
                      fontWeight: "bold",
                    }}
                  >
                    First Name
                  </label>{" "}
                  <br />
                  <input
                    type="text"
                    placeholder="Enter First Name"
                    className="form-control mt-2"
                    name="firstname"
                    onChange={this.handleChange}
                    required
                  />
                </div>
                <div class="col-sm-2"></div>
              </div>
              <div class="row mt-4">
                <div class="col-sm-2"></div>
                <div class="col-sm-8">
                  <label
                    style={{
                      fontSize: 18,
                      fontWeight: "bold",
                    }}
                  >
                    Last Name
                  </label>{" "}
                  <br />
                  <input
                    type="text"
                    placeholder="Enter lase Name"
                    className="form-control mt-2"
                    name="lastname"
                    onChange={this.handleChange}
                    required
                  />
                </div>
                <div class="col-sm-2"></div>
              </div>
              <div class="row mt-4">
                <div class="col-sm-2"></div>
                <div class="col-sm-8">
                  <label
                    style={{
                      fontSize: 18,
                      fontWeight: "bold",
                    }}
                  >
                    Email
                  </label>{" "}
                  <br />
                  <input
                    type="text"
                    placeholder="Enter Email"
                    className="form-control mt-2"
                    name="email"
                    onChange={this.handleChange}
                    required
                  />
                </div>
                <div class="col-sm-2"></div>
              </div>
              <div class="row mt-4">
                <div class="col-sm-2"></div>
                <div class="col-sm-8">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                    <label class="form-check-label" for="flexCheckDefault" style={{fontSize:16}}>
                      Send the Copy of all invoices to this Email
                    </label>
                  </div>
                  <div class="col-sm-2"></div>
                </div>
              </div>

        
 
             
                
              
              
               
              
            
        

        
           
         
              <div class="modal-footer mt-4">
                <div class="col-sm-4"></div>
                <div class="col-sm-7">
                  <button
                    style={{ backgroundColor: "rgb(0, 147, 69)",width:100 }}
                    className="btn btn-success"
                    onClick={this.handleProof}
                  >
                    Save
                  </button> <br /> <br />
                  <span style={{color:"rgb(0,147,69)",fontFamily:"Gotham",marginTop:"5%",fontWeight:"bold"}}>{this.state.message}</span>
                </div>
                <div class="col-sm-1"></div>
              </div>
           
          </div>
          <div class="ft-modal-footer">
            <a class="ft-modal-close" data-dismiss="modal"
                     onClick={this.closeModal} style={{cursor:"pointer"}}>
              [&#10006;]{" "}
            </a>
          </div>
        </div>
      </div>
      )}
     
      <a href="#ft-billingcontactadd-modal" 
       onClick={this.openModal}
      class="btn btn-success mt-2"
      id="a"
      style={{
        
        width: "20%",
        backgroundColor: "rgb(0, 147, 69)",
        fontFamily: "GothamLight",
        textAlign: "center",
        marginTop:"2%",
        float:"right",
        height:36
      }}
                    >Add</a>


               
    </>
  );
}
}