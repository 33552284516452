import React, { useState } from "react";
import Resizer from "react-image-file-resizer";
import "./modal.css";

function close(){
    window.location.reload();
  }
export default class InterestModal extends React.Component{
  
  constructor() {
    super();
    let comments = JSON.parse(sessionStorage.getItem("someData"));
   
   console.log(comments)
    this.state = {
      
    
     
        userid: comments,
        addstudata :"membershipsociety",
        logo:"",
        name:"",
        month:"",
        year:"",
        memberlstyink:"",
        message:"",
        showModal: false,
       
     
    
  
  }
  this.handleSubmit = this.handleSubmit.bind(this);
}

  handleSubmit = (event) => {
    event.preventDefault()
   
    const data = {
        userid: this.state.userid,
        addstudata: this.state.addstudata,
        logo:this.state.logo,
        name:this.state.name,
        month:this.state.month,
        year:this.state.year,
        memberlstyink:this.state.memberlstyink,
     
    }
  
   

    fetch( "https://www.gigeconome.com:5200/api/addstu_profiledata", {
      method: "POST",

      body: JSON.stringify(data), // data can be `string` or {object}!

      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())

      .catch((error) => console.error("Error:", error))

      .then((response) => {
         
        if(response.message=="request entity too large"){
          this.setState({message:"Please Upload a logo Width 120px & Height 120px"}) 
        }
        else{
          this.setState({geomessage:response.message})
          window.location.reload();
        }
          
       
        
      });
  
}
getBase64 = (file) => {
  return new Promise((resolve) => {
    let fileInfo;
    let baseURL = "";
    // Make new FileReader
    let reader = new FileReader();

    // Convert the file to base64 text
    reader.readAsDataURL(file);

    // on reader load somthing...
    reader.onload = () => {
      // Make a fileInfo Object
      console.log("Called", reader);
      baseURL = reader.result;
      console.log(baseURL);
      resolve(baseURL);
    };
    console.log(fileInfo);
  });
};
handleImage = (e) => {
  // let file = new FileReader();
  console.log(e.target.files[0]);
  let { file } = this.state;

  file = e.target.files[0];

  this.getBase64(file)
    .then((result) => {
      file["base64"] = result;
      console.log("File Is", file);
      this.setState({
       logo: result,
        file,
      });
    })
    .catch((err) => {
      console.log(err);
    });

  this.setState({
    //  file: e.target.files[0]
  });

  this.setState({
    //companylogo: e.target.files[0]
  });
  var fileInput = false;
  if (e.target.files[0]) {
    fileInput = true;
  }
  
  if(fileInput) {
    try {
      Resizer.imageFileResizer(
        e.target.files[0],
        120,
        120,
        "JPEG",
        100,
        0,
        (uri) => {
          console.log(uri);
          this.setState({ newImage: uri });
        },
        "base64",
        120,
        120
      );
    } catch (err) {
      console.log(err);
    }
  }
};
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
     
    });
    
  };
  openModal = () => {
    this.setState({ showModal: true });
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };
render(){

  const{geomessage}=this.state
  return (
    <>
     {this.state.showModal && (
      <div class="ft-modal" id="ft-interest-modal">
        <div class="ft-modal-content" >
          <div class="ft-modal-header">
            <div class="header">
              <h5
                class="modal-title"
                id="exampleModalLabel"
                style={{
                  textAlign: "center",
                  fontSize: 25,
                  fontWeight: "bold",
                }}
              >
                Add Membership / Societies
              </h5>
              <hr />
            </div>
          </div>
          <div class="ft-modal-body">
            <div class="row">
              <div class="col-sm-2"></div>
              <div class="col-sm-8">
                <form onSubmit = {this.handleSubmit}>
                <label
                  style={{
                    fontSize: 15,
                    fontFamily:"Gotham",
                    marginTop:"3%"
                  }}
                >
                  Add a Logo
                </label>{" "}
                <br />
                <input type="file"
                 name ="logo" 
                 onChange = {this.handleImage}
                class="form-control mt-3" placeholder="Enter Name" />
                <label
                  style={{
                    fontSize: 15,
                    fontFamily:"Gotham",
                    marginTop:"8%"
                  }}
                >
                  Name of Society
                </label>{" "}
                <br />
                <input type="text"
                 name ="name" 
                 onChange = {this.handleChange}
                class="form-control mt-3" placeholder="Enter the Name of Society" />
                <label
                  style={{
                    fontSize: 15,
                   fontFamily:"Gotham",
                    marginTop:"8%"
                  }}
                >
                  Add a Link
                </label>{" "}
                <br />
               <input type="text" 
               name ="memberlstyink" 
               onChange = {this.handleChange}
               class="form-control mt-3" placeholder="Enter Link For Membership" />
                <div >
              
                <label
                  style={{
                    fontSize: 15,
                   fontFamily:"Gotham",
                    marginTop:"8%"
                  }}
                >
                 Member Since
                </label>{" "}
                <br />
                <div class="row mt-2">
                    <div class="col-sm-6"> <select
                  class="form-select mt-2"
                  name ="month" 
                  onChange = {this.handleChange}
                >
                   <option selected>Select Month</option>
                  <option>January</option>
                  <option>February</option>
                  <option>March</option>
                  <option>April</option>
                  <option>May</option>
                  <option>June</option>
                  <option>July</option>
                  <option>August</option>
                  <option>September</option>
                  <option>October</option>
                  <option>November</option>
                  <option>December</option>
                </select></div>
                    <div class="col-sm-6"> <select
                  class="form-select mt-2"
                  name ="year" 
                  onChange = {this.handleChange}
                >
                   <option selected>Select Year</option>
                  <option>2000</option>
                  <option>2001</option>
                  <option>2002</option>
                  <option>2003</option>
                  <option>2004</option>
                  <option>2005</option>
                  <option>2006</option>
                  <option>2007</option>
                  <option>2008</option>
                  <option>2009</option>
                  <option>2010</option>
                  <option>2011</option>
                  <option>2012</option>
                  <option>2013</option>
                  <option>2014</option>
                  <option>2015</option>
                  <option>2016</option>
                  <option>2017</option>
                  <option>2018</option>
                  <option>2019</option>
                  <option>2020</option>
                  <option>2021</option>
                  <option>2022</option>
                  <option>2023</option>
                  <option>2024</option>
                  <option>2025</option>
                  <option>2026</option>
                  <option>2027</option>
                  <option>2028</option>
                  <option>2029</option>
                  <option>2030</option>
                  <option>2031</option>
                  <option>2032</option>
                  <option>2033</option>
                  <option>2034</option>
                  <option>2035</option>
                  <option>2036</option>
                  <option>2037</option>
                  <option>2038</option>
                  <option>2039</option>
                  <option>2040</option>
                </select></div>
                </div>
               
                
                </div>
                <div style={{textAlign:'center'}}>

<button
  style={{ backgroundColor: "rgb(0, 147, 69)", width: 100,
marginTop:"8%",
}}
  className="btn btn-success"
  type="submit"
>
    
  Save
</button>

</div>
                <span style={{color:"rgb(0,147,69)",fontSize:15,marginTop:"5%",fontWeight:"bold"}}>{geomessage}</span> <br />
                <span style={{color:"red",fontSize:15,marginTop:"5%",fontWeight:"bold"}}>{this.state.message}</span>
                </form>
              </div>
              <div class="col-sm-2"></div>
            </div>{" "}
            <div class="row mt-4">
              <div class="col-sm-2"></div>
              <div class="col-sm-8">
               
              </div>
              <div class="col-sm-2"></div>
            </div>
            <div class="mt-4">
              <div class="col-sm-4"></div>
              <div class="col-sm-7">
               
              </div>
              <div class="col-sm-1"></div>
            </div>
          </div>
          <div class="ft-modal-footer">
            <a class="ft-modal-close" data-dismiss="modal"
                     onClick={this.closeModal}>
              [&#10006;]{" "}
            </a>
          </div>
        </div>
      </div>

     )}
      <a
          id="add_btnfive"
          className="btn btn-sm mt-2 stuworkbtn"
          href="#ft-interest-modal"
          
          onClick={this.openModal}
        >
          Add
        </a>
    </>
  );
}
}
