import React from "react";
import StepFive from "../../images/stepfive.PNG";
import Login1 from '../../images/Login-28 - Copy.png'
import Login2 from '../../images/Login-29.png';
import Login3 from '../../images/Login-30.png';
import Login4 from '../../images/Login-31.png';
import Login5 from '../../images/Login-36.png';
import { Link } from "react-router-dom";
import PostaProjectNav from "./PostaProjectNav";

import axios from 'axios';
import swal from 'sweetalert';
function validate(email, password) {
  // true means invalid, so our conditions got reversed
  return {
    email: email.length === 0,
    password: password.length === 0
  };
}
class SigninProject extends React.Component {




  
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      everFocusedEmail: false,
      everFocusedPassword: false,
      inFocus: "",
      data_one:undefined,
      
    };
  }


  handleEmailChange = evt => {
    this.setState({ email: evt.target.value });
  };

  handlePasswordChange = evt => {
    this.setState({ password: evt.target.value });
  };

  handleSubmit = evt => {
    if (!this.canBeSubmitted()) {
      evt.preventDefault();
      return;
    }
    else{
      
  
    
 
     axios.post("https://mainteccloud.com:8080/api/login", this.state)
     .then(
       (posRes) => {   
 
          //console.log(posRes) 
        this.setState({ data_one: posRes.data.user.name });
        var dataStore = JSON.stringify(posRes.data.user.name );
        localStorage.setItem('nameData', dataStore);
       
      
        if(posRes.data.user.usertype=="Company"){
          swal({
            title: "Login Success!",
            
            icon: "success",
          });
          
          window.location.href=`./PreviewPage`
        }
        if(posRes.data.user.usertype=="Student"){
          swal({
            title: "Login Success!",
            
            icon: "success",
          });
          
          window.location.href=`./PreviewPage`
        }
       
          
         
       },
       (errRes) => {}
     );
   };
 
    
    const { email, password } = this.state;
    
   alert(`Login with email: ${email} password: ${password}`);
  };
 

  canBeSubmitted() {
    const errors = validate(this.state.email, this.state.password);
    const isDisabled = Object.keys(errors).some(x => errors[x]);
    return !isDisabled;
  }
 







  
  getData = (event) => {
    event.preventDefault();

    this.setState({
      [event.target.name]: event.target.value,
      
    });
   
  };
  
  render() {

   const errors = validate(this.state.email, this.state.password);
    const isDisabled = Object.keys(errors).some(x => errors[x]);

    
    return (
      <div>
         {/* ======= Header ======= */}
         <PostaProjectNav />
      <div>
    

      </div>
      {/* End Header */}
        <main id="main" style={{  clear: "both" }}>
          <br />
          <br />
          {/* ======= About Section ======= */}
          <section id="clients" className="clients">
            <div
              className="container col-md-5 col-xs-12 col-sm-12 col-lg-6"
              data-aos="fade-up"
            >
              <style
                type="text/css"
                dangerouslySetInnerHTML={{
                  __html:
                    "\n    .boxxlr{\n     border: 0px solid green;\n    background: #edf8f2;\n    margin-right: 2.5%;\n    margin-bottom: 7%;\n    color: #000;\n    margin-left: 9%;\n    width: 100%;\n    }\n/*.boxxlr:hover {\n    border: 0px solid green;\n    background: green;\n    margin-right: 1%;\n    margin-bottom: 1%;\n     color: #fff;\n}*/\n\n.boxclrtitle {\nfont-size: 12px;font-weight: bold;\n}\n\n.boxclrtitle1 {\nfont-size: 18px;font-weight: bold;\n}\n/*.boxxlr .boxclrtitle:hover {\nfont-size: 12px;font-weight: bold;\n}*/\n\n\n  ",
                }}
              />

<div className="text-center mt-5">
                <img
                  src={StepFive}
                  className="rounded"
                  style={{ width: 700, height: 80 }}
                />
              </div>

              <div className="row no-gutters">
                <div className="content col-xl-12 d-flex align-items-center">
                  <div className="content col-xl-12 d-flex align-items-center">
                    <div
                      className="row mt-4"
                      style={{
                        display: "flex",
                        justifyContent: "center" /* alignItems: 'center', */,
                        background: "#fff",
                        width: "100%" /* marginLeft: '50%', */,
                        borderRadius: "20px",
                        margin: "auto",
                      }}
                    >
                      {/*     <h2 style="font-size: 20px;letter-spacing: 6px;text-align: center;color: #000;
    font-weight: 600; color: #fff;background: green; padding: 1%; width: 70%;margin: 6%;"> Create an Account </h2> */}
                      <div className="row h-100 row align-items-center">
                        <div
                          className="col-md-10"
                          style={{ margin: "auto", marginBottom: "6%" }}
                        >
                          <form onSubmit={this.handleSubmit}>
                            <img
                              src={Login5}
                              style={{
                                marginLeft: "40%",
                                marginBottom: "2%",
                                marginTop: "5%",
                              }}
                            />
                            <h4 style={{ textAlign: "center" }}>
                              Sign in to GigeconoMe
                            </h4>
                            <div
                              className="form-group"
                              style={{ padding: "2%", borderRadius: "1px" }}
                            >
                              <input
                              className={errors.email ? "error" : ""}
                              value={this.state.email}
                                style={{ padding: "2%" }}
                                type="email"
                                className="form-control"
                                placeholder="Email id"
                                id="email1"
                                 name="email"
                                autoComplete="off"
                                 onChange={this.getData}
                                 onChange={this.handleEmailChange}
                              />
                            </div>
                            <div
                              className="form-group"
                              style={{ padding: "2%", borderRadius: "1px" }}
                            >
                              <input
                              className={errors.password ? "error" : ""}
                                style={{ padding: "2%" }}
                                type="password"
                                className="form-control"
                                placeholder="password"
                                id="password1"
                                name="password"
                                required
                                onChange={this.getData}
                                value={this.state.password}
                                onChange={this.handlePasswordChange}
                              />
                            </div>
                            <Link
                              to='/reset'
                              
                              style={{
                                float: "right",
                                color: "rgb(0, 147, 69)",
                                fontSize: "14px",
                              }}
                            >
                              Forgot password ?
                            </Link>
                            <button
                            disabled={isDisabled}
                              className="btn btn-primary"
                              style={{
                                padding: "1%",
                                marginLeft: "23%",
                                marginBottom: "4%",
                                color: "#fff",
                                background: "rgb(0, 147, 69)",
                                width: "50%",
                                border: "none",
                                borderRadius: "1px",
                                marginTop: "5%",
                                fontSize: "20px",
                              }}
                              //onClick={this.signin}
                              type="submit"
                            >
                              Sign in with Email
                            </button>
                          </form>
                          <div
                            style={{
                              float: "left",
                              width: "45%",
                              marginRight: "3%",
                            }}
                          >
                            <hr />
                          </div>
                          <div style={{ float: "right", width: "44%" }}>
                            <hr />
                          </div>
                          Or
                          <h6 style={{fontFamily:'GothamLight',fontWeight:'bold', textAlign: "center", marginTop: "2%" }}>
                            With Your Social Network
                          </h6>
                          <div className="row" style={{ margin: "5%" }}>
                            <div className="box col">
                              <img
                                className="snalgn"
                                src={Login1}
                              />
                            </div>
                            <div className="box col">
                              <img
                                className="snalgn"
                                src={Login2}
                              />
                            </div>
                            <div className="box col">
                              <img
                                className="snalgn"
                                src={Login3}
                              />
                            </div>
                            <div className="box col">
                              <img
                                className="snalgn"
                                src={Login4}
                              />
                            </div>
                          </div>
                          <h5 style={{ textAlign: "center" }}>
                            Don't have an account?
                          </h5>
                          <h6 style={{ textAlign: "center", color: "rgb(0, 147, 69)" }}>
                            Join as student
                            <span style={{ color: "transparent" }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </h6>
                          <h6 style={{ textAlign: "center", color: "rgb(0, 147, 69)" }}>
                            Join as a Employer or startup
                          </h6>
                          <h6 style={{ textAlign: "center", color: "rgb(0, 147, 69)" }}>
                            Join as a University
                            <span style={{ color: "transparent" }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        {/* End #main */}
        {/* ======= Footer ======= */}

      </div>
    );
  }
}
export default SigninProject;
