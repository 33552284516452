import React, { useState } from "react";
import { Redirect, Switch, Route, Link } from "react-router-dom";
import SummaryLogo from "../../images/plogo.PNG";
import "./dashboard.css";
import jsPDF from "jspdf";
import Resizer from "react-image-file-resizer";
import DashboardMain from "./DashboardMain";
import Header from "../Header/Header";

class OpportunitySummary extends React.Component {
  constructor() {
    super();
    let comments = JSON.parse(sessionStorage.getItem("someData"));
    let postopportunityID = JSON.parse(sessionStorage.getItem("PostOPP"));
    let Documents = JSON.parse(sessionStorage.getItem("Document"));
    this.state = {
      userid:comments,
      studentData: [],
      id: "",
      name: "",
      dropdownlist_input: "nationality",
      userid:comments,
      postopportunityid:postopportunityID,
      projname:"",
      projteamsize:"",
      projtype:"",
      projidea:"",
      projdescription:"",
      projsize:"",
      projpostedtype:"",
      categoryname:"",
      subcategoryname:"",
      departmentname:"",
      locationname:"",
      startdate:"",
      OptimalfrequencyProject:"",
      projdescription:"",
      skillname:"",
      languagename:"",
      opentoallintrested:"",
      estimatedProjecttolast:"",
      studenttypename:"",
      price:"",
      addDocuments:Documents,
      cpylogo:"",
      companyname:"",
      cpyindestryname:"",
      cpyfoundedyear:"",
      cpywebsite:"",
      cpylocation:"",
      companysize:"",
      opportunitiestype:"",
      SubjectData:[],
      SubjectID:0,
      DegreeData:[],
      Jobtype:"",
      Jobtitle:"",
      employmenttype:"",
      numberopportunities:"",
      requireddocs:"",
      requireddocsdescp:"",
      oppdescription:"",
      workpermit:"",
      workerseligible:"",
      workingdays:"",
      startdate:"",
     price:"",
     tillmonths:"",
paytype:"",
pdfmaking:0,
summarypdf:"",
deadlinedate:"",
displayemp:""
    };
    this.handleImage=this.handleImage.bind(this)
    this.createElements=this.createElements.bind(this)
    this.handleClose=this.handleClose.bind(this)
  }
  handleClose(){
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // for smoothly scrolling
    });
   window.location.href=`./home`
  }
  
  createElements(){

    var elements = [];
    elements.push(
<>
{this.state.DegreeData.map(function(e, i){
  console.log('test');
  return <div key={i}>
 <div className="row">
 <div className="col-sm-12" key={i}>

 <h3 style={{fontFamily:"GothamLight",fontWeight:"bold",fontSize:18,paddingTop:"2%"}}>
     {e.subjectname} </h3></div>
     

     <div className="col-sm-12">
     {e.degreename.split(",")
         .map((item, i) =>  <div class="col mt-3" style={{float:"left",paddingRight:"1%"}}>  
         <button style={{fontSize:15,fontFamily:"GothamLight",fontWeight:"bold"}}>{item}</button>
         </div>)}  
     </div>
     </div>
  </div>
})}



</>
    )
  return elements;  

  }
  componentDidMount() {
   
   
{/* Get Degree & Course Api Call */}

const degreedata = { addcpydata:"getOpppreviewallsubjectinfo",
userid:this.state.userid,
postopportunityid:this.state.postopportunityid
};

fetch("https://www.gigeconome.com:5200/api/addcpy_profiledata", {
method: "POST",

body: JSON.stringify(degreedata), // data can be `string` or {object}!

headers: { "Content-Type": "application/json" },
})
.then((res) => res.json())

.catch((error) => console.error("Error:", error))

.then((response) => {
if(response.message=="No Data Found."){
  this.setState({SubjectID:0})
}
else{
  this.setState({DegreeData:response.result,SubjectID:1},()=>{
    this.createElements()
  })
}

//console.log(response)
});

   {/* Get Company Details Api Call */}
   const cpydata = { 
   userid:this.state.userid,
  };
  
  fetch("https://www.gigeconome.com:5200/api/getcpyviewmyinfo", {
    method: "POST",
  
    body: JSON.stringify(cpydata), // data can be `string` or {object}!
  
    headers: { "Content-Type": "application/json" },
  })
    .then((res) => res.json())
  
    .catch((error) => console.error("Error:", error))
  
    .then((response) => {
     this.setState({
      cpylogo:response.user.cpylogo,
      companyname:response.user.companyname,
      cpyindestryname:response.user.industryname,
      cpyfoundedyear:response.user.cpyfoundedyear,
      cpywebsite:response.user.websitename,
      cpylocation:response.user.locationname,
      companysize:response.user.orgsizename,
      opportunitiestype:response.user.opportunitiestype,
  
 pdfmaking:1
     

    })
  {/* 
  var doc=new jsPDF('l', 'pt', "a2",'p', 'mm', [600, 1880]);
      //doc.text(20,20,"This is Default Tex
      doc.html(document.querySelector("#preview_contain"),{
        callback:function(pdf){
          pdf.save("mypdf.pdf")
         console.log(pdf)
         var out = doc.output();
          var url = 'data:application/pdf;base64,' + btoa(out)
          console.log(url)
        },
       
    
      }) */}
      
    
    //console.log(response.user.industryname)
    });

    
     {/* Get Project Summary Api Call */}

 const studentdata = { addcpydata:"getpreviewpostopportunitydetails",
 userid:this.state.userid,
 postopportunityid:this.state.postopportunityid
};
console.log(studentdata)
fetch("https://www.gigeconome.com:5200/api/addcpy_profiledata", {
 method: "POST",

 body: JSON.stringify(studentdata), // data can be `string` or {object}!

 headers: { "Content-Type": "application/json" },
})
 .then((res) => res.json())

 .catch((error) => console.error("Error:", error))

 .then((response) => {
   if(response.result.languagename==""||response.result.languagename==null){
     console.log("error")
   }
   else{
     this.setState({ languagename:response.result.languagename,})
   }
   if(response.result.skillsname==""||response.result.skillsname==null){
    console.log("error")
  }
  else{
    this.setState({ skillname:response.result.skillsname},()=>{
     // console.log(this.state.skillname)
    })
  }
  if(response.result.employability==""||response.result.employability==null){
    console.log("error")
  }
  else{
    this.setState({displayemp:response.result.employability,})
  }
   console.log(response)
   var res = response.result.requireddocs.replace(/,/g, ',  ');
 this.setState({requireddocs:res})
   this.setState({
    Jobtype:response.result.Jobtype,
    Jobtitle:response.result.Jobtitle,
    categoryname:response.result.categoryname,
    subcategoryname:response.result.subcategoryname,
    departmentname:response.result.departmentname,
    employmenttype:response.result.employmenttype,
    locationname:response.result.locationname,
    numberopportunities:response.result.numberopportunities,
    studenttypename:response.result.studenttypename,
   
    requireddocsdescp:response.result.requireddocsdescp,
    oppdescription:response.result.oppdescription,
  
   
    opentoallintrested:response.result.opentoallintrested,
    estimatedProjecttolast:response.result.estimatedProjecttolast,
    workpermit:response.result.workpermit,
    workerseligible:response.result.workerseligible,
    workingdays:response.result.workingdays,
    startdate:response.result.startdate,
    OptimalfrequencyProject:response.result.OptimalfrequencyProject,
    price:response.result.price,
    paytype:response.result.paytype,
    deadlinedate:response.result.deadlinedate,
   
    tillmonths:response.result.tillmonth
 })
 //console.log(response)
 });
  }
  getBase64 = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();
  
      // Convert the file to base64 text
      reader.readAsDataURL(file);
  
      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        console.log("Called", reader);
        baseURL = reader.result;
        console.log(baseURL);
        resolve(baseURL);
      };
      console.log(fileInfo);
    });
  };
  handleImage = (e) => {
    // let file = new FileReader();
    //console.log(e.target.files[0]);
    //console.log(e.target.files[0].name);
   // sessionStorage.setItem("Document",JSON.stringify(e.target.files[0].name))
    let { file } = this.state;
  
    file = e.target.files[0];
  
    this.getBase64(file)
      .then((result) => {
        file["base64"] = result;
        console.log("File Is", file);
        this.setState({
          summarypdf: result,
          file,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  
    this.setState({
      //  file: e.target.files[0]
    });
  
    this.setState({
      //companylogo: e.target.files[0]
    });
    var fileInput = false;
    if (e.target.files[0]) {
      fileInput = true;
    }
    
    if(fileInput) {
      try {
        Resizer.imageFileResizer(
          e.target.files[0],
          120,
          120,
          "JPEG",
          100,
          0,
          (uri) => {
            //console.log(uri);
            this.setState({ newImage: uri });
          },
          "base64",
          120,
          120
        );
      } catch (err) {
        console.log(err);
      }
    }
  };
  render() {
   
    return (
      <div>
        

       <Header/>
        <div style={{ marginTop: "6%" }}></div>
        

        <main id="postaproject_img">
          <br />
          <br />

          <div className="container">
            <div class="row text-center text-white">
              <h1 style={{ fontSize: 60, marginTop: "2%" }}>Post an Opportunity</h1>
            </div>
           
            <div class="row" style={{ marginTop: "3%" }}></div>

            <div class="row">
              <div class="project">Opportunity Summary</div>
            </div>
            <div class="row mt-5" id="preview_contain">
              <div class="row">
               
              <div class="row">
               
                         
               
        
               <div class="row" style={{paddingLeft:"4%",paddingTop:"3%"}}>
                 <div class="col-sm-3" style={{width:"21%",boxShadow:"0px 0px 10px 5px #888888"}}>
                 <div
                     class="card"
                     style={{
                       backgroundColor: "white",
                       height: "100%",
                       border: "none",
                       borderRadius: 1,
                     }}
                    
                   >
                     {this.state.cpylogo==""?<>
                     <img src={SummaryLogo} style={{width:"75%",margin:"14%"}}/>
                     </>:<>
                     <img src={this.state.cpylogo} style={{width:"90%",margin:"5% 6%"}}/>
                     </>}
                   
                
                   
   
                      
                   
                   </div>
   
   
   
   
   
   
                 </div>
                 <div class="col-sm-9" style={{backgroundColor:"white",boxShadow:"0px 0px 10px 5px #888888",marginLeft:"4%"}}>
                   <div
                     class="card"
                     style={{
                       backgroundColor: "white",
                       height: "100%",
                       border: "none",
                       borderRadius: 1,
                     }}
                     
                   >
                     <div className="row">
                       
                       <div className="col-sm-5">
                         <h3 style={{color:"rgb(0,147,69)",fontFamily:"Gotham"
                       ,paddingLeft:"7%",fontSize:22,marginTop:"1%"
                       }}>{this.state.companyname}</h3>
                       
                       </div>
                       <div className="col-sm-1">
   
                       </div>
                       <div className="col-sm-6">
                         <h5 style= {{color:"rgb(0,147,69)",fontFamily:"Gotham",
                         paddingTop:"0%",textAlign:"right",marginRight:"20%"
                     
                       }}> <i class="fa fa-globe"></i> &nbsp; 
                       
                       <span style={{marginLeft:"-1%",fontSize:16}}> {this.state.cpywebsite==""?null:<>{this.state.cpywebsite}</>}</span></h5>
                       
                       </div>
                     </div>
                    
                     <div className="row">
                       <div className="col-sm-5">
                       <h5 style= {{fontFamily:"Gotham",
                         paddingTop:"5%",paddingLeft:"4%",
                         fontSize:16
                     
                       }}> <i class="fa fa-cog" style={{color:"rgb(0,147,69)"}}></i> &nbsp; {this.state.cpyindestryname}</h5>
                       
                       </div>
                       <div className="col-sm-3">
   
                       </div>
                       <div className="col-sm-4">
                         <h5 style= {{fontFamily:"Gotham",
                         paddingTop:"5%", fontSize:16,
                         marginRight:"8%",textAlign:"left"
                     
                       }}> <i class="fa fa-map-marker" style={{color:"rgb(0,147,69)"}}></i> &nbsp; {this.state.cpylocation}</h5>
                       
                       </div>
                     </div>
   
                     <div className="row">
                     {this.state.cpyfoundedyear==null?<>
                     <div className="col-sm-5">
                       <h5 style= {{fontFamily:"Gotham",
                         paddingTop:"5%",paddingLeft:"4%", fontSize:15
                     
                       }}> <i class="fa fa-calendar" style={{color:"rgb(0,147,69)"}}></i> &nbsp; Founder Year Not Avaliable </h5>
                       
                       </div>
                     </>:<>
   
                     <div className="col-sm-5">
                       <h5 style= {{fontFamily:"Gotham",
                         paddingTop:"5%",paddingLeft:"4%", fontSize:18
                     
                       }}> <i class="fa fa-calendar" style={{color:"rgb(0,147,69)"}}></i> &nbsp; Founded {this.state.cpyfoundedyear}</h5>
                       
                       </div>
                     </>}
                  
                       <div className="col-sm-3">
   
                       </div>
                       <div className="col-sm-4">
                         <h5 style= {{fontFamily:"Gotham",
                         paddingTop:"5%", fontSize:16,textAlign:"left",marginRight:"8%"
                     
                       }}> <i class="fa fa-user" style={{color:"rgb(0,147,69)"}}></i> &nbsp; {this.state.companysize}</h5>
                       
                       </div>
                     </div>
   
                     <div class="container" style={{marginLeft:"10%",marginTop:"1%"}}>
                   <div class="row">
                   {this.state.opportunitiestype==null?<>
                     <span style={{fontFamily:"GothamLight",fontWeight:"bold"}}>Opportunities Not Avaliable.</span>
                   </>:<>
                   
                   {this.state.opportunitiestype.split(",")
     .map((item, i) =>  <div class="col-sm-3 divopptypeopp">  {item}  </div>)}
                   </>}
                     
                   
                     
                     
                   </div>
   </div>
                  
   
                
               
                 
                   </div>               
   </div>
   </div>
                </div>
                <div className="mt-5">
                  <div className="row" style={{paddingLeft:"2%"}}>
                    
                    <div class="col-sm-7">
                      <h4 style={{fontSize:20,fontFamily:"Gotham"}}>
                        Job Type :{" "}
                        <b style={{ fontFamily: "GothamLight" }}>
                          {this.state.Jobtype}
                        </b>{" "}
                      </h4>
                    </div>
                  
                    <div class="col-sm-2">
                      <b style={{ color: "rgb(0, 147, 69)", fontSize: 18 }}>
                      £&nbsp;{this.state.price} - {this.state.paytype}
                      </b>
                    </div>
                  </div>
                </div>
                <div className="mt-3">
                  <div className="row" style={{paddingLeft:"2%"}}>
                   
                    <div class="col-sm-5">
                      <h4 style={{ fontSize: 18, fontWeight: "bold" }}>
                        Job Title :{" "}
                        <b style={{ fontFamily: "GothamLight" }}>
                          {this.state.Jobtitle}
                        </b>{" "}
                      </h4>
                    </div>
                    <div class="col-sm-2"></div>
                    <div class="col-sm-5">
                      <h4 style={{ fontSize: 18, fontWeight: "bold" }}>
                        Paid/Unpaid :{" "}
                        <b style={{ fontFamily: "GothamLight" }}>
                         {this.state.price==" " || this.state.price==null?<>
                         Unpaid
                         </>:<>Paid</>}
                        </b>{" "}
                      </h4>
                    </div>
                  </div>
                </div>{" "}
                <div className="mt-3">
                  <div className="row" style={{paddingLeft:"2%"}}>
                    
                    <div class="col-sm-5">
                      <h4 style={{ fontSize: 18, fontWeight: "bold" }}>
                       Category :{" "}
                        <b style={{ fontFamily: "GothamLight" }}>
                          {this.state.categoryname}
                        </b>{" "}
                      </h4>
                    </div>
                    <div class="col-sm-2"></div>
                    <div class="col-sm-5">
                      <h4 style={{ fontSize: 18, fontWeight: "bold" }}>
                        Sub Category :{" "}
                        <b style={{ fontFamily: "GothamLight" }}> {this.state.subcategoryname}</b>{" "}
                      </h4>
                    </div>
                  </div>
                  <div className="row mt-3" style={{paddingLeft:"2%"}}>
                   
                    <div class="col-sm-5">
                      <h4 style={{ fontSize: 18, fontWeight: "bold" }}>
                        Department :{" "}
                        <b style={{ fontFamily: "GothamLight" }}>
                          {this.state.departmentname}
                        </b>{" "}
                      </h4>
                    </div>
                    <div class="col-sm-2"></div>
                    <div class="col-sm-5">
                      <h4 style={{ fontSize: 18, fontWeight: "bold" }}>
                        Employment Type :{" "}
                        <b style={{ fontFamily: "GothamLight" }}>
                         {this.state.employmenttype}
                        </b>{" "}
                      </h4>
                    </div>
                  </div>
                  <div className="row mt-3" style={{paddingLeft:"2%"}}>
                   
                    <div class="col-sm-5">
                      <h4 style={{ fontSize: 18, fontWeight: "bold" }}>
                        Location of Opportunity :{" "}
                        <div className="mt-2">
                          <b style={{ fontFamily: "GothamLight" }}>
                           {this.state.locationname}
                          </b>{" "}
                        </div>
                      </h4>
                    </div>
                    <div class="col-sm-2"></div>
                    <div class="col-sm-5">
                      <h4 style={{ fontSize: 18, fontWeight: "bold" }}>
                        No of Open Opportunities :{" "}
                        <div className="mt-2">
                          <b style={{ fontFamily: "GothamLight" }}>
                            {this.state.numberopportunities}
                          </b>{" "}
                        </div>
                      </h4>
                    </div>
                  </div>
                  <div className="row mt-3" style={{paddingLeft:"2%"}}>
                   
                    <div class="col-sm-5">
                      <h4 style={{ fontSize: 18, fontWeight: "bold" }}>
                        Duration of the Project:{" "}
                        <div className="mt-2">
                          <b style={{ fontFamily: "GothamLight" }}>
                           {this.state.estimatedProjecttolast}
                          </b>{" "}
                        </div>
                      </h4>
                    </div>
                    <div class="col-sm-2"></div>
                    <div class="col-sm-5">
                      <h4 style={{ fontSize: 18, fontFamily:"Gotham" }}>
                       Till Months :
                        <div className="mt-2">
                          <b style={{ fontFamily: "GothamLight" }}>{this.state.tillmonths}</b>{" "}
                        </div>
                      </h4>
                    </div>
                  </div>
                  <div className="row mt-3" style={{paddingLeft:"2%"}}>
           
                    <div class="col-sm-5">
                      <h4 style={{ fontSize: 18, fontFamily:"Gotham" }}>
                        Remote Workers eligible :{" "}
                        <b style={{ fontFamily: "GothamLight" }}>{this.state.workerseligible}</b>{" "}
                      </h4>
                    </div>
                    <div class="col-sm-2"></div>
                    <div class="col-sm-5">
                      <h4 style={{ fontSize: 18, fontFamily:"Gotham" }}>
                        No of Remote Working Days :{" "}
                        <b style={{ fontFamily: "GothamLight" }}>
                         {this.state.workingdays}
                        </b>{" "}
                      </h4>
                    </div>
                  </div>
                  <div className="row mt-3" style={{paddingLeft:"2%"}}>
                    
                    <div class="col-sm-5">
                      <h4 style={{ fontSize: 18, fontWeight: "bold" }}>
                       Start date :{" "}
                        <b style={{ fontFamily: "GothamLight" }}>
                         {this.state.startdate}
                        </b>{" "}
                      </h4>
                    </div>
                    <div class="col-sm-2"></div>
                    <div class="col-sm-5">
                      <h4 style={{ fontSize: 18, fontWeight: "bold" }}>
                        Deadline Date :{" "}
                        <b style={{ fontFamily: "GothamLight" }}>
                          {this.state.deadlinedate == null || this.state.deadlinedate==" "?<>
                          No
                          </>:<>{this.state.deadlinedate}</>}
                        </b>{" "}
                      </h4>
                    </div>
                  </div>
                  <div className="row mt-3" style={{paddingLeft:"2%"}}>
           
           <div class="col-sm-5">
           <h4 style={{ fontSize: 18, fontFamily:"Gotham" }}>
                        Role Requires work Permit :
                        <div className="mt-2">
                          <b style={{ fontFamily: "GothamLight" }}>{this.state.workpermit}</b>{" "}
                        </div>
                      </h4>
           </div>
           <div class="col-sm-2"></div>
           
         </div>
                  {this.state.studenttypename==null?null:<>
                    <div className="row mt-4" style={{paddingLeft:"2%"}}>
                   
                    <div class="col-sm-10">
                      <h4
                        style={{
                          fontSize: 18,
                          fontFamily:"Gotham"
                        }}
                      >
                        Preferred Student Type : 
                      </h4>
                      <div className="row">
                            {this.state.studenttypename.split(",")
                      .map((item, i) =>  <div class="col-sm-3" style={{paddingTop:"2%"}}>  
                     <i className="fa fa-circle" style={{color:"rgb(0,147,69)"}}>
                      <span style={{color:"black",fontFamily:"GothamLight",fontWeight:"bold"}}>&nbsp;&nbsp;{item}</span> 
                      </i>
                      </div>)}  

                      </div>
                    

                    
                    </div>
                  </div>
                  
                  </>}
                 
                  {this.state.SubjectID==0?null:<>
                    <div className="row mt-4" style={{paddingLeft:"2%"}}>
                   
                    <div class="col-sm-10">
                      <h4
                        style={{
                          fontSize: 18,
                          fontFamily:"Gotham"
                        }}
                      >
                        Eligible Subject and Degrees :
                      </h4>
                      <div style={{paddingTop:"1%"}}>
                      {this.createElements()}
                      
                                  </div>
                                 
                    </div>
                  </div>
                  
                  </>}


                
              
                  <div className="row mt-5" style={{paddingLeft:"2%"}}>
                    
                    <div class="col-sm-10">
                      <h4
                        style={{
                          fontSize: 18,
                         
                          fontWeight: "bold",
                        }}
                      >
                        Additional Required Documents :&nbsp;
                        <b style={{ fontFamily: "GothamLight" }}>
                          {this.state.requireddocs}
                        </b>{" "}
                      </h4>
                      <div
                        style={{
                          fontFamily: "GothamLight",
                          fontWeight: "bold",
                        }}
                      >
                       {this.state.requireddocsdescp}
                      </div>
                    </div>
                  </div>
                  <div className="row mt-5" style={{paddingLeft:"2%"}}>
                    
                    <div class="col-sm-10">
                      <h4
                        style={{
                          fontSize: 18,
                          textDecoration: "underline",
                          fontWeight: "bold",
                        }}
                      >
                        Tell us More about the Project:
                      </h4>
                      <div
                        style={{
                          fontFamily: "GothamLight",
                          fontWeight: "bold",
                        }}
                      >
                      {this.state.oppdescription}
                      </div>
                    </div>
                  </div>
                 

                  <div className="row mt-3" style={{paddingLeft:"2%"}}>
                  
                  <div class="col-sm-10">
                   
          
                     
                     
                  
                      <div class="row mt-3">
                      {this.state.skillname=="" || this.state.skillname==null?<></>
                      :<>
                       <h4
                      style={{
                        fontSize: 18,
                        
                        fontWeight: "bold",
                      }}
                    >
                      Skills Required:
                    </h4>
                    {this.state.skillname.split(",")
                  .map((item, i) =>  <div class="col-sm-3" id="displayskill">  
                  <span>{item}</span>
                  </div>)}  
                      </>}
                                    

     
                   </div>
                    
                    </div>
            
                </div>

                <div className="row mt-3" style={{paddingLeft:"2%"}}>
                  
                  <div class="col-sm-10">
                    <h4
                      style={{
                        fontSize: 18,
                        
                        fontWeight: "bold",
                      }}
                    >
                      The employability or Soft Skills our student would gain :
                    </h4>
          
                     
                     
                  
                      <div class="row mt-3">
                  
                                      {this.state.displayemp.split(",")
                  .map((item, i) =>  <div class="col-sm-3" id="displayskill">  
                  <span>{item}</span>
                  </div>)}  

     
                   </div>
                    
                    </div>
            
                </div>
                  <div className="row mt-4" style={{paddingLeft:"2%"}}>
                    
                    <div class="col-sm-7">
                      <h4 style={{ fontSize: 18, fontWeight: "bold" }}>
                        Mastered Language :{" "}
                        <div className="row" style={{marginTop:"2%"}}>
                        {this.state.languagename.split(",")
                        .map((item, i) =>  <div class="col-sm-2" id="displayskill">  
                        <span>{item}</span>
                        </div>)} 

                        </div>
                         

                      </h4>
                    </div>
                    <div class="col-sm-1"></div>
                  </div>
                  <div className="row mt-4" style={{paddingLeft:"2%"}}>
                   
                    <div class="col-sm-7">
                      <h4 style={{ fontSize: 18, fontWeight: "bold" }}>
                      Open to all interested candidates :{" "}
                        <b style={{ fontFamily: "GothamLight" }}>
                          &nbsp;&nbsp; {this.state.opentoallintrested}
                        </b>{" "}
                      </h4>
                    </div>
                    <div class="col-sm-1"></div>
                  </div>
                  <div className="row mt-4" style={{paddingLeft:"2%"}}>
                   
                    <div class="col-sm-4 mt-1">
                      <h4 style={{ fontSize: 18, fontFamily:"Gotham" }}>
                        Attachment of Additional Material Shown : &nbsp;&nbsp;{" "}
                        
                      </h4>
                    </div>
                    <div class="col-sm-3"><input
                          type="text"
                          disabled
                          className="form-control"
                          placeholder={this.state.addDocuments}
                          id="stepone"
                        /></div>
                  </div>
                  <div className="row mt-5">
                    <div class="col-sm-6"></div>
                    <div class="col-sm-6">
                      <Link to="/home" class="btn btn-success" id="clo_btn" onClick={this.handleClose}>Close</Link>
                    </div>
                  </div>
                  <div className="row mt-5"></div>
                </div>
                <div class="col-sm-1"></div>
              </div>
            </div>
            <div className="row mt-5"></div>
          </div>
        </main>
      </div>
    );
  }
}

export default OpportunitySummary;
