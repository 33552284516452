import React, { useState } from "react";

import { Redirect, Switch, Route, Link } from "react-router-dom";
import SummaryLogo from "../../images/plogo.PNG";
import Header from "../Header/Header";
import "./dashboard.css";



class ProjectSummaryStudent extends React.Component {
  
  constructor() {
 super()
   
     
    let PostaprojectID = JSON.parse(sessionStorage.getItem("PostID"));
    let Documents = JSON.parse(sessionStorage.getItem("Document"));
    let comments = JSON.parse(sessionStorage.getItem("Companyuserid"));
    this.state = {
     
      studentData: [],
      id: "",
      name: "",
      dropdownlist_input: "nationality",
      userid:comments,
      postprojectid:PostaprojectID,
      projname:"",
      projteamsize:"",
      projtype:"",
      projidea:"",
      projdescription:"",
      projsize:"",
      projpostedtype:"",
      categoryname:"",
      subcategoryname:"",
      departmentname:"",
      locationname:"",
      startdate:"",
      OptimalfrequencyProject:"",
      projdescription:"",
      skillname:"",
      languagename:"",
      opentoallintrested:"",
      estimatedProjecttolast:"",
      studenttypename:"",
      price:"",
      addDocuments:Documents,
      cpylogo:"",
      companyname:"",
      cpyindestryname:"",
      cpyfoundedyear:"",
      cpywebsite:"",
      cpylocation:"",
      companysize:"",
      opportunitiestype:"",
      SubjectData:[],
      SubjectID:0,
      DegreeData:[],
      resvalusechange:"",
    };
    this.createElements=this.createElements.bind(this)
    this.handleClose=this.handleClose.bind(this)
  }
handleClose(){
  sessionStorage.removeItem("PostID");
}

  createElements(){

    var elements = [];
    elements.push(
<>
{this.state.DegreeData.map(function(e, i){
  //console.log('test');
  return <div key={i}>
 <div className="row">
 <div className="col-sm-12" key={i}>

 <h3 style={{fontFamily:"GothamLight",fontWeight:"bold",fontSize:18,paddingTop:"2%"}}>
     {e.subjectname} </h3></div>
     

     <div className="col-sm-12">
     {e.degreename.split(",")
         .map((item, i) =>  <div class="col mt-3" style={{float:"left",paddingRight:"1%"}}>  
         <button style={{fontSize:12,fontFamily:"GothamLight",fontWeight:"bold"}}>{item}</button>
         </div>)}  
     </div>
     </div>
  </div>
})}



</>
    )
  return elements;  

  }
  
  componentDidMount() {
   
{/* Get Degree & Course Api Call */}


const degreedata = { addcpydata:"getpreviewallsubjectinfo",
userid:this.state.userid,
postprojectid:this.state.postprojectid
};
console.log(degreedata)
fetch("https://www.gigeconome.com:5200/api/addcpy_profiledata", {
method: "POST",

body: JSON.stringify(degreedata), // data can be `string` or {object}!

headers: { "Content-Type": "application/json" },
})
.then((res) => res.json())

.catch((error) => console.error("Error:", error))

.then((response) => {
if(response.message=="No Data Found."){
  this.setState({SubjectID:0})
}
else{
  this.setState({DegreeData:response.result,SubjectID:1},()=>{
   this.createElements()
  })
}

console.log(response)
});

   {/* Get Company Details Api Call */}
   const cpydata = { 
   userid:this.state.userid,
  };
  
  fetch("https://www.gigeconome.com:5200/api/getcpyviewmyinfo", {
    method: "POST",
  
    body: JSON.stringify(cpydata), // data can be `string` or {object}!
  
    headers: { "Content-Type": "application/json" },
  })
    .then((res) => res.json())
  
    .catch((error) => console.error("Error:", error))
  
    .then((response) => {
     this.setState({
      cpylogo:response.user.cpylogo,
      companyname:response.user.companyname,
      cpyindestryname:response.user.industryname,
      cpyfoundedyear:response.user.cpyfoundedyear,
      cpywebsite:response.user.websitename,
      cpylocation:response.user.locationname,
      companysize:response.user.orgsizename,
      opportunitiestype:response.user.opportunitiestype

    })
    //console.log(response)
    });

    
    {/* Get Project Summary Api Call */}
 const studentdata = { addcpydata:"getpreviewpostprojectdetails",
 userid:this.state.userid,
postprojectid:this.state.postprojectid
};
console.log(studentdata)
fetch("https://www.gigeconome.com:5200/api/addcpy_profiledata", {
  method: "POST",

  body: JSON.stringify(studentdata), // data can be `string` or {object}!

  headers: { "Content-Type": "application/json" },
})
  .then((res) => res.json())

  .catch((error) => console.error("Error:", error))

  .then((response) => {
   this.setState({projname:response.result.projname,
                  categoryname:response.result.categoryname,
                  subcategoryname:response.result.subcategoryname,
                  projteamsize:response.result.projteamsize,
                  projtype:response.result.projtype,
                  departmentname:response.result.departmentname,
                  projsize:response.result.projsize,
                  projpostedtype:response.result.projpostedtype,
                  projidea:response.result.projidea,
                  locationname:response.result.locationname,
                  startdate:response.result.startdate,
                  OptimalfrequencyProject:response.result.OptimalfrequencyProject,
                  projdescription:response.result.projdescription,
                  skillname:response.result.skillname,
                  languagename:response.result.languagename,
                  opentoallintrested:response.result.opentoallintrested,
                  estimatedProjecttolast:response.result.estimatedProjecttolast,
                  studenttypename:response.result.studenttypename,
                  price:response.result.price
  })
  //console.log(response)
  });
  }
  
  

  render() {
   
   
    return (
      <div>
        

        <Header />
        <div style={{ marginTop: "6%" }}></div>
       

        <main id="postaproject_img">
          <br />
          <br />

          <div className="container">
            <div class="row text-center text-white">
              <h1 style={{ fontSize: 60,marginTop:"2%" }}>Post a Project</h1>
            </div>
            <div class="row" style={{ marginTop:"3%" }}></div>
        
            <div class="row">
             <span
             className="project">Project Summary</span>
            </div>

            <div class="row mt-5" id="preview_contain">
              <div class="row">
               
                         
               
        
            <div class="row" style={{paddingLeft:"4%",paddingTop:"3%"}}>
              <div class="col-sm-3" style={{width:"21%",boxShadow:"0px 0px 10px 5px #888888"}}>
              <div
                  class="card"
                  style={{
                    backgroundColor: "white",
                    height: "100%",
                    border: "none",
                    borderRadius: 1,
                  }}
                 
                >
                  {this.state.cpylogo==""?<>
                  <img src={SummaryLogo} style={{width:"75%",margin:"14%"}}/>
                  </>:<>
                  <img src={this.state.cpylogo} style={{width:"90%",margin:"5% 6%"}}/>
                  </>}
                
             
                

                   
                
                </div>






              </div>
              <div class="col-sm-9" style={{backgroundColor:"white",boxShadow:"0px 0px 10px 5px #888888",marginLeft:"4%"}}>
                <div
                  class="card"
                  style={{
                    backgroundColor: "white",
                    height: "100%",
                    border: "none",
                    borderRadius: 1,
                  }}
                  
                >
                  <div className="row">
                    
                    <div className="col-sm-5">
                      <h3 style={{color:"rgb(0,147,69)",fontFamily:"Gotham"
                    ,paddingLeft:"7%",fontSize:22,marginTop:"1%"
                    }}>{this.state.companyname}</h3>
                    
                    </div>
                    <div className="col-sm-1">

                    </div>
                    <div className="col-sm-6">
                      <h5 style= {{color:"rgb(0,147,69)",fontFamily:"Gotham",
                      paddingTop:"0%",textAlign:"right",marginRight:"20%"
                  
                    }}> <i class="fa fa-globe"></i> &nbsp; 
                    
                    <span style={{marginLeft:"-1%",fontSize:16}}> {this.state.cpywebsite==""?null:<>{this.state.cpywebsite}</>}</span></h5>
                    
                    </div>
                  </div>
                 
                  <div className="row">
                    <div className="col-sm-5">
                    <h5 style= {{fontFamily:"Gotham",
                      paddingTop:"5%",paddingLeft:"4%",
                      fontSize:16
                  
                    }}> <i class="fa fa-cog" style={{color:"rgb(0,147,69)"}}></i> &nbsp; {this.state.departmentname}</h5>
                    
                    </div>
                    <div className="col-sm-3">

                    </div>
                    <div className="col-sm-4">
                      <h5 style= {{fontFamily:"Gotham",
                      paddingTop:"5%", fontSize:16,
                      marginRight:"8%",textAlign:"left"
                  
                    }}> <i class="fa fa-map-marker" style={{color:"rgb(0,147,69)"}}></i> &nbsp; {this.state.locationname}</h5>
                    
                    </div>
                  </div>

                  <div className="row">
                  {this.state.cpyfoundedyear==null?<>
                  <div className="col-sm-5">
                    <h5 style= {{fontFamily:"Gotham",
                      paddingTop:"5%",paddingLeft:"4%", fontSize:15
                  
                    }}> <i class="fa fa-calendar" style={{color:"rgb(0,147,69)"}}></i> &nbsp; Founder Year Not Avaliable </h5>
                    
                    </div>
                  </>:<>

                  <div className="col-sm-5">
                    <h5 style= {{fontFamily:"Gotham",
                      paddingTop:"5%",paddingLeft:"4%", fontSize:18
                  
                    }}> <i class="fa fa-calendar" style={{color:"rgb(0,147,69)"}}></i> &nbsp; Founded {this.state.cpyfoundedyear}</h5>
                    
                    </div>
                  </>}
               
                    <div className="col-sm-3">

                    </div>
                    <div className="col-sm-4">
                      <h5 style= {{fontFamily:"Gotham",
                      paddingTop:"5%", fontSize:16,textAlign:"left",marginRight:"8%"
                  
                    }}> <i class="fa fa-user" style={{color:"rgb(0,147,69)"}}></i> &nbsp; {this.state.companysize}</h5>
                    
                    </div>
                  </div>

                  <div class="container" style={{marginLeft:"10%",marginTop:"1%"}}>
                <div class="row">
                {this.state.opportunitiestype==null?<>
                  <span style={{fontFamily:"GothamLight",fontWeight:"bold"}}>Opportunities Not Avaliable.</span>
                </>:<>
                
                {this.state.opportunitiestype.split(",")
  .map((item, i) =>  <div class="col-sm-3 divopptypepost">  {item}  </div>)}
                </>}
                  
                
                  
                  
                </div>
</div>
               

             
            
              
                </div>               
</div>
</div>
             
                <div className="mt-5">
                  <div className="row" style={{paddingLeft:"2%"}}>
                   
                    <div class="col-sm-7">
                      <h4 style={{fontFamily:"Gotham",fontSize:20}}>
                        Name of the Project :{" "}
                        {this.state.projname==""?null:<>
                        <b style={{ fontFamily: "GothamLight" }}>
                          {this.state.projname}
                        </b>{" "}
                        </>}
                       
                      </h4>
                    </div>
                    
                    <div class="col-sm-2">
                      <b style={{ color: "rgb(0, 147, 69)", fontSize: 18 }}>
                       {this.state.price==""?null:<>$ {this.state.price}</>}
                      </b>
                    </div>
                  </div>
                </div>
                <div className="mt-3">
                  <div className="row" style={{paddingLeft:"2%"}}>
                   
                    <div class="col-sm-6">
                      <h4 style={{ fontSize: 18, fontWeight: "bold" }}>
                        Category :{" "}
                        {this.state.categoryname==""?null:<>
                        <b style={{ fontFamily: "GothamLight" }}>
                          {this.state.categoryname}
                        </b>{" "}
                        
                        </>}
                       
                      </h4>
                    </div>
                    <div class="col-sm-1"></div>
                    <div class="col-sm-5">
                      <h4 style={{ fontSize: 18, fontWeight: "bold" }}>
                        Sub Category :{" "}
                        {this.state.subcategoryname==""?null:<>
                        <b style={{ fontFamily: "GothamLight" }}>
                         {this.state.subcategoryname}
                        </b>{" "}
                        
                        </>}
                       
                      </h4>
                    </div>
                  </div>
                </div>{" "}
                <div className="mt-3">
                  <div className="row" style={{paddingLeft:"2%"}}>
                    
                    <div class="col-sm-6">
                      <h4 style={{ fontSize: 18, fontWeight: "bold" }}>
                        Department :{" "}
                        {this.state.departmentname==""?null:<>
                        
                        <b style={{ fontFamily: "GothamLight" }}>
                         {this.state.departmentname}
                        </b>{" "}
                        </>}
                       
                      </h4>
                    </div>
                    <div class="col-sm-1"></div>
                    <div class="col-sm-5">
                      <h4 style={{ fontSize: 18, fontWeight: "bold" }}>
                        Project Team Size :{" "}
                        {this.state.projteamsize==""?null:<>
                        <b style={{ fontFamily: "GothamLight" }}>{this.state.projteamsize}</b>{" "}
                        </>}
                       
                      </h4>
                    </div>
                  </div>
                  <div className="row mt-3" style={{paddingLeft:"2%"}}>
                   
                    <div class="col-sm-6">
                      <h4 style={{ fontSize: 18, fontWeight: "bold" }}>
                        Type of Project :{" "}
                        {this.state.projtype==""?null:<>
                        <b style={{ fontFamily: "GothamLight" }}>
                         {this.state.projtype}
                        </b>{" "}
                        </>}
                       
                      </h4>
                    </div>
                    <div class="col-sm-1"></div>
                    <div class="col-sm-5">
                      <h4 style={{ fontSize: 18, fontWeight: "bold" }}>
                        Size of the Project :{" "}
                        {this.state.projsize==""?null:<>
                        <b style={{ fontFamily: "GothamLight" }}>
                          {this.state.projsize}
                        </b>{" "}
                        </>}
                       
                      </h4>
                    </div>
                  </div>
                  <div className="row mt-3" style={{paddingLeft:"2%"}}>
                   
                    <div class="col-sm-6">
                      <h4 style={{ fontSize: 18, fontWeight: "bold" }}>
                        Duration of the Project :{" "}
                        <div className="mt-2">
                          <b style={{ fontFamily: "GothamLight" }}>
                            {this.state.estimatedProjecttolast==""?null:<>
                            {this.state.estimatedProjecttolast}
                            </>}
                          </b>{" "}
                        </div>
                      </h4>
                    </div>
                    <div class="col-sm-1"></div>
                    <div class="col-sm-5">
                      <h4 style={{ fontSize: 18, fontWeight: "bold" }}>
                        Type of Project Posted :{" "}
                        <div className="mt-2">
                          {this.state.projpostedtype==""?null:<>
                          <b style={{ fontFamily: "GothamLight" }}>
                            {this.state.projpostedtype}
                          </b>{" "}
                          </>}
                         
                        </div>
                      </h4>
                    </div>
                  </div>
                  <div className="row mt-3" style={{paddingLeft:"2%"}}>
                   
                    <div class="col-sm-6">
                      <h4 style={{ fontSize: 18,fontFamily:"Gotham" }}>
                        New idea/Concept:{" "}
                        <div className="mt-2">
                          {this.state.projidea==""?null:<>
                          <b style={{ fontFamily: "GothamLight" }}>
                            {this.state.projidea}
                          </b>{" "}
                          </>}
                          
                        </div>
                      </h4>
                    </div>
                    <div class="col-sm-1"></div>
                    <div class="col-sm-5">
                      <h4 style={{ fontSize: 18, fontWeight: "bold" }}>
                        Location of Assignment :
                        <div className="mt-2">
                          {this.state.locationname==""?null:
                          
                          <> <b style={{ fontFamily: "GothamLight" }}>{this.state.locationname}</b>{" "}</>
                          }
                         
                        </div>
                      </h4>
                    </div>
                  </div>
                  <div className="row mt-3" style={{paddingLeft:"2%"}}>
                   
                    <div class="col-sm-6">
                      <h4 style={{ fontSize: 18, fontWeight: "bold" }}>
                        Start date :{" "}
                        {this.state.startdate==""?null:<>
                        <b style={{ fontFamily: "GothamLight" }}>{this.state.startdate}</b>{" "}
                        </>}
                        
                      </h4>
                    </div>
                    <div class="col-sm-1"></div>
                    <div class="col-sm-5">
                      <h4 style={{ fontSize: 18, fontWeight: "bold" }}>
                        Optimal Frequency of Project :{" "}
                        {this.state.OptimalfrequencyProject==""?null:<>
                        <b style={{ fontFamily: "GothamLight" }}>
                          {this.state.OptimalfrequencyProject}
                        </b>{" "}
                        </>}
                       
                      </h4>
                    </div>
                  </div>
                  {this.state.studenttypename==null?null:<>
                    <div className="row mt-4" style={{paddingLeft:"2%"}}>
                   
                    <div class="col-sm-10">
                      <h4
                        style={{
                          fontSize: 18,
                          fontFamily:"Gotham"
                        }}
                      >
                        Preferred Student Type : 
                      </h4>
                      <div className="row">
                            {this.state.studenttypename.split(",")
                      .map((item, i) =>  <div class="col-sm-3" style={{paddingTop:"2%"}}>  
                     <i className="fa fa-circle" style={{color:"rgb(0,147,69)"}}>
                      <span style={{color:"black",fontFamily:"GothamLight",fontWeight:"bold"}}>&nbsp;&nbsp;{item}</span> 
                      </i>
                      </div>)}  

                      </div>
                    

                    
                    </div>
                  </div>
                  
                  </>}


                  {this.state.SubjectID==0?null:<>
                    <div className="row mt-4" style={{paddingLeft:"2%"}}>
                   
                    <div class="col-sm-10">
                      <h4
                        style={{
                          fontSize: 18,
                          fontFamily:"Gotham"
                        }}
                      >
                        Eligible Subject and Degrees :
                       
                      </h4>
                      <div style={{paddingTop:"1%"}}>
                    
                       {this.createElements()}
                                  </div>
                            
                    </div>
                  </div>
                  
                  </>}


                  <div className="row mt-5" style={{paddingLeft:"2%"}}>
                    
                    <div class="col-sm-10">
                      <h4
                        style={{
                          fontSize: 18,
                          textDecoration: "underline",
                          fontWeight: "bold",
                        }}
                      >
                        Tell us More about the Project:
                      </h4>
                      {this.state.projdescription==""?null
                    :<>
                      <div
                        style={{
                          fontFamily: "GothamLight",
                          fontWeight: "bold",
                        }}
                      >
                       {this.state.projdescription}
                      </div>
                    
                    </>  
                    }
                    
                    </div>
                  </div>
                  <div className="row mt-3" style={{paddingLeft:"2%"}}>
                    
                    <div class="col-sm-10">
                      <h4
                        style={{
                          fontSize: 18,
                          textDecoration: "underline",
                          fontWeight: "bold",
                        }}
                      >
                        Skills Required:
                      </h4>
                      <div class="row mt-3">
                    
                             {this.state.skillname.split(",")
     .map((item, i) =>  <div class="col-sm-3">  
     <button class="sk_btn">{item}</button>
     </div>)}  

                
                      </div>
                    </div>
                  </div>
                  <div className="row mt-4" style={{paddingLeft:"2%"}}>
                    
                    <div class="col-sm-7">
                      <h4 style={{ fontSize: 18, fontWeight: "bold" }}>
                        Mastered Language :{" "}
                        <div className="row" style={{marginTop:"2%"}}>
                        {this.state.languagename.split(",")
                        .map((item, i) =>  <div class="col-sm-2">  
                        <button class="sk_btn">{item}</button>
                        </div>)} 

                        </div>
                         

                      </h4>
                    </div>
                    <div class="col-sm-1"></div>
                  </div>
                  <div className="row mt-4" style={{paddingLeft:"2%"}}>
                    
                    <div class="col-sm-7">
                      <h4 style={{ fontSize: 18, fontWeight: "bold" }}>
                        Open to all intrsted candidates :{" "}

                        <b style={{ fontFamily: "GothamLight" }}>
                          &nbsp;&nbsp; {this.state.opentoallintrested==""?null:<>{this.state.opentoallintrested}</>}
                        </b>{" "}
                      </h4>
                    </div>
                    <div class="col-sm-1"></div>
                  </div>
                  <div className="row mt-4" style={{paddingLeft:"2%"}}>
               
                    <div class="col-sm-4 mt-1">
                      <h4 style={{ fontSize: 18,fontFamily:"Gotham" }}>
                        Attachment of Additional Material Shown : &nbsp;&nbsp;{" "}
                        
                      </h4>
                    </div>
                    <div class="col-sm-3"><input
                          type="text"
                          disabled
                          className="form-control"
                          placeholder={this.state.addDocuments}
                          id="stepone"
                        /></div>
                  </div>
                  <div className="row mt-5">
                    <div class="col-sm-6"></div>
                    <div class="col-sm-6">
                      <Link to="/home" class="btn btn-success" id="clo_btn"
                      onClick={this.handleClose}
                      >Close</Link>
                    </div>
                  </div>
                  <div className="row mt-5"></div>
                </div>
                <div class="col-sm-1"></div>
              </div>
            </div>
            <div className="row mt-5"></div>
          </div>
        </main>
      </div>
    );
  }
}

export default ProjectSummaryStudent;
