import React from "react";
import Logo from "../../images/logo.png";
import Login1 from "../../images/Login-28.png";
import Login2 from "../../images/Login-29.png";
import Login3 from "../../images/Login-30.png";
import Login4 from "../../images/Login-31.png";
import Login5 from "../../images/Login-36.png";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
class Reset extends React.Component {
  constructor() {
    super();

    this.state = {
    email:"",
    emailerror:""
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleSubmit(event) {
    event.preventDefault();
    var emailregx="^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$"
    if (this.state.email === "") {
      this.setState({ emailerror: "Please Enter a Email ID " });
    } else if (this.state.email.match(emailregx)) {
      const data = {
        
        email: this.state.email,
       
      };

      let BaseUrl = JSON.parse(localStorage.getItem("baseurl"));
      fetch(BaseUrl + "resetpassword", {
        method: "POST",

        body: JSON.stringify(data), // data can be `string` or {object}!

        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())

        .catch((error) => console.error("Error:", error))

        .then((response) => {
          console.log(response);
          this.setState({emailerror:response.message})
          //window.location.href=`./changepassword`
         
        });
    }
    else{
      this.setState({emailerror:"Please Enter a Valid Email ID"})
    }
  }
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    const {emailerror}=this.state
    return (
      <div>
        {/* ======= Header ======= */}
        <Header />
        {/* End Header */}
        <main id="main" style={{ background: "#303030", clear: "both" }}>
          <br />
          <br />
          {/* ======= About Section ======= */}
          <section id="clients" className="clients">
            <div className="container" data-aos="fade-up">
              <style
                type="text/css"
                dangerouslySetInnerHTML={{
                  __html:
                    "\n    .boxxlr{\n     border: 0px solid green;\n    background: #edf8f2;\n    margin-right: 2.5%;\n    margin-bottom: 7%;\n    color: #000;\n    margin-left: 9%;\n    width: 100%;\n    }\n/*.boxxlr:hover {\n    border: 0px solid green;\n    background: green;\n    margin-right: 1%;\n    margin-bottom: 1%;\n     color: #fff;\n}*/\n\n.boxclrtitle {\nfont-size: 12px;font-weight: bold;\n}\n\n.boxclrtitle1 {\nfont-size: 18px;font-weight: bold;\n}\n/*.boxxlr .boxclrtitle:hover {\nfont-size: 12px;font-weight: bold;\n}*/\n\n\n  ",
                }}
              />
              <div className="row no-gutters">
                <div className="content col-xl-12 d-flex align-items-center">
                  <div className="content col-xl-12 d-flex align-items-center">
                    <div
                      className="row forgotpassbox"
                      style={{
                        display: "flex",
                        justifyContent: "center" /* alignItems: 'center', */,
                        background: "#fff",
                    
                        borderRadius: "20px",
                        margin: "auto",
                      }}
                    >
                      {/*     <h2 style="font-size: 20px;letter-spacing: 6px;text-align: center;color: #000;
    font-weight: 600; color: #fff;background: green; padding: 1%; width: 70%;margin: 6%;"> Create an Account </h2> */}
                      <div className="row h-100 row align-items-center">
                        <div className="col-md-10" style={{ margin: "auto" }}>
                          <form onSubmit={this.handleSubmit}>
                            <img
                              src={Login5}
                              style={{
                                marginLeft: "40%",
                                marginBottom: "2%",
                                marginTop: "5%",
                              }}
                            />
                            <h3
                              style={{
                                textAlign: "center",
                                fontFamily: "Gotham",
                                marginTop: "3%",
                              }}
                            >
                              Reset <u id="c_text">Your</u> Password
                            </h3>
                            <h6
                              style={{
                                fontSize: "16px",
                                marginTop: "3%",
                                textAlign: "center",
                                fontFamily: "GothamLight",
                                fontWeight: "bold",
                              }}
                            >
                              Please enter your email and we will send you a
                              link
                            </h6>
                            <div
                              className="form-group"
                              style={{
                                paddingBottom: "2%",
                                borderRadius: "1px",
                              }}
                            >
                              <input
                                style={{ padding: "2%", marginTop: "5%" }}
                                type="email"
                                className="form-control"
                                placeholder="Email Address"
                                id="email"
                                name="email"
                                onChange={this.handleChange}
                              />
                              
                            </div>
                            <span style={{fontWeight:"bold",color:"red"}}>{emailerror}</span>
                            <div
                              className="row"
                              style={{
                                marginRight: "-14% !important",
                                marginBottom: "20%",
                              }}
                            >
                              <div
                                className="col-md-5"
                                style={{ marginRight: "15%" }}
                              >
                               
                                <div className="wpcf7-form-control-wrap">
                                  <div
                                    data-sitekey="6LcHSOkUAAAAADTReP3s76AGpAWjC2f3wrZ43936"
                                    className="wpcf7-form-control g-recaptcha wpcf7-recaptcha"
                                  >
                                    <div>
                                      <div style={{ marginTop: "13%" }}>
                                        <ReCAPTCHA
                                          sitekey="6Lfh5w8dAAAAABDHJ3WNMwUN_C_Y7yIHyLf8xg4D"
                                          style={{ height: "10px" }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-5">
                                <button
                                  className="resetbtn"
                                  type="submit"
                                 
                                >
                                  Reset Password
                                </button>
                              </div>
                              <Link
                                to="/"
                                style={{
                                  fontSize: "15px",
                                  textAlign: "center",
                                  color: "rgb(0,147,69)",
                                  marginTop: "12%",
                                  cursor: "pointer",
                                }}
                                id="c_text"
                              >
                                Return to the Homepage
                              </Link>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        {/* End #main */}
        {/* ======= Footer ======= */}
        <Footer />
      </div>
    );
  }
}
export default Reset;
