import React, { Component } from "react";
import "./terms.css";
import Logo from "../../images/logo.png";
import { Link } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
class TermsandCondition extends Component {
  render() {
    return (
      <div>
       <Header />
        <main
          id="main"
          style={{ background: "#303030", clear: "both", height: "175px" }}
          className="tandcbox"
        >
          <section id="clients">
            <h3
              style={{
                color: "#fff",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: "2.5%",
              }}
              className="termsboxheading"
            >
             GigeconoMe Beta Testing Agreement Terms & Conditions
            </h3>
          </section>
        </main>
        <main
          id="main"
          id="main_one"
        >
          <br />
          <br />
          {/* ======= About Section ======= */}
          <section id="clients" className="mscreentandcbox">
            <div
              className="container"
              data-aos="fade-up"
              style={{ marginTop: "-4%" }}
            >
              <style
                type="text/css"
                dangerouslySetInnerHTML={{
                  __html:
                    "\n    .boxxlr{\n     border: 0px solid green;\n    background: #edf8f2;\n    margin-right: 2.5%;\n    margin-bottom: 7%;\n    color: #000;\n    margin-left: 9%;\n    width: 100%;\n    }\n/*.boxxlr:hover {\n    border: 0px solid green;\n    background: green;\n    margin-right: 1%;\n    margin-bottom: 1%;\n     color: #fff;\n}*/\n\n.boxclrtitle {\nfont-size: 12px;font-weight: bold;\n}\n\n.boxclrtitle1 {\nfont-size: 18px;font-weight: bold;\n}\n/*.boxxlr .boxclrtitle:hover {\nfont-size: 12px;font-weight: bold;\n}*/\n\n\n  ",
                }}
              />
              <div className="row no-gutters">
                <div className="content col-xl-12 d-flex align-items-center">
                  <div className="content col-xl-12 d-flex align-items-center">
                    <div
                      className="row"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        background: "#fff",
                        margin: "auto",
                        padding: "5%",
                      }}
                    >
                      <p />
                      <h3 className="termsmainheading" style={{ marginLeft: "-2%",textAlign:"center",fontFamily:"Gotham",fontWeight:"bold" }}>
                      GigeconoMe Beta Testing Agreement Terms & Conditions
                      </h3>
                      <span className="termsheading">
                      This Beta Testing Licence Agreement is a legal agreement (hereinafter “Agreement”)
between XXXXXXXXXXXXXXXX  (“GigeconoMe”) and you, either an individual or a single entity,
(hereinafter “Beta User”). This Agreement covers the GigeconoMe Platform, the associated media, any printed materials, data, files and information and any “online” or electronic documentation (“Platform”) which accompanies it, and which has been provided to Beta User.

                     </span>
                     <br/> <br/>
                     <span className="cookieheading" style={{padding:"1%",color:"rgb(0,147,69)",fontFamily:"GothamBold",fontSize:18}}>I.	Scope of Agreement</span>
                     <span className="termsescription">Beta Users is being granted rights under this Agreement for the purpose of testing and providing input and other Feedback to GigeconoMe regarding one or more of GigeconoMe’s platform, interfaces, services, features and/or functionalities. This Agreement covers all Alpha and Beta Products made available to Beta Users by GigeconoMe, including, without limitation, any Beta Products specifically identified by GigeconoMe as being in “Beta” or any similar stage of development. GigeconoMe retains sole and absolute discretion as to what, if any, Beta Products will be made available to Beta Users during the Term. While Beta Users is not required to utilize or enable any Beta Products, if Beta Users elects to do so, then Beta Users’ use of and access to any such Beta Products shall be subject to the terms of this Agreement.</span>
                     <br/> <br/>
                     <span className="cookieheading" style={{padding:"1%",color:"rgb(0,147,69)",fontFamily:"GothamBold",fontSize:18}}>II.	Payment Fees. </span>
                     <span className="termsescription">GigeconoMe reserves the right to charge fees for use and access to the Beta Products or may provide the Beta Products for no charge. GigeconoMe reserves the right to start charging or revise fee amounts at any time, at its sole discretion, under this Agreement or after the Beta Products are made widely available and incorporated into a GigeconoMe service.</span>
                     <br/> <br/>
                     <span className="cookieheading" style={{padding:"1%",color:"rgb(0,147,69)",fontFamily:"GothamBold",fontSize:18}}>III.	Limited Use Rights</span>
                     <span className="termsescription">For the term of the Agreement, GigeconoMe grants to Beta Users a limited, non-transferable, non-exclusive, revocable right to access and use the Beta Product(s) in connection with an Existing Account to test its functionality and provide Feedback to GigeconoMe. GigeconoMe hosts and retains physical control over the Beta Product(s) and only makes the Beta Product(s) available for access and use by Beta Users over the Internet through a Web-browser or mobile device. Nothing in this Agreement obligates GigeconoMe to deliver or make available any copies of computer programs or code from the Beta Product(s) to Users, whether in object code or source code form. GigeconoMe reserves the right, in its sole discretion, to revoke access and use of the Beta Products at any time. Further, GigeconoMe reserves the right to enter (“assume into”) the Existing Account as needed to (i) monitor or measure use of the Beta Product(s); (ii) validate certain features or functionality of the Beta Product(s); and (iii) to provide services or support necessary to maintain the Beta Product(s).</span>
                     <br/> <br/>
                     <span className="cookieheading" style={{padding:"1%",color:"rgb(0,147,69)",fontFamily:"GothamBold",fontSize:18}}>IV.	Use Restrictions. </span>
                     <span className="termsescription">Users may not use the Beta Product(s) as the basis for developing a competitive solution (or contract with a third party to do so), or remove or alter any of the logos, trademark, patent or copyright notices, confidentiality or proprietary legends or other notices or markings that are on or in the Beta Product(s).</span>
                     <br/> <br/>
                     <span className="cookieheading" style={{padding:"1%",color:"rgb(0,147,69)",fontFamily:"GothamBold",fontSize:18}}>V.	Feedback. </span>
                     <span className="termsescription">Upon reasonable request by GigeconoMe, Beta Users agrees to provide suggestions, enhancement requests, and recommendations (individually and collectively, “Feedback”) regarding the Beta Product(s). Feedback shall include informing GigeconoMe about the performance, ease of use, features that may be missing, and any bugs encountered during the use of the Beta Product(s). GigeconoMe may contact Beta Users and Beta Users agrees to make available a reasonable amount of time to discuss the Beta Product(s) with GigeconoMe if so requested. GigeconoMe may without restriction or fee use, modify and incorporate this Feedback into the Beta Product(s) and other GigeconoMe products and/or services without any restriction and without any payment.</span>
                     <br/> <br/>
                     <span className="cookieheading" style={{padding:"1%",color:"rgb(0,147,69)",fontFamily:"GothamBold",fontSize:18}}>VI.	Intellectual Property.  </span>
                     <span className="termsescription">The parties acknowledge that this Agreement does not transfer any right, title or interest in any intellectual property right to the other. GigeconoMe maintains all rights, title and interest in and to all its patents, inventions, copyrights, trademarks, domain names, trade secrets, know-how and any other intellectual property and/or proprietary rights (collectively, “Intellectual Property Rights”). The limited rights granted to Users to access and use the Beta Product(s) under this Agreement do not convey any additional rights in the Beta Product(s), or in or to any Intellectual Property Rights associated therewith. Subject only to the limited rights to access and use the Beta Product(s) as expressly provided herein, all rights, title and interest in and to the Beta Product(s) and all hardware, software and other components of or used to provide the Beta Product(s), including all related Intellectual Property Rights, will remain with and belong exclusively to GigeconoMe.</span>
                     <br/> <br/>
                     <span className="cookieheading" style={{padding:"1%",color:"rgb(0,147,69)",fontFamily:"GothamBold",fontSize:18}}>VII.	Term of Agreement and Renewal.  </span>
                     <span className="termsescription">This Agreement shall commence upon the Beta Testing Start Date and shall terminate on expiration of the Beta Testing Period. Upon expiration, this Agreement shall automatically renew for subsequent one (1) month periods until terminated by either party (each a “Renewal Term” and, collectively with the Initial Term, the “Term”). </span>
                     <br/> <br/>
                     <span className="cookieheading" style={{padding:"1%",color:"rgb(0,147,69)",fontFamily:"GothamBold",fontSize:18}}>VIII.	Termination and Expiration.   </span>
                     <span className="termsescription">Either party may terminate this Agreement at any time, for any or no reason, and at either party’s convenience by providing written notice to the other. Upon termination of this Agreement, GigeconoMe will notify its Users that their access to the Beta Product(s) has terminated, and GigeconoMe may withhold, remove or discard any content, data, or other information that Users post or upload into GigeconoMe’s system while using the Beta Product(s). Following termination or expiration of this Agreement, GigeconoMe is not obligated to store, maintain or provide a copy of any content, data or other information that Users made available or provided when using the Beta Product(s). </span>
                    <p />
                     <span className="termsescription">In the event a User violates the TOU, GigeconoMe may suspend and / or terminate Users’ access to the Beta Product(s). </span>
                     <br/> 
                     <span className="cookieheading" style={{padding:"1%",color:"rgb(0,147,69)",fontFamily:"GothamBold",fontSize:18}}>IX.	Suspension of Services.   </span>
                     <span className="termsescription">GigeconoMe may immediately suspend Users or one of its User’s accesses to and use of the Beta Product(s) if Users or one of its Users is in breach of Section 9, provided that the suspension will continue only for as long as reasonably necessary for Users to remedy the breach.</span>
                     <br/> <br/>
                     <span className="cookieheading" style={{padding:"1%",color:"rgb(0,147,69)",fontFamily:"GothamBold",fontSize:18}}>X.	Confidential Information.    </span>
                     <span className="termsescription">Users acknowledges and agrees that participation in the Beta testing under this Agreement will result in GigeconoMe disclosing certain confidential, proprietary information related to the Beta Products and/or GigeconoMe (the “Confidential Information”). Such Confidential Information includes, without limitation, the features, functionality and existence of the Beta Product(s), and any know how, trade secrets, computer programs, source code, flowcharts, diagrams, manuals, schematics, development tools, specifications, design documents, marketing information, financial information, business plans or reports made available to Users. Users agrees that it will not, without the express prior written consent of GigeconoMe, disclose any Confidential Information or any part thereof to any third party, except to the extent that such Confidential Information (a) is or becomes generally available to the public through any means other than as a result of any act or omission by Users; (b) is rightfully received by Users from a third party that is not subject to any obligation of confidentiality with respect thereto and without limitation as to its use; or (c) is independently developed by Users without any reliance on any Confidential Information. At the termination of this Agreement or at any time by request of GigeconoMe, Users will return all Confidential Information in its possession to GigeconoMe and further agrees that it will not duplicate, translate, modify, copy, print, disassemble, decompile or otherwise tamper with the Beta Product(s) or any Confidential Information.</span>
                     <br/> <br/>
                     <span className="cookieheading" style={{padding:"1%",color:"rgb(0,147,69)",fontFamily:"GothamBold",fontSize:18}}>XI.	Third Party Tool Integrations.     </span>
                     <span className="termsescription">If applicable, one or more Beta Products may integrate with third party services. Beta Users hereby consents to the sharing of the information in the Beta Products with these third party services and certifies that it has any and all required consents for doing so.</span>
                     <br/> <br/>
                     <span className="cookieheading" style={{padding:"1%",color:"rgb(0,147,69)",fontFamily:"GothamBold",fontSize:18}}>XII.	Disclaimer of Warranties.     </span>
                     <span className="termsescription">THE BETA PRODUCT(S) ARE PROVIDED “AS IS”. GIGECONOME MAKES NO REPRESENTATIONS OR WARRANTIES, EXPRESS OR IMPLIED, REGARDING THE BETA PRODUCT(S), INCLUDING ANY REPRESENTATION THAT THE SERVICES THEREUNDER WILL BE UNINTERRUPTED OR ERROR-FREE. TO THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW, GIGECONOME DISCLAIMS ANY IMPLIED OR STATUTORY WARRANTY, INCLUDING ANY IMPLIED WARRANTY OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE IN RESPECT OF THE BETA PRODUCT(S). FOR THE AVOIDANCE OF DOUBT, ALL BETA PRODUCT(S) ARE PRELEASE, ARE EXPECTED TO CONTAIN DEFECTS WHICH MAY BE MATERIAL, AND ARE NOT EXPECTED TO OPERATE AT THE LEVEL OF PERFORMANCE OR COMPATIBILITY OF A FINAL, GENERALLY AVAILABLE PRODUCT OFFERING. BETA PRODUCT(S) MAY NOT OPERATE ACCURATELY AND MAY BE SUBSTANTIALLY MODIFIED PRIOR TO PUBLIC AVAILABILITY OR WITHDRAWN AT ANY TIME. ACCORDINGLY, ACCESS TO AND USE OF THE BETA PRODUCT(S) IS ENTIRELY AT USERS’S OWN RISK. IN NO EVENT SHALL GIGECONOME BE LIABLE FOR ANY DAMAGE WHATSOEVER ARISING OUT OF THE USE OF OR INABILITY TO USE THE BETA PRODUCT(S), EVEN IF GIGECONOME HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. YOU ARE ADVISED TO SAFEGUARD IMPORTANT DATA, TO USE CAUTION AND NOT TO RELY IN ANY WAY ON THE CORRECT FUNCTIONING OR PERFORMANCE OF ANY BETA PRODUCT.</span>
                     <br/> <br/>
                     <span className="cookieheading" style={{padding:"1%",color:"rgb(0,147,69)",fontFamily:"GothamBold",fontSize:18}}>XIII.	Indemnity.      </span>
                     <span className="termsescription">Users agrees to indemnify and hold GigeconoMe, its officers, directors and employees harmless from any losses (including attorneys’ fees) that result from any third-party claims related to Users’ (or its Users) access, use or misuse of the Beta Product(s), or any act or omission by Users or its Users in violation of this Agreement.</span>
                     <br/> <br/>
                     <span className="cookieheading" style={{padding:"1%",color:"rgb(0,147,69)",fontFamily:"GothamBold",fontSize:18}}>XIV.	Compliance with Laws and Legal Advice.       </span>
                     <span className="termsescription">Each party must comply with all laws, rule or regulations applicable to such party’s activities in relation to this Agreement, including export control laws of the United Kingdom which are applicable to the Application and which may prohibit use of the Application in certain sanctioned or embargoed countries. GigeconoMe will not provide Users with any legal advice regarding compliance with data privacy or other relevant laws, rules or regulations in the jurisdictions in which Users uses the Beta Product(s) (“Laws”). The parties acknowledge and agree that not all features, functions and capabilities of the Beta Product(s)may be used in all jurisdictions and Users recognizes that certain features, functions and capabilities may need to be configured differently or not used in certain jurisdictions in order to comply with applicable local Laws, and in certain jurisdictions consents may need to be obtained from individuals submitting data via the Beta Product(s) as to the intended purpose, storage, distribution, access and use of the data submitted (“Local Use Decisions”). Users is responsible for Local Use Decisions and GigeconoMe disclaims all liability for Local Use Decisions. In no event may this agreement be governed by the United Nations convention on contracts for the international sale of goods.</span>
                     <br/> <br/>
                     <span className="cookieheading" style={{padding:"1%",color:"rgb(0,147,69)",fontFamily:"GothamBold",fontSize:18}}>XV.	Miscellaneous.        </span>
                     <span className="termsescription">Each party must comply with all laws, rule or regulations applicable to such party’s activities in relation to this Agreement, including export control laws of the United Kingdom which are applicable to the Application and which may prohibit use of the Application in certain sanctioned or embargoed countries. GigeconoMe will not provide Users with any legal advice regarding compliance with data privacy or other relevant laws, rules or regulations in the jurisdictions in which Users uses the Beta Product(s) (“Laws”). The parties acknowledge and agree that not all features, functions and capabilities of the Beta Product(s)may be used in all jurisdictions and Users recognizes that certain features, functions and capabilities may need to be configured differently or not used in certain jurisdictions in order to comply with applicable local Laws, and in certain jurisdictions consents may need to be obtained from individuals submitting data via the Beta Product(s) as to the intended purpose, storage, distribution, access and use of the data submitted (“Local Use Decisions”). Users is responsible for Local Use Decisions and GigeconoMe disclaims all liability for Local Use Decisions. In no event may this agreement be governed by the United Nations convention on contracts for the international sale of goods.</span>
                   <p/>   <p/>
                    
                     <span className="termsescription">Failure of GigeconoMe to enforce a right under this Agreement shall not act as a waiver of that right or the ability to later assert that right relative to the particular situation involved. This Agreement includes any schedules and exhibits attached hereto. Such documents encompass the entire agreement between Users and GigeconoMe with respect to the subject matter hereof and supersede all prior representations, agreements and understandings, written or oral. This Agreement may only be altered, amended or modified by duly executed written instrument.</span>
                     <p/>   <p/>
                     <span className="termsescription"> All notices to be provided by GigeconoMe to Users under this Agreement may be delivered in writing (a) electronic mail to the electronic mail address provided for Users’ Existing Account owner. All notices shall be deemed to have been given immediately upon delivery by electronic mail. You may not assign this Agreement without the prior written consent of GigeconoMe. Subject to the foregoing, the Agreement shall be binding upon the parties and their respective administrators, successors and assigns</span>
                     <br/> <br/>
                     <span className="termsescription"> If any provision of this Agreement shall be found by a court to be void, invalid or unenforceable, the same shall be reformed to comply with applicable law or stricken if not so conformable, so as not to affect the validity or enforceability of this Agreement.</span>
                      <p/>   <p/>
                      <span className="termsescription"> GigeconoMe reserves the right to update these terms and conditions without prior notice. You agree that we may notify you of the Updated Terms by posting them on our website, and that your use of the platform after the effective date of the Updated Terms constitutes your agreement to the Updated Terms. Therefore, you should review these Terms of Use and any Updated Terms before using the Platform. The Updated Terms will be effective as of the time of posting, or such later date as may be specified in the Updated Terms, and will apply to your use of the Platform from that point forward. These Terms of Use will govern any disputes arising before the effective date of the Updated Terms.</span>
                      <br/> <br/>
                     <span className="cookieheading" style={{padding:"1%",color:"rgb(0,147,69)",fontFamily:"GothamBold",fontSize:18}}>Contact Information    </span>
                     <span className="termsescription">Should you have any questions concerning this Agreement, please email 
                      &nbsp; <a href="mailto:someone@example.com">admin@gigeconome.com</a>
                    </span>
                      <p />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        {/* End #main */}
        {/* ======= Footer ======= */}
        <Footer />
      </div>
    );
  }
}
export default TermsandCondition;
