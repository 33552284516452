// import React from "react";
// import './Header.css';
// import Logo from "../../images/logo.png";
// import { Link } from "react-router-dom";
//  class Header extends React.Component{
//     render(){
//         return(
//             <div>
//      {/* ======= Header ======= */}
//       <header id="header" className="fixed-top d-flex align-items-center">
//         <div className="container d-flex align-items-center">
//           <h1 className="logo me-auto">
//             <Link to="/">
//               <img src={Logo} />
//             </Link>
//           </h1>

//           {/* Uncomment below if you prefer to use an image logo */}
//           {/* <a href="index.html" class="logo me-auto"><img src="assets/img/logo.png" alt=""></a>*/}
//           <nav id="navbar" className="navbar order-last order-lg-0">
//             <ul>
//               <li>
//                 <Link to="/aboutus" className="nav-link scrollto">
//                   About Us
//                 </Link>
//               </li>
//               <li className="dropdown">
//                 <a href="#">
//                   <span>Students</span> <i className="bi bi-chevron-down" />
//                 </a>
//                 <ul>
//                   <li>
//                     {" "}
//                     <Link style={{ color: "#009345" }} to="/studentHowitswork">
//                       How it Works
//                     </Link>
//                   </li>
//                 </ul>
//               </li>
//               <li className="dropdown">
//                 <a href="#">
//                   <span>Companies</span> <i className="bi bi-chevron-down" />
//                 </a>
//                 <ul>
//                   <li>
//                     {" "}
//                     <Link to="/company" style={{ color: "#009345" }}>
//                       How it Works
//                     </Link>
//                   </li>
//                 </ul>
//               </li>
//               <li className="dropdown">
//                 <a href="#">
//                   <span>Universities</span> <i className="bi bi-chevron-down" />
//                 </a>
//                 <ul>
//                   <li>
//                     {" "}
//                     <Link to="/universites" style={{ color: "#009345" }}>
//                       How it Works
//                     </Link>
//                   </li>
//                 </ul>
//               </li>
//               <li className="dropdown">
//                 <a href="#">
//                   <span>Startups</span> <i className="bi bi-chevron-down" />
//                 </a>
//                 <ul>
//                   <li>
//                     {" "}
//                     <Link style={{ color: "#009345" }} to="/company">
//                       How it Works
//                     </Link>
//                   </li>
//                 </ul>
//               </li>
//             </ul>
//             <i className="bi bi-list mobile-nav-toggle" />
//           </nav>
//           {/* .navbar */}
//           <a
//             href="#about"
//             className="get-started-btn scrollto"
//             style={{
//               /* padding: '8px 25px', */
//               /* height: '7px', */
//               marginLeft: "30px",
//               background: "#009345",
//               color: "#fff",
//               borderRadius: "4px",
//               padding: "5px 25px",
//               whiteSpace: "nowrap",
//               transition: "0.3s",
//               fontSize: "14px",
//               fontWeight: 600,
//               display: "inline-block",
//               cursor: "pointer",
//             }}
//           >
//             Post a Project
//           </a>
//           <Link
//             to="/signup"
//             className="get-started-btn scrollto"
//             style={{
//               /* padding: '8px 25px', */
//               /* height: '7px', */
//               marginLeft: "30px",
//               background: "gray",
//               color: "#fff",
//               borderRadius: "4px",
//               padding: "5px 25px",
//               whiteSpace: "nowrap",
//               transition: "0.3s",
//               fontSize: "14px",
//               fontWeight: 600,
//               display: "inline-block",
//               cursor: "pointer",
//             }}
//           >
//             Sign Up
//           </Link>
//           <Link
//             to="/signin"
//             className="get-started-btn scrollto"
//             style={{
//               /* padding: '8px 25px', */
//               /* height: '7px', */
//               marginLeft: "30px",
//               background: "#009345",
//               color: "#fff",
//               borderRadius: "4px",
//               padding: "5px 25px",
//               whiteSpace: "nowrap",
//               transition: "0.3s",
//               fontSize: "14px",
//               fontWeight: 600,
//               display: "inline-block",
//               cursor: "pointer",
//             }}
//           >
//             Sign In
//           </Link>
//           {/*<span style="padding-left: 2%;*/}
//           {/*    color: green;*/}
//           {/*    font-size: 28px;">*/}
//           {/* <i class="fa fa-user-circle-o" aria-hidden="true"></i></span>*/}
//         </div>
//       </header>
//       {/* End Header */}
//             </div>
//         )
//     }
// }
// export default Header;

import React, { useState } from "react";
// import './Header.css';
import Logo from "../../images/logo.png";
import Button101 from "../../images/button101.png";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Dropdown from "react-bootstrap/Dropdown";
import "./dashboard.css";
function CompanyNav() {
  let history = useHistory();
  function handlePick(){
    sessionStorage.removeItem("nameData");
    window.location.href=`./Signin`
    sessionStorage.removeItem("baseurl");
    sessionStorage.removeItem("someData");  
  
    sessionStorage.removeItem("UserType");  
  

}
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);
  return (
    <div className="my-4" style={{ marginLeft: 0 }}>
      <nav
        id="example"
        className="navbar navbar-expand-xl navbar-light bg-white fixed-top d-flex align-items-center"
      >
        <div className="container d-flex align-items-left">
          <a className="navbar-brand logo me-auto">
            <Link to="/">
              <img src={Logo} />
            </Link>
          </a>
          {/* Uncomment below if you prefer to use an image logo */}
          {/* <a href="index.html" className="logo me-auto"><img src="assets/img/logo.png" alt=""></a>*/}
          {/* <a className="navbar-brand" href="#">Navbar</a> */}
          <button
            className="custom-toggler navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarCollapse7"
            aria-controls="navbarsExample09"
            aria-expanded={!isNavCollapsed ? true : false}
            aria-label="Toggle navigation"
            onClick={handleNavCollapse}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className={`${isNavCollapsed ? "collapse" : ""} navbar-collapse`}
            id="navbarCollapse7"
          >
            <div className="navbar-nav">
              <div
                className="navbar-nav me-auto mb-2 mb-lg-0 cpyheadersectionnew"
              id="company_nav"
              >
                <a className="nav-link scrollto text-white" id="tooltip">
                  <img src={Button101}  />
                  <span id="tooltiptext">
                    <div class="search" style={{ width: "92%" }}>
                      <div
                        type="text"
                        class="searchTerm"
                        placeholder="What are you looking for?"
                      >
                        <b
                          style={{
                            paddingLeft: 10,
                            color: "black",
                            fontFamily: "GothamLight",
                            fontSize: 12,
                            paddingTop: 10,
                          }}
                        >
                          Search
                        </b>
                      </div>
                      <button type="submit" class="searchButton">
                        <i class="fa fa-search"></i>
                      </button>
                    </div>
                    <hr style={{borderBottom:"3px solid rgb(0,147,69)",width:"90%",marginLeft:30}} />
                    <div>
                    &nbsp; &nbsp; &nbsp; &nbsp;   Search by Type
                    </div>
                    <div className="row mt-3">
                    &nbsp; &nbsp; &nbsp; &nbsp;   
                    <div class="col-sm-3" ><span
                            class="fa fa-briefcase"
                            aria-hidden="true"
                            id="success"
                            style={{marginTop:"-19%"}}
                          ></span>&nbsp;
                          <label style={{fontWeight:'bold'}}>
                          <b>Project</b> </label>
                        </div>
                    <div class="col-sm-3" ><span
                            class="fa fa-laptop"
                            aria-hidden="true"
                            id="success"
                            style={{marginTop:"-19%"}}
                          ></span>&nbsp;
                          <label style={{fontWeight:'bold'}} >
                          <b>Project</b></label>
                        </div>
                    <div class="col-sm-3" ><span
                            class="fa fa-envelope"
                            aria-hidden="true"
                            id="success"
                            style={{marginTop:"-19%"}}
                          ></span>&nbsp;
                          <label style={{fontWeight:'bold'}} >
                          <b>Project</b> </label>
                       </div>
                    <div class="col-sm-2" ><span
                            class="fa fa-user"
                            aria-hidden="true"
                            id="success"
                            style={{marginTop:"-19%"}}
                          ></span>&nbsp;
                          <label style={{fontWeight:'bold'}} >
                             <b>Project</b>  </label>
                      </div>
                    </div>
                  </span>
                 
                </a>

                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle text-white"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Students
                  </a>
                </li>

                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle text-white"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Companies
                  </a>
                </li>

               

                <li className="nav-item dropdown">
                  <a
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i
                      class="fa fa-bell fa-3x"
                      style={{ fontSize: 30,marginTop:"77%" }}
                    ></i>
                  </a>
                </li>
              </div>
              <div className="cpyheadersection">
                <Link
                  to="/postaproject"
                  className="get-started-btn scrollto"
                  className="btn mt-2"
                  id="project"
                  style={{
                    /* padding: '8px 25px', */
                    /* height: '7px', */
                    marginLeft: "20px",
                    background: "#009345",
                    color: "#fff",
                    borderRadius: "18px",
                    padding: "5px 15px",
                    whiteSpace: "nowrap",
                    transition: "0.3s",
                    fontSize: "14px",
                    fontWeight: 600,
                    display: "inline-block",
                    cursor: "pointer",
                  }}
                >
                  Post a Project
                </Link>
                <AccountCircleIcon
                  style={{
                    fontSize: 40,
                    color: "rgb(0, 147, 69)",
                    marginLeft: 30,
                  }}
                />
                Hi,<b> {JSON.parse(sessionStorage.getItem("nameData"))}</b>
                <a
                  className="get-started-btn scrollto"
                  className="btn mt-3"
                  style={{
                    /* padding: '8px 25px', */
                    /* height: '7px', */
                    marginLeft: "1px",

                    padding: "5px 10px",
                    whiteSpace: "nowrap",
                    transition: "0.3s",
                    fontSize: "14px",
                    fontWeight: 600,
                    display: "inline-block",
                    cursor: "pointer",
                  }}
                >
                    <div class="dropdown">
            <Dropdown.Toggle variant="success" id="dropdown-basic" style={{marginLeft:"30%"}}>
                ☰
              </Dropdown.Toggle>
          
  <div class="dropdown-content">
    <Link to="/aboutus" style={{color:"rgb(0, 147, 69)",marginTop:10,fontSize:16,borderBottom:"1px solid #3A3939"}}>About Us</Link> 
    <Link to="/company" style={{color:"rgb(0, 147, 69)",paddingTop:10,fontSize:16,borderBottom:"1px solid #3A3939"}}>Students</Link>

    <Link to="/universites" style={{color:"rgb(0, 147, 69)",marginTop:10,fontSize:16,borderBottom:"1px solid #3A3939"}}>Universities</Link>
    <Link to="/company" style={{color:"rgb(0, 147, 69)",marginTop:10,fontSize:16,borderBottom:"1px solid #3A3939"}}>Startups</Link>
    <a onClick={handlePick} style={{color:"rgb(0, 147, 69)",marginTop:10,fontSize:16}}>Sign out</a>
  </div>
</div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}
export default CompanyNav;
