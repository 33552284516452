import React, { useState } from "react";
import Upload from "../../../images/upload.png";
import "./modal.css";

function close(){
    window.location.reload();
  }
export default class CommunityModal extends React.Component{
  
  constructor() {
    super();
    let comments = JSON.parse(sessionStorage.getItem("someData"));
   
   console.log(comments)
    this.state = {
      
    
     
        userid: comments,
        addstudata :"publisher",
       
        dropdownlist_input : "nationality",
       
        selectOptions : [],
        selectSchool:[],
        selectQualification:[],
        title:"",
        publisher:"",
        year:"",
        area:"",
        linktopublish:"",
        synopsis:"",
    
  
  }
  this.handleSubmit = this.handleSubmit.bind(this);
}

  handleSubmit = (event) => {
    event.preventDefault()
   
    const data = {
        userid: this.state.userid,
        addstudata: this.state.addstudata,
        title:this.state.title,
        publisher:this.state.publisher,
        year:this.state.year,
        startdate:this.state.startdate,
        area:this.state.area,
        linktopublish:this.state.linktopublish,
        description:this.state.description,
        synopsis:this.state.synopsis
    }
  
    let BaseUrl = JSON.parse(sessionStorage.getItem("baseurl"));

    fetch( "https://www.gigeconome.com:5200/api/addstu_profiledata", {
      method: "POST",

      body: JSON.stringify(data), // data can be `string` or {object}!

      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())

      .catch((error) => console.error("Error:", error))

      .then((response) => {
         
        this.setState({geomessage:response.message})
        window.location.reload();
       
        
      });
  
}
componentDidMount(){
  this.getOptions()
}
async getOptions(){
const data_one={dropdownlist_input:this.state.dropdownlist_input,

}

let BaseUrl = JSON.parse(sessionStorage.getItem('baseurl'));

fetch('https://www.gigeconome.com:5200/api/getdropdownlist', { method: 'POST', 

 body: JSON.stringify(data_one), // data can be `string` or {object}!

 headers:{ 'Content-Type': 'application/json' } })

 .then(res => res.json())

 .catch(error => console.error('Error:', error))

 .then((json)=>{
  const data = json.result
  
  this.setState({selectOptions: data})
 })
 const data_two={dropdownlist_input:"areaofpublish"

 }

 //let BaseUrl = JSON.parse(sessionStorage.getItem('baseurl'));

 fetch( 'https://www.gigeconome.com:5200/api/getdropdownlist', { method: 'POST', 

  body: JSON.stringify(data_two), // data can be `string` or {object}!

  headers:{ 'Content-Type': 'application/json' } })

  .then(res => res.json())

  .catch(error => console.error('Error:', error))

  .then((json)=>{
   const data = json.result
   
   this.setState({selectSchool: data})
  })




  const qual_data={dropdownlist_input:"qualification"

}

//let BaseUrl = JSON.parse(sessionStorage.getItem('baseurl'));

fetch('https://www.gigeconome.com:5200/api/getdropdownlist', { method: 'POST', 

body: JSON.stringify(qual_data), // data can be `string` or {object}!

headers:{ 'Content-Type': 'application/json' } })

.then(res => res.json())

.catch(error => console.error('Error:', error))

.then((json)=>{
const data = json.result

this.setState({selectQualification: data})
})

}
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
     
    });
    
  };
render(){
const {selectSchool}=this.state;
  const{geomessage}=this.state
  return (
    <>
      <div class="ft-modal" id="ft-community-modal">
        <div class="ft-modal-content" >
          <div class="ft-modal-header">
            <div class="header">
              <h5
                class="modal-title"
                id="exampleModalLabel"
                style={{
                  textAlign: "center",
                  fontSize: 25,
                  fontWeight: "bold",
                }}
              >
                Add Interests / Membership/ Societies
              </h5>
              <hr />
            </div>
          </div>
          <div class="ft-modal-body">
          <div class="row mt-3">
                <div class="col-sm-2"></div>
                <div class="col-sm-8">
                  <div class="row">
                    <div class="col-sm-3">
                      <div class="card" id="intrest_card">
                        <div class="card-body">
                          <img src={Upload} style={{ width: 35, marginTop:"10%",marginLeft:"15%"}} />
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-3" id="card_inner">
                      <div class="card" id="intrest_card">
                        <div class="card-body">
                          <img src={Upload} style={{ width: 35, marginTop:"10%",marginLeft:"15%" }} />
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-3" id="card_inner">
                      <div class="card" id="intrest_card">
                        <div class="card-body">
                          <img src={Upload} style={{ width: 35, marginTop:"10%",marginLeft:"15%" }} />
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-3" id="card_inner">
                      <div class="card" id="intrest_card">
                        <div class="card-body">
                          <img src={Upload} style={{ width: 35, marginTop:"10%",marginLeft:"15%" }} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            <div class="row mt-4">
              <div class="col-sm-5"></div>
              <div class="col-sm-2">
               <button className="btn btn-success" style={{backgroundColor:"rgb(0,147,69)",width:150,marginTop:"10%"}}>Save</button>
              </div>
              <div class="col-sm-2"></div>
            </div>
            <div class="mt-4">
              <div class="col-sm-4"></div>
              <div class="col-sm-7">
               
              </div>
              <div class="col-sm-1"></div>
            </div>
          </div>
          <div class="ft-modal-footer">
            <a class="ft-modal-close" href="#">
              [&#10006;]{" "}
            </a>
          </div>
        </div>
      </div>

      
      <a
          id="add_btn"
          className="btn-success btn-sm mt-2"
          href="#ft-community-modal"
          style={{
            margin:"auto",
            width: "30%",
            backgroundColor: "rgb(0, 147, 69)",
            fontFamily: "GothamLight",
            textAlign: "center",
          }}
        >
          Add
        </a>
    </>
  );
}
}
