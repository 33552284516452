
import React from "react";
import { Link } from "react-router-dom";
import Logo from '../../images/logo.png';
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Select from 'react-select';
const style = {
  control: (base, state) => ({
    ...base,
    border: "1px solid gray",
    borderRadius:"1px",
   
   
    // This line disable the blue border
    boxShadow: state.isFocused ? 0 : 0,
    "&:hover": {
      border: "1px solid gray",
      borderRadius:"1px",
     
    }
  }),
 
};
class Studentsignup_two extends React.Component {
  constructor() {
    super();
    
    
    let comments = JSON.parse(sessionStorage.getItem('someData'));
    this.state = {
      StudyStatus:" ",
      college_University:" ",
      Course:" ",
      QualificationType:"",
      Lengthofcourse:" ",
      GraduationYear:"",
      userid:comments,
      reg_level:2,
      selectGraduation : [],
      id: "",
      name: '',
      dropdownlist_input : "graduationyear",
      DataisLoaded: false,
      error:"",
    selectQualification:[],
    selectUniversity:[],
    selectCourse:[],
    subCourseName:[],
    LocationData:[],
    location:"",
    university:"",
    displayunniversity:0

      };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
 componentDidMount(){
    this.getOptions()
}
async getOptions(){
  if(JSON.parse(sessionStorage.getItem('University'))==undefined ||JSON.parse(sessionStorage.getItem('University'))==null ){
      console.log("error")
      this.setState({displayunniversity:0})
  }
  else{
    var Location=JSON.parse(sessionStorage.getItem('LocationID'))
    this.setState({location:Location})
    var getUniversity=JSON.parse(sessionStorage.getItem('University'))
    this.setState({university:getUniversity})
  }
  const data_one={dropdownlist_input:this.state.dropdownlist_input,
    
  }
  const data_three={dropdownlist_input:"qualification"}

  let BaseUrl = JSON.parse(sessionStorage.getItem('baseurl'));

  fetch('https://www.gigeconome.com:5200/api/getdropdownlist', { method: 'POST', 

   body: JSON.stringify(data_three), // data can be `string` or {object}!

   headers:{ 'Content-Type': 'application/json' } })

   .then(res => res.json())

   .catch(error => console.error('Error:', error))

   .then((json)=>{
    
    
    const data = json.result
    console.log(data)
    
    
    this.setState({selectQualification: data})
    this.setState({
      DataisLoaded: true 
    })
   })
  

   const locationdata={dropdownlist_input:"country"}
   fetch( 'https://www.gigeconome.com:5200/api/getdropdownlist', { method: 'POST', 

   body: JSON.stringify(locationdata), // data can be `string` or {object}!

   headers:{ 'Content-Type': 'application/json' } })

   .then(res => res.json())

   .catch(error => console.error('Error:', error))

   .then((json)=>{
    
    
    const data = json.result
    //console.log(json)
    
  const options = data.map(d => ({
      "value" : d.id,
      "label" : d.name

    })) 
   
    this.setState({LocationData:options})
    
   })
   fetch( 'https://www.gigeconome.com:5200/api/getdropdownlist', { method: 'POST', 

   body: JSON.stringify(data_one), // data can be `string` or {object}!

   headers:{ 'Content-Type': 'application/json' } })

   .then(res => res.json())

   .catch(error => console.error('Error:', error))

   .then((json)=>{
    
    
    const data = json.result
    console.log(data)
    
    
    this.setState({selectGraduation: data})
   
   })

   const data_six={dropdownlist_input:"coursenew"}
   fetch( 'https://www.gigeconome.com:5200/api/getdropdownlist', { method: 'POST', 

   body: JSON.stringify(data_six), // data can be `string` or {object}!

   headers:{ 'Content-Type': 'application/json' } })

   .then(res => res.json())

   .catch(error => console.error('Error:', error))

   .then((json)=>{
    
   let  data=json.result
    const options = data.map(d => ({
      "value" : d.id,
      "label" : d.subcategoryname

    })) 
    
    this.setState({subCourseName:options})
    
    //console.log(data)
  
   })
   fetch( 'https://www.gigeconome.com:5200/api/getdropdowncourseinfo', { method: 'GET', 



   headers:{ 'Content-Type': 'application/json' } })

   .then(res => res.json())

   .catch(error => console.error('Error:', error))

   .then((json)=>{
    
    
    const dataa = json.result
    console.log(dataa)
    this.setState({selectCourse:dataa})
   dataa.map(element=>{
     element.subcategory.map(e=>{
       //console.log(e)
       
     

       
     })
   
   })
   
   })
    

  }
  handleSubmit(event) {
    event.preventDefault();
    if (this.state.StudyStatus === "") {
     alert("Plese Fill !");
    }
    else{
    const data = { StudyStatus:this.state. StudyStatus, college_University:this.state.college_University , 
      Course:this.state.Course, QualificationType:this.state. QualificationType
      ,Lengthofcourse:this.state.Lengthofcourse, GraduationYear:this.state. GraduationYear,
      userid:this.state.userid, reg_level:this.state. reg_level,location:this.state.location }
     console.log(data)
      let BaseUrl = JSON.parse(sessionStorage.getItem('baseurl'));
    fetch(BaseUrl +'stuserinfo', { method: 'POST', 

    body: JSON.stringify(data), // data can be `string` or {object}!

    headers:{ 'Content-Type': 'application/json' } })

    .then(res => res.json())

    .catch(error => console.error('Error:', error))

    .then((response)=>{
        
        this.setState({
          DataisLoaded: true 
        })
          window.location.href=`./Studentsignup_three`
            //this.setState({ data: response.userid });
        console.log(response)
    })
  }
        
    
       
    
   }
  
handleDrop = (e) => {
    
 this.setState({college_University:e.label})

   }

  handleCourse = (e) => {
    
    
    this.setState({Course:e.value})
 
}
handleLocation = (e) => {
    
    
  this.setState({location:e.value},()=>{
    const data_university={dropdownlist_input:"guniversity",locationid :e.value}
    fetch( 'https://www.gigeconome.com:5200/api/getdropdownlist', { method: 'POST', 
 
    body: JSON.stringify(data_university), // data can be `string` or {object}!
 
    headers:{ 'Content-Type': 'application/json' } })
 
    .then(res => res.json())
 
    .catch(error => console.error('Error:', error))
 
    .then((json)=>{
     
     
     const data = json.result
     //console.log(json)
     
   const options = data.map(d => ({
       "value" : d.location_id,
       "label" : d.university
 
     })) 
    
     this.setState({selectUniversity: options})
     
    })
  })

}
 handleChange = (e) => {

this.setState({
     [e.target.name]: e.target.value

    })
  
 }

  render(){
    const {selectGraduation} = this.state;
   const {selectQualification}=this.state
   
  return (
    <div>
    
     
         {/* ======= Header ======= */}
      <Header />



      <div></div>
      {/* End Header */}
        <main id="main" style={{ background: "#303030", clear: "both" }}>
          <br />
          <br />
          {/* ======= About Section ======= */}
          <section id="clients" className="clients">
            <div className="container" data-aos="fade-up">
              <style
                type="text/css"
                dangerouslySetInnerHTML={{
                  __html:
                    "\n    .boxxlr{\n     border: 0px solid green;\n    background: #edf8f2;\n    margin-right: 2.5%;\n    margin-bottom: 7%;\n    color: #000;\n    margin-left: 9%;\n    width: 100%;\n    }\n/*.boxxlr:hover {\n    border: 0px solid green;\n    background: green;\n    margin-right: 1%;\n    margin-bottom: 1%;\n     color: #fff;\n}*/\n\n.boxclrtitle {\nfont-size: 12px;font-weight: bold;\n}\n\n.boxclrtitle1 {\nfont-size: 18px;font-weight: bold;\n}\n/*.boxxlr .boxclrtitle:hover {\nfont-size: 12px;font-weight: bold;\n}*/\n\n\n  ",
                }}
              />
              <div className="row no-gutters">
                <div className="content col-xl-12 d-flex align-items-center">
                  <div className="content col-xl-12 d-flex align-items-center">
                    <div
                      className="row ssignupsteptwo"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        borderRadius: "25px" /* alignItems: 'center', */,
                        background: "#fff",
                        /* marginLeft: '50%', */
                        margin: "auto",
                        
                      }}
                      
                    >
                      <h2
                        className="currenteducationtxt"
                      >
                        {" "}
                        Your Currrent Education{" "}
                      </h2>
                      <h5
                        
                        className="tellustxt"
                      >
                        Please Tell Us about details of current Study
                      </h5>
                      <div className="row h-100 row align-items-center">
                        <div className="col-md-8" style={{ margin: "auto" }}>
                          
                      
                          <form onSubmit={this.handleSubmit}>
                            {/*                 <div class="form-group" style="padding: 2%;border-radius: 1px;">

                <input style="padding: 2%;" type="text" class="form-control" placeholder="Company Name" id="email">
                </div> */}
                            <div
                              className="form-group"
                              style={{ padding: "2%", borderRadius: "1px" }}
                            >
                              <select
                                className="form-select"
                                style={{
                                  width: "100%",
                                  height: "38px",
                                  border: "1px solid gray",
                              
                                }}
                                required
                                onChange={this.handleChange} 
                                id="StudyStatus"
                                name="StudyStatus"
                                
                              >
                                <option value="">Study Status </option>
                                <option value="Undergraduate">Undergraduate</option>
                                <option value="Post Graduate">Post Graduate</option>
                                <option value="Sixth Form (A levels)">Sixth Form (A levels)</option>
                                <option value="BTEC Student">BTEC Student</option>
                                <option value="Specialist Colleges">Specialist Colleges</option>
                                <option value="Adult Education Institutes">Adult Education Institutes</option>
                                <option  value="NVQ or SVQ Student">NVQ or SVQ Student</option>
                                <option  value="HND (or Equivalent)">HND (or Equivalent)</option>
                                <option  value="HNC (or Equivalent)">HNC (or Equivalent)</option>
                                <option  value="Diploma">Diploma</option>
                                <option  value="Other">Other</option>
                                
                              </select>
                            </div>
                            <div
                              className="form-group"
                              style={{ padding: "2%", borderRadius: "1px" }}
                            >


<Select options={this.state.LocationData}
                              components={{  IndicatorSeparator:() => null }}
                              styles={style} placeholder="Select Your Location" onChange={this.handleLocation}   /> 



                              {/* <Select options={subCourseName}
                              components={{  IndicatorSeparator:() => null }}
                              styles={style} placeholder="Select Courses" onChange={this.handleCourse}   /> */}

                                    
                            </div>
                            <div
                              className="form-group"
                              style={{ padding: "2%", borderRadius: "1px" ,borderRadius:"2px" }}
                            >



                     <Select options={this.state.selectUniversity}
                              components={{  IndicatorSeparator:() => null }}
                              styles={style} placeholder="Select University" onChange={this.handleDrop}   /> 
                              
                              
                   
                            </div>
                            <div
                              className="form-group"
                              style={{ padding: "2%", borderRadius: "1px" }}
                            >


<Select options={this.state.subCourseName}
                              components={{  IndicatorSeparator:() => null }}
                              styles={style} placeholder="Select Course Name" onChange={this.handleCourse}   /> 



                              {/* <Select options={subCourseName}
                              components={{  IndicatorSeparator:() => null }}
                              styles={style} placeholder="Select Courses" onChange={this.handleCourse}   /> */}

                                    
                            </div>
                            <div
                              className="form-group"
                              style={{ padding: "2%", borderRadius: "1px" }}
                            >
                              {selectQualification.length == 0
                    ? "Loading Qualification Name..."
                    : 

                    <select class="form-select" style={{border:"1px solid gray"}}  name ="QualificationType" 
                    onChange = {this.handleChange}>
                      <option>Select Qualification</option>
                    {selectQualification.map(fbb =>
                    
                      <option key={fbb.key} value={fbb.name}>{fbb.name}</option>
                      
                    )};
                  </select>
                    } 
                            </div>
                            <div
                              className="form-group"
                              style={{ padding: "2%", borderRadius: "1px" }}
                            >
                              <select
                                className="form-select"
                                style={{
                                  width: "100%",
                                  height: "38px",
                                  border: "1px solid gray",
                                }}
                                required
                                onChange={this.handleChange} 
                                id="Lengthofcourse"
                                name="Lengthofcourse"
                              >
                                <option value="">Length of Course </option>
                                <option value="1 year">1 year</option>
                                <option value="2 year">2 year</option>
                                <option value="3 year">3 year</option>
                                <option value="4 year">4 year</option>
                                <option value="5 year">5 year</option>
                                <option value="6 year">6 year</option>
                              </select>
                            </div>
                            <div
                              className="form-group"
                              style={{ padding: "2%", borderRadius: "1px", }}
                            >
                               {selectGraduation.length == 0
                    ? "Loading Graduation Name..."
                    : 

                    <select class="form-select" style={{border:"1px solid gray"}}  name ="GraduationYear" 
                    onChange = {this.handleChange}>
                      <option>Select Graduation Year</option>
                    {selectGraduation.map(fbb =>
                    
                      <option key={fbb.key} value={fbb.name}>{fbb.name}</option>
                      
                    )};
                  </select>
                    } 
                    
                            </div>
                          
                            <input type="hidden" 
                               onChange={this.handleChange} 
                               id="userid"
                               name="userid"/>
                                <input type="hidden" 
                               onChange={this.handleChange} 
                               id=" reg_level"
                               name=" reg_level"/>
                            <div
                              className="container"
                              style={{ marginBottom: "5%" }}
                            >
                              <Link
                                style={{ color: "#fff" }}
                                to='/studentsignup_one'
                              
                                  className="btn btn-primary studentbackbtn"
                                
                                >
                                  {" "}
                                  Back
                               {" "}
                              </Link>
                              
                                <button
                                  className="btn btn-primary"
                                  style={{
                                    
                                    marginLeft: "8%",
                                    marginTop: "6%",
                                    color: "#fff",
                                    background: "rgb(0, 147, 69)",
                                    width: "44%",
                                    border: "none",
                                    borderRadius:
                                      "1px" /* marginTop: '-13%', */,
                                    height: "40px",
                                    
                                    marginBottom: "5%",
                                  }}
                                >
                                  Next{" "}
                               {" "}
                              </button>
                            </div>
                          </form>
                          {/* <div style="float: left;width: 45%; margin-right: 3%;"><hr/></div>
<div style="float:right; width: 44%;"><hr/></div>
Or */}
                          {/* <h6 style="text-align: center;">With Your Social Network</h6>

<div style="margin-top: 4%;margin-bottom: 10%">
  <span class="btn btn-primary" style="background: #fff;
    border: 1px solid gray;border-radius: 1px; font-size: 22px; letter-spacing: 1px;
    margin-right: 4%;  width: 40%;padding: 0%;margin-left: 10%"> <img src="assets/img/google.png"> </span>
    <span class="btn btn-primary" style="background: #fff;
    border: 1px solid gray;border-radius: 1px; font-size: 22px; letter-spacing: 1px;width: 40%;padding: 0%;"><img src="assets/img/linkedin.png"></span> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="container"></div>
        </main>
        {/* End #main */}
        {/* ======= Footer ======= */}
       <Footer />
      </div>
   
  );
};
}

export default Studentsignup_two;
