import React from "react";
import { Link } from "react-router-dom";
import "./company.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import LoadingIcon from '../../images/loading2.gif';
import Select from 'react-select';
const style = {
  control: (base, state) => ({
    ...base,
   
    borderRadius:"1px",
   border:"1px solid",
   
    // This line disable the blue border
    boxShadow: state.isFocused ? 0 : 0,
    "&:hover": {
    
      borderRadius:"5px",
      border:"1px solid",
      
    }
  }),
 
};
class Companysignupthree extends React.Component {
  constructor() {
    super();
    let comments = JSON.parse(sessionStorage.getItem('someData'));
    let SignupAS = JSON.parse(sessionStorage.getItem('signupAS'));
   // console.log(SignupAS)
    let Intrestss = sessionStorage.getItem('Intrest');

    this.state = {
      companylogo:" ",
      companysize:" ",
      companyindustry:" ", 
      stinterest:{
        internship:false,
        virtualInternship:false,
        workexperience:false,
        workplacement:false,
        partTimeRole:false,
        SideGigOppurtunities:false,
        CourseWorkRelated:false,
        PortfolioBuilding:false,
        other:false,
        DataisLoaded: false,
        FirstName:"",
        LastName:" ",
       Mobile:"",
      JobTitle:" ",
      JobDept:"",
     CompanyWeb:" ",
      Location:"",
     Country:" ",
    
      },
      interest:Intrestss,
      file: null,
      userid:comments,
      reg_level:3,
      selectIndustry:[],
      selectCountry:[],
      cpylocation:"",
      opportunitiestypeid:[],
      signupfor:SignupAS,
      cpywebsite:"",

      opportunitiestype: {
        Internship: false,
        Externship: false,
       "Work Experience": false,
        "Work Placement": false,
        "Part Time Roles": false,
        "Side Gig Opportunities":false,
        "Course Work Related":false,
        "Portfolio Building":false,
         Other:false,
         "Open to All Positions":false

      },
      
      };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
   
    event.preventDefault();
    
    if (this.state. StudyStatus === "") {
     alert("Plese Fill !");
    }
    else{
      
    const data = {  companylogo:this.state.companylogo, 
      companysize:this.state.companysize, 
      interest:this.state.opportunitiestypeid.toString(), 
      companyindustry:this.state.companyindustry,
      cpylocation:this.state.cpylocation,
      cpywebsite:this.state.cpywebsite,
      userid:this.state.userid, 
      reg_level:this.state. reg_level,
      signupfor:this.state.signupfor
    }

     // console.log(data)

      
      fetch('https://www.gigeconome.com:5200/api/cpuserinfo', { method: 'POST', 

    body: JSON.stringify(data), // data can be `string` or {object}!

    headers:{ 'Content-Type': 'application/json' } })

    .then(res => res.json())

    .catch(error => console.error('Error:', error))

    .then((response)=>{
        console.log('Success:', response)
         
           window.location.href=`./Companysignupfour`
            //this.setState({ data: response.userid });
       // console.log(response)
    })
   
  }
        
  this.setState({
    DataisLoaded: true 
  }) 
       
    
   }
   componentDidMount(){
    const industry_data={dropdownlist_input:"stuindustry",
    reg_level:this.state. reg_level
}
fetch('https://www.gigeconome.com:5200/api/getdropdownlist', { method: 'POST', 

body: JSON.stringify(industry_data), // data can be `string` or {object}!

headers:{ 'Content-Type': 'application/json' } })

.then(res => res.json())

.catch(error => console.error('Error:', error))

.then((posRes)=>{
      this.setState({selectIndustry:posRes.result})
    })
 
    const countrydata={dropdownlist_input:"country",
   
}
fetch('https://www.gigeconome.com:5200/api/getdropdownlist', { method: 'POST', 

body: JSON.stringify(countrydata), // data can be `string` or {object}!

headers:{ 'Content-Type': 'application/json' } })

.then(res => res.json())

.catch(error => console.error('Error:', error))

.then((response)=>{
  const data = response.result

  
  const options = data.map(d => ({
      "value" : d.id,
      "label" : d.name
  
    })) 
   
    this.setState({selectCountry: options})
      
    })
 

   }
   handleDrop = (e) => {
    
    this.setState({cpylocation:e.value})
   
      }
   getBase64 = file => {
    return new Promise(resolve => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        console.log("Called", reader);
        baseURL = reader.result;
        console.log(baseURL);
        resolve(baseURL);
      };
      console.log(fileInfo);
    });
  };
  handleImage = (e) => {
 // let file = new FileReader();
 console.log(e.target.files[0]);
 let { file } = this.state;

 file = e.target.files[0];

 this.getBase64(file)
   .then(result => {
     file["base64"] = result;
     console.log("File Is", file);
     this.setState({
       companylogo: result,
       file
       
     });
   })
   .catch(err => {
     console.log(err);
   });

 this.setState({
 //  file: e.target.files[0]
 });

 this.setState({
   //companylogo: e.target.files[0]
 });



  }
  handleCompany=(e)=>{
    this.setState({
      [e.target.name]: e.target.value
    })
  }
   handleChange = (e) => {
     
   
    const { name, checked } = e.target;
    //console.log("====>", name, checked);
    this.setState((prevState) => {
      const opportunitiestype = prevState.opportunitiestype;
     // console.log("============>", opportunitiestype);
      opportunitiestype[name] = checked;
      return opportunitiestype;
    });
      
   
   
  }
  render() {
    this.state.opportunitiestypeid = Object.keys(this.state.opportunitiestype).filter(
      (key) => this.state.opportunitiestype[key]
    );
    console.log(this.state.opportunitiestypeid.toString());
    
    return (
      <div>
        {/* ======= Header ======= */}
        <Header />
        {/* End Header */}
        <main id="main" style={{ background: "#303030", clear: "both" }}>
          <br />
          <br />
          {/* ======= About Section ======= */}
          <section id="clients" className="clients">
            <div className="container" data-aos="fade-up">
              <style
                type="text/css"
                dangerouslySetInnerHTML={{
                  __html:
                    "\n    .boxxlr{\n     border: 0px solid green;\n    background: #edf8f2;\n    margin-right: 2.5%;\n    margin-bottom: 7%;\n    color: #000;\n    margin-left: 9%;\n    width: 100%;\n    }\n/*.boxxlr:hover {\n    border: 0px solid green;\n    background: green;\n    margin-right: 1%;\n    margin-bottom: 1%;\n     color: #fff;\n}*/\n\n.boxclrtitle {\nfont-size: 12px;font-weight: bold;\n}\n\n.boxclrtitle1 {\nfont-size: 18px;font-weight: bold;\n}\n/*.boxxlr .boxclrtitle:hover {\nfont-size: 12px;font-weight: bold;\n}*/\n\n\n  ",
                }}
              />
              <div className="row no-gutters">
                <div className="content col-xl-12 d-flex align-items-center">
                  <div className="content col-xl-12 d-flex align-items-center">
                    <div
                      className="row cpysignupthree"
                    
                    >
                      <h2
                        style={{
                          fontSize: "20px",
                          textAlign: "center",
                          color: "#fff",
                         fontWeight:'bold',
                          background: "#00674C",
                          padding: "1%",
                          width: "70%",
                          margin: "6%",
                        }}
                      >
                        {" "}
                        Company Details{" "}
                      </h2>
                      <h6
                        style={{
                          textAlign: "center",
                          fontSize: "16px",
                          fontWeight:"bold",
                          marginTop: "-3%",
                          fontFamily:"GothamLight",
                          color:"rgb(0,147,69)"
                          
                        }}
                      >
                        Please enter your company details and tell us what Opportunities do you need student talents for?
                      </h6>
                      <div className="row h-100 row align-items-center">
                        <div className="col-md-10" style={{ margin: "auto",marginTop:"5%" }}>
                          <form onSubmit={this.handleSubmit}>
                            <div
                              className="form-group"
                              style={{ padding: "2%", borderRadius: "1px" }}
                            >
                                {this.state.selectIndustry.length == 0
                    ? "Loading Industry Name..."
                    : 

                    <select class="form-select" style={{
                      width: "100%",
                      height: "38px",
                     border:"1px solid gray",
                      marginBottom: "2%",
                      marginTop: "1%",
                    }} name ="companyindustry" 
                    onChange = {this.handleCompany}>
                     
                      <option >Company Sector / Industry</option>
                    {this.state.selectIndustry.map(fbb =>
                   
                      <option key={fbb.key} value={fbb.id}>{fbb.name}</option>
                      
                    )};
                  </select>
                    } 
                            </div>
                            <div
                              className="form-group"
                              style={{ padding: "2%", borderRadius: "1px" }}
                            >
                              <select
                                className="form-select"
                                style={{
                                  width: "100%",
                                  height: "38px",
                                  border: "1px solid gray",
                                 
                                }}
                                onChange={this.handleCompany} 
                                id="companysize"
                                name="companysize"
                              >
                                <option>Company Size</option>
                                <option value="1">1 person</option>
                                <option value="2">2 – 10 ppl</option>
                                <option value="3">11 – 49 ppl</option>
                                <option value="4">50 – 249 ppl</option>
                                <option value="5">25O – 999 ppl</option>
                                <option value="6">999+ ppl</option>
                                
                              </select>
                            </div>
                            <div
                              className="form-group"
                              style={{ padding: "2%", borderRadius: "1px" }}
                            >
                               <Select options={this.state.selectCountry}
                              components={{  IndicatorSeparator:() => null }}
                              styles={style} placeholder="Select Location" onChange={this.handleDrop}   /> 
                            </div>
                            <div
                              className="form-group"
                              style={{ padding: "2%", borderRadius: "1px" }}
                            >

<input
                                style={{ border:"1px solid gray" }}
                                type="text"
                                className="form-control"
                                placeholder= "Company Website"
                                required
                               //value= {this.state.CompanyWeb}
                               onChange={this.handleCompany} 
                                id="companywebsite"
                                name="cpywebsite"
                                autoComplete="off"
                              />

                              </div>
                            <div
                              className="form-group"
                              style={{ padding: "2%", borderRadius: "1px" }}
                            >
                              <div
                                style={{ border: "1px solid", padding: "1%" }}
                              >
                                <label id="fileLabel">Company Logo</label>
                               
                                <input
                                    type="file"
                                    name="companylogo"
                                    onChange={this.handleImage}
                                   style={{marginLeft:"36%"}}
                                  />
                                 
                              </div>
                            </div>
                            <div
                              className="form-group"
                              style={{ padding: "2%", borderRadius: "1px" }}
                            >
                             
                            </div>
                            <div className="row mt-3">
                              <h6 style={{ fontWeight:'bold',textAlign:'center',fontFamily:"GothamLight",color:"rgb(0,147,69)" }}>
                                Interested in Resources for
                              </h6>
                            </div>
                           

                            <div class="col-md-12" style={{textAlign:"left",margin:"3%"}}>
<div class="row">
{
 
 Object.entries(this.state.opportunitiestype).map((cname) => (
   

       <div key={cname[0]} className="columnstyle">
                <input
                  checked={cname[1]}
                  onChange={this.handleChange}
                  type="checkbox"
                  name={cname[0]}
                  id="company_check"
                />
              <span>{cname[0]}</span>
              </div>



   ))}
  
</div>
</div>






                            <div className="row">
                              
                                <Link
                                  style={{ color: "#fff" }}
                                  to="/companybacksignuptwo"
                                  className="btn btn-primary"
                                  style={{
                                    padding: "0%",
                                    marginLeft: "20%",
                                    marginTop: "7%",
                                    color: "#fff",
                                    background: "#000",
                                    width: "26%",
                                    border: "none",
                                    borderRadius: "1px" /* marginTop: '-13%', */,
                                    height: "40px",
                                    paddingTop: "1%",
                                    marginBottom: "6%",
                                  }}
                                >
                                  {" "}
                                  Back{" "}
                                </Link>{" "}
                              
                              <button
                                className="btn btn-primary"
                                style={{
                                  padding: "2%",
                                  marginLeft: "12%",
                                  marginTop: "7%",
                                  color: "#fff",
                                  background: "#00674C",
                                  width: "26%",
                                  border: "none",
                                  borderRadius: "1px" /* marginTop: '-13%', */,
                                  height: "40px",
                                  paddingTop: "1%",
                                  marginBottom: "6%",
                                }}
                              >
                                
                                  {" "}
                                  Next{" "}
                                {" "}
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        {/* End #main */}
        {/* ======= Footer ======= */}
        <Footer />
      </div>
    );
  }
}
export default Companysignupthree;
