import React, { Component } from "react";
import Header from "../Header/Header";
import Login1 from "../../images/Login-28.png";
import Login2 from "../../images/Login-29.png";
import Login3 from "../../images/Login-30.png";
import Login4 from "../../images/Login-31.png";
import MainIcon from "../../images/Post a Project-13.png";
import Footer from "../Footer/Footer";
import swal from "sweetalert";
import { GoogleLogin } from "react-google-login";

import api from "../../config.js";

import { Link } from "react-router-dom";

class Emailverification extends React.Component {
    
    
  
  constructor(props) {
    const QueryString = window.location.href; 
    console.log(QueryString)
    let paramString = QueryString.split('/');
   let Email=paramString[4]
    console.log(Email)
   let Password=paramString[5]
   console.log(Password)
    let comments = JSON.parse(localStorage.getItem('someData'));
    console.log(comments)
    super(props);
    
    this.state = {
      email: Email,
      password:Password,
      userid:comments,
     success:"",
     error:"",
    
     
    };
   
    
  }
  

  
  componentDidMount() {
    const data_two = {
      
      email: this.state.email,
    };
    fetch("https://www.gigeconome.com:5200/api/sendstuwelcomemail", {
      method: "POST",

      body: JSON.stringify(data_two), // data can be `string` or {object}!

      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())

      .catch((error) => console.error("Error:", error))

      .then((posRes) => {
        console.log(posRes);
        
      });
  
  }
  
  render() {
      const {email}=this.state;
    return (
      <div>
        <div>
          <Header />
        </div>
        <main id="main" style={{ background: "#303030", clear: "both" }}>
          <br />
          <br />
          {/* ======= About Section ======= */}
          <section id="clients" className="clients">
            <div className="container" data-aos="fade-up">
              <style
                type="text/css"
                dangerouslySetInnerHTML={{
                  __html:
                    "\n    .boxxlr{\n     border: 0px solid green;\n    background: #edf8f2;\n    margin-right: 2.5%;\n    margin-bottom: 7%;\n    color: #000;\n    margin-left: 9%;\n    width: 100%;\n    }\n/*.boxxlr:hover {\n    border: 0px solid green;\n    background: green;\n    margin-right: 1%;\n    margin-bottom: 1%;\n     color: #fff;\n}*/\n\n.boxclrtitle {\nfont-size: 12px;font-weight: bold;\n}\n\n.boxclrtitle1 {\nfont-size: 18px;font-weight: bold;\n}\n/*.boxxlr .boxclrtitle:hover {\nfont-size: 12px;font-weight: bold;\n}*/\n\n\n  ",
                }}
              />
              <div className="row no-gutters">
                <div className="content col-xl-12 d-flex align-items-center">
                  <div className="content col-xl-12 d-flex align-items-center">
                  <form onSubmit={this.handleSubmit}>
                    <div
                      className="row"
                      style={{
                        display: "flex",
                        justifyContent: "center" /* alignItems: 'center', */,
                        background: "#fff",
                        borderRadius: "20px",
                        margin: "auto",
                        width: "54%",
                      }}
                    >
                        
                       <Link to="/" id="close">X</Link>
                      <img
                        src={MainIcon}
                        style={{ width: 100, marginTop: "6%" }}
                      />

                      <div className="row h-100 row align-items-center">
                     
                        <div className="col-md-10" style={{ margin: "auto" }}>
                        
                          <div style={{textAlign:"center",color:"rgb(0,147,69)",marginTop:"5%",fontFamily:"Gotham",fontSize:16}} >
                          Thank you for your support, we have successfully verified your Email: {email}.<br />
                       <div style={{textAlign:"center",color:"rgb(0,147,69)",marginTop:"5%",fontFamily:"Gotham",fontSize:18}}>Click link below to sign in and complete your profile</div> </div> <br />
                       
                        </div>
                        <Link to="/signin"
                        type="submit"
                        className="btn btn-success" style={{margin:"auto",width:190,backgroundColor:"rgb(0,147,69)",color:"white",
                    fontFamily:"GothamLight",fontWeight:"bold",border:"1px solid rgb(0,147,69)"}}>Sign In</Link>
                       
                        <div className="mt-5"></div>
                      </div>
                      
                    </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        {/* End #main */}

        <Footer />
      </div>
    );
  }
}

export default Emailverification;
