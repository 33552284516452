import React from "react";
import StepFive from "../../images/stepfive.PNG";
import Login1 from '../../images/Login-28 - Copy.png'
import Login2 from '../../images/Login-29.png';
import Login3 from '../../images/Login-30.png';
import Login4 from '../../images/Login-31.png';
import Login5 from '../../images/Login-36.png';
import { Link } from "react-router-dom";


import axios from 'axios';
import swal from 'sweetalert';
import Header from "../Header/Header";
function validate(email, password) {
  // true means invalid, so our conditions got reversed
  return {
    email: email.length === 0,
    password: password.length === 0
  };
}
class PostaprojectSignin extends React.Component {




  
  constructor(props) {
    super(props);
    let Autoid=sessionStorage.getItem("AutoID")
    let Auto_id=Autoid.replace(/^"(.*)"$/, '$1')
    this.state = {
      email: "",
      password: "",
      everFocusedEmail: false,
      everFocusedPassword: false,
      inFocus: "",
      data_one:undefined,
      ipaddressid:"",
      userid:"",
      autogeneratedid:Auto_id 
      
    };
    this.handleSubmit=this.handleSubmit.bind(this)
  }
  handleSubmit(event) {
    event.preventDefault();
    if (this.state.email === "") {
      swal("Plese Fill !");
    }
    else{
      const data_one={userid:this.state.userid}
    const data = {
      email:this.state.email , 
      ipaddressid:this.state.ipaddressid,
     password:this.state.password,
    autogeneratedid:this.state.autogeneratedid }
console.log(data)
     let BaseUrl = JSON.parse(sessionStorage.getItem('baseurl'));
     fetch("https://www.gigeconome.com:5200/api/login", { method: 'POST',  

    body: JSON.stringify(data), // data can be `string` or {object}!

    headers:{ 'Content-Type': 'application/json' } })

    .then(res => res.json())

    .catch(error => console.error('Error:', error))

    .then((response)=>{
        console.log('Success:', response)
      
        if (response.message=="Login Success"){
          this.setState({success:"Login Success"})
        }
        else{
          this.setState({error:response.message})
        }
        var dataStore = JSON.stringify(response.user.name );
        sessionStorage.setItem('nameData', dataStore);
       var  UserId=JSON.stringify(response.user.id );
        sessionStorage.setItem('someData', UserId);
       
        if(response.user.usertype=="Company" ||response.user.usertype=="Employee"||response.PAPstatus==1 ){
            if(response.PAPstatus==1){
              this.setState({userid:response.user.id},()=>{
                const getstatus={userid:this.state.userid,ipaddressid:this.state.ipaddressid,
                autogeneratedid:this.state.autogeneratedid
                }
                console.log(getstatus)
                fetch("https://www.gigeconome.com:5200/api/insertPAPnotsignedinfo", { method: 'POST',  

                body: JSON.stringify(getstatus), // data can be `string` or {object}!
            
                headers:{ 'Content-Type': 'application/json' } })
            
                .then(res => res.json())
            
                .catch(error => console.error('Error:', error))
            
                .then((response)=>{
                  console.log( response)
                   window.location.href=`./Postaproject_three`
                   sessionStorage.setItem("PostID",JSON.stringify(response.postprojectid))
                })
              })
           
            }
           

          /* Opportunity Api call Redirect Starts */
           else if(response.PAOstatus==1){
              this.setState({userid:response.user.id},()=>{
                const getstatus={userid:this.state.userid,ipaddressid:this.state.ipaddressid,
                autogeneratedid:this.state.autogeneratedid
                }
                console.log(getstatus)
                fetch("https://www.gigeconome.com:5200/api/insertPAOnotsignedinfo", { method: 'POST',  

                body: JSON.stringify(getstatus), // data can be `string` or {object}!
            
                headers:{ 'Content-Type': 'application/json' } })
            
                .then(res => res.json())
            
                .catch(error => console.error('Error:', error))
            
                .then((response)=>{
                    console.log('Success:', response)
                    window.location.href=`./Opportunities_three`
                    sessionStorage.setItem("PostOPP",JSON.stringify(response.postopportunityid))
                })
              })
           
            }
             /* Opportunity Api call Redirect Starts */
           else if(response.PGOstatus==1){
            this.setState({userid:response.user.id},()=>{
              const getstatus={userid:this.state.userid,ipaddressid:this.state.ipaddressid,
              autogeneratedid:this.state.autogeneratedid
              }
              console.log(getstatus)
              fetch("https://www.gigeconome.com:5200/api/insertPGOnotsignedinfo", { method: 'POST',  

              body: JSON.stringify(getstatus), // data can be `string` or {object}!
          
              headers:{ 'Content-Type': 'application/json' } })
          
              .then(res => res.json())
          
              .catch(error => console.error('Error:', error))
          
              .then((response)=>{
                  console.log(response)
                  window.location.href=`./gradopportunitypreview`
                  sessionStorage.setItem("PgoProjectid",JSON.stringify(response.pgoprojectid))
              })
            })
         
          }
            else {
              window.location.href=`./CompanyProfile`
              
            }
         /* Opportunity Api call Redirect Ends */

          sessionStorage.setItem('UserType', JSON.stringify(response.user.usertype ));
          
        
          sessionStorage.setItem("someData",JSON.stringify(response.user.id))
         
        }
        if(response.user.usertype=="Student"){
          fetch(BaseUrl + "https://www.gigeconome.com:5200/api/loginstuprofileinfo", { method: 'POST',  

          body: JSON.stringify(data_one), // data can be `string` or {object}!
      
          headers:{ 'Content-Type': 'application/json' } })
      
          .then(res => res.json())
      
          .catch(error => console.error('Error:', error))
      
          .then((response)=>{
              console.log('Success:', response)

          })
         
         
          //window.location.href=`./Studentdashboard`
        }
       
         
           
    })
   
  
  }
        
    
       
    
   }
   handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }
componentDidMount(){
    
       {/*Ip Address Api Call */}
const fetchPromise = fetch('https://geolocation-db.com/json/');

fetchPromise.then( response => {
  const jsonPromise = response.json();
  jsonPromise.then( json => {
    console.log(json.IPv4);
    this.setState({ipaddressid:json.IPv4})
  });
});
}
  handleEmailChange = evt => {
    this.setState({ email: evt.target.value });
  };

  handlePasswordChange = evt => {
    this.setState({ password: evt.target.value });
  };

 
 







  

  
  render() {

  
    
    return (
      <div>
         {/* ======= Header ======= */}
        <Header />
      <div>
    

      </div>
      {/* End Header */}
        <main id="main" style={{  clear: "both" }}>
          <br />
          <br />
          {/* ======= About Section ======= */}
          <section id="clients" className="clients">
            <div
              className="container col-md-5 col-xs-12 col-sm-12 col-lg-6"
              data-aos="fade-up"
            >
              <style
                type="text/css"
                dangerouslySetInnerHTML={{
                  __html:
                    "\n    .boxxlr{\n     border: 0px solid green;\n    background: #edf8f2;\n    margin-right: 2.5%;\n    margin-bottom: 7%;\n    color: #000;\n    margin-left: 9%;\n    width: 100%;\n    }\n/*.boxxlr:hover {\n    border: 0px solid green;\n    background: green;\n    margin-right: 1%;\n    margin-bottom: 1%;\n     color: #fff;\n}*/\n\n.boxclrtitle {\nfont-size: 12px;font-weight: bold;\n}\n\n.boxclrtitle1 {\nfont-size: 18px;font-weight: bold;\n}\n/*.boxxlr .boxclrtitle:hover {\nfont-size: 12px;font-weight: bold;\n}*/\n\n\n  ",
                }}
              />

<div className="text-center mt-5">
                <img
                  src={StepFive}
                  className="rounded"
                  style={{ width: 700, height: 80 }}
                />
              </div>

              <div className="row no-gutters">
                <div className="content col-xl-12 d-flex align-items-center">
                  <div className="content col-xl-12 d-flex align-items-center">
                    <div
                      className="row mt-4"
                      style={{
                        display: "flex",
                        justifyContent: "center" /* alignItems: 'center', */,
                        background: "#fff",
                        width: "100%" /* marginLeft: '50%', */,
                        borderRadius: "20px",
                        margin: "auto",
                      }}
                    >
                      {/*     <h2 style="font-size: 20px;letter-spacing: 6px;text-align: center;color: #000;
    font-weight: 600; color: #fff;background: green; padding: 1%; width: 70%;margin: 6%;"> Create an Account </h2> */}
                      <div className="row h-100 row align-items-center">
                        <div
                          className="col-md-10"
                          style={{ margin: "auto", marginBottom: "6%" }}
                        >
                          <form onSubmit={this.handleSubmit}>
                            <img
                              src={Login5}
                              style={{
                                marginLeft: "40%",
                                marginBottom: "2%",
                                marginTop: "5%",
                              }}
                            />
                            <h4 style={{ textAlign: "center",fontWeight:"bold" }}>
                              Sign in to GigeconoMe
                            </h4>
                            <div
                              className="form-group"
                              style={{ padding: "2%", borderRadius: "1px" }}
                            >
                              <input
                             required
                              value={this.state.email}
                                style={{ padding: "2%" }}
                                type="email"
                                className="form-control"
                                placeholder="Email id"
                                id="email1"
                                 name="email"
                                autoComplete="off"
                                 onChange={this.handleChange}
                                
                              />
                            </div>
                            <div
                              className="form-group"
                              style={{ padding: "2%", borderRadius: "1px" }}
                            >
                              <input
                             
                                style={{ padding: "2%" }}
                                type="password"
                                className="form-control"
                                placeholder="password"
                                id="password1"
                                name="password"
                                required
                              
                                value={this.state.password}
                                onChange={this.handleChange}
                              />
                            </div>
                            <Link
                              to='/reset'
                              
                              style={{
                                float: "right",
                                color: "rgb(0, 147, 69)",
                                fontSize: "14px",
                              }}
                            >
                              Forgot password ?
                            </Link>
                            <button
                         
                              className="btn btn-primary"
                              style={{
                                padding: "1%",
                                marginLeft: "23%",
                                marginBottom: "4%",
                                color: "#fff",
                                background: "rgb(0, 147, 69)",
                                width: "50%",
                                border: "none",
                                borderRadius: "1px",
                                marginTop: "5%",
                                fontSize: "20px",
                              }}
                              //onClick={this.signin}
                              type="submit"
                            >
                              Sign in with Email
                            </button>
                          </form>
                          <div
                            style={{
                              float: "left",
                              width: "45%",
                              marginRight: "3%",
                            }}
                          >
                            <hr />
                          </div>
                          <div style={{ float: "right", width: "44%" }}>
                            <hr />
                          </div>
                          Or
                          <h6 style={{fontFamily:'GothamLight',fontWeight:'bold', textAlign: "center", marginTop: "2%" }}>
                            With Your Social Network
                          </h6>
                          <div className="row" style={{ margin: "5%" }}>
                            <div className="box col">
                              <img
                                className="snalgn"
                                src={Login1}
                              />
                            </div>
                            <div className="box col">
                              <img
                                className="snalgn"
                                src={Login2}
                              />
                            </div>
                            <div className="box col">
                              <img
                                className="snalgn"
                                src={Login3}
                              />
                            </div>
                            <div className="box col">
                              <img
                                className="snalgn"
                                src={Login4}
                              />
                            </div>
                          </div>
                          <h5 style={{ textAlign: "center",fontWeight:"bold" }}>
                            Don't have an account?
                          </h5>
                          <h6 style={{ textAlign: "center", color: "rgb(0, 147, 69)" }}>
                            Join as student
                            <span style={{ color: "transparent" }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </h6>
                          <h6 style={{ textAlign: "center", color: "rgb(0, 147, 69)" }}>
                            Join as a Employer or startup
                          </h6>
                          <h6 style={{ textAlign: "center", color: "rgb(0, 147, 69)" }}>
                            Join as a University
                            <span style={{ color: "transparent" }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        {/* End #main */}
        {/* ======= Footer ======= */}

      </div>
    );
  }
}
export default PostaprojectSignin;
