import React from "react";
export default class Educatorfaq extends React.Component{
    constructor(){
        super();
        this.state={
            question1:0,
            question2:0,
            question3:0,
            question4:0,
            question5:0,
            question6:0
        }
        this.handleQuestion1=this.handleQuestion1.bind(this)
        this.handleQuestion1close=this.handleQuestion1close.bind(this)
        this.handleQuestion2=this.handleQuestion2.bind(this)
        this.handleQuestion2close=this.handleQuestion2close.bind(this)
        this.handleQuestion3=this.handleQuestion3.bind(this)
        this.handleQuestion3close=this.handleQuestion3close.bind(this)
        this.handleQuestion4=this.handleQuestion4.bind(this)
        this.handleQuestion4close=this.handleQuestion4close.bind(this)
        this.handleQuestion5=this.handleQuestion5.bind(this)
        this.handleQuestion5close=this.handleQuestion5close.bind(this)
        this.handleQuestion6=this.handleQuestion6.bind(this)
        this.handleQuestion6close=this.handleQuestion6close.bind(this)
    }
    handleQuestion5(){
        this.setState({question5:1})
    }
    handleQuestion5close(){
        this.setState({question5:0})
    }
    handleQuestion6(){
        this.setState({question6:1})
    }
    handleQuestion6close(){
        this.setState({question6:0})
    }
    handleQuestion4(){
        this.setState({question4:1})
    }
    handleQuestion4close(){
        this.setState({question4:0})
    }
    handleQuestion3(){
        this.setState({question3:1})
    }
    handleQuestion3close(){
        this.setState({question3:0})
    }
    handleQuestion1(){
        this.setState({question1:1})
    }
    handleQuestion1close(){
        this.setState({question1:0})
    }
    handleQuestion2(){
        this.setState({question2:1})
    }
    handleQuestion2close(){
        this.setState({question2:0})
    }
    render(){
        return(<>
        {this.state.question1==0?<>
            <div style={{paddingRight:"3%"}} onClick={this.handleQuestion1}>
        <h3 className="educatorfaqpage">Is GigeconoMe Free to Students?
        <i className="fa fa-caret-down" style={{fontSize:20,float:"right"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>:<>
        <div style={{paddingRight:"3%"}} onClick={this.handleQuestion1close}>
        <h3 className="educatorfaqpage">Is GigeconoMe Free to Students?
        <i className="fa fa-caret-up" style={{fontSize:20,float:"right"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>}
       
       {this.state.question1==1?<>
       <div>
       <p style={{ marginTop:"-3%",fontFamily:"GothamLight",fontWeight:"bold",fontSize:12,marginLeft:"2%" }}>
                        	Yes, GigeconoMe is free of charge to all students using the platform. We believe all our students should have access to all our features and functionalities at zero cost. 
                        </p>
       </div>
       </>:<></>}

       {this.state.question2==0?<>
            <div style={{paddingRight:"3%"}} onClick={this.handleQuestion2}>
        <h3 className="educatorfaqpage">What do you mean by Employability skills?
        <i className="fa fa-caret-down" style={{fontSize:20,float:"right"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>:<>
        <div style={{paddingRight:"3%"}} onClick={this.handleQuestion2close}>
        <h3 className="educatorfaqpage">What do you mean by Employability skills?
        <i className="fa fa-caret-up" style={{fontSize:20,float:"right"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>}
       
       {this.state.question2==1?<>
       <div>
       <p style={{ marginTop:"-3%",fontFamily:"GothamLight",fontWeight:"bold",fontSize:12,marginLeft:"2%" }}>
       Employability Skills can be defined as the transferable/essential, personal qualities skills needed by an individual to make them ‘employable’. Employability skills are the essential skills, personal qualities and values that enable you to thrive in any workplace. 
                        </p>
       </div>
       </>:<></>}

       {this.state.question3==0?<>
            <div style={{paddingRight:"3%"}} onClick={this.handleQuestion3}>
        <h3 className="educatorfaqpage">What are Employability skills?
        <i className="fa fa-caret-down" style={{fontSize:20,float:"right"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>:<>
        <div style={{paddingRight:"3%"}} onClick={this.handleQuestion3close}>
        <h3 className="educatorfaqpage">What are Employability skills?
        <i className="fa fa-caret-up" style={{fontSize:20,float:"right"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>}
       
       {this.state.question3==1?<>
       <div>
       <p style={{ marginTop:"-3%",fontFamily:"GothamLight",fontWeight:"bold",fontSize:12,marginLeft:"2%" }}>
       These are skills employers believe will equip the employee to carry out their role to the best of their abilities. They include Communication and interpersonal skills, Problem solving skills, Using your initiative and being self-motivated, working under pressure and to deadlines, Organisational skills, Team working, Ability to learn and adapt, Numeracy, Valuing diversity and difference and Negotiation skills. 
                        </p>
       </div>
       </>:<></>}
       {this.state.question4==0?<>
            <div style={{paddingRight:"3%"}} onClick={this.handleQuestion4}>
        <h3 className="educatorfaqpage">How is GigeconoMe different from other marketplaces?
        <i className="fa fa-caret-down" style={{fontSize:20,float:"right",marginTop:"-4%"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>:<>
        <div style={{paddingRight:"3%"}} onClick={this.handleQuestion4close}>
        <h3 className="educatorfaqpage">How is GigeconoMe different from other marketplaces?
        <i className="fa fa-caret-up" style={{fontSize:20,float:"right",marginTop:"-4%"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>}
       
       {this.state.question4==1?<>
       <div>
       <p style={{ marginTop:"-3%",fontFamily:"GothamLight",fontWeight:"bold",fontSize:12,marginLeft:"2%" }}>
       GigeconoMe is a student talent marketplace that aligns study and work. Our focus is to match students to companies and Startups based on employability gaps as well as various preferences including Opportunity Types, role types, Industry, organisation size and skills. As a debiased platform, we have developed a non-conventional selection process where our student profiles have no pictures, to ensure all our students are treated fairly and chosen based on their talents. 
                        </p>
       </div>
       </>:<></>}

       {this.state.question5==0?<>
            <div style={{paddingRight:"3%"}} onClick={this.handleQuestion5}>
        <h3 className="educatorfaqpage">I am an Educator and would love to Partner with GigeconoMe, who do I contact?
        <i className="fa fa-caret-down" style={{fontSize:20,float:"right",marginTop:"-4%"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>:<>
        <div style={{paddingRight:"3%"}} onClick={this.handleQuestion5close}>
        <h3 className="educatorfaqpage">I am an Educator and would love to Partner with GigeconoMe, who do I contact?
        <i className="fa fa-caret-up" style={{fontSize:20,float:"right",marginTop:"-4%"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>}
       
       {this.state.question5==1?<>
       <div>
       <p style={{ marginTop:"-3%",fontFamily:"GothamLight",fontWeight:"bold",fontSize:12,marginLeft:"2%" }}>
       To learn more about GigeconoMe, or see how see how our platform works? Please email us at admin@gigeconome.com or use the link below to Request a Demo. 
                        </p>
       </div>
       </>:<></>}

       {this.state.question6==0?<>
            <div style={{paddingRight:"3%"}} onClick={this.handleQuestion6}>
        <h3 className="educatorfaqpage">What type of companies does GigeconoMe work with?
        <i className="fa fa-caret-down" style={{fontSize:20,float:"right",marginTop:"-4%"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>:<>
        <div style={{paddingRight:"3%"}} onClick={this.handleQuestion6close}>
        <h3 className="educatorfaqpage">What type of companies does GigeconoMe work with?
        <i className="fa fa-caret-up" style={{fontSize:20,float:"right",marginTop:"-4%"}}></i>
        </h3>
        <hr style={{marginLeft:"2%"}}/>
        </div>
        </>}
       
       {this.state.question6==1?<>
       <div>
       <p style={{ marginTop:"-3%",fontFamily:"GothamLight",fontWeight:"bold",fontSize:12,marginLeft:"2%" }}>
       We partner with both newly formed start-ups and large enterprises who are passionate about proving student talents further opportunities to help them secure good first jobs. 
                        </p>
       </div>
       </>:<></>}
        </>)
    }
}