import React from "react";
import Marquee from "react-easy-marquee";
import { Link } from "react-router-dom";
import Tab4 from "../../images/Gigeconome-12.png";
export class DisplayCompany extends React.Component{
    constructor(){
        super()
        this.state={
            cpylogo1:"",
            cpylogo2:"",
            cpylogo3:"",
            cpylogo4:"",
            cpylogo5:"",
            cpylogo6:"",
            cpylogo7:"",
            cpylogo8:"",
            cpylogo9:"",
            cpylogo10:"",
            cpylogo11:"",
            cpylogo12:"",
            cpylogo13:"",
            cpylogo14:"",
            cpylogo15:"",
            cpylogo16:"",
            cpylogo17:"",
            cpylogo18:"",
            cpylogo19:"",
            cpylogo20:"",
            cpylogo21:"",
            cpylogo1id:"",
            cpylogo2id:"",
            cpylogo3id:"",
            cpylogo4id:"",
            cpylogo5id:"",
            cpylogo6id:"",
            cpylogo7id:"",
            cpylogo8id:"",
            cpylogo9id:"",
            cpylogo10id:"",
            cpylogo11id:"",
            cpylogo12id:"",
            cpylogo13id:"",
            cpylogo14id:"",
            cpylogo15id:"",
            cpylogo16id:"",
            cpylogo17id:"",
            cpylogo18id:"",
            cpylogo19id:"",
            cpylogo20id:"",
            cpylogo21id:"",

        }
    }
componentDidMount(){       
const archiveall = { dropdownlist_input:"getallcpylogodetailslive"
};
fetch("https://www.gigeconome.com:5200/api/getdropdownlist", {
method: "POST",

body: JSON.stringify(archiveall), // data can be `string` or {object}!

headers: { "Content-Type": "application/json" },
})
.then((res) => res.json())

.catch((error) => console.error("Error:", error))

.then((response) => {

    this.setState({
        cpylogo1:response.result[0].cpylogo,
        cpylogo2:response.result[1].cpylogo,
        cpylogo3:response.result[2].cpylogo,
        cpylogo4:response.result[3].cpylogo,
        cpylogo5:response.result[4].cpylogo,
        cpylogo6:response.result[5].cpylogo,
        cpylogo7:response.result[6].cpylogo,
        cpylogo8:response.result[7].cpylogo,
        cpylogo9:response.result[8].cpylogo,
        cpylogo10:response.result[9].cpylogo,
        cpylogo11:response.result[10].cpylogo,
        cpylogo12:response.result[11].cpylogo,
        cpylogo13:response.result[12].cpylogo,
        cpylogo14:response.result[13].cpylogo,
        cpylogo15:response.result[14].cpylogo,
        cpylogo16:response.result[15].cpylogo,
        cpylogo17:response.result[16].cpylogo,
        cpylogo18:response.result[17].cpylogo,
        cpylogo19:response.result[18].cpylogo,
        cpylogo20:response.result[20].cpylogo,
        cpylogo21:response.result[21].cpylogo,
        cpylogo1id:response.result[0].userid,
        cpylogo2id:response.result[1].userid,
        cpylogo3id:response.result[2].userid,
        cpylogo4id:response.result[3].userid,
        cpylogo5id:response.result[4].userid,
        cpylogo6id:response.result[5].userid,
        cpylogo7id:response.result[6].userid,
        cpylogo8id:response.result[7].userid,
        cpylogo9id:response.result[8].userid,
        cpylogo10id:response.result[9].userid,
        cpylogo11id:response.result[10].userid,
        cpylogo12id:response.result[11].userid,
        cpylogo13id:response.result[12].userid,
        cpylogo14id:response.result[13].userid,
        cpylogo15id:response.result[14].userid,
        cpylogo16id:response.result[15].userid,
        cpylogo17id:response.result[16].userid,
        cpylogo18id:response.result[17].userid,
        cpylogo19id:response.result[18].userid,
        cpylogo20id:response.result[20].userid,
        cpylogo21id:response.result[21].userid,
    })




}); 
    }
    render(){
        return(
        <div style={{overflow:"hidden"}} >
          
        	<section class="homepage-section employers">

<div class="homepage-section__header homepage-section__header--no-margin container"><div class="employers__header" style={{marginTop:"-3%"}} >
    <h2  style={{textAlign:"center",fontFamily:"Gotham",letterSpacing:"1px",marginBottom:"2%",fontSize:35}} id="iheading">Supported and
    <span style={{fontWeight:"bold",color:"#00539C"}}> trusted</span> by both <br/> <span style={{fontWeight:"bold",color:"#00539C",marginLeft:"1%"}}> Established Enterprises & Startups</span></h2></div></div>
 


<div class="bubble__wrap">

    <div class="bubbles">
       
   
 <div class="bubble__container"> <Link 
                         target="_blank"
                         to={{ 
                          
                           pathname: `/viewgigprofile/${this.state.cpylogo1id}`, 
                            
                         }}><div class="bubble"><img class="bubble__image" src={this.state.cpylogo1} alt="" loading="lazy"/></div></Link></div>



       
 
        <div class="bubble__container"><Link 
                         target="_blank"
                         to={{ 
                          
                           pathname: `/viewgigprofile/${this.state.cpylogo2id}`, 
                            
                         }}><div class="bubble"><img class="bubble__image" src={this.state.cpylogo2} alt="" loading="lazy"/></div></Link></div>


        <div class="bubble__container"><Link 
                         target="_blank"
                         to={{ 
                          
                           pathname: `/viewgigprofile/${this.state.cpylogo3id}`, 
                            
                         }}><div class="bubble"><img class="bubble__image" src={this.state.cpylogo3} alt="" loading="lazy"/></div></Link></div>

        <div class="bubble__container"><Link 
                         target="_blank"
                         to={{ 
                          
                           pathname: `/viewgigprofile/${this.state.cpylogo4id}`, 
                            
                         }}><div class="bubble"><img class="bubble__image" src={this.state.cpylogo4} alt="" loading="lazy"/></div></Link></div>



        <div class="bubble__container"><Link 
                         target="_blank"
                         to={{ 
                          
                           pathname: `/viewgigprofile/${this.state.cpylogo5id}`, 
                            
                         }}><div class="bubble"><img class="bubble__image" src={this.state.cpylogo5} alt="" loading="lazy"/></div></Link></div>



        <div class="bubble__container"><Link 
                         target="_blank"
                         to={{ 
                          
                           pathname: `/viewgigprofile/${this.state.cpylogo6id}`, 
                            
                         }}><div class="bubble"><img class="bubble__image" src={this.state.cpylogo6} alt="" loading="lazy"/></div></Link></div>

        <div class="bubble__container"><Link 
                         target="_blank"
                         to={{ 
                          
                           pathname: `/viewgigprofile/${this.state.cpylogo7id}`, 
                            
                         }}><div class="bubble"><img class="bubble__image" src={this.state.cpylogo7} alt="" loading="lazy"/></div></Link></div>


        <div class="bubble__container"><Link 
                         target="_blank"
                         to={{ 
                          
                           pathname: `/viewgigprofile/${this.state.cpylogo8id}`, 
                            
                         }}><div class="bubble"><img class="bubble__image" src={this.state.cpylogo8} alt="" loading="lazy"/></div></Link></div>

        <div class="bubble__container"><Link 
                         target="_blank"
                         to={{ 
                          
                           pathname: `/viewgigprofile/${this.state.cpylogo9id}`, 
                            
                         }}><div class="bubble"><img class="bubble__image" src={this.state.cpylogo9} alt="" loading="lazy"/></div></Link></div>


        <div class="bubble__container"><Link 
                         target="_blank"
                         to={{ 
                          
                           pathname: `/viewgigprofile/${this.state.cpylogo10id}`, 
                            
                         }}><div class="bubble"><img class="bubble__image" src={this.state.cpylogo10} alt="" loading="lazy"/></div></Link></div>


        <div class="bubble__container"><Link 
                         target="_blank"
                         to={{ 
                          
                           pathname: `/viewgigprofile/${this.state.cpylogo11id}`, 
                            
                         }}><div class="bubble"><img class="bubble__image" src={this.state.cpylogo11} alt="" loading="lazy"/></div></Link></div>


        <div class="bubble__container"><Link 
                         target="_blank"
                         to={{ 
                          
                           pathname: `/viewgigprofile/${this.state.cpylogo12id}`, 
                            
                         }}><div class="bubble"><img class="bubble__image" src={this.state.cpylogo12} alt="" loading="lazy"/></div></Link></div>

        <div class="bubble__container"><Link 
                         target="_blank"
                         to={{ 
                          
                           pathname: `/viewgigprofile/${this.state.cpylogo13id}`, 
                            
                         }}><div class="bubble"><img class="bubble__image" src={this.state.cpylogo13} alt="" loading="lazy"/></div></Link></div>

        <div class="bubble__container"><Link 
                         target="_blank"
                         to={{ 
                          
                           pathname: `/viewgigprofile/${this.state.cpylogo14id}`, 
                            
                         }}><div class="bubble"><img class="bubble__image" src={this.state.cpylogo14} alt="" loading="lazy"/></div></Link></div>

        <div class="bubble__container"><Link 
                         target="_blank"
                         to={{ 
                          
                           pathname: `/viewgigprofile/${this.state.cpylogo15id}`, 
                            
                         }}><div class="bubble"><img class="bubble__image" src={this.state.cpylogo15} alt="" loading="lazy"/></div></Link></div>


        <div class="bubble__container"><Link 
                         target="_blank"
                         to={{ 
                          
                           pathname: `/viewgigprofile/${this.state.cpylogo16id}`, 
                            
                         }}><div class="bubble"><img class="bubble__image" src={this.state.cpylogo16} alt="" loading="lazy"/></div></Link></div>

        <div class="bubble__container"><Link 
                         target="_blank"
                         to={{ 
                          
                           pathname: `/viewgigprofile/${this.state.cpylogo17id}`, 
                            
                         }}><div class="bubble"><img class="bubble__image" src={this.state.cpylogo17} alt="" loading="lazy"/></div></Link></div>

        <div class="bubble__container"><Link 
                         target="_blank"
                         to={{ 
                          
                           pathname: `/viewgigprofile/${this.state.cpylogo18id}`, 
                            
                         }}><div class="bubble"><img class="bubble__image" src={this.state.cpylogo18} alt="" loading="lazy"/></div></Link></div>

        <div class="bubble__container"><Link 
                         target="_blank"
                         to={{ 
                          
                           pathname: `/viewgigprofile/${this.state.cpylogo19id}`, 
                            
                         }}><div class="bubble"><img class="bubble__image" src={this.state.cpylogo19} alt="" loading="lazy"/></div></Link></div>


        <div class="bubble__container"><Link 
                         target="_blank"
                         to={{ 
                          
                           pathname: `/viewgigprofile/${this.state.cpylogo20id}`, 
                            
                         }}><div class="bubble"><img class="bubble__image" src={this.state.cpylogo20} alt="" loading="lazy"/></div></Link></div>
                           <div class="bubble__container"><Link 
                         target="_blank"
                         to={{ 
                          
                           pathname: `/viewgigprofile/${this.state.cpylogo21id}`, 
                            
                         }}><div class="bubble"><img class="bubble__image" src={this.state.cpylogo21} alt="" loading="lazy"/></div></Link></div>

    </div>


</div>

</section>              
        </div>)
    }
}

