import React, { useState } from "react";
import "./modal.css";
import Swal from "sweetalert2";
function close() {
  window.location.reload();
}




export default class AskforRecomm extends React.Component {
  constructor() {
    super();
    let comments = JSON.parse(sessionStorage.getItem("someData"));
    
    console.log(comments);
    this.state = {
      userid: comments,
      addstudata: "category",
      selected: "",
      selectedone: "",
      geomessage: "",
      dropdownlist_input: "rolecategory",
      RoleCategory: [],
     list_id:[1,2,3],
      subcategoryid:1,     
      RoleCategoryType: [],
      RoleCategoryTypelist: [],
      RoleCategoryTypelist_two: [],
      rolecategorytype: "",
      role: 1,
      nodata: "",
      rolecategorytypeid: [],
      opp: "",
      nodataa: "",
      success: "",
      rolecategoryitemname: [],
      RoleCategoryName:[],
      default:false,
      categoryid:1,
      rolecattypeid:1,
      selectid:"",
      SubRoleType:[],
      message:"",
    displaylistone:[],
    displaylisttwo:[],
    displaylistthree:[],
    displaylistfour:[],
    displaylistfive:[],
    displaylistsix:[],
    displaylistseven:[],
    displaylisteight:[],
    displaylistnine:[],
    displaylistten:[],
    displaylisteleven:[],
    displaylisttweleve:[],
    displaylistthirteeen:[],
    displaylistfourteeen:[],
    displaylistfifteen:[],
    displaylistsixteen:[],
    displaylistseventeen:[],
      displaycategory:"",
      getCategoryinfo:[],
      cat_id:0,
      showModal: false,
      keyGen:[],
      selected_data:[],
      db_id:"",
      checked:false,
      deleteGen:[],
      selectedmscreen:"",
      mob_view:0

      

    };
    this.handleClick = this.handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.deleteCategory = this.deleteCategory.bind(this);
    this.checkSomeConditon=this.checkSomeConditon.bind(this);
    this.handleback=this.handleback.bind(this)
    this.handlebackOne=this.handlebackOne.bind(this)
    
  }
  handlebackOne(){
    this.setState({mob_view:1})
  }
  handleback(){
    this.setState({mob_view:0})
  }
  checkSomeConditon(id){
    

      if(this.state.list_id.includes(id)){
        return true
      }
       
      else {
        return false
      }
        
   
   
    
 
 }
  deleteCategory(itemid,userid){
    const deletecat = { userid: userid,rolecategorylistid:itemid };
    fetch("https://www.gigeconome.com:5200/api/deletecategoryitem", {
      method: "POST",
  
      body: JSON.stringify(deletecat), // data can be `string` or {object}!
  
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
  
      .catch((error) => console.error("Error:", error))
  
      .then((response) => {
        
    
    if(response.message=="Role Category Item is Deleted from the list"){
      const fetchcat = { userid: userid};
      fetch("https://www.gigeconome.com:5200/api/getstucategoryinfo", {
        method: "POST",
    
        body: JSON.stringify(fetchcat), // data can be `string` or {object}!
    
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())
    
        .catch((error) => console.error("Error:", error))
    
        .then((response) => {
          
          const data=response.result
     
          
         
        
         data.map(e=>{
          if(e.categoryid==1){
            if(response.message=="Category detail Reterived"){
              response.result.map(e=>{
                if(e.categoryid==1){
                  const data = {
            
                    userid: this.state.userid,
                    categoryid:e.categoryid
                  }
                
                  
                    fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
                      method: "POST",
              
                      body: JSON.stringify(data), // data can be `string` or {object}!
              
                      headers: { "Content-Type": "application/json" },
                    })
                      .then((res) => res.json())
              
                      .catch((error) => console.error("Error:", error))
              
                      .then((response) => {
                        this.setState({displaylistone:response.result})
                     
                        
                      });
                     
                
                }
               
              })
            }
             
                 
               
              
             
           
          }
          else if(e.categoryid==2){
            if(response.message=="Category detail Reterived"){
              response.result.map(e=>{
                if(e.categoryid==2){
                  const data_two = {
            
                    userid: this.state.userid,
                    categoryid:e.categoryid
                  }
                
                  
                    fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
                      method: "POST",
              
                      body: JSON.stringify(data_two), // data can be `string` or {object}!
              
                      headers: { "Content-Type": "application/json" },
                    })
                      .then((res) => res.json())
              
                      .catch((error) => console.error("Error:", error))
              
                      .then((response) => {
                        this.setState({displaylisttwo:response.result})
                     
                        
                      });
                  
                
                }
               
              })
            }
          }
          else if(e.categoryid==3){
            if(response.message=="Category detail Reterived"){
              response.result.map(e=>{
                if(e.categoryid==3){
                  const data_three = {
            
                    userid: this.state.userid,
                    categoryid:e.categoryid
                  }
                
                  
                    fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
                      method: "POST",
              
                      body: JSON.stringify(data_three), // data can be `string` or {object}!
              
                      headers: { "Content-Type": "application/json" },
                    })
                      .then((res) => res.json())
              
                      .catch((error) => console.error("Error:", error))
              
                      .then((response) => {
                        this.setState({displaylistthree:response.result})
                     
                        
                      });
                  
                
                }
               
              })
            }
          }
          else if(e.categoryid==4){
            if(response.message=="Category detail Reterived"){
              response.result.map(e=>{
                if(e.categoryid==4){
                  const data_four = {
            
                    userid: this.state.userid,
                    categoryid:e.categoryid
                  }
                
                  
                    fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
                      method: "POST",
              
                      body: JSON.stringify(data_four), // data can be `string` or {object}!
              
                      headers: { "Content-Type": "application/json" },
                    })
                      .then((res) => res.json())
              
                      .catch((error) => console.error("Error:", error))
              
                      .then((response) => {
                        this.setState({displaylistfour:response.result})
                     
                        
                      });
                  
                
                }
               
              })
            }
          }


          else if(e.categoryid==5){
            if(response.message=="Category detail Reterived"){
              response.result.map(e=>{
                if(e.categoryid==5){
                  const data_five = {
            
                    userid: this.state.userid,
                    categoryid:e.categoryid
                  }
                
                  
                    fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
                      method: "POST",
              
                      body: JSON.stringify(data_five), // data can be `string` or {object}!
              
                      headers: { "Content-Type": "application/json" },
                    })
                      .then((res) => res.json())
              
                      .catch((error) => console.error("Error:", error))
              
                      .then((response) => {
                        this.setState({displaylistfive:response.result})
                     
                        
                      });
                  
                
                }
               
              })
            }
          }

          else if(e.categoryid==6){
            if(response.message=="Category detail Reterived"){
              response.result.map(e=>{
                if(e.categoryid==6){
                  const data_six = {
            
                    userid: this.state.userid,
                    categoryid:e.categoryid
                  }
                
                  
                    fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
                      method: "POST",
              
                      body: JSON.stringify(data_six), // data can be `string` or {object}!
              
                      headers: { "Content-Type": "application/json" },
                    })
                      .then((res) => res.json())
              
                      .catch((error) => console.error("Error:", error))
              
                      .then((response) => {
                        this.setState({displaylistsix:response.result})
                     
                        
                      });
                  
                
                }
               
              })
            }
          }

          else if(e.categoryid==7){
            if(response.message=="Category detail Reterived"){
              response.result.map(e=>{
                if(e.categoryid==7){
                  const data_seven = {
            
                    userid: this.state.userid,
                    categoryid:e.categoryid
                  }
                
                  
                    fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
                      method: "POST",
              
                      body: JSON.stringify(data_seven), // data can be `string` or {object}!
              
                      headers: { "Content-Type": "application/json" },
                    })
                      .then((res) => res.json())
              
                      .catch((error) => console.error("Error:", error))
              
                      .then((response) => {
                        this.setState({displaylistseven:response.result})
                     
                        
                      });
                  
                
                }
               
              })
            }
          }

          else if(e.categoryid==8){
            if(response.message=="Category detail Reterived"){
              response.result.map(e=>{
                if(e.categoryid==8){
                  const data_eight = {
            
                    userid: this.state.userid,
                    categoryid:e.categoryid
                  }
                
                  
                    fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
                      method: "POST",
              
                      body: JSON.stringify(data_eight), // data can be `string` or {object}!
              
                      headers: { "Content-Type": "application/json" },
                    })
                      .then((res) => res.json())
              
                      .catch((error) => console.error("Error:", error))
              
                      .then((response) => {
                        this.setState({displaylisteight:response.result})
                     
                        
                      });
                  
                
                }
               
              })
            }
          }

          else if(e.categoryid==9){
            if(response.message=="Category detail Reterived"){
              response.result.map(e=>{
                if(e.categoryid==9){
                  const data_nine = {
            
                    userid: this.state.userid,
                    categoryid:e.categoryid
                  }
                
                  
                    fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
                      method: "POST",
              
                      body: JSON.stringify(data_nine), // data can be `string` or {object}!
              
                      headers: { "Content-Type": "application/json" },
                    })
                      .then((res) => res.json())
              
                      .catch((error) => console.error("Error:", error))
              
                      .then((response) => {
                        this.setState({displaylistnine:response.result})
                     
                        
                      });
                  
                
                }
               
              })
            }
          }

          else if(e.categoryid==10){
            if(response.message=="Category detail Reterived"){
              response.result.map(e=>{
                if(e.categoryid==10){
                  const data_ten = {
            
                    userid: this.state.userid,
                    categoryid:e.categoryid
                  }
                
                  
                    fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
                      method: "POST",
              
                      body: JSON.stringify(data_ten), // data can be `string` or {object}!
              
                      headers: { "Content-Type": "application/json" },
                    })
                      .then((res) => res.json())
              
                      .catch((error) => console.error("Error:", error))
              
                      .then((response) => {
                        this.setState({displaylistten:response.result})
                     
                        
                      });
                  
                
                }
               
              })
            }
          }

          
          else if(e.categoryid==11){
            if(response.message=="Category detail Reterived"){
              response.result.map(e=>{
                if(e.categoryid==11){
                  const data_eleven = {
            
                    userid: this.state.userid,
                    categoryid:e.categoryid
                  }
                
                  
                    fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
                      method: "POST",
              
                      body: JSON.stringify(data_eleven), // data can be `string` or {object}!
              
                      headers: { "Content-Type": "application/json" },
                    })
                      .then((res) => res.json())
              
                      .catch((error) => console.error("Error:", error))
              
                      .then((response) => {
                        this.setState({displaylisteleven:response.result})
                     
                        
                      });
                  
                
                }
               
              })
            }
          }

          else if(e.categoryid==12){
            if(response.message=="Category detail Reterived"){
              response.result.map(e=>{
                if(e.categoryid==12){
                  const data_tweleve = {
            
                    userid: this.state.userid,
                    categoryid:e.categoryid
                  }
                
                  
                    fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
                      method: "POST",
              
                      body: JSON.stringify(data_tweleve), // data can be `string` or {object}!
              
                      headers: { "Content-Type": "application/json" },
                    })
                      .then((res) => res.json())
              
                      .catch((error) => console.error("Error:", error))
              
                      .then((response) => {
                        this.setState({displaylisttweleve:response.result})
                     
                        
                      });
                  
                
                }
               
              })
            }
          }

          else if(e.categoryid==13){
            if(response.message=="Category detail Reterived"){
              response.result.map(e=>{
                if(e.categoryid==13){
                  const data_thirteen = {
            
                    userid: this.state.userid,
                    categoryid:e.categoryid
                  }
                
                  
                    fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
                      method: "POST",
              
                      body: JSON.stringify(data_thirteen), // data can be `string` or {object}!
              
                      headers: { "Content-Type": "application/json" },
                    })
                      .then((res) => res.json())
              
                      .catch((error) => console.error("Error:", error))
              
                      .then((response) => {
                        this.setState({displaylistthirteeen:response.result})
                     
                        
                      });
                  
                
                }
               
              })
            }
          }

          else if(e.categoryid==14){
            if(response.message=="Category detail Reterived"){
              response.result.map(e=>{
                if(e.categoryid==14){
                  const data_fourteen = {
            
                    userid: this.state.userid,
                    categoryid:e.categoryid
                  }
                
                  
                    fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
                      method: "POST",
              
                      body: JSON.stringify(data_fourteen), // data can be `string` or {object}!
              
                      headers: { "Content-Type": "application/json" },
                    })
                      .then((res) => res.json())
              
                      .catch((error) => console.error("Error:", error))
              
                      .then((response) => {
                        this.setState({displaylistfourteeen:response.result})
                     
                        
                      });
                  
                
                }
               
              })
            }
          }

          
          else if(e.categoryid==15){
            if(response.message=="Category detail Reterived"){
              response.result.map(e=>{
                if(e.categoryid==15){
                  const data_fifteen = {
            
                    userid: this.state.userid,
                    categoryid:e.categoryid
                  }
                
                  
                    fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
                      method: "POST",
              
                      body: JSON.stringify(data_fifteen), // data can be `string` or {object}!
              
                      headers: { "Content-Type": "application/json" },
                    })
                      .then((res) => res.json())
              
                      .catch((error) => console.error("Error:", error))
              
                      .then((response) => {
                        this.setState({displaylistfifteen:response.result})
                     
                        
                      });
                  
                
                }
               
              })
            }
          }

          else if(e.categoryid==16){
            if(response.message=="Category detail Reterived"){
              response.result.map(e=>{
                if(e.categoryid==16){
                  const data_sixteen = {
            
                    userid: this.state.userid,
                    categoryid:e.categoryid
                  }
                
                  
                    fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
                      method: "POST",
              
                      body: JSON.stringify(data_sixteen), // data can be `string` or {object}!
              
                      headers: { "Content-Type": "application/json" },
                    })
                      .then((res) => res.json())
              
                      .catch((error) => console.error("Error:", error))
              
                      .then((response) => {
                        this.setState({displaylistsixteen:response.result})
                     
                        
                      });
                  
                
                }
               
              })
            }
          }

          else if(e.categoryid==17){
            if(response.message=="Category detail Reterived"){
              response.result.map(e=>{
                if(e.categoryid==17){
                  const data_seventeen = {
            
                    userid: this.state.userid,
                    categoryid:e.categoryid
                  }
                
                  
                    fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
                      method: "POST",
              
                      body: JSON.stringify(data_seventeen), // data can be `string` or {object}!
              
                      headers: { "Content-Type": "application/json" },
                    })
                      .then((res) => res.json())
              
                      .catch((error) => console.error("Error:", error))
              
                      .then((response) => {
                        this.setState({displaylistseventeen:response.result})
                     
                        
                      });
                  
                
                }
               
              })
            }
          }
        
         })
       
           
  
       
     this.setState({RoleCategoryName:response.result})
           
           
        });
    }
      
    
      
     
       
      });
    }
  handleClick(categoryid) {
    this.setState({categoryid:1,subcategoryid:1,mob_view:1})
    if (categoryid==1) {
      const data_eight = {
        categoryid: categoryid,
      };
      fetch("https://www.gigeconome.com:5200/api/getrolecategorytype", {
        method: "POST",

        body: JSON.stringify(data_eight), // data can be `string` or {object}!

        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())

        .catch((error) => console.error("Error:", error))

        .then((posRes) => {

          console.log(posRes);

          if (posRes.message == "Retrived Role Category Type details.") {
            this.setState({ RoleCategoryType: posRes.result, nodata: "",nodataa: "No Data Found" });
          
            const rolecattypedata = {
              rolecategorytypeid: this.state.rolecattypeid,
            };
            fetch("https://www.gigeconome.com:5200/api/getrolecategorylist", {
              method: "POST",
      
              body: JSON.stringify(rolecattypedata), // data can be `string` or {object}!
      
              headers: { "Content-Type": "application/json" },
            })
              .then((res) => res.json())
      
              .catch((error) => console.error("Error:", error))
      
              .then((posRes) => {
                console.log(posRes);
                if(posRes.message=="Data is Not Avaialble"){
                  console.log("Empty")
                }
                else{
                  this.setState({categoryid:posRes.categoryid})
                  this.setState({db_id:posRes.subcategoryid}, () => {
                    
        
                  });
                  const db_category = {
                    userid:this.state.userid,
                    subcategoryid:this.state.db_id
                  };
                  fetch("https://www.gigeconome.com:5200/api/getstuselecteditems", {
                    method: "POST",
                  
                    body: JSON.stringify(db_category), // data can be `string` or {object}!
                  
                    headers: { "Content-Type": "application/json" },
                  })
                    .then((res) => res.json())
                  
                    .catch((error) => console.error("Error:", error))
                  
                    .then((posRes) => {
                      console.log(posRes);
                      this.setState({selected_data:[]})
                      if(posRes.message=="No Data found"){
                        //console.log(posRes.message)
                        this.setState({selected_data:[]})
                      }
                   
                      else{
                        posRes.result.map(e=>{
                          this.setState({selected_data:this.state.selected_data.concat([e.itemid])}, () => {
                          });
      
                        })
                        var count = -1
                        this.state.RoleCategoryTypelist.map(e=>{
                          console.log(e)
                          count += 1 
                         if(this.state.selected_data.includes(e.rolecattypelistid)) {
                          let items = [...this.state.RoleCategoryTypelist];
                          let item = {...items[count]};
                          item.isChecked = 1 ;
                          items[count] = item;
                          this.setState({RoleCategoryTypelist:items})
                          console.log(this.state.RoleCategoryTypelist)
                         }
                        })
                        //this.state.selected_data.includes(this.state.RoleCategoryTypelist)
                      }
                      console.log(this.state.selected_data,);
                      //this.setState({ RoleCategory: posRes.result });
                    });
                }
               // alert(posRes.subcategoryid)
                //this.setState({nodata:"No Data Found"})
                this.setState({ RoleCategoryTypelist: posRes.result,selectedone:1, nodataa: "" });
              });
          }
        });
    }
   else if (categoryid) {
      const data_three = {
        categoryid: categoryid,
      };
      fetch("https://www.gigeconome.com:5200/api/getrolecategorytype", {
        method: "POST",

        body: JSON.stringify(data_three), // data can be `string` or {object}!

        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())

        .catch((error) => console.error("Error:", error))

        .then((posRes) => {
          console.log(posRes);
this.setState({db_id:posRes.subcategoryid})
          if (posRes.message == "Retrived Role Category Type details.") {
            this.setState({ RoleCategoryType: posRes.result, nodata: "",nodataa: "No Data Found" });

           
        

          }
        });
    } else {
      this.setState({ nodata: "No data found", nodataa: "No Data Found" });
    }
    this.setState({ selected: categoryid });
  }

  handleClickone(rolecattypeid) {
    this.setState({mob_view:2})
    //alert(rolecattypeid)
    if (rolecattypeid) {
      const data_three = {
        rolecategorytypeid: rolecattypeid,
      };
      fetch("https://www.gigeconome.com:5200/api/getrolecategorylist", {
        method: "POST",

        body: JSON.stringify(data_three), // data can be `string` or {object}!

        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())

        .catch((error) => console.error("Error:", error))

        .then((posRes) => {
          console.log(posRes);
          if(posRes.message=="Data is Not Avaialble"){
            console.log("Empty")
          }
          else{
            this.setState({categoryid:posRes.categoryid})
            this.setState({db_id:posRes.subcategoryid}, () => {
              
  
            });
            const db_category = {
              userid:this.state.userid,
              subcategoryid:this.state.db_id
            };
            fetch("https://www.gigeconome.com:5200/api/getstuselecteditems", {
              method: "POST",
            
              body: JSON.stringify(db_category), // data can be `string` or {object}!
            
              headers: { "Content-Type": "application/json" },
            })
              .then((res) => res.json())
            
              .catch((error) => console.error("Error:", error))
            
              .then((posRes) => {
                console.log(posRes);
                this.setState({selected_data:[]})
                if(posRes.message=="No Data found"){
                  //console.log(posRes.message)
                  this.setState({selected_data:[]})
                }
             
                else{
                  posRes.result.map(e=>{
                    this.setState({selected_data:this.state.selected_data.concat([e.itemid])}, () => {
                    });

                  })
                  var count = -1
                  this.state.RoleCategoryTypelist.map(e=>{
                    console.log(e)
                    count += 1 
                   if(this.state.selected_data.includes(e.rolecattypelistid)) {
                    let items = [...this.state.RoleCategoryTypelist];
                    let item = {...items[count]};
                    item.isChecked = 1 ;
                    items[count] = item;
                    this.setState({RoleCategoryTypelist:items})
                    console.log(this.state.RoleCategoryTypelist)
                   }
                  })
                  //this.state.selected_data.includes(this.state.RoleCategoryTypelist)
                }
                this.setState({keyGen:this.state.selected_data})
                console.log(this.state.keyGen,);
                //this.setState({ RoleCategory: posRes.result });
              });
          }
         
          //alert(posRes.subcategoryid)
          if(posRes.message=="Data is Not Avaialble"){
            this.setState({ RoleCategoryTypelist:[], nodataa: "No Data Found" });
          }
          else{
            this.setState({subcategoryid:posRes.subcategoryid,
              categoryid:posRes.categoryid
            
            })
            //this.setState({nodata:"No Data Found"})
            this.setState({ RoleCategoryTypelist: posRes.result,nodataa:"" });
          }
         
          
        });
        
    }
   
    else{
      this.setState({ RoleCategoryTypelist:[], nodataa: "No Data Found" });
    }
    this.setState({ selectedone: rolecattypeid });
  }
  componentDidMount() {

   






    const data_two = {
      dropdownlist_input: this.state.dropdownlist_input,
    };
    fetch("https://www.gigeconome.com:5200/api/getdropdownlist", {
      method: "POST",

      body: JSON.stringify(data_two), // data can be `string` or {object}!

      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())

      .catch((error) => console.error("Error:", error))

      .then((posRes) => {
        console.log(posRes);
        
        this.setState({ RoleCategory: posRes.result });
      });


      const cat_data = {
        categoryid: this.state.categoryid,
      };
      fetch("https://www.gigeconome.com:5200/api/getrolecategorytype", {
        method: "POST",

        body: JSON.stringify(cat_data), // data can be `string` or {object}!

        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())

        .catch((error) => console.error("Error:", error))

        .then((posRes) => {
          console.log(posRes);

          if (posRes.message == "Retrived Role Category Type details.") {
            this.setState({ RoleCategoryType: posRes.result,selected:1,nodata:"" });
          }
        });


        const rolecattypedata = {
          rolecategorytypeid: this.state.rolecattypeid,
        };
        fetch("https://www.gigeconome.com:5200/api/getrolecategorylist", {
          method: "POST",
  
          body: JSON.stringify(rolecattypedata), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((posRes) => {
            console.log(posRes);
            if(posRes.message=="Data is Not Avaialble"){
              console.log("Empty")
            }
            else{
              this.setState({categoryid:posRes.categoryid})
              this.setState({db_id:posRes.subcategoryid}, () => {
                
    
              });
              const db_category = {
                userid:this.state.userid,
                subcategoryid:this.state.db_id
              };
              fetch("https://www.gigeconome.com:5200/api/getstuselecteditems", {
                method: "POST",
              
                body: JSON.stringify(db_category), // data can be `string` or {object}!
              
                headers: { "Content-Type": "application/json" },
              })
                .then((res) => res.json())
              
                .catch((error) => console.error("Error:", error))
              
                .then((posRes) => {
                  console.log(posRes);
                  this.setState({selected_data:[]})
                  if(posRes.message=="No Data found"){
                    //console.log(posRes.message)
                    this.setState({selected_data:[]})
                  }
               
                  else{
                    posRes.result.map(e=>{
                      this.setState({selected_data:this.state.selected_data.concat([e.itemid])}, () => {
                      });
  
                    })
                    var count = -1
                    this.state.RoleCategoryTypelist.map(e=>{
                      console.log(e)
                      count += 1 
                     if(this.state.selected_data.includes(e.rolecattypelistid)) {
                      let items = [...this.state.RoleCategoryTypelist];
                      let item = {...items[count]};
                      item.isChecked = 1 ;
                      items[count] = item;
                      this.setState({RoleCategoryTypelist:items})
                      console.log(this.state.RoleCategoryTypelist)
                     }
                    })
                    //this.state.selected_data.includes(this.state.RoleCategoryTypelist)
                  }
                  this.setState({keyGen:this.state.selected_data})
                  console.log(this.state.keyGen,);
                  //this.setState({ RoleCategory: posRes.result });
                });
            }
            //this.setState({nodata:"No Data Found"})
            this.setState({ RoleCategoryTypelist: posRes.result,selectedone:1, nodataa: "" });
          });


          const getcategory = {
            userid: this.state.userid,
          };
          fetch("https://www.gigeconome.com:5200/api/getstucategoryinfo", {
            method: "POST",
    
            body: JSON.stringify(getcategory), // data can be `string` or {object}!
    
            headers: { "Content-Type": "application/json" },
          })
            .then((res) => res.json())
    
            .catch((error) => console.error("Error:", error))
    
            .then((posRes) => {
              console.log(posRes);
    
              //this.setState({nodata:"No Data Found"})
              this.setState({ getCategoryinfo:posRes.result,cat_id:1 });
            });
  



  }

  handleSubmit = (event) => {
    event.preventDefault();
    const dashopp = this.state.opp.toString();
    console.log(dashopp);

    const checkdata = {
      userid: this.state.userid,
      addstudata: this.state.addstudata,
      rolecategorytypeid: dashopp,
    };

    let BaseUrl = JSON.parse(sessionStorage.getItem("baseurl"));

    fetch( "https://www.gigeconome.com:5200/api/addstu_profiledata", {
      method: "POST",

      body: JSON.stringify(checkdata), // data can be `string` or {object}!

      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())

      .catch((error) => console.error("Error:", error))

      .then((response) => {
        console.log(response);
        this.setState({ success: response.message })
        // window.location.reload();
        if (
          response.message ==
          "Data has been Added successfully."
        ) {
          const fetchdata = { userid: this.state.userid };

          fetch( "https://www.gigeconome.com:5200/api/getstucategoryinfo", {
            method: "POST",

            body: JSON.stringify(fetchdata), // data can be `string` or {object}!

            headers: { "Content-Type": "application/json" },
          })
            .then((res) => res.json())

            .catch((error) => console.error("Error:", error))

            .then((response) => {
              this.setState({message:response.message})
           
              this.setState({rolecategoryitemname: response.result})
           
if(response.message=="Category detail Reterived"){
  response.result.map(e=>{
    if(e.categoryid==1){
      const data = {

        userid: this.state.userid,
        categoryid:e.categoryid
      }
    
      
        fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
          method: "POST",
  
          body: JSON.stringify(data), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
            this.setState({displaylistone:response.result})
         
            
          });
      
    
    }
    else if(e.categoryid==2){
      const data_two = {

        userid: this.state.userid,
        categoryid:e.categoryid
      }
    
      
        fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
          method: "POST",
  
          body: JSON.stringify(data_two), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
            this.setState({displaylisttwo:response.result})
         
            
          });
      
    
    }
    else if(e.categoryid==3){
      const data_three = {

        userid: this.state.userid,
        categoryid:e.categoryid
      }
    
      
        fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
          method: "POST",
  
          body: JSON.stringify(data_three), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
            this.setState({displaylistthree:response.result})
         
            
          });
      
    
    }

    else if(e.categoryid==4){
      const data_four = {

        userid: this.state.userid,
        categoryid:e.categoryid
      }
    
      
        fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
          method: "POST",
  
          body: JSON.stringify(data_four), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
            this.setState({displaylistfour:response.result})
         
            
          });
      
    
    }

    else if(e.categoryid==5){
      const data_five = {

        userid: this.state.userid,
        categoryid:e.categoryid
      }
    
      
        fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
          method: "POST",
  
          body: JSON.stringify(data_five), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
            this.setState({displaylistfive:response.result})
         
            
          });
      
    
    }

    else if(e.categoryid==6){
      const data_six = {

        userid: this.state.userid,
        categoryid:e.categoryid
      }
    
      
        fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
          method: "POST",
  
          body: JSON.stringify(data_six), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
            this.setState({displaylistsix:response.result})
         
            
          });
      
    
    }

    else if(e.categoryid==7){
      const data_seven = {

        userid: this.state.userid,
        categoryid:e.categoryid
      }
    
      
        fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
          method: "POST",
  
          body: JSON.stringify(data_seven), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
            this.setState({displaylistseven:response.result})
         
            
          });
      
    
    }

    else if(e.categoryid==8){
      const data_eight = {

        userid: this.state.userid,
        categoryid:e.categoryid
      }
    
      
        fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
          method: "POST",
  
          body: JSON.stringify(data_eight), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
            this.setState({displaylisteight:response.result})
         
            
          });
      
    
    }
    else if(e.categoryid==9){
      const data_nine = {

        userid: this.state.userid,
        categoryid:e.categoryid
      }
    
      
        fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
          method: "POST",
  
          body: JSON.stringify(data_nine), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
            this.setState({displaylistnine:response.result})
         
            
          });
      
    
    }
    else if(e.categoryid==10){
      const data_ten = {

        userid: this.state.userid,
        categoryid:e.categoryid
      }
    
      
        fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
          method: "POST",
  
          body: JSON.stringify(data_ten), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
            this.setState({displaylistten:response.result})
         
            
          });
      
    
    }
    else if(e.categoryid==11){
      const data_eleven = {

        userid: this.state.userid,
        categoryid:e.categoryid
      }
    
      
        fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
          method: "POST",
  
          body: JSON.stringify(data_eleven), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
            this.setState({displaylisteleven:response.result})
         
            
          });
      
    
    }

    else if(e.categoryid==12){
      const data_tweleve = {

        userid: this.state.userid,
        categoryid:e.categoryid
      }
    
      
        fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
          method: "POST",
  
          body: JSON.stringify(data_tweleve), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
            this.setState({displaylisttweleve:response.result})
         
            
          });
      
    
    }

    else if(e.categoryid==13){
      const data_thirteen = {

        userid: this.state.userid,
        categoryid:e.categoryid
      }
    
      
        fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
          method: "POST",
  
          body: JSON.stringify(data_thirteen), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
            this.setState({displaylistthirteeen:response.result})
         
            
          });
      
    
    }

    else if(e.categoryid==14){
      const data_fourteen = {

        userid: this.state.userid,
        categoryid:e.categoryid
      }
    
      
        fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
          method: "POST",
  
          body: JSON.stringify(data_fourteen), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
            this.setState({displaylistfourteeen:response.result})
         
            
          });
      
    
    }
    else if(e.categoryid==15){
      const data_fifteen = {

        userid: this.state.userid,
        categoryid:e.categoryid
      }
    
      
        fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
          method: "POST",
  
          body: JSON.stringify(data_fifteen), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
            this.setState({displaylistfifteen:response.result})
         
            
          });
      
    
    }

    else if(e.categoryid==16){
      const data_sixteen = {

        userid: this.state.userid,
        categoryid:e.categoryid
      }
    
      
        fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
          method: "POST",
  
          body: JSON.stringify(data_sixteen), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
            this.setState({displaylistsixteen:response.result})
         
            
          });
      
    
    }

    else if(e.categoryid==17){
      const data_seventeen = {

        userid: this.state.userid,
        categoryid:e.categoryid
      }
    
      
        fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
          method: "POST",
  
          body: JSON.stringify(data_seventeen), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
            this.setState({displaylistseventeen:response.result})
         
            
          });
      
    
    }
  })

  
}


         



                // data.map(e=>{
                   //if(this.state.cateory_id.includes(e.id)){
            // e.subcategory.map(se=>{
                      //this.setState({rolecategoryitemname:[...this.state.rolecategoryitemname,se.subcategoryname]})
                      
                      {/*se.subcategoryitem.map(sse=>{
                        this.setState({changeSub:{itemid:sse.itemid,itemname:sse.itemname}})

                        this.setState({ rolecategoryitemname:[...this.state. rolecategoryitemname,({[e.id]:[this.state.changeSub]})]})
                       
                        console.log(Object.values(this.state.rolecategoryitemname))
                      }) */}
                     {/*const obj = [...new Map(this.state.rolecategoryitemname.map(item => [JSON.stringify(item), item])).values()];
                      this.setState({rolecategoryitemname:obj})
                      console.log(obj) */} 
                 //   })
                    
                  // }
                     

                   
                    // else{
                    //   console.log("Please Chhose")
//}
    
                 // })

                 
                 
                
               
             
              
                  
         
              
            this.setState({RoleCategoryName:response.result})
            });
        }
      });
  };
deletecatOne (subcategoryid,categoryid,itemid){

  this.setState({ keyGen : this.state.keyGen.filter(function(val) {return val!==itemid})}, () => {
    const data_category = {

      userid: this.state.userid,
      itemids:this.state.keyGen.toString(),
      addstudata:"stuaddcatitems",
      subcategoryid:subcategoryid,
      categoryid:categoryid
      
    }
    console.log(data_category)
    fetch( "https://www.gigeconome.com:5200/api/addstu_profiledata", {
            method: "POST",
      
            body: JSON.stringify(data_category), // data can be `string` or {object}!
      
            headers: { "Content-Type": "application/json" },
          })
            .then((res) => res.json())
      
            .catch((error) => console.error("Error:", error))
      
            .then((response) => {
              console.log(response)
              if(response.message=="No user found"){
                this.setState({RoleCategory:[]})
              }
              else{
              const fetchdata = { userid: this.state.userid };

              fetch( "https://www.gigeconome.com:5200/api/getstucategoryinfo", {
                method: "POST",
    
                body: JSON.stringify(fetchdata), // data can be `string` or {object}!
    
                headers: { "Content-Type": "application/json" },
              })
                .then((res) => res.json())
    
                .catch((error) => console.error("Error:", error))
    
                .then((response) => {
                  this.setState({message:response.message})
                  if(response.status==0){
                    const data = {
                
                      rolecategorytypeid:subcategoryid
                    }
                  
                    
                      fetch( "https://www.gigeconome.com:5200/api/getrolecategorylist", {
                        method: "POST",
                
                        body: JSON.stringify(data), // data can be `string` or {object}!
                
                        headers: { "Content-Type": "application/json" },
                      })
                        .then((res) => res.json())
                
                        .catch((error) => console.error("Error:", error))
                
                        .then((response) => {
                          this.setState({ RoleCategoryTypelist: response.result,selectedone:1, nodataa: "" });
                       
                          
                        });
                    
                        this.setState({rolecategoryitemname:[]})

                  }
                  else{

                  
                  this.setState({rolecategoryitemname: response.result})
                  response.result.map(e=>{
                    if(e.categoryid==1){
                      const data = {
                
                        userid: this.state.userid,
                        categoryid:e.categoryid
                      }
                    
                      
                        fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
                          method: "POST",
                  
                          body: JSON.stringify(data), // data can be `string` or {object}!
                  
                          headers: { "Content-Type": "application/json" },
                        })
                          .then((res) => res.json())
                  
                          .catch((error) => console.error("Error:", error))
                  
                          .then((response) => {
                            this.setState({displaylistone:response.result})
                         
                            
                          });
                    }
                   else if(categoryid==2){
                    const data = {
                
                      userid: this.state.userid,
                      categoryid:e.categoryid
                    }
                  
                    
                      fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
                        method: "POST",
                
                        body: JSON.stringify(data), // data can be `string` or {object}!
                
                        headers: { "Content-Type": "application/json" },
                      })
                        .then((res) => res.json())
                
                        .catch((error) => console.error("Error:", error))
                
                        .then((response) => {
                          this.setState({displaylisttwo:response.result})
                       
                          
                        });
                   }
                   else if(categoryid==3){
                    const data = {
                
                      userid: this.state.userid,
                      categoryid:e.categoryid
                    }
                  
                    
                      fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
                        method: "POST",
                
                        body: JSON.stringify(data), // data can be `string` or {object}!
                
                        headers: { "Content-Type": "application/json" },
                      })
                        .then((res) => res.json())
                
                        .catch((error) => console.error("Error:", error))
                
                        .then((response) => {
                          this.setState({displaylistthree:response.result})
                       
                          
                        });
                   }
                   else if(categoryid==4){
                    const data = {
                
                      userid: this.state.userid,
                      categoryid:e.categoryid
                    }
                  
                    
                      fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
                        method: "POST",
                
                        body: JSON.stringify(data), // data can be `string` or {object}!
                
                        headers: { "Content-Type": "application/json" },
                      })
                        .then((res) => res.json())
                
                        .catch((error) => console.error("Error:", error))
                
                        .then((response) => {
                          this.setState({displaylistfour:response.result})
                       
                          
                        });
                   }
                   else if(categoryid==5){
                    const data = {
                
                      userid: this.state.userid,
                      categoryid:e.categoryid
                    }
                  
                    
                      fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
                        method: "POST",
                
                        body: JSON.stringify(data), // data can be `string` or {object}!
                
                        headers: { "Content-Type": "application/json" },
                      })
                        .then((res) => res.json())
                
                        .catch((error) => console.error("Error:", error))
                
                        .then((response) => {
                          this.setState({displaylistfive:response.result})
                       
                          
                        });
                   }
                   else if(categoryid==6){
                    const data = {
                
                      userid: this.state.userid,
                      categoryid:e.categoryid
                    }
                  
                    
                      fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
                        method: "POST",
                
                        body: JSON.stringify(data), // data can be `string` or {object}!
                
                        headers: { "Content-Type": "application/json" },
                      })
                        .then((res) => res.json())
                
                        .catch((error) => console.error("Error:", error))
                
                        .then((response) => {
                          this.setState({displaylistsix:response.result})
                       
                          
                        });
                   }
                   else if(categoryid==7){
                    const data = {
                
                      userid: this.state.userid,
                      categoryid:e.categoryid
                    }
                  
                    
                      fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
                        method: "POST",
                
                        body: JSON.stringify(data), // data can be `string` or {object}!
                
                        headers: { "Content-Type": "application/json" },
                      })
                        .then((res) => res.json())
                
                        .catch((error) => console.error("Error:", error))
                
                        .then((response) => {
                          this.setState({displaylistseven:response.result})
                       
                          
                        });
                   }
                   else if(categoryid==7){
                    const data = {
                
                      userid: this.state.userid,
                      categoryid:e.categoryid
                    }
                  
                    
                      fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
                        method: "POST",
                
                        body: JSON.stringify(data), // data can be `string` or {object}!
                
                        headers: { "Content-Type": "application/json" },
                      })
                        .then((res) => res.json())
                
                        .catch((error) => console.error("Error:", error))
                
                        .then((response) => {
                          this.setState({displaylistseven:response.result})
                       
                          
                        });
                   }
                   else if(categoryid==8){
                    const data = {
                
                      userid: this.state.userid,
                      categoryid:e.categoryid
                    }
                  
                    
                      fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
                        method: "POST",
                
                        body: JSON.stringify(data), // data can be `string` or {object}!
                
                        headers: { "Content-Type": "application/json" },
                      })
                        .then((res) => res.json())
                
                        .catch((error) => console.error("Error:", error))
                
                        .then((response) => {
                          this.setState({displaylisteight:response.result})
                       
                          
                        });
                   }
                   else if(categoryid==9){
                    const data = {
                
                      userid: this.state.userid,
                      categoryid:e.categoryid
                    }
                  
                    
                      fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
                        method: "POST",
                
                        body: JSON.stringify(data), // data can be `string` or {object}!
                
                        headers: { "Content-Type": "application/json" },
                      })
                        .then((res) => res.json())
                
                        .catch((error) => console.error("Error:", error))
                
                        .then((response) => {
                          this.setState({displaylistnine:response.result})
                       
                          
                        });
                   }
                   else if(categoryid==10){
                    const data = {
                
                      userid: this.state.userid,
                      categoryid:e.categoryid
                    }
                  
                    
                      fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
                        method: "POST",
                
                        body: JSON.stringify(data), // data can be `string` or {object}!
                
                        headers: { "Content-Type": "application/json" },
                      })
                        .then((res) => res.json())
                
                        .catch((error) => console.error("Error:", error))
                
                        .then((response) => {
                          this.setState({displaylistten:response.result})
                       
                          
                        });
                   }
                   else if(categoryid==11){
                    const data = {
                
                      userid: this.state.userid,
                      categoryid:e.categoryid
                    }
                  
                    
                      fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
                        method: "POST",
                
                        body: JSON.stringify(data), // data can be `string` or {object}!
                
                        headers: { "Content-Type": "application/json" },
                      })
                        .then((res) => res.json())
                
                        .catch((error) => console.error("Error:", error))
                
                        .then((response) => {
                          this.setState({displaylisteleven:response.result})
                       
                          
                        });
                   }
                   else if(categoryid==12){
                    const data = {
                
                      userid: this.state.userid,
                      categoryid:e.categoryid
                    }
                  
                    
                      fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
                        method: "POST",
                
                        body: JSON.stringify(data), // data can be `string` or {object}!
                
                        headers: { "Content-Type": "application/json" },
                      })
                        .then((res) => res.json())
                
                        .catch((error) => console.error("Error:", error))
                
                        .then((response) => {
                          this.setState({displaylisttweleve:response.result})
                       
                          
                        });
                   }
                   else if(categoryid==13){
                    const data = {
                
                      userid: this.state.userid,
                      categoryid:e.categoryid
                    }
                  
                    
                      fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
                        method: "POST",
                
                        body: JSON.stringify(data), // data can be `string` or {object}!
                
                        headers: { "Content-Type": "application/json" },
                      })
                        .then((res) => res.json())
                
                        .catch((error) => console.error("Error:", error))
                
                        .then((response) => {
                          this.setState({displaylistthirteeen:response.result})
                       
                          
                        });
                   }
                   else if(categoryid==14){
                    const data = {
                
                      userid: this.state.userid,
                      categoryid:e.categoryid
                    }
                  
                    
                      fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
                        method: "POST",
                
                        body: JSON.stringify(data), // data can be `string` or {object}!
                
                        headers: { "Content-Type": "application/json" },
                      })
                        .then((res) => res.json())
                
                        .catch((error) => console.error("Error:", error))
                
                        .then((response) => {
                          this.setState({displaylistfourteeen:response.result})
                       
                          
                        });
                   }
                   else if(categoryid==15){
                    const data = {
                
                      userid: this.state.userid,
                      categoryid:e.categoryid
                    }
                  
                    
                      fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
                        method: "POST",
                
                        body: JSON.stringify(data), // data can be `string` or {object}!
                
                        headers: { "Content-Type": "application/json" },
                      })
                        .then((res) => res.json())
                
                        .catch((error) => console.error("Error:", error))
                
                        .then((response) => {
                          this.setState({displaylistfifteen:response.result})
                       
                          
                        });
                   }
                   else if(categoryid==16){
                    const data = {
                
                      userid: this.state.userid,
                      categoryid:e.categoryid
                    }
                  
                    
                      fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
                        method: "POST",
                
                        body: JSON.stringify(data), // data can be `string` or {object}!
                
                        headers: { "Content-Type": "application/json" },
                      })
                        .then((res) => res.json())
                
                        .catch((error) => console.error("Error:", error))
                
                        .then((response) => {
                          this.setState({displaylistsixteen:response.result})
                       
                          
                        });
                   }
                   else if(categoryid==17){
                    const data = {
                
                      userid: this.state.userid,
                      categoryid:e.categoryid
                    }
                  
                    
                      fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
                        method: "POST",
                
                        body: JSON.stringify(data), // data can be `string` or {object}!
                
                        headers: { "Content-Type": "application/json" },
                      })
                        .then((res) => res.json())
                
                        .catch((error) => console.error("Error:", error))
                
                        .then((response) => {
                          this.setState({displaylistseventeen:response.result})
                       
                          
                        });
                   }
                  })
                      
                }      
                        
                              
                            
                            
                          }
                )            
            }
          }
            )
  })
}

updateStateList(e, value,isChecked,index){
    console.log(value, isChecked,index)

    let items = [...this.state.RoleCategoryTypelist];
    let item = {...items[index]};
    item.isChecked = !isChecked ;
    items[index] = item;
    this.setState({RoleCategoryTypelist:items});
    console.log(this.state.RoleCategoryTypelist);

        if (e.target.checked){
      //append to array
          console.log(e.target.checked)
      this.setState({ keyGen: this.state.keyGen.concat([value])}, () => {
        const data_category = {

          userid: this.state.userid,
          itemids:this.state.keyGen.toString(),
          addstudata:"stuaddcatitems",
          subcategoryid:this.state.subcategoryid,
          categoryid:this.state.categoryid
          
        }
      console.log(data_category)
        
          fetch( "https://www.gigeconome.com:5200/api/addstu_profiledata", {
            method: "POST",
      
            body: JSON.stringify(data_category), // data can be `string` or {object}!
      
            headers: { "Content-Type": "application/json" },
          })
            .then((res) => res.json())
      
            .catch((error) => console.error("Error:", error))
      
            .then((response) => {
              console.log(response)
              const fetchdata = { userid: this.state.userid };

          fetch( "https://www.gigeconome.com:5200/api/getstucategoryinfo", {
            method: "POST",

            body: JSON.stringify(fetchdata), // data can be `string` or {object}!

            headers: { "Content-Type": "application/json" },
          })
            .then((res) => res.json())

            .catch((error) => console.error("Error:", error))

            .then((response) => {
              this.setState({message:response.message})
           
              this.setState({rolecategoryitemname: response.result})
              if(response.message=="Category detail Reterived"){

                response.result.map(e=>{
                  if(e.categoryid==1){
                    const data = {
              
                      userid: this.state.userid,
                      categoryid:e.categoryid
                    }
                  
                    
                      fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
                        method: "POST",
                
                        body: JSON.stringify(data), // data can be `string` or {object}!
                
                        headers: { "Content-Type": "application/json" },
                      })
                        .then((res) => res.json())
                
                        .catch((error) => console.error("Error:", error))
                
                        .then((response) => {
                          this.setState({displaylistone:response.result})
                       
                          
                        });
                        
                      }
                
                      else if(e.categoryid==2){
                        const data = {
                  
                          userid: this.state.userid,
                          categoryid:e.categoryid
                        }
                      
                        
                          fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
                            method: "POST",
                    
                            body: JSON.stringify(data), // data can be `string` or {object}!
                    
                            headers: { "Content-Type": "application/json" },
                          })
                            .then((res) => res.json())
                    
                            .catch((error) => console.error("Error:", error))
                    
                            .then((response) => {
                              this.setState({displaylisttwo:response.result})
                           
                              
                            });
                            
                          }
                
                          else if(e.categoryid==3){
                            const data = {
                      
                              userid: this.state.userid,
                              categoryid:e.categoryid
                            }
                          
                            
                              fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
                                method: "POST",
                        
                                body: JSON.stringify(data), // data can be `string` or {object}!
                        
                                headers: { "Content-Type": "application/json" },
                              })
                                .then((res) => res.json())
                        
                                .catch((error) => console.error("Error:", error))
                        
                                .then((response) => {
                                  this.setState({displaylistthree:response.result})
                               
                                  
                                });
                                
                              }
                    
                
                              else if(e.categoryid==4){
                                const data = {
                          
                                  userid: this.state.userid,
                                  categoryid:e.categoryid
                                }
                              
                                
                                  fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
                                    method: "POST",
                            
                                    body: JSON.stringify(data), // data can be `string` or {object}!
                            
                                    headers: { "Content-Type": "application/json" },
                                  })
                                    .then((res) => res.json())
                            
                                    .catch((error) => console.error("Error:", error))
                            
                                    .then((response) => {
                                      this.setState({displaylistfour:response.result})
                                   
                                      
                                    });
                                    
                                  }
                        
                    
                
                
                                  else if(e.categoryid==5){
                                    const data = {
                              
                                      userid: this.state.userid,
                                      categoryid:e.categoryid
                                    }
                                  
                                    
                                      fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
                                        method: "POST",
                                
                                        body: JSON.stringify(data), // data can be `string` or {object}!
                                
                                        headers: { "Content-Type": "application/json" },
                                      })
                                        .then((res) => res.json())
                                
                                        .catch((error) => console.error("Error:", error))
                                
                                        .then((response) => {
                                          this.setState({displaylistfive:response.result})
                                       
                                          
                                        });
                                        
                                      }
                                      else if(e.categoryid==6){
                                        const data = {
                                  
                                          userid: this.state.userid,
                                          categoryid:e.categoryid
                                        }
                                      
                                        
                                          fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
                                            method: "POST",
                                    
                                            body: JSON.stringify(data), // data can be `string` or {object}!
                                    
                                            headers: { "Content-Type": "application/json" },
                                          })
                                            .then((res) => res.json())
                                    
                                            .catch((error) => console.error("Error:", error))
                                    
                                            .then((response) => {
                                              this.setState({displaylistsix:response.result})
                                           
                                              
                                            });
                                            
                                          }
                    
                                          else if(e.categoryid==7){
                                            const data = {
                                      
                                              userid: this.state.userid,
                                              categoryid:e.categoryid
                                            }
                                          
                                            
                                              fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
                                                method: "POST",
                                        
                                                body: JSON.stringify(data), // data can be `string` or {object}!
                                        
                                                headers: { "Content-Type": "application/json" },
                                              })
                                                .then((res) => res.json())
                                        
                                                .catch((error) => console.error("Error:", error))
                                        
                                                .then((response) => {
                                                  this.setState({displaylistseven:response.result})
                                               
                                                  
                                                });
                                                
                                              }
                        
                
                
                
                                              else if(e.categoryid==8){
                                                const data = {
                                          
                                                  userid: this.state.userid,
                                                  categoryid:e.categoryid
                                                }
                                              
                                                
                                                  fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
                                                    method: "POST",
                                            
                                                    body: JSON.stringify(data), // data can be `string` or {object}!
                                            
                                                    headers: { "Content-Type": "application/json" },
                                                  })
                                                    .then((res) => res.json())
                                            
                                                    .catch((error) => console.error("Error:", error))
                                            
                                                    .then((response) => {
                                                      this.setState({displaylisteight:response.result})
                                                   
                                                      
                                                    });
                                                    
                                                  }
                                                  else if(e.categoryid==9){
                                                    const data = {
                                              
                                                      userid: this.state.userid,
                                                      categoryid:e.categoryid
                                                    }
                                                  
                                                    
                                                      fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
                                                        method: "POST",
                                                
                                                        body: JSON.stringify(data), // data can be `string` or {object}!
                                                
                                                        headers: { "Content-Type": "application/json" },
                                                      })
                                                        .then((res) => res.json())
                                                
                                                        .catch((error) => console.error("Error:", error))
                                                
                                                        .then((response) => {
                                                          this.setState({displaylistnine:response.result})
                                                       
                                                          
                                                        });
                                                        
                                                      }
                                                      else if(e.categoryid==10){
                                                        const data = {
                                                  
                                                          userid: this.state.userid,
                                                          categoryid:e.categoryid
                                                        }
                                                      
                                                        
                                                          fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
                                                            method: "POST",
                                                    
                                                            body: JSON.stringify(data), // data can be `string` or {object}!
                                                    
                                                            headers: { "Content-Type": "application/json" },
                                                          })
                                                            .then((res) => res.json())
                                                    
                                                            .catch((error) => console.error("Error:", error))
                                                    
                                                            .then((response) => {
                                                              this.setState({displaylistten:response.result})
                                                           
                                                              
                                                            });
                                                            
                                                          }
                                                          else if(e.categoryid==11){
                                                            const data = {
                                                      
                                                              userid: this.state.userid,
                                                              categoryid:e.categoryid
                                                            }
                                                          
                                                            
                                                              fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
                                                                method: "POST",
                                                        
                                                                body: JSON.stringify(data), // data can be `string` or {object}!
                                                        
                                                                headers: { "Content-Type": "application/json" },
                                                              })
                                                                .then((res) => res.json())
                                                        
                                                                .catch((error) => console.error("Error:", error))
                                                        
                                                                .then((response) => {
                                                                  this.setState({displaylisteleven:response.result})
                                                               
                                                                  
                                                                });
                                                                
                                                              }

                                                              else if(e.categoryid==12){
                                                                const data = {
                                                          
                                                                  userid: this.state.userid,
                                                                  categoryid:e.categoryid
                                                                }
                                                              
                                                                
                                                                  fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
                                                                    method: "POST",
                                                            
                                                                    body: JSON.stringify(data), // data can be `string` or {object}!
                                                            
                                                                    headers: { "Content-Type": "application/json" },
                                                                  })
                                                                    .then((res) => res.json())
                                                            
                                                                    .catch((error) => console.error("Error:", error))
                                                            
                                                                    .then((response) => {
                                                                      this.setState({displaylisttweleve:response.result})
                                                                   
                                                                      
                                                                    });
                                                                    
                                                                  }

                                                                  else if(e.categoryid==13){
                                                                    const data = {
                                                              
                                                                      userid: this.state.userid,
                                                                      categoryid:e.categoryid
                                                                    }
                                                                  
                                                                    
                                                                      fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
                                                                        method: "POST",
                                                                
                                                                        body: JSON.stringify(data), // data can be `string` or {object}!
                                                                
                                                                        headers: { "Content-Type": "application/json" },
                                                                      })
                                                                        .then((res) => res.json())
                                                                
                                                                        .catch((error) => console.error("Error:", error))
                                                                
                                                                        .then((response) => {
                                                                          this.setState({displaylistthirteeen:response.result})
                                                                       
                                                                          
                                                                        });
                                                                        
                                                                      }

                                                                      else if(e.categoryid==14){
                                                                        const data = {
                                                                  
                                                                          userid: this.state.userid,
                                                                          categoryid:e.categoryid
                                                                        }
                                                                      
                                                                        
                                                                          fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
                                                                            method: "POST",
                                                                    
                                                                            body: JSON.stringify(data), // data can be `string` or {object}!
                                                                    
                                                                            headers: { "Content-Type": "application/json" },
                                                                          })
                                                                            .then((res) => res.json())
                                                                    
                                                                            .catch((error) => console.error("Error:", error))
                                                                    
                                                                            .then((response) => {
                                                                              this.setState({displaylistfourteeen:response.result})
                                                                           
                                                                              
                                                                            });
                                                                            
                                                                          }

                          else if(e.categoryid==15){
                                            const data = {
                                                                      
                                                          userid: this.state.userid,
                                                           categoryid:e.categoryid
                                                           }
                                                                          
                                                                        
                                                       fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
                                                        method: "POST",
                                                                        
                                                                                body: JSON.stringify(data), // data can be `string` or {object}!
                                                                        
                                                                                headers: { "Content-Type": "application/json" },
                                                                              })
                                                                                .then((res) => res.json())
                                                                        
                                                                                .catch((error) => console.error("Error:", error))
                                                                        
                                                                                .then((response) => {
                                                                                  this.setState({displaylistfifteen:response.result})
                                                                               
                                                                                  
                                                                                });
                                                                                
                                                                              }

                                                                              else if(e.categoryid==16){
                                                                                const data = {
                                                                                                          
                                                                                              userid: this.state.userid,
                                                                                               categoryid:e.categoryid
                                                                                               }
                                                                                                              
                                                                                                            
                                                                                           fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
                                                                                            method: "POST",
                                                                                                            
                                                                                                                    body: JSON.stringify(data), // data can be `string` or {object}!
                                                                                                            
                                                                                                                    headers: { "Content-Type": "application/json" },
                                                                                                                  })
                                                                                                                    .then((res) => res.json())
                                                                                                            
                                                                                                                    .catch((error) => console.error("Error:", error))
                                                                                                            
                                                                                                                    .then((response) => {
                                                                                                                      this.setState({displaylistsixteen:response.result})
                                                                                                                   
                                                                                                                      
                                                                                                                    });
                                                                                                                    
                                                                                                                  }


                                                                                                                  else if(e.categoryid==17){
                                                                                                                    const data = {
                                                                                                                                              
                                                                                                                                  userid: this.state.userid,
                                                                                                                                   categoryid:e.categoryid
                                                                                                                                   }
                                                                                                                                                  
                                                                                                                                                
                                                                                                                               fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
                                                                                                                                method: "POST",
                                                                                                                                                
                                                                                                                                                        body: JSON.stringify(data), // data can be `string` or {object}!
                                                                                                                                                
                                                                                                                                                        headers: { "Content-Type": "application/json" },
                                                                                                                                                      })
                                                                                                                                                        .then((res) => res.json())
                                                                                                                                                
                                                                                                                                                        .catch((error) => console.error("Error:", error))
                                                                                                                                                
                                                                                                                                                        .then((response) => {
                                                                                                                                                          this.setState({displaylistseventeen:response.result})
                                                                                                                                                       
                                                                                                                                                          
                                                                                                                                                        });
                                                                                                                                                        
                                                                                                                                                      }


                    })  
                                                                                

              }
            });
      });
    })
     
    
 
    } else {
      //remove from array
      
      //console.log(this.state.keyGen)
      this.setState({ keyGen : this.state.keyGen.filter(function(val) {return val!==value})}, () => {
        //console.log(this.state.keyGen)

        const data_category = {

          userid: this.state.userid,
          itemids:this.state.keyGen.toString(),
          addstudata:"stuaddcatitems",
          subcategoryid:this.state.subcategoryid,
          categoryid:this.state.categoryid
          
        }
      console.log(data_category)
        
          fetch( "https://www.gigeconome.com:5200/api/addstu_profiledata", {
            method: "POST",
      
            body: JSON.stringify(data_category), // data can be `string` or {object}!
      
            headers: { "Content-Type": "application/json" },
          })
            .then((res) => res.json())
      
            .catch((error) => console.error("Error:", error))
      
            .then((response) => {
              console.log(response)
              if(response.status==1){
                const data = {
              
                  userid: this.state.userid,
                  categoryid:1
                }
              
                
                  fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
                    method: "POST",
            
                    body: JSON.stringify(data), // data can be `string` or {object}!
            
                    headers: { "Content-Type": "application/json" },
                  })
                    .then((res) => res.json())
            
                    .catch((error) => console.error("Error:", error))
            
                    .then((response) => {
                      this.setState({displaylistone:response.result})
                   
                      
                    });

                    const data_two = {
              
                      userid: this.state.userid,
                      categoryid:2
                    }
                  
                    
                      fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
                        method: "POST",
                
                        body: JSON.stringify(data_two), // data can be `string` or {object}!
                
                        headers: { "Content-Type": "application/json" },
                      })
                        .then((res) => res.json())
                
                        .catch((error) => console.error("Error:", error))
                
                        .then((response) => {
                          this.setState({displaylisttwo:response.result})
                       
                          
                        });

                        const data_three = {
              
                          userid: this.state.userid,
                          categoryid:3
                        }
                      
                        
                          fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
                            method: "POST",
                    
                            body: JSON.stringify(data_three), // data can be `string` or {object}!
                    
                            headers: { "Content-Type": "application/json" },
                          })
                            .then((res) => res.json())
                    
                            .catch((error) => console.error("Error:", error))
                    
                            .then((response) => {
                              this.setState({displaylistthree:response.result})
                           
                              
                            });

                            const data_four = {
              
                              userid: this.state.userid,
                              categoryid:4
                            }
                          
                            
                              fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
                                method: "POST",
                        
                                body: JSON.stringify(data_four), // data can be `string` or {object}!
                        
                                headers: { "Content-Type": "application/json" },
                              })
                                .then((res) => res.json())
                        
                                .catch((error) => console.error("Error:", error))
                        
                                .then((response) => {
                                  this.setState({displaylistfour:response.result})
                               
                                  
                                });
                                const data_five = {
              
                                  userid: this.state.userid,
                                  categoryid:5
                                }
                              
                                
                                  fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
                                    method: "POST",
                            
                                    body: JSON.stringify(data_five), // data can be `string` or {object}!
                            
                                    headers: { "Content-Type": "application/json" },
                                  })
                                    .then((res) => res.json())
                            
                                    .catch((error) => console.error("Error:", error))
                            
                                    .then((response) => {
                                      this.setState({displaylistfive:response.result})
                                   
                                      
                                    });

                                    const data_six = {
              
                                      userid: this.state.userid,
                                      categoryid:6
                                    }
                                  
                                    
                                      fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
                                        method: "POST",
                                
                                        body: JSON.stringify(data_six), // data can be `string` or {object}!
                                
                                        headers: { "Content-Type": "application/json" },
                                      })
                                        .then((res) => res.json())
                                
                                        .catch((error) => console.error("Error:", error))
                                
                                        .then((response) => {
                                          this.setState({displaylistsix:response.result})
                                       
                                          
                                        });

                                        const data_seven = {
              
                                          userid: this.state.userid,
                                          categoryid:7
                                        }
                                      
                                        
                                          fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
                                            method: "POST",
                                    
                                            body: JSON.stringify(data_seven), // data can be `string` or {object}!
                                    
                                            headers: { "Content-Type": "application/json" },
                                          })
                                            .then((res) => res.json())
                                    
                                            .catch((error) => console.error("Error:", error))
                                    
                                            .then((response) => {
                                              this.setState({displaylistseven:response.result})
                                           
                                              
                                            });

                                            const data_eight = {
              
                                              userid: this.state.userid,
                                              categoryid:8
                                            }
                                          
                                            
                                              fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
                                                method: "POST",
                                        
                                                body: JSON.stringify(data_eight), // data can be `string` or {object}!
                                        
                                                headers: { "Content-Type": "application/json" },
                                              })
                                                .then((res) => res.json())
                                        
                                                .catch((error) => console.error("Error:", error))
                                        
                                                .then((response) => {
                                                  this.setState({displaylisteight:response.result})
                                               
                                                  
                                                });
                                                const data_nine = {
              
                                                  userid: this.state.userid,
                                                  categoryid:9
                                                }
                                              
                                                
                                                  fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
                                                    method: "POST",
                                            
                                                    body: JSON.stringify(data_nine), // data can be `string` or {object}!
                                            
                                                    headers: { "Content-Type": "application/json" },
                                                  })
                                                    .then((res) => res.json())
                                            
                                                    .catch((error) => console.error("Error:", error))
                                            
                                                    .then((response) => {
                                                      this.setState({displaylistnine:response.result})
                                                   
                                                      
                                                    });

                                                    const data_ten = {
              
                                                      userid: this.state.userid,
                                                      categoryid:10
                                                    }
                                                  
                                                    
                                                      fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
                                                        method: "POST",
                                                
                                                        body: JSON.stringify(data_ten), // data can be `string` or {object}!
                                                
                                                        headers: { "Content-Type": "application/json" },
                                                      })
                                                        .then((res) => res.json())
                                                
                                                        .catch((error) => console.error("Error:", error))
                                                
                                                        .then((response) => {
                                                          this.setState({displaylistten:response.result})
                                                       
                                                          
                                                        });
                                                        const data_eleven = {
              
                                                          userid: this.state.userid,
                                                          categoryid:11
                                                        }
                                                      
                                                        
                                                          fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
                                                            method: "POST",
                                                    
                                                            body: JSON.stringify(data_eleven), // data can be `string` or {object}!
                                                    
                                                            headers: { "Content-Type": "application/json" },
                                                          })
                                                            .then((res) => res.json())
                                                    
                                                            .catch((error) => console.error("Error:", error))
                                                    
                                                            .then((response) => {
                                                              this.setState({displaylisteleven:response.result})
                                                           
                                                              
                                                            });

                                                            const data_tweleve = {
              
                                                              userid: this.state.userid,
                                                              categoryid:12
                                                            }
                                                          
                                                            
                                                              fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
                                                                method: "POST",
                                                        
                                                                body: JSON.stringify(data_tweleve), // data can be `string` or {object}!
                                                        
                                                                headers: { "Content-Type": "application/json" },
                                                              })
                                                                .then((res) => res.json())
                                                        
                                                                .catch((error) => console.error("Error:", error))
                                                        
                                                                .then((response) => {
                                                                  this.setState({displaylisttweleve:response.result})
                                                               
                                                                  
                                                                });
                                                                const data_thirteen = {
              
                                                                  userid: this.state.userid,
                                                                  categoryid:13
                                                                }
                                                              
                                                                
                                                                  fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
                                                                    method: "POST",
                                                            
                                                                    body: JSON.stringify(data_thirteen), // data can be `string` or {object}!
                                                            
                                                                    headers: { "Content-Type": "application/json" },
                                                                  })
                                                                    .then((res) => res.json())
                                                            
                                                                    .catch((error) => console.error("Error:", error))
                                                            
                                                                    .then((response) => {
                                                                      this.setState({displaylistthirteeen:response.result})
                                                                   
                                                                      
                                                                    });

                                                                    const data_fourteen = {
              
                                                                      userid: this.state.userid,
                                                                      categoryid:14
                                                                    }
                                                                  
                                                                    
                                                                      fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
                                                                        method: "POST",
                                                                
                                                                        body: JSON.stringify(data_fourteen), // data can be `string` or {object}!
                                                                
                                                                        headers: { "Content-Type": "application/json" },
                                                                      })
                                                                        .then((res) => res.json())
                                                                
                                                                        .catch((error) => console.error("Error:", error))
                                                                
                                                                        .then((response) => {
                                                                          this.setState({displaylistfourteeen:response.result})
                                                                       
                                                                          
                                                                        });
                                                                        const data_fifteen = {
              
                                                                          userid: this.state.userid,
                                                                          categoryid:15
                                                                        }
                                                                      
                                                                        
                                                                          fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
                                                                            method: "POST",
                                                                    
                                                                            body: JSON.stringify(data_fifteen), // data can be `string` or {object}!
                                                                    
                                                                            headers: { "Content-Type": "application/json" },
                                                                          })
                                                                            .then((res) => res.json())
                                                                    
                                                                            .catch((error) => console.error("Error:", error))
                                                                    
                                                                            .then((response) => {
                                                                              this.setState({displaylistfifteen:response.result})
                                                                           
                                                                              
                                                                            });

                                                                            const data_sixteen = {
              
                                                                              userid: this.state.userid,
                                                                              categoryid:16
                                                                            }
                                                                          
                                                                            
                                                                              fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
                                                                                method: "POST",
                                                                        
                                                                                body: JSON.stringify(data_sixteen), // data can be `string` or {object}!
                                                                        
                                                                                headers: { "Content-Type": "application/json" },
                                                                              })
                                                                                .then((res) => res.json())
                                                                        
                                                                                .catch((error) => console.error("Error:", error))
                                                                        
                                                                                .then((response) => {
                                                                                  this.setState({displaylistsixteen:response.result})
                                                                               
                                                                                  
                                                                                });
    
                                                                                const data_seventeen = {
              
                                                                                  userid: this.state.userid,
                                                                                  categoryid:17
                                                                                }
                                                                              
                                                                                
                                                                                  fetch( "https://www.gigeconome.com:5200/api/getstuitemlistinfo", {
                                                                                    method: "POST",
                                                                            
                                                                                    body: JSON.stringify(data_seventeen), // data can be `string` or {object}!
                                                                            
                                                                                    headers: { "Content-Type": "application/json" },
                                                                                  })
                                                                                    .then((res) => res.json())
                                                                            
                                                                                    .catch((error) => console.error("Error:", error))
                                                                            
                                                                                    .then((response) => {
                                                                                      this.setState({displaylistseventeen:response.result})
                                                                                   
                                                                                      
                                                                                    });
        
                                                  }


                                                  

                                                  
              else{
                if(this.state.categoryid==1){
                  this.setState({displaylistone:[]})
                }
               else if(this.state.categoryid==2){
                this.setState({displaylisttwo:[]})
               }
               else if(this.state.categoryid==3){
                this.setState({displaylistthree:[]})
               }
               else if(this.state.categoryid==4){
                this.setState({displaylistfour:[]})
               }
               else if(this.state.categoryid==5){
                this.setState({displaylistfive:[]})
               }
               else if(this.state.categoryid==6){
                this.setState({displaylistsix:[]})
               }
               else if(this.state.categoryid==7){
                this.setState({displaylistseven:[]})
               }
               else if(this.state.categoryid==8){
                this.setState({displaylisteight:[]})
               }
               else if(this.state.categoryid==9){
                this.setState({displaylistnine:[]})
               }
               else if(this.state.categoryid==10){
                this.setState({displaylistten:[]})
               }
               else if(this.state.categoryid==11){
                this.setState({displaylisteleven:[]})
               }
               else if(this.state.categoryid==12){
                this.setState({displaylisttweleve:[]})
               }
               else if(this.state.categoryid==13){
                this.setState({displaylistthirteeen:[]})
               }
               else if(this.state.categoryid==14){
                this.setState({displaylistfourteeen:[]})
               }
               else if(this.state.categoryid==15){
                this.setState({displaylistfifteen:[]})
               }
               else if(this.state.categoryid==16){
                this.setState({displaylistsixteen:[]})
               }
               else if(this.state.categoryid==17){
                this.setState({displaylistseventeen:[]})
               }
              }
              //this.setState({displaylistone:[]})
              
            });
      });
   
    }


   



}  

 

  handleChange = ({target: {checked, value}}) => {
   

    if (checked) {
      this.setState(({rolecategorytypeid}) => ({rolecategorytypeid: [...rolecategorytypeid, value]}));
      console.log(this.state.rolecategorytypeid)
{/* this.state.rolecategorytypeid[value] = value;
      var intrest = Object.keys(this.state.rolecategorytypeid).filter(
        (x) => this.state.rolecategorytypeid[x]
      );
      console.log(intrest.toString());
      const data_category = {

        userid: this.state.userid,
        itemids:intrest.toString(),
        addstudata:"stuaddcatitems",
        subcategoryid:"2",
        categoryid:"1"
        
      }
    
      
        fetch( "https://www.gigeconome.com:5200/api/addstu_profiledata", {
          method: "POST",
  
          body: JSON.stringify(data_category), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
            console.log(response)
         
            
          });
      
    
    
 

      this.setState({
        opp: intrest,
      }); */}

    } else {
      this.setState(({rolecategorytypeid}) => ({rolecategorytypeid: rolecategorytypeid.filter(e => e !== value)}));
      //this.state.rolecategorytypeid.splice(value, 1);
    console.log(this.state.rolecategorytypeid)
    }
  };

  openModal = () => {
    this.setState({ showModal: true });
  };
  
  closeModal = () => {
    this.setState({ showModal: false });
  };
  render() {
    var checked = this.checkSomeConditon();
    
    
    const { RoleCategory } = this.state;
    const { selected } = this.state;
    const { RoleCategoryType } = this.state;
    const { RoleCategoryTypelist } = this.state;
    const { selectedone } = this.state;
    const { nodata } = this.state;
    const { nodataa } = this.state;
    const { success } = this.state;
    const { rolecategoryitemname } = this.state;
    const{categoryid}=this.state
    const{rolecattypeid}=this.state
    const {userid}=this.state;
    const {RoleCategoryName}=this.state
    const{selectid}=this.state
    const{SubRoleType}=this.state
    const {selectedmscreen}=this.state
    return (
      <>
       {this.state.showModal && (
        <div class="ft-modal" id="ft-category-modal">
          <div class="ft-modal-content" style={{ maxWidth: "100%" }}>
            <div class="ft-modal-header">
              <div class="header">
                <h5
                  class="modal-title"
                  id="exampleModalLabel"
                  style={{
                    textAlign: "center",
                    fontSize: 25,
                    fontWeight: "bold",
                  }}
                >
                  Category
                </h5>
                <hr
                  style={{ marginRight: "-6%", marginLeft: "-4.2%", height: 2 }}
                />
              </div>
            </div>
            <div class="ft-modal-body">
             
                <h5
                  style={{
                    color: "rgb(0,147,69)",
                    fontfamily: "GothamLight",
                    marginTop: "2%",
                    textAlign: "center",
                  }}
                >
                  Please Add the Category
                </h5>

                <div class="row">
                  <div class="col-sm-4">
                  
                    {RoleCategory.length == 0 ? (
                      null
                    ) : (
                      <>
                        <div
                         className="rolecategorybox rolecategorytypebox"
                        >
                          {" "}
                          <h4 id="rolectext"> Role Category</h4>
                          {RoleCategory.map((fbb) => (
                            <h5
                              key={fbb.categoryid}
                              id="roletext"
                              onClick={() => this.handleClick(fbb.categoryid)}
                              style={{
                                backgroundColor:
                                  selected === fbb.categoryid
                                    ? "rgb(0,147,69)"
                                    : "",
                                color:
                                  selected === fbb.categoryid ? "white" : "",
                                paddingTop:
                                  selected === fbb.categoryid ? "5%" : "",
                                fontSize:
                                  selected === fbb.categoryid ? "14px" : "",
                                paddingBottom:
                                  selected === fbb.categoryid ? "5%" : "",
                                fontfamily:
                                  selected === fbb.categoryid
                                    ? "GothamLight"
                                    : "",
                              }}
                            >
                              
                              {fbb.name}
                            </h5>
                          ))}
                        </div>

                        <div
                         className="rolecategorybox mscreenava"
                         style={{display:this.state.mob_view==0?null:"none"}}
                        >
                          {" "}
                          <h4 id="rolectext"> Role Category</h4>
                          {RoleCategory.map((fbb) => (
                            <h5
                              key={fbb.categoryid}
                              id="roletext"
                              onClick={() => this.handleClick(fbb.categoryid)}
                            
                            >
                              
                              {fbb.name}
                            </h5>
                          ))}
                        </div>

                        <div
                         className="rolecategorybox mscreenava"
                         style={{display:this.state.mob_view==1?null:"none"}}
                        >
                          {" "}
                         
                          <h4 id="rolectext">
                          <span style={{width:"10%",marginLeft:"-28%",}} ><i class="fa fa-chevron-left" onClick={this.handleback} style={{fontSize:22,marginTop:"10%"}} aria-hidden="true"></i></span>
                           <span style={{fontSize:14,marginLeft:"10%"}} >Role Category Type</span></h4>
                          {RoleCategoryType.map((fbb) => (
                            <h5
                              
                              id="roletext"
                              onClick={() => this.handleClickone(fbb.rolecattypeid)}
                             
                            >
                              
                              {fbb.rolecattypename}
                            </h5>
                          ))}
                        </div>

                        <div
                         className="rolecategorybox mscreenava"
                         style={{display:this.state.mob_view==2?null:"none"}}
                        >
                          {" "}
                          
                          <h4 id="rolectext"> 
                          <span style={{width:"55%",marginLeft:"-62%",}}><i class="fa fa-chevron-left" onClick={this.handlebackOne} style={{fontSize:22,padding:"1%"}} aria-hidden="true"></i></span>
                          <span style={{fontSize:14,marginLeft:"10%"}} >Skills</span> </h4>
                          {RoleCategoryTypelist.map((user,index) => (

<>
  <div id="roletext">
    {" "}
    <input
      type="checkbox"
      key={user.rolecattypelistid} 
     name='list'
      label='Add' 
      checked={user.isChecked}
     //checked={this.state.selected_data.map(e=>{if (e===user.rolecattypelistid){return true}})}
    // {...this.state.list_id.includes(user.rolecategorylistid === true ? checked : null)} 
      value={user.rolecattypelistid}
      onClick={(e)=>this.updateStateList(e,user.rolecattypelistid,user.isChecked,index)}
     //onChange={e => this.updateStateList(e,user.rolecattypelistid)}
    />
    &nbsp;&nbsp;{user.rolecattypelistname}   


  </div>
</>
))}
                        </div>
                      </>
                    )}
                  </div>

                  <div class="col-sm-4">
                    {categoryid==1?<>
                      <div
                      className="rolecategorybox rolecategorytypebox"
                    >
                      <h4 id="rolectext"> Role Category Type</h4>
                      <div class="row" style={{ padding: "4%" }}>
                        {nodata ? (
                          <>
                            <h4 id="rolectext" style={{ marginTop: "50%" }}>
                              {" "}
                              {nodata}
                            </h4>
                          </>
                        ) : (
                          <>
                            {RoleCategoryType.map((user) => (
                              <div class="col-sm-6">
                                <div
                                  style={{
                                    border: "1px solid gray",
                                    backgroundColor: "hsl(0, 0%, 90%)",
                                    marginTop: "5%",
                                  }}
                                >
                                  <div
                                    id="rolecategorytext"
                                    onClick={() =>
                                      this.handleClickone(user.rolecattypeid)
                                    }
                                    style={{
                                      backgroundColor:
                                        selectedone === user.rolecattypeid
                                          ? "rgb(0,147,69)"
                                          : "",
                                      color:
                                        selectedone === user.rolecattypeid
                                          ? "white"
                                          : "",

                                      fontfamily:
                                        selectedone === user.rolecattypeid
                                          ? "GothamLight"
                                          : "",
                                    }}
                                  >
                                    {user.rolecattypename}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </>
                        )}
                      </div>
                    </div>
                    
                    </>:<>
                    <div
                      className="rolecategorybox rolecategorytypebox"
                    >
                      <h4 id="rolectext"> Role Category Type</h4>
                      <div class="row" style={{ padding: "4%" }}>
                        {nodata ? (
                          <>
                            <h4 id="rolectext" style={{ marginTop: "50%" }}>
                              {" "}
                              {nodata}
                            </h4>
                          </>
                        ) : (
                          <>
                            {RoleCategoryType.map((user) => (
                              <div class="col-sm-6">
                                <div
                                  style={{
                                    border: "1px solid gray",
                                    backgroundColor: "hsl(0, 0%, 90%)",
                                    marginTop: "5%",
                                  }}
                                >
                                  <div
                                    id="rolecategorytext"
                                    onClick={() =>
                                      this.handleClickone(user.rolecattypeid)
                                    }
                                  
                                    style={{
                                      backgroundColor:
                                        selectedone === user.rolecattypeid
                                          ? "rgb(0,147,69)"
                                          : "",
                                      color:
                                        selectedone === user.rolecattypeid
                                          ? "white"
                                          : "",

                                      fontfamily:
                                        selectedone === user.rolecattypeid
                                          ? "GothamLight"
                                          : "",
                                    }}
                                  >
                                    {user.rolecattypename}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </>
                        )}
                      </div>
                    </div>
                    
                    </>}
                   
                  </div>
                  <div class="col-sm-4">
                    {rolecattypeid==1?<>
                      <div
                      className="rolecategorytypebox"
                      style={{
                        border: "2px solid rgb(0,147,69)",
                        width: 300,
                        marginTop: "4%",
                        height: 520,
                        overflowY: "scroll",
                      }} 
                    >
                      {nodataa ? (
                        <>
                          <h4 id="rolectext" style={{ marginTop: "90%" }}>
                            {" "}
                            {nodataa}
                          </h4>
                        </>
                      ) : (
                        <>
                          {RoleCategoryTypelist.map((user,index) => (
                            <>
                              <p id="roletext">
                                {" "}
 
                                <input
                                  type="checkbox"
                                  key={user.rolecattypelistid} 
                                  name='list'
                                  label='Add' 
                                  
                                  checked={user.isChecked}
                                  //{...this.state.list_id.includes(user.rolecategorylistid === true ? checked : null)} 
                                  //onChange={e => this.updateStateList(e,user.rolecattypelistid)}
                                  onClick={(e)=>this.updateStateList(e,user.rolecattypelistid,user.isChecked,index)}
                                  
                                />
                                &nbsp;&nbsp;{user.rolecattypelistname}  

                               
                                
                              </p>
                            </>
                          ))}
                        </>
                      )}

                      <div></div>
                    </div>
                    
                    </>:<>
                    <div
                    className="rolecategorytypebox"
                      style={{
                        border: "2px solid rgb(0,147,69)",
                        width: 300,
                        marginTop: "4%",
                        height: 520,
                        overflowY: "scroll",
                      }}
                    >
                      {nodataa ? (
                        <>
                          <h4 id="rolectext" style={{ marginTop: "90%" }}>
                            {" "}
                            {nodataa}
                          </h4>
                        </>
                      ) : (
                        <>
                          {RoleCategoryTypelist.map((user,index) => (

                            <>
                              <p id="roletext">
                                {" "}
                                <input
                                  type="checkbox"
                                  key={user.rolecattypelistid} 
                                 name='list'
                                  label='Add' 
                                  checked={user.isChecked}
                                 //checked={this.state.selected_data.map(e=>{if (e===user.rolecattypelistid){return true}})}
                                // {...this.state.list_id.includes(user.rolecategorylistid === true ? checked : null)} 
                                  value={user.rolecattypelistid}
                                  onClick={(e)=>this.updateStateList(e,user.rolecattypelistid,user.isChecked,index)}
                                 //onChange={e => this.updateStateList(e,user.rolecattypelistid)}
                                />
                                &nbsp;&nbsp;{user.rolecattypelistname}   


                              </p>
                            </>
                          ))}
                        </>
                      )}

                      <div></div>
                    </div>
                    
                    </>}
                    
                  </div>
                </div>
                {rolecategoryitemname.length == 0 ? null : (
                  <>
                  
                    {rolecategoryitemname.map((user) => (
                     <>
                     <span
                        className="btn btn-success stucategorybtn"
                       
                      >
                        {user.categoryname}

                      </span><br/>


  {user.categoryid==1? <div class="row">
                      {this.state.displaylistone.map((user) => (
                        <>
                          <div class="col-sm-3">
                            <div class="rolebox">
                               <div class="rolerightCircle">
                                <i
                                  class="fa fa-times-circle"
                                  aria-hidden="true"
                                  onClick={()=>this.deletecatOne
                                    (user.subcategoryid,user.categoryid,user.itemid)}
                                ></i>
                              </div>  
                             
                              <h4 id="rolectext" style={{ paddingTop: "5%" }}>
                                {user.itemname}
                              </h4>
                              
                            </div>
                          </div>
                        </>
                      ))}
                    </div>:null} 
                     
                    
         
  {user.categoryid==2? <div class="row">
                      {this.state.displaylisttwo.map((user) => (
                        <>
                          <div class="col-sm-3">
                            <div class="rolebox">
                             <div class="rolerightCircle">
                                <i
                                  class="fa fa-times-circle"
                                  aria-hidden="true"
                                  onClick={()=>this.deletecatOne  (user.subcategoryid,user.categoryid,user.itemid)}
                                ></i>
                              </div> 
                              <h4 id="rolectext" style={{ paddingTop: "5%" }}>
                                {user.itemname}
                              </h4>
                              
                            </div>
                          </div>
                        </>
                      ))}
                    </div>:null}          
                     
                     
                    {user.categoryid==3? <div class="row">
                      {this.state.displaylistthree.map((user) => (
                        <>
                          <div class="col-sm-3">
                            <div class="rolebox">
                               <div class="rolerightCircle">
                                <i
                                  class="fa fa-times-circle"
                                  aria-hidden="true"
                                  onClick={()=>this.deletecatOne(user.subcategoryid,user.categoryid,user.itemid)}
                                ></i>
                              </div> 
                              <h4 id="rolectext" style={{ paddingTop: "5%" }}>
                                {user.itemname}
                              </h4>
                              
                            </div>
                          </div>
                        </>
                      ))}
                    </div>:null}  
                      

                    {user.categoryid==4? <div class="row">
                      {this.state.displaylistfour.map((user) => (
                        <>
                          <div class="col-sm-3">
                            <div class="rolebox">
                             <div class="rolerightCircle">
                                <i
                                  class="fa fa-times-circle"
                                  aria-hidden="true"
                                  onClick={()=>this.deletecatOne(user.subcategoryid,user.categoryid,user.itemid)}
                                ></i>
                              </div> 
                              <h4 id="rolectext" style={{ paddingTop: "5%" }}>
                                {user.itemname}
                              </h4>
                              
                            </div>
                          </div>
                        </>
                      ))}
                    </div>:null}  
                      
                    {user.categoryid==5? <div class="row">
                      {this.state.displaylistfive.map((user) => (
                        <>
                          <div class="col-sm-3">
                            <div class="rolebox">
                               <div class="rolerightCircle">
                                <i
                                  class="fa fa-times-circle"
                                  aria-hidden="true"
                                  onClick={()=>this.deletecatOne(user.subcategoryid,user.categoryid,user.itemid)}
                                ></i>
                              </div>
                              <h4 id="rolectext" style={{ paddingTop: "5%" }}>
                                {user.itemname}
                              </h4>
                              
                            </div>
                          </div>
                        </>
                      ))}
                    </div>:null} 

                    {user.categoryid==6? <div class="row">
                      {this.state.displaylistsix.map((user) => (
                        <>
                          <div class="col-sm-3">
                            <div class="rolebox">
                              <div class="rolerightCircle">
                                <i
                                  class="fa fa-times-circle"
                                  aria-hidden="true"
                                  onClick={()=>this.deletecatOne(user.subcategoryid,user.categoryid,user.itemid)}
                                ></i>
                              </div> 
                              <h4 id="rolectext" style={{ paddingTop: "5%" }}>
                                {user.itemname}
                              </h4>
                              
                            </div>
                          </div>
                        </>
                      ))}
                    </div>:null} 

                    {user.categoryid==7? <div class="row">
                      {this.state.displaylistseven.map((user) => (
                        <>
                          <div class="col-sm-3">
                            <div class="rolebox">
                              <div class="rolerightCircle">
                                <i
                                  class="fa fa-times-circle"
                                  aria-hidden="true"
                                  onClick={()=>this.deletecatOne(user.subcategoryid,user.categoryid,user.itemid)}
                                ></i>
                              </div> 
                              <h4 id="rolectext" style={{ paddingTop: "5%" }}>
                                {user.itemname}
                              </h4>
                              
                            </div>
                          </div>
                        </>
                      ))}
                    </div>:null} 


                    
                    {user.categoryid==8? <div class="row">
                      {this.state.displaylisteight.map((user) => (
                        <>
                          <div class="col-sm-3">
                            <div class="rolebox">
                               <div class="rolerightCircle">
                                <i
                                  class="fa fa-times-circle"
                                  aria-hidden="true"
                                  onClick={()=>this.deletecatOne(user.subcategoryid,user.categoryid,user.itemid)}
                                ></i>
                              </div> 
                              <h4 id="rolectext" style={{ paddingTop: "5%" }}>
                                {user.itemname}
                              </h4>
                              
                            </div>
                          </div>
                        </>
                      ))}
                    </div>:null} 
                  

                    {user.categoryid==9? <div class="row">
                      {this.state.displaylistnine.map((user) => (
                        <>
                          <div class="col-sm-3">
                            <div class="rolebox">
                             <div class="rolerightCircle">
                                <i
                                  class="fa fa-times-circle"
                                  aria-hidden="true"
                                  onClick={()=>this.deletecatOne(user.subcategoryid,user.categoryid,user.itemid)}
                                ></i>
                              </div> 
                              <h4 id="rolectext" style={{ paddingTop: "5%" }}>
                                {user.itemname}
                              </h4>
                              
                            </div>
                          </div>
                        </>
                      ))}
                    </div>:null} 
                  

                    {user.categoryid==10? <div class="row">
                      {this.state.displaylistten.map((user) => (
                        <>
                          <div class="col-sm-3">
                            <div class="rolebox">
                               <div class="rolerightCircle">
                                <i
                                  class="fa fa-times-circle"
                                  aria-hidden="true"
                                  onClick={()=>this.deletecatOne(user.subcategoryid,user.categoryid,user.itemid)}
                                ></i>
                              </div> 
                              <h4 id="rolectext" style={{ paddingTop: "5%" }}>
                                {user.itemname}
                              </h4>
                              
                            </div>
                          </div>
                        </>
                      ))}
                    </div>:null} 






                    {user.categoryid==11? <div class="row">
                      {this.state.displaylisteleven.map((user) => (
                        <>
                          <div class="col-sm-3">
                            <div class="rolebox">
                               <div class="rolerightCircle">
                                <i
                                  class="fa fa-times-circle"
                                  aria-hidden="true"
                                  onClick={()=>this.deletecatOne(user.subcategoryid,user.categoryid,user.itemid)}
                                ></i>
                              </div> 
                              <h4 id="rolectext" style={{ paddingTop: "5%" }}>
                                {user.itemname}
                              </h4>
                              
                            </div>
                          </div>
                        </>
                      ))}
                    </div>:null}

                    {user.categoryid==12? <div class="row">
                      {this.state.displaylisttweleve.map((user) => (
                        <>
                          <div class="col-sm-3">
                            <div class="rolebox">
                               <div class="rolerightCircle">
                                <i
                                  class="fa fa-times-circle"
                                  aria-hidden="true"
                                  onClick={()=>this.deletecatOne(user.subcategoryid,user.categoryid,user.itemid)}
                                ></i>
                              </div> 
                              <h4 id="rolectext" style={{ paddingTop: "5%" }}>
                                {user.itemname}
                              </h4>
                              
                            </div>
                          </div>
                        </>
                      ))}
                    </div>:null}


                    {user.categoryid==13? <div class="row">
                      {this.state.displaylistthirteeen.map((user) => (
                        <>
                          <div class="col-sm-3">
                            <div class="rolebox">
                              <div class="rolerightCircle">
                                <i
                                  class="fa fa-times-circle"
                                  aria-hidden="true"
                                  onClick={()=>this.deletecatOne(user.subcategoryid,user.categoryid,user.itemid)}
                                ></i>
                              </div> 
                              <h4 id="rolectext" style={{ paddingTop: "5%" }}>
                                {user.itemname}
                              </h4>
                              
                            </div>
                          </div>
                        </>
                      ))}





                      
                    </div>:null}
                    {user.categoryid==14? <div class="row">
                      {this.state.displaylistfourteeen.map((user) => (
                        <>
                          <div class="col-sm-3">
                            <div class="rolebox">
                               <div class="rolerightCircle">
                                <i
                                  class="fa fa-times-circle"
                                  aria-hidden="true"
                                  onClick={()=>this.deletecatOne(user.subcategoryid,user.categoryid,user.itemid)}
                                ></i>
                              </div> 
                              <h4 id="rolectext" style={{ paddingTop: "5%" }}>
                                {user.itemname}
                              </h4>
                              
                            </div>
                          </div>
                        </>
                      ))}





                      
                    </div>:null}
                      
                    {user.categoryid==15? <div class="row">
                      {this.state.displaylistfifteen.map((user) => (
                        <>
                          <div class="col-sm-3">
                            <div class="rolebox">
                              <div class="rolerightCircle">
                                <i
                                  class="fa fa-times-circle"
                                  aria-hidden="true"
                                  onClick={()=>this.deletecatOne(user.subcategoryid,user.categoryid,user.itemid)}
                                ></i>
                              </div> 
                              <h4 id="rolectext" style={{ paddingTop: "5%" }}>
                                {user.itemname}
                              </h4>
                              
                            </div>
                          </div>
                        </>
                      ))}





                      
                    </div>:null}
                      
                           
                    {user.categoryid==16? <div class="row">
                      {this.state.displaylistsixteen.map((user) => (
                        <>
                          <div class="col-sm-3">
                            <div class="rolebox">
                              <div class="rolerightCircle">
                                <i
                                  class="fa fa-times-circle"
                                  aria-hidden="true"
                                  onClick={()=>this.deletecatOne(user.subcategoryid,user.categoryid,user.itemid)}
                                ></i>
                              </div> 
                              
                              <h4 id="rolectext" style={{ paddingTop: "5%" }}>
                                {user.itemname}
                              </h4>
                              
                            </div>
                          </div>
                        </>
                      ))}





                      
                    </div>:null}

                    {user.categoryid==17? <div class="row">
                      {this.state.displaylistseventeen.map((user) => (
                        <>
                          <div class="col-sm-3">
                            <div class="rolebox">
                              <div class="rolerightCircle">
                                <i
                                  class="fa fa-times-circle"
                                  aria-hidden="true"
                                  onClick={()=>this.deletecatOne(user.subcategoryid,user.categoryid,user.itemid)}
                                ></i>
                              </div> 
                              
                              <h4 id="rolectext" style={{ paddingTop: "5%" }}>
                                {user.itemname}
                              </h4>
                              
                            </div>
                          </div>
                        </>
                      ))}





                      
                    </div>:null}
                      </>
                     
                       ))}
                        
                  

                   
                  </>
                )}




                <div>
                  <button
                    class="btn btn-success"
                    style={{
                      backgroundColor: "rgb(0,147,69)",
                      marginLeft: "40%",
                      marginTop: "2%",
                      width: "20%",
                    }}
                    type="submit"
                    onClick={()=>close()}
                  >
                    Save
                  </button>
                </div>

              

                <span 
              style={{color:"rgb(0,147,69)",
              fontSize:15,marginTop:"5%",fontWeight:"bold",textAlign:"center"}}>{this.state.success}</span>

         
            </div>
            <div class="ft-modal-footer">
              <a class="ft-modal-close" 
              
              onClick={this.closeModal}>
                [&#10006;]{" "}
              </a>
            </div>
          </div>
        </div>
       )}
        <a
          className="btn btn-success editbtncategory"
          id="avaliable_btn"
          style={{ float: "right",width:"27%",fontFamily:"GothamLight" }}
          href="#ft-category-modal"
          onClick={this.openModal}
        >
          Add
        </a>
      </>
    );
  }
}
