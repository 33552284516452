import React, {Component} from 'react';
import Header from "../Header/Header";
import Login1 from '../../images/Login-28.png'
import Login2 from '../../images/Login-29.png';
import Login3 from '../../images/Login-30.png';
import Login4 from '../../images/Login-31.png';
import Footer from "../Footer/Footer";
class Login extends React.Component {

  constructor() {
    super();

    this.state = {
     
      name:'', 
     
      logintype:'',
      email:'',

      };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();


    const data = { name:this.state.name, email:this.state.email , logintype:this.state.logintype }

    fetch('http://mainteccloud.com:8080/api/register', { method: 'POST', 

    body: JSON.stringify(data), // data can be `string` or {object}!

    headers:{ 'Content-Type': 'application/json' } })

    .then(res => res.json())

    .catch(error => console.error('Error:', error))

    .then((response)=>{
        console.log('Success:', response)
       
            window.location.href=`./Studentsignup_two`
        
        
    })
    
        
    
       
    
   }
   handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }


  render() {
    return (

        <div>

        <div>
            <Header />
        </div>
        <main id="main" style={{background: '#303030', clear: 'both'}}>
        <br /><br />
        {/* ======= About Section ======= */}
        <section id="clients" className="clients">
          <div className="container" data-aos="fade-up">
            <style type="text/css" dangerouslySetInnerHTML={{__html: "\n    .boxxlr{\n     border: 0px solid green;\n    background: #edf8f2;\n    margin-right: 2.5%;\n    margin-bottom: 7%;\n    color: #000;\n    margin-left: 9%;\n    width: 100%;\n    }\n/*.boxxlr:hover {\n    border: 0px solid green;\n    background: green;\n    margin-right: 1%;\n    margin-bottom: 1%;\n     color: #fff;\n}*/\n\n.boxclrtitle {\nfont-size: 12px;font-weight: bold;\n}\n\n.boxclrtitle1 {\nfont-size: 18px;font-weight: bold;\n}\n/*.boxxlr .boxclrtitle:hover {\nfont-size: 12px;font-weight: bold;\n}*/\n\n\n  " }} />
            <div className="row no-gutters">
              <div className="content col-xl-12 d-flex align-items-center">
                <div className="content col-xl-12 d-flex align-items-center">
                  <div className="row" style={{display: 'flex', justifyContent: 'center', /* alignItems: 'center', */
    background: '#fff', /* width: '80%', */
    /* marginLeft: '50%', */
    borderRadius: '20px', margin: 'auto'}}>
                    <h2 style={{fontSize: '20px', textAlign: 'center', color: '#fff', fontWeight: 600, background: 'green', padding: '1%', width: '70%', margin: '6%'}}> Create an Account </h2>
                    <div className="row h-100 row align-items-center">
                      <div className="col-md-10" style={{margin: 'auto'}}>


                      <form onSubmit={this.handleSubmit}>
       
       

       
       <input id="name" name="name" type="hidden" onChange={this.handleChange} />
       <div className="form-group" style={{padding: '2%', borderRadius: '1px'}}>
       <input id="email" name="email" type="email" className="form-control" placeholder="Email Id" onChange={this.handleChange} />
      
       </div>
      

       <input id="logintype" name="logintype"  type="hidden"  onChange={this.handleChange} />

       <button style={{padding: '2%', marginLeft: '38%', marginBottom: '4%', color: '#fff', background: 'green', width: '26%', border: 'none', borderRadius: '1px', marginTop: '5%', height: '40px'}}>
          Create
        </button>
     </form>

                     



                        <div style={{float: 'left', width: '45%', marginRight: '3%'}}><hr /></div>
                        <div style={{float: 'right', width: '44%'}}><hr /></div>
                        Or
                        <h6 style={{textAlign: 'center'}}>With Your Social Network</h6>
                        <div style={{marginTop: '4%', marginBottom: '10%'}}>
                          <span className="btn btn-primary" style={{background: '#fff', border: '1px solid gray', borderRadius: '1px', fontSize: '22px', letterSpacing: '1px', marginRight: '5%', padding: '0%', marginLeft: '-1%'}}> <img src={Login1} /> </span>
                          <span className="btn btn-primary" style={{background: '#fff', border: '1px solid gray', borderRadius: '1px', fontSize: '22px', letterSpacing: '1px', padding: '0%', marginRight: '5%'}}><img src={Login2} /></span>
                          <span className="btn btn-primary" style={{background: '#fff', border: '1px solid gray', borderRadius: '1px', fontSize: '22px', letterSpacing: '1px', padding: '0%', marginRight: '5%'}}><img src={Login3} /></span>
                          <span className="btn btn-primary" style={{background: '#fff', border: '1px solid gray', borderRadius: '1px', fontSize: '22px', letterSpacing: '1px', padding: '0%', marginRight: '5%'}}><img src={Login4} /></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>     
      </main>{/* End #main */}
      
     <Footer />
    
      </div>
    );
  }
}

export default Login;