import React from "react";
import CommunityNav from "../Home/CommunityNav";
import "./navbar.css";
import styles from './community.module.css'
import { Link } from "react-router-dom";
export default class Community_onenon extends React.Component {
    constructor(){
        super();

        this.state = {
           communitydata:[]
        }
   }

componentDidMount(){
  const sdata = {

    dropdownlist_input:"rolecategory"
 
  
 

}



  fetch( "https://www.mainteccloud.com:8080/api/getdropdownlist", {
    method: "POST",

    body: JSON.stringify(sdata), // data can be `string` or {object}!

    headers: { "Content-Type": "application/json" },
  })
    .then((res) => res.json())

    .catch((error) => console.error("Error:", error))

    .then((response) => {
     
     this.setState({communitydata:response.result})
    });
}


  render() {
   
    return (
      <div>
        <CommunityNav />

        <div className="container-fluid">
          <div class="row">
            <div class="col-sm-1"></div>
            <div class="col-sm-4" style={{ marginTop: 120 }}>
              <h5 style={{ fontFamily: "Gotham" }}>Select Minimum 5 Topics</h5>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-1"></div>
            <div class="col-sm-10" id="community_landing">
            <div class="col-xs-10 col-xs-offset-1 col-md-8 col-md-offset-2">
      <div class="page-header text-center">
        <h1>Pill-Shaped Picker <small> using checkboxes</small></h1>
      </div>
      
      <div class="button-group-pills text-center" data-toggle="buttons">
        <label class="btn btn-default active">
          <input type="checkbox" name="options"  />
          <div>Word</div>
        </label>
        <label class="btn btn-default">
          <input type="checkbox" name="options" />
          <div>CAPITAL LETTERS</div>
        </label>
        <label class="btn btn-default">
          <input type="checkbox" name="options" />
          <div>Two words</div>
        </label>
        <label class="btn btn-default">
          <input type="checkbox" name="options" />
          <div>Other (please explain)</div>
        </label>
      </div>
      
     
    
      
    </div>
            </div>
          </div>
          <div class="row">
              <div class="col-sm-10"></div>
                    <Link to="/communitynontwo" id="community_btn">Continue</Link>
                </div>
        </div>
      </div>
    );
  }
}
