import React from "react";
import Header from "../Header/Header";
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Link } from "react-router-dom";
import Slide1 from "../../images/slide1.png";
import Blog1 from "../../images/blog1.png";
import Blog2 from "../../images/blog2.png";
import Blog3 from "../../images/blog3.png";
import Footer from "../Footer/Footer";
export default class ResourcePage extends React.Component{
    render(){
        return(<div>
                <Header/>
                <img className="img-fluid" style={{marginTop:"4%"}} src={Slide1} />
                <div style={{background:"#00674C",height:"13em"}}>
                    <div className="container">
                    <div className="row">
                        
                        <div className="col-sm-6"> <h3 style={{color:"white",marginTop:"13%"}} >Subscribe to our Newsletter.</h3></div>
                        <div className="col-sm-4"> <input type="text" 
                        style={{ borderRadius:"15px",marginTop:"17%",width:"113%"}} className="form-control shadow-none" placeholder="Enter email here " /></div>
                        <div className="col-sm-2" style={{textAlign:"right",marginTop:"5.2%"}}>
                            <button style={{width:"80%",borderRadius:"20px",padding:"4%",border:"none",fontFamily:"Gotham",background:"white"}}>Subscribe</button>
                        </div>
                    </div>
                    </div>
                  
            
                </div>
             <div className="container" style={{marginTop:"5%",marginBottom:"3%"}}>
                <div className="row" style={{marginLeft:"-6%"}}>
                <div className="col-sm-1"></div>
                <div className="col-sm-7">
                <div class="input-group" style={{width:"100%",margin:"auto"}}>
<input type="text" name="searchtext"
class="form-control shadow-none"
autoComplete="off"
style={{borderRadius:"7px 8px 7px 7px",height:40,border:"2px solid #00674C"}}
placeholder="Search...." />
<div class="input-group-append">
<button class="btn  shadow-none"
 type="submit" style={{border:"none",
 height:40,marginLeft:"-103%",borderRadius:"0px 20px 20px 0px"}}>
  <i class="fa fa-search"></i>
</button>
</div>
</div>
        
                </div>
                <div className="col-sm-1" style={{color:"#00674C",fontSize:"27px",marginLeft:"-3%"}}><i class="fa fa-sort-amount-desc"></i></div>
                </div>
               
             </div>
             <div className='container mt-5'>
            <div className='row' style={{marginLeft:"3%"}}>
              <div className='col-sm-4'>
              <div class="card blogcard">
  <img src={Blog1} class="card-img-top" alt="..." />
  <div class="card-body">
  <button class="card-title blogbtn">Blog</button>
    <h6 style={{marginTop:"7%"}}>
      33+ examples of US Universities using community marketing for student recruitment.
    </h6>
    <br/>
    <p><h5 style={{color:"#00674C"}}>Read More&nbsp;<i class="fa fa-arrow-circle-right" aria-hidden="true" style={{textAlign:"bottom"}}></i></h5></p>
  </div>
</div>
              </div>
              <div className='col-sm-4'>
              <div class="card blogcard">
  <img src={Blog2} class="card-img-top" alt="..." />
  <div class="card-body">
  <button class="card-title blogbtn">Event</button>
   <h6 style={{marginTop:"7%"}}>Unibuddy Conference - The Unmissable online Higher Ed event of 2021.</h6>
   <br/>
    <p><h5 style={{color:"#00674C"}}>Read More&nbsp;<i class="fa fa-arrow-circle-right" aria-hidden="true" style={{textAlign:"bottom"}}></i></h5></p>
  </div>
</div>
              </div>
              <div className='col-sm-4'>
              <div class="card blogcard" >
  <img src={Blog3} class="card-img-top" alt="..." />
  <div class="card-body">
    <button class="card-title blogbtntwo">Free Template</button>
   <h6 style={{marginTop:"7%"}}>4 tips for writing digital ambassador job descriptions that attract top talent.</h6>
   <br/>
    <p><h5 style={{color:"#00674C"}}>Read More&nbsp;<i class="fa fa-arrow-circle-right" aria-hidden="true" style={{textAlign:"bottom"}}></i></h5></p>
  </div>
</div>
              </div>
            </div>
    </div>
    <div className='container mt-5'>
            <div className='row' style={{marginLeft:"3%"}}>
              <div className='col-sm-4'>
              <div class="card blogcard">
  <img src={Blog1} class="card-img-top" alt="..." />
  <div class="card-body">
  <button class="card-title blogbtn">Blog</button>
    <h6 style={{marginTop:"7%"}}>
      33+ examples of US Universities using community marketing for student recruitment.
    </h6>
    <br/>
    <p><h5 style={{color:"#00674C"}}>Read More&nbsp;<i class="fa fa-arrow-circle-right" aria-hidden="true" style={{textAlign:"bottom"}}></i></h5></p>
  </div>
</div>
              </div>
              <div className='col-sm-4'>
              <div class="card blogcard">
  <img src={Blog2} class="card-img-top" alt="..." />
  <div class="card-body">
  <button class="card-title blogbtn">Event</button>
   <h6 style={{marginTop:"7%"}}>Unibuddy Conference - The Unmissable online Higher Ed event of 2021.</h6>
   <br/>
    <p><h5 style={{color:"#00674C"}}>Read More&nbsp;<i class="fa fa-arrow-circle-right" aria-hidden="true" style={{textAlign:"bottom"}}></i></h5></p>
  </div>
</div>
              </div>
              <div className='col-sm-4'>
              <div class="card blogcard" >
  <img src={Blog3} class="card-img-top" alt="..." />
  <div class="card-body">
    <button class="card-title blogbtntwo">Free Template</button>
   <h6 style={{marginTop:"7%"}}>4 tips for writing digital ambassador job descriptions that attract top talent.</h6>
   <br/>
    <p><h5 style={{color:"#00674C"}}>Read More&nbsp;<i class="fa fa-arrow-circle-right" aria-hidden="true" style={{textAlign:"bottom"}}></i></h5></p>
  </div>
</div>
              </div>
             <div style={{marginBottom:"3%"}}>
             <h3 className id="iheadboing" style={{textAlign: 'center', fontSize: '18px', color: '#fff', fontWeight: 700, fontFamily: '"GothamLight"', clear: 'both',marginTop:"2%"}}><span style={{border: '1px solid green', padding: '10px 45px', background: '#00674C', borderRadius: '20px', fontSize: '14px', letterSpacing: '2px'}}>
      <Link style={{color:"white"}}  to="/">View More</Link>
        </span></h3>
             </div>
            </div>
    </div>
<Footer/>
            </div>)
    }
}