import React from "react";
import DashboardMain from "../Dashboard/DashboardMain";
import "./navbar.css";
import { Link } from "react-router-dom";
export default class Community_one extends React.Component {
    constructor(){
        super();

        this.state = {
             black: true,
            
             communitydata:[]
        }
   }
   
   changeColor_(){
    
    this.setState({black: !this.state.black})
      
 }
componentDidMount(){
  const data_two={dropdownlist_input:"stutopicsinfo"}
    fetch('https://www.mainteccloud.com:8080/api/getdropdownlist', { method: 'POST', 
  
    body: JSON.stringify(data_two), // data can be `string` or {object}!
  
    headers:{ 'Content-Type': 'application/json' } })
  
    .then(res => res.json())
  
    .catch(error => console.error('Error:', error))
  
    .then((response)=>{
     
    this.setState({
       communitydata:response.result
      
    })
                     
       //console.log(this.state.communitydata)     
    })
}
  render() {
    let btn_class = this.state.black ? "blackButton" : "whiteButton";
   
    return (
      <div>
        <DashboardMain />

        <div className="container-fluid">
          <div class="row">
            <div class="col-sm-1"></div>
            <div class="col-sm-4" style={{ marginTop: 120 }}>
              <h5 style={{ fontFamily: "Gotham" }}>Select Minimum 5 Topics</h5>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-1"></div>
            <div class="col-sm-10" id="community_landing">
            <div class="row" style={{padding:"3%"}}>

   {
 
 this.state.communitydata.map((topics) => (
 
     <div class="col-sm-2">
    {/* <button id="div1"  
 className={btn_class} 
                          onClick={this.changeColor_.bind(this)}>
                           <div className="text-center ">
                          <label
                          for={`community__${topics.id} `}
                          style={{fontFamily:"GothamLight",fontWeight:"bold"}}>{topics.name}</label>
                         
                            </div>
                  </button>*/}   
 
 <div id="ck-button">
   <label>
      <input type="checkbox" value="1" /><span>{topics.name}</span>
   </label>
</div>
       
        
</div>





   ))}
 
</div>
              
            </div>
          </div>
          <div class="row">
              <div class="col-sm-10"></div>
                    <Link to="/community_two" id="community_btn">Continue</Link>
                </div>
        </div>
      </div>
    );
  }
}
