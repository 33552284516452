import React, { useState } from "react";
import "./modal.css";
import LinkedIN from "../../../images/company1.png";
import Twitter from "../../../images/twitter.png";
import Facebook1 from "../../../images/fb.png";
import Instagram from "../../../images/insta.png";
import Resizer from "react-image-file-resizer";
import Form from "react-bootstrap/Form";
import Swal from 'sweetalert2';
export default class CompleteCompany extends React.Component {
  constructor() {
    super();
  let comments = JSON.parse(sessionStorage.getItem('someData'));
 
  this.state = {
    
    userid:comments,
    cpyname:"",
    companyname:"",
    companysize:"",
    selectIndustry:[],
    cpyindestryname:"",
    cpyindustry:"",
    cpywebsite:"",
    addcpydata:"empcompanyinfo",
    cpysize:"",
    cpysummary:"",
    cpylocation:"",
    cpylogo:"",
    cpybanner:"",
    cpywebsite:"",
    instagramlink:"",
    linkedinlink:"",
    facebooklink:"",
    twitterlink:"",
    smedialink:"",
    linkedinmessage:"",
    instagrammessage:"",
    twittermessage:"",
    facebookmessage:"",
    bannerwidth:"",
    bannerheight:"",
    success:"",
    selectCountry:[],
    locationname:"",
    cpyfoundedyear:""





      };

      this.handleSubmit=this.handleSubmit.bind(this)
      this.handleLinkedin=this.handleLinkedin.bind(this);
      this.handleInstagram=this.handleInstagram.bind(this);
      this.handleTwitter=this.handleTwitter.bind(this);
      this.handleFacebook=this.handleFacebook.bind(this);
  }
  openModal = () => {
    this.setState({ showModal: true });
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };
  

              
          
      
  componentDidMount(){
    const data_two={userid:this.state.userid}
    fetch('https://www.gigeconome.com:5200/api/getempcompanydetails', { method: 'POST', 
  
    body: JSON.stringify(data_two), // data can be `string` or {object}!
  
    headers:{ 'Content-Type': 'application/json' } })
  
    .then(res => res.json())
  
    .catch(error => console.error('Error:', error))
  
    .then((json)=>{
     
    this.setState({
      cpyname:json.result.cpyname,
      companyname:json.result.cpyname,
      companysize:json.result.companysize,
      cpyindestryname:json.result.cpyindestryname,
      cpyindustry:json.result.cpyindustry,
      cpywebsite:json.result.cpywebsite,
      cpysize:json.result.cpysize,
      cpysummary:json.result.cpysummary,
      cpylocation:json.result.cpylocation,
      locationname:json.result.locationname,
      cpyfoundedyear:json.result.cpyfoundedyear
    })
                     
            
    })
  
    const country_data={dropdownlist_input:"country",
    
}
fetch('https://www.gigeconome.com:5200/api/getdropdownlist', { method: 'POST', 

body: JSON.stringify(country_data), // data can be `string` or {object}!

headers:{ 'Content-Type': 'application/json' } })

.then(res => res.json())

.catch(error => console.error('Error:', error))

.then((posRes)=>{
      this.setState({selectCountry:posRes.result})
    })
 
    const industry_data={dropdownlist_input:"stuindustry",
    
  }
  fetch('https://www.gigeconome.com:5200/api/getdropdownlist', { method: 'POST', 
  
  body: JSON.stringify(industry_data), // data can be `string` or {object}!
  
  headers:{ 'Content-Type': 'application/json' } })
  
  .then(res => res.json())
  
  .catch(error => console.error('Error:', error))
  
  .then((posRes)=>{
        this.setState({selectIndustry:posRes.result})
      })
   
  
  }

  handleSubmit(){
    if(this.state.cpybanner==""){
      const company_data = {
        userid:this.state.userid,
        addcpydata:this.state.addcpydata,
        cpyname:this.state.cpyname,
        cpysummary:this.state.cpysummary,
        cpylocation:this.state.cpylocation,
        cpyindustry:this.state.cpyindustry,
        cpysize:this.state.cpysize,
        cpylogo:this.state.cpylogo,
        cpybanner:this.state.cpybanner,
        cpywebsite:this.state.cpywebsite,
        facebooklink:this.state.facebooklink,
        linkedinlink:this.state.linkedinlink,
        twitterlink:this.state.twitterlink,
        instagramlink:this.state.instagramlink,
        cpyfoundedyear:this.state.cpyfoundedyear,
        bannerwidth:"1400",
        bannerheight:"350"
     };
       
       fetch("https://www.gigeconome.com:5200/api/addcpy_profiledata", {
         method: "POST",
     
         body: JSON.stringify(company_data), // data can be `string` or {object}!
     
         headers: { "Content-Type": "application/json" },
       })
         .then((res) => res.json())
     
         .catch((error) => console.error("Error:", error))
     
         .then((response) => {
           if(response.message=="Banner Image Size Should be More than Width:1180px And Height:500px"){
            this.setState({success:response.message})
           }
         else{
         this.setState({success:response.message})
          window.location.reload();
         //console.log(response)
         }
       
         });
    
    }
    else{
      const company_data = {
        userid:this.state.userid,
        addcpydata:this.state.addcpydata,
        cpyname:this.state.cpyname,
        cpysummary:this.state.cpysummary,
        cpylocation:this.state.cpylocation,
        cpyindustry:this.state.cpyindustry,
        cpysize:this.state.cpysize,
        cpylogo:this.state.cpylogo,
        cpybanner:this.state.cpybanner,
        cpywebsite:this.state.cpywebsite,
        facebooklink:this.state.facebooklink,
        linkedinlink:this.state.linkedinlink,
        twitterlink:this.state.twitterlink,
        instagramlink:this.state.instagramlink,
        bannerwidth:1180,
        bannerheight:350,
        cpyfoundedyear:this.state.cpyfoundedyear,
     };
       if(this.state.bannerwidth<=1180 && this.state.bannerheight<=350){
        fetch("https://www.gigeconome.com:5200/api/addcpy_profiledata", {
          method: "POST",
      
          body: JSON.stringify(company_data), // data can be `string` or {object}!
      
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
      
          .catch((error) => console.error("Error:", error))
      
          .then((response) => {
            
            this.setState({success:response.message})
           window.location.reload();
          
         
        
          });
       }
       
       else{
            
        this.setState({success:"Banner Image Size Should be More than Width:1180px And Height:500px"})
       
       }
    
    }
        
    
      }
      
    
      handleLinkedin = (event) => {
        event.preventDefault()
       
       
    const LinkedINdata = {
    
      addcpydata:"companysocialmedia",
      sminputdata:"linkedinlink",
      linkedinlink:this.state.linkedinlink,
    userid:this.state.userid
     
      
     
    
    }
    
    let BaseUrl = JSON.parse(sessionStorage.getItem("baseurl"));
    
      fetch(BaseUrl + "addcpy_profiledata", {
        method: "POST",
    
        body: JSON.stringify(LinkedINdata), // data can be `string` or {object}!
    
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())
    
        .catch((error) => console.error("Error:", error))
    
        .then((response) => {
          
        this.setState({linkedinmessage:response.message})
           
          
        });
        
      }
    
      handleInstagram = (event) => {
        event.preventDefault()
       
       
    const Instagramdata = {
    
      addcpydata:"companysocialmedia",
      sminputdata:"instagramlink",
      instagramlink:this.state.instagramlink,
      userid:this.state.userid
      
      
     
    
    }
    
    let BaseUrl = JSON.parse(sessionStorage.getItem("baseurl"));
    
      fetch(BaseUrl + "addcpy_profiledata", {
        method: "POST",
    
        body: JSON.stringify(Instagramdata), // data can be `string` or {object}!
    
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())
    
        .catch((error) => console.error("Error:", error))
    
        .then((response) => {
          
        this.setState({instagrammessage:response.message})
           
          
        });
        
      }
    
      handleTwitter = (event) => {
        event.preventDefault()
       
       
    const twitterdata = {
    
      addcpydata:"companysocialmedia",
      sminputdata:"twitterlink",
      twitterlink:this.state.twitterlink,
      userid:this.state.userid
      
      
     
    
    }
    
    let BaseUrl = JSON.parse(sessionStorage.getItem("baseurl"));
    
      fetch(BaseUrl + "addcpy_profiledata", {
        method: "POST",
    
        body: JSON.stringify(twitterdata), // data can be `string` or {object}!
    
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())
    
        .catch((error) => console.error("Error:", error))
    
        .then((response) => {
          
        this.setState({twittermessage:response.message})
           
          
        });
        
      }
      handleFacebook = (event) => {
        event.preventDefault()
       
       
    const facebookdata = {
    
      addcpydata:"companysocialmedia",
      sminputdata:"facebooklink",
      facebooklink:this.state.facebooklink,
      userid:this.state.userid
      
      
     
    
    }
    
    let BaseUrl = JSON.parse(sessionStorage.getItem("baseurl"));
    
      fetch(BaseUrl + "addcpy_profiledata", {
        method: "POST",
    
        body: JSON.stringify(facebookdata), // data can be `string` or {object}!
    
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())
    
        .catch((error) => console.error("Error:", error))
    
        .then((response) => {
         
        this.setState({facebookmessage:response.message})
           
          
        });
        
      }
    
    
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
     
    });
    
  };

  getBase64 = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();
  
      // Convert the file to base64 text
      reader.readAsDataURL(file);
  
      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        console.log("Called", reader);
        baseURL = reader.result;
        console.log(baseURL);
        resolve(baseURL);
      };
      console.log(fileInfo);
    });
  };
   
  handlePhoto = (e) => {
    // let file = new FileReader();
    console.log(e.target.files[0]);
    let { file } = this.state;
  
    file = e.target.files[0];
  
    this.getBase64(file)
      .then((result) => {
        file["base64"] = result;
        console.log("File Is", file);
        this.setState({
          cpylogo: result,
          file,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  
    this.setState({
      //  file: e.target.files[0]
    });
  
    this.setState({
      //companylogo: e.target.files[0]
    });
    var fileInput = false;
    if (e.target.files[0]) {
      fileInput = true;
    }
    
    if(fileInput) {
      try {
        Resizer.imageFileResizer(
          e.target.files[0],
          120,
          120,
          "JPEG",
          100,
          0,
          (uri) => {
            console.log(uri);
            this.setState({ newImage: uri });
          },
          "base64",
          120,
          120
        );
      } catch (err) {
        console.log(err);
      }
    }
  };
  
  handleImage = (e) => {
    // let file = new FileReader();
    console.log(e.target.files[0]);
    let { file } = this.state;
  
    file = e.target.files[0];
  
    this.getBase64(file)
      .then((result) => {
        file["base64"] = result;
        console.log("File Is", file);
        this.setState({
          cpybanner: result,
          file,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  
    this.setState({
      //  file: e.target.files[0]
    });
  
    this.setState({
      //companylogo: e.target.files[0]
    });
    var fileInput = false;
    if (e.target.files[0]) {
      fileInput = true;
    }
    
    if(fileInput) {
      try {
        Resizer.imageFileResizer(
          e.target.files[0],
          120,
          120,
          "JPEG",
          100,
          0,
          (uri) => {
            console.log(uri);
            this.setState({ newImage: uri });
          },
          "base64",
          120,
          120
        );
      } catch (err) {
        console.log(err);
      }
    }
  };
  
 render(){
  return (
    <>
      {this.state.showModal && (
      <div class="ft-modal" id="ft-completecompanyinfo-modal">
        <div class="ft-modal-content" style={{width:"50%"}}>
          <div class="ft-modal-header">
            <div class="header">
            <h5
                  class="modal-title"
                  id="exampleModalLabel"
                  style={{
                    textAlign: "center",
                    fontSize: 25,
                    fontWeight: "bold",
                  }}
                >
               Complete Company Information
                </h5>
                <hr style={{marginLeft:"-4%",marginRight:"-4%",height:2}} />
             
            </div>
          </div>
          <div class="ft-modal-body">
         
          <h5
                style={{
                  textAlign: "center",
                  color: "green",
                }}
              >
                Please Update Details 
              </h5>
              <div class="row">
                        <div class="col-sm-2"></div>
                        <div class="col-sm-8" id="first_text">
                          Company Name
                          <input
                            className="form-control mt-2"
                            type="text"
                            value={this.state.cpyname}
                            name="cpyname"
                            required
                            placeholder="Company Name"
                            onChange={this.handleChange}
                          />
                        </div>
                        <div class="col-sm-2"></div>
                      </div>
                      <div class="row">
                        <div class="col-sm-2"></div>
                        <div class="col-sm-8" id="first_text">
                          Company Summary
                          <div class="form-group mt-2">
                            <textarea
                              class="form-control"
                            
                              name="cpysummary"
                              onChange={this.handleChange}
                              required
                              rows="5"
                              value={this.state.cpysummary}
                              placeholder="Description"
                            ></textarea>
                          </div>
                        </div>
                        <div class="col-sm-2"></div>
                      </div>
                      <div class="row">
                        <div class="col-sm-2"></div>
                        <div class="col-sm-8" id="first_text">
                          Company Location
                          {this.state.selectCountry.length == 0
                    ? "Loading Location Name..."
                    : 

                    <select class="form-select" style={{
                      width: "100%",
                      height: "38px",
                   
                      marginBottom: "2%",
                      marginTop: "1%",
                    }} name ="cpylocation" 
                    onChange = {this.handleChange}>
                     
                      <option disabled>Company Location</option>
                      <option value={this.state.cpylocation}>{this.state.locationname}</option>
                    {this.state.selectCountry.map(fbb =>
                   
                      <option key={fbb.key} value={fbb.id}>{fbb.name}</option>
                      
                    )};
                  </select>
                    } 



                      
                        </div>
                        <div class="col-sm-2"></div>
                      </div>
                      <div class="row">
                        <div class="col-sm-2"></div>
                        <div class="col-sm-8" id="first_text">
                          Company Size
                          <select class="form-select mt-2" name="cpysize" onChange={this.handleChange} >
                            <option value={this.state.cpysize}>{this.state.companysize}</option>
                          <option value="1">None</option>
                                <option value="2">1 person-Solo Ventures</option>
                                <option value="3">2-10 ppl-Microenteprise</option>
                                <option value="4">11-49 ppl-Small Enterprise</option>
                                <option value="5">50-249-Medium Enterprise</option>
                                <option value="6">250-999-Large Enterprise</option>
                          </select>
                        </div>
                        <div class="col-sm-2"></div>
                      </div>
                      <div class="row">
                        <div class="col-sm-2"></div>
                        <div class="col-sm-8" id="first_text">
                         Year Founded
                          <input type="text"
                          class="form-control mt-2"
                          name="cpyfoundedyear"
                          onChange={this.handleChange}
                           value={this.state.cpyfoundedyear} />
                        </div>
                        <div class="col-sm-2"></div>
                      </div>
                      <div class="row">
                        <div class="col-sm-2"></div>
                        <div class="col-sm-8" id="first_text">
                          Industry
                          {this.state.selectIndustry.length == 0
                    ? "Loading Industry Name..."
                    : 

                    <select class="form-select" style={{
                      width: "100%",
                      height: "38px",
                   
                      marginBottom: "2%",
                      marginTop: "1%",
                    }} name ="cpyindustry" 
                    onChange = {this.handleChange}>
                     
                      <option disabled>Company Sector / Industry</option>
                      <option value={this.state.cpyindustry}>{this.state.cpyindestryname}</option>
                    {this.state.selectIndustry.map(fbb =>
                   
                      <option key={fbb.key} value={fbb.id}>{fbb.name}</option>
                      
                    )};
                  </select>
                    } 
                        </div>
                        <div class="col-sm-2"></div>
                      </div>
                      
                      <div class="row">
                        <div class="col-sm-2"></div>
                        <div class="col-sm-8" id="first_text">
                          Logo
                          <Form.Group controlId="formFile" className="mb-3 mt-2">
                            <Form.Control
                              type="file"
                              placeholder="Upload Logo"
                              name="cpylogo"
                              required
                              onChange={this.handlePhoto}
                            />
                          </Form.Group>
                        </div>
                       
                      </div> 
                      
                      
                      <div class="row">
                        <div class="col-sm-2"></div>
                        <div class="col-sm-8" id="first_text">
                          Banner
                          <Form.Group controlId="formFile" className="mb-3 mt-2">
                            <Form.Control
                              type="file"
                              placeholder="Upload Logo"
                              name="cpybanner"
                              required
                              onChange={this.handleImage}
                            />
                          </Form.Group>
                        </div>
                      
                      </div>
                      
                      <div class="row">
                        <div class="col-sm-2"></div>
                        <div class="col-sm-8" id="first_text">
                          Company Website
                          <input
                          type="url"
                            className="form-control mt-2"
                            placeholder="Paste URL"
                            value={this.state.cpywebsite}
                            name="cpywebsite"
                            onChange={this.handleChange}
                            required
                          />
                        </div>
                        <div class="col-sm-2"></div>
                      </div>

                      <div class="row mt-4">
              <div class="col-sm-2"></div>
              <div class="col-sm-1 mt-4">
                <img src={LinkedIN} style={{ height: 40 }} />
              </div>
              <div class="col-sm-5">
                <input className="form-control mt-4" name ="linkedinlink" 
                value={this.state.linkedinlink}
                    onChange = {this.handleChange} placeholder="Paste URL" /> <br />
                    <span style={{color:"rgb(0,147,69)",fontFamily:"Gotham",marginTop:"5%",fontWeight:"bold"}}>{this.state.linkedinmessage}</span>
              </div>
              <div class="col-sm-2">
                <button className="btn btn-success" id="urlbtn" onClick={this.handleLinkedin}>
                  Add
                </button>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-sm-2"></div>
              <div class="col-sm-1 mt-4">
                <img src={Twitter} style={{ height: 40 }} />
              </div>
              <div class="col-sm-5">
                <input className="form-control mt-4" name ="twitterlink" 
                value={this.state.twitterlink}
                    onChange = {this.handleChange} placeholder="Paste URL" /> <br />
                    <span style={{color:"rgb(0,147,69)",fontFamily:"Gotham",marginTop:"5%",fontWeight:"bold"}}>{this.state.twittermessage}</span>
              </div>
              <div class="col-sm-2">
                <button className="btn btn-success" id="urlbtn" onClick={this.handleTwitter}>
                  Add
                </button>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-sm-2"></div>
              <div class="col-sm-1 mt-4">
                <img src={Facebook1} style={{ height: 40 }} />
              </div>
              <div class="col-sm-5">
                <input className="form-control mt-4" name ="facebooklink" 
                value={this.state.facebooklink}
                    onChange = {this.handleChange} placeholder="Paste URL" /> <br />
                     <span style={{color:"rgb(0,147,69)",fontFamily:"Gotham",marginTop:"5%",fontWeight:"bold"}}>{this.state.facebookmessage}</span>
              </div>
              <div class="col-sm-2">
                <button className="btn btn-success" id="urlbtn" onClick={this.handleFacebook}>
                  Add
                </button>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-sm-2"></div>
              <div class="col-sm-1 mt-4">
                <img src={Instagram} style={{ height: 40 }} />
              </div>
              <div class="col-sm-5">
                <input className="form-control mt-4" 
                name ="instagramlink"
                value={this.state.instagramlink} 
                    onChange = {this.handleChange} placeholder="Paste URL" /> <br/>
                     <span style={{color:"rgb(0,147,69)",fontFamily:"Gotham",marginTop:"5%",fontWeight:"bold"}}>{this.state.instagrammessage}</span>
              </div>
              <div class="col-sm-2">
                <button className="btn btn-success" id="urlbtn" onClick={this.handleInstagram}>
                  Add
                </button>
              </div>
            </div>
              
 
             
                
              
              
               
              
            
        

        
           
         
              <div class="modal-footer mt-4">
                <div class="col-sm-4"></div>
                <div class="col-sm-7">
                  <button
                    style={{ backgroundColor: "rgb(0, 147, 69)",width:100 }}
                    className="btn btn-success"
                    onClick={this.handleSubmit}
                  >
                    Save
                  </button> <br /> <br />
                  <span style={{color:"rgb(0,147,69)",fontFamily:"Gotham",marginTop:"5%",fontWeight:"bold"}}>{this.state.success}</span>
                </div>
                <div class="col-sm-1"></div>
              </div>
           
          </div>
          <div class="ft-modal-footer">
            <a class="ft-modal-close" data-dismiss="modal"
                     onClick={this.closeModal} style={{cursor:"pointer"}}>
              [&#10006;]{" "}
            </a>
          </div>
        </div>
      </div>
      )}
     
      <a href="#ft-completecompanyinfo-modal" 
       onClick={this.openModal}
      class="col-sm-4 text-success"  
                    >Incomplete</a>


               
    </>
  );
}
}