import React from "react";
import Login5 from '../../images/Login-36.png';
import { Link } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import swal from 'sweetalert';
const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth' // for smoothly scrolling
  });
};
class Employability extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
     backgroundcolor:0,
     signup:0,
     guest:0
     
    };
    this.handleContinue=this.handleContinue.bind(this)
    this.handleSignup=this.handleSignup.bind(this)
    this.handleGuest=this.handleGuest.bind(this)
  }
  handleContinue(){
   window.location.href=`./emptestsignin`
  }

  handleSignup(){
    window.location.href=`./studentsignup_one`
  }
    handleGuest(){
      window.location.href=`./empguestsignup`
    }
       
    
   
  


  

 
  
  render() {

    
    return (
      <div>
         {/* ======= Header ======= */}
      <Header />
      <div>
    

      </div>
      {/* End Header */}
        <main id="main" style={{ background: "#303030", clear: "both" }}>
          <br />
          <br />
          {/* ======= About Section ======= */}
          <section id="clients" className="clients">
            <div
              className="container col-md-5 col-xs-12 col-sm-12 col-lg-6"
              data-aos="fade-up"
            >
              <style
                type="text/css"
                dangerouslySetInnerHTML={{
                  __html:
                    "\n    .boxxlr{\n     border: 0px solid green;\n    background: #edf8f2;\n    margin-right: 2.5%;\n    margin-bottom: 7%;\n    color: #000;\n    margin-left: 9%;\n    width: 100%;\n    }\n/*.boxxlr:hover {\n    border: 0px solid green;\n    background: green;\n    margin-right: 1%;\n    margin-bottom: 1%;\n     color: #fff;\n}*/\n\n.boxclrtitle {\nfont-size: 12px;font-weight: bold;\n}\n\n.boxclrtitle1 {\nfont-size: 18px;font-weight: bold;\n}\n/*.boxxlr .boxclrtitle:hover {\nfont-size: 12px;font-weight: bold;\n}*/\n\n\n  ",
                }}
              />
              <div className="row no-gutters">
                <div className="content col-xl-12 d-flex align-items-center">
                  <div className="content col-xl-12 d-flex align-items-center" id="mainbox">
                    <div
                      className="row"
                      style={{
                        display: "flex",
                        justifyContent: "center" /* alignItems: 'center', */,
                        background: "#fff",
                        width: "100%" /* marginLeft: '50%', */,
                        borderRadius: "20px",
                        margin: "auto",
                      }}
                    >
              
                      <div className="row h-100 row align-items-center">
                        <div
                          className="col-md-10"
                          style={{ margin: "auto", marginBottom: "6%" }}
                        >
                          
                          <img
                              src={Login5}
                             className="empguestsignuplogo"
                            />
                            <h4 className="empguestsignupmainheading">
                            Career Readiness Assessment
                            </h4>
                           <div className="row" style={{marginTop:"5%"}} >  
                                <div className="col-sm-1"></div>
                                <div className="col-sm-11"><i style={{color:"rgb(0,147,69)",fontSize:20}} class="fa fa-info-circle" aria-hidden="true"></i>
                                &nbsp; &nbsp; 
                                <span className="empheadingtwo">
                                  This assessment is mainly for your record to understand your strengths and </span>
                                <span className="empsubheadingtwo">weaknesses and would not be shared with our employers.</span>
                                </div>
                                
                           </div>
                           <h5 className="empgreencolortxt">
                            Select an option below to continue.
                            </h5>
                            <div className="mscreenava empbackground">
                            <div className="row">
                            <div className={this.state.backgroundcolor==0?"col-sm-3 empboxstu":"col-sm-3 empboxtwo"} onClick={this.handleContinue} >
                                               
                                                <div>
                                                   <span style={{fontSize:34,margin:"5%"}} >
                                                     <i class="fa fa-graduation-cap"></i></span><br/>
                                                </div>
                                                <div>
                                                    
                                                      <span className={this.state.backgroundcolor==0?"col-sm-3 empsigninheading":"col-sm-3 empsigninheadingclick  "} >Sign In</span>
                                                    </div>
                                                    <div style={{textAlign:"center",marginTop:"-22%",marginLeft:"20%"}}>
                                                    <span className="emptestsubheading"  >To your existing </span> 
                                                    </div>   
                                                    <div style={{textAlign:"center",marginLeft:"24%"}}>
                                                    <span className="emptestsubheading" >GigeconoMe account</span>
                                                    </div>  
                                                
                                            </div>
                                            <div className={this.state.signup==0?"col-sm-3 empboxstu":"col-sm-3 empboxtwo"} onClick={this.handleSignup} >
                                            

                                            <div style={{marginTop:"1%"}}>
                                                   <span style={{fontSize:34,margin:"8%"}} >
                                                     <i class="fa fa-id-badge"></i></span><br/>
                                                </div>
                                                <div>
                                                    
                                                      <span style={{margin:"3%"}} className={this.state.signup==0?"col-sm-3 empsigninheading":"col-sm-3 empsigninheadingclick  "}>Sign Up</span>
                                                    </div>
                                                    <div style={{textAlign:"center",marginTop:"-22%",marginLeft:"20%"}}>
                                                    <span className="emptestsubheading"  >To create a </span> 
                                                    </div>   
                                                    <div style={{textAlign:"center",marginLeft:"24%"}}>
                                                    <span className="emptestsubheading" >GigeconoMe profile</span>
                                                    </div>
                                              
                                            </div>
                                            <div className={this.state.guest==0?"col-sm-3 empboxstu":"col-sm-3 empboxtwo"} onClick={this.handleGuest} >
                                            

                                            <div>
                                                   <span style={{fontSize:34,margin:"8%"}} >
                                                     <i class="fa fa-user"></i></span><br/>
                                                </div>
                                                <div>
                                                    
                                                      <span  className={this.state.guest==0?"col-sm-3 empsigninheading":"col-sm-3 empsigninheadingclick"}>Guest</span>
                                                    </div>
                                                    <div style={{textAlign:"center",marginTop:"-22%",marginLeft:"20%"}}>
                                                    <span className="emptestsubheading"  >Take the Test without  </span> 
                                                    </div>   
                                                    <div style={{textAlign:"center",marginLeft:"24%"}}>
                                                    <span className="emptestsubheading"  >Creating an account</span>
                                                    </div>

                                               
                                            </div>
                              </div>
                            </div>
                          <div className="row"  style={{marginRight:"-16%"}} >
                                <div className="col-sm-12 empbackground bigcompanysearch">
                                        <div className="row">
                                            <div className={this.state.backgroundcolor==0?"col-sm-3 empboxstu":"col-sm-3 empboxtwo"} onClick={this.handleContinue} >
                                                <br/>
                                                <div style={{textAlign:"center"}}>
                                                   <span style={{fontSize:45,marginTop:"3%"}} >
                                                     <i class="fa fa-graduation-cap"></i></span><br/>
                                                </div>
                                                <div style={{textAlign:"center"}}>
                                                    
                                                      <span className={this.state.backgroundcolor==0?"col-sm-3 empsigninheading":"col-sm-3 empsigninheadingclick  "} >Sign In</span>
                                                    </div>
                                                    <div style={{textAlign:"center"}}>
                                                    <span className="emptestsubheading"  >To your existing </span> 
                                                    </div>   
                                                    <div style={{textAlign:"center"}}>
                                                    <span className="emptestsubheading" >GigeconoMe account</span>
                                                    </div>  
                                                
                                            </div>
                                            <div className={this.state.signup==0?"col-sm-3 empboxstu":"col-sm-3 empboxtwo"} onClick={this.handleSignup} >
                                            <br/>

                                            <div style={{textAlign:"center"}}>
                                                   <span style={{fontSize:45,marginTop:"3%"}} >
                                                     <i class="fa fa-id-badge"></i></span><br/>
                                                </div>
                                                <div style={{textAlign:"center"}}>
                                                    
                                                      <span className={this.state.signup==0?"col-sm-3 empsigninheading":"col-sm-3 empsigninheadingclick  "}>Sign Up</span>
                                                    </div>
                                                    <div style={{textAlign:"center"}}>
                                                    <span className="emptestsubheading"  >To create a </span> 
                                                    </div>   
                                                    <div style={{textAlign:"center"}}>
                                                    <span className="emptestsubheading" >GigeconoMe profile</span>
                                                    </div>
                                              
                                            </div>
                                            <div className={this.state.guest==0?"col-sm-3 empboxstu":"col-sm-3 empboxtwo"} onClick={this.handleGuest} >
                                            <br/>

                                            <div style={{textAlign:"center"}}>
                                                   <span style={{fontSize:45,marginTop:"3%"}} >
                                                     <i class="fa fa-user"></i></span><br/>
                                                </div>
                                                <div style={{textAlign:"center"}}>
                                                    
                                                      <span className={this.state.guest==0?"col-sm-3 empsigninheading":"col-sm-3 empsigninheadingclick"}>Guest</span>
                                                    </div>
                                                    <div style={{textAlign:"center"}}>
                                                    <span className="emptestsubheading"  >Take the Test without  </span> 
                                                    </div>   
                                                    <div style={{textAlign:"center"}}>
                                                    <span className="emptestsubheading"  >Creating an account</span>
                                                    </div>

                                               
                                            </div>
                                        </div>
                                </div>
                        </div>
                         
                        {this.state.backgroundcolor==1?<>
                        <div style={{textAlign:"center",marginTop:"5%"}} >
                          <Link to="/emptestsignin" onClick={()=>scrollToTop()} className="btn btn-success empcontinuebtn"  >
                            Continue</Link>
                        </div>
                        </>:null}
                        {this.state.guest==1?<>
                        <div style={{textAlign:"center",marginTop:"5%"}} >
                          <Link to="/empguestsignup" onClick={()=>scrollToTop()} className="btn btn-success empcontinuebtn"  >
                            Continue</Link>
                        </div>
                        </>:null}
                        {this.state.signup==1?<>
                        <div style={{textAlign:"center",marginTop:"5%"}} >
                          <Link to="/studentsignup_one"  onClick={()=>scrollToTop()} className="btn btn-success empcontinuebtn"  >Continue</Link>
                        </div>
                        </>:null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        {/* End #main */}
        {/* ======= Footer ======= */}
 <Footer />
      </div>
    );
  }
}
export default Employability;
