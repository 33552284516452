import React from "react";
import './style.css';
import { Link } from "react-router-dom";
import Logo from '../../images/logo.png';
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
class Privacy extends React.Component{
    render(){
        return(
            <div>
                     {/* ======= Header ======= */}
    <Header />
      {/* End Header */}
        <main id="main" className="cookbox" style={{background: '#303030', clear: 'both', height: '175px'}}>
          <section id="clients" className="clients">
            <h3 style={{color: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2.5%'}}>GigeconoMe Privacy Policy</h3>
          </section>
        </main>
        <main id="main" id="main_one">
          <br /><br />
          {/* ======= About Section ======= */}
          <section id="clients" className="clients mscreentandcbox">
            <div
              className="container"
              data-aos="fade-up"
              style={{ marginTop: "-4%" }}
            >
              <style
                type="text/css"
                dangerouslySetInnerHTML={{
                  __html:
                    "\n    .boxxlr{\n     border: 0px solid green;\n    background: #edf8f2;\n    margin-right: 2.5%;\n    margin-bottom: 7%;\n    color: #000;\n    margin-left: 9%;\n    width: 100%;\n    }\n/*.boxxlr:hover {\n    border: 0px solid green;\n    background: green;\n    margin-right: 1%;\n    margin-bottom: 1%;\n     color: #fff;\n}*/\n\n.boxclrtitle {\nfont-size: 12px;font-weight: bold;\n}\n\n.boxclrtitle1 {\nfont-size: 18px;font-weight: bold;\n}\n/*.boxxlr .boxclrtitle:hover {\nfont-size: 12px;font-weight: bold;\n}*/\n\n\n  ",
                }}
              />
              <div className="row no-gutters">
                <div className="content col-xl-12 d-flex align-items-center">
                  <div className="content col-xl-12 d-flex align-items-center">
                    <div
                      className="row"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        background: "#fff",
                        margin: "auto",
                        padding: "5%",
                      }}
                    >
                      <p />
                      <p>

                      <h3 className="termsmainheading" style={{ marginLeft: "-2%",textAlign:"center",fontFamily:"Gotham",fontWeight:"bold" }}>
                      GigeconoMe Privacy Policy
                      </h3>
                      </p>
        <p><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"className="termsescription" style={{color: '#242424',marginTop:"2%"}}>
          <p/>
          We at GigeconoMe value your partnership and understand just how important it is to protect your Personal Data (defined herein). Please take the time to review our privacy policy (the “<strong>Privacy Policy</strong>”) carefully in conjunction with our terms and conditions (the “<strong>Terms and Conditions</strong>”), as you agree to be unconditionally bound by both the Privacy Policy and the&nbsp;terms and conditions&nbsp;when you use www.gigeconome (the "<strong>Website</strong>").<br />
                    &nbsp;&nbsp;<br />
                    <strong style={{color:"rgb(0,147,69)"}}>What does this policy cover?</strong><br />
                    &nbsp;&nbsp;<br />
                    We take your Personal Data seriously at GigeconoMe. This Privacy Policy explains in detail the types of Personal Data we may collect about you when you interact with us. It also explains how we store and handle that data, and keep it safe.</span></span></span></span></span></p>
        <p><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color: '#242424'}} className="termsescription">We know that there’s a lot of information here, but we want you to be fully informed about your rights, and how GigeconoMe uses your data.</span></span></span></span></span></p>
        <p><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color: '#242424'}} className="termsescription">We hope the following sections will answer any questions you have but if not, please do get in touch with us.</span></span></span></span></span></p>
        <p><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}>
          <p/>
          <strong style={{color:"rgb(0,147,69)"}}><span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color: 'rgb(0,147,69)'}}>Who is GigeconoMe?</span></span></strong><br />
              <span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color: '#242424'}}>&nbsp;<br />
                  Here at GigeconoMe, we're committed to </span></span><span className="termsescription"style={{fontSize: '12.0pt'}} className="termsescription">bridge the gap between academic learning and employability skills, that graduates need to secure jobs and succeed in today’s workplace. </span></span></span></p>
        <p><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"className="termsescription" style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color: '#242424'}}>For simplicity throughout this notice, ‘we’ and ‘us’ means GigeconoMe</span></span></span></span></span></p>
        <p><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><strong style={{color:"rgb(0,147,69)"}}><span className="termsescription"style={{fontSize: '12.0pt'}} className="termsescription">
        <p/><span className="termsescription"style={{color: 'rgb(0,147,69)'}} className="termsescription">What is Personal Data?</span></span></strong><br />
                <span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color: '#242424'}} className="termsescription">&nbsp;<br />
                    Personal data is data that can identify you as a living individual. There is general Personal Data such as name and address. Personal data may also include information on physical and mental health, sexual orientation, race or ethnic origin, religious beliefs, trade union membership and criminal records (known as “<strong>Sensitive Personal Data</strong>“). Sensitive Personal Data must be protected to a higher level.<br />
                    &nbsp;<br />
                    <strong style={{color:"rgb(0,147,69)"}}>Who does GigeconoMe collect Personal Data from?</strong><br />
                    &nbsp;<br />
                    We collect Personal Data from the following types of people to allow us to undertake our business:</span></span></span></span></span></p>
        <ul style={{marginLeft:"7%"}}>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>Graduates and students - prospective and placed students for internships and placements</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>Partners - prospective and live partner contacts;</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>Website users;&nbsp;</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>Supplier contacts to support our services;</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>Universities - prospective and confirmed university partner contacts; and</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>Employees, consultants, temporary workers and contractors.</span></span></span></span></span></li>
        </ul>
        <p><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><strong style={{color:"rgb(0,147,69)"}}><span className="termsescription"style={{fontSize: '12.0pt'}}>
         <p/> <span className="termsescription"style={{color: 'rgb(0,147,69)'}}>Why does GigeconoMe collect Personal Data?</span></span></strong></span></span></span></p>
        <p><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color: '#242424'}}>Students &amp; Graduates:</span></span>&nbsp;</span></span></span></p>
        <p><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color: '#242424'}}>We want to connect you with the best internship and work placement opportunities. One way to achieve this is to get the clearest picture of who you are, by combining the data we have about you.&nbsp;</span></span></span></span></span></p>
        <p><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color: '#242424'}}>For prospective students, we may use your Personal Data to:</span></span></span></span></span></p>
        <ul style={{marginLeft:"7%"}}>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>inform you about internships and placements that are most likely to interest you;</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>send you advice and information regarding GigeconoMe and its service;</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>send you surveys and feedback requests to help improve our services;</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>carry out market research and analysis (including equal opportunities monitoring);</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>help a university you’ve attended (or will attend) meet their Graduate Outcomes Survey (or a similar or replacement survey) obligations; and</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>send you important information regarding changes to our policies and other terms and conditions.</span></span></span></span></span></li>
        </ul>
        <p><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color: '#242424'}}>If you have applied for an internship or job opportunity, we may collect and use your Personal Data to:</span></span></span></span></span></p>
        <ul style={{marginLeft:"7%"}}>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>assess your eligibility through different stages of the recruitment process;</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>share it with third party employers who are advertising roles or events via GigeconoMe;&nbsp;</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>send you survey and feedback requests to help improve our services;</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>carry out market research and analysis (including equal opportunities monitoring);</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>inform you about roles and events that are most likely to interest you; and</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>send you important information regarding changes to our policies and other terms and conditions.&nbsp;</span></span></span></span></span></li>
        </ul>
        <p><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color: '#242424'}}>Please do remember that if you choose not to share your Personal Data with us, or refuse certain contact permissions, we might not be able to provide some of the services you’ve asked for.&nbsp;</span></span></span></span></span></p>
        <p><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color: '#242424'}}>Partners</span></span><span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color: '#242424'}}>:&nbsp;</span></span></span></span></span></p>
        <p><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color: '#242424'}}>We want to connect you with verified student talents. &nbsp;We may use your Personal Data to:</span></span></span></span></span></p>
        <ul style={{marginLeft:"7%"}}>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>offer you products and services that are most likely to interest you;</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>send you survey and feedback requests to help improve our services; and</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>send you important information regarding changes to our policies and other terms and conditions.&nbsp;</span></span></span></span></span></li>
        </ul>
        
        <p><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><strong style={{color:"rgb(0,147,69)"}}><span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color: 'rgb(0,147,69)'}}>What Personal Data does GigeconoMe collect?</span></span></strong></span></span></span></p>
        <p><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color: '#242424'}}>Students &amp; Graduates:</span></span><span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color: '#242424'}}>&nbsp;<br />
                    &nbsp;<br />
                    We may collect and process the following Personal Data you will have given us: &nbsp;</span></span></span></span></span></p>
        <ul style={{marginLeft:"7%"}}>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>Your username;&nbsp;</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>Your password;</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>Name;</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>Email address;</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>Post code;</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>Telephone number;</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>Home address;</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>Employment history;</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>Education &amp; professional qualifications;</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>Educational background (including type of school);</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>Parents' educational background;</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>Eligibility for free school meals;</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>University;</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>Cover letter;</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>Whether you require a visa; &nbsp;</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>Degree subject;</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>Degree grade;</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>Graduation year;</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>A-Level results;&nbsp;</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>Your CV;&nbsp;</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>Video interview;&nbsp;</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>Telephone interview;</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>IP address;&nbsp;</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>Marketing preferences;</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>Photographs;&nbsp;</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>Videos;&nbsp;</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>Voice recordings;</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>Bank details; and</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>If you contact us, we may keep a record of that correspondence.</span></span></span></span></span></li>
        </ul>
        <p><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color: '#242424'}}>Where you have given your explicit consent, sensitive Personal Data you may give us includes:&nbsp;</span></span></span></span></span></p>
        <ul style={{marginLeft:"7%"}}>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>Gender;&nbsp;</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>Health Data;&nbsp;</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>Sexual Orientation; and&nbsp;</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>Ethnicity</span></span></span></span></span></li>
        </ul>
        
        <p><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color: '#242424'}}>Partners</span></span><span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color: '#242424'}}>:&nbsp;</span></span></span></span></span></p>
        <p><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color: '#242424'}}>Personal data you may give us includes:&nbsp;</span></span></span></span></span></p>
        <ul style={{marginLeft:"7%"}}>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>Name;&nbsp;</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>Email address;</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>Telephone number;&nbsp;</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>Job role;&nbsp;</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>Your statements and opinions about students and/or other personnel, e.g. a referee;&nbsp;</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>Information relating to our relationship with you or the party for whom you work including records of any meetings or discussions;&nbsp;</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>Your marketing preferences; and&nbsp;</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>A record of our&nbsp;correspondence (if applicable).</span></span></span></span></span></li>
        </ul>
        <p><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><strong style={{color:"rgb(0,147,69)"}}><span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color: 'rgb(0,147,69)'}}>How does GigeconoMe collect Personal Data?&nbsp;</span></span></strong></span></span></span></p>
        <p><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color: '#242424'}}>Students &amp; Graduates</span></span><span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color: '#242424'}}>:&nbsp;<br />
                    &nbsp;<br />
                    Directly from you, when:</span></span></span></span></span></p>
        <ul style={{marginLeft:"7%"}}>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>you visit our website;&nbsp;</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>you register as a member;</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>you apply to an opportunity via our website;</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>you apply for a placement via our website;</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>you complete a telephone interview with GigeconoMe;&nbsp;</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>you take part in a face-to-face interview with GigeconoMe</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>you complete any surveys we send you;</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>you engage with us on social media; or</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>you correspond with us (for example, by email, telephone or video conferencing).&nbsp;</span></span></span></span></span></li>
        </ul>
        <p><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color: '#242424'}}>Through publicly available sources:</span></span></span></span></span></p>
        <ul style={{marginLeft:"7%"}}>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>We may source employment data about you on Linkedin.</span></span></span></span></span></li>
        </ul>
        <p><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color: '#242424'}}>We also collect from:</span></span></span></span></span></p>
        <ul style={{marginLeft:"7%"}}>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>our website;&nbsp;</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>cookies; and</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>other tools and applications.&nbsp;</span></span></span></span></span></li>
        </ul>
        <p><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color: '#242424'}}>&nbsp;<br />
                    Partners:&nbsp;</span></span></span></span></span></p>
        <p><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color: '#242424'}}>Directly from you, when:</span></span></span></span></span></p>
        <ul style={{marginLeft:"7%"}}>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>you visit our website;&nbsp;</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>you complete any surveys we send you; or</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>you correspond with us (for example, by email, telephone or video conferencing).&nbsp;</span></span></span></span></span></li>
        </ul>
        <p><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color: '#242424'}}>Through publicly available sources:</span></span></span></span></span></p>
        <ul style={{marginLeft:"7%"}}>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>We may source employment data about you on Linkedin.</span></span></span></span></span></li>
        </ul>
        <p><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color: '#242424'}}>We also collect from:</span></span></span></span></span></p>
        <ul style={{marginLeft:"7%"}}>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>our website;&nbsp;</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>cookies; or</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>other tools and applications.&nbsp;</span></span></span></span></span></li>
        </ul>
        <p><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><strong style={{color:"rgb(0,147,69)"}}><span className="termsescription"style={{fontSize: '12.0pt'}}>
         <p/> <span className="termsescription"style={{color: 'rgb(0,147,69)'}}>What legal basis do we rely on to process your Personal Data?</span></span></strong></span></span></span></p>
        <p><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color: '#242424'}}>UK Data Protection laws set out a number of different reasons for which a company may collect and process your Personal Data, including:</span></span></span></span></span></p>
        <p><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><strong><span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color: 'rgb(0,147,69)'}}>Legitimate Interest</span></span></strong></span></span></span></p>
        <p><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color: '#242424'}}>In certain situations, we require your data to pursue our legitimate interests in a way which might reasonably be expected as part of running our business and which does not materially impact your rights, freedom or interests.&nbsp;</span></span></span></span></span></p>
        <p><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color: '#242424'}}>Students &amp; Graduates</span></span><span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color: '#242424'}}>:</span></span></span></span></span></p>
        <p><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color: '#242424'}}>UK Data Protection laws allow the following as part of our legitimate interest in understanding our students and providing the most relevant and highest levels of service:</span></span></span></span></span></p>
        <ul style={{marginLeft:"7%"}}>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>to contact you about internships or placements that we think might be of interest to you;&nbsp;</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>to assess your eligibility for an opportunity or role;&nbsp;</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>to communicate with you;</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>for marketing purposes; or</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>to analyse your behaviour, activities, preferences and needs in order to send relevant and tailored promotional communications both to you and to our partners.</span></span></span></span></span></li>
        </ul>
        <p><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color: '#242424'}}>Partners</span></span><span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color: '#242424'}}>:</span></span></span></span></span></p>
        <p><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color: '#242424'}}>UK Data Protection laws allow the following as part of our legitimate interest in understanding our partners and providing the most relevant and highest levels of service:</span></span></span></span></span></p>
        <ul style={{marginLeft:"7%"}}>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>to contact you about products and services that are most likely to interest you;</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>for marketing purposes; or</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>to communicate with you.</span></span></span></span></span></li>
        </ul>
        <p><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><strong><span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color: 'rgb(0,147,69)'}}>Consent</span></span></strong>&nbsp;</span></span></span></p>
        <p><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color: '#242424'}}>In certain situations, we can collect and process your Personal Data with your consent.</span></span></span></span></span></p>
        <p><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color: '#242424'}}>Students &amp; Graduates:</span></span></span></span></span></p>
        <p><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color: '#242424'}}>On certain occasions, we may ask you to consent to disclose Sensitive Personal Data such as your gender, ethnicity and educational background.&nbsp;</span></span></span></span></span></p>
        <p><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color: '#242424'}}>Please note that you have the right to withdraw your consent at any time. Where consent is the only legal basis for processing, we will cease to process your Personal Data after your consent is withdrawn.</span></span></span></span></span></p>
        <p><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><strong><span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color: 'rgb(0,147,69)'}}>Contractual Obligations</span></span></strong></span></span></span></p>
        <p><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color: '#242424'}}>In certain circumstances, we need your Personal Data to comply with our contractual obligations.</span></span></span></span></span></p>
        <p><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color: '#242424'}}>Partners</span></span><span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color: '#242424'}}>:&nbsp;</span></span></span></span></span></p>
        <p><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color: '#242424'}}>GigeconoMe may process your personal data when we need to do this to fulfil a contract with you.&nbsp;</span></span></span></span></span></p>
        <p><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><strong><span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color: 'rgb(0,147,69)'}}>Legal Compliance</span></span></strong></span></span></span></p>
        <p><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color: '#242424'}}>Partners, Students &amp; Graduates</span></span><span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color: '#242424'}}>:&nbsp;</span></span></span></span></span></p>
        <p><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color: '#242424'}}>GigeconoMe may be compelled to process your Personal Data to comply with our legal and regulatory obligations under UK law, e.g. to prevent and investigate fraud or anti-social behaviour and to work with law enforcement agencies:</span></span></span></span></span></p>
        <ul style={{marginLeft:"7%"}}>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>handling customer contacts, queries, complaints and disputes; and</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>fulfilling our duties to our customers, colleagues, shareholders and other stakeholders;&nbsp;</span></span></span></span></span></li>
        </ul>
        <p><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color: '#242424'}}>&nbsp;
                    <strong style={{color:"rgb(0,147,69)"}}>Public Interest</strong></span></span></span></span></span></p>
        <p><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color: '#242424'}}>Students &amp; Graduates:</span></span></span></span></span></p>
        <p><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color: '#242424'}}>GigeconoMe may process your personal data for the performance of a task carried out in the public interest.&nbsp;</span></span></span></span></span></p>
        <p><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><strong><span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color: 'rgb(0,147,69)'}}>Who do we share your Personal Data with?</span></span></strong></span></span></span></p>
        <p><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color: '#242424'}}>We sometimes share your Personal Data with third parties. We require third parties to respect the security of your data and to treat it in accordance with the law.&nbsp;<br />
                    &nbsp;<br />
                    Students &amp; Graduates:</span></span></span></span></span></p>
        <p><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color: '#242424'}}>GigeconoMe may make Personal Data available to:</span></span></span></span></span></p>
        <ul style={{marginLeft:"7%"}}>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>Partners</span></span></span></span></span>
            <ul style={{listStyleType: 'circle'}}>
              <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>Your Personal Data may be shared with a partner who initiates a search for an opportunity which matches with your skills </span></span></span></span></span></li>
            </ul>
          </li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>Our service providers</span></span></span></span></span>
            <ul style={{listStyleType: 'circle'}}>
              <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>Your data may be shared with parties who process data on our behalf. We are happy to provide a list of these third-party suppliers on request</span></span></span></span></span></li>
            </ul>
          </li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>Government authorities and third parties involved in court</span></span></span></span></span>
            <ul style={{listStyleType: 'circle'}}>
              <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>Any regulatory authority or statutory body pursuant to a request for information or any legal obligation which applies to us.&nbsp;</span></span></span></span></span></li>
            </ul>
          </li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>Other third parties</span></span></span></span></span>
            <ul style={{listStyleType: 'circle'}}>
              <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>Occasionally, we may share Personal Data with other third parties such as legal and professional advisors or insurers.&nbsp;</span></span></span></span></span></li>
              <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>We may share your contact details with a university you’ve attended for the purpose of assisting that university in meeting or exceeding its required response rate for the Graduate Outcomes Survey (or such similar survey as is in place from time to time).</span></span></span></span></span></li>
            </ul>
          </li>
        </ul>
        <p className="termsescription"><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color: '#242424'}}>Partners</span></span><span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color: '#242424'}}>:&nbsp;</span></span></span></span></span></p>
        <ul  className="termsescription"style={{marginLeft:"7%"}}>
          <li className="termsescription"><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>Students</span></span></span></span></span>
            <ul style={{listStyleType: 'circle'}}>
              <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>Your Personal Data may be shared with a student who initiates a search for an opportunity which matches with your requirements</span></span></span></span></span></li>
            </ul>
          </li>
        </ul>
        
        <ul className="termsescription" style={{marginLeft:"7%"}}>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>Our service providers</span></span></span></span></span>
            <ul style={{listStyleType: 'circle'}}>
              <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>Your data may be shared with parties who process data on our behalf. We are happy to provide a list of these third-party suppliers on request.&nbsp;</span></span></span></span></span></li>
            </ul>
          </li>
          <li className="termsescription"><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>Government authorities and third parties involved in court</span></span></span></span></span>
            <ul style={{marginLeft:"7%"}}>
              <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>Any regulatory authority or statutory body pursuant to a request for information or any legal obligation which applies to us.&nbsp;</span></span></span></span></span></li>
            </ul>
          </li>
          <li className="termsescription"><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>Other third parties</span></span></span></span></span>
            <ul style={{marginLeft:"7%"}}>
              <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>Occasionally, we may share Personal Data with other third parties such as legal and professional advisors or insurers.&nbsp;</span></span></span></span></span></li>
            </ul>
          </li>
        </ul>
        <p className="termsescription"><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color: '#242424'}}>Other than the disclosures referred to in this policy, we will not disclose any Personal Data without your permission unless we are legally entitled or obliged to do so.<br />
                    &nbsp;&nbsp;<br />
                    <strong style={{color:"rgb(0,147,69)"}}>Where we store your Personal Data</strong><br />
                    &nbsp;<br />
                    Your Personal Data is generally transferred to, and stored at, a destination within the European Economic Area (EEA). It is processed by staff operating inside the EEA. We will take all steps reasonably necessary to ensure that your Personal Data is treated securely and in accordance with this privacy policy.<br />
                    &nbsp;<br />
                    However, due to the nature of our global business and the technologies required, your Personal Data may be transferred to third-party service providers outside the EEA, in countries where privacy laws may be different from those in the UK. In such situations, we transfer the minimum amount of Personal Data necessary, anonymise it (where possible) and enter legal contracts to ensure these third parties handle your Personal Data in accordance with this Privacy Policy and the European levels of data protection.</span></span></span></span></span></p>
        <p className="termsescription"><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><strong><span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color:"rgb(0,147,69)"}}>Security</span></span></strong><br />
                <span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color: '#242424'}}>&nbsp;<br />
                    GigeconoMe is committed to keeping your Personal Data safe and secure from unauthorised access to or unauthorised alterations, disclosure or destruction of information that we hold. Our security measures include:&nbsp;</span></span></span></span></span></p>
        <ul className="termsescription" style={{marginLeft:"7%"}}>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>encryption of our services and data;</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>reviewing our information collection, storage and processing practices, including physical security measures;</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>restricting access to Personal Data to GigeconoMe employees, contractors and agents who need to know that information in order to process it for us and who are subject to contractual confidentiality and processing obligations. &nbsp;They may be disciplined or their contract terminated if they fail to meet these obligations; and</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>Internal policies setting out our data security approach and training for employees.</span></span></span></span></span></li>
        </ul>
        <p className="termsescription"><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><strong><span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color:"rgb(0,147,69)"}}>How long do we keep your Personal Data for?</span></span></strong><br />
                <span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color: '#242424'}}>&nbsp;<br />
                    We will only retain your personal data for as long as necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements.</span></span></span></span></span></p>
        <p className="termsescription"><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color: '#242424'}}>To determine the appropriate retention period for personal data, we consider the amount, nature and sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal requirements.</span></span></span></span></span></p>
        <p className="termsescription"><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color: '#242424'}}>Details of retention periods for different aspects of your personal data are available in our retention policy which you can request from us.&nbsp;<br />
                    &nbsp;<br />
                    <strong style={{color:"rgb(0,147,69)"}}>Your rights</strong><br />
                    &nbsp;<br />
                    In accordance with applicable UK Data Protection Laws, you have a number of rights when it comes to your Personal Data.</span></span></span></span></span></p>
        <ul className="termsescription" style={{marginLeft:"7%"}}>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>The right to be informed</span></span></span></span></span>
            <ul style={{marginLeft:"7%"}}>
              <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>You have the right to be provided with clear, transparent and easily understandable information about how we use your Personal Data and your rights. This is why we’re providing you with the information in this policy.</span></span></span></span></span></li>
            </ul>
          </li>
          <li><span className="termsescription"className="termsescription" style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>The right of access</span></span></span></span></span>
            <ul style={{marginLeft:"7%"}}>
              <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>You have the right to obtain access to your Personal Data (if we’re processing it), and certain other information (similar to that provided in this Privacy Policy). This is so you’re aware and can check that we’re using your information in accordance with data protection law.</span></span></span></span></span></li>
            </ul>
          </li>
          <li><span className="termsescription"className="termsescription" style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>The right to rectification</span></span></span></span></span>
            <ul style={{marginLeft:"7%"}}>
              <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>You are entitled to have your Personal Data corrected if it’s inaccurate or incomplete.</span></span></span></span></span></li>
            </ul>
          </li>
          <li><span className="termsescription"className="termsescription" style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>The right to erasure</span></span></span></span></span>
            <ul className="termsescription" style={{marginLeft:"7%"}}>
              <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>This is also known as the ‘right to be forgotten’, and, in simple terms, enables you to request the deletion or removal of your Personal Data where there is no compelling reason for us to keep using it. It is not a general right to erasure, there are exceptions.</span></span></span></span></span></li>
            </ul>
          </li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>The right to restrict processing</span></span></span></span></span>
            <ul className="termsescription" style={{marginLeft:"7%"}}>
              <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>You have the right to object to certain types of processing, including processing for direct marketing (i.e. if you no longer want to be contacted with potential opportunities);</span></span></span></span></span></li>
              <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>In cases where we are processing your Personal Data on the basis of our legitimate interest, you can ask us to stop for reasons connected to your individual situation. We must then do so unless we believe we have a legitimate overriding reason to continue processing your Personal Data.</span></span></span></span></span></li>
            </ul>
          </li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>The right to data portability</span></span></span></span></span>
            <ul className="termsescription" style={{marginLeft:"7%"}}>
              <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>You have rights to obtain and reuse your Personal Data for your own purposes across different services. For example, if you decide to switch to a new provider, this enables you to move, copy or transfer your information easily between our IT systems and theirs safely and securely, without affecting its usability.</span></span></span></span></span></li>
            </ul>
          </li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>The right to lodge a complaint</span></span></span></span></span>
            <ul className="termsescription" style={{marginLeft:"7%"}}>
              <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>You have the right to lodge a complaint about the way we handle or process your Personal Data with your national data protection regulator.</span></span></span></span></span></li>
            </ul>
          </li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>The right to withdraw consent</span></span></span></span></span>
            <ul className="termsescription" style={{marginLeft:"7%"}}>
              <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>If you have given your consent to anything we do with your Personal Data, you have the right to withdraw your consent at any time (although if you do so, it does not mean that anything we have done with your Personal Data with your consent up to that point is unlawful). This includes your right to withdraw consent to us using your Personal Data for marketing purposes.</span></span></span></span></span></li>
            </ul>
          </li>
        </ul>
        <p className="termsescription"><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color: '#242424'}}>We usually act on requests and provide information free of charge, but may charge a reasonable fee to cover our administrative costs of providing the information for:</span></span></span></span></span></p>
        <ul className="termsescription" style={{marginLeft:"7%"}}>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>baseless or excessive/repeated requests; or</span></span></span></span></span></li>
          <li><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{color: '#242424'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>further copies of the same information.</span></span></span></span></span></li>
        </ul>
        <p className="termsescription"><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color: '#242424'}}>Please consider your request responsibly before submitting it. We’ll respond as soon as we can. Generally, this will be within one month from when we receive your request but, if the request is going to take longer to deal with, we’ll come back to you and let you know. You will not have to pay a fee to access your personal data (or to exercise any of the other rights). However, we may charge a fee if your request is clearly unfounded, repetitive or excessive. Alternatively, we may refuse to comply with your request in these circumstances.</span></span></span></span></span></p>
        <p className="termsescription"><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color: '#242424'}}>We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal data (or to exercise any of your other rights). This is a security measure to ensure that personal data is not disclosed to any person who has no right to receive it. We may also contact you to ask for further information in relation to your request to speed up our response.&nbsp;<br />
                    &nbsp;<br />
                    If you would like to exercise any of your rights, please send an email to&nbsp;</span></span><u><span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color: 'blue'}}>admin@gigeconome.com</span></span></u><span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color: '#242424'}}>.&nbsp;<u>Please do not forget to tell us who you are.</u>&nbsp;<br />
                    &nbsp;&nbsp;<br />
                    <strong style={{color:"rgb(0,147,69)"}}>Third Party Privacy Policies</strong></span></span></span></span></span></p>
        <p className="termsescription"><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color: '#242424'}}>Our website and some emails contain links to and from the websites of our partner networks, third-party employers, advertisers and affiliates. If you follow a link to any of these websites, please note that these websites and any services that may be accessible through them have their own privacy policies and that we do not accept any responsibility or liability for these policies, or for any Personal Data that may be collected through these websites or services, such as contact and location data. Please check these policies before you submit any Personal Data to these websites or use these services.<br />
                    &nbsp;<br />
                    <strong style={{color:"rgb(0,147,69)"}}>Changes to Privacy Policy</strong><br />
                    &nbsp;<br />
                    Any changes we make to our Privacy Policy in the future will be posted on this page and, where appropriate, notified to you by email.&nbsp;</span></span></span></span></span></p>
        <p className="termsescription"><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color: '#242424'}}>It is important that the personal data we hold about you is accurate and current. Please keep us informed if your personal data changes during your relationship with us.&nbsp;<br />
                    &nbsp;<br />
                    <strong style={{color:"rgb(0,147,69)"}}>Contact</strong><br />
                    &nbsp;<br />
                    The data controller responsible for your information is GigeconoMe.<br />
                    &nbsp;<br />
                    If you have any questions, comments or requests regarding this privacy policy or our use of your Personal Data, you can email&nbsp;</span></span><span className="termsescription"style={{color: 'black'}}><a href="mailto:admin@gigeconome.com" style={{color: 'blue', textDecoration: 'underline'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>admin@gigeconome.com</span></a></span><span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color: '#242424'}}>. If you do not think we are handling your Personal Data adequately, you have the right to lodge a complaint with the Information Commissioner’s Office. Further information, including contact details, are available at&nbsp;</span></span><span className="termsescription"style={{color: 'black'}}><a href="https://ico.org.uk/" style={{color: 'blue', textDecoration: 'underline'}}><span className="termsescription"style={{fontSize: '12.0pt'}}>https://ico.org.uk</span></a></span><br />
                <span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color: '#242424'}}>&nbsp;<br />
                    <strong style={{color:"rgb(0,147,69)"}}>Unauthorised Access</strong></span></span></span></span></span></p>
        <p className="termsescription"><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color: '#242424'}}>If a GigeconoMe partner thinks an unauthorised person has become aware of any GigeconoMe password, they should contact GigeconoMe immediately. We cannot be responsible for any unauthorised use of a Partners’ profile or unauthorised activity on the Website.</span></span></span></span></span></p>
        <p className="termsescription"><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><strong><span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color:"rgb(0,147,69)"}}>Transfer of Ownership&nbsp;</span></span></strong></span></span></span></p>
        <p className="termsescription"><span className="termsescription"style={{fontSize: '11pt'}}><span className="termsescription"style={{backgroundColor: 'white'}}><span className="termsescription"style={{fontFamily: 'Gotham'}}><span className="termsescription"style={{fontSize: '12.0pt'}}><span className="termsescription"style={{color: '#242424'}}>If we sell or transfer GigeconoMe to another company, this may transfer all of our rights and obligations under these terms and conditions without any further consent and may disclose or transfer all information we hold about Partners or prospective Partners to a prospective or actual new owner. Such a disclosure or transfer will not alter the rights of such Partners in respect of the use that can be made of such information by such other company.</span></span></span></span></span></p>
        <p>&nbsp;</p>     
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>{/* End #main */}
    <Footer />
        </div>
        )
    }
}
export default Privacy;