import React, { useState } from "react";

import "./modal.css";

function close(){
    window.location.reload();
  }
export default class InterestsEditCom extends React.Component {
 
  constructor() {
    super();
    let comments = JSON.parse(sessionStorage.getItem("someData"));
   
   console.log(comments)
    this.state = {
      
    
     
      charLimit: 0,
      userid: comments,
      addcpydata :"cpyinterests",
      interests_inputdata:"",
      error:"",
      summary:"",
      showModal: false,
  
  }
  this.handleSubmit = this.handleSubmit.bind(this);
}

  handleSubmit = (event) => {
    event.preventDefault()
    if(this.state.interests_inputdata.length>=500){
      this.setState({error:"You have exceeded the maximum number of 500 characters in that field"})
    }
    else{
    const data = {

      userid: this.state.userid,
      addcpydata: this.state.addcpydata,
      interests_inputdata:this.state.interests_inputdata,
      success:"",
      Summary:"",
      error:""

    }
  
    let BaseUrl = JSON.parse(sessionStorage.getItem("baseurl"));

      fetch("https://www.gigeconome.com:5200/api/addcpy_profiledata", {
        method: "POST",

        body: JSON.stringify(data), // data can be `string` or {object}!

        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())

        .catch((error) => console.error("Error:", error))

        .then((response) => {
          console.log(response)
          window.location.reload();
          this.setState({
            success:response.message
          })
         
        });
    }
  }
    handleChange = (e) => {
      this.setState({
        [e.target.name]: e.target.value,
       
      });
      
    };

    openModal = () => {
      this.setState({ showModal: true });
    };
  
    closeModal = () => {
      this.setState({ showModal: false });
    };
  
  
componentDidMount() {
    const data_two={userid:this.state.userid}
    fetch('https://www.gigeconome.com:5200/api/cpyprofileinfo', { method: 'POST', 
  
    body: JSON.stringify(data_two), // data can be `string` or {object}!
  
    headers:{ 'Content-Type': 'application/json' } })
  
    .then(res => res.json())
  
    .catch(error => console.error('Error:', error))
  
    .then((json)=>{
     
      var dataStoree = JSON.stringify(json.user.firstname );
      sessionStorage.setItem('DataName', dataStoree);
            this.setState({
              interests_inputdata:json.user.cpyprofleinterest
            });
            
        })
  }
 render(){
const {success}=this.state
const {error}=this.state
const {Summary}=this.state
  return (
    <>
     {this.state.showModal && (
      <div class="ft-modal" id="ft-interesteditcom-modal">
        <div class="ft-modal-content">
          <div class="ft-modal-header">
            <div class="header">
              <h5
                style={{
                  textAlign: "center",
                  fontSize: 25,
                  fontWeight: "bold",
                }}
              >
                Edit Interests
              </h5>
              <hr style={{marginRight:"-4%",marginLeft:"-4.2%",height:1}} />
            </div>
          </div>
          <div class="ft-modal-body">
          
            <div class="row mt-3">
              <div class="col-sm-1"></div>
              <div class="col-sm-10">
              <form onSubmit = {this.handleSubmit}>
                <div class="form-group">
                  
                  <textarea
                    class="form-control"
                    value={this.state.interests_inputdata}
                    id="interests_inputdata"
                    rows="10"
                    name ="interests_inputdata" 
                     onChange = {this.handleChange}
                  >
                  
                  </textarea>
                <b style={{float:'right',color:'rgb(0,147,69)',fontSize:16,marginTop:'1.5%'}}>{this.state.charLimit + this.state.interests_inputdata.length} / 500</b>
                  <span style={{color:'rgb(0,147,69)',marginTop:"2%",
                  fontFamily:"Gotham",fontWeight:'bold'}}>{success}</span>

<span style={{color:'red',marginTop:"2%",fontSize:12,
                  fontFamily:"Gotham",fontWeight:'bold'}}>{this.state.error}</span>
                </div>
                <div class="modal-footer mt-4">
            
              <div class="col-sm-3">
              <a class="ft-modal-close" href="#" onClick={()=>close()}
                  style={{ backgroundColor: "gray", width: 100 }}
                  className="btn btn-success"
                >
                  Cancel
                </a>
              </div>
              <div class="col-sm-3">
                <button
                  style={{ backgroundColor: "rgb(0, 147, 69)", width: 100,marginLeft:"30%" }}
                  className="btn btn-success"
                  type="submit"
                
                >
                  Save
                </button>
              </div>
              <div class="col-sm-2"></div>
            </div>
                </form>
              </div>
            </div>

            
          </div>
          <div class="ft-modal-footer editprofilesummarymodal">
            <a class="ft-modal-close"  data-dismiss="modal"
                     onClick={this.closeModal}>
              [&#10006;]{" "}
            </a>
          </div>
        </div>
      </div>
     )}
      <a
        href="#ft-interesteditcom-modal"
        className="btn-success btn-sm mt-1 editbtninterest "
        style={{
         float:'right',
          width: 100,
          backgroundColor: "rgb(0, 147, 69)",
          fontFamily: "GothamLight",
          textAlign: "center",
        }}
        onClick={this.openModal}
      >
       Edit
      </a>
    </>
  );
}
}