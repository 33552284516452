import React from "react";
import ReactPaginate from 'react-paginate';
import Footer from "../Footer/Footer";
import axios from 'axios'
import "./navbar.css";
import Invite from "../../images/invitenew.png";
import DummyImage from "../../images/dummyimage.png";
import LoadingIcon from "../../images/loading2.gif";
import { Link } from "react-router-dom";
import Header from "../Header/Header";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
class CompanySearch extends React.Component {

  constructor() {
   // let s_text=sessionStorage.getItem("SearchText")
    super();
    
    this.state = {
        searchtext:"",
        data: [],
        JobCategory:[],
        OpportunityType:[],
        Course:[],
        Industry:[],
        Language:[],
        Location:[],
        offset: 0,
        data: [],
        perPage: 1000,
        currentPage: 0,
        totalcount:"",
        //s_text:s_text,
        stjobcategory:"",
        stopportunitytypes:"",
        stdegreesubject:"",
        studenttype:"",
        studentstatus:"",
        storgsize:"",
        stindustry:"",
        stlocation:"",
        stlanguage:"",
        searchJob:[],
        keyGen:[],
        DataisLoaded: false,
        keyLanguage:[],
        keyLocation:[],
        keyCourse:[],
        keyOpportunity:[],
        keyIndustry:[],
        keyOrgsize:[],
        keyStype:[],
        formattedString:[],
        cpyjobcategory:"",
        cpyopportunitytypes:"",
        cpyorgsize:"",
        cpyindustry:"",
        cpylocation:"",
        checked:true,
        cpyOpp:[],
        mobilescreen:0

    }
    this.handlePageClick = this
    .handlePageClick
    .bind(this);
    this.handleSearch=this.handleSearch.bind(this);
    this.updateStateList=this.updateStateList.bind(this);
    this.updateLanguage=this.updateLanguage.bind(this);
    this.updateLocation=this.updateLocation.bind(this);
    this.handleCourse=this.handleCourse.bind(this);
    this.updateOpportunity=this.updateOpportunity.bind(this);
    this.updateIndustry=this.updateIndustry.bind(this);
    this.updateOrgsize=this.updateOrgsize.bind(this);
    this.updateStype=this.updateStype.bind(this);
    this.updateStatus=this.updateStatus.bind(this);
    //this.userID=this.userID.bind(this);
    this.handleCheck=this.handleCheck.bind(this)
     this.refresh=this.refresh.bind(this);
     this.handleMobile=this.handleMobile.bind(this)
}
handleMobile(){
  this.setState({mobilescreen:1})
}

userID(id){
  sessionStorage.setItem("someData", id);
  window.location.href = `./StudentPublishProfile`;
}
 refresh(){
  window.location.reload();
  sessionStorage.removeItem("SearchText");
  sessionStorage.setItem("SearchText","");
}


updateStatus(e){
  if (e.target.checked){
    //console.log(e.target.checked)
    
      const sdata = {

        searchtext:"",
        stjobcategory:"",
        stopportunitytypes:"",
        stdegreesubject:"",
        studenttype:"",
        studentstatus:e.target.value,
        storgsize:"",
        stindustry:"",
        stlocation:"",
        stlanguage:"",
     
      
     
 
    }
    console.log(sdata)
   axios
       .post(`https://www.gigeconome.com:5200/api/getsearchinfo`,sdata)
       .then(res => {
         console.log(res)
         if(res.data.message=="No Search Result Found."){

           this.setState({posData:[],postData:res.data.message})
           //console.log(res.data.message)
           this.setState({totalcount:res.data.totalcount,DataisLoaded: false,
            s_text:""
          })
         }
         else{
           const data = res.data.result;
           //console.log(data)
           this.setState({totalcount:res.data.totalcount,DataisLoaded: true,
            s_text:""
          })
           const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
          
           const postData = slice.map(user => 
           
           <React.Fragment>
           
          <div className="col-sm-4">
          <div class="searchcard_one" >
                      <div class="searchcard-bodycompany" >
                          <div class="searchcard-img-actionstwo"> 
                          <span
                          style={{borderRadius:"50%",float:"right",width:"9%",margin:"1.5%",backgroundColor:"#fff"}}>
                            <i class="fa fa-star" aria-hidden="true" style={{color:"rgb(0,147,69)"}}></i></span>

                          
                          <img src={DummyImage} class="card-img img-fluid" width="96" height="350" alt=""
                          id="companysearch_img"
                          
                          /> </div>
                      </div>
                      <div class="searchcard-bodycompany">
                          <div style={{textAlign:'left'}}>
                          <h6>
                            <span 
                          style={{color:"rgb(0,147,69)",
                          fontFamily:"Gotham",
                          fontSize:17
                        }}>{user.guser_id}</span> 
                       
                         <span style={{float:"right",fontFamily:"Gotham"}}>
                            Availability </span> 
                            <span style={{marginRight:"1%",color:"rgb(0,147,69)",float:"right"}}><i className="fa fa-clock-o"></i></span> 
                            </h6>
                         
                            <h6>
 
                       
                         <span >
                            <img src={Invite} style={{float:"right",width:"25%",cursor:"pointer"}} /></span> 
                            
                            </h6>
      
                            <h6 style={{marginLeft:"3%",marginTop:"5%",fontSize:13,textAlign:'left',width:"100%",fontFamily:"GothamLight",fontWeight:"bold"}}>
                            <span style={{marginRight:"3%",color:"rgb(0,147,69)"}}><i className="fa fa-user"></i></span> {user.StudyStatus}</h6>
                            <h6 style={{marginLeft:"2%",marginTop:"0%",fontSize:13,textAlign:'left',width:"100%",fontFamily:"GothamLight",fontWeight:"bold"}}>
                            <span style={{marginRight:"1%",color:"rgb(0,147,69)"}}><i class="fa fa-graduation-cap"></i></span> &nbsp;{user.QualificationType} in {user.Course}</h6>
                            <h6 style={{marginLeft:"3%",marginTop:"0%",fontSize:13,textAlign:'left',width:"100%",fontFamily:"GothamLight",fontWeight:"bold"}}>
                            <span style={{marginRight:"4%",color:"rgb(0,147,69)"}}><i class="fa fa-calendar"></i></span>Graduation Year : {user.GraduationYear}</h6>
                            <h6 style={{marginLeft:"4%",marginTop:"0%",fontSize:13,textAlign:'left',width:"100%",fontFamily:"GothamLight",fontWeight:"bold"}}>
                            <span style={{marginRight:"4%",color:"rgb(0,147,69)"}}><i class="fa fa-map-marker" style={{fontSize:14}}></i></span>{user.locationname}</h6>
      
                            {user.summary==""?null:<>
                        <h6 style={{fontSize:11,textAlign:'left',width:"100%",fontWeight:"bold",height:50}}>
                              
                              {user.summary}...<span style={{color:"rgb(0,147,69)",fontFamily:"Gotham",fontSize:11}}>more</span></h6>
                        
                        </>}

      
      
      
                          </div>
                         
      
                         
                          <div  style={{textAlign:"left",height:71}}>
                          {user.skillname.split(",")
  .map((item, i) => <span class="col-md-2">  {item}  </span>)}  
</div>
             
      
      
                          
                          
                      </div>
                      <Link 
                      target="_blank"
                      to={{ 
                       
                        pathname: `/studentpublishprofile/${user.userid}`, 
                         
                      }}
                     
                       id="view_searchbtn">View {user.guser_id}</Link>
                  </div>
   </div>
 
        
      
      
      
      
     
               
               
            
           </React.Fragment>
           )
           this.setState({
             pageCount: Math.ceil(data.length / this.state.perPage),
            
             postData,
             DataisLoaded: true,
        
           })
         }
        
 
          
       });

  }
  else{
    this.setState({keyStype : this.state.keyStype.filter(function(val) {return val!==e.target.value})},()=>{
      const sdata = {

        searchtext:"",
        stjobcategory:this.state.keyGen.toString(),
        stopportunitytypes:this.state.keyOpportunity.toString(),
        stdegreesubject:this.state.keyCourse.toString(),
        studenttype:this.state.keyStype.toString(),
        studentstatus:this.state.studentstatus,
        storgsize:this.state.keyOrgsize.toString(),
        stindustry:this.state.keyIndustry.toString(),
        stlocation:this.state.keyLocation.toString(),
        stlanguage:this.state.keyLanguage.toString(),
     
      
     
 
    }
    //console.log(sdata)
   axios
       .post(`https://www.gigeconome.com:5200/api/getsearchinfo`,sdata)
       .then(res => {
         //console.log(res)

         if(res.data.message=="No Search Result Found."){
           this.setState({postData:res.data.message})
           //console.log(res.data.message)
           this.setState({totalcount:res.data.totalcount,DataisLoaded: true,
            s_text:""
          })
         }
         else{
           const data = res.data.result;
           //console.log(data)
  this.setState({totalcount:res.data.totalcount,DataisLoaded: true,
            s_text:""
          })
           const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
           const postData = slice.map(user => 
           
           <React.Fragment>
          
          <div className="col-sm-4">
          <div class="searchcard_one" >
                      <div class="searchcard-bodycompany" >
                          <div class="searchcard-img-actionstwo"> 
                          <span
                          style={{borderRadius:"50%",float:"right",width:"9%",margin:"1.5%",backgroundColor:"#fff"}}>
                            <i class="fa fa-star" aria-hidden="true" style={{color:"rgb(0,147,69)"}}></i></span>

                          
                          <img src={DummyImage} class="card-img img-fluid" width="96" height="350" alt=""
                          id="companysearch_img"
                          
                          /> </div>
                      </div>
                      <div class="searchcard-bodycompany">
                          <div style={{textAlign:'left'}}>
                          <h6>
                            <span 
                          style={{color:"rgb(0,147,69)",
                          fontFamily:"Gotham",
                          fontSize:17
                        }}>{user.guser_id}</span> 
                       
                         <span style={{float:"right",fontFamily:"Gotham"}}>
                            Availability </span> 
                            <span style={{marginRight:"1%",color:"rgb(0,147,69)",float:"right"}}><i className="fa fa-clock-o"></i></span> 
                            </h6>
                         
                            <h6>
 
                       
                         <span >
                            <img src={Invite} style={{float:"right",width:"25%",cursor:"pointer"}} /></span> 
                            
                            </h6>
      
                            <h6 style={{marginLeft:"3%",marginTop:"5%",fontSize:13,textAlign:'left',width:"100%",fontFamily:"GothamLight",fontWeight:"bold"}}>
                            <span style={{marginRight:"3%",color:"rgb(0,147,69)"}}><i className="fa fa-user"></i></span> {user.StudyStatus}</h6>
                            <h6 style={{marginLeft:"2%",marginTop:"0%",fontSize:13,textAlign:'left',width:"100%",fontFamily:"GothamLight",fontWeight:"bold"}}>
                            <span style={{marginRight:"1%",color:"rgb(0,147,69)"}}><i class="fa fa-graduation-cap"></i></span> &nbsp;{user.QualificationType} in {user.Course}</h6>
                            <h6 style={{marginLeft:"3%",marginTop:"0%",fontSize:13,textAlign:'left',width:"100%",fontFamily:"GothamLight",fontWeight:"bold"}}>
                            <span style={{marginRight:"4%",color:"rgb(0,147,69)"}}><i class="fa fa-calendar"></i></span>Graduation Year : {user.GraduationYear}</h6>
                            <h6 style={{marginLeft:"4%",marginTop:"0%",fontSize:13,textAlign:'left',width:"100%",fontFamily:"GothamLight",fontWeight:"bold"}}>
                            <span style={{marginRight:"4%",color:"rgb(0,147,69)"}}><i class="fa fa-map-marker" style={{fontSize:14}}></i></span>{user.locationname}</h6>
      
                            {user.summary==""?null:<>
                        <h6 style={{fontSize:11,textAlign:'left',width:"100%",fontWeight:"bold",height:50}}>
                              
                              {user.summary}...<span style={{color:"rgb(0,147,69)",fontFamily:"Gotham",fontSize:11}}>more</span></h6>
                        
                        </>}

      
      
      
                          </div>
                         
      
                         
                          <div  style={{textAlign:"left",height:71}}>
                          {user.skillname.split(",")
  .map((item, i) => <span class="col-md-2">  {item}  </span>)}  
</div>
             
      
      
                          
                          
                      </div>
                      <Link 
                      target="_blank"
                      to={{ 
                       
                        pathname: `/studentpublishprofile/${user.userid}`, 
                         
                      }}
                     
                       id="view_searchbtn">View {user.guser_id}</Link>
                  </div>
   </div>
 
        
      
      
      
      
     
               
               
            
           </React.Fragment>
           )
           this.setState({
             pageCount: Math.ceil(data.length / this.state.perPage),
            
             postData, DataisLoaded: true,
        
           })
         }
        
 
          
       });
    })
  }

}










updateStype(e){
  if (e.target.checked){
    //console.log(e.target.checked)
    this.setState({keyStype: this.state.keyStype.concat([e.target.value])},()=>{
      const sdata = {

        searchtext:"",
        stjobcategory:this.state.keyGen.toString(),
        stopportunitytypes:this.state.keyOpportunity.toString(),
        stdegreesubject:this.state.keyCourse.toString(),
        studenttype:this.state.keyStype.toString(),
        studentstatus:this.state.studentstatus,
        storgsize:this.state.keyOrgsize.toString(),
        stindustry:this.state.keyIndustry.toString(),
        stlocation:this.state.keyLocation.toString(),
        stlanguage:this.state.keyLocation.toString(),
     
      
     
 
    }
    //console.log(sdata)
   axios
       .post(`https://www.gigeconome.com:5200/api/getsearchinfo`,sdata)
       .then(res => {
         //console.log(res)
         if(res.data.message=="No Search Result Found."){
           this.setState({postData:res.data.message})
           //console.log(res.data.message)
           this.setState({totalcount:res.data.totalcount,DataisLoaded: true,
            s_text:""
          })
         }
         else{
           const data = res.data.result;
           //console.log(data)
           this.setState({totalcount:res.data.totalcount,DataisLoaded: true,
            s_text:""
          })
           const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
           const postData = slice.map(user => 
           
           <React.Fragment>
          
          <div className="col-sm-4">
          <div class="searchcard_one" >
                      <div class="searchcard-bodycompany" >
                          <div class="searchcard-img-actionstwo"> 
                          <span
                          style={{borderRadius:"50%",float:"right",width:"9%",margin:"1.5%",backgroundColor:"#fff"}}>
                            <i class="fa fa-star" aria-hidden="true" style={{color:"rgb(0,147,69)"}}></i></span>

                          
                          <img src={DummyImage} class="card-img img-fluid" width="96" height="350" alt=""
                          id="companysearch_img"
                          
                          /> </div>
                      </div>
                      <div class="searchcard-bodycompany">
                          <div style={{textAlign:'left'}}>
                          <h6>
                            <span 
                          style={{color:"rgb(0,147,69)",
                          fontFamily:"Gotham",
                          fontSize:17
                        }}>{user.guser_id}</span> 
                       
                         <span style={{float:"right",fontFamily:"Gotham"}}>
                            Availability </span> 
                            <span style={{marginRight:"1%",color:"rgb(0,147,69)",float:"right"}}><i className="fa fa-clock-o"></i></span> 
                            </h6>
                         
                            <h6>
 
                       
                         <span >
                            <img src={Invite} style={{float:"right",width:"25%",cursor:"pointer"}} /></span> 
                            
                            </h6>
      
                            <h6 style={{marginLeft:"3%",marginTop:"5%",fontSize:13,textAlign:'left',width:"100%",fontFamily:"GothamLight",fontWeight:"bold"}}>
                            <span style={{marginRight:"3%",color:"rgb(0,147,69)"}}><i className="fa fa-user"></i></span> {user.StudyStatus}</h6>
                            <h6 style={{marginLeft:"2%",marginTop:"0%",fontSize:13,textAlign:'left',width:"100%",fontFamily:"GothamLight",fontWeight:"bold"}}>
                            <span style={{marginRight:"1%",color:"rgb(0,147,69)"}}><i class="fa fa-graduation-cap"></i></span> &nbsp;{user.QualificationType} in {user.Course}</h6>
                            <h6 style={{marginLeft:"3%",marginTop:"0%",fontSize:13,textAlign:'left',width:"100%",fontFamily:"GothamLight",fontWeight:"bold"}}>
                            <span style={{marginRight:"4%",color:"rgb(0,147,69)"}}><i class="fa fa-calendar"></i></span>Graduation Year : {user.GraduationYear}</h6>
                            <h6 style={{marginLeft:"4%",marginTop:"0%",fontSize:13,textAlign:'left',width:"100%",fontFamily:"GothamLight",fontWeight:"bold"}}>
                            <span style={{marginRight:"4%",color:"rgb(0,147,69)"}}><i class="fa fa-map-marker" style={{fontSize:14}}></i></span>{user.locationname}</h6>
      
                            {user.summary==""?null:<>
                        <h6 style={{fontSize:11,textAlign:'left',width:"100%",fontWeight:"bold",height:50}}>
                              
                              {user.summary}...<span style={{color:"rgb(0,147,69)",fontFamily:"Gotham",fontSize:11}}>more</span></h6>
                        
                        </>}

      
      
      
                          </div>
                         
      
                         
                          <div  style={{textAlign:"left",height:71}}>
                          {user.skillname.split(",")
  .map((item, i) => <span class="col-md-2">  {item}  </span>)}  
</div>
             
      
      
                          
                          
                      </div>
                      <Link 
                      target="_blank"
                      to={{ 
                       
                        pathname: `/studentpublishprofile/${user.userid}`, 
                         
                      }}
                     
                       id="view_searchbtn">View {user.guser_id}</Link>
                  </div>
   </div>
 
        
      
      
      
      
     
               
               
            
           </React.Fragment>
           )
           this.setState({
             pageCount: Math.ceil(data.length / this.state.perPage),
            
             postData,
             DataisLoaded: true,
        
           })
         }
        
 
          
       });
    })
  }
  else{
    this.setState({keyStype : this.state.keyStype.filter(function(val) {return val!==e.target.value})},()=>{
      const sdata = {

        searchtext:"",
        stjobcategory:this.state.keyGen.toString(),
        stopportunitytypes:this.state.keyOpportunity.toString(),
        stdegreesubject:this.state.keyCourse.toString(),
        studenttype:this.state.keyStype.toString(),
        studentstatus:this.state.studentstatus,
        storgsize:this.state.keyOrgsize.toString(),
        stindustry:this.state.keyIndustry.toString(),
        stlocation:this.state.keyLocation.toString(),
        stlanguage:this.state.keyLanguage.toString(),
     
      
     
 
    }
    //console.log(sdata)
   axios
       .post(`https://www.gigeconome.com:5200/api/getsearchinfo`,sdata)
       .then(res => {
         //console.log(res)
         if(res.data.message=="No Search Result Found."){
           this.setState({postData:res.data.message})
           //console.log(res.data.message)
           this.setState({totalcount:res.data.totalcount,DataisLoaded: true,
            s_text:""
          })
         }
         else{
           const data = res.data.result;
           //console.log(data)
           this.setState({totalcount:res.data.totalcount,DataisLoaded: true,
            s_text:""
          })
           const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
           const postData = slice.map(user => 
           
           <React.Fragment>
          
          <div className="col-sm-4">
          <div class="searchcard_one" >
                      <div class="searchcard-bodycompany" >
                          <div class="searchcard-img-actionstwo"> 
                          <span
                          style={{borderRadius:"50%",float:"right",width:"9%",margin:"1.5%",backgroundColor:"#fff"}}>
                            <i class="fa fa-star" aria-hidden="true" style={{color:"rgb(0,147,69)"}}></i></span>

                          
                          <img src={DummyImage} class="card-img img-fluid" width="96" height="350" alt=""
                          id="companysearch_img"
                          
                          /> </div>
                      </div>
                      <div class="searchcard-bodycompany">
                          <div style={{textAlign:'left'}}>
                          <h6>
                            <span 
                          style={{color:"rgb(0,147,69)",
                          fontFamily:"Gotham",
                          fontSize:17
                        }}>{user.guser_id}</span> 
                       
                         <span style={{float:"right",fontFamily:"Gotham"}}>
                            Availability </span> 
                            <span style={{marginRight:"1%",color:"rgb(0,147,69)",float:"right"}}><i className="fa fa-clock-o"></i></span> 
                            </h6>
                         
                            <h6>
 
                       
                         <span >
                            <img src={Invite} style={{float:"right",width:"25%",cursor:"pointer"}} /></span> 
                            
                            </h6>
      
                            <h6 style={{marginLeft:"3%",marginTop:"5%",fontSize:13,textAlign:'left',width:"100%",fontFamily:"GothamLight",fontWeight:"bold"}}>
                            <span style={{marginRight:"3%",color:"rgb(0,147,69)"}}><i className="fa fa-user"></i></span> {user.StudyStatus}</h6>
                            <h6 style={{marginLeft:"2%",marginTop:"0%",fontSize:13,textAlign:'left',width:"100%",fontFamily:"GothamLight",fontWeight:"bold"}}>
                            <span style={{marginRight:"1%",color:"rgb(0,147,69)"}}><i class="fa fa-graduation-cap"></i></span> &nbsp;{user.QualificationType} in {user.Course}</h6>
                            <h6 style={{marginLeft:"3%",marginTop:"0%",fontSize:13,textAlign:'left',width:"100%",fontFamily:"GothamLight",fontWeight:"bold"}}>
                            <span style={{marginRight:"4%",color:"rgb(0,147,69)"}}><i class="fa fa-calendar"></i></span>Graduation Year : {user.GraduationYear}</h6>
                            <h6 style={{marginLeft:"4%",marginTop:"0%",fontSize:13,textAlign:'left',width:"100%",fontFamily:"GothamLight",fontWeight:"bold"}}>
                            <span style={{marginRight:"4%",color:"rgb(0,147,69)"}}><i class="fa fa-map-marker" style={{fontSize:14}}></i></span>{user.locationname}</h6>
      
                            {user.summary==""?null:<>
                        <h6 style={{fontSize:11,textAlign:'left',width:"100%",fontWeight:"bold",height:50}}>
                              
                              {user.summary}...<span style={{color:"rgb(0,147,69)",fontFamily:"Gotham",fontSize:11}}>more</span></h6>
                        
                        </>}

      
      
      
                          </div>
                         
      
                         
                          <div  style={{textAlign:"left",height:71}}>
                          {user.skillname.split(",")
  .map((item, i) => <span class="col-md-2">  {item}  </span>)}  
</div>
             
      
      
                          
                          
                      </div>
                      <Link 
                      target="_blank"
                      to={{ 
                       
                        pathname: `/studentpublishprofile/${user.userid}`, 
                         
                      }}
                     
                       id="view_searchbtn">View {user.guser_id}</Link>
                  </div>
   </div>
 
        
      
      
      
      
     
               
               
            
           </React.Fragment>
           )
           this.setState({
             pageCount: Math.ceil(data.length / this.state.perPage),
            
             postData, DataisLoaded: true,
        
           })
         }
        
 
          
       });
    })
  }

}


updateOrgsize(e,value){
  if(this.state.searchtext==null){
    this.setState({searchtext:""})
  }
  if (e.target.checked){
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // for smoothly scrolling
    });
    this.setState({DataisLoaded:false,mobilescreen:0})
    //console.log(e.target.checked)
    this.setState({keyOrgsize: this.state.keyOrgsize.concat([value])},()=>{
      const sdata = {

        searchtext:this.state.searchtext,
        cpyjobcategory:this.state.keyGen.toString(),
        cpyopportunitytypes:this.state.keyOpportunity.toString(),
        cpyorgsize:this.state.keyOrgsize.toString(),
        cpyindustry:this.state.keyIndustry.toString(),
        cpylocation:this.state.keyLocation.toString(),
       
     
      
     
 
    }
    console.log(sdata)
   axios
       .post(`https://www.gigeconome.com:5200/api/getcpysearchdemoinfo`,sdata)
       .then(res => {
         //console.log(res)
         if(res.data.message=="No Search Result Found."){
           this.setState({postData:res.data.message})
           //console.log(res.data.message)
           this.setState({totalcount:res.data.totalcount,DataisLoaded: true,
            s_text:""
          })
         }
         else{
           const data = res.data.result;
           //console.log(data)
           this.setState({totalcount:res.data.totalcount,DataisLoaded: true,
            s_text:""
          })
           const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
           const postData = slice.map(user => 
            <React.Fragment>
         
            <div className="col-sm-3 col-md-4 mbleview bigcompanysearch">
            <div class="searchcard_one" >
                         <div class="searchcard-bodycompany" style={{padding:"2%"}}>
                             <div class="searchcard-img-actionstwo"> 
                             <span
                             className="cpyfavicon">
                               <i class="fa fa-star" aria-hidden="true" style={{color:"rgb(0,147,69)"}}></i></span>
   
                             
                            {user.cpylogo==""?<>
                           
                            <span id="logotxt">LOGO</span>
                            </>:<> <img src={user.cpylogo} class="card-img img-fluid" width="96" height="350" alt=""
                             id="companysearch_img" style={{backgroundColor:"white"}}
                             
                             /> </>}
                              
                             
                            </div>
                         </div>
                         <div class="searchcard-bodycompany">
                             <div className="companyserachbox">
                             <h6>
                               <span 
                             className="cpysearchname">{user.companyname}</span> 
                          
                           
                             
                               </h6>
          
                               <h6>
                               {user.industryname==" "?<>
                                  <span 
                              className="cpycatgoryname">Category Name Not Avaliable.</span> 
                                  
                                  </>:<>
                                  
                                  <span 
                                 className="cpycatgoryname">{user.industryname}</span> 
                                  </>  }
                          
                           
                             
                               </h6>
                               {user.locationname=="" ?<>
                               <span style={{marginRight:"4%",color:"rgb(0,147,69)"}}><i class="fa fa-map-marker" style={{fontSize:14}}></i></span>
                               
                              Location Not Avaliable.
                               </>:<>
                               <span style={{marginRight:"4%",color:"rgb(0,147,69)"}}><i class="fa fa-map-marker" style={{fontSize:14}}></i></span>
                               <span className="cpycatgoryname">{user.locationname}</span>
                               
                               </>}
                              
         
                               {user.cpysummary=="" || user.cpysummary==null?
                               <h6 style={{fontSize:12,textAlign:'left',
                               fontFamily:"GothamLight",
                               width:"100%",fontWeight:"bold",height:70,marginTop:"5%",color:"transparent"}}>Please Add your summaary</h6>
                               :<>
                           <h6 style={{fontSize:12,textAlign:'left',
                           fontFamily:"GothamLight",
                           width:"100%",fontWeight:"bold",height:70,marginTop:"5%"}}>
                                 
                                 {user.cpysummary}...<span style={{color:"rgb(0,147,69)",fontFamily:"Gotham",fontSize:11}}>more</span></h6>
                           
                           </>}
   
          
                           <div className="cpyopportunity">
   
                             {user.opportunitiestype==null?null
                             :<>
                             {user.opportunitiestype.split(",")
     .map((item, i) => <span class="col-md-2">  {item}  </span>)} 
                             </>}
                             
   </div>
         
         
                             </div>
                            
         
                            
                             
                
         
         
                             
                             
                         </div>
                         <Link 
                         target="_blank"
                         to={{ 
                          
                           pathname: `/viewgigprofile/${user.userid}`, 
                            
                         }}
                        
                          id="view_searchbtn">View {user.companyname}</Link>
                     </div>
     </div>
   
          
        
        
        
        
       
                 
                 
              
             </React.Fragment>
           )
           this.setState({
             pageCount: Math.ceil(data.length / this.state.perPage),
            
             postData,
            
        
           })
           const postDatatwo = slice.map(user => 
          
            <React.Fragment>
           
           <div className="col-sm-3 col-md-4">
           <div class="searchcard_one" >
                        <div class="searchcard-bodycompany" style={{padding:"2%"}}>
                            <div class="searchcard-img-actionstwo"> 
                            <span
                            className="cpyfavicon">
                              <i class="fa fa-star" aria-hidden="true" style={{color:"rgb(0,147,69)"}}></i></span>
  
                            
                           {user.cpylogo==""?<>
                          
                           <span id="logotxt">LOGO</span>
                           </>:<> <img src={user.cpylogo} class="card-img img-fluid" width="96" height="350" alt=""
                            id="companysearch_img" style={{backgroundColor:"white"}}
                            
                            /> </>}
                             
                            
                           </div>
                        </div>
                        <div class="searchcard-bodycompany">
                            <div className="companyserachbox">
                            <h6>
                              <span 
                            className="cpysearchname">{user.companyname}</span> 
                         
                          
                            
                              </h6>
         
                              <h6>
                              {user.industryname==" "?<>
                                 <span 
                             className="cpycatgoryname">Category Name Not Avaliable.</span> 
                                 
                                 </>:<>
                                 
                                 <span 
                                className="cpycatgoryname">{user.industryname}</span> 
                                 </>  }
                         
                          
                            
                              </h6>
                              {user.locationname=="" ?<>
                              <span style={{marginRight:"4%",color:"rgb(0,147,69)"}}><i class="fa fa-map-marker" style={{fontSize:14}}></i></span>
                              
                             Location Not Avaliable.
                              </>:<>
                              <span style={{marginRight:"4%",color:"rgb(0,147,69)"}}><i class="fa fa-map-marker" style={{fontSize:14}}></i></span>
                              <span className="cpycatgoryname">{user.locationname}</span>
                              
                              </>}
                             
        
                              {user.cpysummary=="" || user.cpysummary==null?
                              <h6 style={{fontSize:12,textAlign:'left',
                              fontFamily:"GothamLight",
                              width:"100%",fontWeight:"bold",height:70,marginTop:"5%",color:"transparent"}}>Please Add your summaary</h6>
                              :<>
                          <h6 style={{fontSize:12,textAlign:'left',
                          fontFamily:"GothamLight",
                          width:"100%",fontWeight:"bold",height:70,marginTop:"5%"}}>
                                
                                {user.cpysummary}...<span style={{color:"rgb(0,147,69)",fontFamily:"Gotham",fontSize:11}}>more</span></h6>
                          
                          </>}
  
         
                          <div className="cpyopportunity">
  
                            {user.opportunitiestype==null?null
                            :<>
                            {user.opportunitiestype.split(",")
    .map((item, i) => <span class="col-md-2">  {item}  </span>)} 
                            </>}
                            
  </div>
        
        
                            </div>
                           
        
                           
                            
               
        
        
                            
                            
                        </div>
                        <Link 
                        target="_blank"
                        to={{ 
                         
                          pathname: `/viewgigprofile/${user.userid}`, 
                           
                        }}
                       
                         id="view_searchbtn">View {user.companyname}</Link>
                    </div>
    </div>
  
         
       
       
       
       
      
                
                
             
            </React.Fragment>
            )
  
            this.setState({
                pageCount: Math.ceil(data.length / this.state.perPage),
               
                postDatatwo,    
            })


         }
        
 
          
       });
    })
  }
  else{
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // for smoothly scrolling
    });
    this.setState({keyOrgsize : this.state.keyOrgsize.filter(function(val) {return val!==value})},()=>{
      this.setState({DataisLoaded:false,mobilescreen:0})
      const sdata = {

        searchtext:this.state.searchtext,
        cpyjobcategory:this.state.keyGen.toString(),
        cpyopportunitytypes:this.state.keyOpportunity.toString(),
        cpyorgsize:this.state.keyOrgsize.toString(),
        cpyindustry:this.state.keyIndustry.toString(),
        cpylocation:this.state.keyLocation.toString(),
      
     
 
    }
    console.log(sdata)
   axios
       .post(`https://www.gigeconome.com:5200/api/getcpysearchdemoinfo`,sdata)
       .then(res => {
         //console.log(res)
         if(res.data.message=="No Search Result Found."){
           this.setState({postData:res.data.message})
           //console.log(res.data.message)
           this.setState({totalcount:res.data.totalcount,DataisLoaded: true,
            s_text:""
          })
         }
         else{
           const data = res.data.result;
           //console.log(data)
           this.setState({totalcount:res.data.totalcount,DataisLoaded: true,
            s_text:""
          })
           const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
           const postData = slice.map(user => 
           
              
            <React.Fragment>
         
            <div className="col-sm-3 col-md-4 mbleview bigcompanysearch">
            <div class="searchcard_one" >
                         <div class="searchcard-bodycompany" style={{padding:"2%"}}>
                             <div class="searchcard-img-actionstwo"> 
                             <span
                             className="cpyfavicon">
                               <i class="fa fa-star" aria-hidden="true" style={{color:"rgb(0,147,69)"}}></i></span>
   
                             
                            {user.cpylogo==""?<>
                           
                            <span id="logotxt">LOGO</span>
                            </>:<> <img src={user.cpylogo} class="card-img img-fluid" width="96" height="350" alt=""
                             id="companysearch_img" style={{backgroundColor:"white"}}
                             
                             /> </>}
                              
                             
                            </div>
                         </div>
                         <div class="searchcard-bodycompany">
                             <div className="companyserachbox">
                             <h6>
                               <span 
                             className="cpysearchname">{user.companyname}</span> 
                          
                           
                             
                               </h6>
          
                               <h6>
                               {user.industryname==" "?<>
                                  <span 
                              className="cpycatgoryname">Category Name Not Avaliable.</span> 
                                  
                                  </>:<>
                                  
                                  <span 
                                 className="cpycatgoryname">{user.industryname}</span> 
                                  </>  }
                          
                           
                             
                               </h6>
                               {user.locationname=="" ?<>
                               <span style={{marginRight:"4%",color:"rgb(0,147,69)"}}><i class="fa fa-map-marker" style={{fontSize:14}}></i></span>
                               
                              Location Not Avaliable.
                               </>:<>
                               <span style={{marginRight:"4%",color:"rgb(0,147,69)"}}><i class="fa fa-map-marker" style={{fontSize:14}}></i></span>
                               <span className="cpycatgoryname">{user.locationname}</span>
                               
                               </>}
                              
         
                               {user.cpysummary=="" || user.cpysummary==null?
                               <h6 style={{fontSize:12,textAlign:'left',
                               fontFamily:"GothamLight",
                               width:"100%",fontWeight:"bold",height:70,marginTop:"5%",color:"transparent"}}>Please Add your summaary</h6>
                               :<>
                           <h6 style={{fontSize:12,textAlign:'left',
                           fontFamily:"GothamLight",
                           width:"100%",fontWeight:"bold",height:70,marginTop:"5%"}}>
                                 
                                 {user.cpysummary}...<span style={{color:"rgb(0,147,69)",fontFamily:"Gotham",fontSize:11}}>more</span></h6>
                           
                           </>}
   
          
                           <div className="cpyopportunity">
   
                             {user.opportunitiestype==null?null
                             :<>
                             {user.opportunitiestype.split(",")
     .map((item, i) => <span class="col-md-2">  {item}  </span>)} 
                             </>}
                             
   </div>
         
         
                             </div>
                            
         
                            
                             
                
         
         
                             
                             
                         </div>
                         <Link 
                         target="_blank"
                         to={{ 
                          
                           pathname: `/viewgigprofile/${user.userid}`, 
                            
                         }}
                        
                          id="view_searchbtn">View {user.companyname}</Link>
                     </div>
     </div>
   
          
        
        
        
        
       
                 
                 
              
             </React.Fragment>
           )
           this.setState({
             pageCount: Math.ceil(data.length / this.state.perPage),
            
            
        
           })
           const postDatatwo = slice.map(user => 
          
            <React.Fragment>
           
           <div className="col-sm-3 col-md-4">
           <div class="searchcard_one" >
                        <div class="searchcard-bodycompany" style={{padding:"2%"}}>
                            <div class="searchcard-img-actionstwo"> 
                            <span
                            className="cpyfavicon">
                              <i class="fa fa-star" aria-hidden="true" style={{color:"rgb(0,147,69)"}}></i></span>
  
                            
                           {user.cpylogo==""?<>
                          
                           <span id="logotxt">LOGO</span>
                           </>:<> <img src={user.cpylogo} class="card-img img-fluid" width="96" height="350" alt=""
                            id="companysearch_img" style={{backgroundColor:"white"}}
                            
                            /> </>}
                             
                            
                           </div>
                        </div>
                        <div class="searchcard-bodycompany">
                            <div className="companyserachbox">
                            <h6>
                              <span 
                            className="cpysearchname">{user.companyname}</span> 
                         
                          
                            
                              </h6>
         
                              <h6>
                              {user.industryname==" "?<>
                                 <span 
                             className="cpycatgoryname">Category Name Not Avaliable.</span> 
                                 
                                 </>:<>
                                 
                                 <span 
                                className="cpycatgoryname">{user.industryname}</span> 
                                 </>  }
                         
                          
                            
                              </h6>
                              {user.locationname=="" ?<>
                              <span style={{marginRight:"4%",color:"rgb(0,147,69)"}}><i class="fa fa-map-marker" style={{fontSize:14}}></i></span>
                              
                             Location Not Avaliable.
                              </>:<>
                              <span style={{marginRight:"4%",color:"rgb(0,147,69)"}}><i class="fa fa-map-marker" style={{fontSize:14}}></i></span>
                              <span className="cpycatgoryname">{user.locationname}</span>
                              
                              </>}
                             
        
                              {user.cpysummary=="" || user.cpysummary==null?
                              <h6 style={{fontSize:12,textAlign:'left',
                              fontFamily:"GothamLight",
                              width:"100%",fontWeight:"bold",height:70,marginTop:"5%",color:"transparent"}}>Please Add your summaary</h6>
                              :<>
                          <h6 style={{fontSize:12,textAlign:'left',
                          fontFamily:"GothamLight",
                          width:"100%",fontWeight:"bold",height:70,marginTop:"5%"}}>
                                
                                {user.cpysummary}...<span style={{color:"rgb(0,147,69)",fontFamily:"Gotham",fontSize:11}}>more</span></h6>
                          
                          </>}
  
         
                          <div className="cpyopportunity">
  
                            {user.opportunitiestype==null?null
                            :<>
                            {user.opportunitiestype.split(",")
    .map((item, i) => <span class="col-md-2">  {item}  </span>)} 
                            </>}
                            
  </div>
        
        
                            </div>
                           
        
                           
                            
               
        
        
                            
                            
                        </div>
                        <Link 
                        target="_blank"
                        to={{ 
                         
                          pathname: `/viewgigprofile/${user.userid}`, 
                           
                        }}
                       
                         id="view_searchbtn">View {user.companyname}</Link>
                    </div>
    </div>
  
         
       
       
       
       
      
                
                
             
            </React.Fragment>
            )
  
            this.setState({
                pageCount: Math.ceil(data.length / this.state.perPage),
               
                postDatatwo,    
            })


         }
        
 
          
       });
    })
  }

}



updateIndustry(e,value){
  if(this.state.searchtext==null){
    this.setState({searchtext:""})
  }
  if (e.target.checked){
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // for smoothly scrolling
    });
    this.setState({DataisLoaded:false,mobilescreen:0})
    //console.log(e.target.checked)
    this.setState({keyIndustry: this.state.keyIndustry.concat([value])},()=>{
      const sdata = {

        searchtext:this.state.searchtext,
        cpyjobcategory:this.state.keyGen.toString(),
        cpyopportunitytypes:this.state.keyOpportunity.toString(),
        cpyorgsize:this.state.keyOrgsize.toString(),
        cpyindustry:this.state.keyIndustry.toString(),
        cpylocation:this.state.keyLocation.toString(),
       
     
      
     
 
    }
    console.log(sdata)
   axios
       .post(`https://www.gigeconome.com:5200/api/getcpysearchdemoinfo`,sdata)
       .then(res => {
         //console.log(res)
         if(res.data.message=="No Search Result Found."){
           this.setState({postData:res.data.message})
           //console.log(res.data.message)
           this.setState({totalcount:res.data.totalcount,DataisLoaded: true,
            s_text:""
          })
         }
         else{
           const data = res.data.result;
           //console.log(data)
           this.setState({totalcount:res.data.totalcount,DataisLoaded: true,
            s_text:""
          })
           const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
           const postData = slice.map(user => 
           
            <React.Fragment>
         
            <div className="col-sm-3 col-md-4 mbleview bigcompanysearch">
            <div class="searchcard_one" >
                         <div class="searchcard-bodycompany" style={{padding:"2%"}}>
                             <div class="searchcard-img-actionstwo"> 
                             <span
                             className="cpyfavicon">
                               <i class="fa fa-star" aria-hidden="true" style={{color:"rgb(0,147,69)"}}></i></span>
   
                             
                            {user.cpylogo==""?<>
                           
                            <span id="logotxt">LOGO</span>
                            </>:<> <img src={user.cpylogo} class="card-img img-fluid" width="96" height="350" alt=""
                             id="companysearch_img" style={{backgroundColor:"white"}}
                             
                             /> </>}
                              
                             
                            </div>
                         </div>
                         <div class="searchcard-bodycompany">
                             <div className="companyserachbox">
                             <h6>
                               <span 
                             className="cpysearchname">{user.companyname}</span> 
                          
                           
                             
                               </h6>
          
                               <h6>
                               {user.industryname==" "?<>
                                  <span 
                              className="cpycatgoryname">Category Name Not Avaliable.</span> 
                                  
                                  </>:<>
                                  
                                  <span 
                                 className="cpycatgoryname">{user.industryname}</span> 
                                  </>  }
                          
                           
                             
                               </h6>
                               {user.locationname=="" ?<>
                               <span style={{marginRight:"4%",color:"rgb(0,147,69)"}}><i class="fa fa-map-marker" style={{fontSize:14}}></i></span>
                               
                              Location Not Avaliable.
                               </>:<>
                               <span style={{marginRight:"4%",color:"rgb(0,147,69)"}}><i class="fa fa-map-marker" style={{fontSize:14}}></i></span>
                               <span className="cpycatgoryname">{user.locationname}</span>
                               
                               </>}
                              
         
                               {user.cpysummary=="" || user.cpysummary==null?
                               <h6 style={{fontSize:12,textAlign:'left',
                               fontFamily:"GothamLight",
                               width:"100%",fontWeight:"bold",height:70,marginTop:"5%",color:"transparent"}}>Please Add your summaary</h6>
                               :<>
                           <h6 style={{fontSize:12,textAlign:'left',
                           fontFamily:"GothamLight",
                           width:"100%",fontWeight:"bold",height:70,marginTop:"5%"}}>
                                 
                                 {user.cpysummary}...<span style={{color:"rgb(0,147,69)",fontFamily:"Gotham",fontSize:11}}>more</span></h6>
                           
                           </>}
   
          
                           <div className="cpyopportunity">
   
                             {user.opportunitiestype==null?null
                             :<>
                             {user.opportunitiestype.split(",")
     .map((item, i) => <span class="col-md-2">  {item}  </span>)} 
                             </>}
                             
   </div>
         
         
                             </div>
                            
         
                            
                             
                
         
         
                             
                             
                         </div>
                         <Link 
                         target="_blank"
                         to={{ 
                          
                           pathname: `/viewgigprofile/${user.userid}`, 
                            
                         }}
                        
                          id="view_searchbtn">View {user.companyname}</Link>
                     </div>
     </div>
   
          
        
        
        
        
       
                 
                 
              
             </React.Fragment>
           )
           this.setState({
             pageCount: Math.ceil(data.length / this.state.perPage),
            
             postData,
            
        
           })
           const postDatatwo = slice.map(user => 
          
            <React.Fragment>
           
           <div className="col-sm-3 col-md-4">
           <div class="searchcard_one" >
                        <div class="searchcard-bodycompany" style={{padding:"2%"}}>
                            <div class="searchcard-img-actionstwo"> 
                            <span
                            className="cpyfavicon">
                              <i class="fa fa-star" aria-hidden="true" style={{color:"rgb(0,147,69)"}}></i></span>
  
                            
                           {user.cpylogo==""?<>
                          
                           <span id="logotxt">LOGO</span>
                           </>:<> <img src={user.cpylogo} class="card-img img-fluid" width="96" height="350" alt=""
                            id="companysearch_img" style={{backgroundColor:"white"}}
                            
                            /> </>}
                             
                            
                           </div>
                        </div>
                        <div class="searchcard-bodycompany">
                            <div className="companyserachbox">
                            <h6>
                              <span 
                            className="cpysearchname">{user.companyname}</span> 
                         
                          
                            
                              </h6>
         
                              <h6>
                              {user.industryname==" "?<>
                                 <span 
                             className="cpycatgoryname">Category Name Not Avaliable.</span> 
                                 
                                 </>:<>
                                 
                                 <span 
                                className="cpycatgoryname">{user.industryname}</span> 
                                 </>  }
                         
                          
                            
                              </h6>
                              {user.locationname=="" ?<>
                              <span style={{marginRight:"4%",color:"rgb(0,147,69)"}}><i class="fa fa-map-marker" style={{fontSize:14}}></i></span>
                              
                             Location Not Avaliable.
                              </>:<>
                              <span style={{marginRight:"4%",color:"rgb(0,147,69)"}}><i class="fa fa-map-marker" style={{fontSize:14}}></i></span>
                              <span className="cpycatgoryname">{user.locationname}</span>
                              
                              </>}
                             
        
                              {user.cpysummary=="" || user.cpysummary==null?
                              <h6 style={{fontSize:12,textAlign:'left',
                              fontFamily:"GothamLight",
                              width:"100%",fontWeight:"bold",height:70,marginTop:"5%",color:"transparent"}}>Please Add your summaary</h6>
                              :<>
                          <h6 style={{fontSize:12,textAlign:'left',
                          fontFamily:"GothamLight",
                          width:"100%",fontWeight:"bold",height:70,marginTop:"5%"}}>
                                
                                {user.cpysummary}...<span style={{color:"rgb(0,147,69)",fontFamily:"Gotham",fontSize:11}}>more</span></h6>
                          
                          </>}
  
         
                          <div className="cpyopportunity">
  
                            {user.opportunitiestype==null?null
                            :<>
                            {user.opportunitiestype.split(",")
    .map((item, i) => <span class="col-md-2">  {item}  </span>)} 
                            </>}
                            
  </div>
        
        
                            </div>
                           
        
                           
                            
               
        
        
                            
                            
                        </div>
                        <Link 
                        target="_blank"
                        to={{ 
                         
                          pathname: `/viewgigprofile/${user.userid}`, 
                           
                        }}
                       
                         id="view_searchbtn">View {user.companyname}</Link>
                    </div>
    </div>
  
         
       
       
       
       
      
                
                
             
            </React.Fragment>
            )
  
            this.setState({
                pageCount: Math.ceil(data.length / this.state.perPage),
               
                postDatatwo,    
            })

         }
        
 
          
       });
    })
  }
  else{
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // for smoothly scrolling
    });
    this.setState({keyIndustry : this.state.keyIndustry.filter(function(val) {return val!==value})},()=>{
      this.setState({DataisLoaded:false,mobilescreen:0})
      const sdata = {

        searchtext:this.state.searchtext,
        cpyjobcategory:this.state.keyGen.toString(),
        cpyopportunitytypes:this.state.keyOpportunity.toString(),
        cpyorgsize:this.state.keyOrgsize.toString(),
        cpyindustry:this.state.keyIndustry.toString(),
        cpylocation:this.state.keyLocation.toString(),
       
     
      
     
 
    }
    console.log(sdata)
   axios
       .post(`https://www.gigeconome.com:5200/api/getcpysearchdemoinfo`,sdata)
       .then(res => {
         //console.log(res)
         if(res.data.message=="No Search Result Found."){
           this.setState({postData:res.data.message})
           //console.log(res.data.message)
           this.setState({totalcount:res.data.totalcount,DataisLoaded: true,
            s_text:""
          })
         }
         else{
           const data = res.data.result;
           //console.log(data)
           this.setState({totalcount:res.data.totalcount,DataisLoaded: true,
            s_text:""
          })
           const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
           const postData = slice.map(user => 
           
            <React.Fragment>
         
            <div className="col-sm-3 col-md-4 mbleview bigcompanysearch">
            <div class="searchcard_one" >
                         <div class="searchcard-bodycompany" style={{padding:"2%"}}>
                             <div class="searchcard-img-actionstwo"> 
                             <span
                             className="cpyfavicon">
                               <i class="fa fa-star" aria-hidden="true" style={{color:"rgb(0,147,69)"}}></i></span>
   
                             
                            {user.cpylogo==""?<>
                           
                            <span id="logotxt">LOGO</span>
                            </>:<> <img src={user.cpylogo} class="card-img img-fluid" width="96" height="350" alt=""
                             id="companysearch_img" style={{backgroundColor:"white"}}
                             
                             /> </>}
                              
                             
                            </div>
                         </div>
                         <div class="searchcard-bodycompany">
                             <div className="companyserachbox">
                             <h6>
                               <span 
                             className="cpysearchname">{user.companyname}</span> 
                          
                           
                             
                               </h6>
          
                               <h6>
                               {user.industryname==" "?<>
                                  <span 
                              className="cpycatgoryname">Category Name Not Avaliable.</span> 
                                  
                                  </>:<>
                                  
                                  <span 
                                 className="cpycatgoryname">{user.industryname}</span> 
                                  </>  }
                          
                           
                             
                               </h6>
                               {user.locationname=="" ?<>
                               <span style={{marginRight:"4%",color:"rgb(0,147,69)"}}><i class="fa fa-map-marker" style={{fontSize:14}}></i></span>
                               
                              Location Not Avaliable.
                               </>:<>
                               <span style={{marginRight:"4%",color:"rgb(0,147,69)"}}><i class="fa fa-map-marker" style={{fontSize:14}}></i></span>
                               <span className="cpycatgoryname">{user.locationname}</span>
                               
                               </>}
                              
         
                               {user.cpysummary=="" || user.cpysummary==null?
                               <h6 style={{fontSize:12,textAlign:'left',
                               fontFamily:"GothamLight",
                               width:"100%",fontWeight:"bold",height:70,marginTop:"5%",color:"transparent"}}>Please Add your summaary</h6>
                               :<>
                           <h6 style={{fontSize:12,textAlign:'left',
                           fontFamily:"GothamLight",
                           width:"100%",fontWeight:"bold",height:70,marginTop:"5%"}}>
                                 
                                 {user.cpysummary}...<span style={{color:"rgb(0,147,69)",fontFamily:"Gotham",fontSize:11}}>more</span></h6>
                           
                           </>}
   
          
                           <div className="cpyopportunity">
   
                             {user.opportunitiestype==null?null
                             :<>
                             {user.opportunitiestype.split(",")
     .map((item, i) => <span class="col-md-2">  {item}  </span>)} 
                             </>}
                             
   </div>
         
         
                             </div>
                            
         
                            
                             
                
         
         
                             
                             
                         </div>
                         <Link 
                         target="_blank"
                         to={{ 
                          
                           pathname: `/viewgigprofile/${user.userid}`, 
                            
                         }}
                        
                          id="view_searchbtn">View {user.companyname}</Link>
                     </div>
     </div>
   
          
        
        
        
        
       
                 
                 
              
             </React.Fragment>
           )
           this.setState({
             pageCount: Math.ceil(data.length / this.state.perPage),
            
             postData,
        
           })
           const postDatatwo = slice.map(user => 
          
            <React.Fragment>
           
           <div className="col-sm-3 col-md-4">
           <div class="searchcard_one" >
                        <div class="searchcard-bodycompany" style={{padding:"2%"}}>
                            <div class="searchcard-img-actionstwo"> 
                            <span
                            className="cpyfavicon">
                              <i class="fa fa-star" aria-hidden="true" style={{color:"rgb(0,147,69)"}}></i></span>
  
                            
                           {user.cpylogo==""?<>
                          
                           <span id="logotxt">LOGO</span>
                           </>:<> <img src={user.cpylogo} class="card-img img-fluid" width="96" height="350" alt=""
                            id="companysearch_img" style={{backgroundColor:"white"}}
                            
                            /> </>}
                             
                            
                           </div>
                        </div>
                        <div class="searchcard-bodycompany">
                            <div className="companyserachbox">
                            <h6>
                              <span 
                            className="cpysearchname">{user.companyname}</span> 
                         
                          
                            
                              </h6>
         
                              <h6>
                              {user.industryname==" "?<>
                                 <span 
                             className="cpycatgoryname">Category Name Not Avaliable.</span> 
                                 
                                 </>:<>
                                 
                                 <span 
                                className="cpycatgoryname">{user.industryname}</span> 
                                 </>  }
                         
                          
                            
                              </h6>
                              {user.locationname=="" ?<>
                              <span style={{marginRight:"4%",color:"rgb(0,147,69)"}}><i class="fa fa-map-marker" style={{fontSize:14}}></i></span>
                              
                             Location Not Avaliable.
                              </>:<>
                              <span style={{marginRight:"4%",color:"rgb(0,147,69)"}}><i class="fa fa-map-marker" style={{fontSize:14}}></i></span>
                              <span className="cpycatgoryname">{user.locationname}</span>
                              
                              </>}
                             
        
                              {user.cpysummary=="" || user.cpysummary==null?
                              <h6 style={{fontSize:12,textAlign:'left',
                              fontFamily:"GothamLight",
                              width:"100%",fontWeight:"bold",height:70,marginTop:"5%",color:"transparent"}}>Please Add your summaary</h6>
                              :<>
                          <h6 style={{fontSize:12,textAlign:'left',
                          fontFamily:"GothamLight",
                          width:"100%",fontWeight:"bold",height:70,marginTop:"5%"}}>
                                
                                {user.cpysummary}...<span style={{color:"rgb(0,147,69)",fontFamily:"Gotham",fontSize:11}}>more</span></h6>
                          
                          </>}
  
         
                          <div className="cpyopportunity">
  
                            {user.opportunitiestype==null?null
                            :<>
                            {user.opportunitiestype.split(",")
    .map((item, i) => <span class="col-md-2">  {item}  </span>)} 
                            </>}
                            
  </div>
        
        
                            </div>
                           
        
                           
                            
               
        
        
                            
                            
                        </div>
                        <Link 
                        target="_blank"
                        to={{ 
                         
                          pathname: `/viewgigprofile/${user.userid}`, 
                           
                        }}
                       
                         id="view_searchbtn">View {user.companyname}</Link>
                    </div>
    </div>
  
         
       
       
       
       
      
                
                
             
            </React.Fragment>
            )
  
            this.setState({
                pageCount: Math.ceil(data.length / this.state.perPage),
               
                postDatatwo,    
            })

         }
        
 
          
       });
    })
  }

}









updateOpportunity(e,opportunityid){
  if(this.state.searchtext==null){
    this.setState({searchtext:""})
  }
  if (e.target.checked){
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // for smoothly scrolling
    });
    this.setState({DataisLoaded:false,mobilescreen:0})
    //console.log(e.target.checked)
    this.setState({keyOpportunity: this.state.keyOpportunity.concat([opportunityid])},()=>{
      const sdata = {

        
        searchtext:this.state.searchtext,
        cpyjobcategory:this.state.keyGen.toString(),
        cpyopportunitytypes:this.state.keyOpportunity.toString(),
        cpyorgsize:this.state.keyOrgsize.toString(),
        cpyindustry:this.state.keyIndustry.toString(),
        cpylocation:this.state.keyLocation.toString(),
     
      
     
 
    }
    console.log(sdata)
   axios
       .post(`https://www.gigeconome.com:5200/api/getcpysearchdemoinfo`,sdata)
       .then(res => {
         //console.log(res)
         if(res.data.message=="No Search Result Found."){
           this.setState({postData:res.data.message})
           //console.log(res.data.message)
           this.setState({totalcount:res.data.totalcount,DataisLoaded: true,
            s_text:""
          })
         }
         else{
           const data = res.data.result;
           //console.log(data)
           this.setState({totalcount:res.data.totalcount,DataisLoaded: true,
            s_text:""
          })
           const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
           const postData = slice.map(user => 
            <React.Fragment>
         
            <div className="col-sm-3 col-md-4 mbleview bigcompanysearch">
            <div class="searchcard_one" >
                         <div class="searchcard-bodycompany" style={{padding:"2%"}}>
                             <div class="searchcard-img-actionstwo"> 
                             <span
                             className="cpyfavicon">
                               <i class="fa fa-star" aria-hidden="true" style={{color:"rgb(0,147,69)"}}></i></span>
   
                             
                            {user.cpylogo==""?<>
                           
                            <span id="logotxt">LOGO</span>
                            </>:<> <img src={user.cpylogo} class="card-img img-fluid" width="96" height="350" alt=""
                             id="companysearch_img" style={{backgroundColor:"white"}}
                             
                             /> </>}
                              
                             
                            </div>
                         </div>
                         <div class="searchcard-bodycompany">
                             <div className="companyserachbox">
                             <h6>
                               <span 
                             className="cpysearchname">{user.companyname}</span> 
                          
                           
                             
                               </h6>
          
                               <h6>
                               {user.industryname==" "?<>
                                  <span 
                              className="cpycatgoryname">Category Name Not Avaliable.</span> 
                                  
                                  </>:<>
                                  
                                  <span 
                                 className="cpycatgoryname">{user.industryname}</span> 
                                  </>  }
                          
                           
                             
                               </h6>
                               {user.locationname=="" ?<>
                               <span style={{marginRight:"4%",color:"rgb(0,147,69)"}}><i class="fa fa-map-marker" style={{fontSize:14}}></i></span>
                               
                              Location Not Avaliable.
                               </>:<>
                               <span style={{marginRight:"4%",color:"rgb(0,147,69)"}}><i class="fa fa-map-marker" style={{fontSize:14}}></i></span>
                               <span className="cpycatgoryname">{user.locationname}</span>
                               
                               </>}
                              
         
                               {user.cpysummary=="" || user.cpysummary==null?
                               <h6 style={{fontSize:12,textAlign:'left',
                               fontFamily:"GothamLight",
                               width:"100%",fontWeight:"bold",height:70,marginTop:"5%",color:"transparent"}}>Please Add your summaary</h6>
                               :<>
                           <h6 style={{fontSize:12,textAlign:'left',
                           fontFamily:"GothamLight",
                           width:"100%",fontWeight:"bold",height:70,marginTop:"5%"}}>
                                 
                                 {user.cpysummary}...<span style={{color:"rgb(0,147,69)",fontFamily:"Gotham",fontSize:11}}>more</span></h6>
                           
                           </>}
   
          
                           <div className="cpyopportunity">
   
                             {user.opportunitiestype==null?null
                             :<>
                             {user.opportunitiestype.split(",")
     .map((item, i) => <span class="col-md-2">  {item}  </span>)} 
                             </>}
                             
   </div>
         
         
                             </div>
                            
         
                            
                             
                
         
         
                             
                             
                         </div>
                         <Link 
                         target="_blank"
                         to={{ 
                          
                           pathname: `/viewgigprofile/${user.userid}`, 
                            
                         }}
                        
                          id="view_searchbtn">View {user.companyname}</Link>
                     </div>
     </div>
   
          
        
        
        
        
       
                 
                 
              
             </React.Fragment>
           )
           this.setState({
             pageCount: Math.ceil(data.length / this.state.perPage),
            
             postData,
             DataisLoaded: true,
        
           })
           const postDatatwo = slice.map(user => 
          
            <React.Fragment>
           
           <div className="col-sm-3 col-md-4">
           <div class="searchcard_one" >
                        <div class="searchcard-bodycompany" style={{padding:"2%"}}>
                            <div class="searchcard-img-actionstwo"> 
                            <span
                            className="cpyfavicon">
                              <i class="fa fa-star" aria-hidden="true" style={{color:"rgb(0,147,69)"}}></i></span>
  
                            
                           {user.cpylogo==""?<>
                          
                           <span id="logotxt">LOGO</span>
                           </>:<> <img src={user.cpylogo} class="card-img img-fluid" width="96" height="350" alt=""
                            id="companysearch_img" style={{backgroundColor:"white"}}
                            
                            /> </>}
                             
                            
                           </div>
                        </div>
                        <div class="searchcard-bodycompany">
                            <div className="companyserachbox">
                            <h6>
                              <span 
                            className="cpysearchname">{user.companyname}</span> 
                         
                          
                            
                              </h6>
         
                              <h6>
                              {user.industryname==" "?<>
                                 <span 
                             className="cpycatgoryname">Category Name Not Avaliable.</span> 
                                 
                                 </>:<>
                                 
                                 <span 
                                className="cpycatgoryname">{user.industryname}</span> 
                                 </>  }
                         
                          
                            
                              </h6>
                              {user.locationname=="" ?<>
                              <span style={{marginRight:"4%",color:"rgb(0,147,69)"}}><i class="fa fa-map-marker" style={{fontSize:14}}></i></span>
                              
                             Location Not Avaliable.
                              </>:<>
                              <span style={{marginRight:"4%",color:"rgb(0,147,69)"}}><i class="fa fa-map-marker" style={{fontSize:14}}></i></span>
                              <span className="cpycatgoryname">{user.locationname}</span>
                              
                              </>}
                             
        
                              {user.cpysummary=="" || user.cpysummary==null?
                              <h6 style={{fontSize:12,textAlign:'left',
                              fontFamily:"GothamLight",
                              width:"100%",fontWeight:"bold",height:70,marginTop:"5%",color:"transparent"}}>Please Add your summaary</h6>
                              :<>
                          <h6 style={{fontSize:12,textAlign:'left',
                          fontFamily:"GothamLight",
                          width:"100%",fontWeight:"bold",height:70,marginTop:"5%"}}>
                                
                                {user.cpysummary}...<span style={{color:"rgb(0,147,69)",fontFamily:"Gotham",fontSize:11}}>more</span></h6>
                          
                          </>}
  
         
                          <div className="cpyopportunity">
  
                            {user.opportunitiestype==null?null
                            :<>
                            {user.opportunitiestype.split(",")
    .map((item, i) => <span class="col-md-2">  {item}  </span>)} 
                            </>}
                            
  </div>
        
        
                            </div>
                           
        
                           
                            
               
        
        
                            
                            
                        </div>
                        <Link 
                        target="_blank"
                        to={{ 
                         
                          pathname: `/viewgigprofile/${user.userid}`, 
                           
                        }}
                       
                         id="view_searchbtn">View {user.companyname}</Link>
                    </div>
    </div>
  
         
       
       
       
       
      
                
                
             
            </React.Fragment>
            )
  
            this.setState({
                pageCount: Math.ceil(data.length / this.state.perPage),
               
                postDatatwo,    
            })

         }
        
 
          
       });
    })
  }
  else{
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // for smoothly scrolling
    });
    this.setState({keyOpportunity : this.state.keyOpportunity.filter(function(val) {return val!==opportunityid})},()=>{
      this.setState({DataisLoaded:false,mobilescreen:0})
      const sdata = {

       
        searchtext:this.state.searchtext,
        cpyjobcategory:this.state.keyGen.toString(),
        cpyopportunitytypes:this.state.keyOpportunity.toString(),
        cpyorgsize:this.state.keyOrgsize.toString(),
        cpyindustry:this.state.keyIndustry.toString(),
        cpylocation:this.state.keyLocation.toString(),
     
      
     
 
    }
    //console.log(sdata)
   axios
       .post(`https://www.gigeconome.com:5200/api/getcpysearchdemoinfo`,sdata)
       .then(res => {
         //console.log(res)
         if(res.data.message=="No Search Result Found."){
           this.setState({postData:res.data.message})
           //console.log(res.data.message)
         }
         else{
           const data = res.data.result;
           //console.log(data)
           this.setState({totalcount:res.data.totalcount,DataisLoaded: true,
            s_text:""
          })
           const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
           const postData = slice.map(user => 
           
         
            <React.Fragment>
         
            <div className="col-sm-3 col-md-4 mbleview bigcompanysearch">
            <div class="searchcard_one" >
                         <div class="searchcard-bodycompany" style={{padding:"2%"}}>
                             <div class="searchcard-img-actionstwo"> 
                             <span
                             className="cpyfavicon">
                               <i class="fa fa-star" aria-hidden="true" style={{color:"rgb(0,147,69)"}}></i></span>
   
                             
                            {user.cpylogo==""?<>
                           
                            <span id="logotxt">LOGO</span>
                            </>:<> <img src={user.cpylogo} class="card-img img-fluid" width="96" height="350" alt=""
                             id="companysearch_img" style={{backgroundColor:"white"}}
                             
                             /> </>}
                              
                             
                            </div>
                         </div>
                         <div class="searchcard-bodycompany">
                             <div className="companyserachbox">
                             <h6>
                               <span 
                             className="cpysearchname">{user.companyname}</span> 
                          
                           
                             
                               </h6>
          
                               <h6>
                               {user.industryname==" "?<>
                                  <span 
                              className="cpycatgoryname">Category Name Not Avaliable.</span> 
                                  
                                  </>:<>
                                  
                                  <span 
                                 className="cpycatgoryname">{user.industryname}</span> 
                                  </>  }
                          
                           
                             
                               </h6>
                               {user.locationname=="" ?<>
                               <span style={{marginRight:"4%",color:"rgb(0,147,69)"}}><i class="fa fa-map-marker" style={{fontSize:14}}></i></span>
                               
                              Location Not Avaliable.
                               </>:<>
                               <span style={{marginRight:"4%",color:"rgb(0,147,69)"}}><i class="fa fa-map-marker" style={{fontSize:14}}></i></span>
                               <span className="cpycatgoryname">{user.locationname}</span>
                               
                               </>}
                              
         
                               {user.cpysummary=="" || user.cpysummary==null?
                               <h6 style={{fontSize:12,textAlign:'left',
                               fontFamily:"GothamLight",
                               width:"100%",fontWeight:"bold",height:70,marginTop:"5%",color:"transparent"}}>Please Add your summaary</h6>
                               :<>
                           <h6 style={{fontSize:12,textAlign:'left',
                           fontFamily:"GothamLight",
                           width:"100%",fontWeight:"bold",height:70,marginTop:"5%"}}>
                                 
                                 {user.cpysummary}...<span style={{color:"rgb(0,147,69)",fontFamily:"Gotham",fontSize:11}}>more</span></h6>
                           
                           </>}
   
          
                           <div className="cpyopportunity">
   
                             {user.opportunitiestype==null?null
                             :<>
                             {user.opportunitiestype.split(",")
     .map((item, i) => <span class="col-md-2">  {item}  </span>)} 
                             </>}
                             
   </div>
         
         
                             </div>
                            
         
                            
                             
                
         
         
                             
                             
                         </div>
                         <Link 
                         target="_blank"
                         to={{ 
                          
                           pathname: `/viewgigprofile/${user.userid}`, 
                            
                         }}
                        
                          id="view_searchbtn">View {user.companyname}</Link>
                     </div>
     </div>
   
          
        
        
        
        
       
                 
                 
              
             </React.Fragment>
           )
           this.setState({
             pageCount: Math.ceil(data.length / this.state.perPage),
            
             postData, DataisLoaded: true,
        
           })
           const postDatatwo = slice.map(user => 
          
            <React.Fragment>
           
           <div className="col-sm-3 col-md-4">
           <div class="searchcard_one" >
                        <div class="searchcard-bodycompany" style={{padding:"2%"}}>
                            <div class="searchcard-img-actionstwo"> 
                            <span
                            className="cpyfavicon">
                              <i class="fa fa-star" aria-hidden="true" style={{color:"rgb(0,147,69)"}}></i></span>
  
                            
                           {user.cpylogo==""?<>
                          
                           <span id="logotxt">LOGO</span>
                           </>:<> <img src={user.cpylogo} class="card-img img-fluid" width="96" height="350" alt=""
                            id="companysearch_img" style={{backgroundColor:"white"}}
                            
                            /> </>}
                             
                            
                           </div>
                        </div>
                        <div class="searchcard-bodycompany">
                            <div className="companyserachbox">
                            <h6>
                              <span 
                            className="cpysearchname">{user.companyname}</span> 
                         
                          
                            
                              </h6>
         
                              <h6>
                              {user.industryname==" "?<>
                                 <span 
                             className="cpycatgoryname">Category Name Not Avaliable.</span> 
                                 
                                 </>:<>
                                 
                                 <span 
                                className="cpycatgoryname">{user.industryname}</span> 
                                 </>  }
                         
                          
                            
                              </h6>
                              {user.locationname=="" ?<>
                              <span style={{marginRight:"4%",color:"rgb(0,147,69)"}}><i class="fa fa-map-marker" style={{fontSize:14}}></i></span>
                              
                             Location Not Avaliable.
                              </>:<>
                              <span style={{marginRight:"4%",color:"rgb(0,147,69)"}}><i class="fa fa-map-marker" style={{fontSize:14}}></i></span>
                              <span className="cpycatgoryname">{user.locationname}</span>
                              
                              </>}
                             
        
                              {user.cpysummary=="" || user.cpysummary==null?
                              <h6 style={{fontSize:12,textAlign:'left',
                              fontFamily:"GothamLight",
                              width:"100%",fontWeight:"bold",height:70,marginTop:"5%",color:"transparent"}}>Please Add your summaary</h6>
                              :<>
                          <h6 style={{fontSize:12,textAlign:'left',
                          fontFamily:"GothamLight",
                          width:"100%",fontWeight:"bold",height:70,marginTop:"5%"}}>
                                
                                {user.cpysummary}...<span style={{color:"rgb(0,147,69)",fontFamily:"Gotham",fontSize:11}}>more</span></h6>
                          
                          </>}
  
         
                          <div className="cpyopportunity">
  
                            {user.opportunitiestype==null?null
                            :<>
                            {user.opportunitiestype.split(",")
    .map((item, i) => <span class="col-md-2">  {item}  </span>)} 
                            </>}
                            
  </div>
        
        
                            </div>
                           
        
                           
                            
               
        
        
                            
                            
                        </div>
                        <Link 
                        target="_blank"
                        to={{ 
                         
                          pathname: `/viewgigprofile/${user.userid}`, 
                           
                        }}
                       
                         id="view_searchbtn">View {user.companyname}</Link>
                    </div>
    </div>
  
         
       
       
       
       
      
                
                
             
            </React.Fragment>
            )
  
            this.setState({
                pageCount: Math.ceil(data.length / this.state.perPage),
               
                postDatatwo,    
            })

         }
        
 
          
       });
    })
  }

}






handleCourse(e,value){
  if (e.target.checked){
    //console.log(e.target.checked)
    this.setState({keyCourse: this.state.keyCourse.concat([value])},()=>{
      const sdata = {

        searchtext:"",
        stjobcategory:this.state.keyGen.toString(),
        stopportunitytypes:this.state.stopportunitytypes,
        stdegreesubject:this.state.keyCourse.toString(),
        studenttype:this.state.studenttype,
        studentstatus:this.state.studentstatus,
        storgsize:this.state.storgsize,
        stindustry:this.state.stindustry,
        stlocation:this.state.keyLocation.toString(),
        stlanguage:this.state.keyLocation.toString(),
     
      
     
 
    }
    //console.log(sdata)
   axios
       .post(`https://www.gigeconome.com:5200/api/getsearchinfo`,sdata)
       .then(res => {
         //console.log(res)
         if(res.data.message=="No Search Result Found."){
           this.setState({postData:res.data.message})
           //console.log(res.data.message)
         }
         else{
           const data = res.data.result;
           //console.log(data)
           this.setState({totalcount:res.data.totalcount,DataisLoaded: true,
            s_text:""
          })
           const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
           const postData = slice.map(user => 
           
           <React.Fragment>
          
          <div className="col-sm-4">
          <div class="searchcard_one" >
                      <div class="searchcard-bodycompany" >
                          <div class="searchcard-img-actionstwo"> 
                          <span
                          style={{borderRadius:"50%",float:"right",width:"9%",margin:"1.5%",backgroundColor:"#fff"}}>
                            <i class="fa fa-star" aria-hidden="true" style={{color:"rgb(0,147,69)"}}></i></span>

                          
                          <img src={DummyImage} class="card-img img-fluid" width="96" height="350" alt=""
                          id="companysearch_img"
                          
                          /> </div>
                      </div>
                      <div class="searchcard-bodycompany">
                          <div style={{textAlign:'left'}}>
                          <h6>
                            <span 
                          style={{color:"rgb(0,147,69)",
                          fontFamily:"Gotham",
                          fontSize:17
                        }}>{user.guser_id}</span> 
                       
                         <span style={{float:"right",fontFamily:"Gotham"}}>
                            Availability </span> 
                            <span style={{marginRight:"1%",color:"rgb(0,147,69)",float:"right"}}><i className="fa fa-clock-o"></i></span> 
                            </h6>
                         
                            <h6>
 
                       
                         <span >
                            <img src={Invite} style={{float:"right",width:"25%",cursor:"pointer"}} /></span> 
                            
                            </h6>
      
                            <h6 style={{marginLeft:"3%",marginTop:"5%",fontSize:13,textAlign:'left',width:"100%",fontFamily:"GothamLight",fontWeight:"bold"}}>
                            <span style={{marginRight:"3%",color:"rgb(0,147,69)"}}><i className="fa fa-user"></i></span> {user.StudyStatus}</h6>
                            <h6 style={{marginLeft:"2%",marginTop:"0%",fontSize:13,textAlign:'left',width:"100%",fontFamily:"GothamLight",fontWeight:"bold"}}>
                            <span style={{marginRight:"1%",color:"rgb(0,147,69)"}}><i class="fa fa-graduation-cap"></i></span> &nbsp;{user.QualificationType} in {user.Course}</h6>
                            <h6 style={{marginLeft:"3%",marginTop:"0%",fontSize:13,textAlign:'left',width:"100%",fontFamily:"GothamLight",fontWeight:"bold"}}>
                            <span style={{marginRight:"4%",color:"rgb(0,147,69)"}}><i class="fa fa-calendar"></i></span>Graduation Year : {user.GraduationYear}</h6>
                            <h6 style={{marginLeft:"4%",marginTop:"0%",fontSize:13,textAlign:'left',width:"100%",fontFamily:"GothamLight",fontWeight:"bold"}}>
                            <span style={{marginRight:"4%",color:"rgb(0,147,69)"}}><i class="fa fa-map-marker" style={{fontSize:14}}></i></span>{user.locationname}</h6>
      
                            {user.summary==""?null:<>
                        <h6 style={{fontSize:11,textAlign:'left',width:"100%",fontWeight:"bold",height:50}}>
                              
                              {user.summary}...<span style={{color:"rgb(0,147,69)",fontFamily:"Gotham",fontSize:11}}>more</span></h6>
                        
                        </>}

      
      
      
                          </div>
                         
      
                         
                          <div  style={{textAlign:"left",height:71}}>
                          {user.skillname.split(",")
  .map((item, i) => <span class="col-md-2">  {item}  </span>)}  
</div>
             
      
      
                          
                          
                      </div>
                      <Link 
                      target="_blank"
                      to={{ 
                       
                        pathname: `/studentpublishprofile/${user.userid}`, 
                         
                      }}
                     
                       id="view_searchbtn">View {user.guser_id}</Link>
                  </div>
   </div>
 
        
      
      
      
      
     
               
               
            
           </React.Fragment>
           )
           this.setState({
             pageCount: Math.ceil(data.length / this.state.perPage),
            
             postData,
             DataisLoaded: true,
        
           })
         }
        
 
          
       });
    })
  }
  else{
    this.setState({keyCourse : this.state.keyCourse.filter(function(val) {return val!==value})},()=>{
      const sdata = {

        searchtext:"",
        stjobcategory:this.state.keyGen.toString(),
        stopportunitytypes:this.state.stopportunitytypes,
        stdegreesubject:this.state.keyCourse.toString(),
        studenttype:this.state.studenttype,
        studentstatus:this.state.studentstatus,
        storgsize:this.state.storgsize,
        stindustry:this.state.stindustry,
        stlocation:this.state.keyLocation.toString(),
        stlanguage:this.state.keyLanguage.toString(),
     
      
     
 
    }
    //console.log(sdata)
   axios
       .post(`https://www.gigeconome.com:5200/api/getsearchinfo`,sdata)
       .then(res => {
         //console.log(res)
         if(res.data.message=="No Search Result Found."){
           this.setState({postData:res.data.message})
           //console.log(res.data.message)
           this.setState({totalcount:res.data.totalcount,DataisLoaded: true,
            s_text:""
          })
         }
         else{
           const data = res.data.result;
           //console.log(data)
           this.setState({totalcount:res.data.totalcount,DataisLoaded: true,
            s_text:""
          })
           const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
           const postData = slice.map(user => 
           
           <React.Fragment>
          
          <div className="col-sm-4">
          <div class="searchcard_one" >
                      <div class="searchcard-bodycompany" >
                          <div class="searchcard-img-actionstwo"> 
                          <span
                          style={{borderRadius:"50%",float:"right",width:"9%",margin:"1.5%",backgroundColor:"#fff"}}>
                            <i class="fa fa-star" aria-hidden="true" style={{color:"rgb(0,147,69)"}}></i></span>

                          
                          <img src={DummyImage} class="card-img img-fluid" width="96" height="350" alt=""
                          id="companysearch_img"
                          
                          /> </div>
                      </div>
                      <div class="searchcard-bodycompany">
                          <div style={{textAlign:'left'}}>
                          <h6>
                            <span 
                          style={{color:"rgb(0,147,69)",
                          fontFamily:"Gotham",
                          fontSize:17
                        }}>{user.guser_id}</span> 
                       
                         <span style={{float:"right",fontFamily:"Gotham"}}>
                            Availability </span> 
                            <span style={{marginRight:"1%",color:"rgb(0,147,69)",float:"right"}}><i className="fa fa-clock-o"></i></span> 
                            </h6>
                         
                            <h6>
 
                       
                         <span >
                            <img src={Invite} style={{float:"right",width:"25%",cursor:"pointer"}} /></span> 
                            
                            </h6>
      
                            <h6 style={{marginLeft:"3%",marginTop:"5%",fontSize:13,textAlign:'left',width:"100%",fontFamily:"GothamLight",fontWeight:"bold"}}>
                            <span style={{marginRight:"3%",color:"rgb(0,147,69)"}}><i className="fa fa-user"></i></span> {user.StudyStatus}</h6>
                            <h6 style={{marginLeft:"2%",marginTop:"0%",fontSize:13,textAlign:'left',width:"100%",fontFamily:"GothamLight",fontWeight:"bold"}}>
                            <span style={{marginRight:"1%",color:"rgb(0,147,69)"}}><i class="fa fa-graduation-cap"></i></span> &nbsp;{user.QualificationType} in {user.Course}</h6>
                            <h6 style={{marginLeft:"3%",marginTop:"0%",fontSize:13,textAlign:'left',width:"100%",fontFamily:"GothamLight",fontWeight:"bold"}}>
                            <span style={{marginRight:"4%",color:"rgb(0,147,69)"}}><i class="fa fa-calendar"></i></span>Graduation Year : {user.GraduationYear}</h6>
                            <h6 style={{marginLeft:"4%",marginTop:"0%",fontSize:13,textAlign:'left',width:"100%",fontFamily:"GothamLight",fontWeight:"bold"}}>
                            <span style={{marginRight:"4%",color:"rgb(0,147,69)"}}><i class="fa fa-map-marker" style={{fontSize:14}}></i></span>{user.locationname}</h6>
      
                            {user.summary==""?null:<>
                        <h6 style={{fontSize:11,textAlign:'left',width:"100%",fontWeight:"bold",height:50}}>
                              
                              {user.summary}...<span style={{color:"rgb(0,147,69)",fontFamily:"Gotham",fontSize:11}}>more</span></h6>
                        
                        </>}

      
      
      
                          </div>
                         
      
                         
                          <div  style={{textAlign:"left",height:71}}>
                          {user.skillname.split(",")
  .map((item, i) => <span class="col-md-2">  {item}  </span>)}  
</div>
             
      
      
                          
                          
                      </div>
                      <Link 
                      target="_blank"
                      to={{ 
                       
                        pathname: `/studentpublishprofile/${user.userid}`, 
                         
                      }}
                     
                       id="view_searchbtn">View {user.guser_id}</Link>
                  </div>
   </div>
 
        
      
      
      
      
     
               
               
            
           </React.Fragment>
           )
           this.setState({
             pageCount: Math.ceil(data.length / this.state.perPage),
            
             postData, DataisLoaded: true,
        
           })
         }
        
 
          
       });
    })
  }

}
updateStateList(e,value){
  if(this.state.searchtext==null){
    this.setState({searchtext:""})
  }
  if (e.target.checked){
    this.setState({DataisLoaded:false,mobilescreen:0})
    //console.log(e.target.checked)
    this.setState({keyGen: this.state.keyGen.concat([value])},()=>{
      const sdata = {

        searchtext:this.state.searchtext,
        cpyjobcategory:this.state.keyGen.toString(),
        cpyopportunitytypes:this.state.keyOpportunity.toString(),
        cpyorgsize:this.state.keyOrgsize.toString(),
        cpyindustry:this.state.keyIndustry.toString(),
        cpylocation:this.state.keyLocation.toString(),
     
      
     
 
    }
    console.log(sdata)
   axios
       .post(`https://www.gigeconome.com:5200/api/getcpysearchdemoinfo`,sdata)
       .then(res => {
         console.log(res)
         
         if(res.data.message=="No Search Result Found."){
           this.setState({postData:res.data.message,DataisLoaded:true})
           //console.log(res.data.message)
           this.setState({totalcount:res.data.totalcount,
            s_text:""
          })
         }
         else{
           const data = res.data.result;
           this.setState({totalcount:res.data.totalcount,
            s_text:"",DataisLoaded:true
          })
          
          
           const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
          
           const postData = slice.map(user => 
            //this.setState({formattedString:user.skillname.split(',')}
            
            <React.Fragment>
         
            <div className="col-sm-3 col-md-4 mbleview bigcompanysearch">
            <div class="searchcard_one" >
                         <div class="searchcard-bodycompany" style={{padding:"2%"}}>
                             <div class="searchcard-img-actionstwo"> 
                             <span
                             className="cpyfavicon">
                               <i class="fa fa-star" aria-hidden="true" style={{color:"rgb(0,147,69)"}}></i></span>
   
                             
                            {user.cpylogo==""?<>
                           
                            <span id="logotxt">LOGO</span>
                            </>:<> <img src={user.cpylogo} class="card-img img-fluid" width="96" height="350" alt=""
                             id="companysearch_img" style={{backgroundColor:"white"}}
                             
                             /> </>}
                              
                             
                            </div>
                         </div>
                         <div class="searchcard-bodycompany">
                             <div className="companyserachbox">
                             <h6>
                               <span 
                             className="cpysearchname">{user.companyname}</span> 
                          
                           
                             
                               </h6>
          
                               <h6>
                               {user.industryname==" "?<>
                                  <span 
                              className="cpycatgoryname">Category Name Not Avaliable.</span> 
                                  
                                  </>:<>
                                  
                                  <span 
                                 className="cpycatgoryname">{user.industryname}</span> 
                                  </>  }
                          
                           
                             
                               </h6>
                               {user.locationname=="" ?<>
                               <span style={{marginRight:"4%",color:"rgb(0,147,69)"}}><i class="fa fa-map-marker" style={{fontSize:14}}></i></span>
                               
                              Location Not Avaliable.
                               </>:<>
                               <span style={{marginRight:"4%",color:"rgb(0,147,69)"}}><i class="fa fa-map-marker" style={{fontSize:14}}></i></span>
                               <span className="cpycatgoryname">{user.locationname}</span>
                               
                               </>}
                              
         
                               {user.cpysummary=="" || user.cpysummary==null?
                               <h6 style={{fontSize:12,textAlign:'left',
                               fontFamily:"GothamLight",
                               width:"100%",fontWeight:"bold",height:70,marginTop:"5%",color:"transparent"}}>Please Add your summaary</h6>
                               :<>
                           <h6 style={{fontSize:12,textAlign:'left',
                           fontFamily:"GothamLight",
                           width:"100%",fontWeight:"bold",height:70,marginTop:"5%"}}>
                                 
                                 {user.cpysummary}...<span style={{color:"rgb(0,147,69)",fontFamily:"Gotham",fontSize:11}}>more</span></h6>
                           
                           </>}
   
          
                           <div className="cpyopportunity">
   
                             {user.opportunitiestype==null?null
                             :<>
                             {user.opportunitiestype.split(",")
     .map((item, i) => <span class="col-md-2">  {item}  </span>)} 
                             </>}
                             
   </div>
         
         
                             </div>
                            
         
                            
                             
                
         
         
                             
                             
                         </div>
                         <Link 
                         target="_blank"
                         to={{ 
                          
                           pathname: `/viewgigprofile/${user.userid}`, 
                            
                         }}
                        
                          id="view_searchbtn">View {user.companyname}</Link>
                     </div>
     </div>
   
          
        
        
        
        
       
                 
                 
              
             </React.Fragment>
           )
           this.setState({
             pageCount: Math.ceil(data.length / this.state.perPage),
            
             postData,
             DataisLoaded: true,
        
           })
           const postDatatwo = slice.map(user => 
          
            <React.Fragment>
           
           <div className="col-sm-3 col-md-4">
           <div class="searchcard_one" >
                        <div class="searchcard-bodycompany" style={{padding:"2%"}}>
                            <div class="searchcard-img-actionstwo"> 
                            <span
                            className="cpyfavicon">
                              <i class="fa fa-star" aria-hidden="true" style={{color:"rgb(0,147,69)"}}></i></span>
  
                            
                           {user.cpylogo==""?<>
                          
                           <span id="logotxt">LOGO</span>
                           </>:<> <img src={user.cpylogo} class="card-img img-fluid" width="96" height="350" alt=""
                            id="companysearch_img" style={{backgroundColor:"white"}}
                            
                            /> </>}
                             
                            
                           </div>
                        </div>
                        <div class="searchcard-bodycompany">
                            <div className="companyserachbox">
                            <h6>
                              <span 
                            className="cpysearchname">{user.companyname}</span> 
                         
                          
                            
                              </h6>
         
                              <h6>
                              {user.industryname==" "?<>
                                 <span 
                             className="cpycatgoryname">Category Name Not Avaliable.</span> 
                                 
                                 </>:<>
                                 
                                 <span 
                                className="cpycatgoryname">{user.industryname}</span> 
                                 </>  }
                         
                          
                            
                              </h6>
                              {user.locationname=="" ?<>
                              <span style={{marginRight:"4%",color:"rgb(0,147,69)"}}><i class="fa fa-map-marker" style={{fontSize:14}}></i></span>
                              
                             Location Not Avaliable.
                              </>:<>
                              <span style={{marginRight:"4%",color:"rgb(0,147,69)"}}><i class="fa fa-map-marker" style={{fontSize:14}}></i></span>
                              <span className="cpycatgoryname">{user.locationname}</span>
                              
                              </>}
                             
        
                              {user.cpysummary=="" || user.cpysummary==null?
                              <h6 style={{fontSize:12,textAlign:'left',
                              fontFamily:"GothamLight",
                              width:"100%",fontWeight:"bold",height:70,marginTop:"5%",color:"transparent"}}>Please Add your summaary</h6>
                              :<>
                          <h6 style={{fontSize:12,textAlign:'left',
                          fontFamily:"GothamLight",
                          width:"100%",fontWeight:"bold",height:70,marginTop:"5%"}}>
                                
                                {user.cpysummary}...<span style={{color:"rgb(0,147,69)",fontFamily:"Gotham",fontSize:11}}>more</span></h6>
                          
                          </>}
  
         
                          <div className="cpyopportunity">
  
                            {user.opportunitiestype==null?null
                            :<>
                            {user.opportunitiestype.split(",")
    .map((item, i) => <span class="col-md-2">  {item}  </span>)} 
                            </>}
                            
  </div>
        
        
                            </div>
                           
        
                           
                            
               
        
        
                            
                            
                        </div>
                        <Link 
                        target="_blank"
                        to={{ 
                         
                          pathname: `/viewgigprofile/${user.userid}`, 
                           
                        }}
                       
                         id="view_searchbtn">View {user.companyname}</Link>
                    </div>
    </div>
  
         
       
       
       
       
      
                
                
             
            </React.Fragment>
            )
  
            this.setState({
                pageCount: Math.ceil(data.length / this.state.perPage),
               
                postDatatwo,    
            })
         }
        
 
          
       });
    })
  }
  else{
    this.setState({keyGen : this.state.keyGen.filter(function(val) {return val!==value})},()=>{
      this.setState({DataisLoaded:false,mobilescreen:0})
      const sdata = {

        searchtext:this.state.searchtext,
        cpyjobcategory:this.state.keyGen.toString(),
        cpyopportunitytypes:this.state.keyOpportunity.toString(),
        cpyorgsize:this.state.keyOrgsize.toString(),
        cpyindustry:this.state.keyIndustry.toString(),
        cpylocation:this.state.keyLocation.toString(),
      
     
 
    }
    console.log(sdata)
   axios
       .post(`https://www.gigeconome.com:5200/api/getcpysearchdemoinfo`,sdata)
       .then(res => {
         //console.log(res)
         if(res.data.message=="No Search Result Found."){
           this.setState({postData:res.data.message,DataisLoaded:true})
           //console.log(res.data.message)
           this.setState({totalcount:res.data.totalcount,
            s_text:""
          })
         }
         else{
           const data = res.data.result;
           //console.log(data)
           this.setState({totalcount:res.data.totalcount,DataisLoaded: true,
            s_text:""
          })
           const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
           const postData = slice.map(user => 
           
            <React.Fragment>
         
            <div className="col-sm-3 col-md-4 mbleview bigcompanysearch">
            <div class="searchcard_one" >
                         <div class="searchcard-bodycompany" style={{padding:"2%"}}>
                             <div class="searchcard-img-actionstwo"> 
                             <span
                             className="cpyfavicon">
                               <i class="fa fa-star" aria-hidden="true" style={{color:"rgb(0,147,69)"}}></i></span>
   
                             
                            {user.cpylogo==""?<>
                           
                            <span id="logotxt">LOGO</span>
                            </>:<> <img src={user.cpylogo} class="card-img img-fluid" width="96" height="350" alt=""
                             id="companysearch_img" style={{backgroundColor:"white"}}
                             
                             /> </>}
                              
                             
                            </div>
                         </div>
                         <div class="searchcard-bodycompany">
                             <div className="companyserachbox">
                             <h6>
                               <span 
                             className="cpysearchname">{user.companyname}</span> 
                          
                           
                             
                               </h6>
          
                               <h6>
                               {user.industryname==" "?<>
                                  <span 
                              className="cpycatgoryname">Category Name Not Avaliable.</span> 
                                  
                                  </>:<>
                                  
                                  <span 
                                 className="cpycatgoryname">{user.industryname}</span> 
                                  </>  }
                          
                           
                             
                               </h6>
                               {user.locationname=="" ?<>
                               <span style={{marginRight:"4%",color:"rgb(0,147,69)"}}><i class="fa fa-map-marker" style={{fontSize:14}}></i></span>
                               
                              Location Not Avaliable.
                               </>:<>
                               <span style={{marginRight:"4%",color:"rgb(0,147,69)"}}><i class="fa fa-map-marker" style={{fontSize:14}}></i></span>
                               <span className="cpycatgoryname">{user.locationname}</span>
                               
                               </>}
                              
         
                               {user.cpysummary=="" || user.cpysummary==null?
                               <h6 style={{fontSize:12,textAlign:'left',
                               fontFamily:"GothamLight",
                               width:"100%",fontWeight:"bold",height:70,marginTop:"5%",color:"transparent"}}>Please Add your summaary</h6>
                               :<>
                           <h6 style={{fontSize:12,textAlign:'left',
                           fontFamily:"GothamLight",
                           width:"100%",fontWeight:"bold",height:70,marginTop:"5%"}}>
                                 
                                 {user.cpysummary}...<span style={{color:"rgb(0,147,69)",fontFamily:"Gotham",fontSize:11}}>more</span></h6>
                           
                           </>}
   
          
                           <div className="cpyopportunity">
   
                             {user.opportunitiestype==null?null
                             :<>
                             {user.opportunitiestype.split(",")
     .map((item, i) => <span class="col-md-2">  {item}  </span>)} 
                             </>}
                             
   </div>
         
         
                             </div>
                            
         
                            
                             
                
         
         
                             
                             
                         </div>
                         <Link 
                         target="_blank"
                         to={{ 
                          
                           pathname: `/viewgigprofile/${user.userid}`, 
                            
                         }}
                        
                          id="view_searchbtn">View {user.companyname}</Link>
                     </div>
     </div>
   
          
        
        
        
        
       
                 
                 
              
             </React.Fragment>
           )
           this.setState({
             pageCount: Math.ceil(data.length / this.state.perPage),
            
             postData, DataisLoaded: true,
        
           })
           const postDatatwo = slice.map(user => 
          
            <React.Fragment>
           
           <div className="col-sm-3 col-md-4">
           <div class="searchcard_one" >
                        <div class="searchcard-bodycompany" style={{padding:"2%"}}>
                            <div class="searchcard-img-actionstwo"> 
                            <span
                            className="cpyfavicon">
                              <i class="fa fa-star" aria-hidden="true" style={{color:"rgb(0,147,69)"}}></i></span>
  
                            
                           {user.cpylogo==""?<>
                          
                           <span id="logotxt">LOGO</span>
                           </>:<> <img src={user.cpylogo} class="card-img img-fluid" width="96" height="350" alt=""
                            id="companysearch_img" style={{backgroundColor:"white"}}
                            
                            /> </>}
                             
                            
                           </div>
                        </div>
                        <div class="searchcard-bodycompany">
                            <div className="companyserachbox">
                            <h6>
                              <span 
                            className="cpysearchname">{user.companyname}</span> 
                         
                          
                            
                              </h6>
         
                              <h6>
                              {user.industryname==" "?<>
                                 <span 
                             className="cpycatgoryname">Category Name Not Avaliable.</span> 
                                 
                                 </>:<>
                                 
                                 <span 
                                className="cpycatgoryname">{user.industryname}</span> 
                                 </>  }
                         
                          
                            
                              </h6>
                              {user.locationname=="" ?<>
                              <span style={{marginRight:"4%",color:"rgb(0,147,69)"}}><i class="fa fa-map-marker" style={{fontSize:14}}></i></span>
                              
                             Location Not Avaliable.
                              </>:<>
                              <span style={{marginRight:"4%",color:"rgb(0,147,69)"}}><i class="fa fa-map-marker" style={{fontSize:14}}></i></span>
                              <span className="cpycatgoryname">{user.locationname}</span>
                              
                              </>}
                             
        
                              {user.cpysummary=="" || user.cpysummary==null?
                              <h6 style={{fontSize:12,textAlign:'left',
                              fontFamily:"GothamLight",
                              width:"100%",fontWeight:"bold",height:70,marginTop:"5%",color:"transparent"}}>Please Add your summaary</h6>
                              :<>
                          <h6 style={{fontSize:12,textAlign:'left',
                          fontFamily:"GothamLight",
                          width:"100%",fontWeight:"bold",height:70,marginTop:"5%"}}>
                                
                                {user.cpysummary}...<span style={{color:"rgb(0,147,69)",fontFamily:"Gotham",fontSize:11}}>more</span></h6>
                          
                          </>}
  
         
                          <div className="cpyopportunity">
  
                            {user.opportunitiestype==null?null
                            :<>
                            {user.opportunitiestype.split(",")
    .map((item, i) => <span class="col-md-2">  {item}  </span>)} 
                            </>}
                            
  </div>
        
        
                            </div>
                           
        
                           
                            
               
        
        
                            
                            
                        </div>
                        <Link 
                        target="_blank"
                        to={{ 
                         
                          pathname: `/viewgigprofile/${user.userid}`, 
                           
                        }}
                       
                         id="view_searchbtn">View {user.companyname}</Link>
                    </div>
    </div>
  
         
       
       
       
       
      
                
                
             
            </React.Fragment>
            )
  
            this.setState({
                pageCount: Math.ceil(data.length / this.state.perPage),
               
                postDatatwo,    
            })
         }
        
 
          
       });
    })
  }
}



updateLocation(e,value){

    
  if(this.state.searchtext==null){
    this.setState({searchtext:""})
  }
  if (e.target.checked){
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // for smoothly scrolling
    });
    this.setState({DataisLoaded:false,mobilescreen:0})
    //console.log(e.target.checked)
    this.setState({keyLocation: this.state.keyLocation.concat([value])},()=>{
      const sdata = {

        searchtext:this.state.searchtext,
        cpyjobcategory:this.state.keyGen.toString(),
        cpyopportunitytypes:this.state.keyOpportunity.toString(),
        cpyorgsize:this.state.keyOrgsize.toString(),
        cpyindustry:this.state.keyIndustry.toString(),
        cpylocation:this.state.keyLocation.toString(),
        
     
      
     
 
    }
    console.log(sdata)
   axios
       .post(`https://www.gigeconome.com:5200/api/getcpysearchdemoinfo`,sdata)
       .then(res => {
         //console.log(res)
         if(res.data.message=="No Search Result Found."){
           this.setState({postData:res.data.message,DataisLoaded:true})
           //console.log(res.data.message)
         }
         else{
           const data = res.data.result;
           //console.log(data)
           this.setState({totalcount:res.data.totalcount,DataisLoaded: true,
            s_text:""
          })
           const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
           const postData = slice.map(user => 
            <React.Fragment>
         
            <div className="col-sm-3 col-md-4 mbleview bigcompanysearch">
            <div class="searchcard_one" >
                         <div class="searchcard-bodycompany" style={{padding:"2%"}}>
                             <div class="searchcard-img-actionstwo"> 
                             <span
                             className="cpyfavicon">
                               <i class="fa fa-star" aria-hidden="true" style={{color:"rgb(0,147,69)"}}></i></span>
   
                             
                            {user.cpylogo==""?<>
                           
                            <span id="logotxt">LOGO</span>
                            </>:<> <img src={user.cpylogo} class="card-img img-fluid" width="96" height="350" alt=""
                             id="companysearch_img" style={{backgroundColor:"white"}}
                             
                             /> </>}
                              
                             
                            </div>
                         </div>
                         <div class="searchcard-bodycompany">
                             <div className="companyserachbox">
                             <h6>
                               <span 
                             className="cpysearchname">{user.companyname}</span> 
                          
                           
                             
                               </h6>
          
                               <h6>
                               {user.industryname==" "?<>
                                  <span 
                              className="cpycatgoryname">Category Name Not Avaliable.</span> 
                                  
                                  </>:<>
                                  
                                  <span 
                                 className="cpycatgoryname">{user.industryname}</span> 
                                  </>  }
                          
                           
                             
                               </h6>
                               {user.locationname=="" ?<>
                               <span style={{marginRight:"4%",color:"rgb(0,147,69)"}}><i class="fa fa-map-marker" style={{fontSize:14}}></i></span>
                               
                              Location Not Avaliable.
                               </>:<>
                               <span style={{marginRight:"4%",color:"rgb(0,147,69)"}}><i class="fa fa-map-marker" style={{fontSize:14}}></i></span>
                               <span className="cpycatgoryname">{user.locationname}</span>
                               
                               </>}
                              
         
                               {user.cpysummary=="" || user.cpysummary==null?
                               <h6 style={{fontSize:12,textAlign:'left',
                               fontFamily:"GothamLight",
                               width:"100%",fontWeight:"bold",height:70,marginTop:"5%",color:"transparent"}}>Please Add your summaary</h6>
                               :<>
                           <h6 style={{fontSize:12,textAlign:'left',
                           fontFamily:"GothamLight",
                           width:"100%",fontWeight:"bold",height:70,marginTop:"5%"}}>
                                 
                                 {user.cpysummary}...<span style={{color:"rgb(0,147,69)",fontFamily:"Gotham",fontSize:11}}>more</span></h6>
                           
                           </>}
   
          
                           <div className="cpyopportunity">
   
                             {user.opportunitiestype==null?null
                             :<>
                             {user.opportunitiestype.split(",")
     .map((item, i) => <span class="col-md-2">  {item}  </span>)} 
                             </>}
                             
   </div>
         
         
                             </div>
                            
         
                            
                             
                
         
         
                             
                             
                         </div>
                         <Link 
                         target="_blank"
                         to={{ 
                          
                           pathname: `/viewgigprofile/${user.userid}`, 
                            
                         }}
                        
                          id="view_searchbtn">View {user.companyname}</Link>
                     </div>
     </div>
   
          
        
        
        
        
       
                 
                 
              
             </React.Fragment>
           )
           this.setState({
             pageCount: Math.ceil(data.length / this.state.perPage),
            
             postData,
             
        
           })
           const postDatatwo = slice.map(user => 
          
            <React.Fragment>
           
           <div className="col-sm-3 col-md-4">
           <div class="searchcard_one" >
                        <div class="searchcard-bodycompany" style={{padding:"2%"}}>
                            <div class="searchcard-img-actionstwo"> 
                            <span
                            className="cpyfavicon">
                              <i class="fa fa-star" aria-hidden="true" style={{color:"rgb(0,147,69)"}}></i></span>
  
                            
                           {user.cpylogo==""?<>
                          
                           <span id="logotxt">LOGO</span>
                           </>:<> <img src={user.cpylogo} class="card-img img-fluid" width="96" height="350" alt=""
                            id="companysearch_img" style={{backgroundColor:"white"}}
                            
                            /> </>}
                             
                            
                           </div>
                        </div>
                        <div class="searchcard-bodycompany">
                            <div className="companyserachbox">
                            <h6>
                              <span 
                            className="cpysearchname">{user.companyname}</span> 
                         
                          
                            
                              </h6>
         
                              <h6>
                              {user.industryname==" "?<>
                                 <span 
                             className="cpycatgoryname">Category Name Not Avaliable.</span> 
                                 
                                 </>:<>
                                 
                                 <span 
                                className="cpycatgoryname">{user.industryname}</span> 
                                 </>  }
                         
                          
                            
                              </h6>
                              {user.locationname=="" ?<>
                              <span style={{marginRight:"4%",color:"rgb(0,147,69)"}}><i class="fa fa-map-marker" style={{fontSize:14}}></i></span>
                              
                             Location Not Avaliable.
                              </>:<>
                              <span style={{marginRight:"4%",color:"rgb(0,147,69)"}}><i class="fa fa-map-marker" style={{fontSize:14}}></i></span>
                              <span className="cpycatgoryname">{user.locationname}</span>
                              
                              </>}
                             
        
                              {user.cpysummary=="" || user.cpysummary==null?
                              <h6 style={{fontSize:12,textAlign:'left',
                              fontFamily:"GothamLight",
                              width:"100%",fontWeight:"bold",height:70,marginTop:"5%",color:"transparent"}}>Please Add your summaary</h6>
                              :<>
                          <h6 style={{fontSize:12,textAlign:'left',
                          fontFamily:"GothamLight",
                          width:"100%",fontWeight:"bold",height:70,marginTop:"5%"}}>
                                
                                {user.cpysummary}...<span style={{color:"rgb(0,147,69)",fontFamily:"Gotham",fontSize:11}}>more</span></h6>
                          
                          </>}
  
         
                          <div className="cpyopportunity">
  
                            {user.opportunitiestype==null?null
                            :<>
                            {user.opportunitiestype.split(",")
    .map((item, i) => <span class="col-md-2">  {item}  </span>)} 
                            </>}
                            
  </div>
        
        
                            </div>
                           
        
                           
                            
               
        
        
                            
                            
                        </div>
                        <Link 
                        target="_blank"
                        to={{ 
                         
                          pathname: `/viewgigprofile/${user.userid}`, 
                           
                        }}
                       
                         id="view_searchbtn">View {user.companyname}</Link>
                    </div>
    </div>
  
         
       
       
       
       
      
                
                
             
            </React.Fragment>
            )
  
            this.setState({
                pageCount: Math.ceil(data.length / this.state.perPage),
               
                postDatatwo,    
            })

         }
        
 
          
       });
    })
  }
  else{
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // for smoothly scrolling
    });
    this.setState({keyLocation : this.state.keyLocation.filter(function(val) {return val!==value})},()=>{
      this.setState({DataisLoaded:false,mobilescreen:0})
      const sdata = {
        searchtext:this.state.searchtext,
        cpyjobcategory:this.state.keyGen.toString(),
        cpyopportunitytypes:this.state.keyOpportunity.toString(),
        cpyorgsize:this.state.keyOrgsize.toString(),
        cpyindustry:this.state.keyIndustry.toString(),
        cpylocation:this.state.keyLocation.toString(),
     
      
     
 
    }
    console.log(sdata)
   axios
       .post(`https://www.gigeconome.com:5200/api/getcpysearchdemoinfo`,sdata)
       .then(res => {
         //console.log(res)
         if(res.data.message=="No Search Result Found."){
           this.setState({postData:res.data.message})
           //console.log(res.data.message)
           this.setState({totalcount:res.data.totalcount,DataisLoaded: true,
            s_text:""
          })
         }
         else{
           const data = res.data.result;
           //console.log(data)
           this.setState({totalcount:res.data.totalcount,DataisLoaded: true,
            s_text:""
          })
           const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
           const postData = slice.map(user => 
           
            <React.Fragment>
         
            <div className="col-sm-3 col-md-4 mbleview bigcompanysearch">
            <div class="searchcard_one" >
                         <div class="searchcard-bodycompany" style={{padding:"2%"}}>
                             <div class="searchcard-img-actionstwo"> 
                             <span
                             className="cpyfavicon">
                               <i class="fa fa-star" aria-hidden="true" style={{color:"rgb(0,147,69)"}}></i></span>
   
                             
                            {user.cpylogo==""?<>
                           
                            <span id="logotxt">LOGO</span>
                            </>:<> <img src={user.cpylogo} class="card-img img-fluid" width="96" height="350" alt=""
                             id="companysearch_img" style={{backgroundColor:"white"}}
                             
                             /> </>}
                              
                             
                            </div>
                         </div>
                         <div class="searchcard-bodycompany">
                             <div className="companyserachbox">
                             <h6>
                               <span 
                             className="cpysearchname">{user.companyname}</span> 
                          
                           
                             
                               </h6>
          
                               <h6>
                               {user.industryname==" "?<>
                                  <span 
                              className="cpycatgoryname">Category Name Not Avaliable.</span> 
                                  
                                  </>:<>
                                  
                                  <span 
                                 className="cpycatgoryname">{user.industryname}</span> 
                                  </>  }
                          
                           
                             
                               </h6>
                               {user.locationname=="" ?<>
                               <span style={{marginRight:"4%",color:"rgb(0,147,69)"}}><i class="fa fa-map-marker" style={{fontSize:14}}></i></span>
                               
                              Location Not Avaliable.
                               </>:<>
                               <span style={{marginRight:"4%",color:"rgb(0,147,69)"}}><i class="fa fa-map-marker" style={{fontSize:14}}></i></span>
                               <span className="cpycatgoryname">{user.locationname}</span>
                               
                               </>}
                              
         
                               {user.cpysummary=="" || user.cpysummary==null?
                               <h6 style={{fontSize:12,textAlign:'left',
                               fontFamily:"GothamLight",
                               width:"100%",fontWeight:"bold",height:70,marginTop:"5%",color:"transparent"}}>Please Add your summaary</h6>
                               :<>
                           <h6 style={{fontSize:12,textAlign:'left',
                           fontFamily:"GothamLight",
                           width:"100%",fontWeight:"bold",height:70,marginTop:"5%"}}>
                                 
                                 {user.cpysummary}...<span style={{color:"rgb(0,147,69)",fontFamily:"Gotham",fontSize:11}}>more</span></h6>
                           
                           </>}
   
          
                           <div className="cpyopportunity">
   
                             {user.opportunitiestype==null?null
                             :<>
                             {user.opportunitiestype.split(",")
     .map((item, i) => <span class="col-md-2">  {item}  </span>)} 
                             </>}
                             
   </div>
         
         
                             </div>
                            
         
                            
                             
                
         
         
                             
                             
                         </div>
                         <Link 
                         target="_blank"
                         to={{ 
                          
                           pathname: `/viewgigprofile/${user.userid}`, 
                            
                         }}
                        
                          id="view_searchbtn">View {user.companyname}</Link>
                     </div>
     </div>
   
          
        
        
        
        
       
                 
                 
              
             </React.Fragment>
           )
           this.setState({
             pageCount: Math.ceil(data.length / this.state.perPage),
            
             postData, 
        
           })
           const postDatatwo = slice.map(user => 
          
            <React.Fragment>
           
           <div className="col-sm-3 col-md-4">
           <div class="searchcard_one" >
                        <div class="searchcard-bodycompany" style={{padding:"2%"}}>
                            <div class="searchcard-img-actionstwo"> 
                            <span
                            className="cpyfavicon">
                              <i class="fa fa-star" aria-hidden="true" style={{color:"rgb(0,147,69)"}}></i></span>
  
                            
                           {user.cpylogo==""?<>
                          
                           <span id="logotxt">LOGO</span>
                           </>:<> <img src={user.cpylogo} class="card-img img-fluid" width="96" height="350" alt=""
                            id="companysearch_img" style={{backgroundColor:"white"}}
                            
                            /> </>}
                             
                            
                           </div>
                        </div>
                        <div class="searchcard-bodycompany">
                            <div className="companyserachbox">
                            <h6>
                              <span 
                            className="cpysearchname">{user.companyname}</span> 
                         
                          
                            
                              </h6>
         
                              <h6>
                              {user.industryname==" "?<>
                                 <span 
                             className="cpycatgoryname">Category Name Not Avaliable.</span> 
                                 
                                 </>:<>
                                 
                                 <span 
                                className="cpycatgoryname">{user.industryname}</span> 
                                 </>  }
                         
                          
                            
                              </h6>
                              {user.locationname=="" ?<>
                              <span style={{marginRight:"4%",color:"rgb(0,147,69)"}}><i class="fa fa-map-marker" style={{fontSize:14}}></i></span>
                              
                             Location Not Avaliable.
                              </>:<>
                              <span style={{marginRight:"4%",color:"rgb(0,147,69)"}}><i class="fa fa-map-marker" style={{fontSize:14}}></i></span>
                              <span className="cpycatgoryname">{user.locationname}</span>
                              
                              </>}
                             
        
                              {user.cpysummary=="" || user.cpysummary==null?
                              <h6 style={{fontSize:12,textAlign:'left',
                              fontFamily:"GothamLight",
                              width:"100%",fontWeight:"bold",height:70,marginTop:"5%",color:"transparent"}}>Please Add your summaary</h6>
                              :<>
                          <h6 style={{fontSize:12,textAlign:'left',
                          fontFamily:"GothamLight",
                          width:"100%",fontWeight:"bold",height:70,marginTop:"5%"}}>
                                
                                {user.cpysummary}...<span style={{color:"rgb(0,147,69)",fontFamily:"Gotham",fontSize:11}}>more</span></h6>
                          
                          </>}
  
         
                          <div className="cpyopportunity">
  
                            {user.opportunitiestype==null?null
                            :<>
                            {user.opportunitiestype.split(",")
    .map((item, i) => <span class="col-md-2">  {item}  </span>)} 
                            </>}
                            
  </div>
        
        
                            </div>
                           
        
                           
                            
               
        
        
                            
                            
                        </div>
                        <Link 
                        target="_blank"
                        to={{ 
                         
                          pathname: `/viewgigprofile/${user.userid}`, 
                           
                        }}
                       
                         id="view_searchbtn">View {user.companyname}</Link>
                    </div>
    </div>
  
         
       
       
       
       
      
                
                
             
            </React.Fragment>
            )
  
            this.setState({
                pageCount: Math.ceil(data.length / this.state.perPage),
               
                postDatatwo,    
            })

         }
        
 
          
       });
    })
  }
}



updateLanguage(e,value){
  if (e.target.checked){
    //console.log(e.target.checked)
    this.setState({keyLanguage: this.state.keyLanguage.concat([value])},()=>{
      const sdata = {

        searchtext:"",
        stjobcategory:this.state.keyGen.toString(),
        stopportunitytypes:this.state.stopportunitytypes,
        stdegreesubject:this.state.keyCourse.toString(),
        studenttype:this.state.studenttype,
        studentstatus:this.state.studentstatus,
        storgsize:this.state.storgsize,
        stindustry:this.state.stindustry,
        stlocation:this.state.keyLocation.toString(),
        stlanguage:this.state.keyLanguage.toString(),
     
      
     
 
    }
    //console.log(sdata)
   axios
       .post(`https://www.gigeconome.com:5200/api/getsearchinfo`,sdata)
       .then(res => {
         //console.log(res)
         if(res.data.message=="No Search Result Found."){
           this.setState({postData:res.data.message})
           //console.log(res.data.message)
         }
         else{
           const data = res.data.result;
           //console.log(data)
           this.setState({totalcount:res.data.totalcount,DataisLoaded: true,
            s_text:""
          })
           const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
           const postData = slice.map(user => 
           
           <React.Fragment>
          
          <div className="col-sm-4">
          <div class="searchcard_one" >
                      <div class="searchcard-bodycompany" >
                          <div class="searchcard-img-actionstwo"> 
                          <span
                          style={{borderRadius:"50%",float:"right",width:"9%",margin:"1.5%",backgroundColor:"#fff"}}>
                            <i class="fa fa-star" aria-hidden="true" style={{color:"rgb(0,147,69)"}}></i></span>

                          
                          <img src={DummyImage} class="card-img img-fluid" width="96" height="350" alt=""
                          id="companysearch_img"
                          
                          /> </div>
                      </div>
                      <div class="searchcard-bodycompany">
                          <div style={{textAlign:'left'}}>
                          <h6>
                            <span 
                          style={{color:"rgb(0,147,69)",
                          fontFamily:"Gotham",
                          fontSize:17
                        }}>{user.guser_id}</span> 
                       
                         <span style={{float:"right",fontFamily:"Gotham"}}>
                            Availability </span> 
                            <span style={{marginRight:"1%",color:"rgb(0,147,69)",float:"right"}}><i className="fa fa-clock-o"></i></span> 
                            </h6>
                         
                            <h6>
 
                       
                         <span >
                            <img src={Invite} style={{float:"right",width:"25%",cursor:"pointer"}} /></span> 
                            
                            </h6>
      
                            <h6 style={{marginLeft:"1%",marginTop:"5%",fontSize:13,textAlign:'left',width:"100%",fontFamily:"GothamLight",fontWeight:"bold"}}>
                            <span style={{marginRight:"1%",color:"rgb(0,147,69)"}}><i className="fa fa-user"></i></span> {user.StudyStatus}</h6>
                            <h6 style={{marginLeft:"2%",marginTop:"0%",fontSize:13,textAlign:'left',width:"100%",fontFamily:"GothamLight",fontWeight:"bold"}}>
                            <span style={{marginRight:"1%",color:"rgb(0,147,69)"}}><i class="fa fa-graduation-cap"></i></span> &nbsp;{user.QualificationType} in {user.Course}</h6>
                            <h6 style={{marginLeft:"2%",marginTop:"0%",fontSize:13,textAlign:'left',width:"100%",fontFamily:"GothamLight",fontWeight:"bold"}}>
                            <span style={{marginRight:"2%",color:"rgb(0,147,69)"}}><i class="fa fa-calendar"></i></span>Graduation Year : {user.GraduationYear}</h6>
                            <h6 style={{marginLeft:"3%",marginTop:"0%",fontSize:13,textAlign:'left',width:"100%",fontFamily:"GothamLight",fontWeight:"bold"}}>
                            <span style={{marginRight:"2%",color:"rgb(0,147,69)"}}><i class="fa fa-map-marker" style={{fontSize:14}}></i></span>{user.locationname}</h6>
      
                            {user.summary==""?null:<>
                        <h6 style={{fontSize:11,textAlign:'left',width:"100%",fontWeight:"bold",height:50}}>
                              
                              {user.summary}...<span style={{color:"rgb(0,147,69)",fontFamily:"Gotham",fontSize:11}}>more</span></h6>
                        
                        </>}

      
      
                          </div>
                         
      
                         
                          <div  style={{textAlign:"left",height:71}}>
                          {user.skillname.split(",")
  .map((item, i) => <span class="col-md-2">  {item}  </span>)}  
</div>
             
      
      
                          
                          
                      </div>
                      <Link 
                      target="_blank"
                      to={{ 
                       
                        pathname: `/studentpublishprofile/${user.userid}`, 
                         
                      }}
                     
                       id="view_searchbtn">View {user.guser_id}</Link>
                  </div>
   </div>
 
        
      
      
      
      
     
               
               
            
           </React.Fragment>
           )
           this.setState({
             pageCount: Math.ceil(data.length / this.state.perPage),
            
             postData,
             DataisLoaded: true,
        
           })
         }
        
 
          
       });
    })
  }
  else{
    this.setState({keyLanguage : this.state.keyLanguage.filter(function(val) {return val!==value})},()=>{
      const sdata = {

        searchtext:"",
        stjobcategory:this.state.keyGen.toString(),
        stopportunitytypes:this.state.stopportunitytypes,
        stdegreesubject:this.state.keyCourse.toString(),
        studenttype:this.state.studenttype,
        studentstatus:this.state.studentstatus,
        storgsize:this.state.storgsize,
        stindustry:this.state.stindustry,
        stlocation:this.state.keyLocation.toString(),
        stlanguage:this.state.keyLanguage.toString(),
     
      
     
 
    }
    //console.log(sdata)
   axios
       .post(`https://www.gigeconome.com:5200/api/getsearchinfo`,sdata)
       .then(res => {
         //console.log(res)
         if(res.data.message=="No Search Result Found."){
           this.setState({postData:res.data.message})
           //console.log(res.data.message)
           this.setState({totalcount:res.data.totalcount,DataisLoaded: true,
            s_text:""
          })
         }
         else{
           const data = res.data.result;
           //console.log(data)
           this.setState({totalcount:res.data.totalcount,DataisLoaded: true,
            s_text:""
          })
           const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
           const postData = slice.map(user => 
           
           <React.Fragment>
          
          <div className="col-sm-4">
          <div class="searchcard_one" >
                      <div class="searchcard-bodycompany" >
                          <div class="searchcard-img-actionstwo"> 
                          <span
                          style={{borderRadius:"50%",float:"right",width:"9%",margin:"1.5%",backgroundColor:"#fff"}}>
                            <i class="fa fa-star" aria-hidden="true" style={{color:"rgb(0,147,69)"}}></i></span>

                          
                          <img src={DummyImage} class="card-img img-fluid" width="96" height="350" alt=""
                          id="companysearch_img"
                          
                          /> </div>
                      </div>
                      <div class="searchcard-bodycompany">
                          <div style={{textAlign:'left'}}>
                          <h6>
                            <span 
                          style={{color:"rgb(0,147,69)",
                          fontFamily:"Gotham",
                          fontSize:17
                        }}>{user.guser_id}</span> 
                       
                         <span style={{float:"right",fontFamily:"Gotham"}}>
                            Availability </span> 
                            <span style={{marginRight:"1%",color:"rgb(0,147,69)",float:"right"}}><i className="fa fa-clock-o"></i></span> 
                            </h6>
                         
                            <h6>
 
                       
                         <span >
                            <img src={Invite} style={{float:"right",width:"25%",cursor:"pointer"}} /></span> 
                            
                            </h6>
      
                            <h6 style={{marginLeft:"3%",marginTop:"5%",fontSize:13,textAlign:'left',width:"100%",fontFamily:"GothamLight",fontWeight:"bold"}}>
                            <span style={{marginRight:"3%",color:"rgb(0,147,69)"}}><i className="fa fa-user"></i></span> {user.StudyStatus}</h6>
                            <h6 style={{marginLeft:"2%",marginTop:"0%",fontSize:13,textAlign:'left',width:"100%",fontFamily:"GothamLight",fontWeight:"bold"}}>
                            <span style={{marginRight:"1%",color:"rgb(0,147,69)"}}><i class="fa fa-graduation-cap"></i></span> &nbsp;{user.QualificationType} in {user.Course}</h6>
                            <h6 style={{marginLeft:"3%",marginTop:"0%",fontSize:13,textAlign:'left',width:"100%",fontFamily:"GothamLight",fontWeight:"bold"}}>
                            <span style={{marginRight:"4%",color:"rgb(0,147,69)"}}><i class="fa fa-calendar"></i></span>Graduation Year : {user.GraduationYear}</h6>
                            <h6 style={{marginLeft:"4%",marginTop:"0%",fontSize:13,textAlign:'left',width:"100%",fontFamily:"GothamLight",fontWeight:"bold"}}>
                            <span style={{marginRight:"4%",color:"rgb(0,147,69)"}}><i class="fa fa-map-marker" style={{fontSize:14}}></i></span>{user.locationname}</h6>
                            {user.summary==""?null:<>
                        <h6 style={{fontSize:11,textAlign:'left',width:"100%",fontWeight:"bold",height:50}}>
                              
                              {user.summary}...<span style={{color:"rgb(0,147,69)",fontFamily:"Gotham",fontSize:11}}>more</span></h6>
                        
                        </>}

      
      
      
                          </div>
                         
      
                         
                          <div  style={{textAlign:"left",height:71}}>
                          {user.skillname.split(",")
  .map((item, i) => <span class="col-md-2">  {item}  </span>)}  
</div>
             
      
      
                          
                          
                      </div>
                      <Link 
                      target="_blank"
                      to={{ 
                       
                        pathname: `/studentpublishprofile/${user.userid}`, 
                         
                      }}
                     
                       id="view_searchbtn">View {user.guser_id}</Link>
                  </div>
   </div>
 
        
      
      
      
      
     
               
               
            
           </React.Fragment>
           )
           this.setState({
             pageCount: Math.ceil(data.length / this.state.perPage),
            
             postData, DataisLoaded: true,
        
           })
         }
        
 
          
       });
    })
  }
}

handleSearch(){
  const sdata = {

       searchtext:this.state.searchtext,
       cpyjobcategory:this.state.cpyjobcategory,
       cpyopportunitytypes:this.state.cpyopportunitytypes,
       cpyorgsize:this.state.cpyorgsize,
       cpyindustry:this.state.cpyindustry,
       cpylocation:this.state.cpylocation,
     
    
     
    

   }
   //console.log(sdata)
   this.setState({DataisLoaded:false})
  axios
      .post(`https://www.gigeconome.com:5200/api/getcpysearchdemoinfo`,sdata)
      .then(res => {
        console.log(res)
        if(res.data.message=="No Search Result Found."){
          this.setState({postData:res.data.message})
          //console.log(res.data.message)
          this.setState({totalcount:res.data.totalcount,DataisLoaded: true,
            s_text:""
          })
        }
        else{
          const data = res.data.result;
          //console.log(data)
          this.setState({totalcount:res.data.totalcount,DataisLoaded: true,
            s_text:""
          })
          const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
          const postData = slice.map(user => 
            <React.Fragment>
         
            <div className="col-sm-4 bigcompanysearch">
            <div class="searchcard_one" >
                         <div class="searchcard-bodycompany" >
                             <div class="searchcard-img-actionstwo"> 
                             <span
                             style={{borderRadius:"50%",float:"right",width:"9%",margin:"1.5%",backgroundColor:"#fff"}}>
                               <i class="fa fa-star" aria-hidden="true" style={{color:"rgb(0,147,69)"}}></i></span>
   
                         
                               {user.cpylogo==""?<>
                        
                        <span id="logotxt">LOGO</span>
                        </>:<> <img src={user.cpylogo} class="card-img img-fluid" width="96" height="350" alt=""
                         id="companysearch_img" style={{backgroundColor:"white"}}
                         
                         /> </>}
                              
                             
                            </div>
                         </div>
                         <div class="searchcard-bodycompany">
                             <div style={{textAlign:'left',paddingLeft:"2%"}}>
                             <h6>
                               <span 
                             style={{color:"rgb(0,147,69)",
                             fontFamily:"Gotham",
                             fontSize:18
                           }}>{user.companyname}</span> 
                          
                           
                             
                               </h6>
          
                               <h6>
                               {user.industryname==" "?<>
                                  <span 
                                style={{
                                fontFamily:"Gotham",
                                fontSize:15
                              }}>Category Name Not Avaliable.</span> 
                                  
                                  </>:<>
                                  
                                  <span 
                                style={{
                                fontFamily:"Gotham",
                                fontSize:15
                              }}>{user.industryname}</span> 
                                  </>  }
                          
                           
                             
                               </h6>
                               {user.locationname=="" ?<>
                               <span style={{marginRight:"4%",color:"rgb(0,147,69)"}}><i class="fa fa-map-marker" style={{fontSize:14}}></i></span>
                               
                              Location Not Avaliable.
                               </>:<>
                               <span style={{marginRight:"4%",color:"rgb(0,147,69)"}}><i class="fa fa-map-marker" style={{fontSize:14}}></i></span>
                               
                               <span style={{fontFamily:"Gotham"}}>{user.locationname}</span>
                               </>}
                              
         
                               {user.cpysummary=="" || user.cpysummary==null?
                            <h6 style={{fontSize:12,textAlign:'left',
                            fontFamily:"GothamLight",
                            width:"100%",fontWeight:"bold",height:70,marginTop:"5%",color:"transparent"}}>Please Add your summaary</h6>
                               :<>
                           <h6 style={{fontSize:12,textAlign:'left',
                           fontFamily:"GothamLight",
                           width:"100%",fontWeight:"bold",height:70,marginTop:"5%"}}>
                                 
                                 {user.cpysummary}...<span style={{color:"rgb(0,147,69)",fontFamily:"Gotham",fontSize:11}}>more</span></h6>
                           
                           </>}
   
          
                           <div  style={{textAlign:"left",marginBottom:"6%",height:50}}>
   
                             {user.opportunitiestype==null?null
                             :<>
                             {user.opportunitiestype.split(",")
     .map((item, i) => <span class="col-md-2">  {item}  </span>)} 
                             </>}
                             
   </div>
         
         
                             </div>
                            
         
                            
                             
                
         
         
                             
                             
                         </div>
                         <Link 
                         target="_blank"
                         to={{ 
                          
                           pathname: `/viewgigprofile/${user.userid}`, 
                            
                         }}
                        
                          id="view_searchbtn">View {user.companyname}</Link>
                     </div>
     </div>
   
          
        
        
        
        
       
                 
                 
              
             </React.Fragment>
          )
          this.setState({
            pageCount: Math.ceil(data.length / this.state.perPage),
           
            postData,    DataisLoaded: true,
       
          })
          const postDatatwo = slice.map(user => 
          
            <React.Fragment>
           
           <div className="col-sm-3 col-md-4">
           <div class="searchcard_one" >
                        <div class="searchcard-bodycompany" style={{padding:"2%"}}>
                            <div class="searchcard-img-actionstwo"> 
                            <span
                            className="cpyfavicon">
                              <i class="fa fa-star" aria-hidden="true" style={{color:"rgb(0,147,69)"}}></i></span>
  
                            
                           {user.cpylogo==""?<>
                          
                           <span id="logotxt">LOGO</span>
                           </>:<> <img src={user.cpylogo} class="card-img img-fluid" width="96" height="350" alt=""
                            id="companysearch_img" style={{backgroundColor:"white"}}
                            
                            /> </>}
                             
                            
                           </div>
                        </div>
                        <div class="searchcard-bodycompany">
                            <div className="companyserachbox">
                            <h6>
                              <span 
                            className="cpysearchname">{user.companyname}</span> 
                         
                          
                            
                              </h6>
         
                              <h6>
                              {user.industryname==" "?<>
                                 <span 
                             className="cpycatgoryname">Category Name Not Avaliable.</span> 
                                 
                                 </>:<>
                                 
                                 <span 
                                className="cpycatgoryname">{user.industryname}</span> 
                                 </>  }
                         
                          
                            
                              </h6>
                              {user.locationname=="" ?<>
                              <span style={{marginRight:"4%",color:"rgb(0,147,69)"}}><i class="fa fa-map-marker" style={{fontSize:14}}></i></span>
                              
                             Location Not Avaliable.
                              </>:<>
                              <span style={{marginRight:"4%",color:"rgb(0,147,69)"}}><i class="fa fa-map-marker" style={{fontSize:14}}></i></span>
                              <span className="cpycatgoryname">{user.locationname}</span>
                              
                              </>}
                             
        
                              {user.cpysummary=="" || user.cpysummary==null?
                              <h6 style={{fontSize:12,textAlign:'left',
                              fontFamily:"GothamLight",
                              width:"100%",fontWeight:"bold",height:70,marginTop:"5%",color:"transparent"}}>Please Add your summaary</h6>
                              :<>
                          <h6 style={{fontSize:12,textAlign:'left',
                          fontFamily:"GothamLight",
                          width:"100%",fontWeight:"bold",height:70,marginTop:"5%"}}>
                                
                                {user.cpysummary}...<span style={{color:"rgb(0,147,69)",fontFamily:"Gotham",fontSize:11}}>more</span></h6>
                          
                          </>}
  
         
                          <div className="cpyopportunity">
  
                            {user.opportunitiestype==null?null
                            :<>
                            {user.opportunitiestype.split(",")
    .map((item, i) => <span class="col-md-2">  {item}  </span>)} 
                            </>}
                            
  </div>
        
        
                            </div>
                           
        
                           
                            
               
        
        
                            
                            
                        </div>
                        <Link 
                        target="_blank"
                        to={{ 
                         
                          pathname: `/viewgigprofile/${user.userid}`, 
                           
                        }}
                       
                         id="view_searchbtn">View {user.companyname}</Link>
                    </div>
    </div>
  
         
       
       
       
       
      
                
                
             
            </React.Fragment>
            )
  
            this.setState({
                pageCount: Math.ceil(data.length / this.state.perPage),
               
                postDatatwo,    
            })
        
        }
       

         
      });
      
}
receivedData() {
  const sdata = {

    searchtext:"",
    cpyjobcategory:this.state.cpyjobcategory,
    cpyopportunitytypes:this.state.cpyopportunitytypes,
    cpyorgsize:this.state.cpyorgsize,
    cpyindustry:this.state.cpyindustry,
    cpylocation:this.state.cpylocation,
      
    
     
    

   }
  axios
      .post(`https://www.gigeconome.com:5200/api/getcpysearchdemoinfo`,sdata)
      .then(res => {
        //console.log(res)
        this.setState({totalcount:res.data.totalcount,DataisLoaded: true,},()=>{
          console.log(this.state.totalcount)
        })
          const data = res.data.result;
          //console.log(data)
          const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
          const postData = slice.map(user => 
          
          <React.Fragment>
         
         <div className="col-sm-3 col-md-4 bigcompanysearch">
         <div class="searchcard_one" >
                      <div class="searchcard-bodycompany" style={{padding:"2%"}}>
                          <div class="searchcard-img-actionstwo"> 
                          <span
                          className="cpyfavicon">
                            <i class="fa fa-star" aria-hidden="true" style={{color:"rgb(0,147,69)"}}></i></span>

                          
                         {user.cpylogo==""?<>
                        
                         <span id="logotxt">LOGO</span>
                         </>:<> <img src={user.cpylogo} class="card-img img-fluid" width="96" height="350" alt=""
                          id="companysearch_img" style={{backgroundColor:"white"}}
                          
                          /> </>}
                           
                          
                         </div>
                      </div>
                      <div class="searchcard-bodycompany">
                          <div className="companyserachbox">
                          <h6>
                            <span 
                          className="cpysearchname">{user.companyname}</span> 
                       
                        
                          
                            </h6>
       
                            <h6>
                            {user.industryname==" "?<>
                               <span 
                           className="cpycatgoryname">Category Name Not Avaliable.</span> 
                               
                               </>:<>
                               
                               <span 
                              className="cpycatgoryname">{user.industryname}</span> 
                               </>  }
                       
                        
                          
                            </h6>
                            {user.locationname=="" ?<>
                            <span style={{marginRight:"4%",color:"rgb(0,147,69)"}}><i class="fa fa-map-marker" style={{fontSize:14}}></i></span>
                            
                           Location Not Avaliable.
                            </>:<>
                            <span style={{marginRight:"4%",color:"rgb(0,147,69)"}}><i class="fa fa-map-marker" style={{fontSize:14}}></i></span>
                            <span className="cpycatgoryname">{user.locationname}</span>
                            
                            </>}
                           
      
                            {user.cpysummary=="" || user.cpysummary==null?
                            <h6 style={{fontSize:12,textAlign:'left',
                            fontFamily:"GothamLight",
                            width:"100%",fontWeight:"bold",height:70,marginTop:"5%",color:"transparent"}}>Please Add your summaary</h6>
                            :<>
                        <h6 style={{fontSize:12,textAlign:'left',
                        fontFamily:"GothamLight",
                        width:"100%",fontWeight:"bold",height:70,marginTop:"5%"}}>
                              
                              {user.cpysummary}...<span style={{color:"rgb(0,147,69)",fontFamily:"Gotham",fontSize:11}}>more</span></h6>
                        
                        </>}

       
                        <div className="cpyopportunity">

                          {user.opportunitiestype==null?null
                          :<>
                          {user.opportunitiestype.split(",")
  .map((item, i) => <span class="col-md-2">  {item}  </span>)} 
                          </>}
                          
</div>
      
      
                          </div>
                         
      
                         
                          
             
      
      
                          
                          
                      </div>
                      <Link 
                      target="_blank"
                      to={{ 
                       
                        pathname: `/viewgigprofile/${user.userid}`, 
                         
                      }}
                     
                       id="view_searchbtn">View {user.companyname}</Link>
                  </div>
  </div>

       
     
     
     
     
    
              
              
           
          </React.Fragment>
          )

          this.setState({
              pageCount: Math.ceil(data.length / this.state.perPage),
             
              postData,    
          })

          const postDatatwo = slice.map(user => 
          
            <React.Fragment>
           
           <div className="col-sm-3 col-md-4">
           <div class="searchcard_one" >
                        <div class="searchcard-bodycompany" style={{padding:"2%"}}>
                            <div class="searchcard-img-actionstwo"> 
                            <span
                            className="cpyfavicon">
                              <i class="fa fa-star" aria-hidden="true" style={{color:"rgb(0,147,69)"}}></i></span>
  
                            
                           {user.cpylogo==""?<>
                          
                           <span id="logotxt">LOGO</span>
                           </>:<> <img src={user.cpylogo} class="card-img img-fluid" width="96" height="350" alt=""
                            id="companysearch_img" style={{backgroundColor:"white"}}
                            
                            /> </>}
                             
                            
                           </div>
                        </div>
                        <div class="searchcard-bodycompany">
                            <div className="companyserachbox">
                            <h6>
                              <span 
                            className="cpysearchname">{user.companyname}</span> 
                         
                          
                            
                              </h6>
         
                              <h6>
                              {user.industryname==" "?<>
                                 <span 
                             className="cpycatgoryname">Category Name Not Avaliable.</span> 
                                 
                                 </>:<>
                                 
                                 <span 
                                className="cpycatgoryname">{user.industryname}</span> 
                                 </>  }
                         
                          
                            
                              </h6>
                              {user.locationname=="" ?<>
                              <span style={{marginRight:"4%",color:"rgb(0,147,69)"}}><i class="fa fa-map-marker" style={{fontSize:14}}></i></span>
                              
                             Location Not Avaliable.
                              </>:<>
                              <span style={{marginRight:"4%",color:"rgb(0,147,69)"}}><i class="fa fa-map-marker" style={{fontSize:14}}></i></span>
                              <span className="cpycatgoryname">{user.locationname}</span>
                              
                              </>}
                             
        
                              {user.cpysummary=="" || user.cpysummary==null?
                              <h6 style={{fontSize:12,textAlign:'left',
                              fontFamily:"GothamLight",
                              width:"100%",fontWeight:"bold",height:70,marginTop:"5%",color:"transparent"}}>Please Add your summaary</h6>
                              :<>
                          <h6 style={{fontSize:12,textAlign:'left',
                          fontFamily:"GothamLight",
                          width:"100%",fontWeight:"bold",height:70,marginTop:"5%"}}>
                                
                                {user.cpysummary}...<span style={{color:"rgb(0,147,69)",fontFamily:"Gotham",fontSize:11}}>more</span></h6>
                          
                          </>}
  
         
                          <div className="cpyopportunity">
  
                            {user.opportunitiestype==null?null
                            :<>
                            {user.opportunitiestype.split(",")
    .map((item, i) => <span class="col-md-2">  {item}  </span>)} 
                            </>}
                            
  </div>
        
        
                            </div>
                           
        
                           
                            
               
        
        
                            
                            
                        </div>
                        <Link 
                        target="_blank"
                        to={{ 
                         
                          pathname: `/viewgigprofile/${user.userid}`, 
                           
                        }}
                       
                         id="view_searchbtn">View {user.companyname}</Link>
                    </div>
    </div>
  
         
       
       
       
       
      
                
                
             
            </React.Fragment>
            )
  
            this.setState({
                pageCount: Math.ceil(data.length / this.state.perPage),
               
                postDatatwo,    
            })
        
      });


}

handlePageClick = (e) => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth' // for smoothly scrolling
  });
  const selectedPage = e.selected;
  const offset = selectedPage * this.state.perPage;
 
  this.setState({
      currentPage: selectedPage,
      offset: offset,
   DataisLoaded:false
  }, () => {
      this.receivedData()
  });

};
handleChange = (e) => {
  this.setState({
    [e.target.name]: e.target.value,
  });
  //sessionStorage.setItem("Search", e.target.value);
};

componentDidMount(){
  this.receivedData()
 

{/* Opportunitiy API Call */}
const cpydata = {

  dropdownlist_input:"cpysearchopportunitylist"
 }
   fetch( "https://www.gigeconome.com:5200/api/getdropdownlist", {
     method: "POST",

     body: JSON.stringify(cpydata), // data can be `string` or {object}!

     headers: { "Content-Type": "application/json" },
   })
     .then((res) => res.json())

     .catch((error) => console.error("Error:", error))

     .then((response) => {
       
     
           this.setState({ cpyOpp: response.result },()=>{
            //console.log(this.state.JobCategory)
           });   
     });


{/* Job Category API Call */}
const data = {

  dropdownlist_input:"rolecategory"
 }
   fetch( "https://www.gigeconome.com:5200/api/getdropdownlist", {
     method: "POST",

     body: JSON.stringify(data), // data can be `string` or {object}!

     headers: { "Content-Type": "application/json" },
   })
     .then((res) => res.json())

     .catch((error) => console.error("Error:", error))

     .then((response) => {
       
     
           this.setState({ JobCategory: response.result },()=>{
            //console.log(this.state.JobCategory)
           });   
     });

{/* Opportunity Type API Call */}
const odata = {

  dropdownlist_input:"oportunitiestypes"
 }
   fetch( "https://www.gigeconome.com:5200/api/getdropdownlist", {
     method: "POST",

     body: JSON.stringify(odata), // data can be `string` or {object}!

     headers: { "Content-Type": "application/json" },
   })
     .then((res) => res.json())

     .catch((error) => console.error("Error:", error))

     .then((response) => {
       
     
           this.setState({ OpportunityType: response.result },()=>{
            //console.log(this.state.OpportunityType)
           });   
     });


{/* Degree API Call */}
const cdata = {

  dropdownlist_input:"stusearchdegreelist"
 }
   fetch( "https://www.gigeconome.com:5200/api/getdropdownlist", {
     method: "POST",

     body: JSON.stringify(cdata), // data can be `string` or {object}!

     headers: { "Content-Type": "application/json" },
   })
     .then((res) => res.json())

     .catch((error) => console.error("Error:", error))

     .then((response) => {
       
     
           this.setState({ Course: response.result },()=>{
            //console.log(this.state.Course)
           });   
     });
      
{/* OrgSize API Call */}
const industrydata = {

  dropdownlist_input:"cpysearchorgsizelist"
 }
   fetch( "https://www.gigeconome.com:5200/api/getdropdownlist", {
     method: "POST",

     body: JSON.stringify(industrydata), // data can be `string` or {object}!

     headers: { "Content-Type": "application/json" },
   })
     .then((res) => res.json())

     .catch((error) => console.error("Error:", error))

     .then((response) => {
       
     
           this.setState({ Industry: response.result },()=>{
            //console.log(this.state.Course)
           });   
     });


{/* Industry API Call */}
const languagedata = {

  dropdownlist_input:"cpysearchindustrylist"
 }
   fetch( "https://www.gigeconome.com:5200/api/getdropdownlist", {
     method: "POST",

     body: JSON.stringify(languagedata), // data can be `string` or {object}!

     headers: { "Content-Type": "application/json" },
   })
     .then((res) => res.json())

     .catch((error) => console.error("Error:", error))

     .then((response) => {
       
     
           this.setState({ Language: response.result },()=>{
            //console.log(this.state.Course)
           });   
     });


     {/* Location API Call */}
const locationdata = {

  dropdownlist_input:"cpysearchlocationlist"
 }
   fetch( "https://www.gigeconome.com:5200/api/getdropdownlist", {
     method: "POST",

     body: JSON.stringify(locationdata), // data can be `string` or {object}!

     headers: { "Content-Type": "application/json" },
   })
     .then((res) => res.json())

     .catch((error) => console.error("Error:", error))

     .then((response) => {
       
     
           this.setState({ Location: response.result },()=>{
            //console.log(this.state.Course)
           });   
     });


{/* Job Category API Call */}
const getcategorydata = {

  dropdownlist_input:"cpysearchcategorylist"
 }
   fetch( "https://www.gigeconome.com:5200/api/getdropdownlist", {
     method: "POST",

     body: JSON.stringify(getcategorydata), // data can be `string` or {object}!

     headers: { "Content-Type": "application/json" },
   })
     .then((res) => res.json())

     .catch((error) => console.error("Error:", error))

     .then((response) => {
       
     
           this.setState({ searchJob: response.result },()=>{
            //console.log(this.state.searchJob)
           });   
     });
}
handleCheck() {
  this.setState({checked: !this.state.checked});
}
  render() {
    var msg;
    if (this.state.checked) {
      msg = "checked";
    } else {
      msg = "unchecked";
    }
    {/*  const { DataisLoaded } = this.state;
   if (DataisLoaded==false) return <div>
          <Header />
         <div class="text-center">
         <img src={LoadingIcon} className="loadingicon"/> </div>
         
         </div> 
         ; */}
  
    return (
      <div>
          <Header />
          
        <div class="container-fluid" >
        {this.state.totalcount==""||this.state.totalcount==null?null:<>
              <h5 className="companysearhtxt">{this.state.totalcount} Companies Found.</h5>
              </>}
          <div class="row">
            <div
                            class={this.state.mobilescreen==0?"col-sm-3 sidebarsearch":"col-sm-3 sidebarsearchcompany"}
              style={{
              
                marginTop:this.state.postData=="No Search Result Found."?"7%":"0%"
              }}
            >
              <div id="container">
                <div class="accordion">
                  <label>
                    <h2 style={{ fontFamily: "GothamLight",fontWeight:"bold" }}>
                      Filters{" "}
                      <i
                        class="fa fa-refresh"
                        onClick={this.refresh}
                        style={{ color: "rgb(0, 147, 69)", float: "right" }}
                      ></i>
                    </h2>
                  </label>
                  <label for="tm" class="accordionitem">
                    <h2>
                    Job Department<span class="arrow">&#8964;</span>
                    </h2>
                  </label>
                  <input type="checkbox" id="tm"  onChange={this.handleCheck} defaultChecked={this.state.checked}/>


                  {this.state.searchJob.map((user) => (
                            <>
                              <p class="hiddentext">
                                {" "}
 
                                <input
                                  type="checkbox" className="mt-2"
                                  label='Add' 
                                  name='list'
                                  key={user.categoryid} 
                                  onClick={(e)=>this.updateStateList(e,user.categoryid)}  
                                />
                                
                                
                                <span id="jobcategory">{user.categoryname}</span>
                               
                                
                              </p>
                            </>
                          ))}
               
                </div>

                <div class="accordion">
                  <label for="tn" class="accordionitem" >
                    <h2>
                      Opportunity Types <span class="arrow">&#8964;</span>
                    </h2>
                  </label>
                  <input type="checkbox" id="tn" onChange={this.handleCheck} defaultChecked={this.state.checked} />
                  
                            
                  {this.state.cpyOpp.map((user) => (
                            <>
                          
                              <p class="hiddentext">
                                {" "}
 
                                <input
                                  type="checkbox" className="mt-1"
                                  label='Add'
                                  name='list'
                                  key={user.opportunityid} 
                                  onClick={(e)=>this.updateOpportunity(e,user.opportunityid)} 
                                  
                                />
                                
                                <span id="jobcategory">{user.opportunityname}</span>

                               
                                
                              </p>
                            </>
                          ))}
                       
                </div>

           
              
         
                <div class="accordion">
                  <label for="tr" class="accordionitem">
                    <h2>
                      Org Size <span class="arrow">&#8964;</span>
                    </h2>
                  </label>
                  <input type="checkbox" id="tr" onChange={this.handleCheck} defaultChecked={this.state.checked}/>
                  {this.state.Industry.map((user) => (
                            <>
                          
                              <p class="hiddentext">
                                {" "}
 
                                <input
                                  type="checkbox" className="mt-1"
                                  label='Add'
                                  name='list'
                                  key={user.orgsizeid} 
                                  onClick={(e)=>this.updateOrgsize(e,user.orgsizeid)} 
                                  
                                />
                                
                                <span id="jobcategory">{user.orgsizename}</span>

                               
                                
                              </p>
                            </>
                          ))}
                </div>
                <div class="accordion">
                  <label for="ts" class="accordionitem">
                    <h2>
                      Industry <span class="arrow">&#8964;</span>
                    </h2>
                  </label>
                  

                  <input type="checkbox" id="ts" onChange={this.handleCheck} defaultChecked={this.state.checked}/>
                  {this.state.Language.map((user) => (
                            <>
                          
                              <p class="hiddentext">
                                {" "}
 
                                <input
                                  type="checkbox" className="mt-1"
                                  label='Add'
                                  name='list'
                                  key={user.industryid} 
                                  onClick={(e)=>this.updateIndustry(e,user.industryid)} 
                                  
                                />
                                
                                <span id="jobcategory">{user.industryname}</span>

                               
                                
                              </p>
                            </>
                          ))}
                                    
                </div>
                <div class="accordion">
                  <label for="tt" class="accordionitem">
                    <h2>
                      Location<span class="arrow">&#8964;</span>
                    </h2>
                  </label>
                  <input type="checkbox" id="tt" onChange={this.handleCheck} defaultChecked={this.state.checked} />
                  {this.state.Location.map((user) => (
                            <>
                          
                              <p class="hiddentext">
                                {" "}
 
                                <input
                                  type="checkbox" className="mt-1"
                                  label='Add'
                                  name='list'
                                  key={user.locationid} 
                                  onClick={(e)=>this.updateLocation(e,user.locationid)} 
                                  
                                />
                                
                                <span id="jobcategory">{user.locationname}</span>

                               
                                
                              </p>
                            </>
                          ))}
                </div>
             
              </div>
            </div>
            <div class="col-sm-9 text-center" >


             

           
           {/*         <div class="input-group companysearchbar">
              
    <input type="text" name="searchtext"
   
    class="form-control shadow-none"
    onChange={this.handleChange} 
    autoComplete="off"
    onKeyPress={event => {
      if (event.key === "Enter") {
        this.handleSearch();
      }
    }}
    style={{backgroundColor:"#cbcbcb",border:"2px solid rgb(0,147,69)"}}
    placeholder="Search...." />
    <div class="input-group-append">
      <button class="btn btn-secondary"
onClick={this.handleSearch}
       type="submit" style={{backgroundColor:"#cbcbcb",border:"2px solid rgb(0,147,69)",height:40}}>
        <i class="fa fa-search"></i>
      </button>
    </div>
  </div>*/}
      
              <div class="container justify-content-center">
             
            <div class="input-group" id="companyressearchbar">
    <input type="text" name="searchtext"
    value={this.state.searchtext}
    class="form-control shadow-none"
    onChange={this.handleChange} 
    onKeyPress={event => {
      if (event.key === "Enter") {
        this.handleSearch();
      }
    }}
    autoComplete="off"
    id="searchheight"
    placeholder="Search...." />
    <div class="input-group-append">
      <button class="btn btn-secondary"
onClick={this.handleSearch}
       type="submit"  id="searchheight">
        <i class="fa fa-search"></i>
      </button>
      <i className="fa fa-filter filtericon" onClick={this.handleMobile}></i>
      <i className="fa fa-sort-amount-desc filtericon" style={{paddingLeft:"20%"}} onClick={this.handleMobile}></i>
    </div>
  </div>
   {/*  <div class="row" >
   {
 
 this.state.data.map((user) => (
 
     <div class="col-md-4 mt-2">
       

  <div class="searchcard_one" >
                <div class="searchcard-bodycompany" >
                    <div class="searchcard-img-actionstwo"> <img src={user.userphoto} class="card-img img-fluid" width="96" height="350" alt=""
                    id="companysearch_img"
                    
                    /> </div>
                </div>
                <div class="searchcard-bodycompany">
                    <div style={{textAlign:'left'}}>
                    <h6>
                      <span 
                    style={{color:"rgb(0,147,69)",
                    fontFamily:"Gotham",
                    fontSize:17
                  }}>{user.guser_id}</span> 
                 
                   <span style={{float:"right",fontFamily:"Gotham"}}>
                      Availability </span> 
                      <span style={{marginRight:"1%",color:"rgb(0,147,69)",float:"right"}}><i className="fa fa-clock-o"></i></span> 
                      </h6>
                   
                      <h6 style={{marginTop:"-3%"}}>
                      <span 
                    style={{color:"rgb(0,147,69)",
                    fontFamily:"Gotham",
                    fontSize:12
                  }}>{user.skills} Developer</span> 
                 
                   <span >
                      <img src={Invite} style={{float:"right",width:"25%",cursor:"pointer"}} /></span> 
                      
                      </h6>

                      <h6 style={{marginLeft:"1%",marginTop:"0%",fontSize:13,textAlign:'left',width:"100%",fontFamily:"GothamLight",fontWeight:"bold"}}>
                      <span style={{marginRight:"1%",color:"rgb(0,147,69)"}}><i className="fa fa-graduation-cap"></i></span> {user.StudyStatus}</h6>
                      <h6 style={{marginLeft:"2%",marginTop:"0%",fontSize:13,textAlign:'left',width:"100%",fontFamily:"GothamLight",fontWeight:"bold"}}>
                      <span style={{marginRight:"1%",color:"rgb(0,147,69)"}}><i class="fa fa fa-bookmark"></i></span> &nbsp;{user.QualificationType} in {user.Course}</h6>
                      <h6 style={{marginLeft:"2%",marginTop:"0%",fontSize:13,textAlign:'left',width:"100%",fontFamily:"GothamLight",fontWeight:"bold"}}>
                      <span style={{marginRight:"2%",color:"rgb(0,147,69)"}}><i class="fa fa-calendar"></i></span>Graduation Year : {user.GraduationYear}</h6>
                      <h6 style={{marginLeft:"3%",marginTop:"0%",fontSize:13,textAlign:'left',width:"100%",fontFamily:"GothamLight",fontWeight:"bold"}}>
                      <span style={{marginRight:"2%",color:"rgb(0,147,69)"}}><i class="fa fa-map-marker" style={{fontSize:14}}></i></span>{user.location}</h6>

                      <h6 style={{fontSize:11,textAlign:'left',width:"100%",fontFamily:"GothamLight",fontWeight:"bold",height:100}}>
                        
                        {user.summary}</h6>



                    </div>
                   

                    <table class="table" id="search_table">
    <thead>
      <tr>
        <th>Java Script</th>
        <th>Java</th>
        <th>HTML</th>
        <th>HTML</th>
      </tr>
         <tr>
        <th>Java Script</th>
        <th>Java</th>
        <th>HTML</th>
      </tr>
    </thead>
 
  </table>


                    
                    
                </div>
                <div id="view_searchbtn">View {user.guser_id}</div>
            </div>




</div> 

   ))}
 
</div> */}
 
 {this.state.DataisLoaded==false?<>
  
         <div class="text-center" style={{marginTop:"5%"}}>
         <img src={LoadingIcon} className="loadingicon"/> </div>
         
      
         </>:<>
         <div className="row">
 {this.state.postData=="No Search Result Found."?
<>
<span style={{margin:"auto",fontSize:20,fontFamily:"Gotham",marginTop:"2%"}}>{this.state.postData}</span>
   
<div class="input-group" id="companyressearchbar">
    <input type="text" name="searchtext"
    value={this.state.searchtext}
    class="form-control shadow-none"
    onChange={this.handleChange} 
    onKeyPress={event => {
      if (event.key === "Enter") {
        this.handleSearch();
      }
    }}
    autoComplete="off"
    id="searchheight"
    placeholder="Search...." />
    <div class="input-group-append">
      <button class="btn btn-secondary"
onClick={this.handleSearch}
       type="submit"  id="searchheight">
        <i class="fa fa-search"></i>
      </button>
      <i className="fa fa-filter filtericon" onClick={this.handleMobile}></i>
      <i className="fa fa-sort-amount-desc filtericon" style={{paddingLeft:"20%"}} onClick={this.handleMobile}></i>
    </div>
  </div>
</>:<>
<div className="mobilescreencompnay mob_screenbox">
<Carousel
        showArrows={true}
        infiniteLoop={true}
        showThumbs={false}
        showStatus={false}
        autoPlay={true}
        interval={6100}
      >
{this.state.postDatatwo}
        
      </Carousel>

</div>

{this.state.postData}
</> 
}
  
 </div>
         
         </>}


     
      
 
              <ReactPaginate
                  previousLabel={"prev"}
                  nextLabel={"next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}/>

    


       
        
       
     {/*<div class="col-md-4 mt-2">
            <div class="searchcard_one">
                <div class="searchcard-bodycompany">
                    <div class="searchcard-img-actionstwo"> <img src="https://res.cloudinary.com/dxfq3iotg/image/upload/v1562074043/234.png" class="card-img img-fluid" width="96" height="350" alt="" /> </div>
                </div>
                <div class="searchcard-bodycompany bg-light text-center">
                    <div class="mb-2">
                        <h6 class="font-weight-semibold mb-2"> <a href="#" class="text-default mb-2" data-abc="true">Toshiba Notebook with 500GB HDD & 8GB RAM</a> </h6> <a href="#" class="text-muted" data-abc="true">Laptops & Notebooks</a>
                    </div>
                    <h3 class="mb-0 font-weight-semibold">$250.99</h3>
                    <div> <i class="fa fa-star star"></i> <i class="fa fa-star star"></i> <i class="fa fa-star star"></i> <i class="fa fa-star star"></i> </div>
                    <div class="text-muted mb-3">34 reviews</div> <button type="button" class="btn bg-cart"><i class="fa fa-cart-plus mr-2"></i> Add to cart</button>
                </div>
            </div>
        </div> */}
        
    </div>

             </div>
            
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
export default CompanySearch;
