import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route,Switch,withRouter} from "react-router-dom";
import Home from './components/Home/Home';
import Signup from './components/Signup/Signup';
import Studentsignup_one from './components/Signup/Studentsignup_one';
import Studentsignup_two from './components/Signup/Studentsignup_two';
import Studentsignup_three from './components/Signup/Studentsignup_three';
import Studentsignup_four from './components/Signup/Studentsignup_four';
import Studentsignup_five from './components/Signup/Studentsignup_five';
import Studentdashboard from './components/Dashboard/Studentdashboard';
import TermsandCondition from './components/Terms/TermsandCondition';
import StudentHowitswork from './components/Home/StudentHowitswork';
import Cookie from './components/Terms/Cookie';
import Aboutus from './components/Home/Aboutus';
import Company from './components/Company/Company';
import Universites from './components/Universities/Universites';
import Signin from './components/Signin/Signin';
import Code from './components/Home/Code';
import Inclusion from './components/Home/Inclusion';
import Privacy from './components/Home/Privacy';
import Companysignup from './components/Company/Companysignup';
import Companysignuptwo from './components/Company/Companysignuptwo';
import Companysignupthree from './components/Company/Companysignupthree';
import Reset from './components/Signin/Reset';
import Cookiepolicy from './components/Terms/Cookiepolicy';
import Index from './components/Home/Index';
import Login from './components/Signin/Login';
import Checkbox from './components/Signup/Checkbox';
import Companysignupfour from './components/Company/Companysignupfour';
import CompanySignupbacktwo from './components/Company/CompanySignupbacktwo';
import Companysignupfive from './components/Company/Companysignupfive';
import CompanyDashboard from './components/Dashboard/CompanyDashboard';
import CompanySecondDashboard from './components/Dashboard/CompanySecondDashboard';
import CompanyProfile from './components/Dashboard/CompanyProfile';
import CompanySetting from './components/Dashboard/CompanySetting';
import CompanyMessage from './components/Dashboard/CompanyMessage';
import StudentMessage from './components/Dashboard/StudentMessage';
import StudentSetting from './components/Dashboard/StudentSetting';
import StudentPublishProfile from './components/Dashboard/StudentPublishProfile';
import CompanyPublishProfile from './components/Dashboard/CompanyPublishProfile';
import PostaProject from './components/Dashboard/PostaProject';
import PostaProject_one from './components/Dashboard/PostaProject_one';
import PostaProject_two from './components/Dashboard/PostaProject_two';
import PostaProject_three from './components/Dashboard/PostaProject_three';
import ProjectSummary from './components/Dashboard/ProjectSummary';
import Opportunities_one from './components/Dashboard/Opportunities_one';
import Opportunities_two from './components/Dashboard/Opportunities_two';
import Opportunities_three from './components/Dashboard/Opportunities_three';
import OpportunitySummary from './components/Dashboard/OpportunitySummary';
import LandingPage from './components/PostaProject/LandingPage';
import BasicDetails from './components/PostaProject/BasicDetails';
import Duration from './components/PostaProject/Duration';
import SigninProject from './components/PostaProject/SigininProject';
import PreviewPage from './components/PostaProject/PreviewPage';
import Opportunities_Basic from './components/PostaProject/OpportunityBasic';
import OpportunityDuration from './components/PostaProject/OpportunityDuration';
import OpportunitySignin from './components/PostaProject/OpportunitySignin';
import StudentSearch from './components/Home/StudentSearch';
import Community_one from './components/Home/Community_one';
import Community_two from './components/Home/Community_two';
import Community_three from './components/Home/Community_three';
import SigninHeader from './components/Header/SigninHeader';
import Community_onenon from './components/Home/Community_onenon';
import Community_nontwo from './components/Home/Community_nontwo';
import SigninHomeStudent from './components/Home/SigninHomeStudent';
import Community_nonthree from './components/Home/Community_nonthree';
import SuccessReg from './components/Signup/SuccessReg';
import StudentOpen from './components/Dashboard/StudentOpen';
import StudentbackSignup_two from './components/Signup/StudentbackSignup_two';
import Companysignupbackthree from './components/Company/Companysignupbackthree';
import Studentsignupbackfour from './components/Signup/StudentSignupbackfour';
import Reset_two from './components/Signin/Reset_two';
import ResetSuccess from './components/Signin/ResetSuccess';
import Emailverification from './components/Signin/Emailverification';
import ViewGigProfile from './components/Home/ViewGigProfile';
import ContactUs from './components/Home/ContactUs';
import Faq from './components/Home/Faq';
import CompanySearch from './components/Home/CompanySearch';
import PostaprojectSignin from './components/Dashboard/PostaprojectSignin';
import RequestaDemo from './components/Home/RequestaDemo';
import StudentOpportunity from './components/Dashboard/StudentOpportunity';
import CompanyOpportunity from './components/Dashboard/CompanyOpportunity';
import ProjectSummaryStudent from './components/Dashboard/ProjectSummaryStudent';
import OpportunitySummaryStudent from './components/Dashboard/OpportunitySummaryStudent';
import Startup from './components/Company/Startup';
import Educators from './components/Company/Educators';
import Employability from './components/Home/Employability';
import EmpTestDescription from './components/Home/EmpTestDescription';
import EmpGuestSignup from './components/Home/EmpGuestSignup';
import EmpQuestions from './components/Home/EmpQuestions';
import EmpThanksPage from './components/Home/EmpThanksPage';
import EmpTestSignin from './components/Home/EmpTestSignin';
import EmpThanksPageSignin from './components/Home/EmpThanksPageSignin';
import EmpFullreport from './components/Dashboard/EmpFullreport';
import GuestPDF from './components/Home/GuestPDF';

import Stusignupemployability from './components/Signup/Stusignupemployability';
import RetakeEmpTest from './components/Home/RetakeEmpTest';
import EmpnotRetake from './components/Home/EmpnotRetake';
import Studentsignup_newfour from './components/Signup/Studentsignup_newfour';
import Studentsignup_orgsize from './components/Signup/Studentsignup_orgsize';
import Studentsignup_industry from './components/Signup/Studentsignup_industry';
import Studentsignup_Jobdepartment from "./components/Signup/Studentsignup_Jobdepartment";
import Student_signupworkexp from "./components/Signup/Student_signupworkexp";
import GradOpportunity from './components/Home/GradOpportunity';
import Gradopp_one from './components/Dashboard/Gradopp_one';
import Gradopp_two from './components/Dashboard/Gradopp_two';
import Gradopp_three from './components/Dashboard/Gradopp_three';
import Gradopp_preview from './components/Dashboard/Gradopp_preview';
import GradOppIndividual from './components/Dashboard/GradOppIndividual';
import ResourcePage from './components/Home/ResourcePage';
import BlogReadmore from './components/Home/BlogReadmore';
import Event from './components/Home/Event';
function App() {
 
  return (
    <div>
      
     <Router>
      <div>
        <Switch>
        <Route exact path="/" component={Index} />
        <Route  path="/signup" component={Signup} />
        <Route  path="/studentsignup_one" component={Studentsignup_one} />
        <Route  path="/studentsignup_two" component={Studentsignup_two} />
        <Route  path="/studentsignup_three" component={Studentsignup_three} />
        <Route  path="/studentsignup_four" component={Studentsignup_four} />
        <Route  path="/studentsignup_five" component={Studentsignup_five} />
        <Route  path="/studentdashboard"  component={Studentdashboard} />
        <Route  path="/termsandcondition" component={TermsandCondition} />
        <Route  path="/studentHowitswork" component={StudentHowitswork} />
        <Route  path="/cookie" component={Cookie} />
        <Route  path="/aboutus" component={Aboutus} />
        <Route  path="/company" component={Company} />
        <Route  path="/universites" component={Universites} />
        <Route  path="/signin" component={Signin} />
        <Route  path="/code" component={Code} />
        <Route  path="/inclusion" component={Inclusion} />
        <Route  path="/privacy" component={Privacy} />
        <Route  path="/companysignup" component={Companysignup} />
        <Route  path="/companysignuptwo" component={Companysignuptwo} />
        <Route  path="/companysignupthree" component={Companysignupthree} />
        <Route  path="/reset" component={Reset} />
        <Route  path="/cookiepolicy" component={Cookiepolicy} />
        <Route  path="/home" component={Index} />
        <Route  path="/login" component={Login} />
        <Route  path="/checkbox" component={Checkbox} />
        <Route  path="/companysignupfour" component={Companysignupfour} />
        <Route  path="/companysignupfive" component={Companysignupfive} />
        <Route  path="/companydashboard" component={CompanyDashboard} />
        <Route  path="/companyseconddashboard" component={CompanySecondDashboard} />
        <Route  path="/companyprofile" component={CompanyProfile} />
        <Route  path="/companysetting" component={CompanySetting} />
        <Route  path="/companymessage" component={CompanyMessage} />
        <Route  path="/studentmessage" component={StudentMessage} />
        <Route  path="/studentsetting" component={StudentSetting} />
        <Route  path="/studentpublishprofile/:id" component={StudentPublishProfile} />
        <Route  path="/companypublishprofile/:id" component={CompanyPublishProfile} />
        <Route  path="/postaproject" component={PostaProject} />
        <Route  path="/postaproject_one" component={PostaProject_one} />
        <Route  path="/postaproject_two" component={PostaProject_two} />
        <Route  path="/postaproject_three" component={PostaProject_three} />
        <Route  path="/projectsummary" component={ProjectSummary} />
        <Route  path="/opportunities_one" component={Opportunities_one} />
        <Route  path="/opportunities_two" component={Opportunities_two} />
        <Route  path="/opportunities_three" component={Opportunities_three} />
        <Route  path="/opportunitysummary" component={OpportunitySummary} />
        <Route  path="/landingpage" component={LandingPage} />
        <Route  path="/basicdetails" component={BasicDetails} />
        <Route  path="/duration" component={Duration} />
        <Route  path="/signinproject" component={SigninProject} />
        <Route  path="/PreviewPage" component={PreviewPage} />
        <Route  path="/opportunitybasic" component={Opportunities_Basic} />
        <Route  path="/opportunityduration" component={OpportunityDuration} />
        <Route  path="/opportunitysignin" component={OpportunitySignin} />
        <Route  path="/studentsearch" component={StudentSearch} />
        <Route  path="/community_one" component={Community_one} />
        <Route  path="/community_two" component={Community_two} />
        <Route  path="/community_three" component={Community_three} />
        <Route  path="/signinhomestudent" component={SigninHomeStudent} />
        <Route  path="/signinheader" component={SigninHeader} />
        <Route  path="/communitynon" component={Community_onenon} />
        <Route  path="/communitynontwo" component={Community_nontwo} />
        <Route  path="/communitynonthree" component={Community_nonthree} />
        <Route  path="/registrationsuccess" component={SuccessReg} />
        <Route  path="/studentopen" component={StudentOpen} />
        <Route  path="/studentbacksignuptwo" component={StudentbackSignup_two} />
        <Route  path="/companybacksignuptwo" component={CompanySignupbacktwo} />
        <Route  path="/companybacksignupthree" component={Companysignupbackthree} />
        <Route  path="/studentsignupbackfour" component={Studentsignupbackfour} />
        <Route  path="/changepassword" component={Reset_two} />
        <Route  path="/resetsuccess" component={ResetSuccess} />
        <Route  path="/emailverification" component={Emailverification} />
        <Route  path="/viewgigprofile/:id" component={ViewGigProfile} />
        <Route  path="/contactus" component={ContactUs} />
        <Route  path="/faq" component={Faq} />
        <Route  path="/companysearch" component={CompanySearch} />
        <Route  path="/postaprojectnotsign" component={PostaprojectSignin} />
        <Route  path="/requestademo" component={RequestaDemo} />
        <Route  path="/studentopportunity" component={StudentOpportunity} />
        <Route  path="/companyopportunity" component={CompanyOpportunity} />
        <Route  path="/projectsummarystudent" component={ProjectSummaryStudent} />
        <Route  path="/opportuniysummarystudent" component={OpportunitySummaryStudent} />
        <Route  path="/startups" component={Startup} />
        <Route  path="/educators" component={Educators} />
        <Route  path="/emplyoabilityone" component={Employability} />
        <Route  path="/emptestdescription" component={EmpTestDescription} />
        <Route  path="/empguestsignup" component={EmpGuestSignup} />
        <Route  path="/emptestquestions" component={EmpQuestions} />
        <Route  path="/empthankspage" component={EmpThanksPage} />
        <Route  path="/emptestsignin" component={EmpTestSignin} />
        <Route  path="/empthankspagesignin" component={EmpThanksPageSignin} />
        <Route  path="/empfullreport" component={EmpFullreport} />
        <Route  path="/guestpdf" component={GuestPDF} />
        <Route  path="/stusignupindustry" component={Studentsignup_industry} />
        <Route  path="/stusignupemployability" component={Stusignupemployability} />
        <Route  path="/stutellusmore" component={Studentsignup_newfour}  />
        <Route  path="/stuorgsize" component={Studentsignup_orgsize}  />
        <Route  path="/retakeemptest" component={RetakeEmpTest} />
        <Route  path="/noemptest" component={EmpnotRetake} />
        <Route  path="/stusignupjobdept" component={Studentsignup_Jobdepartment} />
        <Route  path="/stusignupworkexperience" component={Student_signupworkexp} />
        <Route  path="/gradopportunity" component={GradOpportunity} />
        <Route  path="/gradopportunitystepone" component={Gradopp_one} />
        <Route  path="/gradopportunitysteptwo" component={Gradopp_two} />
        <Route  path="/gradopportunitystepthree" component={Gradopp_three} />
        <Route  path="/gradopportunitypreview" component={Gradopp_preview} />
        <Route  path="/gradoppindividualpage/:id1/:id2" component={GradOppIndividual} />
        <Route  path="/resourcepage" component={ResourcePage} />
        <Route  path="/blogreadmore" component={BlogReadmore} />
        <Route  path="/eventpage" component={Event} />
        </Switch>
      </div>
    </Router>
    </div>
  );
}

export default App;
