import React from "react";
import './signup.css';
import { Link } from "react-router-dom";
import Logo from '../../images/logo.png';
import Students from '../../images/students.png'
import Universities from '../../images/universites.png'
import Startup from '../../images/startup.png'
import Companies from '../../images/companies.png'
import signup11 from '../../images/signup.png'
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
const SignUp = () => {
  return (
    <div>
   <Header />
    
   
      {/* End Header */}
          <main id="main" style={{ background: "#303030", clear: "both" }}>
            <br />
            <br />
            {/* ======= About Section ======= */}
            <section id="clients" className="clients">
              <div className="container" data-aos="fade-up">
                <style
                  type="text/css"
                  dangerouslySetInnerHTML={{
                    __html:
                      "\n    .boxxlr{\n    border: 0px solid green;\n margin-top:2%;\n   background: #edf8f2;\n    margin-right: -3.5%;\n    margin-bottom: 9%;\n    color: #000;\n    margin-left: 7%;\n    width: 26%;\n    }\n/*.boxxlr:hover {\n    border: 0px solid green;\n    background: green;\n    margin-right: 1%;\n    margin-bottom: 1%;\n     color: #fff;\n}*/\n\n.boxclrtitle {\nfont-size: 12px;}\n\n.boxclrtitle1 {\nfont-size: 18px;}\n/*.boxxlr .boxclrtitle:hover {\nfont-size: 12px;}*/\n\n\n  ",
                  }}
                />
                <div className="row no-gutters">
                  <div className="content col-xl-11 d-flex align-items-stretch">
                    <div className="content">
                      <div
                        className="row signupcard"
                      
                      >
                        <h2
                          style={{
                            fontSize: "20px",
                            textAlign: "center",
                            color: "#fff",
                           
                            background: "rgb(0,147,69)",
                            padding: "1%",
                            width: "82%",
                            margin: "6%",
                          }}
                        >
                          {" "}
                          Sign Up{" "}
                        </h2>
                        <div className="row">
                          <div className="col-md-5">
                            <h2
                              style={{
                                fontSize: "30px",
                                letterSpacing: "4px",
                                textAlign: "right",
                                marginRight: "0%",
                                color: "#000",
                              }}
                            >
                              <div id="text_one">
                              GigeconoMe -{" "}
                              </div>
                            </h2>
                          </div>
                          <div className="col-md-6">
                            <p className="signupsubheading">
                            Welcome to GigeconoMe - Join our community of Students, Educators and Companies.
                            </p>
                          </div>
                          <br />
                        </div>
                      
                        <div className="row">
                          <div className="col-md-2" id="signupbox">
                            <div
                              className="icon-box"
                              data-aos="fade-up"
                              data-aos-delay={100}
                              style={{ padding: "6%", textAlign: "center" }}
                            >
                              <div >
                               <img src={Students}  alt="logo" id="signup_icon" />
                               </div>
                              <h4 className="boxclrtitle1">
                                <div id="student_text">
                                STUDENTS
                                </div></h4>
                              <p
                                className="boxclrtitle"
                                style={{ fontWeight: "normal",fontFamily:"Gotham" }}
                              >
                                <div id="signup_text">
                                I’m a student looking to develop and gain a variety of employability skills.{" "}
                                </div>
                              </p>
                            </div>
                            <h3
                              style={{
                                textAlign: "center",
                                color: "#fff",
                                background: "rgb(0,147,69)",
                                fontSize: "16px",
                                padding: "5%",
                                margin: "-5%",
                              }}
                            >
                              <Link to="/studentsignup_one"
                                style={{ color: "#fff" }}
                                
                              >
                                <span>
                                <div id="signup_btn">SIGN UP
                                </div></span>
                              </Link>
                            </h3>
                          </div>
                          <div className="col-md-2" id="signupbox">
                            <div
                              className="icon-box"
                              data-aos="fade-up"
                              data-aos-delay={100}
                              style={{ padding: "6%", textAlign: "center" }}
                            >
                              <img  src={Universities} alt="logo" id="signup_icon" />
                              <h4 className="boxclrtitle1">
                              <div id="student_text">
                                EDUCATORS
                                </div>
                                </h4>
                                
                              <p
                                className="boxclrtitle"
                                style={{ fontWeight: "normal",fontFamily:"Gotham" }}
                              >
                                <div id="signup_text">
                                I’m an educator looking to prepare my students for tomorrow workforce.{" "}
                                </div>
                              </p>
                            </div>
                            <h3
                              style={{
                                textAlign: "center",
                                color: "#fff",
                                background: "rgb(0,147,69)",
                                fontSize: "16px",
                                padding: "5%",
                                margin: "-5%",
                              }}
                            >
                              <a target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSfV64t5mBU6C-EkTBf72RumAQR8_YUWoFu2oSmFtqpfydOFOA/viewform" style={{ color: "#fff" }}>
                              <span>
                                <div id="signup_btn">SIGN UP
                                </div></span>
                              </a>
                            </h3>
                          </div>
                        
                          <div className="col-md-2" id="signupbox">
                            <div
                              className="icon-box"
                              data-aos="fade-up"
                              data-aos-delay={100}
                              style={{ padding: "6%", textAlign: "center" }}
                            >
                              <img src={Companies} id="signup_icon" />
                              <h4 className="boxclrtitle1">
                              <div id="student_text">
                                COMPANIES
                                </div>
                                </h4>
                              <p
                                className="boxclrtitle"
                                style={{ fontWeight: "normal",fontFamily:"Gotham" }}
                              >
                                <div id="signup_text">
                                I'm an employer looking to unlock talents to complete different opportunities{" "}
                                </div>
                              </p>
                            </div>
                           
                            <h3
                              style={{
                                textAlign: "center",
                                color: "#fff",
                                background: "rgb(0,147,69)",
                                fontSize: "16px",
                                padding: "5%",
                                margin: "-5%",
                              }}
                            >
                              <Link
                              to="/companysignup"
                                style={{ color: "#fff" }}
                              >
                                <span>
                                <div id="signup_btn">SIGN UP
                                </div></span>
                              </Link>
                            </h3>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </main>
    <Footer style={{marginTop:-50}} />
    </div>
  );
};

export default SignUp;
