import React from "react";
import { Link } from "react-router-dom";
let logout = () => {
    window.sessionStorage.removeItem("someData");
    window.sessionStorage.removeItem("nameData");
    window.sessionStorage.removeItem("DataName");
    window.sessionStorage.removeItem("UserType");
    window.location.href=`./home`
  };
export default class UserIcon extends React.Component{
    constructor(){
        super()
        this.state={
                usericon:0
        }
        this.handleUser=this.handleUser.bind(this)
        this.handleUserClose=this.handleUserClose.bind(this)
        this.handleSignup=this.handleSignup.bind(this)
        this.handleSignin=this.handleSignin.bind(this)
    }
    handleUser(){
this.setState({usericon:0})
    }
    handleUserClose(){
        this.setState({usericon:1})
            }
            handleSignup(){
                window.location.href=`./signup`
            }
            handleSignin(){
                window.location.href=`./signin`
            }
    render(){
        return(<>
        {JSON.parse(sessionStorage.getItem("nameData"))==null?<>
        <div class="dropdown">
            {this.state.usericon==1?<>
                <i className="fa fa-user-circle-o usericonhpage" onClick={this.handleUser}></i>
            </>:<>
            <i className="fa fa-user-circle-o usericonhpage" onClick={this.handleUserClose}></i>
            </>}
  
  <div class={this.state.usericon==1?"dropdown-contenttt":"dropdown-contentt"}>
   <div style={{display:"inherit",margin:"auto"}}>
   <a href="#">
        <button style={{width:100,backgroundColor:"black",color:"white",borderRadius:10}} onClick={this.handleSignup}>Sign Up</button>
    </a>
    <a href="#"><button  style={{width:100,backgroundColor:"rgb(0,147,69)",borderRadius:10,color:"white"}} onClick={this.handleSignin}>Sign In</button></a>
   </div>
  </div>
</div>
        </>:<>
        <div class="dropdown">
        {this.state.usericon==1?<>
                <i className="fa fa-user-circle-o" style={{fontSize:20,marginLeft:"-100%",color:"rgb(0,147,69)"}} onClick={this.handleUser}></i>
            </>:<>
            <i className="fa fa-user-circle-o" style={{fontSize:20,marginLeft:"-100%",color:"rgb(0,147,69)"}} onClick={this.handleUserClose}></i>
            </>}
  
                <span style={{marginLeft:"13%",fontFamily:"GothamLight",fontWeight:"bold"}}>Hi, {JSON.parse(sessionStorage.getItem("nameData"))}</span>
                <div class={this.state.usericon==1?"dropdown-contentdashboard":"dropdown-contentt"}
                style={{marginLeft:`${JSON.parse(sessionStorage.getItem("UserType"))=="Company"||JSON.parse(sessionStorage.getItem("UserType"))=="Employee"?"-295%":null}`}}
                >
            <div style={{lineHeight:"6px"}}>
                {JSON.parse(sessionStorage.getItem("UserType"))=="Company"||JSON.parse(sessionStorage.getItem("UserType"))=="Employee"?<>
               

   <Link to="/companyprofile" style={{color:"rgb(0,147,69)",fontFamily:"GothamLight",fontWeight:"bold"}}>My Profile</Link>
           <Link to="/companyseconddashboard" style={{color:"rgb(0,147,69)",fontFamily:"GothamLight",fontWeight:"bold"}}>Company</Link>
           <Link to="/companyprofile" style={{color:"rgb(0,147,69)",fontFamily:"GothamLight",fontWeight:"bold"}}>Opportunities </Link>
           <Link to="/companyprofile" style={{color:"rgb(0,147,69)",fontFamily:"GothamLight",fontWeight:"bold"}}>Messages</Link>
           <Link to="/companyprofile" style={{color:"rgb(0,147,69)",fontFamily:"GothamLight",fontWeight:"bold"}}>Analytics</Link>
           <Link to="/companyprofile" style={{color:"rgb(0,147,69)",fontFamily:"GothamLight",fontWeight:"bold"}}>Community</Link>
           <Link to="/companyprofile" style={{color:"rgb(0,147,69)",fontFamily:"GothamLight",fontWeight:"bold"}}>Settings</Link>
           <Link to="/companyprofile" style={{color:"rgb(0,147,69)",fontFamily:"GothamLight",fontWeight:"bold"}}>Post a Project</Link>
           <Link to="/" onClick={()=>logout()} style={{color:"rgb(0,147,69)",fontFamily:"GothamLight",fontWeight:"bold"}}>Sign Out</Link>
                </>:<>
                <Link to="/studentdashboard" style={{marginLeft:"30%",color:"rgb(0,147,69)",fontFamily:"GothamLight",fontWeight:"bold"}}>My Profile</Link>
           <Link to="/studentdashboard" style={{marginLeft:"30%",color:"rgb(0,147,69)",fontFamily:"GothamLight",fontWeight:"bold"}}>Opportunities</Link>
           <Link to="/studentdashboard" style={{marginLeft:"30%",color:"rgb(0,147,69)",fontFamily:"GothamLight",fontWeight:"bold"}}>Message</Link>
           <Link to="/studentdashboard" style={{marginLeft:"30%",color:"rgb(0,147,69)",fontFamily:"GothamLight",fontWeight:"bold"}}>Analytics</Link>
           <Link to="/studentdashboard" style={{marginLeft:"30%",color:"rgb(0,147,69)",fontFamily:"GothamLight",fontWeight:"bold"}}>Community</Link>
           <Link to="/studentdashboard" style={{marginLeft:"30%",color:"rgb(0,147,69)",fontFamily:"GothamLight",fontWeight:"bold"}}>Settings</Link>
           <Link to="/" onClick={()=>logout()} style={{marginLeft:"30%",color:"rgb(0,147,69)",fontFamily:"GothamLight",fontWeight:"bold"}}>Sign Out</Link>
                </>}
         
            </div>
            </div>
        </div>
        </>}
    
        </>)
    }
}