import React, { useState } from "react";
import Upload from "../../../images/upload.png";
import "./modal.css";

function close(){
    window.location.reload();
  }
export default class InterestsAddCom extends React.Component{
  
    constructor() {
        super();
        let comments = JSON.parse(sessionStorage.getItem("someData"));
       
       console.log(comments)
        this.state = {
          
        
         
          charLimit: 0,
          userid: comments,
          addcpydata :"cpyinterests",
          interests_inputdata:"",
          error:"",
          showModal: false,
        
      
      }
      this.handleSubmit = this.handleSubmit.bind(this);
    }
    
      handleSubmit = (event) => {
        event.preventDefault()
        if(this.state.interests_inputdata.length>=500){
         this.setState({error:"You have exceeded the maximum number of 500 characters in that field"})
        }
        else{
        const data = {
    
          userid: this.state.userid,
          addcpydata: this.state.addcpydata,
          interests_inputdata:this.state.interests_inputdata,
          success:""
    
        }
      
        let BaseUrl = JSON.parse(sessionStorage.getItem("baseurl"));
    
          fetch(BaseUrl + "addcpy_profiledata", {
            method: "POST",
    
            body: JSON.stringify(data), // data can be `string` or {object}!
    
            headers: { "Content-Type": "application/json" },
          })
            .then((res) => res.json())
    
            .catch((error) => console.error("Error:", error))
    
            .then((response) => {
              console.log(response)
              this.setState({
                success:response.message
              })
              window.location.reload();
            });
        }
      }
        handleChange = (e) => {
          this.setState({
            [e.target.name]: e.target.value,
           
          });
          
        };

        openModal = () => {
          this.setState({ showModal: true });
        };
      
        closeModal = () => {
          this.setState({ showModal: false });
        };
render(){
    const {success}=this.state
    const {error}=this.state
  return (
    <>
     {this.state.showModal && (
      <div class="ft-modal" id="ft-interestaddcom-modal">
        <div class="ft-modal-content" >
          <div class="ft-modal-header">
            <div class="header">
              <h5
                class="modal-title"
                id="exampleModalLabel"
                style={{
                  textAlign: "center",
                  fontSize: 25,
                  fontWeight: "bold",
                }}
              >
                Add Interests
              </h5>
              <hr />
            </div>
          </div>
          <div class="ft-modal-body">
           
            <div class="row mt-3">
              <div class="col-sm-1"></div>
              <div class="col-sm-10">
              <form onSubmit = {this.handleSubmit}>
                <div class="form-group">
                  
                  <textarea
                    class="form-control"
                    placeholder="Write something about your Interests... "
                    id="interests_inputdata"
                    rows="10"
                    name ="interests_inputdata" 
                     onChange = {this.handleChange}
                  >
                    
                  </textarea>
                <b style={{float:'right',color:'rgb(0,147,69)'}}>{this.state.charLimit + this.state.interests_inputdata.length} / 500</b>
                  <span style={{color:'rgb(0,147,69)',marginTop:"2%",
                  fontFamily:"Gotham",fontWeight:'bold',fontSize:12}}>{success}</span>
                  <span style={{color:'red',marginTop:"2%",
                  fontFamily:"Gotham",fontWeight:'bold',fontSize:12}}>{error}</span>
                </div>
                <div class="modal-footer mt-4">
            
              <div class="col-sm-3">
              <a class="ft-modal-close"  onClick={()=>close()}
                  style={{ backgroundColor: "gray", width: 100 }}
                  className="btn btn-success"
                >
                  Cancel
                </a>
              </div>
              <div class="col-sm-3">
                <button
                  style={{ backgroundColor: "rgb(0, 147, 69)", width: 100 }}
                  className="btn btn-success"
                  type="submit"
                
                >
                  Save
                </button>
              </div>
              <div class="col-sm-2"></div>
            </div>
                </form>
              </div>
            </div>

            
          </div>
          <div class="ft-modal-footer">
            <a class="ft-modal-close"data-dismiss="modal"
                     onClick={this.closeModal}>
              [&#10006;]{" "}
            </a>
          </div>
        </div>
      </div>
    )}
      
      <a
         
          className="btn-success btn-sm mt-2"
          href="#ft-interestaddcom-modal"
          style={{
            margin:"auto",
            width: "30%",
            backgroundColor: "rgb(0, 147, 69)",
            fontFamily: "GothamLight",
            textAlign: "center",
          }}
          onClick={this.openModal}
        >
          Add
        </a>
    </>
  );
}
}
