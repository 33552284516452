import React, { useState } from "react";

import "./modal.css";

function close(){
  window.location.reload();
}

export default class  EditPublicationModal extends React.Component{
   
  constructor() {
    super();
    let comments = JSON.parse(sessionStorage.getItem("someData"));
    let Pub = JSON.parse(sessionStorage.getItem("publicationID"));
   console.log(comments)
    this.state = {
      
    
        publisherid:Pub,
        userid: comments,
       
       
        dropdownlist_input : "nationality",
       
        selectOptions : [],
        selectSchool:[],
        selectQualification:[],
        title:"",
        publisher:"",
        year:"",
        area:"",
        linktopublish:"",
        synopsis:"",
        
    
  
  }
  this.handleSubmit = this.handleSubmit.bind(this);
}

  handleSubmit = (event) => {
    event.preventDefault()
   
    const data = {
        userid: this.state.userid,
      
        title:this.state.title,
        publisher:this.state.publisher,
        year:this.state.year,
       
        area:this.state.area,
        linktopublish:this.state.linktopublish,
        year:this.state.year,
        synopsis:this.state.synopsis,
        publisherid:this.state.publisherid
    }
  
   

    fetch( "https://www.gigeconome.com:5200/api/editstupublisher", {
      method: "POST",

      body: JSON.stringify(data), // data can be `string` or {object}!

      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())

      .catch((error) => console.error("Error:", error))

      .then((response) => {
         
        this.setState({geomessage:response.message})
        window.location.reload();
       
        
      });
  
}




componentDidMount(){
  this.getOptions()
}
async getOptions(){
const data_one={dropdownlist_input:this.state.dropdownlist_input,

}

let BaseUrl = JSON.parse(sessionStorage.getItem('baseurl'));

fetch( 'https://www.gigeconome.com:5200/api/getdropdownlist', { method: 'POST', 

 body: JSON.stringify(data_one), // data can be `string` or {object}!

 headers:{ 'Content-Type': 'application/json' } })

 .then(res => res.json())

 .catch(error => console.error('Error:', error))

 .then((json)=>{
  const data = json.result
  
  this.setState({selectOptions: data})
 })
 const data_two={dropdownlist_input:"areaofpublish"

 }

 //let BaseUrl = JSON.parse(sessionStorage.getItem('baseurl'));

 fetch( 'https://www.gigeconome.com:5200/api/getdropdownlist', { method: 'POST', 

  body: JSON.stringify(data_two), // data can be `string` or {object}!

  headers:{ 'Content-Type': 'application/json' } })

  .then(res => res.json())

  .catch(error => console.error('Error:', error))

  .then((json)=>{
   const data = json.result
   
   this.setState({selectSchool: data})
  })




  const qual_data={dropdownlist_input:"qualification"

}

//let BaseUrl = JSON.parse(sessionStorage.getItem('baseurl'));

fetch( 'https://www.gigeconome.com:5200/api/getdropdownlist', { method: 'POST', 

body: JSON.stringify(qual_data), // data can be `string` or {object}!

headers:{ 'Content-Type': 'application/json' } })

.then(res => res.json())

.catch(error => console.error('Error:', error))

.then((json)=>{
const data = json.result

this.setState({selectQualification: data})
})



const pub_data={userid:this.state.userid,

  publisherid:this.state.publisherid

}


fetch( 'https://www.gigeconome.com:5200/api/getstusinglepublisher', { method: 'POST', 

body: JSON.stringify(pub_data), // data can be `string` or {object}!

headers:{ 'Content-Type': 'application/json' } })

.then(res => res.json())

.catch(error => console.error('Error:', error))

.then((response)=>{

this.setState({title:response.result.title,
                publisher:response.result.publisher,
                area:response.result.area,
                year:response.result.year,
                linktopublish:response.result.linktopublish,
                synopsis:response.result.synopsis



})


})




}
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
     
    });
    
  };

   
    
render(){
  const {selectSchool}=this.state;
  const{geomessage}=this.state
  return (
    <>
       <div class="ft-modal" id="ft-editpublication-modal">
        <div class="ft-modal-content" >
          <div class="ft-modal-header">
            <div class="header">
              <h5
                class="modal-title"
                id="exampleModalLabel"
                style={{
                  textAlign: "center",
                  fontSize: 25,
                  fontWeight: "bold",
                }}
              >
                Edit Publications / Research Paper
              </h5>
              <hr />
            </div>
          </div>
          <div class="ft-modal-body">
            <div class="row mt-4">
              <div class="col-sm-2"></div>
              <div class="col-sm-8">
                <form onSubmit = {this.handleSubmit} style={{fontSize:15}}>
                <div class="row">
                      
                      <div class="col-sm-12">
                        <label style={{display:"flex"}}>Title</label>
                        <p>
                          <input
                            type="text"
                            placeholder="Enter the Title"
                            className="form-control mt-1"
                            name ="title" 
                            onChange = {this.handleChange}
                            value={this.state.title}
                          />
                        </p>
                      </div>

                      <div class="col-sm-1"></div>
                    </div>

                    <div class="row">
                     
                      <div class="col-sm-5">
                      <label style={{display:"flex"}}>Publisher</label>
                        <p>
                          <input
                            type="text"
                            placeholder="Enter the Publisher"
                            className="form-control mt-1"
                            name ="publisher" 
                            onChange = {this.handleChange}
                            value={this.state.publisher}
                          />
                        </p>
                      </div>

                      <div class="col-sm-2"></div>
                      <div class="col-sm-5">
                      <label style={{display:"flex"}}>Year of Publish</label>
                        <p>
                        <input type="text" class="form-control mt-1"
                       name ="year" 
                       onChange = {this.handleChange}
                      placeholder={this.state.year}
                      onFocus={(e) =>
                        (e.currentTarget.type = "month")
                      }
                      onBlur={(e) =>
                        (e.currentTarget.type = "text")
                      }
                     
                      />
                        </p>
                      </div>
                    </div>
                    <div class="row">
                      
                      <div class="col-sm-5">
                      <label style={{display:"flex"}}>Area</label>
                        <p>
                        {selectSchool.length == 0
                    ? "Loading Area of Publish..."
                    : 

                    <select class="form-select"  name ="area" 
                    onChange = {this.handleChange}>
                      <option>{this.state.area}</option>
                    {selectSchool.map(fbb =>
                    
                      <option key={fbb.key} value={fbb.id}>{fbb.name}</option>
                      
                    )};
                  </select>
                    } 
                        </p>
                      </div>

                      <div class="col-sm-2"></div>
                      <div class="col-sm-5">
                      <label style={{display:"flex"}}>Link To Publish</label>
                        <p>
                          <input
                            type="text"
                            placeholder="Link to Publications "
                            className="form-control mt-1"
                            name ="linktopublish" 
                            value={this.state.linktopublish}
                    onChange = {this.handleChange}
                          />
                        </p>
                      </div>
</div>
                      <div class="row mt-2">
                       
                        <div class="col-sm-12">
                          <form>
                            <div class="form-group">
                            <label style={{display:"flex"}}>Synopsis</label>
                              <textarea
                                class="form-control mt-1"
                                rows="5"
                                name ="synopsis" 
                    onChange = {this.handleChange}
                    value={this.state.synopsis}
                              ></textarea>
                            </div>
                          </form>
                        </div>
                        <div class="col-sm-1"></div>
                      </div>
                      
                   
                    <div class="row mt-4">
                      <div class="col-sm-8"></div>
                      <div class="col-sm-2">
                      <button
                          style={{ backgroundColor: "rgb(0, 147, 69)",width:"150%" }}
                          className="btn-success"
                          id="education_btn"
                          type="submit"
                        >
                          Save
                        </button>
                      </div>
                      <div class="col-sm-1">
                        
                      </div>
                      <div class="col-sm-1"></div>
                    </div>
                <span style={{color:"rgb(0,147,69)",fontSize:15,marginTop:"5%",fontWeight:"bold"}}>{geomessage}</span>
                </form>
              </div>
              <div class="col-sm-2"></div>
            </div>{" "}
            <div class="row mt-4">
              <div class="col-sm-2"></div>
              <div class="col-sm-8">
               
              </div>
              <div class="col-sm-2"></div>
            </div>
            <div class="mt-4">
              <div class="col-sm-4"></div>
              <div class="col-sm-7">
               
              </div>
              <div class="col-sm-1"></div>
            </div>
          </div>
          <div class="ft-modal-footer">
            <a class="ft-modal-close" href="#"  onClick={()=>close()}>
              [&#10006;]{" "}
            </a>
          </div>
        </div>
      </div>
            
          
      
      <a
          
          href="#ft-editpublication-modal"
         
        >
         <i className="fa fa-pencil" style={{paddingLeft:"5%",fontSize:20}} ></i>
        </a>
    </>
  );
}
}
