import React, { useState } from "react";
import LinkedIN from "../../images/company1.png";
import Twitter from "../../images/twitter.png";
import Facebook1 from "../../images/fb.png";
import Instagram from "../../images/insta.png";
import CompanyLogo from "../../images/plogo.PNG";
import Header from "../Header/Header";
import BackgroundImage from "../../images/companyDashboard.jpg";
import { Link } from "react-router-dom";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';
class ViewGigProfile extends React.Component {

  constructor(props) {
    
    let data=window.location.pathname.slice(16);
    //console.log(data)
   
    super(props);
    this.state = {
      isOpen: false,
      userid:data,
      companyname:"",
      websitename:"",
      industryname:"",
      locationname:"",
      orgsizename:"",
      cpyfoundedyear:"",
      jobdepartmentname:"",
      opportunitiestype:"",
      cpysummary:"",
      fackbooklink:"",
      linkedinlink:"",
      twitterlink:"",
      pinterestlink:"",
      cpybanner:"",
      cpylogo:"",
      employeeData:[],
      showEmpData:0
     
    }
    
    
  }
 
componentDidMount() {
   

  {/* Employee Data */}
  const empdata = { addcpydata: "getcpyemployeeinfo",
userid:this.state.userid
};
  fetch("https://www.gigeconome.com:5200/api/addcpy_profiledata", {
    method: "POST",

    body: JSON.stringify(empdata), // data can be `string` or {object}!

    headers: { "Content-Type": "application/json" },
  })
    .then((res) => res.json())

    .catch((error) => console.error("Error:", error))

    .then((response) => {
      if(response.message=="Company Employee Details Retrived."){
        this.setState({
          employeeData:response.result,showEmpData:1
         })
      }
      else{
          this.setState({showEmpData:0})
      }
    
   //console.log(response)
       
       
       
     
    });


  const data_nine = { userid: this.state.userid };
  fetch("https://www.gigeconome.com:5200/api/getcpyviewmyinfo", {
    method: "POST",

    body: JSON.stringify(data_nine), // data can be `string` or {object}!

    headers: { "Content-Type": "application/json" },
  })
    .then((res) => res.json())

    .catch((error) => console.error("Error:", error))

    .then((json) => {
    this.setState({
      companyname:json.user.companyname,
      websitename:json.user.websitename,
      industryname:json.user.industryname,
      locationname:json.user.locationname,
      cpyfoundedyear:json.user.cpyfoundedyear,
      orgsizename:json.user.orgsizename,
      jobdepartmentname:json.user.industryname,
      opportunitiestype:json.user.opportunitiestype,
      cpysummary:json.user.cpysummary,
      pinterestlink:json.user.pinterestlink,
      fackbooklink:json.user.fackbooklink,
      linkedinlink:json.user.linkedinlink,
      twitterlink:json.user.twitterlink,
      cpybanner:json.user.cpybanner,
      cpylogo:json.user.cpylogo
    })
   
       
       
       
     
    });

    }

  
  render() {
    
    return (
      <div>
  
       
  <Header />

      
        <main
        className="cpymainboxdashboard"
        style={{ backgroundColor: "#333333",
        backgroundImage:this.state.cpybanner==""
        ?`url('${BackgroundImage}')`:`url('${this.state.cpybanner}')`,
        
               backgroundRepeat:"no-repeat",
                
                backgroundSize:"contain",
                marginTop:"6%",
               
        borderBottom:"6px solid rgb(0,147,69)" }}
         
        >
          <br />
          <br />

         
        
        
         
               
          <div className="container " style={{marginTop:"15%"}}>
            <div class="row cpypublishprofilesubheading">
            <div class="col-sm-4 mscreenava" style={{marginLeft:"1%",background:"white"}}>
                    
                      
                      <img
                          class="card-img-top"
                          src={this.state.cpylogo}
                          id="dashboard_img" />
                       
                        <div class="card-body cpypublishprofiletxt">
                          <div class="card-title" id="dashboard_text">
                            <div
                              class="text-success"
                              style={{ marginLeft: -19 }}
                            >
                             <h4 id="dashboard_text" style={{fontFamily:"GothamLight",fontWeight:"bold",fontSize:15}}>{this.state.companyname}</h4>
                            </div>
                          
                            <div style={{ marginLeft: -20 }} className="pt-2">
                              <i
                                class="fa fa fa-globe"
                                style={{ fontSize: 10,color:"rgb(0,147,69)" }}
                              ></i>{" "}
                              &nbsp;<b style={{fontFamily:"GothamLight"}}>{this.state.websitename} </b> <br />
                            </div>
                            
                          </div>
                        </div>
                        <div  style={{textAlign:"left",marginTop:"4%"}}>
                        <i class="fa fa-cog" style={{color:"rgb(0,147,69)"}}></i> &nbsp; <span style={{fontFamily:"Gothamlight",fontWeight:"bold",fontSize:12}}>{this.state.jobdepartmentname}</span>
                        <div><i class="fa fa-map-marker" style={{color:"rgb(0,147,69)",fontSize:14,marginLeft:"0.5%"}}></i> &nbsp; <span style={{fontFamily:"Gothamlight",fontWeight:"bold",fontSize:12}}> {this.state.locationname}</span>
                        </div>
                        <div>
                        {this.state.cpyfoundedyear==null?<>
                          <i class="fa fa-calendar" style={{color:"rgb(0,147,69)",fontSize:12}}></i> &nbsp; <span style={{fontFamily:"Gothamlight",fontWeight:"bold",fontSize:12}}>Founder Year Not Avaliable </span>
                        </>:<>
                        <i class="fa fa-calendar" style={{color:"rgb(0,147,69)",fontSize:12}}></i> &nbsp; <span style={{fontFamily:"Gothamlight",fontWeight:"bold",fontSize:12}}> Founded {this.state.cpyfoundedyear}</span>
                        </>}
                        </div>
                        <div>
                        <i class="fa fa-user" style={{color:"rgb(0,147,69)"}}></i> &nbsp; <span style={{fontFamily:"Gothamlight",fontWeight:"bold",fontSize:12}}> {this.state.orgsizename}</span>
                        </div>
                        
                        </div>
                        <div class="container" style={{marginLeft:"-3%",marginTop:"4%",marginBottom:"5%"}}>
                <div class="row">
                {this.state.opportunitiestype==null?<>
                  <span style={{fontFamily:"GothamLight",fontWeight:"bold"}}>Opportunities Not Avaliable.</span>
                </>:<>
                
                {this.state.opportunitiestype.split(",")
  .map((item, i) =>  <div class="col-sm-3 divopptype">  {item}  </div>)}
                </>}
                  
                
                  
                  
                </div>
</div>
                      </div>
                     
              <div class="col-sm-3 bigcompanysearch">
              <div
                  class="card"
                  style={{
                    backgroundColor: "white",
                    height: "100%",
                    border: "none",
                    borderRadius: 1,
                  }}
                 
                >
                  {this.state.cpylogo==""?<>
                  <img src={CompanyLogo} style={{width:"75%",margin:"14%"}}/>
                  </>:<>
                  <img src={this.state.cpylogo} style={{width:"75%",margin:"3% 13% 3%"}}/>
                  </>}
                
             
                

                   
                
                </div>






              </div>
              <div class="col-sm-9 bigcompanysearch">
                <div
                  class="card"
                  style={{
                    backgroundColor: "white",
                    height: "100%",
                    border: "none",
                    borderRadius: 1,
                  }}
                  
                >
                  <div className="row">
                    
                    <div className="col-sm-5">
                      <h3 style={{color:"rgb(0,147,69)",fontFamily:"Gotham"
                    ,paddingLeft:"7%",fontSize:22,marginTop:"1%"
                    }}>{this.state.companyname}</h3>
                    
                    </div>
                    <div className="col-sm-1">

                    </div>
                    <div className="col-sm-6">
                      <h5 style= {{color:"rgb(0,147,69)",fontFamily:"Gotham",
                      paddingTop:"0%",textAlign:"right",marginRight:"20%"
                  
                    }}> <i class="fa fa-globe"></i> &nbsp; 
                    
                    <span style={{marginLeft:"-1%",fontSize:16}}>{this.state.websitename}</span></h5>
                    
                    </div>
                  </div>
                 
                  <div className="row">
                    <div className="col-sm-5">
                    <h5 style= {{fontFamily:"Gotham",
                      paddingTop:"5%",paddingLeft:"4%",
                      fontSize:16
                  
                    }}> <i class="fa fa-cog" style={{color:"rgb(0,147,69)"}}></i> &nbsp; {this.state.jobdepartmentname}</h5>
                    
                    </div>
                    <div className="col-sm-3">

                    </div>
                    <div className="col-sm-4">
                      <h5 style= {{fontFamily:"Gotham",
                      paddingTop:"5%", fontSize:16,
                      marginRight:"8%",textAlign:"left"
                  
                    }}> <i class="fa fa-map-marker" style={{color:"rgb(0,147,69)"}}></i> &nbsp; {this.state.locationname}</h5>
                    
                    </div>
                  </div>

                  <div className="row">
                  {this.state.cpyfoundedyear==null?<>
                  <div className="col-sm-5">
                    <h5 style= {{fontFamily:"Gotham",
                      paddingTop:"5%",paddingLeft:"4%", fontSize:15
                  
                    }}> <i class="fa fa-calendar" style={{color:"rgb(0,147,69)"}}></i> &nbsp; Founder Year Not Avaliable </h5>
                    
                    </div>
                  </>:<>

                  <div className="col-sm-5">
                    <h5 style= {{fontFamily:"Gotham",
                      paddingTop:"5%",paddingLeft:"4%", fontSize:18
                  
                    }}> <i class="fa fa-calendar" style={{color:"rgb(0,147,69)"}}></i> &nbsp; Founded {this.state.cpyfoundedyear}</h5>
                    
                    </div>
                  </>}
               
                    <div className="col-sm-3">

                    </div>
                    <div className="col-sm-4">
                      <h5 style= {{fontFamily:"Gotham",
                      paddingTop:"5%", fontSize:16,textAlign:"left",marginRight:"8%"
                  
                    }}> <i class="fa fa-user" style={{color:"rgb(0,147,69)"}}></i> &nbsp; {this.state.orgsizename}</h5>
                    
                    </div>
                  </div>

                  <div class="container" style={{marginLeft:"10%",marginTop:"1%"}}>
                <div class="row">
                {this.state.opportunitiestype==null?<>
                  <span style={{fontFamily:"GothamLight",fontWeight:"bold"}}>Opportunities Not Avaliable.</span>
                </>:<>
                
                {this.state.opportunitiestype.split(",")
  .map((item, i) =>  <div class="col-sm-3 divopptype">  {item}  </div>)}
                </>}
                  
                
                  
                  
                </div>
</div>
               

               {/*       <div className="row">
                {this.state.opportunitiestype==null?<>
                <div className="col-sm-12" style={{marginTop:"5%",marginLeft:"4%"}}>
                  <span class="col-md-4">
                  <span style={{fontFamily:"GothamLight",fontWeight:"bold"}}>Opportunities Not Avaliable.</span>
                  </span>
                </div>
                
                </>:<>
                <div className="col-sm-12" style={{marginTop:"5%",marginLeft:"4%"}}>
                    {this.state.opportunitiestype.split(",")
  .map((item, i) => <span class="col-md-4">  {item}  </span>)}
                    </div>
                </>}
                  
                   
             
                  </div> */}
            
              
                </div>               
</div>
</div>
<div className="row">
  <div className="col-sm-12">
  <div className="mscreenava">
             <Accordion allowZeroExpanded preExpanded={[0]}>
            <AccordionItem uuid={0}>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    
                    <div  id="cert_text">
                <a style={{paddingLeft:"1%",}} id="geogra_text">Company Summary</a>
               
             </div>
              
              
                 
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
               
                <span style={{fontFamily:"GothamLight",fontWeight:"bold",fontSize:12}}>{this.state.cpysummary}</span>
                <div className="row" style={{marginTop:"7%"}}>
                 
                  <div className="col-sm-2" style={{width:"25%"}}>
                    <a href={this.state.linkedinlink} target="_blank"><img src={LinkedIN} style={{width:"60%"}} /></a>
                    
                    </div>
                    <div className="col-sm-2" style={{width:"25%"}}>
                    <a href={this.state.pinterestlink} target="_blank"><img src={Instagram} style={{width:"60%"}}/></a>
                   
                    </div>
                    <div className="col-sm-2" style={{width:"25%"}}>
                    <a href={this.state.fackbooklink} target="_blank"><img src={Facebook1} style={{width:"60%"}}/></a>
                    
                    </div>
                    <div className="col-sm-2" style={{width:"25%"}}>
                    <a href={this.state.twitterlink} target="_blank"><img src={Twitter} style={{width:"60%"}}/></a>
                    
                    </div>
                    
                   </div>
                   
                  
                </AccordionItemPanel>
            </AccordionItem>
            </Accordion>
             </div>

             <div className="mscreenava">
             <Accordion allowZeroExpanded preExpanded={[0]}>
            <AccordionItem uuid={0}>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    
                    <div  id="cert_text">
                <a style={{paddingLeft:"1%",}} id="geogra_text">Reviews</a>
               
             </div>
              
              
                 
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
               <div style={{textAlign:"center"}}>
               <span style={{fontFamily:"GothamLight",fontWeight:"bold",fontSize:12}}>
                  There no feedback yet.
                </span>
               </div>
               
               
                   
                  
                </AccordionItemPanel>
            </AccordionItem>
            </Accordion>
             </div>
             <div className="mscreenava">
             <Accordion allowZeroExpanded preExpanded={[0]}>
            <AccordionItem uuid={0}>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    
                    <div  id="cert_text">
                <a style={{paddingLeft:"1%",}} id="geogra_text">Employees</a>
               
             </div>
              
              
                 
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                <div class="card-body">
                    <div className="row">
                      {this.state.showEmpData==1?<>
                        {this.state.employeeData.map((emp)=>(
                          <div className="col-sm-4">
                            <div style={{paddingTop:"3%"}}>
                            <Link 
                         target="_blank"
                         to={{ 
                          
                           pathname: `/companypublishprofile/${emp.userid}`, 
                            
                         }}> 
                                                       <img src={emp.userphoto} style={{borderRadius:"50%",height:100,width:100}} />
                         
                         </Link>

                        
                            </div>
                            
                             <div style={{paddingTop:"2%"}}> 
                             
                             <Link 
                         target="_blank"
                         to={{ 
                          
                           pathname: `/companypublishprofile/${emp.userid}`, 
                            
                         }}> 
                               
                             <span style={{fontFamily:"GothamLight",fontWeight:"bold",color:"rgb(0,147,69)",fontSize:16}}>{emp.name} {emp.lastname}</span></Link></div>
                          
                           <div style={{paddingTop:"0%"}}>
                             
                           <Link 
                         target="_blank"
                         to={{ 
                          
                           pathname: `/companypublishprofile/${emp.userid}`, 
                            
                         }}> 
                                <span style={{fontFamily:"GothamLight",fontWeight:"bold",fontSize:15,color:"black"}}>{emp.email}</span></Link></div>
                         
                           <div style={{paddingTop:"0%"}}>
                           <Link 
                         target="_blank"
                         to={{ 
                          
                           pathname: `/companypublishprofile/${emp.userid}`, 
                            
                         }}> 
                               
                             <span style={{fontFamily:"GothamLight",fontWeight:"bold",fontSize:15,color:"black"}}>{emp.Jobtitle}</span></Link></div>
                         
                          </div>
                      ))}
                      </>:<>
                      <span style={{fontSize:15,fontFamily:"GothamLight",fontWeight:"bold",marginLeft:"2%"}}>No Employees Avaliable</span>
                      
                      </>}
                     
                     
                    </div>
                   
                  </div>
               
               
                   
                  
                </AccordionItemPanel>
            </AccordionItem>
            </Accordion>
             </div>
             <div className="mscreenava" style={{marginBottom:"5%"}}>
             <Accordion allowZeroExpanded preExpanded={[0]}>
            <AccordionItem uuid={0}>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    
                    <div  id="cert_text">
                <a style={{paddingLeft:"1%",}} id="geogra_text">Projects & Opportunities</a>
               
             </div>
              
              
                 
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
               <div style={{textAlign:"center"}}>
               <span style={{fontFamily:"GothamLight",fontWeight:"bold",fontSize:12}}>
                Not Avaliable.
                </span>
               </div>
               
               
                   
                  
                </AccordionItemPanel>
            </AccordionItem>
            </Accordion>
             </div>
  <div
                  class="card bigcompanysearch"
                  style={{
                    backgroundColor: "white",

                    border: "none",
                    borderRadius: 1,
                   marginTop:"2%",
                   height:"auto"
                  }}
                 
                >
                  <div  id="cert_text" >
                   <span style={{marginLeft:"1%"}}>Company Summary</span> 
                    <hr style={{marginRight:"-1.2%",marginLeft:"-1%"}}/>
                  </div>
                 
                  <div class="card-body">
                   <span style={{fontFamily:"GothamLight",fontWeight:"bold",fontSize:18}}>{this.state.cpysummary}</span>
                   <div className="row" style={{marginTop:"4%"}}>
                  <div className="col-sm-1">

                  </div>
                  <div className="col-sm-2">

                  </div>
                  <div className="col-sm-2">
                    <a href={this.state.linkedinlink} target="_blank"><img src={LinkedIN} /></a>
                    
                    </div>
                    <div className="col-sm-2">
                    <a href={this.state.pinterestlink} target="_blank"><img src={Instagram} /></a>
                   
                    </div>
                    <div className="col-sm-2">
                    <a href={this.state.fackbooklink} target="_blank"><img src={Facebook1} /></a>
                    
                    </div>
                    <div className="col-sm-2">
                    <a href={this.state.twitterlink} target="_blank"><img src={Twitter} /></a>
                    
                    </div>
                    <div className="col-sm-2">

                      </div>
                   </div>
                  </div>
                </div>

  </div>

</div>
<div className="row bigcompanysearch">
  <div className="col-sm-12">
  <div
                  class="card"
                  style={{
                    backgroundColor: "white",

                    border: "none",
                    borderRadius: 1,
                   marginTop:"2%",
                  }}
                  id="recomm_card"
                >
                  <div  id="cert_text" >
                    Reviews
                    <hr style={{marginRight:"-4.2%",marginLeft:"-4%"}}/>
                   
                  </div>
                 
                  <div class="card-body" style={{textAlign:"center"}}>
                   <span style={{padding:"2%",fontFamily:"GothamLight",fontWeight:"bold",fontSize:18}}>
                     There is no feedback yet.
                   </span>
          
                  </div>
                </div>

  </div>

</div>
<div className="row bigcompanysearch">
  <div className="col-sm-12">
  <div
                  class="card"
                  style={{
                    backgroundColor: "white",
                      height:"auto",
                    border: "none",
                    borderRadius: 1,
                   marginTop:"2%",
                  }}
                  
                >
                  <div  id="cert_text" >
                    Employees
                    <hr style={{marginRight:"-4.2%",marginLeft:"-4%"}}/>
                  </div>
                 
                  <div class="card-body" style={{textAlign:"center"}}>
                    <div className="row">
                      {this.state.showEmpData==1?<>
                        {this.state.employeeData.map((emp)=>(
                          <div className="col-sm-4" style={{width:"22%"}}>
                            <div style={{paddingTop:"3%"}}>
                            <Link 
                         target="_blank"
                         to={{ 
                          
                           pathname: `/companypublishprofile/${emp.userid}`, 
                            
                         }}> 
                                                       <img src={emp.userphoto} style={{borderRadius:"50%",height:100,width:100}} />
                         
                         </Link>

                        
                            </div>
                            
                             <div style={{paddingTop:"2%"}}> 
                             
                             <Link 
                         target="_blank"
                         to={{ 
                          
                           pathname: `/companypublishprofile/${emp.userid}`, 
                            
                         }}> 
                               
                             <span style={{fontFamily:"GothamLight",fontWeight:"bold",color:"rgb(0,147,69)",fontSize:16}}>{emp.name} {emp.lastname}</span></Link></div>
                          
                           <div style={{paddingTop:"0%"}}>
                             
                           <Link 
                         target="_blank"
                         to={{ 
                          
                           pathname: `/companypublishprofile/${emp.userid}`, 
                            
                         }}> 
                                <span style={{fontFamily:"GothamLight",fontWeight:"bold",fontSize:15,color:"black"}}>{emp.email}</span></Link></div>
                         
                           <div style={{paddingTop:"0%"}}>
                           <Link 
                         target="_blank"
                         to={{ 
                          
                           pathname: `/companypublishprofile/${emp.userid}`, 
                            
                         }}> 
                               
                             <span style={{fontFamily:"GothamLight",fontWeight:"bold",fontSize:15,color:"black"}}>{emp.Jobtitle}</span></Link></div>
                         
                          </div>
                      ))}
                      </>:<>
                      <span style={{fontSize:15,fontFamily:"GothamLight",fontWeight:"bold",marginLeft:"2%"}}>No Employees Avaliable</span>
                      
                      </>}
                     
                     
                    </div>
                   
                  </div>
                </div>

  </div>

</div> 

<div className="row bigcompanysearch">
  <div className="col-sm-12" style={{marginBottom:"2%"}}>
  <div
                  class="card"
                  style={{
                    backgroundColor: "white",

                    border: "none",
                    borderRadius: 1,
                   marginTop:"2%",
                  }}
                  id="recomm_card"
                >
                  <div  id="cert_text" >
                  Projects & Opportunities
                    <hr style={{marginRight:"-4.2%",marginLeft:"-4%"}}/>
                  </div>
                 
                  <div class="card-body" style={{textAlign:"center"}}>
                   <span style={{padding:"2%",fontFamily:"GothamLight",fontWeight:"bold",fontSize:18}}>
                     Not Avaliable.
                   </span>
          
                  </div>
                </div>

  </div>

</div>
</div>
        </main>

        {/* ======= About Section ======= */}
        {/* End #main */}
      </div>
    );
  }
}

export default ViewGigProfile;
