import React from "react";
import "./company.css";
import { Link } from "react-router-dom";
import Logo from '../../images/logo.png';
import How2work from '../../images/how2work.png';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import LaptopIcon from '@mui/icons-material/Laptop';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import About_usfour from '../../images/AboutUs/aboutus-04.png';
import Sbanner from '../../images/studenthowitworkbanner.jpg';
import Aboutus from "../../images/aboutus.png";
import Companies11 from "../../images/companies11.png";
import Accordion from "../Home/Accordion";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth' // for smoothly scrolling
  });
  sessionStorage.removeItem("SearchText");
  sessionStorage.setItem("SearchText","");
};
class Company extends React.Component {
  render() {
    return (
      <div>
         {/* ======= Header ======= */}
     <Header />
      {/* End Header */}
        {/* ======= Hero Section ======= */}
        <section
          className="d-flex align-items-center"
          style={{ clear: "both" }}
        >
          <style
            type="text/css"
            dangerouslySetInnerHTML={{
              __html:
                "\n  .centered {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n}\n",
            }}
          />
       
        
        </section>
        {/* End Hero */}
        <main id="main" className="cpyhowitsworkbnr" style={{ backgroundImage:`url('${Sbanner}')`,
      backgroundRepeat:"no-repeat",backgroundSize:"contain",
      marginLeft:"-1%"}}>
          <section
            id="clients"
            className="clients"
            style={{ marginTop: "-1%" }}
          >
            <style
              type="text/css"
              dangerouslySetInnerHTML={{
                __html:
                  "\n  .cnterbtn{\n    display: flex;\n  justify-content: center;\n  align-items: center;\n  padding-top: 4%;\n  }\n\n  .btnalgnhtw{\n     display: flex;\n  justify-content: center;\n  align-items: center;\n  padding-top: 4%;\n  }\n\n\n",
              }}
            />
            <div className="container" data-aos="zoom-in">
            <div class="row text-center">
              <h1 className="cpyhowitsworktxt">Companies : Work With Our Student Talents</h1>
            </div>
              <h2
               className="cpyhowitsworksubtxt"
              >
                {" "}
                How it Works{" "}
              </h2>
              <br />
              <div className="companyowitsworkimgmobile">
                <img src={Companies11} />
              </div>
           <div className="companyowitsworkimg">
           <img
                style={{ marginLeft: "7%", width: "86%", marginTop: "3%" }}
                src={How2work}
                alt=""
                className="img-fluid"
              />
              <div className="row">
                <div className="col-md-3">
                  <h3 style={{ textAlign: "center", fontFamily: "normal",fontWeight:"bold",
                fontSize:22,color:"rgb(0,147,69)",marginLeft:"13%"
                }}>
                  Post an Opportunity
                  </h3>{" "}
                  <p
                    style={{
                      marginLeft:"25%",
                      fontSize: "15px",
                     
                    }}
                  >
                   Post a project or different job opportunities using our forms for our talented students.
                  </p>
                </div>
                <div className="col-md-3">
                  <h3 style={{  fontFamily: "normal",fontWeight:"bold",fontSize:22,color:"rgb(0,147,69)",marginLeft:"22%"  }}>
                  Review Students
                  </h3>{" "}
                  <p
                    style={{
                  
                      fontSize: "15px",
                      marginLeft:"22%"
                    }}
                  >
                     Review the proposals and profiles that our smart and technology have contacted.
                  </p>
                </div>
                <div className="col-md-3">
                  <h3 style={{ textAlign: "center", 
                  fontFamily: "normal",fontWeight:"bold",fontSize:22,color:"rgb(0,147,69)",marginLeft:"8%" }}>
                  Shortlist Students
                  </h3>{" "}
                  <p
                    style={{
                      marginLeft:"26%",
                      fontSize: "15px",
                      
                    }}
                  >
                    Schedule meetings and engage our talents or assign projects directly to perfect matches.
                  </p>
                </div>
                <div className="col-md-3">
                  <h3 style={{ textAlign: "center", 
                  fontFamily: "normal",fontWeight:"bold",fontSize:22,color:"rgb(0,147,69)",marginLeft:"2%" }}>
                  Evaluate Students
                  </h3>{" "}
                  <p
                    style={{
                      marginLeft:"23%",
                      fontSize: "15px",
                      
                    }}
                  >
                   Upon completion of project or opportunity, evaluate and provide feedback for hired talent.
                  </p>
                </div>
              </div>
           </div>
              <div className="row">
                <div className="col-md-3"></div>
                <div className="col-md-4 startsupbtn">
                 
                    <Link
                    to="/postaproject"
                    
                     onClick={()=>scrollToTop()}
                     className="btn btn-success"
                     style={{
                       background: "rgb(0,147,69)",
                       border: "none",
                       borderRadius: "10px",
                      
                       letterSpacing: "1px",
                       fontFamily: "Gotham",
                      
                       color:"white"
                     }}
                    
                    > GET MATCHED TO TALENT</Link>
                   
               
                </div>
                <div className="col-md-5 startsupbtn">
                 
                    <Link to="/studentsearch"
                    
                     onClick={()=>scrollToTop()}
                     className="btn btn-success"
                     style={{
                       background: "rgb(0,147,69)",
                       border: "none",
                       borderRadius: "10px",
                      
                       letterSpacing: "1px",
                       fontFamily: "Gotham",
                      
                       color:"white"
                     }}
                    
                    >
                       EXPLORE OUR TALENT POOL
                    </Link>
                   
                 
                </div>
              </div>
            </div>
          </section>
          
           {/* ======= About Section ======= */}
      <section id="about" className="about section-bg">

        <div className="container" data-aos="fade-up">
          <div className="row">
          <hr style={{ border: "1px solid #009345", opacity: "inherit" }} />
            <div className="col-lg-4">
              <span className="about-head">GigeconoMe - </span>
            </div>
            <div className="col-lg-8">
              <span className="about-content">
                Clearing Pathways to good first jobs by connecting school,
                students and employers.
              </span>
            </div>
          </div>
          <div className="row no-gutters">
            <div className="content col-xl-6 d-flex align-items-stretch">
              <div className="content">
                <img className="homegiglogo" src={Aboutus} />
              </div>
              
            </div>
            <div className="testinomialscreen">
            <Carousel
        showArrows={true}
        infiniteLoop={true}
        showThumbs={false}
        showStatus={false}
        autoPlay={true}
        interval={6100}
      >
        <div>
          
          <div className="myCarousel">
          <h4 style={{color:"rgb(0,147,69)",fontSize:16,fontFamily:"Gotham"}}>Debiased Selection Process</h4>
            <p style={{color:"black"}}>Our non-conventional selection process fosters diversity &amp; inclusion for all our students and ensures that all decisions are based purely on skills.</p>
          </div>
        </div>

        <div>
         
          <div className="myCarousel">
          <h4 style={{color:"rgb(0,147,69)",fontSize:16,fontFamily:"Gotham"}}>Next Generation Talent pool</h4>
            <p style={{color:"black"}}>Pool of diverse talent ready to complete one-off tasks, internships, gigs, short &amp; long-term projects, part time or long-term opportunities or address skills shortages.</p>
          </div>
        </div>

        <div>
          
          <div className="myCarousel">
          <h4 style={{color:"rgb(0,147,69)",fontSize:16,fontFamily:"Gotham"}}>Smart Matching Technology</h4>
            <p style={{color:"black"}}>Intelligent matching and searching functionality to unlock talents &amp; ensure that you match, screen and contact right students in minutes to build lasting relationships.</p>
          </div>
          </div>
         <div>
         <div className="myCarousel">
          <h4 style={{color:"rgb(0,147,69)",fontSize:16,fontFamily:"Gotham"}}>Fully Verified Talents</h4>
            <p style={{color:"black"}}>Thorough vetting process ensures all students have been fully screened to maintain integrity and signed code of conduct ensures that all our students can be fully trusted.</p>
          </div>
         </div>
          <div>
          <div className="myCarousel">
          <h4 id='clearingthepath'>Clearing pathways to good first jobs </h4>
            <p style={{color:"black"}}>Aligning study &amp; work by providing students ability to gain real life work experience, build portfolios and develop employability skills for linear paths to good first jobs.</p>
          </div>
          </div>
          <div>
          <div className="myCarousel">
          <h4 style={{color:"rgb(0,147,69)",fontSize:16,fontFamily:"Gotham"}}>One-Stop Platform</h4>
            <p style={{color:"black"}}>Easy to use & secure suite of cutting-edge tools in one platform to manage each step of the journey from reviewing profiles, shortlisting, contracts, payments & review.</p>
          </div>
        </div>
      </Carousel>
     
     
            </div>
            <div className="col-xl-6 d-flex align-items-stretch uspcontentmscreen">
            <div className="icon-boxes d-flex flex-column justify-content-center">
        <div className="row">
          <div className="col-md-6 icon-box" data-aos data-aos-delay={100}>
            <h4 style={{color:"rgb(0,147,69)",fontSize:16,fontFamily:"Gotham"}}>Debiased Selection Process</h4>
            <p style={{color:"black"}}>Our non-conventional selection process fosters diversity &amp; inclusion for all our students and ensures that all decisions are based purely on skills.</p>
          </div>
          <div className="col-md-6 icon-box" data-aos data-aos-delay={200}>
            <h4 style={{color:"rgb(0,147,69)",fontSize:16,fontFamily:"Gotham"}}>Next Generation Talent pool</h4>
            <p style={{color:"black"}}>Pool of diverse talent ready to complete one-off tasks, internships, gigs, short &amp; long-term projects, part time or long-term opportunities or address skills shortages.</p>
          </div>
          <div className="col-md-6 icon-box" data-aos data-aos-delay={300}>
            <h4 style={{color:"rgb(0,147,69)",fontSize:16,fontFamily:"Gotham"}}>Smart Matching Technology</h4>
            <p style={{color:"black"}}>Intelligent matching and searching functionality to unlock talents &amp; ensure that you match, screen and contact right students in minutes to build lasting relationships.</p>
          </div>
          <div className="col-md-6 icon-box" data-aos data-aos-delay={400}>
            <h4 style={{color:"rgb(0,147,69)",fontSize:16,fontFamily:"Gotham"}}>Fully Verified Talents</h4>
            <p style={{color:"black"}}>Thorough vetting process ensures all students have been fully screened to maintain integrity and signed code of conduct ensures that all our students can be fully trusted.</p>
          </div>
          <div className="col-md-6 icon-box" data-aos data-aos-delay={300}>
            <h4 id='clearingthepath'>Clearing pathways to good first jobs </h4>
            <p style={{color:"black"}}>Aligning study &amp; work by providing students ability to gain real life work experience, build portfolios and develop employability skills for linear paths to good first jobs.</p>
          </div>
          <div className="col-md-6 icon-box" data-aos data-aos-delay={400}>
            <h4 style={{color:"rgb(0,147,69)",fontSize:16,fontFamily:"Gotham"}}>One-Stop Platform</h4>
            <p style={{color:"black"}}>Easy to use & secure suite of cutting-edge tools in one platform to manage each step of the journey from reviewing profiles, shortlisting, contracts, payments & review.</p>
          </div>
        </div>
      </div>
              {/* End .content*/}
            </div>
          </div>
        </div>
        <div className="cnterbtn">
                
                <Link to="/signup"
                onClick={()=>scrollToTop()}
                className="btn btn-success"
                style={{
                  background: "rgb(0,147,69)",
                  border: "none",
                  borderRadius: "10px",
                 
                  letterSpacing: "1px",
                  fontFamily: "Gotham",
                 
                  color:"white"
                }}
                id="start"
                >SIGN UP</Link>
               
           
            </div>
      </section>
      
      {/* End About Section */}
          {/* ======= Clients Section ======= */}
          <section id="clients" className="clients" >
            <div className="container" data-aos="zoom-in">
              <hr className="startupshr" style={{ border: "1px solid #009345", opacity: "inherit" }} />
             
             
            
            </div>
          </section>
          {/* End Clients Section */}
          {/* ======= About Section ======= */}
          <section id="clients" className="clients startupsboxm">
            <div className="container" data-aos="fade-up">
              <style
                type="text/css"
                dangerouslySetInnerHTML={{
                  __html:
                    "\n    .boxxlr{\n      border: 0px solid green;\n      /*background: #dcdedd;*/\n      margin-right: 3%;\n    margin-bottom: 3%;\n    color: #000;\n    }\n\n\n.boxclrtitle {\nfont-size: 12px;font-weight: bold;margin-left: 12%\n}\n.boxxlr .boxclrtitle:hover {\nfont-size: 12px;font-weight: bold;cursor: pointer;\n}\n\n\n  ",
                }}
              />
              <div className="row no-gutters">
                <div className="content col-xl-12 d-flex align-items-stretch">
                 
                </div>
              </div>
             
            </div>
            <div className="container" style={{marginTop:"-3%"}}>
        <h2 className="section-heading" id="cpyvarioustxt">Various Opportunities Our Inclusive and Diverse Talents Can do.</h2>
        <div className="row">
        <div className="maccordion">
        <Accordion/>
         </div>
          <div className="col-md-3 bigaccord">
            <div
              className="icon-box aos-init aos-animate downsec"
              data-aos="fade-up"
              data-aos-delay={100}
              style={{
                padding: "6%",
                marginBottom: "35px",
                paddingBottom: "42px"
              }}
              id="box"
            >
              <i className="fa fa-briefcase fa-5x" id="fa"/>
              <h4 className="boxclrtitle" id="text" style={{marginLeft:"0%"}}>Internship</h4>
              <p id="text" style={{ fontWeight: "normal",fontSize:15 }}>
                In an ever-competitive marketplace, secure the best internship
                opportunities and gain real industry and valuable hands-on
                experience across all sectors and geographical locations.
              </p>
              {/*  <p  class="palgnbx" style="color: green"><i class="fa fa-long-arrow-right" aria-hidden="true"></i></p> */}
            </div>
          </div>
          <div className="col-md-3 bigaccord">
            <div
              className="icon-box aos-init aos-animate downsec" 
              data-aos="fade-up"
              data-aos-delay={100}
              style={{
                padding: "6%",
                marginBottom: "35px",
                paddingBottom: "39px"
              }}
              id="box"
            >
              <i
                style={{ marginBottom: "4px" }}
                className="fa fa-id-card-o fa-5x" id="fa"
              />
              <h4 className="boxclrtitle" id="text" style={{marginLeft:"0%"}}>Work Experience</h4>
              <p  id="text" style={{ fontWeight: "normal",fontSize:15  }}>
                <div id="work">
                Access to both newly formed Startups and Large enterprises,
                provides the perfect opportunity to acquire new skills and build
                experience either through voluntary or paid positions. <span style={{visibility:"hidden"}} >KKk</span>
                </div>
              </p>
            </div>
          </div>
          <div className="col-md-3 bigaccord">
            <div
              className="icon-box aos-init aos-animate downsec" 
              data-aos="fade-up"
              data-aos-delay={100}
              style={{ padding: "6%", height:"90%" }}
              id="box"
            >
              <i className="fa fa-american-sign-language-interpreting fa-5x" id="fa" />
              <h4 className="boxclrtitle" id="work" id="text" style={{marginLeft:"0%"}}>Work Placement</h4>
              <p  id="text" style={{ fontWeight: "normal",fontSize:15  }}>
                <div id="work_one">
                Gain significant on-the-job experience as well as apply your
                knowledge and skills in a work setting of your chosen line of
                study or industry and increase competencies sought after by
                employers.
                </div>
              </p>
            </div>
          </div>
          <div className="col-md-3 bigaccord">
            <div
              className="icon-box aos-init aos-animate downsec"
              data-aos="fade-up"
              data-aos-delay={100}
              style={{
                padding: "6%",
                marginBottom: "35px",
                paddingBottom: "38px"
              }}
              id="box"
            >
              <i
                style={{ marginBottom: "4px" }}
                className="fa fa-envelope-open fa-5x" id="fa"
              />
              <h4 className="boxclrtitle" id="text" style={{marginLeft:"0%"}}>Gig Opportunities</h4>
              <p id="text" style={{ fontWeight: "normal",fontSize:15  }}>
                Wide range of flexible Gig opportunities to make the most of
                spare times, earn money and gain valuable real-life experiences
                by securing freelance works or short-term day-rate contracts.
              </p>
            </div>
          </div>
        </div>
        <div className="row" style={{ display: "flex" }}>
          <div className="col-md-3 bigaccord ">
            <div
              className="icon-box aos-init aos-animate downsec" id="fa"
              data-aos="fade-up"
              data-aos-delay={100}
              style={{ padding: "6%", marginBottom: "35px", }}
              id="box"
            >
              <i className="fa fa-clock-o fa-5x" id="fa" />
              <h4 className="boxclrtitle" id="text" style={{marginLeft:"0%"}}>Part Time Roles</h4>
              <p id="text" style={{ fontWeight: "normal",fontSize:15  }}>
                GigeconoMe provides you the opportunity to not only gain
                financial protection during your studies but also gain a variety
                of employability find part-time opportunities directly relevant
                to definite career plans.
              </p>
            </div>
          </div>
          <div className="col-md-3 bigaccord">
            <div
              className="icon-box aos-init aos-animate downsec" id="fa"
              data-aos="fade-up"
              data-aos-delay={100}
              style={{ padding: "6%", marginBottom: "35px" }}
              id="box"
            >
              <i className="fa fa-cog fa-5x"id="fa" />
              <h4 className="boxclrtitle" id="text" style={{marginLeft:"0%"}}>Course Work Related Projects</h4>
              <p id="text" style={{ fontWeight: "normal",fontSize:15  }}>
                <div id="course">
                Interested in inspiring real-life projects than those in current
                curriculum? Use GigeconoMe to find specific work-related
                activities and develop an understanding of work and develop
                skills employers want.
                </div>
              </p>
            </div>
          </div>
          <div className="col-md-3 bigaccord">
            <div
              className="icon-box aos-init aos-animate downsec" id="fa"
              data-aos="fade-up"
              data-aos-delay={100}
              style={{ padding: "6%", height:"89%"}}
              id="box"
            >
              <i className="fa fa-folder-open fa-5x" id="fa"/>
              <h4 className="boxclrtitle" id="text" style={{marginLeft:"0%"}} >Portfolio Building</h4>
              <p  id="text" style={{ fontWeight: "normal",padding:"4%" ,marginLeft:"-4%",fontSize:15,marginTop:"-4%" }}>
              Impress future employers and build an impressive portfolio of work while studying to showcase learning growth as well as demonstrate your creativity, skills and ability.

              </p>
            </div>
          </div>
          <div className="col-md-3 bigaccord">
            <div
              className="icon-box aos-init aos-animate downsec" id="fa"
              data-aos="fade-up"
              data-aos-delay={100}
              style={{ padding: "6%", }}
              id="box"
            >
              <i className="fa fa-lightbulb-o fa-5x" id="fa"/>
              <h4 className="boxclrtitle" id="text" style={{marginLeft:"0%"}}>Graduate Opportunities</h4>
              <p  id="text" style={{ fontWeight: "normal",fontSize:15  }}>
              In your final year of study and ready for the job market? Use GigeconoMe to navigate the first role of your career ladder and explore the best available graduate jobs across the widest range of sectors and employers.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="cnterbtn ">
              
              <Link to="/signup"  
              className="btn btn-success"
              onClick={()=>scrollToTop()}
              style={{
                background: "#009345",
                border: "none",
                borderRadius: "10px",
               
                letterSpacing: "1px",
                color:"white",
                
              }}>
GET STARTED
              </Link>
            
           
          </div>
          </section>
        </main>
        {/* End #main */}
        {/* ======= Footer ======= */}
        
      <Footer />
      </div>
    );
  }
}
export default Company;
