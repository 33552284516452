import React, { Component } from "react";
import Select from "react-select";
const style = {
  control: (base, state) => ({
    ...base,
    border: "1px solid gray",
    borderRadius:"1px",
   
   
    // This line disable the blue border
    boxShadow: state.isFocused ? 0 : 0,
    "&:hover": {
      border: "1px solid gray",
      borderRadius:"1px",
     
    }
  }),
 
};
class GraduationYear extends React.Component {
  constructor() {
    let comments = JSON.parse(sessionStorage.getItem("someData"));
    super();
    this.state = {
      reg_level: 2,
      id: "",
      name: "",
      dropdownlist_input: "graduationyear",
      selectOptions: [],
      userid: comments,
      GraduationYear:""
    };
  }
  componentDidMount() {
    this.getOptions();
  }
  async getOptions() {
    const data_one = { dropdownlist_input: this.state.dropdownlist_input };
    const data_two={userid:this.state.userid,
      reg_level:this.state. reg_level
  }
    let BaseUrl = JSON.parse(sessionStorage.getItem('baseurl'));

    fetch( 'https://www.gigeconome.com:5200/api/getdropdownlist', { method: 'POST', 

      body: JSON.stringify(data_one), // data can be `string` or {object}!

      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())

      .catch((error) => console.error("Error:", error))

      .then((json) => {
        const data = json.result;
        const options = data.map((d) => ({
          value: d.id,
          label: d.name,
         
        }));
        this.setState({ selectOptions: options });
        
      });
      fetch('https://www.gigeconome.com:5200/api/getststepinfo', { method: 'POST', 
  
      body: JSON.stringify(data_two), // data can be `string` or {object}!
    
      headers:{ 'Content-Type': 'application/json' } })
    
      .then(res => res.json())
    
      .catch(error => console.error('Error:', error))
    
      .then((posRes)=>{
      // console.log((posRes.result))
       this.setState({
         
        GraduationYear:posRes.result. GraduationYear,
        
  
          
        })
             
          })
  
  }
  
  handleSchool = (e) => {
    var SchoolName = JSON.stringify(e.label);
    sessionStorage.setItem("SchoolName", SchoolName);
    this.setState({ name: e.label });
  };
  render() {
    const { GraduationYear}=this.state
    return (
      <div>
        <Select
        placeholder={ GraduationYear}
          onChange={this.handleSchool}
          options={this.state.selectOptions}
          styles={style}
                    components={{
                      IndicatorSeparator: () => null
                    }}
        />
      </div>
    );
  }
}
export default GraduationYear;
