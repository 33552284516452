import React, { Component } from "react";
import Select from "react-select";
const style = {
    control: (base, state) => ({
      ...base,
      border: "1px solid gray",
      borderRadius: "1px",
      marginTop: "1%",
  
      // This line disable the blue border
      boxShadow: state.isFocused ? 0 : 0,
      "&:hover": {
        border: "1px solid gray",
        borderRadius: "1px",
        marginTop: "1%",
      },
    }),
  };
class Qualification extends React.Component {
  constructor() {
    let comments = JSON.parse(sessionStorage.getItem("someData"));
    super();
    this.state = {
      reg_level:2,
      id: "",
      name: "",
      dropdownlist_input: "qualification",
      selectOptions: [],
      userid: comments,
      Qualification:" ",
    };
  }
  componentDidMount() {
    this.getOptions();
  }
  async getOptions() {
    const data_one = { dropdownlist_input: this.state.dropdownlist_input };
    const data_two={userid:this.state.userid,
      reg_level:this.state. reg_level
  }
    let BaseUrl = JSON.parse(sessionStorage.getItem('baseurl'));

    fetch(BaseUrl + 'getdropdownlist', { method: 'POST', 

      body: JSON.stringify(data_one), // data can be `string` or {object}!

      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())

      .catch((error) => console.error("Error:", error))

      .then((json) => {
        const data = json.result;
        const options = data.map((d) => ({
          value: d.id,
          label: d.name,
         
        }));
        this.setState({ selectOptions: options });
        
      });
      fetch('https://mainteccloud.com:8080/api/getststepinfo', { method: 'POST', 
  
      body: JSON.stringify(data_two), // data can be `string` or {object}!
    
      headers:{ 'Content-Type': 'application/json' } })
    
      .then(res => res.json())
    
      .catch(error => console.error('Error:', error))
    
      .then((posRes)=>{
      console.log((posRes.result.QualificationType))
       this.setState({
         
        Qualification:posRes.result.QualificationType,
        
  
          
        })
             
          })
  
  }
  handleSchool = (e) => {
    var Quali = JSON.stringify(e.label);
    sessionStorage.setItem("Qualification", Quali);
    this.setState({ name: e.label });
  };


  

  render() {
    const { Qualification }=this.state;
    return (
      <div>
        <Select
         placeholder={ Qualification }
          onChange={this.handleSchool}
          options={this.state.selectOptions}
          styles={style}
          
          components={{
            IndicatorSeparator: () => null
          }}
        />
      </div>
    );
  }
}
export default Qualification;
