import React from "react";
import Header from "../Header/Header";
import PostaProjectimg from "../../images/Post a Project-13.png";
import { Link } from "react-router-dom";
import "./dashboard.css";
export default class PostaProject extends React.Component {
  constructor() {
    super();
    this.state={
postproject:0,
fixedterm:1,
gradopp:0
    }
this.handleProject=this.handleProject.bind(this)
this.handleFixed=this.handleFixed.bind(this)
this.handleGradopp=this.handleGradopp.bind(this)
  }

  handleProject(){
this.setState({postproject:0,fixedterm:1,gradopp:0})
  }
  handleFixed(){
    this.setState({fixedterm:0,postproject:1,gradopp:0})
  }
handleGradopp(){
        this.setState({gradopp:1,fixedterm:2,postproject:2})
  }
  render() {
    return (
      <div>
        <div>
        <Header />
        </div>
        <div class="container postaprojectboxx">
          <div class="text-center">
            
           
              <img src={PostaProjectimg} class="rounded" style={{ height:80,width:90 }}/>
          
          </div>
          <div >
           
            <div class="text-center mt-4 postaprojectheading">
            Tell us more to access our student network.
            </div>
          </div>
          <div >
            
            <div class="text-center mt-2 postaprojectsubheading">
            Select a relevant category below to start getting matched to our Student Talents in minutes.
            </div>
          </div>
          
          <div class="row mt-4" style={{background:"#e7e6e6",border:"2px solid rgb(0,147,69)",borderRadius:15,boxShadow: "0px 1px 2px 1px green"}}>
        
              <div class="col-sm-12 postaproject">
          <div>
           
              <div className="row">
              <div className={this.state.postproject==0?"col-sm-3 postacontent":"col-sm-3 postcontent"} onClick={this.handleProject}>
         <span style={{marginLeft:"40%",fontFamily:"Gotham",fontSize:40}}><i class="fa fa-picture-o" aria-hidden="true"></i></span> <br/>
              <span style={{fontFamily:"Gotham",fontSize:17}}>Project Related Opportunities</span> <br/>
              <span style={{fontWeight:"bold",fontFamily:"GothamLight",fontWeight:"bold",fontSize:14}}>Access Student talents interested in:</span>
              <ul style={{marginTop:"5%",fontFamily:"GothamLight",fontWeight:"bold",fontSize:12}}>
                <li>Gig Opportunities</li>
                <li>Course work related projects</li>
                <li>Portfolio Building Assignments</li>
              </ul>
</div>
<div className="col-sm-1"></div>
{this.state.fixedterm==1?<>
  <div className="col-sm-3 postcontent" onClick={this.handleFixed}>
<span style={{marginLeft:"40%",fontFamily:"Gotham",fontSize:40}}><i class="fa fa-calendar" aria-hidden="true"></i></span> <br/>
              <span style={{marginLeft:"7%",fontFamily:"Gotham",fontSize:17}}>Fixed Term Opportunities</span> <br/>
              <span style={{fontWeight:"bold",fontFamily:"GothamLight",fontWeight:"bold",fontSize:14}}>Access Student talents interested in:</span>
              <ul style={{marginTop:"5%",fontFamily:"GothamLight",fontWeight:"bold",fontSize:12}}>
                <li>Internship</li>
                <li>Work Placement & Experience</li>
                <li>Part Time Roles</li>
              </ul>
</div>
</>:null}
{this.state.fixedterm==2?<>
  <div className="col-sm-3 postcontent" onClick={this.handleFixed}>
<span style={{marginLeft:"40%",fontFamily:"Gotham",fontSize:40}}><i class="fa fa-calendar" aria-hidden="true"></i></span> <br/>
              <span style={{marginLeft:"7%",fontFamily:"Gotham",fontSize:17}}>Fixed Term Opportunities</span> <br/>
              <span style={{fontWeight:"bold",fontFamily:"GothamLight",fontWeight:"bold",fontSize:14}}>Access Student talents interested in:</span>
              <ul style={{marginTop:"5%",fontFamily:"GothamLight",fontWeight:"bold",fontSize:12}}>
                <li>Internship</li>
                <li>Work Placement & Experience</li>
                <li>Part Time Roles</li>
              </ul>
</div>
</>:null}
{this.state.fixedterm==0?<>
  <div className="col-sm-3 postacontent" onClick={this.handleFixed}>
<span style={{marginLeft:"40%",fontFamily:"Gotham",fontSize:40}}><i class="fa fa-calendar" aria-hidden="true"></i></span> <br/>
              <span style={{marginLeft:"7%",fontFamily:"Gotham",fontSize:17}}>Fixed Term Opportunities</span> <br/>
              <span style={{fontWeight:"bold",fontFamily:"GothamLight",fontWeight:"bold",fontSize:14}}>Access Student talents interested in:</span>
              <ul style={{marginTop:"5%",fontFamily:"GothamLight",fontWeight:"bold",fontSize:12}}>
                <li>Internship</li>
                <li>Work Placement & Experience</li>
                <li>Part Time Roles</li>
              </ul>
</div>
</>:null}
<div className="col-sm-1"></div>
<div className={this.state.gradopp==0?"col-sm-3 postcontent":"col-sm-3 postacontent"} onClick={this.handleGradopp}>
<span style={{marginLeft:"40%",fontFamily:"Gotham",fontSize:40}}><i class="fa fa-handshake-o" aria-hidden="true"></i></span> <br/>
              <span style={{marginLeft:"7%",fontFamily:"Gotham",fontSize:17}}>Long Term Opportunities</span> <br/>
              <span style={{fontWeight:"bold",fontFamily:"GothamLight",fontWeight:"bold",fontSize:14}}>Access Student talents interested in:</span>
              <ul style={{marginTop:"5%",fontFamily:"GothamLight",fontWeight:"bold",fontSize:12}}>
                <li>Graduate Roles</li>
                <li>Trainee Roles</li>
                <li>Graduate Scheme</li>
              </ul>
</div>
              </div>
            
            </div>
            <div class="col-sm-1"></div>    
            </div>
         
          </div>
          <div class="row">
              <div class="col-sm-5">
                  </div>
            <div class="col-sm-7">
            <div class="tab-content" id="pills-tabContent">
  <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
{this.state.postproject==0?<>
  <Link to="/postaproject_one" className="btn btn-success" id="continue">Continue</Link>
</>:<>


</>}
{this.state.fixedterm==0?<><Link to="/opportunities_one" className="btn btn-success" id="continue">Continue</Link></>:null}
{this.state.gradopp==1?<><Link to="/gradopportunitystepone" className="btn btn-success" id="continue">Continue</Link></>:null}
  </div>
  <div class="tab-pane fade" id="pills-fixed" role="tabpanel" aria-labelledby="pills-fixed-tab">
 
    </div>
  <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
    <button className="btn btn-success" id="continue">Continue</button></div>
</div>
            </div>
            </div>
        </div>
      </div>
    );
  }
}
