import React, { useState } from "react";
import "./modal.css";
import Resizer from "react-image-file-resizer";

export default class TelephoneVerify extends React.Component {
  constructor() {
    super();
  let comments = JSON.parse(sessionStorage.getItem('someData'));
  this.textInput = React.createRef();
  this.state = {
    
      userid:comments,
      addstudata:"verifystudent",
      verifystudentstatus:"Yes",
      studentidproof:"",
      message:""



      };

   
   
  }
  openModal = () => {
    this.setState({ showModal: true });
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };
  getBase64 = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();
  
      // Convert the file to base64 text
      reader.readAsDataURL(file);
  
      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        console.log("Called", reader);
        baseURL = reader.result;
        console.log(baseURL);
        resolve(baseURL);
      };
      console.log(fileInfo);
    });
  };
  handleImage = (e) => {
    // let file = new FileReader();
    console.log(e.target.files[0]);
    let { file } = this.state;
  
    file = e.target.files[0];
  
    this.getBase64(file)
      .then((result) => {
        file["base64"] = result;
        console.log("File Is", file);
        this.setState({
          studentidproof: result,
          file,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  
    this.setState({
      //  file: e.target.files[0]
    });
  
    this.setState({
      //companylogo: e.target.files[0]
    });
    var fileInput = false;
    if (e.target.files[0]) {
      fileInput = true;
    }
    
    if(fileInput) {
      try {
        Resizer.imageFileResizer(
          e.target.files[0],
          120,
          120,
          "JPEG",
          100,
          0,
          (uri) => {
            console.log(uri);
            this.setState({ newImage: uri });
          },
          "base64",
          120,
          120
        );
      } catch (err) {
        console.log(err);
      }
    }
  };
  
  
  handlePhoto = (e) => {
    // let file = new FileReader();
    console.log(e.target.files[0]);
    let { file } = this.state;
  
    file = e.target.files[0];
  
    this.getBase64(file)
      .then((result) => {
        file["base64"] = result;
        console.log("File Is", file);
        this.setState({
          studentidproof: result,
          file,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  
    this.setState({
      //  file: e.target.files[0]
    });
  
    this.setState({
      //companylogo: e.target.files[0]
    });
    var fileInput = false;
    if (e.target.files[0]) {
      fileInput = true;
    }
    
    if(fileInput) {
      try {
        Resizer.imageFileResizer(
          e.target.files[0],
          120,
          120,
          "JPEG",
          100,
          0,
          (uri) => {
            console.log(uri);
            this.setState({ newImage: uri });
          },
          "base64",
          120,
          120
        );
      } catch (err) {
        console.log(err);
      }
    }
  };
  
handleProof=(e)=>{
  const data_proof={
userid:this.state.userid,
studentidproof:this.state.studentidproof,
verifystudentstatus:this.state.verifystudentstatus,
addstudata:this.state.addstudata

  }

  fetch( 'https://www.gigeconome.com:5200/api/addstu_profiledata', { method: 'POST', 

  body: JSON.stringify(data_proof), // data can be `string` or {object}!

  headers:{ 'Content-Type': 'application/json' } })

  .then(res => res.json())

  .catch(error => console.error('Error:', error))

  .then((response)=>{
   console.log(response)
this.setState({message:response.message})
window.location.reload();
 
  })
 

}
 render(){
  return (
    <>
      {this.state.showModal && (
      <div class="ft-modal" id="ft-telephone-modal">
        <div class="ft-modal-content" style={{width:"50%"}}>
          <div class="ft-modal-header">
            <div class="header">
            <h5
                  class="modal-title"
                  id="exampleModalLabel"
                  style={{
                    textAlign: "center",
                    fontSize: 25,
                    fontWeight: "bold",
                  }}
                >
                Verify Your Telephone Number 
                </h5>
                <hr />
             
            </div>
          </div>
          <div class="ft-modal-body">
         
          
          <div className="row">

            <div className="col-sm-2">
              
            </div>
            <div className="col-sm-8">
              <label style={{marginTop:"3%",fontSize:15}}>Enter Telephone Number</label> <br />
              <input type="text" 
              placeholder="Enter Your Mobile Number"
              className="form-control mt-3"
              />
              </div> 
              <div className="col-sm-2">
              
              </div>
          </div>

          <div className="row">

<div className="col-sm-2">
  
</div>
<div className="col-sm-5">
  <label style={{marginTop:"3%",fontSize:15}}>Enter OTP</label> <br />
  <input type="text"
  placeholder="Enter OTP"
  className="form-control mt-3"
  />
  </div>

  <div className="col-sm-4">
  <label style={{marginTop:"3%",fontSize:15,color:"transparent"}}>Enter OTP</label> <br />
  <button
                    style={{ backgroundColor: "rgb(0, 147, 69)",width:150,marginTop:"7%" }}
                    className="btn btn-success"
                   
                  >
                    Sent OTP
                  </button>
  </div> 
  <div className="col-sm-2">
  
  </div>
</div>

        
 
             
                
              
              
               
              
            
        

        
           
         
              <div class="modal-footer mt-4">
                <div class="col-sm-4"></div>
                <div class="col-sm-7">
                  <button
                    style={{ backgroundColor: "rgb(0, 147, 69)",width:150 }}
                    className="btn btn-success"
                   
                  >
                    Save
                  </button> <br /> <br />
                  <span style={{color:"rgb(0,147,69)",fontFamily:"Gotham",marginTop:"5%",fontWeight:"bold"}}>{this.state.message}</span>
                </div>
                <div class="col-sm-1"></div>
              </div>
           
          </div>
          <div class="ft-modal-footer">
            <a class="ft-modal-close" data-dismiss="modal"
                     onClick={this.closeModal} style={{cursor:"pointer"}}>
              [&#10006;]{" "}
            </a>
          </div>
        </div>
      </div>
      )}
     
      <a href="#ft-telephone-modal" 
       onClick={this.openModal}
      class="col-sm-4 text-success"  
                    >Verify</a>


               
    </>
  );
}
}