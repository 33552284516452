import React,{Component} from 'react';
import Select from 'react-select';
class SchoolName extends React.Component{
    constructor() {
        let comments = JSON.parse(sessionStorage.getItem('someData'));
        super();
        this.state = {
        
         
          reg_level:5,
          id: "",
          name: '',
          dropdownlist_input : "schoolname",
          selectOptions : [],
          userid:comments,
    
          };
       
      }
      componentDidMount(){
        this.getOptions()
    }
    async getOptions(){
      const data_one={dropdownlist_input:this.state.dropdownlist_input,
      
      }
    
      let BaseUrl = JSON.parse(sessionStorage.getItem('baseurl'));

      fetch( 'https://www.gigeconome.com:5200/api/getdropdownlist', { method: 'POST', 
    
       body: JSON.stringify(data_one), // data can be `string` or {object}!
    
       headers:{ 'Content-Type': 'application/json' } })
    
       .then(res => res.json())
    
       .catch(error => console.error('Error:', error))
    
       .then((json)=>{
        const data = json.result
       
        const options = data.map(d => ({
          "value" : d.id,
          "label" : d.name
    
        }))
        this.setState({selectOptions: options})
       })
      
      }
      handleSchool = (e) => {

        var SchoolName = JSON.stringify(e.label);
          sessionStorage.setItem('SchoolName', SchoolName);
         this.setState({name:e.label})
       
       
     }
    render(){
        return(
            <div>
<Select 
                                
                               
                                  onChange={this.handleSchool} 
                                 
                                  options={this.state.selectOptions}  />

            </div>
        )
    }
}
export default SchoolName;