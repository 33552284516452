import React, { Component } from "react";
import './style.css';
import { Link } from "react-router-dom";
import Logo from '../../images/logo.png';
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
class Inclusion extends Component{
    render(){
        return(
            <div>
                  {/* ======= Header ======= */}
                    <Header />
        <main className="tandcbox" id="main" style={{background: '#303030', clear: 'both', height: '175px'}}>
          <section id="clients" className="clients">
            <h3 className="inclusionheading"> GigeconoMe Equality, diversity and inclusion policy</h3>
          </section>
        </main>
        <main id="main" id="main_one">
          <br /><br />
          {/* ======= About Section ======= */}
          <section id="clients" className="clients mscreentandcbox">
            <div className="container" data-aos="fade-up" style={{marginTop: '-4%'}}>
              <style type="text/css" dangerouslySetInnerHTML={{__html: "\n    .boxxlr{\n     border: 0px solid green;\n    background: #edf8f2;\n    margin-right: 2.5%;\n    margin-bottom: 7%;\n    color: #000;\n    margin-left: 9%;\n    width: 100%;\n    }\n/*.boxxlr:hover {\n    border: 0px solid green;\n    background: green;\n    margin-right: 1%;\n    margin-bottom: 1%;\n     color: #fff;\n}*/\n\n.boxclrtitle {\nfont-size: 12px;font-weight: bold;\n}\n\n.boxclrtitle1 {\nfont-size: 18px;font-weight: bold;\n}\n/*.boxxlr .boxclrtitle:hover {\nfont-size: 12px;font-weight: bold;\n}*/\n\n\n  " }} />
              <div className="row no-gutters">
                <div className="content col-xl-12 d-flex align-items-center">
                  <div className="content col-xl-12 d-flex align-items-center">
                    <div className="row" style={{display: 'flex', justifyContent: 'center', background: '#fff', margin: 'auto',}}>
                    <div
                      className="row"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        background: "#fff",
                        margin: "auto",
                        padding: "5%",
                      }}
                      
                    >
                       <h3 className="inclusionsubheading">
                        GigeconoMe Equality, diversity and inclusion policy
                      </h3>
                      <p className="inclusiontextpage" style={{fontFamily:"Gotham",marginTop:"2%"}}>
                       
                        GigeconoMe is committed to encouraging equality, diversity and inclusion among our workforce, as well as those using our platforms and to eliminating unlawful discrimination. We want our workforce and users of our platform to be truly representative of all sections of society. We want our platform to be one in which every user feels respected, supported and able to give their best.{" "}
                   
                      </p>
                      <p className="inclusiontextpage" style={{fontFamily:"Gotham"}}>
                        
                        The organisation - in providing goods and/or services and/or facilities - is also committed against unlawful discrimination of customers or the public.{" "}
                       
                      </p>
                      <p  style={{fontFamily:"Gotham",fontWeight:"bold",color:"rgb(0,147,69)"}}>

                         
                          Our policy’s purpose
                          
                      </p>
                      <p style={{fontFamily:"Gotham",fontWeight:"bold"}}>
                          
                          This policy’s purpose is to :
                         
                      </p>
                      <p>
                          <ol  className="inclusiontextpage" style={{ listStyle: "number",fontFamily:"Gotham" }}>
                                <li  style={{marginTop:"1%"}}>Provide equality, fairness and respect for all in our employment, whether temporary, part-time or full-time</li>
                                <li  style={{marginTop:"1%"}}>Provide equality, fairness and respect for all users of our platform, whether they are students or employees of companies and universities.</li>
                                <li  style={{marginTop:"1%"}}>Not unlawfully discriminate because of the Equality Act 2010 protected characteristics of:</li>
                                    <ol style={{ listStyle: "disc" }}>
                                        <li>age</li>
                                        <li>disability</li>
                                        <li>gender reassignment</li>
                                        <li>marriage or civil partnership</li>
                                        <li>pregnancy and maternity</li>
                                        <li>race (including colour, nationality, and ethnic or national origin)</li>
                                        <li>sex</li>                                        
                                        <li>sexual orientation</li>
                                    </ol>
                          <li style={{marginTop:"1%"}}>Oppose and avoid all forms of unlawful discrimination. This includes in : </li>
                          <ol className="inclusiontextpage" style={{ listStyle: "disc" }}>
                                        <li>pay and benefits</li>
                                        <li>terms and conditions of employment</li>
                                        <li>dealing with grievances and discipline</li>
                                        <li>dismissal</li>
                                        <li>redundancy</li>
                                        <li>leave for parents</li>
                                        <li>requests for flexible working</li>                                        
                                        <li>selection for employment, promotion, training or other developmental opportunities </li>
                                    </ol>
                          </ol>
                      </p>
                         <p style={{fontFamily:"Gotham",fontWeight:"bold",color:"rgb(0,147,69)"}}>
                           
                                Our commitments
                        
                          </p>
                          <p style={{fontFamily:"Gotham",fontWeight:"bold"}}>   
                                The organisation commits to :
                            
                          </p>
                          <ol className="inclusiontextpage" style={{ listStyle: "number",fontFamily:"Gotham",marginLeft:"5%"  }}>
                          <li  style={{marginTop:"1%",fontFamily:"Gotham"}}>Encourage equality, diversity and inclusion in the workplace as they are good practice and make business sense</li>
                          <li  style={{marginTop:"1%",fontFamily:"Gotham"}}>Create a working environment and platform free of bullying, harassment, victimisation and unlawful discrimination, promoting dignity and respect for all, and where individual differences and the contributions of all staff are recognised and valued. <br/>

                                        <p style={{marginTop:"1%"}}> This commitment includes training managers and all other employees about their rights and responsibilities under the equality, diversity and inclusion policy. Responsibilities include staff conducting themselves to help the organisation provide equal opportunities in employment, and prevent bullying, harassment, victimisation and unlawful discrimination. <br /></p>      

                               <p> All staff should understand they, as well as their employer, can be held liable for acts of bullying, harassment, victimisation and unlawful discrimination, in the course of their employment, against fellow employees, customers, suppliers and the public</p>
                               
                                </li>

                                
                          <li  style={{marginTop:"1%"}}>Take seriously complaints of bullying, harassment, victimisation and unlawful discrimination by fellow employees, customers, suppliers, visitors, the public and any others in the course of the organisation’s work activities. <br/>

                                        <p style={{marginTop:"1%"}}> Such acts will be dealt with as misconduct under the organisation’s grievance and/or disciplinary procedures, and appropriate action will be taken. Particularly serious complaints could amount to gross misconduct and lead to dismissal without notice.<br /></p>      

                               <p> Further, sexual harassment may amount to both an employment rights matter and a criminal matter, such as in sexual assault allegations. In addition, harassment under the Protection from Harassment Act 1997 – which is not limited to circumstances where harassment relates to a protected characteristic – is a criminal offence.</p>
                               
                                </li>
                                <li  style={{marginTop:"1%"}}>Make opportunities for training, development and progress available to all staff, who will be helped and encouraged to develop their full potential, so their talents and resources can be fully utilised to maximise the efficiency of the organisation.</li>
                                <li  style={{marginTop:"1%"}}>Make decisions concerning staff being based on merit (apart from in any necessary and limited exemptions and exceptions allowed under the Equality Act).</li>
                                <li  style={{marginTop:"1%"}}>Review employment practices and procedures when necessary to ensure fairness, and also update them and the policy to take account of changes in the law.</li>
                                <li  style={{marginTop:"1%"}}>
                                    <p> Monitor the make-up of the workforce regarding information such as age, sex, ethnic background, sexual orientation, religion or belief, and disability in encouraging equality, diversity and inclusion, and in meeting the aims and commitments set out in the equality, diversity and inclusion policy.</p>
                                    <p> Monitoring will also include assessing how the equality, diversity and inclusion policy, and any supporting action plan, are working in practice, reviewing them annually, and considering and taking action to address any issues.</p>
                                   </li>
                                
                            
                            </ol>
                            <ol className="inclusiontextpage" style={{ listStyle: "none" }}>
                                <li style={{fontFamily:"Gotham",fontWeight:"bold",color:"rgb(0,147,69)"}}>
                                    Agreement to follow this policy</li>
                                <li style={{marginTop:"1%",fontFamily:"Gotham"}}>The equality, diversity and inclusion policy is fully supported by senior management and has been agreed with trade unions and/or employee representatives.</li>
                           </ol>
                           <ol className="inclusiontextpage" style={{ listStyle: "none" }}>
                                <li  style={{fontFamily:"Gotham",fontWeight:"bold",color:"rgb(0,147,69)"}}>
                                    Our disciplinary and grievance procedures</li>
                                <li style={{marginTop:"1%",fontFamily:"Gotham"}}>Details of the organisation’s grievance and disciplinary policies and procedures can be found at the company office and in HR. This includes with whom an employee should raise a grievance – usually their line manager.</li>
                                <li style={{marginTop:"1%",fontFamily:"Gotham"}}>Use of the organisation’s grievance or disciplinary procedures does not affect an employee’s right to make a claim to an employment tribunal within three months of the alleged discrimination.
                                Contact
                                </li>
                           </ol>
                           <ol className="inclusiontextpage" style={{ listStyle: "none" }}>
                                <li  style={{fontFamily:"Gotham",fontWeight:"bold",color:"rgb(0,147,69)"}}>
                                    Contact</li>
                                <li style={{marginTop:"1%",fontFamily:"Gotham"}}>Any questions regarding this Inclusion and Diversity Policy may be directed to the Human Resources team at GigeconoMe.</li>
                                <li style={{marginTop:"1%",fontFamily:"Gotham"}}>In addition, GigeconoMe has an Ethics and Compliance Committee and an Ethics Channel, through which all employees and stakeholders can consult or communicate any issues related to the Code of Ethics and applicable rules of the Organisation in general, and issues of equality, diversity and inclusion, in particular.</li>
                           </ol>
                        
                      <p />
                      

                           <div class="row">
                      <div class="col-sm-4">
                        </div>
                        <div class="col-sm-5">
                       
                         </div>
                           <div class="col-sm-3"></div></div>

                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>     
        </main>{/* End #main */}
        <Footer />
            </div>
        )
    }
}
export default Inclusion;