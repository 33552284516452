import React from "react";
import { Link } from "react-router-dom";
const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // for smoothly scrolling
    });
    sessionStorage.removeItem("SearchText");
    sessionStorage.setItem("SearchText","");
  };
  const handleRetake=()=>{
    const retakedata = { userid: sessionStorage.getItem("someData") ,
  
  };
  console.log(retakedata)
  fetch("https://www.gigeconome.com:5200/api/getstEOAdaysvalidinfo", {
  method: "POST",
  
  body: JSON.stringify(retakedata), // data can be `string` or {object}!
  
  headers: { "Content-Type": "application/json" },
  })
  .then((res) => res.json())
  
  .catch((error) => console.error("Error:", error))
  
  .then((response) => {
    console.log(response)
  if(response.validdays==90 || response.validdays>=90){
    window.location.href=`./emptestdescription`
  }
  else if(response.validdays<90){
    window.location.href=`./retakeemptest`
  }
  else{
      console.log("error")
  }
  })
    
  }
export default class MobileMenu extends React.Component{
    constructor(){
        super()
        this.state={
            homemenu:0,
            studentmenu:0,
            companymenu:0,
            educatormenu:0,
            startupsmenu:0
        }
        this.handleHome=this.handleHome.bind(this)
        this.handleStudent=this.handleStudent.bind(this)
        this.handleCompany=this.handleCompany.bind(this)
        this.handleEducators=this.handleEducators.bind(this)
        this.handleStartups=this.handleStartups.bind(this)
        this.handleHomeclose=this.handleHomeclose.bind(this)
        this.handleStudentclose=this.handleStudentclose.bind(this)
        this.handleCompanyclose=this.handleCompanyclose.bind(this)
        this.handleEducatorsclose=this.handleEducatorsclose.bind(this)
        this.handleStartupsclose=this.handleStartupsclose.bind(this)
        this.handleProfile=this.handleProfile.bind(this)
        this.handleLogout=this.handleLogout.bind(this)
    }
    handleLogout(){
      window.sessionStorage.removeItem("someData");
      window.sessionStorage.removeItem("nameData");
      window.sessionStorage.removeItem("DataName");
      window.sessionStorage.removeItem("UserType");
      window.location.href=`./home`
    }
    handleProfile(){
      window.location.href=`./StudentDashboard`
    }
    handleHome(){
        this.setState({homemenu:1})
    }
    handleHomeclose(){
        this.setState({homemenu:0})
    }
    handleStudentclose(){
        this.setState({studentmenu:0})
    }
    handleStudent(){
        this.setState({studentmenu:1})
    }
    handleCompany(){
        this.setState({companymenu:1})
    }
    handleCompanyclose(){
        this.setState({companymenu:0})
    }
    handleEducators(){
      this.setState({educatormenu:1})
    }
    handleEducatorsclose(){
        this.setState({educatormenu:0})
    }
    handleStartups(){
        this.setState({startupsmenu:1})
    }
    handleStartupsclose(){
        this.setState({startupsmenu:0})
    }
    render(){
        return(<>
       
        {this.state.homemenu==0?<>
            <span style={{fontSize:14,fontFamily:"Gotham",marginLeft:"3%",textDecoration:this.state.homemenu==1?"underline":null}} onClick={this.handleHome} >Home <i class="fa fa-caret-down" aria-hidden="true"></i></span><br/>
        </>:<>
        <span style={{fontSize:14,fontFamily:"Gotham",marginLeft:"3%",textDecoration:this.state.homemenu==1?"underline":null}} onClick={this.handleHomeclose} >Home <i class="fa fa-caret-down" aria-hidden="true"></i></span><br/>
        </>}
        
        {this.state.homemenu==1?<>
            <Link
        
                     style={{ color: "#009345",marginLeft:"-5%",marginTop:"-2.5%",fontSize:14 }}
                     to="aboutus"
                  
                   >
                    About Us
                   </Link>
                   <Link
        
        style={{ color: "#009345",marginLeft:"-5%",marginTop:"-5%",fontSize:14 }}
        to="/contactus"
     
      >
       Contact Us
      </Link>
        </>:<></>}
        {this.state.studentmenu==0?<>
            <span style={{fontSize:14,fontFamily:"Gotham",marginLeft:"3%",textDecoration:this.state.studentmenu==1?"underline":null}} onClick={this.handleStudent}>Students <i class="fa fa-caret-down" aria-hidden="true"></i></span>
        </>:<>
        <span style={{fontSize:14,fontFamily:"Gotham",marginLeft:"3%",textDecoration:this.state.studentmenu==1?"underline":null}} onClick={this.handleStudentclose}>Students <i class="fa fa-caret-down" aria-hidden="true"></i></span>
        </>}
       
        <br/>
        {this.state.studentmenu==1?<>
            <Link
                        
                        style={{ color: "#009345",marginLeft:"-5%",marginTop:"-3%" }}
                        to="studentHowitswork"
                        onClick={()=>scrollToTop()}
                      >
                        How it Works
                      </Link>
                      <Link
                       
                        style={{ color: "#009345",marginLeft:"-5%",marginTop:"-5%" }}
                        to="/companysearch"
                        onClick={()=>scrollToTop()}
                      >
                        Our Employers
                      </Link>
                      {JSON.parse(sessionStorage.getItem("UserType"))=="Student"?<>
                      <a
                        className="dropdown-item"
                        
                        style={{ color: "#009345",marginLeft:"-5%",marginTop:"-5%" }}
                        
                        onClick={handleRetake}
                      >
                        Career Readiness Test
                      </a>
          
                      </>:<>
                      {JSON.parse(sessionStorage.getItem("UserType"))=="Company"||JSON.parse(sessionStorage.getItem("UserType"))=="Employee"?<>
                     
                      </>:<>
                      
                      <Link
                        className="dropdown-item"
                        
                        style={{ color: "#009345",marginLeft:"-5%",marginTop:"-5%" }}
                        to="/emplyoabilityone"
                        onClick={()=>scrollToTop()}
                      >
                        Career Readiness Test
                      </Link>
                      </>}
                      </>}
        </>:<></>}
        {this.state.companymenu==0?<>
            <span style={{fontSize:14,fontFamily:"Gotham",marginLeft:"3%",textDecoration:this.state.companymenu==1?"underline":null}} onClick={this.handleCompany}>Companies <i class="fa fa-caret-down" aria-hidden="true"></i></span><br/>
        </>:<>
        <span style={{fontSize:14,fontFamily:"Gotham",marginLeft:"3%",textDecoration:this.state.companymenu==1?"underline":null}} onClick={this.handleCompanyclose}>Companies <i class="fa fa-caret-down" aria-hidden="true"></i></span><br/>
        </>}
       
        {this.state.companymenu==1?<>
           
                 
                      <Link
                    
                       
                        style={{ color: "#009345",marginLeft:"-5%",marginTop:"-3%" }}
                        to="/company"
                        onClick={()=>scrollToTop()}
                      >
                        How it Works
                      </Link>
                      <a
                       
                        target="_blank"
                        style={{ color: "#009345",marginLeft:"-5%",marginTop:"-5%" }}
                        href="https://calendly.com/gigeconome"
                       
                      >
                        Book a Demo
                      </a>
        </>:<></>}
        {this.state.educatormenu==0?<>
            <span style={{fontSize:14,fontFamily:"Gotham",marginLeft:"3%",textDecoration:this.state.educatormenu==1?"underline":null}} onClick={this.handleEducators}>Educators <i class="fa fa-caret-down" aria-hidden="true"></i></span> <br/>
        </>:<>
        <span style={{fontSize:14,fontFamily:"Gotham",marginLeft:"3%",textDecoration:this.state.educatormenu==1?"underline":null}} onClick={this.handleEducatorsclose}>Educators <i class="fa fa-caret-down" aria-hidden="true"></i></span> <br/>
        </>}
       
        {this.state.educatormenu==1?<>
            <Link
                    
                   
                    style={{ color: "#009345",marginLeft:"-5%",marginTop:"-3%" }}
                    to="/educators"
                    onClick={()=>scrollToTop()}
                  >
                    How it Works
                     </Link>
                     
                      <Link
                    
                       
                        style={{ color: "#009345",marginLeft:"-5%",marginTop:"-6%" }}
                        to="/requestademo"
                        onClick={()=>scrollToTop()}
                      >
                        Request a Demo
                      </Link>
                      <Link
                       
                        style={{ color: "#009345" ,marginLeft:"-5%",marginTop:"-6%"}}
                        to="/companysearch"
                        onClick={()=>scrollToTop()}
                      >
                        Our Employers
                      </Link>
        </>:<></>}
       <br/>
        
        </>)
    }
}