import React, { useState } from "react";
import "./modal.css";

function close(){
  window.location.reload();
}
export default class SectorModal extends React.Component {
  constructor() {
    super();
    let comments = JSON.parse(sessionStorage.getItem("someData"));
    this.state = {
      isOpen: false,

      selectOptions: [],
      id: "",
      name: "",
      addstudata : "stu_sector",
      userid: comments,
      opportunitiestypeid:[],
      opp:"",
      success:"",
    
      isChecked:true,
      Checked:true,
      dropdownlist_input: "oportunitiestypes",
      selectOpportunity:[],
      showModal: false,
      formattedString:"",
      inputdata:"opportunitiestype",
      oppid:[],

      sectorinfo: {
         None: false,
        "Solo Ventures": false,
        "Microenterprise": false,
        "Small Enterprise": false,
        "Medium Enterprise":false,
        "Large Enterprise":false,
        

      },
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state.array = [this.state.formattedString]
  
  }
 

    handleSubmit = (event) => {
    
   
const data = {

  userid: this.state.userid,
  addstudata: this.state.addstudata,
  sector:this.state.opportunitiestypeid.toString()
  
 

}

let BaseUrl = JSON.parse(sessionStorage.getItem("baseurl"));

  fetch(BaseUrl + "addstu_profiledata", {
    method: "POST",

    body: JSON.stringify(data), // data can be `string` or {object}!

    headers: { "Content-Type": "application/json" },
  })
    .then((res) => res.json())

    .catch((error) => console.error("Error:", error))

    .then((response) => {
      console.log(response)
    this.setState({success:response.message})
    window.location.reload();
      
    });
    







  
  
  }
  
componentDidMount(){
   
          



        const data_one = {

          userid: this.state.userid,
         
         
        
        }
        
        let BaseUrl = JSON.parse(sessionStorage.getItem("baseurl"));
        
          fetch(BaseUrl + "stuprofileinfo", {
            method: "POST",
        
            body: JSON.stringify(data_one), // data can be `string` or {object}!
        
            headers: { "Content-Type": "application/json" },
          })
            .then((res) => res.json())
        
            .catch((error) => console.error("Error:", error))
        
            .then((response) => {
        if(response.user.sectorinfo!==null)
          {

            
            response.user.sectorinfo.split(",").map((n) => {
                this.setState((prevState) => {
                  const sectorinfo = prevState.sectorinfo;
                  console.log("******************", sectorinfo);
                  sectorinfo[n] = true;
                  return sectorinfo;
                });
              });
            //console.log(response.user.opportunitiestype.split(","))
            //this.setState({ formattedString:response.user.opportunitiestype.split(",")})
          }
          else{
           console.log("empty")
          }
              
            });
            
        
        
        
        
        
        

}
 
  handleChange = (event) => {

    const { name, checked } = event.target;
    console.log("====>", name, checked);
    this.setState((prevState) => {
      const sectorinfo = prevState.sectorinfo;
      console.log("============>", sectorinfo);
      sectorinfo[name] = checked;
      return sectorinfo;
    });
  };
  
  openModal = () => {
    this.setState({ showModal: true });
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  render() {

    this.state.opportunitiestypeid = Object.keys(this.state.sectorinfo).filter(
      (key) => this.state.sectorinfo[key]
    );
    console.log(this.state.opportunitiestypeid.toString());
    
    return (
      <>
       {this.state.showModal && (
        <div class="ft-modal" id="ft-sector-modal">
          <div class="ft-modal-content"style={{paddingLeft:"5%"}}>
            <div class="ft-modal-header">
              <div class="header">
                <h5
                  class="modal-title"
                  id="exampleModalLabel"
                  style={{
                    textAlign: "center",
                    fontSize: 18,
                    fontWeight: "bold",
                  }}
                >
                  Preferences - Sector Types
                </h5>
                <hr style={{marginLeft:"-10.2%",marginRight:"-3%",border:"1px solid"}} />
              </div>
            </div>
          
            <div class="ft-modal-body">
            
              <h5
                style={{
                  textAlign: "center",
                  color: "rgb(0, 147, 69)",
                  fontWeight: "bold",fontSize: 18,
                }}
              >
                {" "}
                Please Select Sector
              </h5>






  <div class="row" >
   {
 
 Object.entries(this.state.sectorinfo).map((cname) => (
     <div class="col-sm-4 oppcheckbox">
       

       <div key={cname[0]}>
                <input
                  checked={cname[1]}
                  onChange={this.handleChange}
                  type="checkbox"
                  name={cname[0]}
                />
               &nbsp;&nbsp; <span style={{fontFamily:"GothamLight",fontWeight:"bold"}}>{cname[0]}</span> 
              </div>


</div> 

   ))}
 
</div>






           <div className="row"> 
           <div class="col-sm-4"></div>
                <div class="col-sm-7">
                  <button
                    style={{ backgroundColor: "rgb(0, 147, 69)", width: 100,
                  marginTop:"8%",marginLeft:"10%"
                  }}
                    className="btn btn-success"
                   onClick={this.handleSubmit}
                  >

                    Save
                  </button>
                </div>
                <div class="col-sm-1"></div>
              </div>
          
               <span style={{color:"rgb(0,147,69)",fontFamily:"Gotham",marginTop:"5%",fontWeight:"bold"}}>{this.state.success}</span>
                
                 
        
            </div>

            <div class="ft-modal-footer">
              <a class="ft-modal-close" data-dismiss="modal"
                     onClick={this.closeModal}>
                [&#10006;]{" "}
              </a>
            </div>
          </div>
        </div>
       )}
        <a
          style={{ marginLeft: 70, color: "green" }}
          href="#ft-sector-modal"
          id="co_text"
          onClick={this.openModal}
        >
          Edit
        </a>
      </>
    );
  }
}
