import React from "react";
export default class Accordion extends React.Component{
    constructor(){
        super();
        this.state={
        internship:0,
        workexperience:0,
        workplacement:0,
        gigopp:0,
        partroles:0,
        portfolio:0,
        graduate:0
        
        }
        this.handleInternship=this.handleInternship.bind(this)
        this.handleInternshipclose=this.handleInternshipclose.bind(this)
        this.handleWorkexperience=this.handleWorkexperience.bind(this)
        this.handleworkclose=this.handleworkclose.bind(this)
        this.handleWorkexperience=this.handleWorkexperience.bind(this)
        this.handleworkcloseplacement=this.handleworkcloseplacement.bind(this)
        this.handleWorkPlacement=this.handleWorkPlacement.bind(this)
        this.handleGigopp=this.handleGigopp.bind(this)
        this.handleGigoppclose=this.handleGigoppclose.bind(this)
        this.hanldlePartroles=this.hanldlePartroles.bind(this)
        this.handlePartrolesclose=this.handlePartrolesclose.bind(this)
        this.hanldlePortfolio=this.hanldlePortfolio.bind(this)
        this.handleportfolioclose=this.handleportfolioclose.bind(this)
        this.hanldleGraduate=this.hanldleGraduate.bind(this)
        this.hanldleGraduateclose=this.hanldleGraduateclose.bind(this)
    }
    hanldleGraduate(){
    this.setState({graduate:1})
    }
    hanldleGraduateclose(){
        this.setState({graduate:0})
    }
    handlePartrolesclose(){
     this.setState({portfolio:0})   
    }
    hanldlePortfolio(){
        this.setState({portfolio:1})   
       }
       handleportfolioclose(){
        this.setState({portfolio:0})   
       }
    hanldlePartroles(){
        this.setState({partroles:1})
    }
    handlePartrolesclose(){
        this.setState({partroles:0})
    }
    handleGigoppclose(){
        this.setState({gigopp:0})
    }
    handleGigopp(){
        this.setState({gigopp:1})
    }
    handleworkcloseplacement(){
        this.setState({workplacement:0})
    }
    handleWorkPlacement(){
        this.setState({workplacement:1})
    }
    handleWorkexperience(){
        this.setState({workexperience:1})
    }
    handleInternship(){
        this.setState({internship:1})
    }
    handleInternshipclose(){
        this.setState({internship:0}) 
    }
    handleworkclose(){
        this.setState({workexperience:0})
    }
    render(){
        return(<>
       <div class="accord">
        {this.state.internship==0?<>
            <div class="bg1" onClick={this.handleInternship}>
        <i style={{fontSize:30,color:"white",padding:"3%"}} className="fa fa-tasks"></i>
        <h4 className="acctext">Internship</h4>
        <i  className="fa fa-caret-down crretacc" style={{marginLeft:"30%"}}></i>
        </div>
        </>:<>
        <div class="bg1" onClick={this.handleInternshipclose}>
        <i style={{fontSize:30,color:"white",padding:"3%"}} className="fa fa-tasks"></i>
        <h4 className="acctext">Internship</h4>
        <i  className="fa fa-caret-up crretacc" style={{marginLeft:"30%"}}></i>
        </div>
        </>}
        
        {this.state.internship==1?<>
        <div class="bg2">
            <p>In an ever-competitive marketplace, secure the best internship
                opportunities and gain real industry and valuable hands-on
                experience across all sectors and geographical locations.</p>
        </div>
        </>:null}
        
        {this.state.workexperience==0?<>
            <div class="bg1" onClick={this.handleWorkexperience}>
        <i style={{fontSize:30,color:"white",padding:"3%"}} className="fa fa-id-card-o"></i>
        <h4 className="acctext" style={{marginLeft:"5%"}}>Work Experience</h4>
        <i  className="fa fa-caret-down crretacc" style={{marginLeft:"23%"}}></i>
        </div>
        </>:<>
        <div class="bg1" onClick={this.handleworkclose}>
        <i style={{fontSize:30,color:"white",padding:"3%"}} className="fa fa-id-card-o"></i>
        <h4 className="acctext" style={{marginLeft:"5%"}} >Work Experience</h4>
        <i  className="fa fa-caret-up crretacc" style={{marginLeft:"23%"}}></i>
        </div>
        </>}
        
        {this.state.workexperience==1?<>
        <div class="bg2">
            <p> Access to both newly formed Startups and Large enterprises,
                provides the perfect opportunity to acquire new skills and build
                experience either through voluntary or paid positions.</p>
        </div>
        </>:null}
        {this.state.workplacement==0?<>
            <div class="bg1" onClick={this.handleWorkPlacement}>
        <i style={{fontSize:30,color:"white",padding:"3%"}} className="fa fa-american-sign-language-interpreting"></i>
        <h4 className="acctext" style={{marginLeft:"5.5%"}}>Work Placement</h4>
        <i  className="fa fa-caret-down crretacc" style={{marginLeft:"23%"}}></i>
        </div>
        </>:<>
        <div class="bg1" onClick={this.handleworkcloseplacement}>
        <i style={{fontSize:30,color:"white",padding:"3%"}} className="fa fa-american-sign-language-interpreting"></i>
        <h4 className="acctext" style={{marginLeft:"5.5%"}} >Work Placement</h4>
        <i  className="fa fa-caret-up crretacc" style={{marginLeft:"23%"}}></i>
        </div>
        </>}
        
        {this.state.workplacement==1?<>
        <div class="bg2">
            <p>   Gain significant on-the-job experience as well as apply your
                knowledge and skills in a work setting of your chosen line of
                study or industry and increase competencies sought after by
                employers.</p>
        </div>
        </>:null}

        {this.state.gigopp==0?<>
            <div class="bg1" onClick={this.handleGigopp}>
        <i style={{fontSize:30,color:"white",padding:"3%"}} className="fa fa-envelope-open"></i>
        <h4 className="acctext" style={{marginLeft:"6.5%"}}>Gig Opportunities</h4>
        <i  className="fa fa-caret-down crretacc" style={{marginLeft:"22%"}}></i>
        </div>
        </>:<>
        <div class="bg1" onClick={this.handleGigoppclose}>
        <i style={{fontSize:30,color:"white",padding:"3%"}} className="fa fa-envelope-open"></i>
        <h4 className="acctext" style={{marginLeft:"6.5%"}} >Gig Opportunities</h4>
        <i  className="fa fa-caret-up crretacc" style={{marginLeft:"22%"}}></i>
        </div>
        </>}
        
        {this.state.gigopp==1?<>
        <div class="bg2">
            <p>Wide range of flexible Gig opportunities to make the most of
                spare times, earn money and gain valuable real-life experiences
                by securing freelance works or short-term day-rate contracts.</p>
        </div>
        </>:null}

        {this.state.partroles==0?<>
            <div class="bg1" onClick={this.hanldlePartroles}>
        <i style={{fontSize:30,color:"white",padding:"3%"}} className="fa fa-clock-o"></i>
        <h4 className="acctext" style={{marginLeft:"9.5%"}}>Part Time Roles</h4>
        <i  className="fa fa-caret-down crretacc" style={{marginLeft:"25%"}}></i>
        </div>
        </>:<>
        <div class="bg1" onClick={this.handlePartrolesclose}>
        <i style={{fontSize:30,color:"white",padding:"3%"}} className="fa fa-clock-o"></i>
        <h4 className="acctext" style={{marginLeft:"9.5%"}} >Part Time Roles</h4>
        <i  className="fa fa-caret-up crretacc" style={{marginLeft:"25%"}}></i>
        </div>
        </>}
        
        {this.state.partroles==1?<>
        <div class="bg2">
            <p>GigeconoMe provides you the opportunity to not only gain
                financial protection during your studies but also gain a variety
                of employability find part-time opportunities directly relevant
                to definite career plans.</p>
        </div>
        </>:null}
        {this.state.portfolio==0?<>
            <div class="bg1" onClick={this.hanldlePortfolio}>
        <i style={{fontSize:30,color:"white",padding:"3%"}} className="fa fa-folder-open"></i>
        <h4 className="acctext" style={{marginLeft:"5.5%"}}>Portfolio Building</h4>
        <i  className="fa fa-caret-down crretacc" style={{marginLeft:"23%"}}></i>
        </div>
        </>:<>
        <div class="bg1" onClick={this.handleportfolioclose}>
        <i style={{fontSize:30,color:"white",padding:"3%"}} className="fa fa-folder-open"></i>
        <h4 className="acctext" style={{marginLeft:"5.5%"}} >Portfolio Building</h4>
        <i  className="fa fa-caret-up crretacc" style={{marginLeft:"23%"}}></i>
        </div>
        </>}
        
        {this.state.portfolio==1?<>
        <div class="bg2">
            <p> Impress future employers and build an impressive portfolio of work while studying to showcase learning growth as well as demonstrate your creativity, skills and ability.</p>
        </div>
        </>:null}

        {this.state.graduate==0?<>
            <div class="bg1" onClick={this.hanldleGraduate}>
        <i style={{fontSize:30,color:"white",padding:"3%"}} className="fa fa-lightbulb-o"></i>
        <h4 className="acctext" style={{marginLeft:"2.5%"}}>Graduate Opportunities</h4>
        <i  className="fa fa-caret-down crretacc" style={{marginLeft:"18%"}}></i>
        </div>
        </>:<>
        <div class="bg1" onClick={this.hanldleGraduateclose}>
        <i style={{fontSize:30,color:"white",padding:"3%"}} className="fa fa-lightbulb-o"></i>
        <h4 className="acctext" style={{marginLeft:"2.5%"}} >Graduate Opportunities</h4>
        <i  className="fa fa-caret-up crretacc" style={{marginLeft:"18%"}}></i>
        </div>
        </>}
        
        {this.state.graduate==1?<>
        <div class="bg2">
            <p> Impress future employers and build an impressive portfolio of work while studying to showcase learning growth as well as demonstrate your creativity, skills and ability.</p>
        </div>
        </>:null}
        </div>
        
        </>)
    }
}