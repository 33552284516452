import React from "react";
import { Link } from "react-router-dom";
let logout = () => {
    window.sessionStorage.removeItem("someData");
    window.sessionStorage.removeItem("nameData");
    window.sessionStorage.removeItem("DataName");
    window.sessionStorage.removeItem("UserType");
    window.location.href=`./home`
  };
export default class Studashboardmenu extends React.Component{
    constructor(){
        super();
        this.state={
            profile:0,

        }
        this.handleProfile=this.handleProfile.bind(this)
        this.handleProfileclose=this.handleProfileclose.bind(this)
    }
    handleProfileclose(){
        this.setState({profile:0})
    }
    handleProfile(){
        this.setState({profile:1})
      
    }
    render(){
        return(<>
        <div style={{textAlign:'center'}}>
        <div class="dropdown">
           {this.state.profile==0?<>
            {JSON.parse(sessionStorage.getItem("UserType"))=="Company"||JSON.parse(sessionStorage.getItem("UserType"))=="Employee"?<>
            <Link to="/companyprofile"
        onClick={this.handleProfile} 
           style={{fontFamily:"GothamLight",fontWeight:"bold",color:"black"}}  id="c_text">Profile
           <span style={{color:"black"}}><i className="fa fa-caret-down"></i></span>
           </Link>
            </>:<>
            <Link to="/studentdashboard"
        onClick={this.handleProfile} 
           style={{fontFamily:"GothamLight",fontWeight:"bold",color:"black"}}  id="c_text">Profile
           <span style={{color:"black"}}><i className="fa fa-caret-down"></i></span>
           </Link>
            </>}
            
           </>:<>
           {JSON.parse(sessionStorage.getItem("UserType"))=="Company"||JSON.parse(sessionStorage.getItem("UserType"))=="Employee"?<>
           <Link to="/companyprofile"
        onClick={this.handleProfileclose} 
           style={{fontFamily:"GothamLight",fontWeight:"bold",color:"black"}}  id="c_text">Profile
           <span style={{color:"black"}}><i className="fa fa-caret-down"></i></span>
           </Link>
           </>:<>
           <Link to="/studentdashboard"
        onClick={this.handleProfileclose} 
           style={{fontFamily:"GothamLight",fontWeight:"bold",color:"black"}}  id="c_text">Profile
           <span style={{color:"black"}}><i className="fa fa-caret-down"></i></span>
           </Link>
           </>}

           </>} 
       
           <div class={this.state.profile==1?"dropdown-contentdashboardmenu":"dropdown-contentt"}>
            <div className="studashboardmenus">
            {JSON.parse(sessionStorage.getItem("UserType"))=="Company"||JSON.parse(sessionStorage.getItem("UserType"))=="Employee"?<>
               

               <Link to="/companyprofile" style={{color:"rgb(0,147,69)",fontFamily:"GothamLight",fontWeight:"bold"}}>My Profile</Link><br/>
                       <Link to="/companyseconddashboard" style={{color:"rgb(0,147,69)",fontFamily:"GothamLight",fontWeight:"bold"}}>Company</Link><br/>
                       <Link to="/companyprofile" style={{color:"rgb(0,147,69)",fontFamily:"GothamLight",fontWeight:"bold"}}>Opportunities </Link><br/>
                       <Link to="/companyprofile" style={{color:"rgb(0,147,69)",fontFamily:"GothamLight",fontWeight:"bold"}}>Messages</Link><br/>
                       <Link to="/companyprofile" style={{color:"rgb(0,147,69)",fontFamily:"GothamLight",fontWeight:"bold"}}>Analytics</Link><br/>
                       <Link to="/companyprofile" style={{color:"rgb(0,147,69)",fontFamily:"GothamLight",fontWeight:"bold"}}>Community</Link><br/>
                       <Link to="/companyprofile" style={{color:"rgb(0,147,69)",fontFamily:"GothamLight",fontWeight:"bold"}}>Settings</Link><br/>
                       <Link to="/postaproject" style={{color:"rgb(0,147,69)",fontFamily:"GothamLight",fontWeight:"bold"}}>Post a Project</Link><br/>
                       <Link to="/" onClick={()=>logout()} style={{color:"rgb(0,147,69)",fontFamily:"GothamLight",fontWeight:"bold"}}>Sign Out</Link><br/>
                            </>:<> <Link to="/studentdashboard" style={{color:"rgb(0,147,69)",fontFamily:"GothamLight",fontWeight:"bold"}}>My Profile</Link><br/>
           <Link to="/studentopportunity" style={{color:"rgb(0,147,69)",fontFamily:"GothamLight",fontWeight:"bold"}}>Opportunities</Link><br/>
           <Link to="/studentmessage" style={{color:"rgb(0,147,69)",fontFamily:"GothamLight",fontWeight:"bold"}}>Message</Link><br/>
           <Link to="/studentdashboard" style={{color:"rgb(0,147,69)",fontFamily:"GothamLight",fontWeight:"bold"}}>Analytics</Link><br/>
           <Link to="/studentdashboard" style={{color:"rgb(0,147,69)",fontFamily:"GothamLight",fontWeight:"bold"}}>Community</Link><br/>
           <Link to="/studentsetting" style={{color:"rgb(0,147,69)",fontFamily:"GothamLight",fontWeight:"bold"}}>Settings</Link><br/>
           <Link to="/" onClick={()=>logout()} style={{color:"rgb(0,147,69)",fontFamily:"GothamLight",fontWeight:"bold"}}>Sign Out</Link></>}
          
            </div>
            </div>
        </div>
          
          
        </div>
        </>)
    }
}