import React, { useState } from "react";
import "./modal.css";
function close(){
  window.location.reload();
}
export default class AskforRecomm extends React.Component {
  constructor() {
    super();
    let comments = JSON.parse(sessionStorage.getItem("someData"));
   
   console.log(comments)
    this.state = {
      
    
     
     
      userid: comments,
      addstudata :"availability",
     
      
      availablematchcomp:"",
      availability:"",
      geomessage:"",
      showModal: false,

  
  }
  this.handleSubmit = this.handleSubmit.bind(this);
}
handleSubmit = (event) => {
  event.preventDefault()
 
  const data = {

    userid: this.state.userid,
    addstudata: this.state.addstudata,
    availablematchcomp:this.state.availablematchcomp,
    availability:this.state.availability,
   
   

  }

  let BaseUrl = JSON.parse(sessionStorage.getItem("baseurl"));

    fetch( "https://www.gigeconome.com:5200/api/addstu_profiledata", {
      method: "POST",

      body: JSON.stringify(data), // data can be `string` or {object}!

      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())

      .catch((error) => console.error("Error:", error))

      .then((response) => {
          this.setState({geomessage:response.message})
        window.location.reload();
       console.log(response)
        
      });
  
}
handleChange = (e) => {
  this.setState({
    [e.target.name]: e.target.value,
   
  });
  
};



componentDidMount(){
  const data_two={userid:this.state.userid}
  fetch('https://www.gigeconome.com:5200/api/stuprofileinfo', { method: 'POST', 

  body: JSON.stringify(data_two), // data can be `string` or {object}!

  headers:{ 'Content-Type': 'application/json' } })

  .then(res => res.json())

  .catch(error => console.error('Error:', error))

  .then((json)=>{
  

  
          this.setState({
            availablematchcomp:json.user.availablematchcomp,
            availability:json.user.availability
          });
         
      })
    
}

  
openModal = () => {
  this.setState({ showModal: true });
};

closeModal = () => {
  this.setState({ showModal: false });
};
  render(){
const {geomessage}=this.state;
  return (
    <>
     {this.state.showModal && (
      <div class="ft-modal" id="ft-ava-modal">
        <div class="ft-modal-content  bigmodalcontent">
          <div class="ft-modal-header">
            <div class="header">
            <h5
                  class="modal-title"
                  id="exampleModalLabel"
                  style={{
                    textAlign: "center",
                    fontSize: 25,
                    fontWeight: "bold",
                  }}
                >
                  Availability
                </h5>
              <hr style={{marginRight:"-6%",marginLeft:"-4.2%",height:2}} />
            </div>
          </div>
          <div class="ft-modal-body">
         
          <form onSubmit={this.handleSubmit} style={{fontSize:15}}>
          

                  
          <div class="row mt-4">
                <div class="col-sm-1"></div>
                <div class="col-sm-10">
                 <h5 className="modaltext"> Available to be matched to Companies?</h5> 
                 <select  class="form-select mt-2" name ="availablematchcomp" 
                            onChange = {this.handleChange} >
  
                   <option>{this.state.availablematchcomp}</option>
                    <option>Yes</option>
                    <option>No</option>
                 </select>

                </div>
                <div class="col-sm-1"></div>
              </div>
 
              <div class="row mt-4">
                <div class="col-sm-1"></div>
                <div class="col-sm-10">
                 <h5 className="modaltext"> Select Your Availability</h5> 
                 <select  class="form-select mt-2" name ="availability" 
                            onChange = {this.handleChange}>
                 
                 <option>{this.state.availability}</option>
                  <option>Less than 1 week</option>
                  <option>Immediate</option>
                  <option>2 Weeks</option>
                  <option>4 Weeks</option>
                  <option>1 - 2 weeks</option>
                  <option>3 - 4 weeks</option>
                  <option>5 weeks onwards</option>
                  <option>Longer Notice</option>
                 </select>

                </div>
                <div class="col-sm-1"></div>
              </div>
                <div className="row mt-5">
                    <div class="col-sm-3">
                      </div>
                      <div class="col-sm-2">
                        <button className="btn btn-dark" onClick={()=>close()} style={{width:100,marginLeft:"10%"}}>
                        
                        Cancel
                       
                        </button>
                      </div>
                      <div class="col-sm-1"></div>
                      <div class="col-sm-3" >
                      <button className="btn btn-success profileeditstu" type="submit" style={{backgroundColor:'rgb(0, 147, 69)',width:100}} >
                       
                        Confirm
                        </button>
                      </div>
                </div>
              
               
                <div class="row mt-5" >
                <div class="col-sm-1"></div>
                <div class="col-sm-10">
                <h6 className="modaltext" style={{fontSize:17.2,color:'rgb(0, 147, 69)'}} > Each action on your profile confirms availability for the next 7 days</h6>
                

                </div>
                <div class="col-sm-1"></div>
              </div>
              <div class="mt-4">
                <div class="col-sm-4"></div>
                <div class="col-sm-7">
                
                </div>
                <div class="col-sm-1"></div>
              </div>
              </form>
              <span style={{color:"rgb(0,147,69)",fontSize:15,marginTop:"5%",fontWeight:"bold"}}>{geomessage}</span>
          </div>
          <div class="ft-modal-footer">
            <a class="ft-modal-close"  data-dismiss="modal"
                     onClick={this.closeModal}>
              [&#10006;]{" "}
            </a>
          </div>
        </div>
      </div>
     )}
     
      <a
                          className="btn btn-success mt-2"
                          id="avaliable_btntwo"
                          style={{marginLeft:"1%"}}
                          href="#ft-ava-modal"
                          onClick={this.openModal}
                        >
                          Edit Availability
                        </a>
    </>
  );
}
}