import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import LinkedIN from "../../../images/company1.png";
import Instagram from "../../../images/insta.png";
import Twitter from "../../../images/twitter.png";
import Facebook1 from "../../../images/fb.png";
import GraduationYear from "../../Signup/GraduationYear";
import "./modal.css";

function close(){
  window.location.reload();
}
export default class  Postaprojectmodal extends React.Component {
  constructor() {
    super();
  let comments = JSON.parse(sessionStorage.getItem('someData'));
  this.textInput = React.createRef();
  this.state = {
    
      userid:comments,
      FirstName:"",
      LastName:"",
      Studystatus:"",
      selectGraduation:[],
      GraduationYear:"",
      subCourseName:[],
      Course:"",
      selectUniversity:[],
      firstname:"",
      lastname:"",
      StudyStatus:"",
      graduationyear:"",
      Degree:"",
      university:"",
      locationname:"",
      instagramlink:"",
      linkedinlink:"",
      facebooklink:"",
      twitterlink:"",
      value: '',
      college_University:"",
      showModal: false,
      linkedinmessage:"",
      instagrammessage:"",
      twittermessage:"",
      facebookmessage:"",
      sminputdata:"",
      selectCountry:"",
      location:"",
      Coursename:"",
      success:"",
      addskillsname:""




      };
    this.handleSubmit = this.handleSubmit.bind(this);
   
   
  }

  openModal = () => {
    this.setState({ showModal: true });
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  componentDidMount(){
 
    const data_one={userid:this.state.userid,
    
    }
  
    let BaseUrl = JSON.parse(sessionStorage.getItem('baseurl'));
  
    fetch(BaseUrl + 'stuprofileinfo', { method: 'POST', 
  
     body: JSON.stringify(data_one), // data can be `string` or {object}!
  
     headers:{ 'Content-Type': 'application/json' } })
  
     .then(res => res.json())
  
     .catch(error => console.error('Error:', error))
  
     .then((json)=>{
      
     
    this.setState({
      firstname:json.user.firstname,
      lastname:json.user.lastname,
      StudyStatus:json.user.StudyStatus,
      GraduationYear:json.user.GraduationYear,
      Course:json.user.Course,
      college_University:json.user.college_University,
      locationname:json.user.locationname,
      instagramlink:json.user.instagramlink,
      linkedinlink:json.user.linkedinlink,
      facebooklink:json.user.facebooklink,
      twitterlink:json.user.twitterlink,
      Coursename:json.user.Coursename,
      location:json.user.location
    })
     })
     const data_graduation={dropdownlist_input: "graduationyear"}
     fetch( 'https://www.gigeconome.com:5200/api/getdropdownlist', { method: 'POST', 
  
     body: JSON.stringify(data_graduation), // data can be `string` or {object}!
  
     headers:{ 'Content-Type': 'application/json' } })
  
     .then(res => res.json())
  
     .catch(error => console.error('Error:', error))
  
     .then((json)=>{
      
      
      const data = json.result
    
      this.setState({selectGraduation: data})
      this.setState({
        DataisLoaded: true 
      })
     })

     const country_data={dropdownlist_input: "country"}
     fetch( 'https://www.gigeconome.com:5200/api/getdropdownlist', { method: 'POST', 
  
     body: JSON.stringify(country_data), // data can be `string` or {object}!
  
     headers:{ 'Content-Type': 'application/json' } })
  
     .then(res => res.json())
  
     .catch(error => console.error('Error:', error))
  
     .then((json)=>{
      
      
      const data = json.result
    
      this.setState({selectCountry: data})
      
     })
     const data_six={dropdownlist_input:"coursenew"}
     fetch( 'https://www.gigeconome.com:5200/api/getdropdownlist', { method: 'POST', 
  
     body: JSON.stringify(data_six), // data can be `string` or {object}!
  
     headers:{ 'Content-Type': 'application/json' } })
  
     .then(res => res.json())
  
     .catch(error => console.error('Error:', error))
  
     .then((json)=>{
      
     let  data=json.result
    this.setState({subCourseName:data})
      
      
    
     })

     const data_seven={dropdownlist_input:"university"}
     fetch( 'https://www.gigeconome.com:5200/api/getdropdownlist', { method: 'POST', 
  
     body: JSON.stringify(data_seven), // data can be `string` or {object}!
  
     headers:{ 'Content-Type': 'application/json' } })
  
     .then(res => res.json())
  
     .catch(error => console.error('Error:', error))
  
     .then((json)=>{
      
     let  data=json.result
    this.setState({selectUniversity:data})
      
      
    
     })
    }
    handleLinkedin = (event) => {
      event.preventDefault()
     
     
  const LinkedINdata = {
  
    addstudata:"stusocialmedia",
    sminputdata:"linkedinlink",
    linkedinlink:this.state.linkedinlink,
  userid:this.state.userid
   
    
   
  
  }
  
  let BaseUrl = JSON.parse(sessionStorage.getItem("baseurl"));
  
    fetch(BaseUrl + "addstu_profiledata", {
      method: "POST",
  
      body: JSON.stringify(LinkedINdata), // data can be `string` or {object}!
  
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
  
      .catch((error) => console.error("Error:", error))
  
      .then((response) => {
        
      this.setState({linkedinmessage:response.message})
         
        
      });
      
    }

    handleInstagram = (event) => {
      event.preventDefault()
     
     
  const Instagramdata = {
  
    addstudata:"stusocialmedia",
    sminputdata:"instagramlink",
    instagramlink:this.state.instagramlink,
    userid:this.state.userid
    
    
   
  
  }
  
  let BaseUrl = JSON.parse(sessionStorage.getItem("baseurl"));
  
    fetch(BaseUrl + "addstu_profiledata", {
      method: "POST",
  
      body: JSON.stringify(Instagramdata), // data can be `string` or {object}!
  
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
  
      .catch((error) => console.error("Error:", error))
  
      .then((response) => {
        
      this.setState({instagrammessage:response.message})
         
        
      });
      
    }

    handleTwitter = (event) => {
      event.preventDefault()
     
     
  const twitterdata = {
  
    addstudata:"stusocialmedia",
    sminputdata:"twitterlink",
    twitterlink:this.state.twitterlink,
    userid:this.state.userid
    
    
   
  
  }
  
  let BaseUrl = JSON.parse(sessionStorage.getItem("baseurl"));
  
    fetch(BaseUrl + "addstu_profiledata", {
      method: "POST",
  
      body: JSON.stringify(twitterdata), // data can be `string` or {object}!
  
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
  
      .catch((error) => console.error("Error:", error))
  
      .then((response) => {
        
      this.setState({twittermessage:response.message})
         
        
      });
      
    }
    handleFacebook = (event) => {
      event.preventDefault()
     
     
  const facebookdata = {
  
    addstudata:"stusocialmedia",
    sminputdata:"facebooklink",
    facebooklink:this.state.twitterlink,
    userid:this.state.userid
    
    
   
  
  }
  
  let BaseUrl = JSON.parse(sessionStorage.getItem("baseurl"));
  
    fetch(BaseUrl + "addstu_profiledata", {
      method: "POST",
  
      body: JSON.stringify(facebookdata), // data can be `string` or {object}!
  
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
  
      .catch((error) => console.error("Error:", error))
  
      .then((response) => {
       
      this.setState({facebookmessage:response.message})
         
        
      });
      
    }

    handleSubmit = (event) => {
      event.preventDefault()
     
     
  const data = {
  

  userid:57,
  addcpydata:"cpyaddnewskillsinfo",
  addskillsname:this.state.addskillsname
   
    
   
  
  }
  console.log(data)
  let BaseUrl = JSON.parse(sessionStorage.getItem("baseurl"));
  
    fetch(BaseUrl + "addcpy_profiledata", {
      method: "POST",
  
      body: JSON.stringify(data), // data can be `string` or {object}!
  
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
  
      .catch((error) => console.error("Error:", error))
  
      .then((response) => {
        //console.log(response)
        this.setState({success:response.message})
        if(response.message=="Data has been Added successfully."){
          const skilldata = { dropdownlist_input: "postaprojectskills" };

          fetch("https://www.gigeconome.com:5200/api/getdropdownlist", {
            method: "POST",

            body: JSON.stringify(skilldata), // data can be `string` or {object}!

            headers: { "Content-Type": "application/json" },
          })
            .then((res) => res.json())

            .catch((error) => console.error("Error:", error))

            .then((json) => {
              const data = json.result;
            // console.log(data)
              this.setState({skillData:data})
            });

        }
     else{
       console.log("empty")
     }
       
        
      });
      
    }
    handleChange = (e) => {
      this.setState({
        [e.target.name]: e.target.value,
       
      });
     
      
    };
render(){
  return (
    <>
     {this.state.showModal && (
      <div class="ft-modal" id="ft-postaskills-modal">
        <div class="ft-modal-content" style={{width:"50%"}}>
          <div class="ft-modal-header">
            <div class="header">
              <h5
                class="modal-title"
                id="exampleModalLabel"
                style={{
                  textAlign: "center",
                  fontSize: 25,
                  fontWeight: "bold",
                  fontFamily:"Gotham"
                }}
              >
                Please Add Other Skills
              </h5>
              <hr />
            </div>
          </div>
          <div class="ft-modal-body">
         <div className="row">
             <div className="col-sm-2">

             </div>
             <div className="col-sm-8 mt-4">
                <label style={{fontSize:18}}>Please Add Other Skills</label> <br/>
                <input type="text" 
                name="addskillsname"
                onChange={this.handleChange}
                className="form-control mt-2" style={{border:"1px solid rgb(0,147,69)"}} />
            </div>
            <div className="col-sm-2">

                </div>
         </div>
            
          
           
           
           
            <div class="modal-footer mt-4">
              <div class="col-sm-4"></div>
              <div class="col-sm-7">
                <button
                  style={{ backgroundColor: "rgb(0, 147, 69)", width: 100 }}
                  className="btn btn-success"
                  onClick={this.handleSubmit}
                >
                  Add
                </button><br/><br/>
                <span style={{color:"rgb(0,147,69)",fontFamily:"Gotham",marginTop:"5%",fontWeight:"bold",marginLeft:"-16%"}}>{this.state.success}</span>
              </div>
              <div class="col-sm-1"></div>
             
            </div>
      
          </div>
          <div>
            <a class="ft-modal-close" data-dismiss="modal"
            style={{cursor:"pointer"}}
                     onClick={this.closeModal}>
              [&#10006;]{" "}
            </a>
          </div>
        </div>
      </div>
     )}
      <a  className="btn btn-success"  
       onClick={this.openModal} 
      id="addaskillbtn"
       href="#ft-postaskills-modal">
        Please Add Other Skills
      </a>
    </>
  );
}
}