import React from "react";
import Logo from "../../images/logo.png";
import Login1 from '../../images/Login-28 - Copy.png'
import Login2 from '../../images/Login-29.png';
import Login3 from '../../images/Login-30.png';
import Login4 from '../../images/Login-31.png';
import Login5 from '../../images/Login-36.png';
import { Link } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import axios from 'axios';
import swal from 'sweetalert';
const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth' // for smoothly scrolling
  });
  sessionStorage.removeItem("SearchText");
  sessionStorage.setItem("SearchText","");
};
class EmpTestSignin extends React.Component {




  
  constructor(props) {
    let comments = JSON.parse(sessionStorage.getItem('someData'));
    let IpAddress = JSON.parse(sessionStorage.getItem('IP Address'));
    let Autoid=sessionStorage.getItem("AutoID")
    let Auto_id=Autoid.replace(/^"(.*)"$/, '$1')
    //console.log(IpAddress)
    super(props);
    
    this.state = {
      email: "",
      password: "",
      userid:comments,
     success:"",
     error:"",
     isBack: "./Studentdashboard",
     ipaddressid:"",
     autogeneratedid:Auto_id 
     
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onPopstate = this.onPopstate.bind(this)
  }
  onPopstate() {
    this.setState({ isBack: "./Studentdashboard" });
   
  
    
  }

  componentDidMount() {
    window.addEventListener("popstate", this.onPopstate);
    
  }

  componentWillUnmount() {
    window.removeEventListener("popstate", this.onPopstate, false);
  }
  handleSubmit(event) {
    event.preventDefault();
    if (this.state.email === "") {
      swal("Plese Fill !");
    }
    else{
      const data_one={userid:this.state.userid}
    const data = {
      email:this.state.email , 
      ipaddressid:this.state.ipaddressid,
     password:this.state.password,
    autogeneratedid:this.state.autogeneratedid }
console.log(data)
     let BaseUrl = JSON.parse(sessionStorage.getItem('baseurl'));
     fetch("https://www.gigeconome.com:5200/api/login", { method: 'POST',  

    body: JSON.stringify(data), // data can be `string` or {object}!

    headers:{ 'Content-Type': 'application/json' } })

    .then(res => res.json())

    .catch(error => console.error('Error:', error))

    .then((response)=>{
        console.log('Success:', response)
      
        if (response.message=="Login Success"){
          this.setState({success:"Login Success"})
        }
        else{
          this.setState({error:response.message})
        }
        var dataStore = JSON.stringify(response.user.name );
        sessionStorage.setItem('nameData', dataStore);
       var  UserId=JSON.stringify(response.user.id );
        sessionStorage.setItem('someData', UserId);
       
        if(response.user.usertype=="Company" ||response.user.usertype=="Employee" ){
         
          sessionStorage.setItem('UserType', JSON.stringify(response.user.usertype ));
          window.location.href=`./CompanyProfile`
        }
        if(response.user.usertype=="Student"){
         
         
         
          const retakedata = { userid: response.user.id ,
 
          };
          fetch("https://www.gigeconome.com:5200/api/getstEOAdaysvalidinfo", {
            method: "POST",
          
            body: JSON.stringify(retakedata), // data can be `string` or {object}!
          
            headers: { "Content-Type": "application/json" },
          })
            .then((res) => res.json())
          
            .catch((error) => console.error("Error:", error))
          
            .then((response) => {
              console.log(response)
            if(response.validdays==90 || response.validdays>=90){
              
              window.location.href=`./emptestdescription`
            }
            else if(response.validdays<90){
              
              window.location.href=`./retakeemptest`
            }
            else{
                console.log("error")
            }
            })
        }
       
         
           
    })
   
  
  }
        
    
       
    
   }
   handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }
  

    componentDidMount(){
     
       {/*Ip Address Api Call */}
const fetchPromise = fetch('https://geolocation-db.com/json/');

fetchPromise.then( response => {
  const jsonPromise = response.json();
  jsonPromise.then( json => {
    console.log(json.IPv4);
    this.setState({ipaddressid:json.IPv4})
  });
});
          if (this.state.isBack=="./Studentdashboard"){
            //window.location.href=`./signin`
          }
         
        
      
     
    }
  




  

 
  
  render() {

   const {error}=this.state
    
    return (
      <div>
         {/* ======= Header ======= */}
      <Header />
      <div>
    

      </div>
      {/* End Header */}
        <main id="main" style={{ background: "#303030", clear: "both" }}>
          <br />
          <br />
          {/* ======= About Section ======= */}
          <section id="clients" className="clients">
            <div
              className="container col-md-5 col-xs-12 col-sm-12 col-lg-6"
              data-aos="fade-up"
            >
              <style
                type="text/css"
                dangerouslySetInnerHTML={{
                  __html:
                    "\n    .boxxlr{\n     border: 0px solid green;\n    background: #edf8f2;\n    margin-right: 2.5%;\n    margin-bottom: 7%;\n    color: #000;\n    margin-left: 9%;\n    width: 100%;\n    }\n/*.boxxlr:hover {\n    border: 0px solid green;\n    background: green;\n    margin-right: 1%;\n    margin-bottom: 1%;\n     color: #fff;\n}*/\n\n.boxclrtitle {\nfont-size: 12px;font-weight: bold;\n}\n\n.boxclrtitle1 {\nfont-size: 18px;font-weight: bold;\n}\n/*.boxxlr .boxclrtitle:hover {\nfont-size: 12px;font-weight: bold;\n}*/\n\n\n  ",
                }}
              />
              <div className="row no-gutters">
                <div className="content col-xl-12 d-flex align-items-center">
                  <div className="content col-xl-12 d-flex align-items-center">
                    <div
                      className="row"
                      style={{
                        display: "flex",
                        justifyContent: "center" /* alignItems: 'center', */,
                        background: "#fff",
                        width: "100%" /* marginLeft: '50%', */,
                        borderRadius: "20px",
                        margin: "auto",
                      }}
                    >
                      {/*     <h2 style="font-size: 20px;letter-spacing: 6px;text-align: center;color: #000;
    font-weight: 600; color: #fff;background: green; padding: 1%; width: 70%;margin: 6%;"> Create an Account </h2> */}
                      <div className="row h-100 row align-items-center">
                        <div
                          className="col-md-10"
                          style={{ margin: "auto", marginBottom: "6%" }}
                        >
                          <form onSubmit={this.handleSubmit}>
                            <img
                              src={Login5}
                              style={{
                                marginLeft: "40%",
                                marginBottom: "2%",
                                marginTop: "5%",
                              }}
                            />
                            <h4 style={{ textAlign: "center" }}>
                              Sign in to GigeconoMe
                            </h4>
                            <div
                              className="form-group"
                              style={{ padding: "2%", borderRadius: "1px" }}
                            >
                              <input
                             
                              
                                style={{ padding: "2%" }}
                                type="email"
                                className="form-control"
                                placeholder="Email id"
                                
                                 
                                autoComplete="off"
                                name="email"
                                onChange={this.handleChange}
                                 
                              />
                            </div>
                            <div
                              className="form-group"
                              style={{ padding: "2%", borderRadius: "1px" }}
                            >
                              <input
                              
                                style={{ padding: "2%" }}
                                type="password"
                                className="form-control"
                                placeholder="password"
                                
                                name="password"
                                required
                                onChange={this.handleChange}
                               
                               
                              />
                            </div>
                            <Link
                              to='/reset'
                              className="text-body"
                              style={{
                                float: "right",
                                color: "#009345 !important",
                                fontSize: "14px",
                              }}
                            >
                              Forgot password?
                            </Link>
                            <span style={{marginLeft:"2%",
                            fontWeight:"bold",color:"red"}}>
                              {error}
                            </span>
                            <button
                           
                              className="signinwithemail"
                             
                              //onClick={this.signin}
                              type="submit"
                            >
                              Sign in with Email
                            </button>
                          </form>
                          <div
                            style={{
                              float: "left",
                              width: "45%",
                              marginRight: "3%",
                            }}
                          >
                            <hr />
                          </div>
                          <div style={{ float: "right", width: "44%" }}>
                            <hr />
                          </div>
                          Or
                          <h6 style={{ textAlign: "center", fontFamily:"Gotham", marginTop: "2%" }}>
                            With Your Social Network
                          </h6>
                          <div className="row mobileiconone" style={{ margin: "5%" }}>
                            <div className="box col">
                              <img
                                className="snalgn"
                                src={Login1}
                              />
                            </div>
                            <div className="box col">
                              <img
                                className="snalgn"
                                src={Login2}
                              />
                            </div>
                            <div className="box col">
                              <img
                                className="snalgn"
                                src={Login3}
                              />
                            </div>
                            <div className="box col">
                              <img
                                className="snalgn"
                                src={Login4}
                              />
                            </div>
                          </div>
                          <div className="row mobileicon" style={{marginTop:"-26%"}} >
                            <div className="col-sm" style={{display:"contents"}} ><img
                                className="snalgn"
                                src={Login1}
                                style={{marginLeft:"3%"}}
                              /></div>
 <div className="col-sm" style={{display:"contents"}} ><img
                                className="snalgn"
                                src={Login2}
                                style={{marginLeft:"3%"}}
                              /></div>
                               <div className="col-sm"style={{display:"contents"}} ><img
                                className="snalgn"
                                src={Login3}
                                style={{marginLeft:"3%"}}
                              /></div>
                               <div className="col-sm" style={{display:"contents"}}><img
                                className="snalgn"
                                src={Login4}
                                style={{marginLeft:"3%"}}
                              /></div>
                          </div>
                          <h5 style={{ textAlign: "center" }}>
                            Don't have an account?
                          </h5>
                          <h6 style={{ textAlign: "center", color: "rgb(0,147,69)" }}>
                            <Link   onClick={()=>scrollToTop()} to="/studentsignup_one" style={{ textAlign: "center", color: "rgb(0,147,69)" }}>Join as student</Link>
                            <span style={{ color: "transparent" }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </h6>
                          <h6 style={{ textAlign: "center", color: "rgb(0,147,69)" }}>
                            <Link   onClick={()=>scrollToTop()} to="/companysignup" style={{ textAlign: "center", color: "rgb(0,147,69)" ,marginLeft:"-2%"}}>Join as a Employer or startup</Link>
                          </h6>
                          <h6 style={{ textAlign: "center", color: "rgb(0,147,69)" }}>
                            Join as a University
                            <span style={{ color: "rgb(0,147,69)" }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        {/* End #main */}
        {/* ======= Footer ======= */}
 <Footer />
      </div>
    );
  }
}
export default EmpTestSignin;
