import React, { useState } from "react";

import "./modal.css";

function close(){
  window.location.reload();
}
export default class RequestRecommModal extends React.Component {
  constructor() {
    super();
    let comments = JSON.parse(sessionStorage.getItem("someData"));
   
   console.log(comments)
    this.state = {
      
    
     
     
      userid: comments,
     
      dropdownlist_input : "recommendation",
      addstudata:"recommendation",
      selectOptions : [],
      email:"",
      rtype:"",
      description:"",
      success:"",
      charLimit: 0,
      error:"",
      showModal: false,
  
  }
  this.handleSubmit = this.handleSubmit.bind(this);
  this.cancel=this.cancel.bind(this)
}
cancel(){
  window.location.reload();
}

  handleSubmit = (event) => {
    event.preventDefault()
    if(this.state.description.length>=300){
      this.setState({error:"You have exceeded the maximum number of 300 characters in that field"})
     }
    else{
    const data = {

      userid: this.state.userid,
      addstudata: this.state.addstudata,
      email:this.state.email,
      rtype:this.state.rtype,
      description:this.state.description,
     
      
     

    }
  
    let BaseUrl = JSON.parse(sessionStorage.getItem("baseurl"));

      fetch( "https://www.gigeconome.com:5200/api/addstu_profiledata", {
        method: "POST",

        body: JSON.stringify(data), // data can be `string` or {object}!

        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())

        .catch((error) => console.error("Error:", error))

        .then((response) => {
          console.log(response)
         
          this.setState({
            success:response.message
          })
          window.location.reload();
        });
      }
  }
  componentDidMount(){
    this.getOptions()
}
async getOptions(){
  const data_one={dropdownlist_input:this.state.dropdownlist_input,
  
  }

  let BaseUrl = JSON.parse(sessionStorage.getItem('baseurl'));

  fetch( 'https://www.gigeconome.com:5200/api/getdropdownlist', { method: 'POST', 

   body: JSON.stringify(data_one), // data can be `string` or {object}!

   headers:{ 'Content-Type': 'application/json' } })

   .then(res => res.json())

   .catch(error => console.error('Error:', error))

   .then((json)=>{
    const data = json.result
    
    this.setState({selectOptions: data})
   })
  
   
 
 
 
  
  }
    handleChange = (e) => {
      this.setState({
        [e.target.name]: e.target.value,
       
      });
      
    };

    openModal = () => {
      this.setState({ showModal: true });
    };
  
    closeModal = () => {
      this.setState({ showModal: false });
    };
  
render(){
const {selectOptions}=this.state;
const {success}=this.state;
const{error}=this.state;
  return (
    <>
     {this.state.showModal && (
      <div class="ft-modal" id="ft-requestrecomm-modal">
        <div class="ft-modal-content">
          <div class="ft-modal-header">
            <div class="header">
              <h5
                class="modal-title"
                id="exampleModalLabel"
                style={{
                  textAlign: "center",
                  fontSize: 25,
                  fontWeight: "bold",
                }}
              >
                Request Recommendation
              </h5>
              <hr style={{marginRight:"-4.2%",height:2,marginLeft:"-4%"}}/>
            </div>
          </div>
          <div class="ft-modal-body">
            <form onSubmit = {this.handleSubmit}>
            <div class="row mt-2">
              <div class="col-sm-1"></div>
              <div class="col-sm-10">
                <input
                  class="form-control mt-3"
                  placeholder="Email Address"
                  type="email"
                  name ="email" 
                  onChange = {this.handleChange}
                />
              </div>
              <div class="col-sm-1"></div>
            </div>
            <div class="row mt-3">
              <div class="col-sm-1"></div>
              <div class="col-sm-10">
              {selectOptions.length == 0
                    ? "Loading Nationalities..."
                    : 

                    <select class="form-select"    name ="rtype" 
                    onChange = {this.handleChange}>
                      <option>Recommendation Type</option>
                    {selectOptions.map(fbb =>
                    
                      <option key={fbb.key} value={fbb.id}>{fbb.name}</option>
                      
                    )};
                  </select>
                    } 
              </div>
              <div class="col-sm-1"></div>
            </div>
            <div class="row mt-4">
              <div class="col-sm-1"></div>
              <div class="col-sm-10">
                <div class="form-group">
                  <textarea
                    class="form-control"
                    name ="description" 
                    onChange = {this.handleChange}
                    rows="4"
                  >
                   
                  </textarea>
                  <b style={{float:'right',color:'rgb(0,147,69)'}}>{this.state.charLimit + this.state.description.length} / 300</b>
                </div>
              </div>
              <div class="col-sm-1"></div>
            </div>
            <div >  <span style={{color:"rgb(0,147,69)",fontWeight:"bold",textAlign:"center",fontSize:12}}>
                    {success}
                  </span>
                  <span style={{color:'red',marginTop:"2%",textAlign:"center",
                  fontFamily:"Gotham",fontWeight:'bold',fontSize:12}}>{error}</span>
                  </div>
            <div class="mt-4 reqmodalfooter" >
              <button type="button" class="btn btn-secondary cancelbtnrem"
               onClick={this.cancel}
             >
                Cancel
              </button>
              <button
                type="button"
                class="btn btn-secondary sendbtnrem"
               
            onClick={this.handleSubmit}
              >
                Send
              </button>
            </div>
          
            </form>
          </div>
          <div class="ft-modal-footer">
            <a class="ft-modal-close" data-dismiss="modal"
                     onClick={this.closeModal}>
              [&#10006;]{" "}
            </a>
          </div>
        </div>
      </div>
     )}
      <a
         
          className="btn-success btn-sm stuedubtn"
          href="#ft-requestrecomm-modal"
          style={{
            margin:'auto',
            width: "20%",
            backgroundColor: "rgb(0, 147, 69)",
            fontFamily: "GothamLight",
            textAlign: "center",
            display:"block",
            color:"white"
            
          }}
          onClick={this.openModal}
        >
          Request
        </a>
    </>
  );
}
}