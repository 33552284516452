import React, { useState } from "react";
import "./dashboard.css";
import StepTwo from "../../images/projectsummary.png";
import Multiselect from "multiselect-react-dropdown";
import Resizer from "react-image-file-resizer";
import Header from "../Header/Header";
import Select from 'react-select';
const style = {
  control: (base, state) => ({
    ...base,
    border: "1px solid rgb(0,147,69)",
    borderRadius:"1px",
    marginTop:"1%",
   
   
    // This line disable the blue border
    boxShadow: state.isFocused ? 0 : 0,
    "&:hover": {
      border: "1px solid rgb(0,147,69)",
      borderRadius:"1px",
      marginTop:"1%",
    }
  }),
 
};
class PostaProject_one extends React.Component {
  constructor() {
    
    super();
    let comments = JSON.parse(sessionStorage.getItem("someData"));
    let PostID = JSON.parse(sessionStorage.getItem("PostID"));
    let IpAddress = JSON.parse(sessionStorage.getItem("IP Address"));
    let APIskil = JSON.parse(sessionStorage.getItem("APISkil"));
    let APILanguage = JSON.parse(sessionStorage.getItem("APILanguage"));
    this.state = {
      ipaddressid:"",
      options: [],
      selectOptions: [],
      id: "",
      name: "",
      dropdownlist_input: "languages",
      Universitydata:[],
      Jobdepartment:[],
      skillName:[],
      languageName:[],
      Category:[], 
      subCategory:[],
      opportunitiestypeid:[],
      Hours:0,
      Fixed:0,
      subjectDetails:[],
      selected:"",
      degreeData:[],
      projname:"",
      departmentid:"",
      categoryid:"",
      subcategoryid:"",
      projteamsize:"",
      displayprojteamsize:"",
      projtype:"",
      displayprojtype:"",
      projidea:"",
      displayprojidea:"",
      projdescription:"",
      additionalinfo:"",
      projsize:"",
      displayprojsize:"",
      paytype:"",
      projpostedtype:"",
      currency:"",
      price:"",
      displayprice:"",
      opentoallintrested:"",
      universityid:"",
      userid:comments,
      skillsid:"",
      languageid:"",
      studenttypeid:[],
      subdegreeids:"",
      LanguageID:[],
      skillData:[],
      skillID:[],
      skillName:[],
    degreeName:[],
      studentData:[],
      StudentStatus:[],
      eligibleSubject:[],
      postprojectid:PostID,
      categoryname:"",
      subcategoryname:"",
      departmentname:"",
      skillname:"",
      displaypaytype:"",
      apiSkill:[],
      apiLanguage:[],
      displayapiskill:[],
      displayapilanguage:[],
      categoryiderror:"",
      subcategoryiderror:"",
      languageiderror:"",
      departmentiderror:"",
      universityiderror:"",
      skillsiderror:"",
      responseerror:"",
      projecterror:"",
      displayextraskill:0,
      showModal: false,
      addskillsname:"",
      success:"",
    displaysubcategoryname:"",
      employability:"",
      empSkillData:[],
      displayemp:[],
      empName:[],
      backgroundyes:0,
      backgroundno:0,
      subjectName:[],
     universityname:"",
      opportunitiestype: {
        "Post Graduate":false,
         "Sixth Form (A levels)": false,
         "BTEC Student": false,
         "Specialist Colleges": false,
         "Adult Education Institutes": false,
         "Undergraduate": false,
        
        

      },
      
    };
    this.handleCategory = this.handleCategory.bind(this);
    this.handleHours = this.handleHours.bind(this);
    this.handleFixed = this.handleFixed.bind(this)
    this.handleSubject = this.handleSubject.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.projectTypeone = this.projectTypeone.bind(this)
    this.projectTypetwo = this.projectTypetwo.bind(this)
    this.projectTypethree = this.projectTypethree.bind(this)
    this.onSelectLanguage=this.onSelectLanguage.bind(this)
    this.onRemoveLanguage=this.onRemoveLanguage.bind(this)
    this.onSelectSkill=this.onSelectSkill.bind(this)
    this.onRemoveSkill=this.onRemoveSkill.bind(this)
    this.openYes=this.openYes.bind(this)
    this.openNo=this.openNo.bind(this)
    this.updateStudentstatus=this.updateStudentstatus.bind(this)
    this.updateSubject=this.updateSubject.bind(this)
    this.handleAddaskill=this.handleAddaskill.bind(this)
    this.state.backApiSubcategory=this.backApiSubcategory.bind(this)
    this.onSelectEmployee=this.onSelectEmployee.bind(this)
    this.onRemoveEmployee=this.onRemoveEmployee.bind(this)
    this.handleYes=this.handleYes.bind(this)
    this.handleNo=this.handleNo.bind(this)
    this.handleChangeCheckbox=this.handleChangeCheckbox.bind(this);
    this.handleDrop=this.handleDrop.bind(this)
    this.RemoveSubject=this.RemoveSubject.bind(this)
 
  }

  handleDrop = (e) => {
    
    this.setState({universityname:e.label})
     
    this.setState({universityid:e.value})
   
      }
  handleChangeCheckbox = (event) => {
    const { name, checked } = event.target;
    //console.log( name, checked);
  if(name=="Post Graduate" && checked==true){
    this.setState({studenttypeid:this.state.studenttypeid.concat(["1"])},()=>{
      console.log(this.state.studenttypeid)
    })
  }
  else if(name=="Post Graduate" && checked==false){
    this.setState({studenttypeid : this.state.studenttypeid.filter(function(val) {return val!=="1"})},()=>{
      console.log(this.studenttypeid)
    })
  }

  if(name=="Graduate Jobs" && checked==true){
    this.setState({studenttypeid:this.state.studenttypeid.concat(["2"])},()=>{
      console.log(this.state.studenttypeid)
    })
  }
  else if(name=="Graduate Jobs" && checked==false){
    
    this.setState({studenttypeid : this.state.studenttypeid.filter(function(val) {return val!=="2"})},()=>{
      console.log(this.studenttypeid)
    })
  
  
  }
  if(name=="Undergraduate" && checked==true){
    this.setState({studenttypeid:this.state.studenttypeid.concat(["11"])},()=>{
      console.log(this.state.studenttypeid)
    })
  }
  else if(name=="Undergraduate" && checked==false){
    this.setState({studenttypeid : this.state.studenttypeid.filter(function(val) {return val!=="11"})},()=>{
      //console.log(this.studenttypeid)
    })
  }
  if(name=="BTEC Student" && checked==true){
    this.setState({studenttypeid:this.state.studenttypeid.concat(["3"])},()=>{
      console.log(this.state.studenttypeid)
    })
  }
  else if(name=="BTEC Student" && checked==false){
    this.setState({studenttypeid : this.state.studenttypeid.filter(function(val) {return val!=="3"})},()=>{
      //console.log(this.studenttypeid)
    })
  }
  if(name=="Sixth Form (A levels)" && checked==true){
    this.setState({studenttypeid:this.state.studenttypeid.concat(["2"])},()=>{
      console.log(this.state.studenttypeid)
    })
  }
  else if(name=="Sixth Form (A levels)" && checked==false){
    this.setState({studenttypeid : this.state.studenttypeid.filter(function(val) {return val!=="2"})},()=>{
      //console.log(this.studenttypeid)
    })
  }
  if(name=="Specialist Colleges" && checked==true){
    this.setState({studenttypeid:this.state.studenttypeid.concat(["4"])},()=>{
      console.log(this.state.studenttypeid)
    })
  }
  else if(name=="Specialist Colleges" && checked==false){
    this.setState({studenttypeid : this.state.studenttypeid.filter(function(val) {return val!=="4"})},()=>{
      //console.log(this.studenttypeid)
    })
  }
  if(name=="Side Gig Opportunities" && checked==true){
    this.setState({studenttypeid:this.state.studenttypeid.concat(["7"])},()=>{
      console.log(this.state.studenttypeid)
    })
  }
  else if(name=="Side Gig Opportunities" && checked==false){
    this.setState({studenttypeid : this.state.studenttypeid.filter(function(val) {return val!=="7"})},()=>{
      //console.log(this.studenttypeid)
    })
  }
  if(name=="Adult Education Institutes" && checked==true){
    this.setState({studenttypeid:this.state.studenttypeid.concat(["5"])},()=>{
      console.log(this.state.studenttypeid)
    })
  }
  else if(name=="Adult Education Institutes" && checked==false){
    this.setState({studenttypeid : this.state.studenttypeid.filter(function(val) {return val!=="5 "})},()=>{
      //console.log(this.studenttypeid)
    })
  }
    this.setState((prevState) => {
      const opportunitiestype = prevState.opportunitiestype;
     //console.log( opportunitiestype);
      opportunitiestype[name] = checked;
      return opportunitiestype;
    });
   
  };
handleYes(){
this.setState({backgroundyes:1,backgroundno:0,opentoallintrested:"Yes"})
}
handleNo(){
  this.setState({backgroundyes:0,backgroundno:1,opentoallintrested:"No"})
  }
  onSelectEmployee(selectedList, selectedItem) {
  

    
    this.setState({ empName:this.state.empName.concat([selectedItem["name"]])},()=>{
    // console.log(this.state.empName)
       })
       
       
}

onRemoveEmployee(selectedList, removedItem) {

 
  this.setState({empName : this.state.empName.filter(function(val) {return val!==removedItem["name"]})},()=>{
   //  console.log(this.state.empName)
  })
  
 }
  backApiSubcategory(e,subcategoryname){
    const subcategorydata = { categoryid: e };

    fetch("https://www.gigeconome.com:5200/api/getrolecategorytype", {
      method: "POST",
  
      body: JSON.stringify(subcategorydata), // data can be `string` or {object}!
  
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
  
      .catch((error) => console.error("Error:", error))
  
      .then((response) => {
       this.setState({subCategory:response.result,displaysubcategoryname:subcategoryname})
      });
  }
  handleAddaskill = (event) => {
    event.preventDefault()
   this.setState({success:""})
   
const data = {


userid:57,
addcpydata:"cpyaddnewskillsinfo",
addskillsname:this.state.addskillsname,
adddropdownskills:this.state.skillName.toString()
 
  
 

}
console.log(data)
let BaseUrl = JSON.parse(sessionStorage.getItem("baseurl"));

  fetch(BaseUrl + "addcpy_profiledata", {
    method: "POST",

    body: JSON.stringify(data), // data can be `string` or {object}!

    headers: { "Content-Type": "application/json" },
  })
    .then((res) => res.json())

    .catch((error) => console.error("Error:", error))

    .then((response) => {
      console.log(response)
      this.setState({success:response.message,displayapiskill:[]})
     
      if(response.message=="Data has been Added successfully."){
       // this.setState({displayapiskill:response.newskillsname,addskillsname:response.newskillsname})
       // sessionStorage.setItem("NewSkill",response.newskillsname )

        response.newskillsname.split(",")
        .map((item, i) => this.setState({displayapiskill:[...this.state.displayapiskill,{name:item}],
        skillName:[...this.state.skillName,item]
        },()=>{
          
        }))  
       this.setState({skillName:[...new Set(this.state.skillName)]
       },()=>{
        console.log(this.state.skillName)
       })
        const skilldata = { dropdownlist_input: "postaprojectskills" };

        fetch("https://www.gigeconome.com:5200/api/getdropdownlist", {
          method: "POST",

          body: JSON.stringify(skilldata), // data can be `string` or {object}!

          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())

          .catch((error) => console.error("Error:", error))

          .then((json) => {
            const data = json.result;
          // console.log(data)
            this.setState({skillData:data})
          });

      }
   else{
     console.log("empty")
   }
     
      
    });
    
  }
  updateSubject(e,subjectid,degreename){
    this.setState({eligibleSubject:this.state.eligibleSubject.concat([subjectid])},()=>{
      console.log(this.state.eligibleSubject)
    })
    this.setState({degreeName:this.state.degreeName.concat([degreename])},()=>{
      console.log(this.state.degreeName)
    })
    /* if (e.target.checked){
this.setState({eligibleSubject:this.state.eligibleSubject.concat([subjectid])},()=>{
  //console.log(this.state.eligibleSubject)
})
this.setState({degreeName:this.state.degreeName.concat([degreename])},()=>{
  console.log(this.state.degreeName)
})
    }*/
    
   /*  else{
this.setState({eligibleSubject : this.state.eligibleSubject.filter(function(val) {return val!==subjectid})},()=>{
  //console.log(this.state.eligibleSubject)
})
this.setState({degreeName : this.state.degreeName.filter(function(val) {return val!==degreename})},()=>{
  console.log(this.state.degreeName)
})
    }*/

  }
  RemoveSubject(e,subjectid,degreename){
    this.setState({eligibleSubject : this.state.eligibleSubject.filter(function(val) {return val!==subjectid})},()=>{
      //console.log(this.state.eligibleSubject)
    })
    this.setState({degreeName : this.state.degreeName.filter(function(val) {return val!==degreename})},()=>{
      console.log(this.state.degreeName)
    })
  }
  updateStudentstatus(e,updateStudentstatus,studystatusname){
    if (e.target.checked){
this.setState({StudentStatus:this.state.StudentStatus.concat([updateStudentstatus])})
this.setState({Studystatusname:this.state.Studystatusname.concat([studystatusname])},()=>{
  console.log(this.state.Studystatusname)
})
    }
    else{
this.setState({StudentStatus : this.state.StudentStatus.filter(function(val) {return val!==updateStudentstatus})})
this.setState({Studystatusname : this.state.Studystatusname.filter(function(val) {return val!==studystatusname})},()=>{
  console.log(this.state.Studystatusname)
})
}

  }
  openYes(){
this.setState({opentoallintrested:"Yes"})

  }
  openNo(){
    this.setState({opentoallintrested:"No"})
  }
  projectTypeone(){
    this.setState({
      projpostedtype:"Gig Project"
    })
  }
  projectTypetwo(){
    this.setState({
      projpostedtype:"Course Work Related Project"
    })
  }
  projectTypethree(){
    this.setState({
      projpostedtype:"Portoflio Buidling Project"
    })
  }

 
  handleSubmit(){
  
         
     if(this.state.userid==null || this.state.userid==""){
      const submitdatanon = 
      { 
      projname:this.state.projname,
      departmentid:this.state.departmentid,
      categoryid:this.state.categoryid,
      subcategoryid:this.state.subcategoryid,
      projteamsize:this.state.projteamsize,
      projtype:this.state.projtype,
      projidea:this.state.projidea,
      projdescription:this.state.projdescription,
      additionalinfo:this.state.additionalinfo,
      projsize:this.state.projsize,
      projpostedtype:this.state.projpostedtype,
      paytype:this.state.paytype,
      currency:"GBP",
      price:this.state.price,
      opentoallintrested:this.state.opentoallintrested,
      universityid:this.state.universityid,
      ipaddressid:this.state.ipaddressid,
      skillsid:this.state.skillID.toString(),
      languageid:this.state.LanguageID.toString(),
      studenttypeid:this.state.studenttypeid.toString(),
      subdegreeids:this.state.eligibleSubject.toString(),
      skillsname:this.state.skillName.toString(),   
      studenttypename:this.state.opportunitiestypeid.toString(), 
      subjectname:this.state.subjectName.toString(),  
      degreename:this.state.degreeName.toString(),        
      languagename:this.state.languageName.toString(),  
      universityname:this.state.universityname,
      postprojectid:this.state.postprojectid,
      employability:this.state.empName.toString()
 
 
 
 
 
 
     
     };
     console.log(submitdatanon)
     fetch("https://www.gigeconome.com:5200/api/cpynotsightinPAPstep1", {
       method: "POST",
   
       body: JSON.stringify(submitdatanon), // data can be `string` or {object}!
   
       headers: { "Content-Type": "application/json" },
     })
       .then((res) => res.json())
   
       .catch((error) => console.error("Error:", error))
   
       .then((response) => {
          console.log(response)
          if(response.message=="Data has been Added successfully." || response.message=="Data has been Updated successfully."){
            window.location.href=`./PostaProject_two`
          sessionStorage.setItem("PostID",JSON.stringify(response.postprojectid))
          }
          else{
              this.setState({responseerror:response.message})
          }
       });
    }
    else{
      const submitdata = 
      { addcpydata:"cpypostaprojectstep1",
      projname:this.state.projname,
      departmentid:this.state.departmentid,
      categoryid:this.state.categoryid,
      subcategoryid:this.state.subcategoryid,
      projteamsize:this.state.projteamsize,
      projtype:this.state.projtype,
      projidea:this.state.projidea,
      projdescription:this.state.projdescription,
      additionalinfo:this.state.additionalinfo,
      projsize:this.state.projsize,
      projpostedtype:this.state.projpostedtype,
      paytype:this.state.paytype,
      currency:"GBP",
      price:this.state.price,
      opentoallintrested:this.state.opentoallintrested,
      universityid:this.state.universityid,
      userid:this.state.userid,
      skillsid:this.state.skillID.toString(),
      languageid:this.state.LanguageID.toString(),
      studenttypeid:this.state.studenttypeid.toString(),
      subdegreeids:this.state.eligibleSubject.toString(),
      skillsname:this.state.skillName.toString(),
      subjectname:this.state.subjectName.toString(),
      degreename:this.state.degreeName.toString(),
      languagename:this.state.languageName.toString(),
      studenttypename:this.state.opportunitiestypeid.toString(),
      postprojectid:this.state.postprojectid,
      employability:this.state.empName.toString(),
      universityname:this.state.universityname

 
 
 
 
 
 
     
     };
     console.log(submitdata)
     fetch("https://www.gigeconome.com:5200/api/addcpy_profiledata", {
       method: "POST",
   
       body: JSON.stringify(submitdata), // data can be `string` or {object}!
   
       headers: { "Content-Type": "application/json" },
     })
       .then((res) => res.json())
   
       .catch((error) => console.error("Error:", error))
   
       .then((response) => {
          console.log(response)
          if(response.message=="Data has been Added successfully." || response.message=="Data has been Updated successfully."){
           window.location.href=`./PostaProject_two`
          sessionStorage.setItem("PostID",JSON.stringify(response.postprojectid))
          }
          else{
           
              this.setState({responseerror:response.message})
          
          }
       });
    }
  
  }


  handleSubject(subjectid,subjectname){
    this.setState({subjectName:this.state.subjectName.concat([subjectname])},()=>{
      console.log(this.state.subjectName)
    })
    const subcategorydata = { subjectid:subjectid,
      dropdownlist_input:"degreedetails"
    
    };

    fetch("https://www.gigeconome.com:5200/api/getdropdownlist", {
      method: "POST",
  
      body: JSON.stringify(subcategorydata), // data can be `string` or {object}!
  
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
  
      .catch((error) => console.error("Error:", error))
  
      .then((response) => {
       this.setState({degreeData:response.result,})
       response.result.map(e=>{
         this.setState({selected:e.subjectid})
       
       })
      });
  }
handleHours(){
this.setState({Hours:1})
this.setState({Fixed:0})
this.setState({paytype:"By Hours"})
}

handleFixed(){
  this.setState({Hours:0})
  this.setState({Fixed:1})
  this.setState({paytype:"By Fixed Price"})
  }

handleCategory(e){
  this.setState({
    [e.target.name]: e.target.value,
  });
  const subcategorydata = { categoryid: e.target.value };

  fetch("https://www.gigeconome.com:5200/api/getrolecategorytype", {
    method: "POST",

    body: JSON.stringify(subcategorydata), // data can be `string` or {object}!

    headers: { "Content-Type": "application/json" },
  })
    .then((res) => res.json())

    .catch((error) => console.error("Error:", error))

    .then((response) => {
    this.setState({subCategory:response.result})
    });
}


onSelectLanguage(selectedList, selectedItem) {
  //console.log(selectedItem["id"])
  this.setState({LanguageID: this.state.LanguageID.concat([selectedItem["id"]])},()=>{
//console.log(this.state.LanguageID)
  })

  this.setState({languageName: this.state.languageName.concat([selectedItem["name"]])},()=>{
   // console.log(this.state.languageName)
      })

  this.setState({apiLanguage:this.state.apiLanguage.concat(selectedItem)},()=>{
    sessionStorage.setItem("APILanguage",JSON.stringify(this.state.languageName.toString()))
    //console.log(this.state.apiSkill)
   })
}
  onSelectSkill(selectedList, selectedItem) {
    if([selectedItem["name"]]=="Other"){
      this.setState({displayextraskill:1})
    }
   //console.log(selectedItem)
   this.setState({apiSkill:this.state.apiSkill.concat(selectedItem)},()=>{
    sessionStorage.setItem("APISkil",JSON.stringify(this.state.apiSkill))
    //console.log(this.state.apiSkill)
   })
   this.setState({skillID: this.state.skillID.concat([selectedItem["id"]]),
   skillName:this.state.skillName.concat([selectedItem["name"]])},()=>{
    //console.log(this.state.skillName)
      })
}

onRemoveSkill(selectedList, removedItem) {
  if([removedItem["name"]]=="Other"){
    this.setState({displayextraskill:0})
  }
  this.setState({skillID : this.state.skillID.filter(function(val) {return val!==removedItem["id"]})})
  this.setState({skillName : this.state.skillName.filter(function(val) {return val!==removedItem["name"]})})
this.setState({apiSkill : this.state.apiSkill.filter(function(val) {return val!==removedItem})},()=>{
  //sessionStorage.setItem("APISkil",JSON.stringify(this.state.apiSkill))
  //console.log(this.state.apiSkill)
})

this.setState({displayapiskill : this.state.displayapiskill.filter(function(val) {return val!==removedItem})},()=>{
  //sessionStorage.setItem("APISkil",JSON.stringify(this.state.apiSkill))
  console.log(this.state.displayapiskill)
  console.log(this.state.skillName)
})
}
onRemoveLanguage(selectedList, removedItem) {
this.setState({LanguageID : this.state.LanguageID.filter(function(val) {return val!==removedItem["id"]})})
this.setState({languageName : this.state.languageName.filter(function(val) {return val!==removedItem["name"]})})
this.setState({apiLanguage : this.state.apiLanguage.filter(function(val) {return val!==removedItem})},()=>{
  sessionStorage.setItem("APILanguage",JSON.stringify(this.state.apiLanguage))
  //console.log(this.state.apiSkill)
})

}

  componentDidMount() {

   
 /* if(this.state.postprojectid==null){
    this.setState({postprojectid:""})
  }
  else{
    console.log("postaproject")
  }*/
    if(this.state.displayapiskill=="" || this.state.displayapiskill==undefined){
      console.log("empty")
    }
    else{
      {this.state.displayapiskill.map(e=>{
        this.setState({skillID: this.state.skillID.concat([e.id])
        },()=>{
          console.log(this.state.displayapiskill)
            })
      })}
      
    }

    if(this.state.displayapilanguage=="" || this.state.displayapilanguage==undefined){
      //console.log("empty")
    }
    else{
      {this.state.displayapilanguage.map(e=>{
        this.setState({LanguageID: this.state.LanguageID.concat([e.id])},()=>{
          //console.log(this.state.skillID)
            })
      })}
      
    }
{/*Ip Address Api Call */}
    const fetchPromise = fetch('https://geolocation-db.com/json/');

    fetchPromise.then( response => {
      const jsonPromise = response.json();
      jsonPromise.then( json => {
        console.log(json.IPv4);
        this.setState({ipaddressid:json.IPv4},()=>{
          if(this.state.postprojectid==null){
            console.log("error")
          }
          else if(this.state.userid==null){
            const displaydata = { 
              postprojectid:this.state.postprojectid,
              ipaddressid:this.state.ipaddressid
          };
          console.log(displaydata)
          fetch("https://www.gigeconome.com:5200/api/getcpynotsightinPAP", {
            method: "POST",
          
            body: JSON.stringify(displaydata), // data can be `string` or {object}!
          
            headers: { "Content-Type": "application/json" },
          })
            .then((res) => res.json())
          
            .catch((error) => console.error("Error:", error))
          
            .then((response) => {
              console.log(response)
              //console.log(response.result.projpostedtype)
            //  console.log(response.result.paytype)
              if(response.message=="The Post Project Id not be empty"
              || response.message=="No Data Found"
              ){
                console.log("empty")
              }
              else{
                if(response.result.paytype=="By Hours"){
                  this.setState({Hours:1})
                  this.setState({Fixed:0})
                }
                else{
                  console.log("empty")
                }
                if(response.result.paytype=="By Fixed Price"){
                  this.setState({Hours:0})
                  this.setState({Fixed:1})
                }
                else{
                  console.log("empty")
                }
                if(response.message=="Post Project detail Reterived"){
                   console.log(response)
                   response.result.skillsname.split(",")
  .map((item, i) => this.setState({displayapiskill:[...this.state.displayapiskill,{name:item}],
  skillName:[...this.state.skillName,item]
  },()=>{
    console.log(this.state.displayapiskill)
  }))  


  response.result.employability.split(",")
  .map((item, i) => this.setState({displayemp:[...this.state.displayemp,{name:item}],
    empName:[...this.state.empName,item]
 },()=>{
    console.log(this.state.displayemp)
  }))
  response.result.languagename.split(",")
  .map((item, i) => this.setState({displayapilanguage:[...this.state.displayapilanguage,{name:item}],
  languageName:response.result.languagename
  },()=>{
    console.log(this.state.displayapilanguage)
  })) 
  
  response.result.languageid.split(",")
  .map((item, i) => this.setState({LanguageID:[...this.state.LanguageID,item],
  
  },()=>{
    console.log(this.state.LanguageID)
  })) 
  if(response.result.studenttypename==null || response.result.studenttypename==" "){
   console.log("empty")
  }
  else{
    response.result.studenttypename.split(",").map((n) => {
      this.setState((prevState) => {
        const opportunitiestype = prevState.opportunitiestype;
       // console.log("******************", opportunitiestype);
        opportunitiestype[n] = true;
        return opportunitiestype;
      });
    });
  }
  if(response.result.opentoallintrested=="Yes"){
    this.setState({backgroundyes:1})
  }
  else{
    console.log("error")
  }
  if(response.result.studenttypeid==null || response.result.studenttypeid==""){
    console.log("success")
  }
  else{
    response.result.studenttypeid.split(",")
    .map((item, i) => this.setState({studenttypeid:[...this.state.studenttypeid,item],
    
    },()=>{
      console.log(this.state.studenttypeid)
    }))
  }
  if(response.result.opentoallintrested=="No"){
    this.setState({backgroundno:1})
    response.result.subdegreeids.split(",")
  .map((item, i) => this.setState({eligibleSubject:[...this.state.eligibleSubject,item],
  
  },()=>{
    console.log(this.state.eligibleSubject)
  })) 
  }
  else{
    console.log("error")
  }
                    this.setState({ 
                    projname:response.result.projname,
                    categoryname:response.result.categoryname,
                    categoryid:response.result.categoryid,
                    displaysubcategoryname:response.result.subcategoryname,
                    subcategoryid:response.result.subcategoryid,
                    departmentname:response.result.departmentname,
                    departmentid:response.result.departmentid,
                    displayprojteamsize:response.result.projteamsize,
                    displayprojtype:response.result.projtype,
                    displayprojidea:response.result.projidea,
                    projidea:response.result.projidea,
                    projdescription:response.result.projdescription,
                    displayprojsize:response.result.projsize,
                    skillname:response.result.skillname,
                    skillName:response.result.skillsname,
                    languageName:response.result.languagename,
                    projpostedtype:response.result.projpostedtype,
                    displaypaytype:response.result.paytype,
                    displayprice:response.result.displayprice,
                    opentoallintrested:response.result.opentoallintrested,
                    paytype:response.result.paytype,
                    projsize:response.result.projsize,
                    projtype:response.result.projtype,
                    projteamsize:response.result.projteamsize,
                    price:response.result.price,
                    universityname:response.result.universityname,
                    universityid:response.result.universityid
                  
                
                
                  },()=>{
                   
                  console.log(this.state.languageName)
                  })
                }
              }
             
            
         
            }); 
          }
         else{
           console.log("errorthere")
         }
        
        })
      });
    });

    
    {/* Back button data displaying for not signed in user */}


 if(this.state.postprojectid==null || this.state.userid==null){
  
 }
 else{
  const displaydata = { userid:this.state.userid,
    postprojectid:this.state.postprojectid,
    addcpydata:"getpreviewpostprojectdetails"
};
console.log(displaydata)
fetch("https://www.gigeconome.com:5200/api/addcpy_profiledata", {
  method: "POST",

  body: JSON.stringify(displaydata), // data can be `string` or {object}!

  headers: { "Content-Type": "application/json" },
})
  .then((res) => res.json())

  .catch((error) => console.error("Error:", error))

  .then((response) => {
    console.log(response)
    //console.log(response.result.projpostedtype)
    if(response.message=="Retrived Post A Project Preview Details."){
      response.result.skillsname.split(",")
 .map((item, i) => this.setState({displayapiskill:[...this.state.displayapiskill,{name:item}],
skillName:[...this.state.skillName,item]
},()=>{
   console.log(this.state.displayapiskill)
 }))  
 response.result.languagename.split(",")
  .map((item, i) => this.setState({displayapilanguage:[...this.state.displayapilanguage,{name:item}],
    languageName:response.result.languagename
  },()=>{
    console.log(this.state.displayapilanguage)
  }))  
  response.result.employability.split(",")
  .map((item, i) => this.setState({displayemp:[...this.state.displayemp,{name:item}],
    empName:[...this.state.empName,item]
 },()=>{
    console.log(this.state.displayemp)
  }))
  response.result.languageid.split(",")
  .map((item, i) => this.setState({LanguageID:[...this.state.LanguageID,item],
  
  },()=>{
    console.log(this.state.LanguageID)
  }))

  if(response.result.studenttypeid==null || response.result.studenttypeid==""){
    console.log("success")
  }
  else{
    response.result.studenttypeid.split(",")
    .map((item, i) => this.setState({studenttypeid:[...this.state.studenttypeid,item],
    
    },()=>{
      console.log(this.state.studenttypeid)
    }))
  }
  if(response.result.studenttypename==null || response.result.studenttypename==" "){
   console.log("success")
  }
  else{
    response.result.studenttypename.split(",").map((n) => {
      this.setState((prevState) => {
        const opportunitiestype = prevState.opportunitiestype;
       // console.log("******************", opportunitiestype);
        opportunitiestype[n] = true;
        return opportunitiestype;
      });
    });
  }
  
     this.backApiSubcategory(response.result.categoryid,response.result.subcategoryname)
      if(response.result.paytype=="By Hours"){
        this.setState({Hours:1})
        this.setState({Fixed:0})
      }
      else{
        console.log("empty")
      }
      if(response.result.paytype=="By Fixed Price"){
        this.setState({Hours:0})
        this.setState({Fixed:1})
      }
      else{
        console.log("empty")
      }
      if(response.result.opentoallintrested=="Yes"){
        this.setState({backgroundyes:1})
      }
      else{
        console.log("error")
      }
      if(response.result.opentoallintrested=="No"){
        this.setState({backgroundno:1})
       
        response.result.subdegreeids.split(",")
        .map((item, i) => this.setState({eligibleSubject:[...this.state.eligibleSubject,item],
        
        },()=>{
          //console.log(this.state.eligibleSubject)
        }))
        this.setState({eligibleSubject:this.state.eligibleSubject.map(Number)},()=>{
          console.log(this.state.eligibleSubject)
        }) 
      }
      else{
        console.log("error")
      }
    this.setState({ 
      projname:response.result.projname,
      categoryname:response.result.categoryname,
      categoryid:response.result.categoryid,
      displaysubcategoryname:response.result.subcategoryname,
      subcategoryid:response.result.subcategoryid,
      departmentname:response.result.departmentname,
      departmentid:response.result.departmentid,
      displayprojteamsize:response.result.projteamsize,
      displayprojtype:response.result.projtype,
      displayprojidea:response.result.projidea,
      projdescription:response.result.projdescription,
      displayprojsize:response.result.projsize,
      skillname:response.result.skillname,
      skillName:response.result.skillsname,
      projpostedtype:response.result.projpostedtype,
      displaypaytype:response.result.paytype,
      displayprice:response.result.price,
      opentoallintrested:response.result.opentoallintrested,
      paytype:response.result.paytype,
      languageName:response.result.languagename,
      projidea:response.result.projidea,
      projsize:response.result.projsize,
      projtype:response.result.projtype,
      projteamsize:response.result.projteamsize,
      price:response.result.price,
      universityname:response.result.universityname,
      universityid:response.result.universityid
   
  
    },()=>{
     
      
      
      
     // console.log(card);
    })
    }
   else{
        console.log("empty")
   }
// console.log(response.result.subcategoryname)
  });

 }
 {/* Student Status Api Call */}
 const studentdata = { dropdownlist_input: "stusearchstudystatuslist"
 };

 fetch("https://www.gigeconome.com:5200/api/getdropdownlist", {
   method: "POST",

   body: JSON.stringify(studentdata), // data can be `string` or {object}!

   headers: { "Content-Type": "application/json" },
 })
   .then((res) => res.json())

   .catch((error) => console.error("Error:", error))

   .then((response) => {
    this.setState({studentData:response.result})
   
   });

 


 {/* Employee Skill Api Call */}
 const empskilldata = { dropdownlist_input: "employabilityskillsinfo"
 };

 fetch("https://www.gigeconome.com:5200/api/getdropdownlist", {
   method: "POST",

   body: JSON.stringify(empskilldata), // data can be `string` or {object}!

   headers: { "Content-Type": "application/json" },
 })
   .then((res) => res.json())

   .catch((error) => console.error("Error:", error))

   .then((response) => {
    this.setState({empSkillData:response.result})
   
   });




     {/* Degree Api Call */}
     const degdata = { dropdownlist_input: "degreedetails",
     subjectid:1
     };

     fetch("https://www.gigeconome.com:5200/api/getdropdownlist", {
       method: "POST",
 
       body: JSON.stringify(degdata), // data can be `string` or {object}!
 
       headers: { "Content-Type": "application/json" },
     })
       .then((res) => res.json())
 
       .catch((error) => console.error("Error:", error))
 
       .then((response) => {
         console.log(response)
        this.setState({degreeData:response.result})
        response.result.map(e=>{
          this.setState({selected:e.subjectid})
        })
       });
 
    {/* Subject Api Call */}
    const subdata = { dropdownlist_input: "subjectdetails" };

    fetch("https://www.gigeconome.com:5200/api/getdropdownlist", {
      method: "POST",

      body: JSON.stringify(subdata), // data can be `string` or {object}!

      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())

      .catch((error) => console.error("Error:", error))

      .then((response) => {
       this.setState({subjectDetails:response.result})
      });






    {/* Category Api Call */}
    const categorydata = { dropdownlist_input: "rolecategory" };

    fetch("https://www.gigeconome.com:5200/api/getdropdownlist", {
      method: "POST",

      body: JSON.stringify(categorydata), // data can be `string` or {object}!

      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())

      .catch((error) => console.error("Error:", error))

      .then((response) => {
       this.setState({Category:response.result})
      });


    {/* Language Api Call */}
    const data_one = { dropdownlist_input: this.state.dropdownlist_input };

    fetch("https://www.gigeconome.com:5200/api/getdropdownlist", {
      method: "POST",

      body: JSON.stringify(data_one), // data can be `string` or {object}!

      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())

      .catch((error) => console.error("Error:", error))

      .then((json) => {
        const data = json.result;
      // console.log(data)
       this.setState({options:data})
      });

 {/* Skill Api Call */}
 const skilldata = { dropdownlist_input: "postaprojectskills" };

 fetch("https://www.gigeconome.com:5200/api/getdropdownlist", {
   method: "POST",

   body: JSON.stringify(skilldata), // data can be `string` or {object}!

   headers: { "Content-Type": "application/json" },
 })
   .then((res) => res.json())

   .catch((error) => console.error("Error:", error))

   .then((json) => {
     const data = json.result;
   // console.log(data)
    this.setState({skillData:data})
   });

      {/* University API Call */}

      const Udata = { dropdownlist_input: "university" };

      fetch("https://www.gigeconome.com:5200/api/getdropdownlist", {
        method: "POST",
  
        body: JSON.stringify(Udata), // data can be `string` or {object}!
  
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())
  
        .catch((error) => console.error("Error:", error))
  
        .then((response) => {
          const data = response.result
          //console.log(json)
          
        const options = data.map(d => ({
            "value" : d.id,
            "label" : d.name
      
          })) 
         
          this.setState({Universitydata: options})
        });

 {/* Department API Call */}

 const Ddata = { dropdownlist_input: "jobdepartment" };

 fetch("https://www.gigeconome.com:5200/api/getdropdownlist", {
   method: "POST",

   body: JSON.stringify(Ddata), // data can be `string` or {object}!

   headers: { "Content-Type": "application/json" },
 })
   .then((res) => res.json())

   .catch((error) => console.error("Error:", error))

   .then((response) => {
    this.setState({Jobdepartment:response.result})
   });

  }
  getBase64 = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        console.log("Called", reader);
        baseURL = reader.result;
        console.log(baseURL);
        resolve(baseURL);
      };
      console.log(fileInfo);
    });
  };
  handleImage = (e) => {
    // let file = new FileReader();
    //console.log(e.target.files[0]);
    //console.log(e.target.files[0].name);
    sessionStorage.setItem("Document",JSON.stringify(e.target.files[0].name))
    let { file } = this.state;

    file = e.target.files[0];

    this.getBase64(file)
      .then((result) => {
        file["base64"] = result;
        console.log("File Is", file);
        this.setState({
          additionalinfo: result,
          file,
        });
      })
      .catch((err) => {
        console.log(err);
      });

    this.setState({
      //  file: e.target.files[0]
    });

    this.setState({
      //companylogo: e.target.files[0]
    });
    var fileInput = false;
    if (e.target.files[0]) {
      fileInput = true;
    }
    
    if(fileInput) {
      try {
        Resizer.imageFileResizer(
          e.target.files[0],
          120,
          120,
          "JPEG",
          100,
          0,
          (uri) => {
            //console.log(uri);
            this.setState({ newImage: uri });
          },
          "base64",
          120,
          120
        );
      } catch (err) {
        console.log(err);
      }
    }
  };
  
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    //sessionStorage.setItem("Search", e.target.value);
  };
  openModal = () => {

    this.setState({ showModal: true,success:""})
  };
  
  closeModal = () => {
  
   
        this.setState({showModal: false,success:""},()=>{
          console.log(this.state.skillname)
        })
       
  
}
  render() {
    this.state.opportunitiestypeid = Object.keys(this.state.opportunitiestype).filter(
      (key) => this.state.opportunitiestype[key]
    );
 const { selected }=this.state
    return (
      <div>
     
{/* Add a skill modal starts */}
{this.state.showModal && (
      <div class="ft-modal" id="ft-postaskills-modal">
        <div class="ft-modal-content" style={{width:"50%"}}>
          <div class="ft-modal-header">
            <div class="header">
              <h5
                class="modal-title"
                id="exampleModalLabel"
                style={{
                  textAlign: "center",
                  fontSize: 25,
                  fontWeight: "bold",
                  fontFamily:"Gotham"
                }}
              >
                Please Add Other Skills
              </h5>
              <hr />
            </div>
          </div>
          <div class="ft-modal-body">
         <div className="row">
             <div className="col-sm-2">

             </div>
             <div className="col-sm-8 mt-4">
                <label style={{fontSize:18}}>Please Add Other Skills</label> <br/>
                <input type="text" 
                name="addskillsname"
                onChange={this.handleChange}
                className="form-control mt-2" style={{border:"1px solid rgb(0,147,69)"}} />
            </div>
            <div className="col-sm-2">

                </div>
         </div>
            
          
           
           
           
            <div class="modal-footer mt-4">
              <div class="col-sm-4"></div>
              <div class="col-sm-7">
                <button
                  style={{ backgroundColor: "rgb(0, 147, 69)", width: 100 }}
                  className="btn btn-success"
                  onClick={this.handleAddaskill}
                >
                  Add
                </button><br/><br/>
                <span style={{color:"rgb(0,147,69)",fontFamily:"Gotham",marginTop:"5%",fontWeight:"bold",marginLeft:"-16%"}}>{this.state.success}</span>
              </div>
              <div class="col-sm-1"></div>
             
            </div>
      
          </div>
          <div>
            <a class="ft-modal-close" data-dismiss="modal"
            style={{cursor:"pointer"}}
                     onClick={this.closeModal}>
              [&#10006;]{" "}
            </a>
          </div>
        </div>
      </div>
     )}

{/* Add a skill modal end */}
    
        <Header />
        <div style={{ marginTop: "4%" }}></div>
      

          {/* Modal Start */}

<main id="postaproject_img">
          <br />
          <br />

          <div className="container">
            <div class="row text-center text-white">
              <h1 style={{ fontSize: 50, marginTop: "5%" }}>Post a Project</h1>
            </div>
            <div class="row" style={{ marginTop: "4%" }}>
            <div class="row" style={{ marginTop: "3%"}}>
              <div className="text-center">
                <img
                  src={StepTwo}
                  className="rounded"
                  style={{ width: 800, height: 90 }}
                />
              </div>
            </div>

            </div>

            <div class="row mt-2">
              <div class="project">Project Summary</div>
            </div>
            <div class="row mt-3">
              <div class="col-sm-2"></div>
              <div class="col-sm-8">
              <h4 style={{ fontSize: 20,fontFamily:"Gotham" }}>
                Name of the Project <span style={{color:"red"}}>*</span></h4> 
                {this.state.projname==""?<>
                <input id="stepone"
                placeholder="Enter the Name of Project"
                type="text" name ="projname"
               
                onChange={this.handleChange}
                class="form-control mt-1 " />
                </>:<>
                <input id="stepone"
                placeholder="Enter the Name of Project"
                type="text" name ="projname"
                value={this.state.projname}
                onChange={this.handleChange}
                class="form-control mt-1 " />
                </>}
              
              </div>
              <div class="col-sm-2"></div>
            </div>
            <div class="row mt-4">
              <div class="col-sm-2"></div>
              <div class="col-sm-3">
              <h4 style={{ fontSize: 20,fontFamily:"Gotham" }}>
                Select the Category <span style={{color:"red"}}>*</span></h4>
                
                {this.state.Category.length == 0
                    ? "Loading Category Name..."
                    : 

                    <select class="form-select mt-2"
                    name ="categoryid"
                //onChange={this.handleChange}
                    onChange={(e)=>this.handleCategory(e)} style={{border:"1px solid gray"}} >
                     {this.state.categoryname==""?<>
                     <option>Select</option>
                     </>:<>
                     <option value={this.state.categoryid}>{this.state.categoryname}</option>
                     </>}
                    
                    {this.state.Category.map(fbb =>
                    
                      <option key={fbb.key} value={fbb.categoryid}>{fbb.name}</option>
                      
                    )};
                  </select>
                    } 
                     <span className="errormessage">{this.state.categoryiderror}</span>
              </div>
              <div class="col-sm-2"></div>
              <div class="col-sm-3">
              <h4 style={{ fontSize: 20,fontFamily:"Gotham" }}>
                Select the Subcategory <span style={{color:"red"}}>*</span></h4>
                {this.state.subCategory.length == 0
                    ? <>
                    <select
                   
                    className="form-select mt-2" style={{border:"1px solid rgb(0,147,69)"}}>
                    <option>Select</option>
                    </select>
                    
                    </>
                    : 

                    <select class="form-select mt-2"  
                    name ="subcategoryid"
                    onChange={this.handleChange}
                    style={{border:"1px solid rgb(0,147,69)"}} >
                    
                     
                     
                      <option>{this.state.displaysubcategoryname==""?"Select":`${this.state.displaysubcategoryname}`}</option>
                    {this.state.subCategory.map(fbb =>
                    
                      <option key={fbb.key} value={fbb.rolecattypeid}>{fbb.rolecattypename}</option>
                      
                    )};
                  </select>
                    } 
                    <span className="errormessage">{this.state.subcategoryiderror}</span>
              </div>
              <div class="col-sm-2"></div>
            </div>
            <div class="row mt-4">
              <div class="col-sm-2"></div>
              <div class="col-sm-8">
              <h4 style={{ fontSize: 20,fontFamily:"Gotham" }}>
                Department <span style={{color:"red"}}>*</span></h4>
              
                {this.state.Jobdepartment.length == 0
                    ? "Loading School Name..."
                    : 

                    <select 
                    name ="departmentid"
                    onChange={this.handleChange}
                    class="form-select mt-2" 
                    style={{border:"1px solid gray"}} >
                      {this.state.departmentname==""?<>
                      <option>Select</option>
                      </>:<><option value={this.state.departmentid}>{this.state.departmentname}</option></>}
                    {this.state.Jobdepartment.map(fbb =>
                    
                      <option key={fbb.key} value={fbb.id}>{fbb.name}</option>
                      
                    )};
                  </select>
                    } 
                     <span className="errormessage">{this.state.departmentiderror}</span>
              </div>
              <div class="col-sm-2"></div>
            </div>
            <div class="row mt-4">
              <div class="col-sm-2"></div>
              <div class="col-sm-8">
              <h4 style={{ fontSize: 20,fontFamily:"Gotham" }}>
                  What is the project team size? <span style={{color:"red"}}>*</span>
                </h4>
               
                <select
                  id="stepone"
                  class="form-select mt-2"
                  name ="projteamsize"
                  onChange={this.handleChange}

                >
                  {this.state.displayprojteamsize==""?<>
                  <option selected>Select Project team size </option>
                  </>:<><option>{this.state.displayprojteamsize}</option></>}
                 
                  <option>0-5</option>
                  <option>6-15</option>
                  <option>16–30</option>
                  <option>31+</option>
                  <option>I don't know</option>
                  <option>Not applicable</option>
                </select>
              </div>
              <div class="col-sm-2"></div>
            </div>
            <div class="row mt-4">
              <div class="col-sm-2"></div>
              <div class="col-sm-8">
              <h4 style={{ fontSize: 20,fontFamily:"Gotham" }}>
                  What is the type of project? <span style={{color:"red"}}>*</span>
                </h4>
               
                <select
                  id="stepone"
                  class="form-select mt-2"
                  name ="projtype"
                  onChange={this.handleChange}
                >
                  {this.state.displayprojtype==""?<>
                  <option selected>Select Project Type </option>
                  </>:<>
                  <option>{this.state.displayprojtype}</option>
                  </>}
                
                  <option>Type of Project Posted</option>
                  <option>New Idea or Project</option>
                  <option>Existing Project - Additional Talents Needed</option>
                  <option>Ongoing Assistance or Consultation</option>
                  <option>None of the Above</option>
                  <option>I don’t Know</option>
                </select>
              </div>
              <div class="col-sm-2"></div>
            </div>
            <div class="row mt-4">
              <div class="col-sm-2"></div>
              <div class="col-sm-8">
              <h4 style={{ fontSize: 20,fontFamily:"Gotham" }}>
                  If Project is a new Idea or Concept, do you? <span style={{color:"red"}}>*</span>
                </h4>
              
                <select
                  id="stepone"
                  class="form-select mt-2"
                  name ="projidea"
                  onChange={this.handleChange}
                >
                  {this.state.displayprojidea==""?<>
                  <option>Select</option>
                  </>:<><option>{this.state.displayprojidea}</option></>}
                  
                  <option>have a rough idea of what I want to build</option>
                  <option>have a clear idea but I need some input</option>
                  <option>have a clear idea which is written down and ready to go</option>
                </select>
              </div>
              <div class="col-sm-2"></div>
            </div>
            <div class="row mt-4">
              <div class="col-sm-2"></div>
              <div class="col-sm-8">
              <h4 style={{ fontSize: 20,fontFamily:"Gotham" }}>
                  Tell us More about Your Project <span style={{color:"red"}}>*</span>
                </h4>
                {this.state.projdescription==""||this.state.projdescription==null?<>
                <div class="form-group mt-2">
                  <textarea
                    class="form-control"
                    id="stepone"
                    rows="5"
                  
                     name ="projdescription"
                  onChange={this.handleChange}
                  >
                  
                  </textarea>
                </div>
                </>:<>
                <div class="form-group mt-2">
                  <textarea
                    class="form-control"
                    id="stepone"
                    rows="5"
                   value=  {this.state.projdescription}
                     name ="projdescription"
                  onChange={this.handleChange}
                  >
                  
                  </textarea>
                </div>
                
                </>}
               
              </div>
              <div class="col-sm-2"></div>
            </div>
            <div class="row mt-4">
              <div class="col-sm-2"></div>
              <div class="col-sm-8">
              <h4 style={{ fontSize: 20,fontFamily:"Gotham" }}>
                  Upload Aditional Information or any helpful Materials
                  &nbsp;<sub>(Optional)</sub>
                </h4>
                
                <div class="form-group mt-2">
                  <input
                    type="file"
                    id="stepone"
                    class="form-control"
                    placeholder="Upload Files"
                    name ="additionalinfo"
                    onChange={this.handleImage}
                  />
                </div>
              </div>
              <div class="col-sm-2"></div>
            </div>
            <div class="row mt-5">
              <div class="col-sm-2"></div>
              <div class="col-sm-8">
              <h4 style={{ fontSize: 20,fontFamily:"Gotham" }}>
                  What is the size of the project? <span style={{color:"red"}}>*</span>
                </h4>
               
                <div class="form-group mt-2">
                  <select
                    id="stepone"
                    class="form-select mt-2"
                    name ="projsize"
                    onChange={this.handleChange}

                  >
                    {this.state.displayprojsize==""?<>
                    <option>Select Size of the Project </option>
                    </>:<><option>{this.state.displayprojsize}</option></>}
                    
                    <option>Small Project</option>
                    <option>Large Project</option>
                    <option>Company Wide Transformation</option>
                    <option>N/A</option>
                    <option>I don’t know</option>
                    <option>Prefer Not to Say</option>
                  </select>
                </div>
              </div>
              <div class="col-sm-2"></div>
            </div>
            <div class="row mt-4">
              <div class="col-sm-2"></div>
              <div class="col-sm-6">
              <h4 style={{ fontSize: 20,fontFamily:"Gotham" }}>
              What Skill Sets are Required 
                  &nbsp; <span style={{color:"red"}}>*</span>
                &nbsp;<sub>(Select up to 5 skills)</sub> </h4>
               
                <div class="form-group mt-2">
                 {this.state.displayapiskill==""?<>
                 <Multiselect
                    style={{
                      highlightOption: {
                        background: "green",
                      },
                    }}
                    id="stepone"
                    options={this.state.skillData} // Options to display in the dropdown
                    selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                    onSelect={this.onSelectSkill} // Function will trigger on select event
                    onRemove={this.onRemoveSkill} // Function will trigger on remove event
                    displayValue="name" // Property name to display in the dropdown options
                  placeholder=""
                  showArrow
                  />
                     <span className="errormessage">{this.state.skillsiderror}</span>
                 </>:<>
                 <Multiselect
                    style={{
                      highlightOption: {
                        background: "green",
                      },
                    }}
                    id="stepone"
                    options={this.state.skillData} // Options to display in the dropdown
                    selectedValues={this.state.displayapiskill} // Preselected value to persist in dropdown
                    onSelect={this.onSelectSkill} // Function will trigger on select event
                    onRemove={this.onRemoveSkill} // Function will trigger on remove event
                    displayValue="name" // Property name to display in the dropdown options
                  placeholder=""
                  showArrow
                  />
                 </>}
              
                 
                </div>
              </div>
              <div class="col-sm-2" style={{marginTop:"2.5%"}}>

              <a  className="btn btn-success"  
                onClick={this.openModal} 
                id="addaskillbtn"
                href="#ft-postaskills-modal">
                  Please Add Other Skills
                </a>
              </div>
              <div class="col-sm-2"></div>
            </div>
            <div class="row mt-5">
              <div class="col-sm-2"></div>
              <div class="col-sm-8">
              <h4 style={{ fontSize: 20,fontFamily:"Gotham" }}>
              Choose from below, the employability or Soft Skills our student would gain? <span style={{color:"red"}}>*</span></h4>
              
                <div class="form-group mt-1">
                  {this.state.displayemp.length==0?<>
                    <Multiselect
                    style={{
                      highlightOption: {
                        background: "green",
                      },
                    }}
                    id="stepone"
                    options={this.state.empSkillData} // Options to display in the dropdown
                    selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                    onSelect={this.onSelectEmployee} // Function will trigger on select event
                    onRemove={this.onRemoveEmployee} // Function will trigger on remove event
                    displayValue="name" // Property name to display in the dropdown options
                    placeholder=""
                    showArrow
                  />
                  </>:<>
                  <Multiselect
                    style={{
                      highlightOption: {
                        background: "green",
                      },
                    }}
                    id="stepone"
                    options={this.state.empSkillData} // Options to display in the dropdown
                    selectedValues={this.state.displayemp} // Preselected value to persist in dropdown
                    onSelect={this.onSelectEmployee} // Function will trigger on select event
                    onRemove={this.onRemoveEmployee} // Function will trigger on remove event
                    displayValue="name" // Property name to display in the dropdown options
                    placeholder=""
                    showArrow
                  />
                  
                  </>}
               
              
                 
                </div>
              </div>
              <div class="col-sm-2"></div>
            </div>
            <div class="row mt-4">
              <div class="col-sm-2"></div>
              <div class="col-sm-8">
              <h4 style={{ fontSize: 20,fontFamily:"Gotham" }}>
                  Mastered Language ?  &nbsp; <span style={{color:"red"}}>*</span></h4>
               
                <div class="form-group mt-2">
                  {this.state.displayapilanguage==""?<>
                  <Multiselect
                    style={{
                      highlightOption: {
                        background: "green",
                      },
                    }}
                    id="stepone"
                    options={this.state.options} // Options to display in the dropdown
                    selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                    onSelect={this.onSelectLanguage} // Function will trigger on select event
                    onRemove={this.onRemoveLanguage} // Function will trigger on remove event
                    displayValue="name" // Property name to display in the dropdown options
                    placeholder=""
                    showArrow
                  />
                     <span className="errormessage">{this.state.languageiderror}</span>
                  
                  </>:<>
                  <Multiselect
                    style={{
                      highlightOption: {
                        background: "green",
                      },
                    }}
                    id="stepone"
                    options={this.state.options} // Options to display in the dropdown
                    selectedValues={this.state.displayapilanguage} // Preselected value to persist in dropdown
                    onSelect={this.onSelectLanguage} // Function will trigger on select event
                    onRemove={this.onRemoveLanguage} // Function will trigger on remove event
                    displayValue="name" // Property name to display in the dropdown options
                    placeholder=""
                    showArrow
                  />
                  </>}
                
                </div>
              </div>
              <div class="col-sm-2">

                
              </div>
              <div class="col-sm-2"></div>
            </div>
            <div class="row mt-4">
              <div class="col-sm-2"></div>
              <div class="col-sm-8">
              <h4 style={{ fontSize: 20,fontFamily:"Gotham" }}>
              Type of Employment Posted?  &nbsp; <span style={{color:"red"}}>*</span></h4>
               
                <div class="row">
                  <div class="col-sm-12 mt-3 typeofproject">
                    <ul
                      class="nav nav-pills mb-3"
                      id="pills-tab"
                      role="tablist"
                    >
                      {this.state.projpostedtype=="Gig Project"?<>
                      
                      <li class="nav-item stupostaprojectt" role="presentation"  style={{backgroundColor:"rgb(0,147,69)",color:"white"}}>
                        <h2
                          style={{ fontSize: 20, textAlign: "center",padding:"3%"}}
                         
                          id="pills-home-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-home"
                          type="button"
                          role="tab"
                       
                          aria-controls="pills-home"
                          aria-selected="true"
                          onClick={()=>this.projectTypeone()}
                        >
                          <div class="text-center">
                           
                          </div>
                          <div
                            style={{ fontFamily: "Gotham" }}
                            className="mt-5"
                          >
                            {" "}
                            Gig Opportunities /Projects
                          </div> 
                          <div class="mt-3" style={{ fontSize: 12,textAlign:"start",lineHeight:"17px",fontWeight:'bold' }}>
                          	Hire our student talents for temporary, flexible jobs and one-off tasks and projects.
                          </div>
                          
                        </h2>
                      </li>
                      </>:<>
                      <li class="nav-item stupostaprojectt"  role="presentation"  >
                        <h2
                          style={{ fontSize: 20, textAlign: "center",padding:"3%"}}
                         
                          id="pills-home-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-home"
                          type="button"
                          role="tab"
                       
                          aria-controls="pills-home"
                          aria-selected="true"
                          onClick={()=>this.projectTypeone()}
                        >
                          <div class="text-center">
                           
                          </div>
                          <div
                            style={{ fontFamily: "Gotham" }}
                            className="mt-5"
                          >
                            {" "}
                            Gig Opportunities /Projects
                          </div> 
                          <div class="mt-3" style={{ fontSize: 12,fontWeight:"bold",textAlign:"start",lineHeight:"17px",fontWeight:'bold' }}>
                          	Hire our student talents for temporary, flexible jobs and one-off tasks and projects.
                          </div>
                        </h2>
                      </li>
                      </>}
                      
                      <li
                        style={{ marginLeft: 65 ,  backgroundColor:this.state.projpostedtype=="Course Work Related Project"?"rgb(0,147,69)" :null
                        ,color:this.state.projpostedtype=="Course Work Related Project"?"white" :null}}
                        class="nav-item stupostaprojectt"
                        role="presentation"
                        
                      >
                        <h2
                          style={{ fontSize: 20, textAlign: "center" ,padding:"3%",

                      }}
                          id="pills-profile-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#coursework"
                          type="button"
                          role="tab"
                          aria-controls="coursework"
                          aria-selected="false"
                          onClick={()=>this.projectTypetwo()}
                        >
                          <div class="text-center">
                           
                          </div>
                          <div
                            style={{ fontFamily: "Gotham" }}
                            className="mt-5"
                          >
                            Coursework related Projects
                          </div>
                          <div class="mt-3" style={{ fontSize: 12,fontWeight:"bold",textAlign:"start",lineHeight:"17px" }}>
                          	Let our student carry out short term assignments as part of their project-based learning.
                          </div>
                        </h2>
                      </li>
                      <li
                        style={{ marginLeft: 65 , backgroundColor:this.state.projpostedtype=="Portoflio Buidling Project"?"rgb(0,147,69)" :null
                        ,color:this.state.projpostedtype=="Portoflio Buidling Project"?"white" :null}}
                        class="nav-item stupostaprojectt"
                       
                        role="presentation"
                      >
                        <h2
                          style={{ fontSize: 20, textAlign: "center",padding:"3%" }}
                          id="pills-contact-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-contact"
                          type="button"
                          role="tab"
                          aria-controls="pills-contact"
                          aria-selected="false"
                          onClick={()=>this.projectTypethree()}
                        >
                         
                          <div
                            style={{ fontFamily: "Gotham" }}
                            className="mt-5"
                          >
                            Portfolio Building Project
                          </div>
                          <div class="mt-3" style={{ fontSize: 12,fontWeight:"bold",textAlign:"start",lineHeight:"17px" }}>
                           Best for projects to help our student talents build experience, show their talent & develop their portfolio.
                          </div>
                        </h2>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-sm-2"></div>
              <div class="col-sm-8">
              <h4 style={{ fontSize: 20,fontFamily:"Gotham" }}>
                How to Pay?  &nbsp; <span style={{color:"red"}}>*</span></h4>
               
              



                <div class="row">

                      <div className="col-sm-5">
                     
                        {this.state.displaypaytype=="By Hours"?<>
                        <label class="labl">
                        <input type="radio" name="radioname" value="another" 
                        checked
                        onClick={()=>this.handleHours()} />
                        <div className="row">
                            <div class="col-sm-1">
                              </div>
                              <div class="col-sm-2 mt-2" style={{fontSize:43,marginLeft:"-5%"}}>
                                <i className="fa fa-clock-o"></i>
                              </div>
                              <div class="col-sm-9 mt-3" style={{marginLeft:"0%"}}>
                                <h5 style={{fontSize:20}}>By Hours</h5> 
                                <div className="howtopay">

                                Select to pay our student talents by the Hour. Perfect for Part-time and short time work.
                                <span style={{visibility:"hidden"}} >Hire based on an hourly rate and</span>
                                </div>
                              </div>
                              <div class="col-sm-1">
                              </div>
                          </div>
                        </label>
                        </>:<>
                        <label class="labl">
                       <input type="radio" name="radioname" value="another" 
                    
                        onClick={()=>this.handleHours()} />
                        <div className="row">
                            <div class="col-sm-1">
                              </div>
                              <div class="col-sm-2 mt-2" style={{fontSize:43,marginLeft:"-5%"}}>
                                <i className="fa fa-clock-o"></i>
                              </div>
                              <div class="col-sm-9 mt-3" style={{marginLeft:"0%"}}>
                                <h5 style={{fontSize:20}}>By Hours</h5> 
                                <div className="howtopay">

                                Select to pay our student talents by the Hour. Perfect for Part-time and short time work.
                    <span style={{visibility:"hidden"}} >Hire based on an hourly rate and</span>
                                </div>
                              </div>
                              <div class="col-sm-1">
                              </div>
                          </div>
                        </label>
                        </>}
                
               
                      
                      </div>
                      <div className="col-sm-2">

                      </div>
                      <div className="col-sm-5">
                        {this.state.displaypaytype=="By Fixed Price"
                        ?<>

<label class="labl" style={{marginLeft:"2%"}} >
                      <input type="radio" checked name="radioname" value="another" onClick={()=>this.handleFixed()} />
                      <div className="row">
                           
                              <div class="col-sm-2 mt-2" style={{fontSize:43,marginLeft:"5%"}}>
                                <i className="fa fa-lock"></i>
                              </div>
                              <div class="col-sm-9 mt-3" style={{marginLeft:"0%"}}>
                                <h5 style={{fontSize:20}}>By Fixed Price</h5> 
                                <div className="howtopay">

                                Select to pay our student talents a fixed price once the work is completed. Perfect for coursework or one-off portfolio building projects.

                                </div>
                              </div>
                              <div class="col-sm-1">
                              </div>
                          </div>
                      </label>
                        </>:<>
                        <label class="labl" style={{marginLeft:"2%"}} >
                      <input type="radio" name="radioname" value="another" onClick={()=>this.handleFixed()} />
                      <div className="row">
                           
                              <div class="col-sm-2 mt-2" style={{fontSize:43,marginLeft:"5%"}}>
                                <i className="fa fa-lock"></i>
                              </div>
                              <div class="col-sm-9 mt-3" style={{marginLeft:"0%"}}>
                                <h5 style={{fontSize:20}}>By Fixed Price</h5> 
                                <div className="howtopay">

                                Select to pay our student talents a fixed price once the work is completed. Perfect for coursework or one-off portfolio building projects.
                                </div>
                              </div>
                              <div class="col-sm-1">
                              </div>
                          </div>
                      </label>
                        
                        </>
                        }
                     
                      </div>
                  
                 
                </div>
                
              </div>
            </div>
{this.state.Hours==1?<>
  <div class="row mt-4">
             <div class="row">
                    <div class="col-sm-2">
                    </div>
                    <div class="col-sm-8">
                    <h4 style={{ fontSize: 20,fontFamily:"Gotham" }}>
                      What is Your Estimated Budget?  &nbsp; <span style={{color:"red"}}>*</span></h4>
                    </div>
                    <div class="col-sm-2">
                    </div>
              </div>
              <div class="col-sm-2"></div>
              
              <div class="col-sm-3">
                <br />
                <select
                  id="stepone"
                  class="form-select"
                  name ="currency"
                    onChange={this.handleChange}
                >
                  <option>GBP</option>
                  
                 
                </select>
              </div>
              <div class="col-sm-1"></div>
              <div class="col-sm-4">
               
                <br />
                <select
                  id="stepone"
                  class="form-select"
                  name ="price"
                  onChange={this.handleChange}
                >
                  {this.state.displayprice==""?<>
                  <option>Hourly</option>
                  </>:<><option>{this.state.displayprice}</option></>}
                  
                  <option>10</option>
                  <option>11-20</option>
                  <option>21-30</option>
                  <option>31-40</option>
                  <option>50+</option>
                  <option>I dont Know</option>
                  <option>To be discussed</option>
                 
                </select>
              </div>
              <div class="col-sm-2"></div>
            </div>
</>:null}

{this.state.Fixed==1?<>
  <div class="row mt-4">
             <div class="row">
                    <div class="col-sm-2">
                    </div>
                    <div class="col-sm-8">
                    <h4 style={{ fontSize: 20,fontFamily:"Gotham" }}>
                      What is Your Estimated Budget?</h4>
                    </div>
                    <div class="col-sm-2">
                    </div>
              </div>
              <div class="col-sm-2"></div>
              
              <div class="col-sm-3">
                <br />
                <select
                  id="stepone"
                  class="form-select"
                  aria-label="Default select example"
                  name ="currency"
                  onChange={this.handleChange}
                >
                  <option>GBP</option>
                  
                 
                </select>
              </div>
              <div class="col-sm-1"></div>
              <div class="col-sm-4">
               
                <br />
                <select
                  id="stepone"
                  class="form-select"
                  name ="price"
                  onChange={this.handleChange}
                >
                 {/*  {this.state.displayprice==""?<>
                   <option>Fixed Price</option>
                  </>:<>
                  <option>{this.state.displayprice}</option></>} */}
                  <option>{this.state.displayprice==""?"Fixed Price":`${this.state.displayprice}`}</option>
                 
                  <option>100 - 250</option>
                  <option>251 - 500</option>
                  <option>501 - 1000</option>
                  <option>1000 - 2000</option>
                  <option>2000+</option>
                  <option>I dont Know</option>
                  <option>To be discussed</option>
                 
                </select>
              </div>
              <div class="col-sm-2"></div>
            </div>
</>:null}

<div className="row mt-4">
<div class="col-sm-2"></div>
              <div class="col-sm-8">
              <h4 style={{ fontSize: 20,fontFamily:"Gotham" }}>
              Are you open to all interested candidates?  &nbsp; <span style={{color:"red"}}>*</span></h4>
                </div>
</div>
<div className="row" >
<div class="col-sm-2"></div>

<div class={this.state.backgroundyes==1 || this.state.opentoallintrested=="Yes" ?"col-sm-3 postaprojectformtwo":"col-sm-3 postaprojectform"} style={{marginLeft:"1%"}} onClick={this.handleYes} >
<br/>
<div style={{textAlign:"center"}}>
  <span style={{fontFamily:"Gotham" ,fontSize:25,color:this.state.backgroundyes==1 || this.state.opentoallintrested=="Yes"?"white":null}} >Yes</span>

</div>
<div style={{textAlign:"center"}}>
  <span style={{fontFamily:"Gotham",color:this.state.backgroundyes==1 || this.state.opentoallintrested=="Yes"?"white":null}} >I am interested in all candidates</span>
  
</div>
</div>
<div class="col-sm-2"></div>
<div class={this.state.backgroundno==1 || this.state.opentoallintrested=="No"?"col-sm-3 postaprojectformtwo":"col-sm-3 postaprojectform"} onClick={this.handleNo}>
<br/>
<div style={{textAlign:"center"}}>
  <span style={{fontFamily:"Gotham" ,fontSize:25,color:this.state.backgroundno==1 || this.state.opentoallintrested=="No"?"white":null}}>No</span>

</div>
<div style={{textAlign:"center"}}>
  <span style={{fontFamily:"Gotham",color:this.state.backgroundno==1 || this.state.opentoallintrested=="No"?"white":null}} >I have a selection criteria.</span>
  
</div>
</div>
</div>
         {this.state.backgroundno==1?<>
       <div className="row mt-4">
         <div className="col-sm-2"></div>
         <div className="col-sm-8">
         <h4 style={{ fontSize: 20,fontFamily:"Gotham" }}>
                    What schools can apply?</h4>
                   <br />
              {this.state.universityname=="" || this.state.universityname==null ?<>
              <Select options={this.state.Universitydata}
                              components={{  IndicatorSeparator:() => null }}
                              styles={style} placeholder="Select" onChange={this.handleDrop}   /> 
              </>:<>
              <Select options={this.state.Universitydata}
                              components={{  IndicatorSeparator:() => null }}
                              styles={style} placeholder={this.state.universityname} onChange={this.handleDrop}   /> 
              </>}
               
              
         </div>

       </div>
       <br/><br/>
       <div className="row">
         <div className="col-sm-2"></div>
         <div className="col-sm-8"> <h4 style={{ fontSize: 20,fontFamily:"Gotham" }}>
                      What is your preferred Student Type?&nbsp;<sub>(Please Select)</sub></h4></div> 

       </div>
              
                    
                    <div class="row" style={{fontSize:17}} >
                   
                   <div className="col-sm-2"></div>
                   <div className="col-sm-8">
                   
                 {/* {this.state.studentData.map((user)=>(
                      
                        <div class="col-sm-4" style={{paddingTop:"3%"}}>
                           {this.state.StudentStatus.includes(user.studystatusname)=="true"?<>
                            <input type="checkbox" 
                        onClick={(e)=>this.updateStudentstatus(e,user.studystatusid,user.studystatusname)} 
                      checked
                        class="checkbox-round" />
                        
                          <span style={{paddingLeft:"5%",fontSize:17,fontFamily:"GothamLight",fontWeight:"bold"}}>{user.studystatusname}</span>
                  
                   </>:<>
                   <input type="checkbox" 
                        onClick={(e)=>this.updateStudentstatus(e,user.studystatusid,user.studystatusname)} 
                      
                        class="checkbox-round" />
                        
                          <span style={{paddingLeft:"5%",fontSize:17,fontFamily:"GothamLight",fontWeight:"bold"}}>{user.studystatusname}</span>
                   </>}
                       
                      


</div> 
                    ))}
                  */} 
                   
                    
                 
                   <div class="row" >
   {
 
 Object.entries(this.state.opportunitiestype).map((cname) => (
     <div class="col-sm-4 oppcheckbox">
       

       <div key={cname[0]}>
                <input
                  checked={cname[1]}
                  onChange={this.handleChangeCheckbox}
                  type="checkbox"
                  class="checkbox-round"
                  name={cname[0]}
                />
               &nbsp;&nbsp; <span style={{fontFamily:"GothamLight",fontWeight:"bold"}}>{cname[0]}</span> 
              </div>


</div> 

   ))}
 
</div>
                   </div>
                  
                    <div class="row mt-5">
                      <div className="col-sm-2"></div>
                    <div class="col-sm-8">
                    <h4 style={{ fontSize: 20,fontFamily:"Gotham" }}>
                      Select Eligible Subject & Degrees &nbsp;<sub>(Please Select)</sub></h4>
                    </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-2"></div>
                      <div className="col-sm-8">
                      <div className="row">
                      <div className="col-sm-4">
                      {this.state.subjectDetails.length == 0 ? (
                      null
                    ) : (
                      <>
                        <div
                          style={{
                            border: "2px solid rgb(0,147,69)",
                            width: "100%",
                            marginTop: "5%",
                            height: 520,
                           overflow:"auto"
                           
                          }}
                        >
                          {" "}
                          
                          {this.state.subjectDetails.map((fbb) => (
                            <h5
                              key={fbb.subjectid}
                              id="subtext"
                             onClick={() => this.handleSubject(fbb.subjectid,fbb.subjectname)}
                              style={{
                                backgroundColor:
                                  selected === fbb.subjectid
                                    ? "rgb(0,147,69)"
                                    : "",
                                color:
                                  selected === fbb.subjectid ? "white" : "",
                                paddingTop:
                                  selected === fbb.subjectid ? "5%" : "",
                                fontSize:
                                  selected === fbb.subjectid ? "14px" : "",
                                paddingBottom:
                                  selected === fbb.subjectid ? "5%" : "",
                                fontfamily:
                                  selected === fbb.subjectid
                                    ? "GothamLight"
                                    : "",
                              }}
                            >
                              
                              {fbb.subjectname}
                            </h5>
                          ))}
                        </div>
                      </>
                    )}
                      </div>

                  <div class="col-sm-1" id="arrow">
                    <i class="fa fa-arrow-right"></i>
                    </div>
<div className="col-sm-6">
{this.state.degreeData.length == 0 ? (
                      null
                    ) : (
                      <>
                        <div
                          style={{
                            border: "2px solid rgb(0,147,69)",
                            width: "105%",
                            marginTop: "5%",
                            height: 520,
                           overflowY:"scroll"
                          }}
                        >
                          {" "}
                          
                          {this.state.degreeData.map((user) => (
                             <p id="subtext">
                               {this.state.eligibleSubject.includes(user.degreeid)==true?<>
                                <i class="fa fa-check-square" style={{fontSize:17,color:"rgb(0,147,69)"}} onClick={(e)=>this.RemoveSubject(e,user.degreeid,user.degreename)}  ></i>
                               </>:<>
                               <i class="fa fa-square-o" style={{fontSize:17}} onClick={(e)=>this.updateSubject(e,user.degreeid,user.degreename)}  ></i>
                               </>}
                           
                        

                      <span> &nbsp;&nbsp;{user.degreename}</span>
                             
                           </p>
                          ))}
                        </div>
                      </>
                    )}

</div>
                    
                    </div>
                      </div>

                    </div>
                    
  <div class="row">
                      <div class="col-sm-2"></div>
                      <div class="col-sm-8">
                      </div>
                  <div class="col-sm-2">
                  <button to="/postaproject_two" 
                  className="btn btn-success" 
                  onClick={this.handleSubmit}
                  id="next_one" >Next</button></div>
                  </div>
         <br/><br/>  

           
              
                    </div>
         
         </>:null} 
         {this.state.backgroundyes==1?<>
          <div class="row">
                      <div class="col-sm-2"></div>
                      <div class="col-sm-8">
                      </div>
                  <div class="col-sm-2">
                  <button to="/postaproject_two" 
                  className="btn btn-success" 
                  onClick={this.handleSubmit}
                  id="next_one" >Next</button></div>
                  </div>
         </>:null}  
            <div class="row mt-4" style={{display:"none"}} >
              <div class="col-sm-2"></div>
              <div class="col-sm-8">
              <h4 style={{ fontSize: 20,fontFamily:"Gotham" }}>
              Are you open to all interested candidates?  &nbsp; <span style={{color:"red"}}>*</span></h4>
               
                <div class="row">
                  <div class="col-sm-12 mt-3">
                    <ul
                      class="nav nav-pills mb-3"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li class="nav-item" role="presentation" id="placement_one">
                       {this.state.opentoallintrested=="Yes"?<>
                       <h3
                          style={{ fontSize: 20, }}
                         class="active"
                          id="pills-home-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-home"
                          type="button"
                          role="tab"
                          aria-controls="pills-home"
                          aria-selected="true"
                          onClick={()=>this.openYes()}
                        >
                          <div className="row" style={{marginLeft:-30}}>
                            <div class="col-sm-3">
                              </div>
                              
                              <div class="col-sm-7 " style={{marginLeft:-10,marginTop:40}}>
                                <h5 style={{fontSize:25}}>YES</h5> 
                                <div style={{fontSize:16,fontFamily:"GothamLight",fontWeight:"bold"}}>

                                I am interested in all candidates

                                </div>
                              </div>
                              <div class="col-sm-1">
                              </div>
                          </div>
                        
                         
                         
                        </h3>
                       
                       </>:<>
                       <h3
                          style={{ fontSize: 20, }}
                          id="pills-home-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-home"
                          type="button"
                          role="tab"
                          aria-controls="pills-home"
                          aria-selected="true"
                          onClick={()=>this.openYes()}
                        >
                          <div className="row" style={{marginLeft:-30}}>
                            <div class="col-sm-3">
                              </div>
                              
                              <div class="col-sm-7 " style={{marginLeft:-10,marginTop:40}}>
                                <h5 style={{fontSize:25}}>YES</h5> 
                                <div style={{fontSize:16,fontFamily:"GothamLight",fontWeight:'bold'}}>

                                I am interested in all candidates

                                </div>
                              </div>
                              <div class="col-sm-1">
                              </div>
                          </div>
                        
                         
                         
                        </h3>
                       </>}
                        
                      </li>
                      <li
                        style={{ marginLeft: "16%" }}
                        class="nav-item"
                        role="presentation"
                        id="placement_one"
                      >
                        {this.state.opentoallintrested=="NO"?<>
                        <h3
                          style={{ fontSize: 20, }}
                          
                          id="pills-profile-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-profile"
                          type="button"
                          class="active"
                          role="tab"
                          aria-controls="pills-profile"
                          aria-selected="true"
                          onClick={()=>this.openNo()}
                        >
                          <div className="row" style={{marginLeft:-30}}>
                            <div class="col-sm-3">
                              </div>
                              
                              <div class="col-sm-7 " style={{marginLeft:-10,marginTop:40}}>
                                <h5 style={{fontSize:25}}>No</h5> 
                                <div style={{fontSize:16,fontFamily:"GothamLight",fontWeight:"bold"}}>

                               I have a selection criteria.

                                </div>
                              </div>
                              <div class="col-sm-1">
                              </div>
                          </div>
                        
                         
                         
                        </h3>
                        </>:<>
                        <h3
                          style={{ fontSize: 20, }}
                          
                          id="pills-profile-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-profile"
                          type="button"
                          role="tab"
                          aria-controls="pills-profile"
                          aria-selected="true"
                          onClick={()=>this.openNo()}
                        >
                          <div className="row" style={{marginLeft:-30}}>
                            <div class="col-sm-3">
                              </div>
                              
                              <div class="col-sm-7 " style={{marginLeft:-10,marginTop:40}}>
                                <h5 style={{fontSize:25}}>No</h5> 
                                <div style={{fontSize:16,fontFamily:"GothamLight",fontWeight:'bold'}}>

                               I have a selection criteria.

                                </div>
                              </div>
                              <div class="col-sm-1">
                              </div>
                          </div>
                        
                         
                         
                        </h3>
                        </>}
                     
                      </li>
                     
                    </ul>
                  </div>
                </div>
                
              </div>
            </div>
          
            <div class="row mt-4">


           
                    <div class="col-sm-2">
                    </div>
                    <div class="col-sm-8">
                    <div class="tab-content" id="pills-tabContent">
                    <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
            
                    
                    </div>

                  </div>
  </div>
                    <div class="col-sm-2">
                    </div>
             
              
            </div>






            <div className="mt-5">

            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default PostaProject_one;
