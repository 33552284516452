import React from "react";
import Login5 from '../../images/Login-36.png';
import { Link } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import swal from 'sweetalert';
const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth' // for smoothly scrolling
  });
};
class EmpnotRetake extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
    secondquestion:0,
    thirdquestion:0
     
    };
   this.handleNo=this.handleNo.bind(this)
   this.handleYes=this.handleYes.bind(this)
  }
  handleNo(){
    window.location.href=`./home`
  }
handleYes(){
    window.location.href=`./studentdashboard`
}   
    
   
  


  

 
  
  render() {

    
    return (
      <div>
         {/* ======= Header ======= */}
      <Header />
      <div>
    

      </div>
      {/* End Header */}
        <main id="main" style={{ background: "#303030", clear: "both" }}>
          <br />
          <br />
          {/* ======= About Section ======= */}
          <section id="clients" className="clients">
            <div
              className="container col-md-5 col-xs-12 col-sm-12 col-lg-6"
              data-aos="fade-up"
            >
              <style
                type="text/css"
                dangerouslySetInnerHTML={{
                  __html:
                    "\n    .boxxlr{\n     border: 0px solid green;\n    background: #edf8f2;\n    margin-right: 2.5%;\n    margin-bottom: 7%;\n    color: #000;\n    margin-left: 9%;\n    width: 100%;\n    }\n/*.boxxlr:hover {\n    border: 0px solid green;\n    background: green;\n    margin-right: 1%;\n    margin-bottom: 1%;\n     color: #fff;\n}*/\n\n.boxclrtitle {\nfont-size: 12px;font-weight: bold;\n}\n\n.boxclrtitle1 {\nfont-size: 18px;font-weight: bold;\n}\n/*.boxxlr .boxclrtitle:hover {\nfont-size: 12px;font-weight: bold;\n}*/\n\n\n  ",
                }}
              />
              <div className="row no-gutters">
                <div className="content col-xl-12 d-flex align-items-center">
                  <div className="content col-xl-12 d-flex align-items-center" id="mainbox">
                    <div
                      className="row"
                      style={{
                        display: "flex",
                        justifyContent: "center" /* alignItems: 'center', */,
                        background: "#fff",
                        width: "95%" /* marginLeft: '50%', */,
                        borderRadius: "20px",
                        margin: "auto",
                      }}
                    >
              
                      <div className="row h-100 row align-items-center">
                        <div
                          className="col-md-10"
                          style={{ margin: "auto", marginBottom: "6%" }}
                        >
                          
                          <img
                              src={Login5}
                             className="empguestsignuplogo"
                            />
                           
                           
                           <div className="row" style={{marginTop:"3%"}} >  
                           <i class="fa fa-info-circle" style={{color:"rgb(0,147,69)",textAlign:"center",fontSize:"4rem"}}></i>
                          
                                
                           </div>
                          <table style={{marginTop:"8%"}}>
                            <tr>
                                <th>
                                    <span style={{fontFamily:"Gothic A1",fontSize:20}} >Unfortunately, you are not able to re-take your
Employability assessment right now as you haven't met 
any of our set criteria to be able to retake the test.</span>
                                </th>
                               
                            </tr>
                            <tr>
                            <th style={{paddingTop:"3%"}}>
                                    <span style={{fontFamily:"Gothic A1",fontSize:20}} >If you think, we have made a mistake, please contact
admin at admin@gigeconome.com.</span>
                                </th>
                            </tr>
                          </table>
                          <table style={{marginTop:"15%",marginBottom:"10%"}}>
                            <tr>
                                <th className="empnottake">
                                    <button onClick={this.handleYes} className="btn empnottestbtnstwo">Back to Dashboard</button>
                                </th>
                                <th>
                                <button onClick={this.handleNo} className="btn empnottestbtnstwo">Back to Homepage</button>
                                </th>
                            </tr>
                          </table>
                         
                        
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        {/* End #main */}
        {/* ======= Footer ======= */}
 <Footer />
      </div>
    );
  }
}
export default EmpnotRetake;
