


import React, { useState } from "react";
// import './Header.css';
import Logo from "../../images/logo.png";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Dropdown from "react-bootstrap/Dropdown";
import "./dashboard.css";
import { TalentSearch } from "./TalentSearch";

function DashboardMain() {
  let history = useHistory();
  let login = () => {
    history.push("/signin");
    
   
  };

  function handlePick(){
    window.sessionStorage.removeItem("someData");
    window.sessionStorage.removeItem("nameData");
    window.sessionStorage.removeItem("DataName");
    window.sessionStorage.removeItem("UserType");
    window.sessionStorage.removeItem("Guest Email");
    window.sessionStorage.removeItem("Guest Id");
    window.location.href=`./Signin`
  
      
  


}


  const [isNavCollapsed, setIsNavCollapsed] = useState(true);

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);
  return (
    <div className="my-4" style={{ marginLeft: 0 }}>
      <nav id="example" className="navbar navbar-expand-xl navbar-light bg-white fixed-top d-flex align-items-center">
        <div className="container d-flex align-items-left">
          <a className="navbar-brand logo me-auto">
            <Link to="/home">
              <img src={Logo} />
            </Link>
          </a>

          {/* Uncomment below if you prefer to use an image logo */}
          {/* <a href="index.html" className="logo me-auto"><img src="assets/img/logo.png" alt=""></a>*/}
          {/* <a className="navbar-brand" href="#">Navbar</a> */}
          <button
            className="custom-toggler navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarCollapse3"
            aria-controls="navbarsExample09"
            aria-expanded={!isNavCollapsed ? true : false}
            aria-label="Toggle navigation"
            onClick={handleNavCollapse}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className={`${isNavCollapsed ? "collapse" : ""} navbar-collapse`}
            id="navbarCollapse3"
          >
            <div className="navbar-nav">
            <div className="navbar-nav me-auto mb-2 mb-lg-0 belliconres" >
         
  
 <TalentSearch />


              

                
                <li className="nav-item dropdown" >
                  <a
                    
                   
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                  <i class="fa fa-bell fa-3x mt-4" style={{fontSize:30}}></i>
                  </a>
                  
                </li>
              </div>

              {JSON.parse(sessionStorage.getItem("nameData"))==null?
                   
      <>
         <div style={{marginTop:"1%"}}>
              <Link
             to="/postaproject"
            className="get-started-btn scrollto"
            className="btn mt-2"
            id="project"
            style={{
              /* padding: '8px 25px', */
              /* height: '7px', */
              marginLeft: "25px",
              background: "#009345",
              color: "#fff",
              borderRadius: "20px",
              padding: "5px 15px",
              whiteSpace: "nowrap",
              transition: "0.3s",
              fontSize: "14px",
              fontWeight: 600,
              display: "inline-block",
              cursor: "pointer",
            }}
          >
            Post a Project
          </Link>
         
          <Link
            to="/signup"
            className="get-started-btn scrollto"
            className="btn mt-2"
          
            style={{
              /* padding: '8px 25px', */
              /* height: '7px', */
              marginLeft: "30px",
              background: "gray",
              color: "#fff",
              borderRadius: "10px",
              padding: "5px 25px",
              whiteSpace: "nowrap",
              transition: "0.3s",
              fontSize: "14px",
              fontWeight: 600,
              display: "inline-block",
              cursor: "pointer",
            }}
          >
            Sign Up
          </Link>
          
          <button
           
            className="get-started-btn scrollto"
            className="btn mt-2"
            onClick={(e) => {
              login();
            }}
            style={{
              /* padding: '8px 25px', */
              /* height: '7px', */
              marginLeft: "30px",
              background: "#009345",
              color: "#fff",
              borderRadius: "10px",
              padding: "5px 25px",
              whiteSpace: "nowrap",
              transition: "0.3s",
              fontSize: "14px",
              fontWeight: 600,
              display: "inline-block",
              cursor: "pointer",
            }}
          >
            Sign In
          </button>
          </div>

       
       </>:<>
       <div className="postaprojectress">
              <Link
             to="/postaproject"
            className="get-started-btn scrollto "
            className="btn mt-2"
            id="project"
            style={{
              /* padding: '8px 25px', */
              /* height: '7px', */
              marginLeft: "25px",
              background: "#009345",
              color: "#fff",
              borderRadius: "20px",
              padding: "5px 15px",
              whiteSpace: "nowrap",
              transition: "0.3s",
              fontSize: "14px",
              fontWeight: 600,
              display: "inline-block",
              cursor: "pointer",
            }}
          >
            Post a Project
          </Link>
          <AccountCircleIcon   style={{fontSize:40,color:'rgb(0, 147, 69)',marginLeft:20,marginTop:5}} />
         <span style={{top:"7%",position:"relative"}}>Hi,</span>
         <span style={{top:"7%",position:"relative",fontWeight:'bold'}}>
            {JSON.parse(sessionStorage.getItem("nameData"))}</span>

          <a
           
            className="get-started-btn scrollto"
            className="btn mt-2"
           
            style={{
              /* padding: '8px 25px', */
              /* height: '7px', */
              marginLeft: "15px",
              
              padding: "5px 25px",
              whiteSpace: "nowrap",
              transition: "0.3s",
              fontSize: "14px",
              fontWeight: 600,
              display: "inline-block",
              cursor: "pointer",
            }}
          >
          
            <div class="dropdown">
            <Dropdown.Toggle variant="success" id="dropdown-basic">
                ☰
              </Dropdown.Toggle>
          
  <div class="dropdown-content">
    <Link to="/aboutus" style={{color:"rgb(0, 147, 69)",marginTop:10,fontSize:16,borderBottom:"1px solid #3A3939"}}>About Us</Link> 
    <Link to="/studentHowitswork" style={{color:"rgb(0, 147, 69)",paddingTop:10,fontSize:16,borderBottom:"1px solid #3A3939"}}>Students</Link>

    <Link to="/educators" style={{color:"rgb(0, 147, 69)",marginTop:10,fontSize:16,borderBottom:"1px solid #3A3939"}}>Educators</Link>
    <Link to="/startups" style={{color:"rgb(0, 147, 69)",marginTop:10,fontSize:16,borderBottom:"1px solid #3A3939"}}>Startups</Link>
    <a onClick={handlePick} style={{color:"rgb(0, 147, 69)",marginTop:10,fontSize:16}}>Sign out</a>
  </div>
</div>
          </a>
       
          </div>
         
       
       </>}
        

           
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}
export default DashboardMain;
