
import React from "react";
import { Link } from "react-router-dom";
import Logo from '../../images/logo.png';
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Qualification from "./Qualification";
import LoadingIcon from '../../images/loading2.gif';
import Select from 'react-select';
import GraduationYear from "./GraduationYear";
const style = {
  control: (base, state) => ({
    ...base,
    border: "1px solid gray",
    borderRadius:"1px",
   
   
    // This line disable the blue border
    boxShadow: state.isFocused ? 0 : 0,
    "&:hover": {
      border: "1px solid gray",
      borderRadius:"1px",
     
    }
  }),
 
};
class  StudentbackSignup_two extends React.Component {
  constructor() {
    super();
    let year = JSON.parse(sessionStorage.getItem('Graduationyear'));
    let stu_qualification = JSON.parse(sessionStorage.getItem('Qualification'));
    let comments = JSON.parse(sessionStorage.getItem('someData'));
    let University = JSON.parse(sessionStorage.getItem('UniversityName'));
    this.state = {
      StudyStatus:" ",
      college_University:" ",
      Course:" ",
      QualificationType:stu_qualification,
      Lengthofcourse:" ",
      GraduationYear:"",
      userid:comments,
      reg_level:2,
         selectOptions : [],
         selectQualification:[],
      id: "",
      name: '',
      dropdownlist_input : "graduationyear",
      DataisLoaded: false,
      error:"",
      StudyStatus:"",
    
      Course:"",
      QualificationType:"",
      Lengthofcourse:"",
      selectUniversity:[],
      UniversityName:University,
      subCourseName:[],
      selectGraduation:[],
      displayCourse:"",
      LocationData:[],
      location:"",
      locationname:""

      };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
 componentDidMount(){
  const qual_data={dropdownlist_input:"qualification"}

  fetch('https://www.gigeconome.com:5200/api/getdropdownlist', { method: 'POST', 

   body: JSON.stringify(qual_data), // data can be `string` or {object}!

   headers:{ 'Content-Type': 'application/json' } })

   .then(res => res.json())

   .catch(error => console.error('Error:', error))

   .then((json)=>{
    
    
    const data = json.result
   // console.log(data)
    
    
    this.setState({selectQualification: data})
   
   })

   const locationdata={dropdownlist_input:"country"}
   fetch( 'https://www.gigeconome.com:5200/api/getdropdownlist', { method: 'POST', 

   body: JSON.stringify(locationdata), // data can be `string` or {object}!

   headers:{ 'Content-Type': 'application/json' } })

   .then(res => res.json())

   .catch(error => console.error('Error:', error))

   .then((json)=>{
    
    
    const data = json.result
    //console.log(json)
    
  const options = data.map(d => ({
      "value" : d.id,
      "label" : d.name

    })) 
   
    this.setState({LocationData:options})
    
   })
  const data_one={dropdownlist_input:this.state.dropdownlist_input,
  
  }

  let BaseUrl = JSON.parse(sessionStorage.getItem('baseurl'));

  fetch("https://www.gigeconome.com:5200/api/getdropdownlist", { method: 'POST', 

   body: JSON.stringify(data_one), // data can be `string` or {object}!

   headers:{ 'Content-Type': 'application/json' } })

   .then(res => res.json())

   .catch(error => console.error('Error:', error))

   .then((json)=>{
    
   
    const data = json.result
    this.setState({selectGraduation: data})
    
   })
  
    


   const data_six={dropdownlist_input:"coursenew"}
   fetch( 'https://www.gigeconome.com:5200/api/getdropdownlist', { method: 'POST', 

   body: JSON.stringify(data_six), // data can be `string` or {object}!

   headers:{ 'Content-Type': 'application/json' } })

   .then(res => res.json())

   .catch(error => console.error('Error:', error))

   .then((json)=>{
    
   let  data=json.result
    const options = data.map(d => ({
      "value" : d.id,
      "label" : d.subcategoryname

    })) 
   
this.setState({subCourseName:options})
    
    
  
   })
   const data_two={userid:this.state.userid,
    reg_level:this.state. reg_level
}
fetch('https://www.gigeconome.com:5200/api/getststepinfo', { method: 'POST', 

body: JSON.stringify(data_two), // data can be `string` or {object}!

headers:{ 'Content-Type': 'application/json' } })

.then(res => res.json())

.catch(error => console.error('Error:', error))

.then((posRes)=>{
 console.log((posRes.result))
 this.setState({
    StudyStatus:posRes.result.StudyStatus,
    college_University:posRes.result.college_University,
    displayCourse:posRes.result.Course,
    Course:posRes.result.subcourseid,
    QualificationType:posRes.result.QualificationType,
    Lengthofcourse:posRes.result.Lengthofcourse,
    GraduationYear:posRes.result.GraduationYear,
    locationname:posRes.result.locationname,
    location:posRes.result.location

    
  })
       
    })
    this.setState({
        DataisLoaded: true 
      })

  }
  handleCourse = (e) => {
    
    
    this.setState({Course:e.value})
 
}
  handleDrop = (e) => {
    
   this.setState({college_University:e.label})
 
}

  handleSubmit(event) {
    event.preventDefault();
    if (this.state.StudyStatus === "") {
     alert("Plese Fill !");
    }
    else{
    const data = { StudyStatus:this.state. StudyStatus, college_University:this.state.college_University , 
      Course:this.state.Course, QualificationType:this.state. QualificationType
      ,Lengthofcourse:this.state.Lengthofcourse, GraduationYear:this.state. GraduationYear,
      userid:this.state.userid, reg_level:this.state. reg_level,location:this.state.location }
      let BaseUrl = JSON.parse(sessionStorage.getItem('baseurl'));
   console.log(data)
      fetch(BaseUrl +'stuserinfo', { method: 'POST', 

    body: JSON.stringify(data), // data can be `string` or {object}!

    headers:{ 'Content-Type': 'application/json' } })

    .then(res => res.json())

    .catch(error => console.error('Error:', error))

    .then((response)=>{
        console.log('Success:', response)
        this.setState({
          DataisLoaded: true 
        })
           window.location.href=`./Studentsignup_three`
            //this.setState({ data: response.userid });
       // console.log(response)
    })
  }
        
    
       
    
   }
   handleDrop = (e) => {
    
    
       var GraduationYear = JSON.stringify(e.label);
       sessionStorage.setItem('Graduationyear', GraduationYear);
      this.setState({name:e.label})
    
  }

  handleLocation = (e) => {
    
    
    this.setState({location:e.value},()=>{
      const data_university={dropdownlist_input:"guniversity",locationid :e.value}
      fetch( 'https://www.gigeconome.com:5200/api/getdropdownlist', { method: 'POST', 
   
      body: JSON.stringify(data_university), // data can be `string` or {object}!
   
      headers:{ 'Content-Type': 'application/json' } })
   
      .then(res => res.json())
   
      .catch(error => console.error('Error:', error))
   
      .then((json)=>{
       
       
       const data = json.result
       //console.log(json)
       
     const options = data.map(d => ({
         "value" : d.location_id,
         "label" : d.university
   
       })) 
      
       this.setState({selectUniversity: options})
       
      })
    })
  
  }
 handleChange = (e) => {

this.setState({
     [e.target.name]: e.target.value

    })
 }

  render(){
    const {StudyStatus}=this.state;
    const {Lengthofcourse}=this.state;
          
  return (
    <div>
    
      <Header />



      <div></div>
      {/* End Header */}
        <main id="main" style={{ background: "#303030", clear: "both" }}>
          <br />
          <br />
          {/* ======= About Section ======= */}
          <section id="clients" className="clients">
            <div className="container" data-aos="fade-up">
              <style
                type="text/css"
                dangerouslySetInnerHTML={{
                  __html:
                    "\n    .boxxlr{\n     border: 0px solid green;\n    background: #edf8f2;\n    margin-right: 2.5%;\n    margin-bottom: 7%;\n    color: #000;\n    margin-left: 9%;\n    width: 100%;\n    }\n/*.boxxlr:hover {\n    border: 0px solid green;\n    background: green;\n    margin-right: 1%;\n    margin-bottom: 1%;\n     color: #fff;\n}*/\n\n.boxclrtitle {\nfont-size: 12px;font-weight: bold;\n}\n\n.boxclrtitle1 {\nfont-size: 18px;font-weight: bold;\n}\n/*.boxxlr .boxclrtitle:hover {\nfont-size: 12px;font-weight: bold;\n}*/\n\n\n  ",
                }}
              />
              <div className="row no-gutters">
                <div className="content col-xl-12 d-flex align-items-center">
                  <div className="content col-xl-12 d-flex align-items-center">
                    <div
                      className="row ssignupsteptwo"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        borderRadius: "25px" /* alignItems: 'center', */,
                        background: "#fff",
                        /* marginLeft: '50%', */
                        borderRadius: "20px",
                        margin: "auto",
                       
                      }}
                    >
                      <h2
                        style={{
                          fontSize: "20px",
                          textAlign: "center",
                          color: "#fff",
                         fontWeight:"bold",
                          background: "rgb(0, 147, 69)",
                          padding: "1%",
                          width: "56%",
                          marginTop: "10%",
                        }}
                      >
                        {" "}
                        Your Currrent Education{" "}
                      </h2>
                      <h5
                        style={{
                          textAlign: "center",
                          fontSize: "18px",
                          fontFamily:"Gotham",
                          color:"rgb(0,147,69)",
                          marginTop: "2%",
                          marginBottom: "2%",
                        }}
                      >
                        Please Tell Us about details of current Study
                      </h5>
                      <div className="row h-100 row align-items-center">
                        <div className="col-md-8" style={{ margin: "auto" }}>
                          
                      
                          <form onSubmit={this.handleSubmit}>
                            {/*                 <div class="form-group" style="padding: 2%;border-radius: 1px;">

                <input style="padding: 2%;" type="text" class="form-control" placeholder="Company Name" id="email">
                </div> */}
                            <div
                              className="form-group"
                              style={{ padding: "2%", borderRadius: "1px" }}
                            >
                              <select
                                className="form-select"
                                style={{
                                  width: "100%",
                                  height: "38px",
                                  border: "1px solid gray",
                              
                                }}
                                required
                                onChange={this.handleChange} 
                                id="StudyStatus"
                                name="StudyStatus"
                                required
                              >
                                <option >{StudyStatus} </option>
                                <option >Undergraduate</option>
                                <option >Post Graduate</option>
                                <option >Sixth Form (A levels)</option>
                                <option >BTEC Student</option>
                                <option >Specialist Colleges</option>
                                <option >Adult Education Institutes</option>
                                <option  >NVQ or SVQ Student</option>
                                <option  >HND (or Equivalent)</option>
                                <option  >HNC (or Equivalent)</option>
                                <option >Diploma</option>
                                <option  >Other</option>
                                
                              </select>
                            </div>
                            <div
                              className="form-group"
                              style={{ padding: "2%", borderRadius: "1px" }}
                            >


<Select options={this.state.LocationData}
                              components={{  IndicatorSeparator:() => null }}
                              styles={style} placeholder={this.state.locationname} onChange={this.handleLocation}   /> 



                              {/* <Select options={subCourseName}
                              components={{  IndicatorSeparator:() => null }}
                              styles={style} placeholder="Select Courses" onChange={this.handleCourse}   /> */}

                                    
                            </div>
                            <div
                              className="form-group"
                              style={{ padding: "2%", borderRadius: "1px" }}
                            >

{/*
{this.state.selectUniversity.length == 0
                    ? "Loading University Name..."
                    : 

                    <select class="form-select" style={{border:"1px solid gray"}}  name ="college_University" 
                    onChange = {this.handleChange}>
                      <option>{this.state.college_University}</option>
                    {this.state.selectUniversity.map(fbb =>
                    
                      <option key={fbb.key} value={fbb.name}>{fbb.name}</option>
                      
                    )};
                  </select>
                    }   */}


                   <Select options={this.state.selectUniversity}
                              components={{  IndicatorSeparator:() => null }}
                              styles={style} placeholder={this.state.college_University} onChange={this.handleDrop}   /> 
                              
                              
                   
                            </div>
                            <div
                              className="form-group"
                              style={{ padding: "2%", borderRadius: "1px" }}
                            >
<Select options={this.state.subCourseName}
                              components={{  IndicatorSeparator:() => null }}
                              styles={style} placeholder={this.state.displayCourse} onChange={this.handleCourse}   /> 
{/* 
{this.state.subCourseName.length == 0
                    ? "Loading Course Name..."
                    : 

                    <select class="form-select" style={{border:"1px solid gray"}}  name ="Course" 
                    onChange = {this.handleChange}>
                      <option>{this.state.Course}</option>
                    {this.state.subCourseName.map(fbb =>
                    
                      <option key={fbb.key} value={fbb.id}>{fbb.subcategoryname}</option>
                      
                    )};
                  </select>
                    } 
*/}




                              {/* <Select options={subCourseName}
                              components={{  IndicatorSeparator:() => null }}
                              styles={style} placeholder="Select Courses" onChange={this.handleCourse}   /> */}

                                    
                            </div>
                            <div
                              className="form-group"
                              style={{ padding: "2%", borderRadius: "1px" }}
                            >
                               {this.state.selectQualification.length == 0
                    ? "Loading Qualification Name..."
                    : 

                    <select class="form-select" style={{border:"1px solid gray"}}  name ="QualificationType" 
                    onChange = {this.handleChange}>
                      <option>{this.state.QualificationType}</option>
                    {this.state.selectQualification.map(fbb =>
                    
                      <option key={fbb.key} value={fbb.name}>{fbb.name}</option>
                      
                    )};
                  </select>
                    } 
                            
                            </div>
                            <div
                              className="form-group"
                              style={{ padding: "2%", borderRadius: "1px" }}
                            >
                              <select
                                className="form-select"
                                style={{
                                  width: "100%",
                                  height: "38px",
                                  border: "1px solid gray",
                                }}
                                
                                onChange={this.handleChange} 
                                id="Lengthofcourse"
                                name="Lengthofcourse"
                              >
                                <option >{Lengthofcourse}</option>
                                <option >1 year</option>
                                <option>2 year</option>
                                <option >3 year</option>
                                <option >4 year</option>
                                <option >5 year</option>
                                <option >6 year</option>
                              </select>
                            </div>
                            <div
                              className="form-group"
                              style={{ padding: "2%", borderRadius: "1px" }}
                            >
                          {this.state.selectGraduation.length == 0
                    ? "Loading Graduation Name..."
                    : 

                    <select class="form-select" style={{border:"1px solid gray"}}  name ="GraduationYear" 
                    onChange = {this.handleChange}>
                      <option>{this.state.GraduationYear}</option>
                    {this.state.selectGraduation.map(fbb =>
                    
                      <option key={fbb.key} value={fbb.name}>{fbb.name}</option>
                      
                    )};
                  </select>
                    } 
                            </div>
                           
                            <input type="hidden" 
                               onChange={this.handleChange} 
                               id="userid"
                               name="userid"/>
                                <input type="hidden" 
                               onChange={this.handleChange} 
                               id=" reg_level"
                               name=" reg_level"/>
                            <div
                              className="container"
                              style={{ marginBottom: "5%" }}
                            >
                             
                              
                                <button
                                  className="btn btn-primary"
                                  style={{
                                    
                                    marginLeft: "36%",
                                    marginTop: "7%",
                                    color: "#fff",
                                    background: "rgb(0, 147, 69)",
                                    width: "26%",
                                    border: "none",
                                    borderRadius:
                                      "1px" /* marginTop: '-13%', */,
                                    height: "40px",
                                    
                                    marginBottom: "5%",
                                  }}
                                >
                                  Next{" "}
                               {" "}
                              </button>
                            </div>
                          </form>
                         
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="container"></div>
        </main>
        {/* End #main */}
        {/* ======= Footer ======= */}
       <Footer />
      </div>
  
  );
};
}

export default StudentbackSignup_two;
