import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Logo from "../../images/logo.png";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import swal from "sweetalert";
import LoadingIcon from "../../images/loading2.gif";
import validator from "validator";
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth' // for smoothly scrolling
  });
  
};
class Student_signupworkexp extends React.Component {
  constructor() {
    super();
    let comments = JSON.parse(sessionStorage.getItem("someData"));

    this.state = {
      first_name: "",
      last_name: "",
      password: "",
      dob: "",
      confirm: "",
      userid: comments,
      reg_level: 3,
      DataisLoaded: false,
      error: "",
      second_error: "",
      pass: "",
      selectOptions: [],
      id: "",
      name: "",
      FirstName: "",
      LastName: " ",
      DateOfbirth: "",
      Cdate:"",
      startDate: new Date()  
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setDate=this.setDate.bind(this)
  }
  setDate(date){
    this.setState({  
      startDate: date  
    })  
      
    
    
  }
  componentDidMount() {
    this.getOptions();
  }
  async getOptions() {
  
    const data_one = { dropdownlist_input: this.state.dropdownlist_input };

    let BaseUrl = JSON.parse(sessionStorage.getItem("baseurl"));

    fetch( "https://www.gigeconome.com:5200/api/getdropdownlist", {
      method: "POST",

      body: JSON.stringify(data_one), // data can be `string` or {object}!

      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())

      .catch((error) => console.error("Error:", error))

      .then((json) => {
        const data = json.result;

        if (this.state.LastName == "") {
          this.setState({ LastName: "Last Name" });
        }
        this.setState({ selectOptions: data });
        this.setState({
          DataisLoaded: true,
        });
      });
  }

  componentDidMount() {

    const data_two = {
      userid: this.state.userid,
      reg_level: this.state.reg_level,
    };
    console.log(data_two)
    fetch("https://www.gigeconome.com:5200/api/getststepinfo", {
      method: "POST",

      body: JSON.stringify(data_two), // data can be `string` or {object}!

      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())

      .catch((error) => console.error("Error:", error))

      .then((posRes) => {
        console.log(posRes);
        if (posRes.result.lastname == null) {
          this.setState({ LastName: "Last Name" });
          this.setState({ FirstName: "First Name" });
        } else {
          this.setState({
            first_name: posRes.result.name,
            last_name: posRes.result.lastname,
            dob: posRes.result.dob,
          });
        }
      });
    this.setState({
      DataisLoaded: true,
    });
  }
  handleSubmit() {
   window.location.href=`./stusignupemployability`
  }
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });

  };

  render() {
    
    return (
      <div>
        
          {/* ======= Header ======= */}
          <Header />
          {/* End Header */}
          <main id="main" style={{ background: "#303030", clear: "both" }}>
            <br />
            <br />
            {/* ======= About Section ======= */}
            <section id="clients" className="clients">
              <div className="container" data-aos="fade-up">
                <style
                  type="text/css"
                  dangerouslySetInnerHTML={{
                    __html:
                      "\n    .boxxlr{\n     border: 0px solid green;\n    background: #edf8f2;\n    margin-right: 2.5%;\n    margin-bottom: 7%;\n    color: #000;\n    margin-left: 9%;\n    width: 100%;\n    }\n/*.boxxlr:hover {\n    border: 0px solid green;\n    background: green;\n    margin-right: 1%;\n    margin-bottom: 1%;\n     color: #fff;\n}*/\n\n.boxclrtitle {\nfont-size: 12px;font-weight: bold;\n}\n\n.boxclrtitle1 {\nfont-size: 18px;font-weight: bold;\n}\n/*.boxxlr .boxclrtitle:hover {\nfont-size: 12px;font-weight: bold;\n}*/\n\n\n  ",
                  }}
                />
                <div className="row no-gutters">
                  <div className="content col-xl-12 d-flex align-items-center">
                    <div className="content col-xl-12 d-flex align-items-center ssignupstepthree" >
                      <div
                        className="row"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          borderRadius: "25px" /* alignItems: 'center', */,
                          background: "#fff",
                          /* marginLeft: '50%', */
                          borderRadius: "20px",
                          margin: "auto",
                        }}
                      >
                        <h2
                          style={{
                            fontSize: "20px",
                            textAlign: "center",
                            color: "#fff",

                            background: "rgb(0, 147, 69)",
                            padding: "1%",
                            width: "74%",
                            marginTop: "10%",
                          }}
                        >
                          {" "}
                          Work Experience{" "}
                        </h2>
                        <h5
                       className="tellustxt"
                      >
                          Please include your most recent work experience (you may add more)
                        </h5>
                        <div className="row h-100 row align-items-center">
                          <div className="col-md-10" style={{ margin: "auto" }}>
                            
                          <div
                                className="row"
                                style={{
                                  padding: "1%",
                                  borderRadius: "1px",
                                 
                                }}
                              >
                              <table>
                                <tr >
                                    <th className="stuworkcheckbox"><i class="fa fa-square-o stucheckbox"></i>
                                    
                                    </th>
                                    <th> <label for="vehicle1" id="checkbox_text"> I currently do not have the work experience
                        </label></th>
                                </tr>
                              </table>
                       
                                </div>
                              <div
                                className="form-group"
                                style={{ padding: "1%"}}
                              >
                                <span className="stuworkexpheading">Job Title</span>
                                <input
                                  style={{ padding: "1%", borderRadius: "1px",marginTop:"1%" }}
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter your job title"
                                  name="password"
                                  //value={password}
                                  required
                                  onChange={this.handleChange}
                                  
                                />
                              </div>

                              <div
                                className="form-group"
                                style={{ padding: "1%",  }}
                              >
                                <span className="stuworkexpheading">Company</span>
                                <input
                                  style={{ padding: "1%", borderRadius: "1px",marginTop:"1%" }}
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter the company name"
                                  name="password"
                                  
                                  required
                                  onChange={this.handleChange}
                                 
                                />
                              </div>
                             
                              <div
                                className="form-group"
                                style={{ padding: "1%",  }}
                              >
                                <span className="stuworkexpheading"> Role Type</span>
                                <select
                                className="form-select"
                                
                                style={{ padding: "1%", borderRadius: "1px",marginTop:"1%" }}
                              >
                                <option selected>Select</option>
                                <option value="1">Opportunities</option>
                                <option  value="2">Internship</option>
                                <option value="3">Externship</option>
                                <option value="4">Work Experience</option>
                                <option value="5">Work Placement</option>
                                <option value="6">Part Time Roles</option>
                                <option value="7">Side Gig Opportunities</option>
                                <option value="8">Course Work Related</option>
                                <option value="9">Portfolio Building</option>
                                <option value="10">Other</option>
             
                              </select>
                              </div>
                              
                              <div
                                className="row"
                                style={{
                                  padding: "1%",
                                  borderRadius: "1px",
                                 
                                }}
                              >
                                <div className="col">
                                 
                                <span className="stuworkexpheading"> Start</span>
                        <div>
                        <input type="text" class="form-control"
                       name ="startdate"
                       style={{ padding: "2%", borderRadius: "1px",marginTop:"1%" }} 
                       required
                       onChange = {this.handleChange}
                      placeholder="Select Year"
                      onFocus={(e) =>
                        (e.currentTarget.type = "month")
                      }
                      onBlur={(e) =>
                        (e.currentTarget.type = "text")
                      }
                     
                      />
                  
                        </div>
                                 
                                 
                                </div>
                                <div className="col">
                                 
                               
                                <span className="stuworkexpheading"> End</span>
                        <div>
                      <input type="text" class="form-control"
                      style={{ padding: "2%", borderRadius: "1px",marginTop:"1%" }}
                       name ="enddate" 
                       onChange = {this.handleChange}
                      placeholder="Select Year"
                      onFocus={(e) =>
                        (e.currentTarget.type = "month")
                      }
                      onBlur={(e) =>
                        (e.currentTarget.type = "text")
                      }
                      
                      />
                        </div>
                                
                                  
                                </div>
                              </div>
                              
                              <div
                                className="row"
                                style={{
                                  padding: "1%",
                                  borderRadius: "1px",
                                 
                                }}
                              >
                              <table>
                                <tr >
                                    <th className="stuworkcheckbox"><i class="fa fa-square-o stucheckbox"></i>
                                    
                                    </th>
                                    <th> <label for="vehicle1" id="checkbox_text"> I am Currently Working Here
                        </label></th>
                                </tr>
                              </table>
                       
                                </div>
                                <div
                                className="form-group"
                                style={{ padding: "1%",  }}
                              >
                                <span className="stuworkexpheading">Description</span>
                              <textarea  style={{ borderRadius: "1px",marginTop:"1%" }} rows="3" placeholder="Enter Job Description" className="form-control">

                              </textarea>
                              </div>
                              <div
                                className="container"
                                style={{ marginBottom: "2%" }}
                              >
                                <Link
                                  style={{ color: "#fff" }}
                                  to="/stusignupjobdepartment"
                                  className="btn btn-primary"
                                  onClick={()=>scrollToTop()}
                                  style={{
                                    
                                   
                                    marginTop: "7%",
                                    color: "#fff",
                                    background: "#000",
                                    width: "48%",
                                    border: "none",
                                    borderRadius:
                                      "1px" /* marginTop: '-13%', */,
                                    height: "40px",
                                   
                                    marginBottom: "6%",
                                  }}
                                >
                                  {" "}
                                  Back
                                </Link>
                                <button
                                  className="btn btn-primary"
                                  type="submit"
                                  onClick={this.handleSubmit}
                                  style={{
                                    
                                    marginLeft: "8%",
                                    marginTop: "6%",
                                    color: "#fff",
                                    background: "rgb(0, 147, 69)",
                                    width: "44%",
                                    border: "none",
                                    borderRadius:
                                      "1px" /* marginTop: '-13%', */,
                                    height: "40px",
                                    
                                    marginBottom: "5%",
                                  }}
                                >
                                  Next
                                </button>{" "}
                              </div>
                           
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </main>
          {/* End #main */}
          {/* ======= Footer ======= */}
          <Footer />
        </div>
     
    );
  }
}
export default Student_signupworkexp;
