import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import LinkedIN from "../../../images/company1.png";
import Instagram from "../../../images/insta.png";
import Twitter from "../../../images/twitter.png";
import Facebook1 from "../../../images/fb.png";
import GraduationYear from "../../Signup/GraduationYear";
import "./modal.css";

function close(){
  window.location.reload();
}
export default class  CompanyProfileModal extends React.Component {
  constructor() {
    super();
  let comments = JSON.parse(sessionStorage.getItem('someData'));
  this.textInput = React.createRef();
  this.state = {
    
      userid:comments,
      FirstName:"",
      LastName:"",
      Studystatus:"",
      selectGraduation:[],
      GraduationYear:"",
      subCourseName:[],
      Course:"",
      selectLocation:[],
      firstname:"",
      lastname:"",
      StudyStatus:"",
      graduationyear:"",
      Degree:"",
      university:"",
      locationname:"",
      instagramlink:"",
      linkedinlink:"",
      facebooklink:"",
      twitterlink:"",
      value: '',
      college_University:"",
      showModal: false,
      companyname:"",
      jobdepartment:"",
      Jobtitle:"",
      smedialink:"",
      linkedinmessage:"",
      instagrammessage:"",
      twittermessage:"",
      facebookmessage:"",
      jobDepartment:[],
      jobdepartmentname:"",
      location:""


      };
    this.handleSubmit = this.handleSubmit.bind(this);
   this.handleLinkedin=this.handleLinkedin.bind(this);
   this.handleInstagram=this.handleInstagram.bind(this);
   this.handleTwitter=this.handleTwitter.bind(this);
   this.handleFacebook=this.handleFacebook.bind(this);
  }

  openModal = () => {
    this.setState({ showModal: true });
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  componentDidMount(){
 
    const jobdata={dropdownlist_input:"jobdepartment",
  
  }



  fetch( 'https://www.gigeconome.com:5200/api/getdropdownlist', { method: 'POST', 

   body: JSON.stringify(jobdata), // data can be `string` or {object}!

   headers:{ 'Content-Type': 'application/json' } })

   .then(res => res.json())

   .catch(error => console.error('Error:', error))

   .then((json)=>{
    const data = json.result
    this.setState({jobDepartment:data})
    
   })
    const data_one={userid:this.state.userid,
    
    }
  
    let BaseUrl = JSON.parse(sessionStorage.getItem('baseurl'));
  
    fetch(BaseUrl + 'cpyprofileinfo', { method: 'POST', 
  
     body: JSON.stringify(data_one), // data can be `string` or {object}!
  
     headers:{ 'Content-Type': 'application/json' } })
  
     .then(res => res.json())
  
     .catch(error => console.error('Error:', error))
  
     .then((json)=>{
      
     
    this.setState({
      firstname:json.user.firstname,
      lastname:json.user.lastname,
      StudyStatus:json.user.StudyStatus,
      GraduationYear:json.user.GraduationYear,
      Course:json.user.Course,
      college_University:json.user.college_University,
      locationname:json.user.locationname,
      companyname:json.user.companyname,
      jobdepartmentname:json.user.jobdepartmentname,
      Jobtitle:json.user.Jobtitle,
      instagramlink:json.user.instagramlink,
      linkedinlink:json.user.linkedinlink,
      facebooklink:json.user.facebooklink,
      twitterlink:json.user.twitterlink,
      jobdepartment:json.user.jobdepartment,
      location:json.user.location
    })
     })
     const data_graduation={dropdownlist_input: "graduationyear"}
     fetch( 'https://www.gigeconome.com:5200/api/getdropdownlist', { method: 'POST', 
  
     body: JSON.stringify(data_graduation), // data can be `string` or {object}!
  
     headers:{ 'Content-Type': 'application/json' } })
  
     .then(res => res.json())
  
     .catch(error => console.error('Error:', error))
  
     .then((json)=>{
      
      
      const data = json.result
    
      this.setState({selectGraduation: data})
      this.setState({
        DataisLoaded: true 
      })
     })

     const data_six={dropdownlist_input:"coursenew"}
     fetch( 'https://www.gigeconome.com:5200/api/getdropdownlist', { method: 'POST', 
  
     body: JSON.stringify(data_six), // data can be `string` or {object}!
  
     headers:{ 'Content-Type': 'application/json' } })
  
     .then(res => res.json())
  
     .catch(error => console.error('Error:', error))
  
     .then((json)=>{
      
     let  data=json.result
    this.setState({subCourseName:data})
      
      
    
     })

     const data_seven={dropdownlist_input:"country"}
     fetch( 'https://www.gigeconome.com:5200/api/getdropdownlist', { method: 'POST', 
  
     body: JSON.stringify(data_seven), // data can be `string` or {object}!
  
     headers:{ 'Content-Type': 'application/json' } })
  
     .then(res => res.json())
  
     .catch(error => console.error('Error:', error))
  
     .then((json)=>{
      
     let  data=json.result
    this.setState({selectLocation:data})
      
      
    
     })
    }

    handleSubmit = (event) => {
      event.preventDefault()
     
     
  const data = {
  
  firstname:this.state.firstname,
  lastname:this.state.lastname,
  companyname:this.state.companyname,
  jobdepartment:this.state.jobdepartment,
  Jobtitle:this.state.Jobtitle,
  location:this.state.location,
  instagramlink:this.state.instagramlink,
  linkedinlink:this.state.linkedinlink,
  facebooklink:this.state.facebooklink,
  twitterlink:this.state.twitterlink,
  userid:this.state.userid
   
    
   
  
  }
  console.log(data)
  let BaseUrl = JSON.parse(sessionStorage.getItem("baseurl"));
  
    fetch(BaseUrl + "editempbasicinfo", {
      method: "POST",
  
      body: JSON.stringify(data), // data can be `string` or {object}!
  
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
  
      .catch((error) => console.error("Error:", error))
  
      .then((response) => {
        console.log(response)
      this.setState({success:response.message})
        window.location.reload();
        
      });
      
    }


    handleLinkedin = (event) => {
      event.preventDefault()
     
     
  const LinkedINdata = {
  
    addstudata:"stusocialmedia",
    sminputdata:"linkedinlink",
    linkedinlink:this.state.linkedinlink,
  userid:this.state.userid
   
    
   
  
  }
  
  let BaseUrl = JSON.parse(sessionStorage.getItem("baseurl"));
  
    fetch(BaseUrl + "addstu_profiledata", {
      method: "POST",
  
      body: JSON.stringify(LinkedINdata), // data can be `string` or {object}!
  
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
  
      .catch((error) => console.error("Error:", error))
  
      .then((response) => {
        
      this.setState({linkedinmessage:response.message})
         
        
      });
      
    }

    handleInstagram = (event) => {
      event.preventDefault()
     
     
  const Instagramdata = {
  
    addstudata:"stusocialmedia",
    sminputdata:"instagramlink",
    instagramlink:this.state.instagramlink,
    userid:this.state.userid
    
    
   
  
  }
  
  let BaseUrl = JSON.parse(sessionStorage.getItem("baseurl"));
  
    fetch(BaseUrl + "addstu_profiledata", {
      method: "POST",
  
      body: JSON.stringify(Instagramdata), // data can be `string` or {object}!
  
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
  
      .catch((error) => console.error("Error:", error))
  
      .then((response) => {
        
      this.setState({instagrammessage:response.message})
         
        
      });
      
    }

    handleTwitter = (event) => {
      event.preventDefault()
     
     
  const twitterdata = {
  
    addstudata:"stusocialmedia",
    sminputdata:"twitterlink",
    twitterlink:this.state.twitterlink,
    userid:this.state.userid
    
    
   
  
  }
  
  let BaseUrl = JSON.parse(sessionStorage.getItem("baseurl"));
  
    fetch(BaseUrl + "addstu_profiledata", {
      method: "POST",
  
      body: JSON.stringify(twitterdata), // data can be `string` or {object}!
  
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
  
      .catch((error) => console.error("Error:", error))
  
      .then((response) => {
        
      this.setState({twittermessage:response.message})
         
        
      });
      
    }
    handleFacebook = (event) => {
      event.preventDefault()
     
     
  const facebookdata = {
  
    addstudata:"stusocialmedia",
    sminputdata:"facebooklink",
    facebooklink:this.state.facebooklink,
    userid:this.state.userid
    
    
   
  
  }
  
  let BaseUrl = JSON.parse(sessionStorage.getItem("baseurl"));
  
    fetch(BaseUrl + "addstu_profiledata", {
      method: "POST",
  
      body: JSON.stringify(facebookdata), // data can be `string` or {object}!
  
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
  
      .catch((error) => console.error("Error:", error))
  
      .then((response) => {
       
      this.setState({facebookmessage:response.message})
         
        
      });
      
    }


    handleChange = (e) => {
      this.setState({
        [e.target.name]: e.target.value,
       
      });
     
      
    };
render(){
  return (
    <>
     {this.state.showModal && (
      <div class="ft-modal" id="ft-companyprofile-modal">
        <div class="ft-modal-content">
          <div class="ft-modal-header">
            <div class="header">
              <h5
                class="modal-title"
                id="exampleModalLabel"
                style={{
                  textAlign: "center",
                  fontSize: 25,
                  fontWeight: "bold",
                }}
              >
                Profile
              </h5>
              <hr />
            </div>
          </div>
          <div class="ft-modal-body">
           
            <div class="row mt-3">
              <div class="col-sm-1"></div>
              <div class="col-sm-4">
                <label
                  style={{
                    fontSize: 17,
                    fontWeight: "bold",
                  }}
                >
                  First Name <br />
                </label>
                <input
                  type="text"
                  placeholder="First Name"
                  className="form-control mt-2"
                  
                 value={this.state.firstname}
                  onChange={this.handleChange}
                  name="firstname"
                  //onChange={(e) => { this.setState({ FirstName: e.target.value }) }}
                />
              </div>
              <div class="col-sm-1"></div>
              <div class="col-sm-5">
                <label
                  style={{
                    fontSize: 17,
                    fontWeight: "bold",
                   
                  }}
                  className="stumarginleftprofile"
                >
                  Last Name <br />
                </label>
                <input
                 
                  type="text"
                  placeholder="Last Name"
                  value={this.state.lastname}
                  className="form-control mt-2 stumarginleftprofile"
                  name="lastname"
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-sm-1"></div>
              <label
                class="col-sm-3 mt-4"
                style={{
                  fontSize: 17,
                  fontWeight: "bold",
                }}
              >
                Company Name
              </label>
              <div class="col-sm-1"></div>
              <div class="col-sm-5">
<input type="text" value={this.state.companyname} 
name="companyname"
onChange={this.handleChange}
className="form-control mt-4 stustudystatusmargin" />

              </div>
            </div>
            <div class="row mt-4">
              <div class="col-sm-1"></div>
              <label
                class="col-sm-4 mt-3"
                style={{
                  fontSize: 17,
                  fontWeight: "bold",
                }}
              >
                Job Department
              </label>

              <div class="col-sm-5 mt-3">
              {this.state.jobDepartment.length == 0
                    ? "Loading Job Department.."
                    : 

                    <select class="form-select stustudystatusmargin" style={{
                      width: "100%",
                    
                     
                      marginBottom: "2%",
                      marginTop: "1%",
                    }} name ="jobdepartment" 
                    onChange = {this.handleChange}>
                     
                      <option value={this.state.jobdepartment}>{this.state.jobdepartmentname}</option>
                    {this.state.jobDepartment.map(fbb =>
                   
                      <option key={fbb.key} value={fbb.id}>{fbb.name}</option>
                      
                    )};
                  </select>
                    } 
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-sm-1"></div>
              <label
                class="col-sm-3 mt-3"
                style={{
                  fontSize: 17,
                  fontWeight: "bold",
                }}
              >
                Job Title
              </label>
              <div class="col-sm-1"></div>
              <div class="col-sm-5 cpyjobtitledropdown">
              <input type="text"className="form-control mt-3 " 
              value={this.state.Jobtitle}
              name="Jobtitle stustudystatusmargin"  onChange={this.handleChange}
             />
              </div>
            </div>
         
            <div class="row mt-4">
              <div class="col-sm-1"></div>
              <label
                class="col-sm-3 mt-3"
                style={{
                  fontSize: 17,
                  fontWeight: "bold",
                }}
              >
                Location
              </label>
              <div class="col-sm-1"></div>
              <div class="col-sm-5">
              {this.state.selectLocation.length == 0
                    ? "Loading Location..."
                    : 

                    <select class="form-select stustudystatusmargin" style={{
                      width: "100%",
                   
                     
                      marginBottom: "2%",
                      marginTop: "1%",
                    }} name ="location" 
                    onChange = {this.handleChange}>
                     
                      <option value={this.state.location}>{this.state.locationname}</option>
                    {this.state.selectLocation.map(fbb =>
                   
                      <option key={fbb.key} value={fbb.id}>{fbb.name}</option>
                      
                    )};
                  </select>
                    } 
              </div>
            </div>
            <div
              className="mt-5"
              style={{
                textAlign: "center",
                fontSize: 20,
                fontWeight: "bold",
                marginLeft: "-5%",
              }}
            >
              Add Your Social Media Accounts
            </div>
            <div class="row mt-4">
              <div class="col-sm-2"></div>
              <div class="col-sm-1 mt-4">
                <img src={Instagram} style={{ height: 40 }} />
              </div>
              <div class="col-sm-5">
                <input className="form-control mt-4" 
                name ="instagramlink"
                value={this.state.instagramlink} 
                    onChange = {this.handleChange} placeholder="Paste URL" /> <br/>
                     <span style={{color:"rgb(0,147,69)",fontFamily:"Gotham",marginTop:"5%",fontWeight:"bold"}}>{this.state.instagrammessage}</span>
              </div>
              <div class="col-sm-2">
                <button className="btn btn-success" id="urlbtn" onClick={this.handleInstagram}>
                  Add
                </button>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-sm-2"></div>
              <div class="col-sm-1 mt-4">
                <img src={LinkedIN} style={{ height: 40 }} />
              </div>
              <div class="col-sm-5">
                <input className="form-control mt-4" name ="linkedinlink" 
                value={this.state.linkedinlink}
                    onChange = {this.handleChange} placeholder="Paste URL" /> <br />
                    <span style={{color:"rgb(0,147,69)",fontFamily:"Gotham",marginTop:"5%",fontWeight:"bold"}}>{this.state.linkedinmessage}</span>
              </div>
              <div class="col-sm-2">
                <button className="btn btn-success" id="urlbtn" onClick={this.handleLinkedin}>
                  Add
                </button>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-sm-2"></div>
              <div class="col-sm-1 mt-4">
                <img src={Twitter} style={{ height: 40 }} />
              </div>
              <div class="col-sm-5">
                <input className="form-control mt-4" name ="twitterlink" 
                value={this.state.twitterlink}
                    onChange = {this.handleChange} placeholder="Paste URL" /> <br />
                    <span style={{color:"rgb(0,147,69)",fontFamily:"Gotham",marginTop:"5%",fontWeight:"bold"}}>{this.state.twittermessage}</span>
              </div>
              <div class="col-sm-2">
                <button className="btn btn-success" id="urlbtn" onClick={this.handleTwitter}>
                  Add
                </button>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-sm-2"></div>
              <div class="col-sm-1 mt-4">
                <img src={Facebook1} style={{ height: 40 }} />
              </div>
              <div class="col-sm-5">
                <input className="form-control mt-4" name ="facebooklink" 
                value={this.state.facebooklink}
                    onChange = {this.handleChange} placeholder="Paste URL" /> <br />
                     <span style={{color:"rgb(0,147,69)",fontFamily:"Gotham",marginTop:"5%",fontWeight:"bold"}}>{this.state.facebookmessage}</span>
              </div>
              <div class="col-sm-2">
                <button className="btn btn-success" id="urlbtn" onClick={this.handleFacebook}>
                  Add
                </button>
              </div>
            </div>
            <div class="modal-footer mt-4">
              <div class="col-sm-4"></div>
              <div class="col-sm-7">
                <button
                  style={{ backgroundColor: "rgb(0, 147, 69)", width: 100 }}
                  className="btn btn-success"
                  onClick={this.handleSubmit}
                >
                  Save
                </button>
                <br /> <br />
                  <span style={{color:"rgb(0,147,69)",fontFamily:"Gotham",marginTop:"5%",fontWeight:"bold"}}>{this.state.success}</span>
              </div>
              <div class="col-sm-1"></div>
            </div>
          
          </div>
          <div>
            <a class="ft-modal-close" data-dismiss="modal"
                     onClick={this.closeModal}>
              [&#10006;]{" "}
            </a>
          </div>
        </div>
      </div>
     )}
      <a id="edit_btn" className="btn btn-success mprofilebtn"   
      onClick={this.openModal} href="#ft-companyprofile-modal">
        Edit
      </a>
    </>
  );
}
}