import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Login2 from '../../images/google.png';
import Login3 from '../../images/linkedin.png';
import Select from 'react-select';
const style = {
  control: (base, state) => ({
    ...base,
   marginTop:"5%",
    borderRadius:"1px",
   height:"44px",
   
    // This line disable the blue border
    boxShadow: state.isFocused ? 0 : 0,
    "&:hover": {
    
      borderRadius:"5px",
      height:"44px",
      marginTop:"5%",
    }
  }),
 
};
class Companysignup extends React.Component {
  constructor() {
    super();
  
    this.state = {
     
    
      logintype : "Normal",
      usertype : "",
      email:"",
      companyname : "",
      companystatus : "",
      password:"",
      erroremail:"",
      errorpass:"",
      emailerror:"",
      CompanyData:[],
      showdropdown:0,
      signupfor:0,
      companyid:"",
      suggestions: [],
      text: '',
      items:[],
      errormsg:""
     
      };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleEmployee=this.handleEmployee.bind(this);
    this.handleCompany=this.handleCompany.bind(this);

  }
handleCompany(){
  this.setState({signupfor:2,showdropdown:2,usertype:"Company"})
}

componentDidMount(){
    {/* All Company Api Call */}
const cdata = { dropdownlist_input: "companynamelist"
};

fetch("https://www.gigeconome.com:5200/api/getdropdownlist", {
  method: "POST",

  body: JSON.stringify(cdata), // data can be `string` or {object}!

  headers: { "Content-Type": "application/json" },
})
  .then((res) => res.json())

  .catch((error) => console.error("Error:", error))

  .then((response) => {
    response.result.map(e=>{
    
      this.setState({items:this.state.items.concat([e.companyname])},()=>{
        //console.log(this.state.items)
       })
     })
  
  });
}
  handleEmployee(){
        {/* All Company Api Call */}
const pricedata = { dropdownlist_input: "companynamelist"
};

fetch("https://www.gigeconome.com:5200/api/getdropdownlist", {
  method: "POST",

  body: JSON.stringify(pricedata), // data can be `string` or {object}!

  headers: { "Content-Type": "application/json" },
})
  .then((res) => res.json())

  .catch((error) => console.error("Error:", error))

  .then((response) => {
   // console.log(response)
   this.setState({showdropdown:1,
    signupfor:1,usertype:"Employee"
  },()=>{
     //console.log(this.state.PriceData)
   })
   const data = response.result

  
const options = data.map(d => ({
    "value" : d.companyid,
    "label" : d.companyname

  })) 
 
  this.setState({CompanyData: options})
  });
 
  }
  handleDrop = (e) => {
    
    this.setState({companyid:e.value})
   
      }
  handleSubmit(event) {
    event.preventDefault();
    if (this.state.email === "") {
     this.setState({erroremail:"Enter an Email"})
    }
    else if(this.state.password ===""){
      this.setState({errorpass:"Enter a Password"})
    }
    else{
    const data = { 
      logintype:this.state.logintype,
      usertype:this.state.usertype,
      email:this.state.email , 
      companyname:this.state.companyname,
      companystatus:this.state.companystatus,
     password:this.state.password,
     companyid:this.state.companyid,
     signupfor:this.state.signupfor
    }
    
    sessionStorage.setItem("signupAS", data["signupfor"]);
     console.log(data)
     fetch( "https://www.gigeconome.com:5200/api/register", { method: 'POST',  

    body: JSON.stringify(data), // data can be `string` or {object}!

    headers:{ 'Content-Type': 'application/json' } })

    .then(res => res.json())

    .catch(error => console.error('Error:', error))

    .then((response)=>{
        console.log('Success:', response)
        if(response.status==0){
          this.setState({emailerror:response.message})
        }
        else if(response.status==2){
          var dataToStore = JSON.stringify(response.userid);
          sessionStorage.setItem("someData", dataToStore);
        //  sessionStorage.setItem("signupAS", response.signupfor);
       window.location.href=`./Companysignuptwo`
        }
      else if(response.message=="The user for Company has been successfully inserted.") {
        var dataToStore = JSON.stringify(response.userid);
        sessionStorage.setItem("someData", dataToStore);
       // sessionStorage.setItem("signupAs", response.signupfor);
         window.location.href=`./Companysignuptwo`
      }
else if(response.status==5){
this.setState({errormsg:response.message})
}


        
         
            
          //  var dataToStore = JSON.stringify(response.userid);
            //sessionStorage.setItem('someData', dataToStore);
           // window.location.href=`./Companysignuptwo`
            //sessionStorage.setItem(data); 
    })
  }
        
    
       
    
   }
   handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }
  onTextChanged = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
    const value = e.target.value;
    //alert(value)
    let suggestions = [];
    if(value.length > 0) {
      const regex = new RegExp(`^${value}`, 'i');
      suggestions = this.state.items.sort().filter(v => regex.test(v));
      
    }
    this.setState(() => ({suggestions, text: value}));
    
  }
  
  suggestionSelected(value) {
    this.setState(() => ({
      text: value,
      suggestions: []
    }));
    //console.log(value)
    this.setState({companyname:value})
  }
  
  renderSuggestions() {
    const {suggestions} = this.state;
    if(suggestions.length === 0) {
      return null;
    }
    return (
      <div className="srchList">
        <ul style={{border:"1px solid",listStyle:"none"}}>
          {suggestions.map((item) => <li className="companyname" style={{cursor:"pointer",fontFamily:"Gotham",}} onClick={() => this.suggestionSelected(item)}>{item}</li>)}
        </ul>
      </div>
    );
  }
  
  render() {
    const{erroremail}=this.state
    const{errorpass}=this.state
    const { text } = this.state;
    return (
      <div>
        {/* ======= Header ======= */}
     <Header />
      {/* End Header */}
        <main id="main" style={{ background: "#303030", clear: "both" }}>
          <br />
          <br />
          {/* ======= About Section ======= */}
          <section id="clients" className="clients">
            <div className="container" data-aos="fade-up">
              <style
                type="text/css"
                dangerouslySetInnerHTML={{
                  __html:
                    "\n    .boxxlr{\n     border: 0px solid green;\n    background: #edf8f2;\n    margin-right: 2.5%;\n    margin-bottom: 7%;\n    color: #000;\n    margin-left: 9%;\n    width: 100%;\n    }\n/*.boxxlr:hover {\n    border: 0px solid green;\n    background: green;\n    margin-right: 1%;\n    margin-bottom: 1%;\n     color: #fff;\n}*/\n\n.boxclrtitle {\nfont-size: 12px;font-weight: bold;\n}\n\n.boxclrtitle1 {\nfont-size: 18px;font-weight: bold;\n}\n/*.boxxlr .boxclrtitle:hover {\nfont-size: 12px;font-weight: bold;\n}*/\n\n\n  ",
                }}
              />
              <div className="row no-gutters">
                <div className="content col-xl-12 d-flex align-items-center">
                  <div className="content col-xl-12 d-flex align-items-center">
                    <div
                      className="row cpysignupbox"
                     
                    >
                      <h2
                        style={{
                          fontSize: "20px",
                          textAlign: "center",
                          color: "#fff",
                          fontWeight: 600,
                          background: "#00674C",
                          padding: "1%",
                          width: "79%",
                          margin: "6%",
                        }}
                      >
                        {" "}
                        Sign Up As{" "}
                      </h2>
                      
                      <div className="row h-100 row align-items-center">
                        <div className="col-md-10" style={{ margin: "auto" }}>
                          <form onSubmit={this.handleSubmit}>
                            <div style={{fontFamily:"Gotham"}}>
                            <div style={{border:this.state.showdropdown==2?"1px solid":null,padding:"1%",marginBottom:"2%"}}>

                            <input type="radio" name="Employee"className="mt-1" onClick={this.handleCompany}/>&nbsp;&nbsp;&nbsp;New Company 
                             &nbsp;&nbsp;&nbsp;<h6 style={{marginLeft:"6%",fontFamily:"Gotham",fontSize:12,color:"rgb(0,147,69)"}}>
                               Add a new company to join companies already using GigeconoMe </h6>
                            </div>
                             
                            <div style={{border:this.state.showdropdown==1?"1px solid":null,padding:"1%",marginBottom:"2%"}}>

                            <input type="radio" name="Employee" className="mt-1"  onClick={this.handleEmployee}/>&nbsp;&nbsp;&nbsp;Existing Company
                           <h6 style={{fontSize:12,fontFamily:"Gotham",marginLeft:"6%",color:"rgb(0,147,69)"}}>Select company from list of companies already using GigeconoMe</h6>
                            </div>
                             
                  
                             

                            </div>
                            {this.state.showdropdown==1?<>
                              <div
                              className="form-group"
                              style={{ borderRadius: "1px" }}
                            >
                           <Select options={this.state.CompanyData}
                              components={{  IndicatorSeparator:() => null }}
                              styles={style} placeholder="Select Company Name" onChange={this.handleDrop}   /> 
                              
                            </div>
                
                            </>:<>
                           
                          
                            </>}
                           {this.state.showdropdown==2?<>
                           
                            <div
                              className="form-group mt-4"
                              
                            >
                            {/*<input
                                style={{ padding: "2%", borderRadius: "1px" }}
                                type="text"
                                className="form-control"
                                placeholder="Company Name"
                                id="email"
                                name="companyname"
                                onChange={this.handleChange}
                                autoComplete="off"
                                
                              /> */}

<input value={text} 
className="form-control"
style={{
  width: "100%",
  height: "44px",
  border: "1px solid #ced4da",
 
}}
name="companyname"
onChange={this.onTextChanged} 
autoComplete="off"
type="text" placeHolder="Company Name" />
<div
                              className="form-group"
                              style={{borderRadius: "1px" }}
                            >
                               {this.renderSuggestions()}
                            </div>   
                                
                            </div>
                           </>:<></>}
                            <div
                              className="form-group mt-4"
                              style={{ borderRadius: "1px" }}
                            >
                              <select
                                className="form-select"
                                style={{
                                  width: "100%",
                                  height: "44px",
                                  border: "1px solid #ced4da",
                                 
                                }}
                                id="companystatus"
                                name="companystatus"
                                onChange={this.handleChange}
                                required
                              >
                                <option disabled selected  style={{ color: "#ced4da" }}>
                                  Company Status{" "}
                                </option>
                                <option value="1">Publicly Listed Company</option>
                                <option value="2">Startup</option>
                                <option value="3">Scale Up</option>
                                <option value="4">Charity</option>
                                <option value="5">Non Profit / NGO</option>
                                <option value="6">Government or Public Body</option>
                                <option value="7">Private Sector</option>
                                <option value="8">Other</option>
                                <option value="9">College or Educational Establishment</option>
                                <option value="10">Unincorporated Association</option>
                                <option value="11">Partnership</option>
                              </select>
                            </div>
                            <div
                              className="form-group mt-3"
                              
                            >
                              <input
                                style={{  borderRadius: "1px",padding:"2%" }}
                                type="email"
                                className="form-control"
                                placeholder="Email Id"
                                id="email"
                                name="email"
                                onChange={this.handleChange}
                                autoComplete="off"
                                
                              />
                                <span style={{fontWeight:"bold",color:'red'}}>{erroremail}</span>
                            </div>
                          
                            <div
                              className="form-group mt-4"
                             
                            >
                              <input
                                style={{ padding: "2%", borderRadius: "1px" }}
                                type="password"
                                className="form-control"
                                placeholder="Password"
                                id="password"
                                name="password"
                                onChange={this.handleChange}
                                autoComplete="off"
                                
                              />
                               <span style={{fontWeight:"bold",color:'red'}}>{errorpass}</span>
                               <span style={{fontWeight:"bold",color:'red',fontSize:12}}>Password must be 8 -32 characters long, contain upper and lower case characters and have 1 or more numbers.</span>
                            </div>
                            <div
                              className="form-group mt-4"
                              
                            >
                              <input
                                style={{ padding: "2%", borderRadius: "1px" }}
                                type="password"
                                className="form-control"
                                placeholder="Confirm Password"
                             
                                
                                autoComplete="off"
                                
                              />
                               <span style={{fontWeight:"bold",color:'red'}}>{errorpass}</span>
                            </div>
       <input id="name" name="name"  type="hidden"  onChange={this.handleChange} />
       <input id="logintype" name="logintype"  type="hidden"  onChange={this.handleChange} />
       <input id="usertype" name="usertype"  type="hidden"  onChange={this.handleChange} />
       <br />
                              <span style={{fontWeight:"bold",color:"red",marginLeft:"2%",fontFamily:"GothamLight"}}>{this.state.emailerror}</span>
      
                              <span style={{fontWeight:"bold",color:"red",marginLeft:"2%",fontFamily:"GothamLight",fontSize:13}}>{this.state.errormsg}</span>
                              {" "}
                              <button
                              type="submit"
                                className="btn btn-primary"
                                style={{
                                  padding: "2%",
                                  marginLeft: "34%",
                                  marginBottom: "4%",
                                  color: "#fff",
                                  background: "#00674C",
                                  width: "26%",
                                  border: "none",
                                  borderRadius: "1px",
                                  marginTop: "4%",
                                }}
                              >
                                
                                Create
                              </button> 
                          </form>
                          <div
                            style={{
                              float: "left",
                              width: "45%",
                              marginRight: "3%",
                            }}
                          >
                            <hr />
                          </div>
                          <div style={{ float: "right", width: "45%" }}>
                            <hr />
                          </div>
                          Or
                          <h6 style={{ textAlign: "center",fontFamily:"GothamLight",fontWeight:"bold" }}>
                            With Your Social Network
                          </h6>
                          <div style={{ marginTop: "4%", marginBottom: "10%" }}>
                            <span
                              className="btn btn-primary"
                              style={{
                                background: "#fff",
                                border: "1px solid gray",
                                borderRadius: "1px",
                                fontSize: "22px",
                                letterSpacing: "1px",
                                marginRight: "4%",
                                width: "40%",
                                padding: "0%",
                                marginLeft: "10%",
                              }}
                            >
                              {" "}
                              <img src={Login2} />{" "}
                            </span>
                            <span
                              className="btn btn-primary"
                              style={{
                                background: "#fff",
                                border: "1px solid gray",
                                borderRadius: "1px",
                                fontSize: "22px",
                                letterSpacing: "1px",
                                width: "40%",
                                padding: "0%",
                              }}
                            >
                              <img src={Login3} />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        {/* End #main */}
       {/* ======= Footer ======= */}
        <Footer />
      </div>
    );
  }
}
export default Companysignup;
