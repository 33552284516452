import React from "react";
import PostaProjectimg from "../../images/Post a Project-13.png";
import { Link } from "react-router-dom";
import PostaProjectNav from "./PostaProjectNav";
export default class LandingPage extends React.Component {
  render() {
    return (
      <div>
        <div>
        <PostaProjectNav />
        </div>
        <div class="container" id="postaproject_one">
          <div class="text-center">
            
           
              <img src={PostaProjectimg} class="rounded" style={{ height:80,width:90 }}/>
          
          </div>
          <div >
           
            <div class="text-center mt-4" style={{fontSize:25,fontFamily:'Gotham'}} >
              Choose What to Post About
            </div>
          </div>
          <div >
            
            <div class="text-center mt-2" style={{fontSize:15,fontFamily:'GothamLight'}} >
            Lorem Ipsum is simply dummy text of the printing and typesetting
            </div>
          </div>
          <div class="row mt-4">
              <div class="col-sm-12">
          <div class="postaproject">
            <div class="row">

            <div class="col-sm-1"></div>    
            <div class="col-sm-10 mt-5">
            <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
            <li class="nav-item" role="presentation" id="placement_postaproject">
                <h2 style={{fontSize:20,textAlign:'center'}} class=" active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">
                <div class="text-center">
                <i class="fa fa-image" style={{marginTop:40,fontSize:30}}></i>
                    </div>
                    <div style={{fontFamily:"Gotham"}} className="mt-2">  Post a Project</div>
                <div class="mt-3" style={{fontSize:11}}>
                Lorem Ipsum has been the industry's
                </div>
                <div style={{fontSize:11}}>
                Lorem Ipsum has been the industry's
                </div>
                <div style={{fontSize:11}}>
                Lorem Ipsum has been the industry's
                </div>
                <div style={{fontSize:11}}>
                Lorem Ipsum has been the industry's
                </div>

                </h2>
            </li>
            <li style={{marginLeft:40}} class="nav-item" role="presentation" id="placement_postaproject">
                <h2 style={{fontSize:20,textAlign:'center'}} id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">

                <div class="text-center">
                <i class="fa fa-user-o" style={{marginTop:40,fontSize:30}}></i> <br />
                    </div>
                    <div style={{fontFamily:"Gotham"}} className="mt-2">   Placement</div>
                <div class="mt-3" style={{fontSize:11}}>
                Lorem Ipsum has been the industry's
                </div>
                <div style={{fontSize:11}}>
                Lorem Ipsum has been the industry's
                </div>
                <div style={{fontSize:11}}>
                Lorem Ipsum has been the industry's
                </div>
                <div style={{fontSize:11}}>
                Lorem Ipsum has been the industry's
                </div>

                </h2>
            </li>
            <li style={{marginLeft:40}} class="nav-item" id="placement_postaproject" role="presentation">
                <h2 style={{fontSize:16,textAlign:'center'}} id="pills-fixed-tab" data-bs-toggle="pill" data-bs-target="#pills-fixed" type="button" role="tab" aria-controls="pills-fixed" aria-selected="false">

                <div class="text-center">
                <i class="fa fa-calendar" style={{marginTop:40,fontSize:30}}></i>
                    </div>
                    <div style={{fontFamily:"Gotham"}} className="mt-2"> 
                     Fixed Term Opportunities</div>
                <div class="mt-3" style={{fontSize:11}}>
                Lorem Ipsum has been the industry's
                </div>
                <div style={{fontSize:11}}>
                Lorem Ipsum has been the industry's
                </div>
                <div style={{fontSize:11}}>
                Lorem Ipsum has been the industry's
                </div>
                <div style={{fontSize:11}}>
                Lorem Ipsum has been the industry's
                </div>

                </h2>
            </li>
            <li style={{marginLeft:35}} class="nav-item" role="presentation" id="placement_postaproject">
                <h2 style={{fontSize:18,textAlign:'center'}} id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">
                    
                    
                <div class="text-center">
                <i class="fa fa-briefcase"style={{marginTop:40,fontSize:30}} ></i>
                    </div>
                 <div style={{fontFamily:"Gotham"}} className="mt-2">  Work Experience</div>
                <div class="mt-3" style={{fontSize:11}}>
                Lorem Ipsum has been the industry's
                </div>
                <div style={{fontSize:11}}>
                Lorem Ipsum has been the industry's
                </div>
                <div style={{fontSize:11}}>
                Lorem Ipsum has been the industry's
                </div>
                <div style={{fontSize:11}}>
                Lorem Ipsum has been the industry's
                </div>
</h2>
            </li>
            </ul>

            </div>
            <div class="col-sm-1"></div>    
            </div>
          </div>
          </div>
          </div>
          <div class="row">
              <div class="col-sm-5">
                  </div>
            <div class="col-sm-7">
            <div class="tab-content" id="pills-tabContent">
  <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">

<Link to="/basicdetails" className="btn btn-success" id="continue">Continue</Link>

  </div>
  <div class="tab-pane fade" id="pills-fixed" role="tabpanel" aria-labelledby="pills-fixed-tab">
  <Link to="/opportunitybasic" className="btn btn-success" id="continue">Continue</Link>
    </div>
  <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab"><button className="btn btn-success" id="continue">Continue</button></div>
</div>
            </div>
            </div>
        </div>
      </div>
    );
  }
}
