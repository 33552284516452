import React from "react";
import Login5 from '../../images/Login-36.png';
import { Link } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import GuestPDF from "./GuestPDF";
const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth' // for smoothly scrolling
  });
  sessionStorage.removeItem("SearchText");
  sessionStorage.setItem("SearchText","");
};
class EmpThanksPageSignin extends React.Component {
  constructor(props) {
    let GuestID=JSON.parse(sessionStorage.getItem("Guest Id"))
   // console.log(GuestID)
    super(props);
    
    this.state = {
   guestid:GuestID
     
    };
  this.handleHome=this.handleHome.bind(this)
  this.viewPDF=this.viewPDF.bind(this)
  }
handleHome(){
  window.location.href=`./`
}
viewPDF(){
  window.open("/guestpdf","_blank")
}
    componentDidMount(){
    
    }
  
  render() {

    
    return (
      <div>
         {/* ======= Header ======= */}
      <Header />
      <div>
    

      </div>
      {/* End Header */}
        <main id="main" style={{ background: "#303030", clear: "both" }}>
          <br />
          <br />
          {/* ======= About Section ======= */}
          <section id="clients" className="clients">
            <div
              className="container col-md-5 col-xs-12 col-sm-12 col-lg-6"
              data-aos="fade-up"
            >
              <style
                type="text/css"
                dangerouslySetInnerHTML={{
                  __html:
                    "\n    .boxxlr{\n     border: 0px solid green;\n    background: #edf8f2;\n    margin-right: 2.5%;\n    margin-bottom: 7%;\n    color: #000;\n    margin-left: 9%;\n    width: 100%;\n    }\n/*.boxxlr:hover {\n    border: 0px solid green;\n    background: green;\n    margin-right: 1%;\n    margin-bottom: 1%;\n     color: #fff;\n}*/\n\n.boxclrtitle {\nfont-size: 12px;font-weight: bold;\n}\n\n.boxclrtitle1 {\nfont-size: 18px;font-weight: bold;\n}\n/*.boxxlr .boxclrtitle:hover {\nfont-size: 12px;font-weight: bold;\n}*/\n\n\n  ",
                }}
              />
              <div className="row no-gutters">
                <div className="content col-xl-12 d-flex align-items-center">
                  <div className="content col-xl-12 d-flex align-items-center" id="mainbox">
                    <div
                      className="row"
                      style={{
                        display: "flex",
                        justifyContent: "center" /* alignItems: 'center', */,
                        background: "#fff",
                        width: "100%" /* marginLeft: '50%', */,
                        borderRadius: "20px",
                        margin: "auto",
                      }}
                    >
              
                      <div className="row h-100 row align-items-center">
                        <div
                          className="col-md-10"
                          style={{ margin: "auto", marginBottom: "6%" }}
                        >
                          {this.state.guestid==null || this.state.guestid==""?null:<>
                          <div>
                            <Link target="_blank"
                            className="viewpdf"
                         to={{ 
                          
                           pathname: `/guestpdf/${this.state.guestid}`, 
                            
                         }}>View PDF
                            </Link>
                            </div>
                          </>}
                          
                            <img
                              src={Login5}
                              className="empguestsignuplogo"
                            />
                           
                            <h4 className="empguestsignupmainheadingthanks">
                           {this.state.guestid==null || this.state.guestid==" "?<>
                           👏 Test Complete! 👏
                           </>:<>👏 Assessment Complete! 👏</>} 
                            </h4>
                          <br/><br/><br/>
                          {this.state.guestid==null || this.state.guestid==" "?<>
                          <h4 className="empthankspageheadingone">
                            Thank you for completing the employability assessment.
                            </h4>
                          </>:<>
                          <h4 className="empthankspageheadingone">
                            Thank you for completing the employability assessment,<br/>your results have been sent to your email.
                            </h4>
                          </>}
                           
                          {this.state.guestid==null || this.state.guestid==" "?<>
                          <h4 className="empthankspageheadingone">
                            you can now view your results in your student dashboard. 
                            </h4>
                          </>:<>
                          <br/>
                          <h4 className="empthankspageheadingone">
                            Ready to boost your skills? Sign up to GigeconoMe today !
                            </h4>
                          </>}
                          {this.state.guestid==null || this.state.guestid==" "?<>
                          <div style={{textAlign:"center",marginTop:"10%"}}>
                               <Link to="/studentdashboard" className="btn btn-success" style={{fontFamily:"Gotham",backgroundColor:"rgb(0,147,69)"}} >Go to Dashboard</Link>
                       </div>
                          </>:<>
                          <div className="row bigcompanysearch" style={{marginTop:"15%"}}>
                                <div className="col-sm-2"></div>
                                <div className="col-sm-4">
                                    <button onClick={this.handleHome}  className="btn btn-success backtohomepagebtn" > Homepage </button></div>
                                <div className="col-sm-4"><Link to="/studentsignup_one"  className="btn btn-success empthankspagebtn1" >Sign Up</Link></div>
                       </div>
                       <div className="mscreenava">
                        <table style={{marginBottom:"8%",marginTop:"20%"}}>
                          <tr>
                            <th style={{width:"60%"}}> <button onClick={this.handleHome}  className="btn btn-success backtohomepagebtn" > Back to Homepage </button></th>
                            <th style={{width:"40%",paddingLeft:"9%"}}><Link to="/studentsignup_one"  className="btn btn-success backtohomepagebtn" >Sign Up</Link></th>
                          </tr>
                        </table>
                       </div>
                          </>} 
                      

                      
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        {/* End #main */}
        {/* ======= Footer ======= */}
        
 <Footer />
      </div>
    );
  }
}
export default EmpThanksPageSignin;
