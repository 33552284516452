import React from "react";
import Students from "../../images/students.png";
import Universities from "../../images/universites.png";
import Startup from "../../images/startup.png";
import Companies from "../../images/companies.png";
import Student11 from "../../images/student11.png";
import Companies11 from "../../images/companies11.png";
import Educator11 from "../../images/educator11.png";
import StudentHow1 from "../../images/studenthow1.png";
import StudentHow2 from "../../images/studenthow2.png";
import StudentHow3 from "../../images/studenthow3.png";
import StudentHow4 from "../../images/studenthow4.png";
export default class StudentMscreen extends React.Component {
    constructor(){
        super()
        this.state={
          student:1,
          company:0,
          educator:0
        }
        this.handleStudent=this.handleStudent.bind(this)
        this.handleCompany=this.handleCompany.bind(this)
        this.handleEducator=this.handleEducator.bind(this)
      }
      handleStudent(){
        this.setState({student:1,company:0,educator:0})
      }
      handleCompany(){
        this.setState({company:1,student:0,educator:0})
      }
      handleEducator(){
        this.setState({educator:1,company:0,student:0})
      }
  render() {
    return (
      <div>
        <hr style={{ border: "1px solid #009345", marginTop: "5%",visibility:"hidden" }} />
        <h4
            style={{
             
    
              textAlign: "center",
              color: "black",
              fontFamily:"Gotham",
              letterSpacing:"1px",
              padding:"4%"
              
            }}
           
          >
            Everything you need to know about how We connect <span style={{color:"#00539c",fontWeight:"bold"}}>Students</span> and <span style={{color:"#00539c",fontWeight:"bold"}}>Employers.</span>
          
          </h4>
       
        <table style={{textAlign:"center",margin:"auto",marginBottom:"2%"}}>
                <tr>
                    <td style={{width:"20%"}}><h6 style={{cursor:"pointer"}} onClick={this.handleStudent}>Student</h6></td>
                    <td><h6 style={{cursor:"pointer"}} onClick={this.handleCompany}>Companies</h6></td>
                    
                    <td><h6 style={{cursor:"pointer"}} onClick={this.handleEducator}>Educators</h6></td>
                </tr>
              </table>
              <div class="progress" style={{height:"6px",width:"85%",marginLeft:"5%"}}>
            {this.state.student==1?<>
              <div class="progress-bar blueprogress"
                style={{width:"27%",borderRadius:"7px"}}>
            </div>
            </>:<>
            <div class="progress-bar"
                style={{width:"30%",backgroundColor:'#e9ecef'}}>
            </div>
            </>}
            {this.state.company==1?<>
              <div class="progress-bar blueprogress"
                style={{width:"30%",borderRadius:"7px"}}>
            </div>
            </>:<>
            <div class="progress-bar"
                style={{width:"38%",backgroundColor:'#e9ecef'}}>
            </div>
            </>}
          {this.state.educator==1?<>
            <div class="progress-bar blueprogress"
                style={{width:"34%",borderRadius:"7px"}}>
            </div>
          </>:<></>}
        </div>
        {this.state.student==1?<>
        <div className="text-center container" style={{padding:"5%"}}>
          <img src={StudentHow1} style={{width:"20%"}} />
          <h6 style={{color:"#00539C"}}><b>Career Readiness Test</b></h6>
                <p style={{textTransform:"none",fontSize:15,fontFamily:"GothamLight",fontWeight:"bold"}}>Complete our assessment to understand strengths and weakness in key areas needed to join future workforce.</p>
        </div>
        <div className="text-center container" style={{marginTop:"-5%"}}>
          <img src={StudentHow2} style={{width:"20%"}} />
          <h6 style={{color:"#00539C"}}><b>Complete Profile</b></h6>
                <p style={{textTransform:"none",fontSize:16,fontFamily:"GothamLight",fontWeight:"bold"}}> Sign Up and complete profile & additional details including studies, job opportunities & interests.</p>
        </div>
        <div className="text-center container" style={{marginTop:"-2%"}}>
          <img src={StudentHow3} style={{width:"20%"}} />
          <h6 style={{color:"#00539C"}}><b>Get Matched</b></h6>
                <p style={{textTransform:"none",fontSize:15,fontFamily:"GothamLight",fontWeight:"bold"}}> Start receiving opportunities according to preferences and gaps from employers across various sectors.</p>
        </div>
        <div className="text-center container" style={{marginTop:"-3%",marginBottom:"10%"}}>
          <img src={StudentHow4} style={{width:"18%"}} />
          <h6 style={{color:"#00539C"}}><b>Getting Job Readiness</b></h6>
                <p style={{textTransform:"none",fontSize:15,fontFamily:"GothamLight",fontWeight:"bold"}}>Complete opportunities, earn and start developing workplace skills and building your portfolio.</p>
        </div>
        </>:null}
        {this.state.company==1?<>
        <div className="text-center container" style={{padding:"5%"}}>
          <img src={StudentHow1} style={{width:"20%"}} />
          <h6 style={{color:"#00539C"}}><b>Post an Opportunity</b></h6>
                <p style={{textTransform:"none",fontSize:17,fontFamily:"GothamLight",fontWeight:"bold"}}>Post a Project or different job Opportunities using our forms for our talented students.</p>
        </div>
        <div className="text-center container" style={{marginTop:"-5%"}}>
          <img src={StudentHow2} style={{width:"20%"}} />
          <h6 style={{color:"#00539C"}}><b>Review Students</b></h6>
                <p style={{textTransform:"none",fontSize:18,fontFamily:"GothamLight",fontWeight:"bold"}}> Review the proposals and profiles that our smart and technology have contacted.</p>
        </div>
        <div className="text-center container" style={{marginTop:"-2%"}}>
          <img src={StudentHow3} style={{width:"20%"}} />
          <h6 style={{color:"#00539C"}}><b>Shortlist Students</b></h6>
                <p style={{textTransform:"none",fontSize:17,fontFamily:"GothamLight",fontWeight:"bold"}}> Schedule meetings and engage our talents or assign projects directly to Perfect Matches.</p>
        </div>
        <div className="text-center container" style={{marginTop:"-3%",marginBottom:"10%"}}>
          <img src={StudentHow4} style={{width:"20%"}} />
          <h6 style={{color:"#00539C"}}><b>Evaluate Students</b></h6>
                <p style={{textTransform:"none",fontSize:17,fontFamily:"GothamLight",fontWeight:"bold"}}>Upon completion of project or opportunity, evaluate and provide feedback for Hired Talent.</p>
        </div>
        </>:null}
        {this.state.educator==1?<>
        <div className="text-center container" style={{padding:"5%"}}>
          <img src={StudentHow1} style={{width:"20%"}} />
          <h6 style={{color:"#00539C"}}><b>Complete Basic Information</b></h6>
                <p style={{textTransform:"none",fontSize:17,fontFamily:"GothamLight",fontWeight:"bold"}}>Complete basic Information to tell us more about you and your educational organisation.</p>
        </div>
        <div className="text-center container" style={{marginTop:"-5%"}}>
          <img src={StudentHow2} style={{width:"20%"}} />
          <h6 style={{color:"#00539C"}}><b>Receive call from GigeconoMe</b></h6>
                <p style={{textTransform:"none",fontSize:17,fontFamily:"GothamLight",fontWeight:"bold"}}> Receive a call from our teams to discuss how we can help your students and programs.</p>
        </div>
        <div className="text-center container" style={{marginTop:"-2%"}}>
          <img src={StudentHow3} style={{width:"20%"}} />
          <h6 style={{color:"#00539C"}}><b>Personalised Demo Session</b></h6>
                <p style={{textTransform:"none",fontSize:17,fontFamily:"GothamLight",fontWeight:"bold"}}> Take part in our Demo Session to understand how we connect school and employers.</p>
        </div>
        <div className="text-center container" style={{marginTop:"-3%",marginBottom:"10%"}}>
          <img src={StudentHow4} style={{width:"18%"}} />
          <h6 style={{color:"#00539C"}}><b>Join as an Educator</b></h6>
                <p style={{textTransform:"none",fontSize:16,fontFamily:"GothamLight",fontWeight:"bold"}}>Join GigeconoMe and start connecting with employers integrating academic programs into actual work.</p>
        </div>
        </>:null}
        {/* <div style={{ textAlign: "center" }}>
          {" "}
          <span>Select a button below to view more info </span>
        </div>
        <div class="flex-container">
          <div class="flex-child magenta" style={{backgroundColor:this.state.student==1?"#00539C":null,color:this.state.student==1?"white":null,cursor:"pointer",marginLeft:"3%",width:"85%",textAlign:"center",padding:"0.3%"}} onClick={this.handleStudent}>
            <img src={Students} style={{ width: "12%", padding: "1%" ,verticalAlign:"middle"}} />
            <span
              className="stumhow"
            >
              STUDENT
            </span>
          </div>

          <div class="flex-child green" style={{backgroundColor:this.state.company==1?"#00539C":null,color:this.state.company==1?"white":null,cursor:"pointer",marginRight:"3%",width:"85%",textAlign:"center",padding:"0.3%"}} onClick={this.handleCompanies}>
            <img src={Universities} style={{ width: "14%", padding: "1%",verticalAlign:"middle" }} />
            <span
              className="cpymhow"
            >
              COMPANIES
            </span>
          </div>
        </div>
        <div class="flex-container mt-3">
          <div class="flex-child magenta" style={{backgroundColor:this.state.stratups==1?"#00539C":null,color:this.state.stratups==1?"white":null,cursor:"pointer",marginLeft:"3%",width:"85%",textAlign:"center"}} onClick={this.handleStartups}>
            <img src={Startup} className="startsupmlogo" />
            <span
              className="startuptxtm"
            >
              STARTUPS
            </span>
          </div>

          <div class="flex-child green" style={{backgroundColor:this.state.educator==1?"#00539C":null,color:this.state.educator==1?"white":null,cursor:"pointer",marginRight:"3%",textAlign:"center",padding:"0.3%",width:"85%"}} onClick={this.handleEducators}>
            <img src={Companies} style={{ width: "14%", padding: "1%",verticalAlign:"middle" }} />
            <span
              className="educatortxtm"
            >
              EDUCATORS
            </span>
          </div>
        </div>
        {this.state.student==1?<>
        <img src={Student11} style={{padding:"5%"}} />
        </>:null}
        {this.state.company==1?<>
        <img src={Companies11} style={{padding:"5%"}} />
        </>:null}
        {this.state.stratups==1?<>
        <img src={Companies11} style={{padding:"5%"}}/>
        </>:null}
        {this.state.educator==1?<>
        <img src={Educator11} style={{padding:"5%"}} />
        </>:null} */}
        
      </div>
    );
  }
}
