import React from "react";
import image1 from "../../images/wave.png";
export default class Newhowitswork extends React.Component{
  constructor(){
    super();
    this.state={
      student:1,
      company:0,
      educator:0
    }
    this.handleStudent=this.handleStudent.bind(this)
    this.handleCompany=this.handleCompany.bind(this)
    this.handleEducator=this.handleEducator.bind(this)
  }
  handleStudent(){
    this.setState({student:1,company:0,educator:0})
  }
  handleCompany(){
    this.setState({company:1,student:0,educator:0})
  }
  handleEducator(){
    this.setState({educator:1,company:0,student:0})
  }
render(){
    return(<>

            <div>
           
            <h2
            style={{
             
    
              textAlign: "center",
              color: "black",
              fontFamily:"Gotham",
              letterSpacing:"1px"
              
            }}
           
          >
            Everything you need to know about how 
          
          </h2>
          <h2
            style={{
             
    
              textAlign: "center",
              color: "black",
              fontFamily:"Gotham",
              letterSpacing:"1px"
             
            }}
           
          >
            We connect <span style={{color:"#00539C",fontWeight:"bold"}}>Students</span> and <span style={{color:"#00539C",fontWeight:"bold"}}>Employers.</span>
          
          </h2>
            </div>
            
            <div className="container" style={{display:"grid",marginTop:"3%",color:"black"}}>
              <table style={{width:"60%",textAlign:"center",margin:"auto"}}>
                <tr>
                    <td style={{width:"20%"}}><h4 style={{cursor:"pointer"}} onClick={this.handleStudent}>Student</h4></td>
                    <td><h4 style={{marginLeft:"15%",cursor:"pointer"}} onClick={this.handleCompany}>Companies</h4></td>
                    
                    <td><h4 style={{cursor:"pointer"}} onClick={this.handleEducator}>Educators</h4></td>
                </tr>
              </table>
              <div class="progress" style={{maxWidth:"56%",height:"6px",marginLeft:"20%"}}>
            {this.state.student==1?<>
              <div class="progress-bar blueprogress"
                >
            </div>
            </>:<>
            <div class="progress-bar"
                style={{width:"35%",backgroundColor:'#e9ecef'}}>
            </div>
            </>}
            {this.state.company==1?<>
              <div class="progress-bar blueprogress"
                style={{width:"27%"}}>
            </div>
            </>:<>
            <div class="progress-bar"
                style={{width:"35%",backgroundColor:'#e9ecef'}}>
            </div>
            </>}
          {this.state.educator==1?<>
            <div class="progress-bar blueprogress"
                style={{borderRadius:"7px"}}>
            </div>
          </>:<></>}
        </div>
        {this.state.student==1?<>
          <img src={image1} className="newstudenthowitworksimg studenthowworkani" />
        </>:null}
        {this.state.company==1?<>
          <img src={image1} className="newstudenthowitworksimg studenthowworkani" />
        </>:null}
        {this.state.educator==1?<>
          <img src={image1} className="newstudenthowitworksimg studenthowworkani" />
        </>:null}
       {this.state.student==1?<>
        <table className="newhowitsworktbl studenthowworkani">
                <tr>
                    <td style={{width:"20%"}}> <h6 style={{color:"#00539C"}}><b>Career Readiness Test</b></h6>
                <p style={{textTransform:"none",fontSize:14.5,fontFamily:"GothamLight",fontWeight:"bold"}}>Complete our assessment to understand strengths and weakness in key areas needed to join future workforce.</p></td>
                <td style={{width:"22%",paddingLeft:"5%"}}> <h6 style={{color:"#00539C"}}><b>Complete Profile</b></h6>
                <p style={{textTransform:"none",fontSize:15,fontFamily:"GothamLight",fontWeight:"bold"}}> Sign Up and complete profile & additional details including studies, job opportunities & interests.</p></td>
                    
                    <td style={{width:"25%",paddingLeft:"5%"}}><h6 style={{color:"#00539C"}}><b>Get Matched</b></h6>
                <p style={{textTransform:"none",fontSize:15,fontFamily:"GothamLight",fontWeight:"bold"}}> Start receiving opportunities according to preferences and gaps from employers across various sectors.</p></td>
                <td className="gettingjobread"><h6 style={{color:"#00539C"}}><b>Getting Job Readiness</b></h6>
                <p style={{textTransform:"none",fontSize:15,fontFamily:"GothamLight",fontWeight:"bold"}}>Complete opportunities, earn and start developing workplace skills and building your portfolio.</p></td>
                </tr>
              </table>
       </>:null}

       {this.state.company==1?<>
        <table className="newhowitsworktbl studenthowworkani">
                <tr>
                    <td style={{width:"20%"}}> <h6 style={{color:"#00539C"}}><b>Post an Opportunity</b></h6>
                <p style={{textTransform:"none",fontSize:15,fontFamily:"GothamLight",fontWeight:"bold"}}>Post a Project or different job Opportunities using our forms for our talented students.</p></td>
                <td style={{width:"25%",paddingLeft:"5%"}}> <h6 style={{color:"#00539C"}}><b>Review Students</b></h6>
                <p style={{textTransform:"none",fontSize:15,fontFamily:"GothamLight",fontWeight:"bold"}}> Review the proposals and profiles that our smart and technology have contacted.</p></td>
                    
                    <td style={{width:"25%",paddingLeft:"5%"}}><h6 style={{color:"#00539C"}}><b>Shortlist Students</b></h6>
                <p style={{textTransform:"none",fontSize:15,fontFamily:"GothamLight",fontWeight:"bold"}}> Schedule meetings and engage our talents or assign projects directly to Perfect Matches.</p></td>
                <td style={{width:"25%",paddingLeft:"5%"}}><h6 style={{color:"#00539C"}}><b>Evaluate Students</b></h6>
                <p style={{textTransform:"none",fontSize:15,fontFamily:"GothamLight",fontWeight:"bold"}}>Upon completion of project or opportunity, evaluate and provide feedback for Hired Talent.</p></td>
                </tr>
              </table>
       </>:null}
             
       {this.state.educator==1?<>
        <table className="newhowitsworktbl studenthowworkani">
                <tr>
                    <td style={{width:"20%"}}> <h6 style={{color:"#00539C"}}><b>Complete Basic Information</b></h6>
                <p style={{textTransform:"none",fontSize:15,fontFamily:"GothamLight",fontWeight:"bold"}}>Complete basic Information to tell us more about you and your educational organisation.</p></td>
                <td style={{width:"25%",paddingLeft:"5%"}}> <h6 style={{color:"#00539C"}}><b>Receive call from Us</b></h6>
                <p style={{textTransform:"none",fontSize:15,fontFamily:"GothamLight",fontWeight:"bold"}}> Receive a call from our teams to discuss how we can help your students and programs.</p></td>
                    
                    <td style={{width:"25%",paddingLeft:"5%"}}><h6 style={{color:"#00539C"}}><b>Personalised Demo Session</b></h6>
                <p style={{textTransform:"none",fontSize:15,fontFamily:"GothamLight",fontWeight:"bold"}}> Take part in our Demo Session to understand how we connect school and employers.</p></td>
                <td style={{width:"29%",paddingLeft:"5%"}}><h6 style={{color:"#00539C"}}><b>Join as an Educator</b></h6>
                <p style={{textTransform:"none",fontSize:15,fontFamily:"GothamLight",fontWeight:"bold"}}>Join GigeconoMe and start connecting with employers integrating academic programs into actual work.</p></td>
                </tr>
              </table>
       </>:null}
            </div>
                
    
    
    </>)
}
}