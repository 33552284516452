import React, { useState } from "react";
import Swal from 'sweetalert2';
import "./modal.css";
function close(){
    window.location.reload();
  }
export default class  EditWorkExperience extends React.Component{
    constructor(props) {
        super(props);
        let comments = JSON.parse(sessionStorage.getItem("someData"));
        let WorkID = JSON.parse(sessionStorage.getItem("WorkexpID"));
       console.log(comments)
        this.state = {
          
        
         
         
          userid: comments,
          addstudata :"workexperience",
         
          dropdownlist_input : "nationality",
         
          selectOptions : [],
          selectSchool:[],
          selectQualification:[],
          title:"",
          companyname:"",
          degree:"",
          roletype:"",
          startdate:"",
          enddate:"",
          description:"",
          currentcompany:"yes",
          geomessage:"",
          workexperienceid:WorkID,
          rolename:""
    
      
      }
      this.handleSubmit = this.handleSubmit.bind(this);
    }
    
      handleSubmit = (event) => {
        event.preventDefault()
       
        const data = {
    
          userid: this.state.userid,
          workexperienceid: this.state.workexperienceid,
          title:this.state.title,
          companyname:this.state.companyname,
          roletype:this.state.roletype,
          startdate:this.state.startdate,
          enddate:this.state.enddate,
          currentcompany:this.state.currentcompany,
          description:this.state.description,
         
         
    
        }
      
        let BaseUrl = JSON.parse(sessionStorage.getItem("baseurl"));
    
          fetch(BaseUrl + "editworkexperience", {
            method: "POST",
    
            body: JSON.stringify(data), // data can be `string` or {object}!
    
            headers: { "Content-Type": "application/json" },
          })
            .then((res) => res.json())
    
            .catch((error) => console.error("Error:", error))
    
            .then((response) => {
                this.setState({geomessage:response.message})
                window.location.reload();
             
              
            });
        
      }
      componentDidMount(){
        this.getOptions()




    }
    async getOptions(){
      const data_one={dropdownlist_input:this.state.dropdownlist_input,
      
      }
    
      let BaseUrl = JSON.parse(sessionStorage.getItem('baseurl'));
    
      fetch( 'https://www.gigeconome.com:5200/api/getdropdownlist', { method: 'POST', 
    
       body: JSON.stringify(data_one), // data can be `string` or {object}!
    
       headers:{ 'Content-Type': 'application/json' } })
    
       .then(res => res.json())
    
       .catch(error => console.error('Error:', error))
    
       .then((json)=>{
        const data = json.result
        
        this.setState({selectOptions: data})
       })
       const data_two={dropdownlist_input:"country"
      
       }
     
       //let BaseUrl = JSON.parse(sessionStorage.getItem('baseurl'));
     
       fetch( 'https://www.gigeconome.com:5200/api/getdropdownlist', { method: 'POST', 
     
        body: JSON.stringify(data_two), // data can be `string` or {object}!
     
        headers:{ 'Content-Type': 'application/json' } })
     
        .then(res => res.json())
     
        .catch(error => console.error('Error:', error))
     
        .then((json)=>{
         const data = json.result
         
         this.setState({selectSchool: data})
        })
    
      
    
    
        const qual_data={dropdownlist_input:"qualification"
      
    }
    
    //let BaseUrl = JSON.parse(sessionStorage.getItem('baseurl'));
    
    fetch('https://www.gigeconome.com:5200/api/getdropdownlist', { method: 'POST', 
    
     body: JSON.stringify(qual_data), // data can be `string` or {object}!
    
     headers:{ 'Content-Type': 'application/json' } })
    
     .then(res => res.json())
    
     .catch(error => console.error('Error:', error))
    
     .then((json)=>{
      const data = json.result
      
      this.setState({selectQualification: data})
     })
      console.log(this.state.workexperienceid)
   

      }
        handleChange = (e) => {
          this.setState({
            [e.target.name]: e.target.value,
           
          });





          
        };
   
render(){
  let {Workexperienceid} = this.props;
  const{geomessage}=this.state
  const{currentcompany}=this.state;
  return (
    <>
        <div class="ft-modal" id="ft-workedit-modal">
        <div class="ft-modal-content" >
          <div class="ft-modal-header">
            <div class="header">
              <h5
                class="modal-title"
                id="exampleModalLabel"
                style={{
                  textAlign: "center",
                  
                  fontWeight: "bold",
                }}
              >
               Edit Work Experience
               
              </h5>
              <hr />
            </div>
          </div>
          <div class="ft-modal-body">


          <form onSubmit={this.handleSubmit} style={{fontSize:15}}>
   <div class="row">
                      <div class="col-sm-1"></div>
                      <div class="col-sm-5">
                       <b style={{display:'flex' ,fontFamily:"GothamLight"}}>Title</b> 
                        <p>
                          <input
                            type="select"
                            placeholder="Enter Your Job Title"
                            className="form-control mt-2"
                            name ="title" 
                            onChange = {this.handleChange}
                            value={this.state.title}
                          />
                        </p>
                      </div>
                      <div class="col-sm-5">
                      <b style={{display:'flex' ,fontFamily:"GothamLight"}}>Company</b>
                        <p>
                          <input
                            type="select"
                            placeholder="Enter Your Comapny Name"
                            className="form-control mt-2"
                            name ="companyname" 
                            onChange = {this.handleChange}
                            value={this.state.companyname}
                          />
                        </p>
                      </div>
                      <div class="col-sm-1"></div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-sm-1"></div>
                      <div class="col-sm-10">
                      <b style={{display:'flex' ,fontFamily:"GothamLight"}}> Role Type</b>
                        <select
                                className="form-select mt-2"
                                name ="roletype" 
                                onChange = {this.handleChange}
                              >
                                <option value={this.state.roletype}>{this.state.rolename}</option>
                                <option value="1">Opportunities</option>
                                <option  value="2">Internship</option>
                                <option value="3">Externship</option>
                                <option value="4">Work Experience</option>
                                <option value="5">Work Placement</option>
                                <option value="6">Part Time Roles</option>
                                <option value="7">Side Gig Opportunities</option>
                                <option value="8">Course Work Related</option>
                                <option value="9">Portfolio Building</option>
                                <option value="10">Other</option>
             
                              </select>
                      </div>

                      <div class="col-sm-1"></div>
                    </div>

                    <div class="row mt-3">
                      <div class="col-sm-1"></div>
                      <div class="col-sm-3">
                      <b style={{display:'flex' ,fontFamily:"GothamLight"}}> Start</b>
                        <p>
                        <input type="text" class="form-control mt-2"
                       name ="startdate" 
                       onChange = {this.handleChange}
                      placeholder={this.state.startdate}
                      onFocus={(e) =>
                        (e.currentTarget.type = "month")
                      }
                      onBlur={(e) =>
                        (e.currentTarget.type = "text")
                      }
                      min="2022-01" max="2022-12"
                      />
                  
                        </p>
                      </div>
                      <div class="col-sm-3">
                      <b style={{display:'flex' ,fontFamily:"GothamLight"}}> End</b>
                        <p class="mt-2">
                      <input type="text" class="form-control mt-1"
                       name ="enddate" 
                       onChange = {this.handleChange}
                      placeholder={this.state.enddate}
                      onFocus={(e) =>
                        (e.currentTarget.type = "month")
                      }
                      onBlur={(e) =>
                        (e.currentTarget.type = "text")
                      }
                      min="2022-01" max="2022-12"
                      />
                        </p>
                      </div>
                      <div class="col-sm-5"></div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-sm-1"></div>
                      <div class="col-sm-6">
                        {currentcompany ?<>
                          <input
                          type="checkbox"
                          id="vehicle1"
                         
                          onChange = {this.handleChange}
                          style={{marginLeft:"-45%" ,fontFamily:"GothamLight"}}
                          checked
                        />
                        </>:<>
                        <input
                          type="checkbox"
                          id="vehicle1"
                          name ="currentcompany" 
                          onChange = {this.handleChange}
                          style={{marginLeft:"-45%" ,fontFamily:"GothamLight"}}
                          
                        />
                        </>}
                        
                        <label for="vehicle1" id="checkbox_text" >
                          {" "}
                         &nbsp; 
                         
                        
                           I am Currently Working Here
                        </label>
                      </div>

                      <div class="col-sm-5"></div>
                    </div>
                      <div class="row mt-3">
                        <div class="col-sm-1"></div>
                        <div class="col-sm-10">
                          <form>
                            <div class="form-group">
                              <label for="comment" style={{display:'flex' ,fontFamily:"GothamLight"}}>Description</label>
                              <textarea
                                class="form-control mt-2"
                                rows="5"
                                id="comment"
                                name ="description" 
                                onChange = {this.handleChange}
                                value={this.state.description}
                              ></textarea>
                            </div>
                          </form>
                        </div>
                        <div class="col-sm-2"></div>
                      </div>
                      <div class="col-sm-5"></div>

                    <div class="row mt-4">
                      <div class="col-sm-8"></div>
                      <div class="col-sm-2">
                        <button className="btn-secondary" id="education_btn">
                          Cancel
                        </button>
                      </div>
                      <div class="col-sm-1">
                        <button
                          style={{ backgroundColor: "rgb(0, 147, 69)" }}
                          className="btn-success"
                          id="education_btn"
                          type="submit"
                        >
                          Save
                        </button>
                      </div>
                      <div class="col-sm-1"></div>
                    </div>
                    <span style={{color:"rgb(0,147,69)",fontSize:15,marginTop:"5%",fontWeight:"bold"}}>{geomessage}</span>
                    <div class="ft-modal-footer">
            <a class="ft-modal-close" href="#" >
              [&#10006;]{" "}
            </a>
          </div>     </form>
        </div>
        </div>
      </div>

      
      <a
         
          className="mt-1"
          href="#ft-workedit-modal"
          
        >
          <i className="fa fa-pencil" style={{paddingLeft:"5%",fontSize:20}} ></i>
        </a>
    </>
  );
}
}
