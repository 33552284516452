import React from "react";
import Login5 from '../../images/Login-36.png';
import { Link } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Slider from "react-slick";
const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth' // for smoothly scrolling
  });
  sessionStorage.removeItem("SearchText");
  sessionStorage.setItem("SearchText","");
};
class EmpQuestions extends React.Component {

  constructor(props) {
    let comments = JSON.parse(sessionStorage.getItem('someData'));
    let g_id=JSON.parse(sessionStorage.getItem('Guest Id'));
    super(props);
    
    this.state = {
   userid:comments,
   eotquestionid: 1,
   questionsinfo:"",
   eotoptiondata:0,
   optionerror:1,
   questionid:1,
   guestid:g_id
 
     
    };
  this.handleContinue=this.handleContinue.bind(this)
  this.handleOne=this.handleOne.bind(this)
  this.handleTwo=this.handleTwo.bind(this)
  this.handleThree=this.handleThree.bind(this)
  this.handleFour=this.handleFour.bind(this)
  this.handleFive=this.handleFive.bind(this)
  this.handleRemove=this.handleRemove.bind(this)
  this.handleEmpty=this.handleEmpty.bind(this)
  this.handleBack=this.handleBack.bind(this)
  }
  
  handleEmpty(){
    this.setState({eotoptiondata:0,optionerror:0})
  }
  handleRemove(){
    this.setState({eotoptiondata:0})
  }
  handleOne(){
this.setState({eotoptiondata:1,optionerror:1})
  }
  handleTwo(){
    this.setState({eotoptiondata:2,optionerror:1})
      }
handleThree(){
    this.setState({eotoptiondata:3,optionerror:1})
  }
  handleFour(){
    this.setState({eotoptiondata:4,optionerror:1})
  }
  handleFive(){
    this.setState({eotoptiondata:5,optionerror:1})
  }

  handleBack(){
  // console.log(this.state.eotquestionid)
  if(this.state.guestid==""|| this.state.guestid==null){
    const data={userid:this.state.userid,
      eotquestionid:this.state.eotquestionid-1,
      addstudata: "getstu_EOTanswerinfo",

    }
  console.log(data)
  
    fetch( 'https://www.gigeconome.com:5200/api/addstu_profiledata', { method: 'POST', 
  
     body: JSON.stringify(data), // data can be `string` or {object}!
  
     headers:{ 'Content-Type': 'application/json' } })
  
     .then(res => res.json())
  
     .catch(error => console.error('Error:', error))
  
     .then((response)=>{
       console.log(response)
       if(response.message=="No data Found."){
            window.location.href=`./emptestdescription`
       }
       else{
        this.setState({questionid:response.result.questionid,
          eotoptiondata:response.result.eotoptiondata,
          questionsinfo:response.result.questionsinfo,
          eotquestionid:response.result.questionid
        })
       }    
    
     }) 
  }
   else{
    const g_data={userid:"38",
      eotquestionid:this.state.eotquestionid-1,
      addstudata: "getguest_EOTanswerinfo",
      guestid:this.state.guestid

    }
  console.log(g_data)
  
    fetch( 'https://www.gigeconome.com:5200/api/addstu_profiledata', { method: 'POST', 
  
     body: JSON.stringify(g_data), // data can be `string` or {object}!
  
     headers:{ 'Content-Type': 'application/json' } })
  
     .then(res => res.json())
  
     .catch(error => console.error('Error:', error))
  
     .then((response)=>{
       console.log(response)
       if(response.message=="No data Found."){
            window.location.href=`./emptestdescription`
       }
       else{
        this.setState({questionid:response.result.questionid,
          eotoptiondata:response.result.eotoptiondata,
          questionsinfo:response.result.questionsinfo,
          eotquestionid:response.result.questionid
        })
       }    
    
     }) 
   }
  }
  handleContinue(){
     
if(this.state.guestid==""||this.state.guestid==null){
  const data={userid:this.state.userid,
    eotquestionid:this.state.eotquestionid,
    addstudata: "insertstu_EOTanswerinfo",
    eotoptiondata:this.state.eotoptiondata

  }

  console.log(data)
  fetch( 'https://www.gigeconome.com:5200/api/addstu_profiledata', { method: 'POST', 

   body: JSON.stringify(data), // data can be `string` or {object}!

   headers:{ 'Content-Type': 'application/json' } })

   .then(res => res.json())

   .catch(error => console.error('Error:', error))

   .then((response)=>{
    console.log(response)
     if(response.nextquestionid==0){
              window.location.href=`./empthankspagesignin`
     }
     else{
      this.setState({eotquestionid:response.nextquestionid})
   
      const data_one={userid:this.state.userid,
        eotquestionid:response.nextquestionid,
        addstudata: "getstu_EOTquestionsinfo",
  
      }
    
    
      fetch( 'https://www.gigeconome.com:5200/api/addstu_profiledata', { method: 'POST', 
    
       body: JSON.stringify(data_one), // data can be `string` or {object}!
    
       headers:{ 'Content-Type': 'application/json' } })
    
       .then(res => res.json())
    
       .catch(error => console.error('Error:', error))
    
       .then((response)=>{
        this.setState({questionsinfo:response.result.questionsinfo,
          eotoptiondata:0,
          questionid:response.result.questionid
        })
        
      
       })

     }
  
  
   })
}
  else{
    const gdata={userid:"38",
      eotquestionid:this.state.eotquestionid,
      addstudata: "insertguest_EOTanswerinfo",
      eotoptiondata:this.state.eotoptiondata,
      guestid:this.state.guestid

    }
  
    console.log(gdata)
    fetch( 'https://www.gigeconome.com:5200/api/addstu_profiledata', { method: 'POST', 
  
     body: JSON.stringify(gdata), // data can be `string` or {object}!
  
     headers:{ 'Content-Type': 'application/json' } })
  
     .then(res => res.json())
  
     .catch(error => console.error('Error:', error))
  
     .then((response)=>{
      console.log(response)
       if(response.nextquestionid==0){
                window.location.href=`./empthankspagesignin`
       }
       else{
        this.setState({eotquestionid:response.nextquestionid})
     
        const data_one={userid:"38",
          eotquestionid:response.nextquestionid,
          addstudata: "getstu_EOTquestionsinfo",
    
        }
      
      
        fetch( 'https://www.gigeconome.com:5200/api/addstu_profiledata', { method: 'POST', 
      
         body: JSON.stringify(data_one), // data can be `string` or {object}!
      
         headers:{ 'Content-Type': 'application/json' } })
      
         .then(res => res.json())
      
         .catch(error => console.error('Error:', error))
      
         .then((response)=>{
          this.setState({questionsinfo:response.result.questionsinfo,
            eotoptiondata:0,
            questionid:response.result.questionid
          })
          
        
         })

       }
    
    
     })
  }    
  }

    componentDidMount(){
if(this.state.guestid==" " || this.state.guestid==null){
  const data_one={userid:this.state.userid,
    eotquestionid:this.state.eotquestionid,
    addstudata: "getstu_EOTquestionsinfo",

  }
console.log(data_one)

  fetch( 'https://www.gigeconome.com:5200/api/addstu_profiledata', { method: 'POST', 

   body: JSON.stringify(data_one), // data can be `string` or {object}!

   headers:{ 'Content-Type': 'application/json' } })

   .then(res => res.json())

   .catch(error => console.error('Error:', error))

   .then((response)=>{
    this.setState({questionsinfo:response.result.questionsinfo,

    })
    
  
   })
}
   else{
    const guestdata={userid:"38",
      eotquestionid:this.state.eotquestionid,
      addstudata: "getstu_EOTquestionsinfo",
  
    }
  console.log(guestdata)
  
    fetch( 'https://www.gigeconome.com:5200/api/addstu_profiledata', { method: 'POST', 
  
     body: JSON.stringify(guestdata), // data can be `string` or {object}!
  
     headers:{ 'Content-Type': 'application/json' } })
  
     .then(res => res.json())
  
     .catch(error => console.error('Error:', error))
  
     .then((response)=>{
      this.setState({questionsinfo:response.result.questionsinfo,
  
      })
      
    
     })
   }   
    }
  
  render() {

    
    return (
      <div>
         {/* ======= Header ======= */}
      <Header />
      <div>
    

      </div>
      {/* End Header */}
        <main id="main" style={{ background: "#303030", clear: "both" }}>
          <br />
          <br />
          {/* ======= About Section ======= */}
          <section id="clients" className="clients">
            <div
              className="container col-md-8 col-xs-12 col-sm-12 col-lg-7"
              data-aos="fade-up"
            >
              <style
                type="text/css"
                dangerouslySetInnerHTML={{
                  __html:
                    "\n    .boxxlr{\n     border: 0px solid green;\n    background: #edf8f2;\n    margin-right: 2.5%;\n    margin-bottom: 7%;\n    color: #000;\n    margin-left: 9%;\n    width: 100%;\n    }\n/*.boxxlr:hover {\n    border: 0px solid green;\n    background: green;\n    margin-right: 1%;\n    margin-bottom: 1%;\n     color: #fff;\n}*/\n\n.boxclrtitle {\nfont-size: 12px;font-weight: bold;\n}\n\n.boxclrtitle1 {\nfont-size: 18px;font-weight: bold;\n}\n/*.boxxlr .boxclrtitle:hover {\nfont-size: 12px;font-weight: bold;\n}*/\n\n\n  ",
                }}
              />
              <div className="row no-gutters">
                <div className="content col-xl-12 d-flex align-items-center">
                  <div className="content col-xl-12 d-flex align-items-center">
                    <div
                      className="row"
                      style={{
                        display: "flex",
                        justifyContent: "center" /* alignItems: 'center', */,
                        background: "#fff",
                        width: "100%" /* marginLeft: '50%', */,
                        borderRadius: "20px",
                        margin: "auto",
                      }}
                    >
              
                      <div className="row h-100 row align-items-center">
                        <div
                          className="col-md-10"
                          style={{ margin: "auto", marginBottom: "6%" }}
                        >
                          
                          <img
                              src={Login5}
                             className="empguestsignuplogo"
                            />
                             
                            <h4 className="empguestsignupmainheading">
                            Question {this.state.questionid}
                            </h4>
                           <div className="row mt-2">
                               <div className="col-sm-12" style={{height:100}}>
                                 <br/>
                               <h4 className="empguestquesheading" id="empguestquesheading">
                                   {this.state.questionsinfo}
                               </h4>
                               </div>
                              
                           </div>
                         <div className="row mt-5">
                             <div className="col-sm-3" style={{textAlign:"center"}}>
                                 <span className="ansheading" >Don't have experience</span>
                             </div>
                             <div className="col-sm-5" style={{textAlign:"center"}}>
                                 <span className="ansheading">I have practised this but not feeling fully knowledgeable</span></div>
                             <div className="col-sm-4" style={{textAlign:"center"}}>
                                 <span className="ansheading">I'm really skilled/have experience</span></div>
                         </div>
                         <div className="mscreenava">
                         <table style={{marginLeft:"10%"}}>
                          <tr>
                            <th>
                            {this.state.eotoptiondata==1?<>
                              <div  onClick={this.handleRemove} class="numberCircleclick">1</div>
                            </>:<>
                            <div  onClick={this.handleOne} class="numberCircle">1</div>
                            </>}
                             </th>
                              <th style={{paddingLeft:"5%",width:"87%"}}><span style={{fontSize:12,fontFamily:"Gothic A1"}}>Don't have experience</span></th>
                            
                          </tr>
                          <tr>
                          <th style={{paddingTop:"5%"}}>
                          {this.state.eotoptiondata==2?<>
                              <div  onClick={this.handleRemove} class="numberCircleclick">2</div>
                            </>:<>
                            <div  onClick={this.handleTwo} class="numberCircle">2</div>
                            </>}
                             </th> 
                          </tr>
                          <tr>
                            <th style={{paddingTop:"5%"}}>
                            {this.state.eotoptiondata==3?<>
                              <div  onClick={this.handleRemove} class="numberCircleclick">3</div>
                            </>:<>
                            <div  onClick={this.handleThree} class="numberCircle">3</div>
                            </>}
                          </th>
                              <th style={{paddingLeft:"5%",lineHeight:"11px",paddingTop:"6%"}}><span style={{fontSize:12,fontFamily:"Gothic A1"}}>I have praticed this but not feeling fully knowledgeable</span></th>
                            
                          </tr>
                          <tr>
                          <th style={{paddingTop:"5%"}}>
                          {this.state.eotoptiondata==4?<>
                              <div  onClick={this.handleRemove} class="numberCircleclick">4</div>
                            </>:<>
                            <div  onClick={this.handleFour} class="numberCircle">4</div>
                            </>}</th>
                          </tr>
                          <tr>
                          <th style={{paddingTop:"5%"}}>
                          {this.state.eotoptiondata==5?<>
                              <div  onClick={this.handleRemove} class="numberCircleclick">5</div>
                            </>:<>
                            <div  onClick={this.handleFive} class="numberCircle">5</div>
                            </>}</th>
                              <th style={{paddingLeft:"5%",width:"87%",paddingTop:"5%"}}><span style={{fontSize:12,fontFamily:"Gothic A1"}}>I'm really skilled/have experience</span></th>
                          </tr>
                          </table>
                         </div>
                        <div className="row mt-3 mainemprow bigcompanysearch">
                            <div className="col-sm-1"></div>
                              <div className="col-sm-2 empcentertext"  ><span className="optiondata" >1</span><br/>
                           {this.state.eotoptiondata==1?<>
                            <i class="fa fa-circle" style={{color:"rgb(0,147,69)",fontSize:35,cursor:"pointer"}} onClick={this.handleRemove} ></i>
                            <span className="donothavethetextsecond" >Don't have experience</span>
                           </>:<>
                           <i class="fa fa-circle-thin" style={{color:"rgb(0,147,69)",fontSize:35,cursor:"pointer"}}  onClick={this.handleOne}></i>
                           <span className="donothavetext" >Don't have experience</span>
                           </>}   </div>
                              <div className="col-sm-2 empcentertext" ><span className="optiondata" >2</span><br/>
                              {this.state.eotoptiondata==2?<>
                                <i class="fa fa-circle" style={{color:"rgb(0,147,69)",fontSize:35,cursor:"pointer"}} onClick={this.handleRemove} ></i>
                               
                              </>:<><i class="fa fa-circle-thin" style={{color:"rgb(0,147,69)",fontSize:35,cursor:"pointer"}} onClick={this.handleTwo}></i>
                             
                              </>}
</div>
                              <div className="col-sm-2 empcentertext"  ><span className="optiondata" >3</span><br/>
                              {this.state.eotoptiondata==3?<>
                                <i class="fa fa-circle" style={{color:"rgb(0,147,69)",fontSize:35,cursor:"pointer"}} onClick={this.handleRemove} ></i>
                                <span className="donothavetext">I have practised this but not feeling fully knowledgeable</span>
                              </>:<> <i class="fa fa-circle-thin" style={{color:"rgb(0,147,69)",fontSize:35,cursor:"pointer"}} onClick={this.handleThree}></i>
                              <span className="donothavetext">I have practised this but not feeling fully knowledgeable</span>
                              </>}
                             </div>
                              <div className="col-sm-2 empcentertext"  ><span className="optiondata" >4</span><br/>
                              {this.state.eotoptiondata==4?<>
                                <i class="fa fa-circle" style={{color:"rgb(0,147,69)",fontSize:35,cursor:"pointer"}} onClick={this.handleRemove} ></i>
                              </>:<>
                              <i class="fa fa-circle-thin" style={{color:"rgb(0,147,69)",fontSize:35,cursor:"pointer"}} onClick={this.handleFour}></i>
                              </>}
                              </div>
                             
                              <div className="col-sm-2 empcentertext"  ><span className="optiondata">5</span><br/>
                              {this.state.eotoptiondata==5?<>
                                <i class="fa fa-circle" style={{color:"rgb(0,147,69)",fontSize:35,cursor:"pointer"}} onClick={this.handleRemove} ></i>
                                <span className="secondsubheading">I'm really skilled/have experience</span>
                              </>:<><i class="fa fa-circle-thin" style={{color:"rgb(0,147,69)",fontSize:35,cursor:'pointer'}} onClick={this.handleFive}></i>
                              <span className="donothavetext">I'm really skilled/have experience</span>
                              </>}
                              </div>
                        </div>
                        {this.state.optionerror==1?null:<>
                          <div className="row">
                          <div className="col-sm-12 optionerror">
                            <span>Please answer this question to proceed to the next question.</span>
                          </div>

                        </div>
                        </>}
                        
                       <div className="row mt-5">
                                <div className="col-sm-2"></div>
                                <div className="col-sm-4"><button  className="btn btn-success empquestionbackbtn shadow-none" onClick={this.handleBack} > <span style={{fontSize:17,fontWeight:"bold",marginLeft:"-10%"}} >&#60;</span> &nbsp; &nbsp;Back</button></div>
                                <div className="col-sm-4">
                                  {this.state.eotoptiondata==0?<>
                                    <button  className="btn btn-success empquestioncontbtn shadow-none" onClick={this.handleEmpty}> &nbsp;Continue<span style={{marginLeft:"11%",fontSize:17,fontWeight:"bold"}} >&#62;</span></button>
                                  </>:<>
                                  <button  className="btn btn-success empquestioncontbtn shadow-none" onClick={this.handleContinue} > &nbsp;Continue<span style={{marginLeft:"11%",fontSize:17,fontWeight:"bold"}} >&#62;</span></button>
                                  </>}
                                  </div>
                       </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        {/* End #main */}
        {/* ======= Footer ======= */}
 <Footer />
      </div>
    );
  }
}
export default EmpQuestions;
