import React, { Component } from "react";
import "./terms.css";
import Logo from "../../images/logo.png";
import { Link } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
class Cookiepolicy extends Component {
  render() {
    return (
      <div>
       <Header />
        <main
          id="main"
          style={{ background: "#303030", clear: "both", height: "175px" }}
          className="cookbox"
        >
          <section id="clients" className="clients">
            <h3
              style={{
                color: "#fff",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: "2.5%",
              }}
            >
              GigeconoMe Cookies Policy
            </h3>
          </section>
        </main>
        <main
          id="main"
          id="main_one"
          
        >
          <br />
          <br />
          {/* ======= About Section ======= */}
          <section id="clients" className="clients mscreentandcbox">
            <div
              className="container"
              data-aos="fade-up"
              style={{ marginTop: "-4%" }}
            >
              <style
                type="text/css"
                dangerouslySetInnerHTML={{
                  __html:
                    "\n    .boxxlr{\n     border: 0px solid green;\n    background: #edf8f2;\n    margin-right: 2.5%;\n    margin-bottom: 7%;\n    color: #000;\n    margin-left: 9%;\n    width: 100%;\n    }\n/*.boxxlr:hover {\n    border: 0px solid green;\n    background: green;\n    margin-right: 1%;\n    margin-bottom: 1%;\n     color: #fff;\n}*/\n\n.boxclrtitle {\nfont-size: 12px;font-weight: bold;\n}\n\n.boxclrtitle1 {\nfont-size: 18px;font-weight: bold;\n}\n/*.boxxlr .boxclrtitle:hover {\nfont-size: 12px;font-weight: bold;\n}*/\n\n\n  ",
                }}
              />
              <div className="row no-gutters">
                <div className="content col-xl-12 d-flex align-items-center">
                  <div className="content col-xl-12 d-flex align-items-center">
                    <div
                      className="row"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        background: "#fff",
                        margin: "auto",
                        padding: "5%",
                      }}
                    >
                      <p />
                      <h3 className="termsmainheading" style={{ marginLeft: "-2%",textAlign:"center",fontFamily:"Gotham",fontWeight:"bold" }}>
                      GigeconoMe Cookies Policy
                      </h3>
                     <span className="cookieheading">
                     This website uses cookies and similar technologies to collect data from your device. We need to collect this data for the website to operate, but we also use it to provide the best experience, understand how you use our website, provide social media features, personalise content, deliver service messages and deliver advert.

                     </span>
                      <span className="cookieheading" style={{padding:"1%",color:"rgb(0,147,69)",fontFamily:"GothamBold"}}>
                      I.	What are Cookies?
                      </span>
                     <span className="cookiedescription">Cookies are text files, containing small amounts of information, which are downloaded to your browsing device (such as a computer or smartphone) when you visit a website. Cookies can be recognized by the website that downloaded them — or other websites that use the same cookies. This helps websites know if the browsing device has visited them before.</span>
                     <span className="cookieheading" style={{padding:"1%",color:"rgb(0,147,69)",fontFamily:"GothamBold"}}>
                     II.	What are the Purposes of Cookies?
                      </span>
                     <span className="cookiedescription">
                     Cookies do lots of different jobs, like helping us understand how the Site is being used, letting you navigate between pages efficiently, remembering your preferences, and generally improving your browsing experience. Cookies can also help ensure marketing you see online is more relevant to you and your interests.</span>
                     <span className="cookieheading" style={{padding:"1%",color:"rgb(0,147,69)",fontFamily:"GothamBold"}}>
                     III.	What types of Cookies Does GigeconoMe Uses?
                      </span>
                     <span className="cookiedescription">
                     The type of cookies used on most websites can generally be put into one of six categories: Strictly Necessary, Performance, Functionality, Flash, Tailored Content and Targeting. To provide our users with the best browsing experience, GigeconoMe uses all of these categories on our platform. Below are the various categories, we use and for cookies not necessary for the performance of the Site and Site Services, our collection is subject to your consent as provided through the acceptance of our Privacy Policy and cookie consent opt-in available on the Site.</span>
                     <span className="cookieheading" style={{padding:"1%",color:"rgb(0,147,69)",fontFamily:"GothamBold"}}>
                     IV.	Strictly Necessary Cookies
                      </span>
                     <span className="cookiedescription">
                     These cookies are essential, as they enable you to move around the platform and use its features, such as accessing secure areas. Without these cookies, some services you have asked for such as payment submission can’t be provided.</span>
                     <span className="cookieheading" style={{padding:"1%",color:"rgb(0,147,69)",fontFamily:"GothamBold"}}>
                     V.	Performance Cookies
                      </span>
                     <span className="cookiedescription">
                     These cookies collect information about how you use the platform, for example which pages you go to most often and if you get error messages from certain pages. These cookies don’t gather information that identifies you. All information these cookies collect is anonymous and is only used to improve how the Site works.</span>
                     <span className="cookieheading" style={{padding:"1%",color:"rgb(0,147,69)",fontFamily:"GothamBold"}}>
                     VI.	Functionality Cookies
                      </span>
                     <span className="cookiedescription">
                     These cookies allow the platform to remember choices you make. For instance, the Site uses functionality cookies to remember your language preference. These cookies can also be used to remember changes you’ve made to customise various part of our platform. The information these cookies collect may be anonymous and they cannot track your browsing activity on other websites.</span>
                     <span className="cookieheading" style={{padding:"1%",color:"rgb(0,147,69)",fontFamily:"GothamBold"}}>
                     VII.	Tailored Content Cookies
                      </span>
                     <span className="cookiedescription">
                     Tailored content cookies help the platform provide enhanced features and display content in a way that is relevant to you. These cookies help the platform determine what information to show you based on how you have used the platform previously. These cookies do not track your browsing activity on other websites.</span>
                     <span className="cookieheading" style={{padding:"1%",color:"rgb(0,147,69)",fontFamily:"GothamBold"}}>
                     VIII.	Targeting Cookies
                      </span>
                     <span className="cookiedescription">
                     These cookies are used to deliver advertisements that are more relevant to you and your interests. They are also used to limit the number of times you see an advertisement as well as help measure the effectiveness of an advertising campaign. They remember that you have visited a website and this information may be shared with other organizations such as advertisers. </span>
                     <span className="cookieheading" style={{padding:"1%",color:"rgb(0,147,69)",fontFamily:"GothamBold"}}>
                     IX.	Email Cookies
                      </span>
                     <span className="cookiedescription">
                     In addition to the cookies, we use on this website, we also use cookies and similar technologies in some emails and push notifications. These help us to understand whether you have opened the email and how you have interacted with it. If you have enabled images, cookies may be set on your computer or mobile device. Cookies will also be set if you click on any link within the email. </span>
                     <span className="cookieheading" style={{padding:"1%",color:"rgb(0,147,69)",fontFamily:"GothamBold"}}>
                     X.	First and Third-Party Cookies
                      </span>
                     <span className="cookiedescription">
                     First party cookies are cookies that belong to us, while third party cookies are cookies that another party places on your browsing device through our Site.  </span>
                     <span className="cookieheading" style={{padding:"1%",color:"rgb(0,147,69)",fontFamily:"GothamBold"}}>
                     XI.	How Long will Cookies Stay on Browsing Device?
                      </span>
                     <span className="cookiedescription">
                     The length of time a cookie will stay on your browsing device depends on whether it is a “persistent” or “session” cookie. Session cookies will only stay on your device until you stop browsing. Persistent cookies stay on your browsing device until they expire or are deleted. </span>
                     <span className="cookieheading" style={{padding:"1%",color:"rgb(0,147,69)",fontFamily:"GothamBold"}}>
                     XII.	How to Control cookies?
                      </span>
                     <span className="cookiedescription">
                     A number of websites provide detailed information on cookies, including AboutCookies.org and AllAboutCookies.org.
                     <p/>
                     The European Interactive Digital Advertising Alliance website Your Online Choices allows you to install opt-out cookies across different advertising networks. Google has developed a browser add-on to allow users to opt-out of Google Analytics across all websites which use it. This is also available in the Chrome web store.
                     <p/>
                     Some browsers include a feature known as ‘Do Not Track’ or DNT. This allows you to indicate a preference that websites should not track you. However, whilst DNT is available in many browsers, websites are not required to recognise its request, so it may not always work. You can get help on how to use DNT in Microsoft Edge, Microsoft Internet Explorer, Mozilla Firefox, Google Chrome and Opera.
                     
                     </span>
                     <span className="cookieheading" style={{padding:"1%",color:"rgb(0,147,69)",fontFamily:"GothamBold"}}>
                     XIII.	Contacting Us
                      </span>
                     <span className="cookiedescription">
                          If you have any questions about the cookies or similar technologies in use on our website, please send an email to admin@gigeconome.com
                       </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        {/* End #main */}
        {/* ======= Footer ======= */}
        <Footer />
      </div>
    );
  }
}
export default Cookiepolicy;
