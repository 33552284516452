import React, { Component } from "react";
import "./style.css";
import Logo from '../../images/logo.png'
import { Link } from "react-router-dom";
import About_usfour from '../../images/AboutUs/aboutus-04.png';
import Sbanner from '../../images/studenthowitworkbanner.jpg';
import StudentHow from '../../images/studenthowitworks.png';
import How2work from '../../images/how2work.png';
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Aboutus from "../../images/aboutus.png";
import Student11 from "../../images/student11.png";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Accordion from "./Accordion";
const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth' // for smoothly scrolling
  });
  sessionStorage.removeItem("SearchText");
  sessionStorage.setItem("SearchText","");
};
class StudentHowitswork extends Component {
  render() {
    return (
      <div>
        {/* ======= Header ======= */}
    <Header />
      {/* End Header */}
        {/* ======= Hero Section ======= */}
        <section
          className="d-flex align-items-center"
          style={{ clear: "both" }}
        >
          <style
            type="text/css"
            dangerouslySetInnerHTML={{
              __html:
                "\n  .centered {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n}\n",
            }}
          />
         
         
        </section>
        {/* End Hero */}
        <main id="main" className="cpyhowitsworkbnr" style={{ backgroundImage:`url('${Sbanner}')`,
      backgroundRepeat:"no-repeat",backgroundSize:"contain",marginTop:"-4%",
      marginLeft:"-1%"}}>
          <div id="clients" className="clients">
            <style
              type="text/css"
              dangerouslySetInnerHTML={{
                __html:
                  "\n  .cnterbtn{\n    display: flex;\n  justify-content: center;\n  align-items: center;\n  padding-top: 4%;\n  }\n\n",
              }}
            />
            <div className="container" data-aos="zoom-in">
            <div class="row text-center">
              <h1 className="howitworksheading">Students : Explore How To Use GigeconoMe </h1>
            </div>
              <h2
                style={{
                  fontSize: "54px",
                  letterSpacing: "6px",
                  textAlign: "center",
                  color: "#000",
                  marginTop:"14%"
                }}
               
              >
                <div  id="how" style={{fontFamily:"Gotham"}}>
                {" "}
                HOW IT WORKS{" "}
                </div>
              </h2>
              <br />
              <div className="companyowitsworkimgmobile">
                <img src={Student11} style={{padding:"4%",marginTop:"-8%"}} />
              </div>
              <div className="companyowitsworkimg">
              <img
                style={{ marginLeft: "7%", width: "86%", marginTop: "3%" }}
                src="assets/img/how2work.png"
                alt=""
                className="img-fluid"
              />
               <br />
              <img
                style={{ marginLeft: "7%", width: "86%", marginTop: "3%" }}
                src={How2work}
                alt=""
                className="img-fluid"
              />
            
             
            <div className="row">
                <div className="col-md-3">
                  <h3 id="taketext">
                  Career Readiness Test
                  </h3>{" "}
                  <p
                    style={{
                    
                      fontSize: "15px",
                     
                    }}
                  >
                     Complete our assessment to understand strengths and weakness in key areas needed to join future workforce.
                  </p>
                </div>
                <div className="col-md-3">
                  <h3 id="taketext">
                  Complete Profile
                  </h3>{" "}
                  <p
                    style={{
                      marginLeft:"19%",
                      fontSize: "15px",

                   
                    }}
                  >
                    Sign Up and complete profile & additional details including studies, job opportunities & interests.
                  </p>
                </div>
                <div className="col-md-3">
                  <h3 id="taketext" >
                  Get Matched
                  </h3>{" "}
                  <p
                    style={{
                      marginLeft:"12%",
                      fontSize: "14.9px",
                      
                    }}
                  >
                    Start receiving opportunities according to preferences and gaps from employers across various sectors.
                  </p>
                </div>
                <div className="col-md-3">
                  <h3 id="taketext">
                  Getting Job Readiness
                  </h3>{" "}
                  <p
                    style={{
                    
                      fontSize: "14.9px",
                      marginLeft:"15%"

                    }}
                  >
                   Complete opportunities, earn and start developing workplace skills and building your portfolio.


                  </p>
                </div>
              </div>
              </div>
         
             
              <div className="cnterbtn" style={{paddingBottom:""}}>
               
              <Link to="/signup"      onClick={()=>scrollToTop()}
                          className="btn btn-success"
                          style={{
                            background: "rgb(0,147,69)",
                            border: "none",
                            borderRadius: "10px",
                           
                            letterSpacing: "1px",
                            fontFamily: "Gotham",
                           
                            color:"white"
                          }}>
                          Create An Account
                              </Link>
                 
             
              </div>
              <hr style={{ border: "1px solid #009345", opacity: "inherit" }} />
            </div>
          </div>

           {/* ======= About Section ======= */}
      <section id="about" className="about section-bg">
        <div className="container" data-aos="fade-up">
          <div className="row">
            <div className="col-lg-4">
              <span className="about-head">GigeconoMe - </span>
            </div>
            <div className="col-lg-8">
              <span className="about-content">
                Clearing Pathways to good first jobs by connecting school,
                students and employers.
              </span>
            </div>
          </div>
          <div className="row no-gutters">
        
            <div className="content col-xl-6 d-flex align-items-stretch">
              <div className="content">
                <img className="homegiglogo" src={Aboutus} />
              </div>
            </div>
            <div className="testinomialscreen">
            <Carousel
        showArrows={true}
        infiniteLoop={true}
        showThumbs={false}
        showStatus={false}
        autoPlay={true}
        interval={6100}
      >
        <div>
          
          <div className="myCarousel">
          <h4 style={{color:"rgb(0,147,69)",fontSize:16,fontFamily:"Gotham"}}>Debiased Selection Process</h4>
            <p style={{color:"black"}}>Our non-conventional selection process fosters diversity &amp; inclusion for all our students and ensures that all decisions are based purely on skills.</p>
          </div>
        </div>

        <div>
         
          <div className="myCarousel">
          <h4 style={{color:"rgb(0,147,69)",fontSize:16,fontFamily:"Gotham"}}>Next Generation Talent pool</h4>
            <p style={{color:"black"}}>Pool of diverse talent ready to complete one-off tasks, internships, gigs, short &amp; long-term projects, part time or long-term opportunities or address skills shortages.</p>
          </div>
        </div>

        <div>
          
          <div className="myCarousel">
          <h4 style={{color:"rgb(0,147,69)",fontSize:16,fontFamily:"Gotham"}}>Smart Matching Technology</h4>
            <p style={{color:"black"}}>Intelligent matching and searching functionality to unlock talents &amp; ensure that you match, screen and contact right students in minutes to build lasting relationships.</p>
          </div>
          </div>
         <div>
         <div className="myCarousel">
          <h4 style={{color:"rgb(0,147,69)",fontSize:16,fontFamily:"Gotham"}}>Fully Verified Talents</h4>
            <p style={{color:"black"}}>Thorough vetting process ensures all students have been fully screened to maintain integrity and signed code of conduct ensures that all our students can be fully trusted.</p>
          </div>
         </div>
          <div>
          <div className="myCarousel">
          <h4 id='clearingthepath'>Clearing pathways to good first jobs </h4>
            <p style={{color:"black"}}>Aligning study &amp; work by providing students ability to gain real life work experience, build portfolios and develop employability skills for linear paths to good first jobs.</p>
          </div>
          </div>
          <div>
          <div className="myCarousel">
          <h4 style={{color:"rgb(0,147,69)",fontSize:16,fontFamily:"Gotham"}}>One-Stop Platform</h4>
            <p style={{color:"black"}}>Easy to use & secure suite of cutting-edge tools in one platform to manage each step of the journey from reviewing profiles, shortlisting, contracts, payments & review.</p>
          </div>
        </div>
      </Carousel>
     
     
            </div>
            <div className="col-xl-6 d-flex align-items-stretch uspcontentmscreen">
            <div className="icon-boxes d-flex flex-column justify-content-center">
        <div className="row">
          <div className="col-md-6 icon-box" data-aos data-aos-delay={100}>
            <h4 style={{color:"rgb(0,147,69)",fontSize:16,fontFamily:"Gotham"}}>Debiased Selection Process</h4>
            <p style={{color:"black"}}>Our non-conventional selection process fosters diversity &amp; inclusion for all our students and ensures that all decisions are based purely on skills.</p>
          </div>
          <div className="col-md-6 icon-box" data-aos data-aos-delay={200}>
            <h4 style={{color:"rgb(0,147,69)",fontSize:16,fontFamily:"Gotham"}}>Next Generation Talent pool</h4>
            <p style={{color:"black"}}>Pool of diverse talent ready to complete one-off tasks, internships, gigs, short &amp; long-term projects, part time or long-term opportunities or address skills shortages.</p>
          </div>
          <div className="col-md-6 icon-box" data-aos data-aos-delay={300}>
            <h4 style={{color:"rgb(0,147,69)",fontSize:16,fontFamily:"Gotham"}}>Smart Matching Technology</h4>
            <p style={{color:"black"}}>Intelligent matching and searching functionality to unlock talents &amp; ensure that you match, screen and contact right students in minutes to build lasting relationships.</p>
          </div>
          <div className="col-md-6 icon-box" data-aos data-aos-delay={400}>
            <h4 style={{color:"rgb(0,147,69)",fontSize:16,fontFamily:"Gotham"}}>Fully Verified Talents</h4>
            <p style={{color:"black"}}>Thorough vetting process ensures all students have been fully screened to maintain integrity and signed code of conduct ensures that all our students can be fully trusted.</p>
          </div>
          <div className="col-md-6 icon-box" data-aos data-aos-delay={300}>
            <h4 id='clearingthepath'>Clearing pathways to good first jobs </h4>
            <p style={{color:"black"}}>Aligning study &amp; work by providing students ability to gain real life work experience, build portfolios and develop employability skills for linear paths to good first jobs.</p>
          </div>
          <div className="col-md-6 icon-box" data-aos data-aos-delay={400}>
            <h4 style={{color:"rgb(0,147,69)",fontSize:16,fontFamily:"Gotham"}}>One-Stop Platform</h4>
            <p style={{color:"black"}}>Easy to use & secure suite of cutting-edge tools in one platform to manage each step of the journey from reviewing profiles, shortlisting, contracts, payments & review.</p>
          </div>
        </div>
      </div>
              {/* End .content*/}
            </div>
          </div>
        </div>
        <div className="cnterbtn">
                
        <Link to="/signup"      onClick={()=>scrollToTop()}
                          className="btn btn-success"
                          style={{
                            background: "rgb(0,147,69)",
                            border: "none",
                            borderRadius: "10px",
                           
                            letterSpacing: "1px",
                            fontFamily: "Gotham",
                           
                            color:"white"
                          }}>
                          Start Earning
                              </Link>
               
           
            </div>
      </section>
      
      {/* End About Section */}
          {/* ======= Clients Section ======= */}
          <div id="clients" className="clients">
            <div className="container" data-aos="zoom-in">
             
            
             
              <hr style={{ border: "1px solid #009345", opacity: "inherit" }} />
            </div>
          </div>
          {/* End Clients Section */}
          {/* ======= About Section ======= */}
          <div id="clients" className="clients">
            <div className="container" data-aos="fade-up">
              <style
                type="text/css"
                dangerouslySetInnerHTML={{
                  __html:
                    "\n    .boxxlr{\n      border: 0px solid green;\n      background: #dcdedd;\n      margin-right: 4%;\n    margin-bottom: 4%;\n    color: #000;\n    }\n.boxxlr:hover {\n    border: 0px solid green;\n    background: green;\n    margin-right: 4%;\n    margin-bottom: 4%;\n     color: #fff;\n}\n\n.boxclrtitle {\nfont-size: 12px;font-weight: bold;\n}\n.boxxlr .boxclrtitle:hover {\nfont-size: 12px;font-weight: bold;\n}\n\n\n\n/*.boxalgn  { color: green;float: right; }\n.boxalgn1  { color: #fff;float: right; }\n*/\n/*.boxalgn:hover  { color: #fff;float: right; }*/\n  ",
                }}
              />
              <div className="row no-gutters">
                <div className="content col-xl-12 d-flex align-items-stretch">
                  <div className="content"  style={{
                       
                        display:"none"
                      }}>
                    <h2
                     
                    >
                      {" "}
                      EXPLORE HOW TO USE GIGECONOME{" "}
                    </h2>
                    <h4
                      style={{
                        fontSize: "16px",
                        letterSpacing: "2px",
                        textAlign: "center",
                        color: "#000",
                        fontWeight: 600,
                      }}
                    >
                      {" "}
                      To Gain Experience, Build Network and still Get Paid{" "}
                    </h4>
                    <br />
                   
                    <div
                      className="row"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div className="col-md-2 boxxlr">
                        <div
                          className="icon-box"
                          data-aos="fade-up"
                          data-aos-delay={100}
                          style={{ padding: "6%" }}
                        >
                          <i className="fa fa-briefcase fa-3x" />
                          <h4 className="boxclrtitle" id="work_text">Internship</h4>
                          <p
                            className="boxclrtitle"
                            style={{ fontWeight: "normal" }}
                          >
                            Voluptatum deleniti atque corrupti quos dolores et
                            quas molestias{" "}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-2 boxxlr" id="vir">
                        <div
                          className="icon-box"
                          data-aos="fade-up"
                          data-aos-delay={100}
                          style={{ padding: "6%" }}
                        >
                          <i className="fa fa-laptop fa-3x" />
                          <h4 className="boxclrtitle" id="work_text">Virtual Internship</h4>
                          <p
                            className="boxclrtitle"
                            style={{ fontWeight: "normal" }}
                          >
                            Voluptatum deleniti atque corrupti quos dolores et
                            quas molestias{" "}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-2 boxxlr">
                        <div
                          className="icon-box"
                          data-aos="fade-up"
                          data-aos-delay={100}
                          style={{ padding: "6%" }} id="vir"
                        >
                          <i
                            style={{ fontSize: "40px", marginBottom: "4px" }}
                            className="fa fa-id-card-o fa-3x"
                          />
                          <h4 className="boxclrtitle" id="work_text" >Work Experience</h4>
                          <p
                            className="boxclrtitle"
                            style={{ fontWeight: "normal" }}
                          >
                            Voluptatum deleniti atque corrupti quos dolores et
                            quas molestias{" "}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-2 boxxlr">
                        <div
                          className="icon-box"
                          data-aos="fade-up"
                          data-aos-delay={100}
                          style={{ padding: "6%" }}
                        >
                          <i className="fa fa-american-sign-language-interpreting fa-3x" />
                          <h4 className="boxclrtitle" id="work_text">Work Placement</h4>
                          <p
                            className="boxclrtitle"
                            style={{ fontWeight: "normal" }}
                          >
                            Voluptatum deleniti atque corrupti quos dolores et
                            quas molestias{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className="row"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div className="col-md-2 boxxlr">
                        <div
                          className="icon-box"
                          data-aos="fade-up"
                          data-aos-delay={100}
                          style={{ padding: "6%" }}
                        >
                          <i className="fa fa-clock-o fa-3x" />
                          <h4 className="boxclrtitle" id="work_text">Part Time Roles</h4>
                          <p
                            className="boxclrtitle"
                            style={{ fontWeight: "normal" }}
                          >
                            Voluptatum deleniti atque corrupti quos dolores et
                            quas molestias{" "}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-2 boxxlr" id="side">
                        <div
                          className="icon-box"
                          data-aos="fade-up"
                          data-aos-delay={100}
                          style={{ padding: "6%" }}
                        >
                          <i className="fa fa-envelope-open fa-3x" />
                          <h4  className="boxclrtitle" id="work_text" >Side Gig Opportunities</h4>
                          <p
                            className="boxclrtitle"
                            style={{ fontWeight: "normal" }}
                          >
                            Voluptatum deleniti atque corrupti quos dolores et
                            quas molestias{" "}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-2 boxxlr" id="side">
                        <div
                          className="icon-box"
                          data-aos="fade-up"
                          data-aos-delay={100}
                          style={{ padding: "6%" }}
                        >
                          <i className="fa fa-cog fa-3x" />
                          <h4 className="boxclrtitle" id="work_text">
                            Course Work Related Projects
                          </h4>
                          <p
                            className="boxclrtitle"
                            style={{ fontWeight: "normal" }}
                          >
                            Voluptatum deleniti atque corrupti quos dolores et
                            quas molestias{" "}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-2 boxxlr" id="vir">
                        <div
                          className="icon-box"
                          data-aos="fade-up"
                          data-aos-delay={100}
                          style={{ padding: "6%" }}
                        >
                          <i className="fa fa-folder-open fa-3x" />
                          <h4 className="boxclrtitle" id="work_text">Portfolio Building</h4>
                          <p
                            className="boxclrtitle"
                            style={{ fontWeight: "normal" }}
                          >
                            Voluptatum deleniti atque corrupti quos dolores et
                            quas molestias{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            
            </div>
          </div>
        </main>
        {/* End #main */}
        {/* ======= Footer ======= */}
       
      <div className="container">
        <h2 className="section-heading studentpagetxt" >Gain Experience. Build Your Network. Get Paid.</h2>
        <div className="row">
        <div className="maccordion">
                   <Accordion />
                    </div>
          <div className="col-md-3 bigaccord">
            <div
              className="icon-box aos-init aos-animate downsec"
              data-aos="fade-up"
              data-aos-delay={100}
              style={{
                padding: "6%",
                marginBottom: "35px",
                paddingBottom: "42px"
              }}
              id="box"
            >
              <i className="fa fa-briefcase fa-5x" id="fa"/>
              <h4 className="boxclrtitle" id="text" style={{marginLeft:"-1%"}}>Internship</h4>
              <p className="boxclrtitle" id="text" style={{ fontWeight: "normal",fontSize:15 }}>
                In an ever-competitive marketplace, secure the best internship
                opportunities and gain real industry and valuable hands-on
                experienc eacross all sectors and geographical locations.
              </p>
              {/*  <p  class="palgnbx" style="color: green"><i class="fa fa-long-arrow-right" aria-hidden="true"></i></p> */}
            </div>
          </div>
          <div className="col-md-3 bigaccord">
            <div
              className="icon-box aos-init aos-animate downsec" 
              data-aos="fade-up"
              data-aos-delay={100}
              style={{
                padding: "6%",
                marginBottom: "35px",
                paddingBottom: "39px"
              }}
              id="box"
            >
              <i
                style={{ marginBottom: "4px" }}
                className="fa fa-id-card-o fa-5x" id="fa"
              />
              <h4 className="boxclrtitle" id="text" >Work Experience</h4>
              <p className="boxclrtitle" id="text" style={{ fontWeight: "normal",fontSize:15  }}>
                <div id="work">
                Access to both newly formed Startups and Large enterprises,
                provides the perfect opportunity to acquire new skills and build
                experience either through voluntary or paid positions. <span style={{visibility:"hidden"}}>kkkk</span>
                </div>
              </p>
            </div>
          </div>
          <div className="col-md-3 bigaccord">
            <div
              className="icon-box aos-init aos-animate downsec" 
              data-aos="fade-up"
              data-aos-delay={100}
              style={{ padding: "6%", height:"90%" }}
              id="box"
            >
              <i className="fa fa-american-sign-language-interpreting fa-5x" id="fa" />
              <h4 className="boxclrtitle" id="work" id="text">Work Placement</h4>
              <p className="boxclrtitle" id="text" style={{ fontWeight: "normal",fontSize:15  }}>
                <div id="work_one">
                Gain significant on-the-job experience as well as apply your
                knowledge and skills in a work setting of your chosen line of
                study or industry and increase competencies sought after by
                employers.
                </div>
              </p>
            </div>
          </div>
          <div className="col-md-3 bigaccord">
            <div
              className="icon-box aos-init aos-animate downsec"
              data-aos="fade-up"
              data-aos-delay={100}
              style={{
                padding: "6%",
                marginBottom: "35px",
                paddingBottom: "38px"
              }}
              id="box"
            >
              <i
                style={{ marginBottom: "4px" }}
                className="fa fa-envelope-open fa-5x" id="fa"
              />
              <h4 className="boxclrtitle" id="text">Gig Opportunities</h4>
              <p className="boxclrtitle" id="text" style={{ fontWeight: "normal",fontSize:15  }}>
                Wide range of flexible Gig opportunities to make the most of
                spare times, earn money and gain valuable real-life experiences
                by securing freelance works or short-term day-rate contracts.
              </p>
            </div>
          </div>
        </div>
        <div className="row" style={{ display: "flex" }}>
          <div className="col-md-3 bigaccord">
            <div
              className="icon-box aos-init aos-animate downsec" id="fa"
              data-aos="fade-up"
              data-aos-delay={100}
              style={{ padding: "6%", marginBottom: "35px", }}
              id="box"
            >
              <i className="fa fa-clock-o fa-5x" id="fa" />
              <h4 className="boxclrtitle" id="text">Part Time Roles</h4>
              <p className="boxclrtitle" id="text" style={{ fontWeight: "normal",fontSize:15  }}>
                GigeconoMe provides you the opportunity to not only gain
                financial protection during your studies but also gain a variety
                of employability find part-time opportunities directly relevant
                to definite career plans.
              </p>
            </div>
          </div>
          <div className="col-md-3 bigaccord">
            <div
              className="icon-box aos-init aos-animate downsec" id="fa"
              data-aos="fade-up"
              data-aos-delay={100}
              style={{ padding: "6%", marginBottom: "35px" }}
              id="box"
            >
              <i className="fa fa-cog fa-5x"id="fa" />
              <h4 className="boxclrtitle" id="text">Course Work Related Projects</h4>
              <p className="boxclrtitle" id="text" style={{ fontWeight: "normal",fontSize:15  }}>
                <div id="course">
                Interested in inspiring real-life projects than those in current
                curriculum? Use GigeconoMe to find specific work-related
                activities and develop an understanding of work and develop
                skills employers want.
                </div>
              </p>
            </div>
          </div>
          <div className="col-md-3 bigaccord">
            <div
              className="icon-box aos-init aos-animate downsec" id="fa"
              data-aos="fade-up"
              data-aos-delay={100}
              style={{ padding: "6%", height:"89%"}}
              id="box"
            >
              <i className="fa fa-folder-open fa-5x" id="fa"/>
              <h4 className="boxclrtitle" id="text" >Portfolio Building</h4>
              <p className="boxclrtitle" id="text" style={{ fontWeight: "normal",padding:"4%" ,marginLeft:"-4%",fontSize:15,marginTop:"-4%" }}>
              Impress future employers and build an impressive portfolio of work while studying to showcase learning growth as well as demonstrate your creativity, skills and ability.

              </p>
            </div>
          </div>
          <div className="col-md-3 bigaccord">
            <div
              className="icon-box aos-init aos-animate downsec" id="fa"
              data-aos="fade-up"
              data-aos-delay={100}
              style={{ padding: "6%", }}
              id="box"
            >
              <i className="fa fa-lightbulb-o fa-5x" id="fa"/>
              <h4 className="boxclrtitle" id="text">Graduate Opportunities</h4>
              <p className="boxclrtitle" id="text" style={{ fontWeight: "normal",fontSize:15  }}>
              In your final year of study and ready for the job market? Use GigeconoMe to navigate the first role of your career ladder and explore the best available graduate jobs across the widest range of sectors and employers.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="cnterbtn" style={{paddingBottom:"4%"}}>
      {JSON.parse(sessionStorage.getItem("UserType"))=="Student"?<>
      <Link
                onClick={()=>scrollToTop()}
                className="btn btn-success"
                to="/emptestdescription"
                style={{
                  background: "rgb(0,147,69)",
                  border: "none",
                  borderRadius: "10px",
                 
                  letterSpacing: "1px",
                  fontFamily: "Gotham",
                 
                  color:"white"
                }}> Career Readiness Test</Link>
               
      </>:<>
      {JSON.parse(sessionStorage.getItem("UserType"))=="Company"||JSON.parse(sessionStorage.getItem("UserType"))=="Employee"?<>
      
      </>:<>  <Link
                        className="btn btn-success"
                       
                        style={{
                          background: "rgb(0,147,69)",
                          border: "none",
                          borderRadius: "10px",
                         
                          letterSpacing: "1px",
                          fontFamily: "Gotham",
                         
                          color:"white"
                        }}
                        
                        
                        to="/emplyoabilityone"
                        onClick={()=>scrollToTop()}
                      >
                        Career Readiness Test
                      </Link></>}
      </>}
               
        
            </div>
            <div className="container" style={{display:"none"}}>
        <h2 className="section-heading">Gain Experience. Build Your Network. Get Paid.</h2>
        <div className="row">
          <div className="col-md-3">
            <div
              className="icon-box aos-init aos-animate downsec"
              data-aos="fade-up"
              data-aos-delay={100}
              style={{
                padding: "6%",
                marginBottom: "35px",
                paddingBottom: "42px"
              }}
              id="box"
            >
              <i className="fa fa-briefcase fa-5x" id="fa"/>
              <h4 className="boxclrtitle" id="text">Internship</h4>
              <p className="boxclrtitle" id="text" style={{ fontWeight: "normal",fontSize:15, }}>
                In an ever-competitive marketplace, secure the best internship
                opportunities and gain real industry and valuable hands-on
                experienceacross all sectors and geographical locations.
              </p>
              {/*  <p  class="palgnbx" style="color: green"><i class="fa fa-long-arrow-right" aria-hidden="true"></i></p> */}
            </div>
          </div>
          <div className="col-md-3">
            <div
              className="icon-box aos-init aos-animate downsec" 
              data-aos="fade-up"
              data-aos-delay={100}
              style={{
                padding: "6%",
                marginBottom: "35px",
                paddingBottom: "39px"
              }}
              id="box"
            >
              <i
                style={{ marginBottom: "4px" }}
                className="fa fa-id-card-o fa-5x" id="fa"
              />
              <h4 className="boxclrtitle" id="text">Work Experience</h4>
              <p className="boxclrtitle" id="text" style={{ fontWeight: "normal",fontSize:15,  }}>
                <div id="work">
                Access to bothnewly formed Startupsand Large enterprises,
                provides the perfect opportunity to acquire new skills and build
                experience eitherthroughvoluntary orpaid positions.
                </div>
              </p>
            </div>
          </div>
          <div className="col-md-3">
            <div
              className="icon-box aos-init aos-animate downsec" 
              data-aos="fade-up"
              data-aos-delay={100}
              style={{ padding: "6%", height:"90%" }}
              id="box"
            >
              <i className="fa fa-american-sign-language-interpreting fa-5x" id="fa" />
              <h4 className="boxclrtitle" id="work" id="text">Work Placement</h4>
              <p className="boxclrtitle" id="text" style={{ fontWeight: "normal",fontSize:15  }}>
                <div id="work_one">
                Gain significant on-the-job experience as well as apply your
                knowledge and skills in a work setting of your chosen line of
                study or industry and increase competencies sought after by
                employers.
                </div>
              </p>
            </div>
          </div>
          <div className="col-md-3">
            <div
              className="icon-box aos-init aos-animate downsec"
              data-aos="fade-up"
              data-aos-delay={100}
              style={{
                padding: "6%",
                marginBottom: "35px",
                paddingBottom: "38px"
              }}
              id="box"
            >
              <i
                style={{ marginBottom: "4px" }}
                className="fa fa-envelope-open fa-5x" id="fa"
              />
              <h4 className="boxclrtitle" id="text">Gig Opportunities</h4>
              <p className="boxclrtitle" id="text" style={{ fontWeight: "normal",fontSize:15  }}>
                Wide range of flexible Gig opportunities to make the most of
                spare times, earn money and gain valuable real-life experiences
                by securing freelance works or short-term day-rate contracts.
              </p>
            </div>
          </div>
        </div>
        <div className="row" style={{ display: "flex" }}>
          <div className="col-md-3">
            <div
              className="icon-box aos-init aos-animate downsec" id="fa"
              data-aos="fade-up"
              data-aos-delay={100}
              style={{ padding: "6%", marginBottom: "35px", }}
              id="box"
            >
              <i className="fa fa-clock-o fa-5x" id="fa" />
              <h4 className="boxclrtitle" id="text">Part Time Roles</h4>
              <p className="boxclrtitle" id="text" style={{ fontWeight: "normal",fontSize:15  }}>
                GigeconoMe provides you the opportunity to not only gain
                financial protection during your studies but also gain a variety
                of employability find part-time opportunities directly relevant
                to definite career plans.
              </p>
            </div>
          </div>
          <div className="col-md-3">
            <div
              className="icon-box aos-init aos-animate downsec" id="fa"
              data-aos="fade-up"
              data-aos-delay={100}
              style={{ padding: "6%", marginBottom: "35px" }}
              id="box"
            >
              <i className="fa fa-cog fa-5x"id="fa" />
              <h4 className="boxclrtitle" id="text">Course Work Related Projects</h4>
              <p className="boxclrtitle" id="text" style={{ fontWeight: "normal",fontSize:15  }}>
                <div id="course">
                Interested in inspiring real-life projects than those in current
                curriculum? Use GigeconoMe to find specific work-related
                activities and develop an understanding of work and develop
                skills employers want.
                </div>
              </p>
            </div>
          </div>
          <div className="col-md-3">
            <div
              className="icon-box aos-init aos-animate downsec" id="fa"
              data-aos="fade-up"
              data-aos-delay={100}
              style={{ padding: "6%", height:"89%"}}
              id="box"
            >
              <i className="fa fa-folder-open fa-5x" id="fa"/>
              <h4 className="boxclrtitle" id="text" >Portfolio Building</h4>
              <p className="boxclrtitle" id="text" style={{ fontWeight: "normal",padding:"4%" ,marginLeft:"-4%",fontSize:15 }}>
              Impress future employers and build an impressive portfolio of work while studying to showcase learning growth as well as demonstrate your creativity, skills and ability.

              </p>
            </div>
          </div>
          <div className="col-md-3">
            <div
              className="icon-box aos-init aos-animate downsec" id="fa"
              data-aos="fade-up"
              data-aos-delay={100}
              style={{ padding: "6%", }}
              id="box"
            >
              <i className="fa fa-lightbulb-o fa-5x" id="fa"/>
              <h4 className="boxclrtitle" id="text">Graduate Opportunities</h4>
              <p className="boxclrtitle" id="text" style={{ fontWeight: "normal",fontSize:15  }}>
              In your final year of study and ready for the job market? Use GigeconoMe to navigate the first role of your career ladder and explore the best available graduate jobs across the widest range of sectors and employers.
              </p>
            </div>
          </div>
        </div>
      </div>
       <Footer />
      </div>
    );
  }
}
export default StudentHowitswork;
