import React from "react";
import "./company.css";
import { Link } from "react-router-dom";
import Logo from '../../images/logo.png';
import How2work from '../../images/how2work.png';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import LaptopIcon from '@mui/icons-material/Laptop';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import About_usfour from '../../images/AboutUs/aboutus-04.png';
import Sbanner from '../../images/studenthowitworkbanner.jpg';
import Aboutus from "../../images/aboutus.png";
import Educator11 from "../../images/educator11.png";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Accordion from "../Home/Accordion";
import Educatorfaq from "./Educatorfaq";
const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth' // for smoothly scrolling
  });
  sessionStorage.removeItem("SearchText");
  sessionStorage.setItem("SearchText","");
};
class Educators extends React.Component {
  render() {
    return (
      <div>
         {/* ======= Header ======= */}
     <Header />
      {/* End Header */}
        {/* ======= Hero Section ======= */}
        <section
          className="d-flex align-items-center"
          style={{ clear: "both" }}
        >
          <style
            type="text/css"
            dangerouslySetInnerHTML={{
              __html:
                "\n  .centered {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n}\n",
            }}
          />
       
        
        </section>
        {/* End Hero */}
        <main id="main" className="cpyhowitsworkbnr" style={{ backgroundImage:`url('${Sbanner}')`,
      backgroundRepeat:"no-repeat",backgroundSize:"contain",marginTop:"-4%",
      marginLeft:"-1%"}}>
          <section
            id="clients"
            className="clients"
            style={{ marginTop: "-1%" }}
          >
            <style
              type="text/css"
              dangerouslySetInnerHTML={{
                __html:
                  "\n  .cnterbtn{\n    display: flex;\n  justify-content: center;\n  align-items: center;\n  padding-top: 4%;\n  }\n\n  .btnalgnhtw{\n     display: flex;\n  justify-content: center;\n  align-items: center;\n  padding-top: 4%;\n  }\n\n\n",
              }}
            />
            <div className="container" data-aos="zoom-in">
            <div class="row text-center">
              <h1 className="cpyhowitsworktxt">Educators : Supporting your Students into Work</h1>
            </div>
              <h2
              className="cpyhowitsworksubtxt"
              >
                {" "}
                How it Works{" "}
              </h2>
              <br />
              <div className="companyowitsworkimgmobile">
                <img src={Educator11} />
              </div>
          <div className="companyowitsworkimg">
          <img
                style={{ marginLeft: "7%", width: "86%", marginTop: "3%" }}
                src={How2work}
                alt=""
                className="img-fluid"
              />
              <div className="row">
                <div className="col-md-3">
                  <h3 style={{ 
                  fontFamily: "normal",fontWeight:"bold",fontSize:20,color:"rgb(0,147,69)",marginLeft:"6%" }}>
                  Complete Basic Information
                  </h3>{" "}
                  <p
                    style={{
                      
                      fontSize: "14px",
                      marginLeft:"6%"
                      
                    }}
                  >
                   Complete basic information to tell us more about you and your educational organisation.
                  </p>
                </div>
                <div className="col-md-3">
                  <h3 id="taketext">
                  Receive call from GigeconoMe
                  </h3>{" "}
                  <p
                    style={{
                     
                      fontSize: "14px",
                      marginLeft:"5%"
                  
                    }}
                  >
                    Receive a call from our teams to discuss how we can help your students and programs.
                  </p>
                </div>
                <div className="col-md-3">
                  <h3 style={{ textAlign: "center",
                   fontFamily: "normal",fontWeight:"bold",
                   fontSize:20,color:"rgb(0,147,69)",marginLeft:"-5%" }}>
                  Personalised Demo Session
                  </h3>{" "}
                  <p
                    style={{
                     
                      fontSize: "14px",
                      marginLeft:"8%"
                    
                    }}
                  >
                    Take part in our demo session to understand how we connect school and employers.
                  </p>
                </div>
                <div className="col-md-3">
                  <h3 style={{ textAlign: "center", 
                  fontFamily: "normal",fontWeight:"bold",fontSize:20,
                  color:"rgb(0,147,69)",marginLeft:"-5%" }}>
                  Join as an  Educator
                  </h3>{" "}
                  <p
                    style={{
                     
                      fontSize: "14px",
                      marginLeft:"19%"
                   
                    }}
                  >
                   Join Gigeconome and start connecting with employers integrating academic programs into actual work.
                  </p>
                </div>
              </div>
          </div>
              <div className="row">
                <div className="col-md-3"></div>
                <div className="col-md-4 educatorpagebtnone">
                 
                    <Link
                    to="/companysearch"
                    
                     onClick={()=>scrollToTop()}
                     className="btn btn-success"
                     style={{
                       background: "rgb(0,147,69)",
                       border: "none",
                       borderRadius: "10px",
                      
                       letterSpacing: "1px",
                       fontFamily: "Gotham",
                      
                       color:"white"
                     }}
                    
                    > See Our Employers</Link>
                   
               
                </div>
                <div className="col-md-5 educatorpagebtnone">
                 
                    <Link to="/requestademo"
                    
                     onClick={()=>scrollToTop()}
                     className="btn btn-success"
                     style={{
                       background: "rgb(0,147,69)",
                       border: "none",
                       borderRadius: "10px",
                      
                       letterSpacing: "1px",
                       fontFamily: "Gotham",
                      
                       color:"white"
                     }}
                    
                    >
                       Request a Demo
                    </Link>
                   
                 
                </div>
              </div>
            </div>
          </section>
          
           {/* ======= About Section ======= */}
      <section id="about" className="about section-bg">

        <div className="container" data-aos="fade-up">
          <div className="row" style={{marginTop:"-3%"}}>
          <hr style={{ border: "1px solid #009345", opacity: "inherit" }} />
            <div className="col-lg-4">
              <span className="about-head">GigeconoMe - </span>
            </div>
            <div className="col-lg-8">
              <span className="about-content">
                Clearing Pathways to good first jobs by connecting school,
                students and employers.
              </span>
            </div>
          </div>
          <div className="row no-gutters">
            <div className="content col-xl-6 d-flex align-items-stretch">
              <div className="content">
                <img className="homegiglogo"  src={Aboutus} />
              </div>
            </div>
            <div className="testinomialscreen">
            <Carousel
        showArrows={true}
        infiniteLoop={true}
        showThumbs={false}
        showStatus={false}
        autoPlay={true}
        interval={6100}
      >
        <div>
          
          <div className="myCarousel">
          <h4 style={{color:"rgb(0,147,69)",fontSize:16,fontFamily:"Gotham"}}>Debiased Selection Process</h4>
            <p style={{color:"black"}}>Our non-conventional selection process fosters diversity &amp; inclusion for all our students and ensures that all decisions are based purely on skills.</p>
          </div>
        </div>

        <div>
         
          <div className="myCarousel">
          <h4 style={{color:"rgb(0,147,69)",fontSize:16,fontFamily:"Gotham"}}>Next Generation Talent pool</h4>
            <p style={{color:"black"}}>Pool of diverse talent ready to complete one-off tasks, internships, gigs, short &amp; long-term projects, part time or long-term opportunities or address skills shortages.</p>
          </div>
        </div>

        <div>
          
          <div className="myCarousel">
          <h4 style={{color:"rgb(0,147,69)",fontSize:16,fontFamily:"Gotham"}}>Smart Matching Technology</h4>
            <p style={{color:"black"}}>Intelligent matching and searching functionality to unlock talents &amp; ensure that you match, screen and contact right students in minutes to build lasting relationships.</p>
          </div>
          </div>
         <div>
         <div className="myCarousel">
          <h4 style={{color:"rgb(0,147,69)",fontSize:16,fontFamily:"Gotham"}}>Fully Verified Talents</h4>
            <p style={{color:"black"}}>Thorough vetting process ensures all students have been fully screened to maintain integrity and signed code of conduct ensures that all our students can be fully trusted.</p>
          </div>
         </div>
          <div>
          <div className="myCarousel">
          <h4 id='clearingthepath'>Clearing pathways to good first jobs </h4>
            <p style={{color:"black"}}>Aligning study &amp; work by providing students ability to gain real life work experience, build portfolios and develop employability skills for linear paths to good first jobs.</p>
          </div>
          </div>
          <div>
          <div className="myCarousel">
          <h4 style={{color:"rgb(0,147,69)",fontSize:16,fontFamily:"Gotham"}}>One-Stop Platform</h4>
            <p style={{color:"black"}}>Easy to use & secure suite of cutting-edge tools in one platform to manage each step of the journey from reviewing profiles, shortlisting, contracts, payments & review.</p>
          </div>
        </div>
      </Carousel>
     
     
            </div>
            <div className="col-xl-6 d-flex align-items-stretch uspcontentmscreen">
            <div className="icon-boxes d-flex flex-column justify-content-center">
        <div className="row">
          <div className="col-md-6 icon-box" data-aos data-aos-delay={100}>
            <h4 style={{color:"rgb(0,147,69)",fontSize:16,fontFamily:"Gotham"}}>Debiased Selection Process</h4>
            <p style={{color:"black"}}>Our non-conventional selection process fosters diversity &amp; inclusion for all our students and ensures that all decisions are based purely on skills.</p>
          </div>
          <div className="col-md-6 icon-box" data-aos data-aos-delay={200}>
            <h4 style={{color:"rgb(0,147,69)",fontSize:16,fontFamily:"Gotham"}}>Next Generation Talent pool</h4>
            <p style={{color:"black"}}>Pool of diverse talent ready to complete one-off tasks, internships, gigs, short &amp; long-term projects, part time or long-term opportunities or address skills shortages.</p>
          </div>
          <div className="col-md-6 icon-box" data-aos data-aos-delay={300}>
            <h4 style={{color:"rgb(0,147,69)",fontSize:16,fontFamily:"Gotham"}}>Smart Matching Technology</h4>
            <p style={{color:"black"}}>Intelligent matching and searching functionality to unlock talents &amp; ensure that you match, screen and contact right students in minutes to build lasting relationships.</p>
          </div>
          <div className="col-md-6 icon-box" data-aos data-aos-delay={400}>
            <h4 style={{color:"rgb(0,147,69)",fontSize:16,fontFamily:"Gotham"}}>Fully Verified Talents</h4>
            <p style={{color:"black"}}>Thorough vetting process ensures all students have been fully screened to maintain integrity and signed code of conduct ensures that all our students can be fully trusted.</p>
          </div>
          <div className="col-md-6 icon-box" data-aos data-aos-delay={300}>
            <h4 id='clearingthepath'>Clearing pathways to good first jobs </h4>
            <p style={{color:"black"}}>Aligning study &amp; work by providing students ability to gain real life work experience, build portfolios and develop employability skills for linear paths to good first jobs.</p>
          </div>
          <div className="col-md-6 icon-box" data-aos data-aos-delay={400}>
            <h4 style={{color:"rgb(0,147,69)",fontSize:16,fontFamily:"Gotham"}}>One-Stop Platform</h4>
            <p style={{color:"black"}}>Easy to use & secure suite of cutting-edge tools in one platform to manage each step of the journey from reviewing profiles, shortlisting, contracts, payments & review.</p>
          </div>
        </div>
      </div>
              {/* End .content*/}
            </div>
          </div>
        </div>
        <div className="cnterbtn joinourbtn">
                
                <Link to="/signup"
                onClick={()=>scrollToTop()}
                className="btn btn-success"
                style={{
                  background: "rgb(0,147,69)",
                  border: "none",
                  borderRadius: "10px",
                 
                  letterSpacing: "1px",
                  fontFamily: "Gotham",
                 
                  color:"white"
                }}
                id="start"
                >Join Our University Network</Link>
               
           
            </div>
      </section>
      
      {/* End About Section */}
          {/* ======= Clients Section ======= */}
          <section id="clients" className="clients" >
            <div className="container" data-aos="zoom-in">
              <hr style={{ border: "1px solid #009345", opacity: "inherit" }} />
             
             
            
            </div>
          </section>
          {/* End Clients Section */}
          {/* ======= About Section ======= */}
          <section id="clients" className="clients educatorboxm">
            <div className="container" data-aos="fade-up">
              <style
                type="text/css"
                dangerouslySetInnerHTML={{
                  __html:
                    "\n    .boxxlr{\n      border: 0px solid green;\n      /*background: #dcdedd;*/\n      margin-right: 3%;\n    margin-bottom: 3%;\n    color: #000;\n    }\n\n\n.boxclrtitle {\nfont-size: 12px;font-weight: bold;margin-left: 12%\n}\n.boxxlr .boxclrtitle:hover {\nfont-size: 12px;font-weight: bold;cursor: pointer;\n}\n\n\n  ",
                }}
              />
              <div className="row no-gutters">
                <div className="content col-xl-12 d-flex align-items-stretch">
                 
                </div>
              </div>
             
            </div>
            <div className="container" style={{marginTop:"-3%"}}>
        <h2 className="section-heading" id="iheading">Gain Experience. Build Your Network. Get Paid.</h2>
        <div className="row">
        <div className="maccordion">
       <Accordion/>
         </div>
          <div className="col-md-3 bigaccord">
            <div
              className="icon-box aos-init aos-animate downsec"
              data-aos="fade-up"
              data-aos-delay={100}
              style={{
                padding: "6%",
                marginBottom: "35px",
                paddingBottom: "42px"
              }}
              id="box"
            >
              <i className="fa fa-briefcase fa-5x" id="fa"/>
              <h4 className="boxclrtitle" id="text" style={{marginLeft:"0%"}}>Internship</h4>
              <p id="text" style={{ fontWeight: "normal",fontSize:15 }}>
                In an ever-competitive marketplace, secure the best internship
                opportunities and gain real industry and valuable hands-on
                experience across all sectors and geographical locations.
              </p>
              {/*  <p  class="palgnbx" style="color: green"><i class="fa fa-long-arrow-right" aria-hidden="true"></i></p> */}
            </div>
          </div>
          <div className="col-md-3 bigaccord">
            <div
              className="icon-box aos-init aos-animate downsec" 
              data-aos="fade-up"
              data-aos-delay={100}
              style={{
                padding: "6%",
                marginBottom: "35px",
                paddingBottom: "39px"
              }}
              id="box"
            >
              <i
                style={{ marginBottom: "4px" }}
                className="fa fa-id-card-o fa-5x" id="fa"
              />
              <h4 className="boxclrtitle" id="text" style={{marginLeft:"0%"}}>Work Experience</h4>
              <p  id="text" style={{ fontWeight: "normal",fontSize:15  }}>
                <div id="work">
                Access to bothnewly formed Startups and Large enterprises,
                provides the perfect opportunity to acquire new skills and build
                experience either through voluntary or paid positions. <span style={{visibility:"hidden"}} >kkk</span>
                </div>
              </p>
            </div>
          </div>
          <div className="col-md-3 bigaccord">
            <div
              className="icon-box aos-init aos-animate downsec" 
              data-aos="fade-up"
              data-aos-delay={100}
              style={{ padding: "6%", height:"90%" }}
              id="box"
            >
              <i className="fa fa-american-sign-language-interpreting fa-5x" id="fa" />
              <h4 className="boxclrtitle" id="work" id="text" style={{marginLeft:"0%"}}>Work Placement</h4>
              <p  id="text" style={{ fontWeight: "normal",fontSize:15  }}>
                <div id="work_one">
                Gain significant on-the-job experience as well as apply your
                knowledge and skills in a work setting of your chosen line of
                study or industry and increase competencies sought after by
                employers.
                </div>
              </p>
            </div>
          </div>
          <div className="col-md-3 bigaccord">
            <div
              className="icon-box aos-init aos-animate downsec"
              data-aos="fade-up"
              data-aos-delay={100}
              style={{
                padding: "6%",
                marginBottom: "35px",
                paddingBottom: "38px"
              }}
              id="box"
            >
              <i
                style={{ marginBottom: "4px" }}
                className="fa fa-envelope-open fa-5x" id="fa"
              />
              <h4 className="boxclrtitle" id="text" style={{marginLeft:"0%"}}>Gig Opportunities</h4>
              <p id="text" style={{ fontWeight: "normal",fontSize:15  }}>
                Wide range of flexible Gig opportunities to make the most of
                spare times, earn money and gain valuable real-life experiences
                by securing freelance works or short-term day-rate contracts.
              </p>
            </div>
          </div>
        </div>
        <div className="row" style={{ display: "flex" }}>
          <div className="col-md-3 bigaccord">
            <div
              className="icon-box aos-init aos-animate downsec" id="fa"
              data-aos="fade-up"
              data-aos-delay={100}
              style={{ padding: "6%", marginBottom: "35px", }}
              id="box"
            >
              <i className="fa fa-clock-o fa-5x" id="fa" />
              <h4 className="boxclrtitle" id="text" style={{marginLeft:"0%"}}>Part Time Roles</h4>
              <p id="text" style={{ fontWeight: "normal",fontSize:15  }}>
                GigeconoMe provides you the opportunity to not only gain
                financial protection during your studies but also gain a variety
                of employability find part-time opportunities directly relevant
                to definite career plans.
              </p>
            </div>
          </div>
          <div className="col-md-3 bigaccord">
            <div
              className="icon-box aos-init aos-animate downsec" id="fa"
              data-aos="fade-up"
              data-aos-delay={100}
              style={{ padding: "6%", marginBottom: "35px" }}
              id="box"
            >
              <i className="fa fa-cog fa-5x"id="fa" />
              <h4 className="boxclrtitle" id="text" style={{marginLeft:"0%"}}>Course Work Related Projects</h4>
              <p id="text" style={{ fontWeight: "normal",fontSize:15  }}>
                <div id="course">
                Interested in inspiring real-life projects than those in current
                curriculum? Use GigeconoMe to find specific work-related
                activities and develop an understanding of work and develop
                skills employers want.
                </div>
              </p>
            </div>
          </div>
          <div className="col-md-3 bigaccord">
            <div
              className="icon-box aos-init aos-animate downsec" id="fa"
              data-aos="fade-up"
              data-aos-delay={100}
              style={{ padding: "6%", height:"89%"}}
              id="box"
            >
              <i className="fa fa-folder-open fa-5x" id="fa"/>
              <h4 className="boxclrtitle" id="text" style={{marginLeft:"0%"}} >Portfolio Building</h4>
              <p  id="text" style={{ fontWeight: "normal",padding:"4%" ,marginLeft:"-4%",fontSize:15,marginTop:"-4%" }}>
              Impress future employers and build an impressive portfolio of work while studying to showcase learning growth as well as demonstrate your creativity, skills and ability.

              </p>
            </div>
          </div>
          <div className="col-md-3 bigaccord">
            <div
              className="icon-box aos-init aos-animate downsec" id="fa"
              data-aos="fade-up"
              data-aos-delay={100}
              style={{ padding: "6%", }}
              id="box"
            >
              <i className="fa fa-lightbulb-o fa-5x" id="fa"/>
              <h4 className="boxclrtitle" id="text" style={{marginLeft:"0%"}}>Graduate Opportunities</h4>
              <p  id="text" style={{ fontWeight: "normal",fontSize:15  }}>
              In your final year of study and ready for the job market? Use GigeconoMe to navigate the first role of your career ladder and explore the best available graduate jobs across the widest range of sectors and employers.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="cnterbtn">
              
              <a href="https://calendly.com/gigeconome"
              target="_blank"
              className="btn btn-success"
              onClick={()=>scrollToTop()}
              style={{
                background: "#009345",
                border: "none",
                borderRadius: "10px",
               
                letterSpacing: "1px",
                color:"white",
                
              }}>
      Schedule a Demo
              </a>
            
           
          </div>
          <div className="container">
          <div className="row no-gutters">
          <hr style={{ border: "1px solid #009345", opacity: "inherit",marginTop:"5%" }} />
                <div className="content col-xl-12 d-flex align-items-stretch">
                  <div className="content">
                    <h2
                      style={{
                       
                        letterSpacing: "2px",
                        textAlign: "center",
                        color: "#000",
                        fontWeight:"bold",
                        marginTop:"3%"
                       
                      }}
                    >
                      {" "}
                      Frequently Asked Questions{" "}
                    </h2>
                    <br />
                    <br />
                    <div className="mviewfaqpage">
                      <Educatorfaq/>
                    </div>
                    <div className="row mscreeneducatorfaq">
                   
                      <div className="col-md-5">
                        <h3 className="aboutusheading">Is GigeconoMe Free to Students?</h3>
                        <p style={{ marginTop:"1%",fontFamily:"GothamLight",fontWeight:"bold" }}>
                        	Yes, GigeconoMe is free of charge to all students using the platform. We believe all our students should have access to all our features and functionalities at zero cost. 
                        </p>
                       
                      </div>
                      <div className="col-md-1"></div>
                      <div className="col-md-5 educatorfaq">
                        <h3 className="aboutusheading">What do you mean by Employability skills?</h3>
                        <p style={{  marginTop:"1%",fontFamily:"GothamLight",fontWeight:"bold" }}>
                        	Employability Skills can be defined as the transferable/essential, personal qualities skills needed by an individual to make them ‘employable’. Employability skills are the essential skills, personal qualities and values that enable you to thrive in any workplace. 
                        </p>
                       
                      </div>
                    </div>
                    <div className="row mt-5 mscreeneducatorfaq">
                  
                      <div className="col-md-5">
                        <h3 className="aboutusheading">What are Employability skills? </h3>
                        <p style={{  marginTop:"2%",fontFamily:"GothamLight",fontWeight:"bold" }}>
                        	These are skills employers believe will equip the employee to carry out their role to the best of their abilities. They include Communication and interpersonal skills, Problem solving skills, Using your initiative and being self-motivated, working under pressure and to deadlines, Organisational skills, Team working, Ability to learn and adapt, Numeracy, Valuing diversity and difference and Negotiation skills. 
                        </p>
                      </div>
                      <div className="col-md-1"></div>
                      <div className="col-md-5 educatorfaq">
                        <h3 className="aboutusheading">How is GigeconoMe different from other marketplaces?</h3>
                        <p style={{  marginTop:"1%",fontFamily:"GothamLight",fontWeight:"bold" }}>
                        	GigeconoMe is a student talent marketplace that aligns study and work. Our focus is to match students to companies and Startups based on employability gaps as well as various preferences including Opportunity Types, role types, Industry, organisation size and skills. As a debiased platform, we have developed a non-conventional selection process where our student profiles have no pictures, to ensure all our students are treated fairly and chosen based on their talents. 
                        </p>
                      
                      </div>
                    </div>
                    <div className="row mt-5 mscreeneducatorfaq">
               
                      <div className="col-md-5">
                        <h3 className="aboutusheading">I am an Educator and would love to Partner with GigeconoMe, who do I contact? </h3>
                        <p style={{  marginTop:"1%",fontFamily:"GothamLight",fontWeight:"bold" }}>
                        To learn more about GigeconoMe, or see how see how our platform works? Please email us at admin@gigeconome.com or use the link below to Request a Demo. 
                        </p>
                      </div>
                      <div className="col-md-1"></div>
                      <div className="col-md-5 educatorfaq">
                        <h3 className="aboutusheading">What type of companies does GigeconoMe work with?</h3>
                        <p style={{ marginTop:"5%",fontFamily:"GothamLight",fontWeight:"bold"}}>
                        	We partner with both newly formed start-ups and large enterprises who are passionate about proving student talents further opportunities to help them secure good first jobs. 
                        </p>
                      
                      </div>
                    </div>
                  
                  </div>
                </div>
                <div className="cnterbtn">
              
              <Link to="/faq"
               onClick={()=>scrollToTop()}
               className="btn btn-success"
               style={{
                 background: "rgb(0,147,69)",
                 border: "none",
                 borderRadius: "10px",
                 
                 letterSpacing: "1px",
                 fontFamily: "Gotham",
                
                 color:"white"}}
              
              >More FAQs</Link>
              </div>
              </div>
          </div>
          </section>
          
        </main>
        {/* End #main */}
        {/* ======= Footer ======= */}
        
      <Footer />
      </div>
    );
  }
}
export default Educators;
