


import React, { useState } from "react";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Logo from "../../images/New Logo.jpg";
import Login5 from '../../images/Login-36.png';
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Search } from "../Home/Search";
import UserIcon from "../Home/UserIcon";
import MobileMenu from "./MobileMenu";

const handleClick=()=>{
  //alert("home")
  window.location.href=`./home`
}
const handleRetake=()=>{
  const retakedata = { userid: sessionStorage.getItem("someData") ,

};
console.log(retakedata)
fetch("https://www.gigeconome.com:5200/api/getstEOAdaysvalidinfo", {
method: "POST",

body: JSON.stringify(retakedata), // data can be `string` or {object}!

headers: { "Content-Type": "application/json" },
})
.then((res) => res.json())

.catch((error) => console.error("Error:", error))

.then((response) => {
  console.log(response)
if(response.validdays==90 || response.validdays>=90){
  window.location.href=`./emptestdescription`
}
else if(response.validdays<90){
  window.location.href=`./retakeemptest`
}
else{
    console.log("error")
}
})
  
}
const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth' // for smoothly scrolling
  });
  sessionStorage.removeItem("SearchText");
  sessionStorage.setItem("SearchText","");
};
  if(JSON.parse(sessionStorage.getItem("AutoID"))==null || JSON.parse(sessionStorage.getItem("AutoID"))==""){
    var ss = Math.random().toString(36).substr(2, 9);
    console.log(ss)
    sessionStorage.setItem("AutoID",JSON.stringify(ss))

  }
  else{
      console.log("empty")
  }
function Header() {
  sessionStorage.setItem("baseurl",JSON.stringify("https://www.gigeconome.com:5200/api/"))
  let history = useHistory();
  let login = () => {
    history.push("/signin");
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // for smoothly scrolling
    });
  };
  let logout = () => {
    window.sessionStorage.removeItem("someData");
    window.sessionStorage.removeItem("nameData");
    window.sessionStorage.removeItem("DataName");
    window.sessionStorage.removeItem("UserType");
    window.sessionStorage.removeItem("Guest Email");
    window.sessionStorage.removeItem("Guest Id");
    window.sessionStorage.removeItem("PgoProjectid");
    window.location.href=`./home`
  };
  let handlehome=()=>{
    window.location.href=`./home`
  }
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  
  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);
  return (
    <div className="my-4" style={{ marginLeft: 0 }}>
      <nav  className="navbar navbar-expand-xl navbar-light bg-white fixed-top d-flex align-items-center">
        <div className={JSON.parse(sessionStorage.getItem("UserType"))=="Company"?"container-fluid d-flex mscreenleftcpy":"container-fluid d-flex mscreenleft"}>
          <a className="navbar-brand logo me-auto mscreenauto" id="home_nav" >
          {JSON.parse(sessionStorage.getItem("nameData"))==null?<>
         <img onClick={()=>handlehome()} src={Logo} className="mgiglogo" />
        
          </>:<>
         
          <img src={Logo} onClick={()=>handlehome()} className="mgiglogotwo" />
         
          </>}
          
            <Link to="/">
              <img src={Logo} className="gigelogoo" />
            </Link>
            <div>
              {JSON.parse(sessionStorage.getItem("nameData"))==null?<>
              <Link
            to="/postaproject"
            className="get-started-btn scrollto btn postaprojectbtnn"
           
            id="project"
           
          >
            REQUEST DEMO
          </Link>
              </>:<>
              <span  className="belliconbigscreen" style={{marginLeft:"-417%"}}><i style={{fontSize:18}} class="fa fa-bell"></i></span>
              </>}
         
          </div>
          
          <div className="usermenuu">

         <UserIcon/>
          

</div>
           
          </a>
         
          {/* Uncomment below if you prefer to use an image logo */}
          {/* <a href="index.html" className="logo me-auto"><img src="assets/img/logo.png" alt=""></a>*/}
          {/* <a className="navbar-brand" href="#">Navbar</a> */}
          <button
            className="custom-toggler navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarCollapse1"
            aria-controls="navbarsExample09"
            aria-expanded={!isNavCollapsed ? true : false}
            aria-label="Toggle navigation"
            onClick={handleNavCollapse}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
         
       
          <div
            className={`${isNavCollapsed ? "collapse" : ""} navbar-collapse`}
            id="navbarCollapse1"
          >
             <div className="container-fluid" id="hidesearch" >
       <Search/>
       </div>
       <div class="mobilemenu">
        <MobileMenu/>
       </div>
            <div className="navbar-nav headersection">
              <div className="navbar-nav me-auto mb-2 mb-lg-0" >
  
              
              <li className="nav-item dropdown drpmenu" style={{marginLeft:10}}>
               
               <Link
                 className="nav-link dropdown-toggle"
                 to="home"
                 id="navbarDropdown"
              
                 data-bs-toggle="dropdown"
                
               >
                 Home
               </Link>
               <ul
                 className="dropdown-menu"
                 aria-labelledby="navbarDropdown"
               >
                 <li className="homenavdrop">
                   <Link
                     className="dropdown-item"
                     style={{ color: "#00539C" }}
                     to="aboutus"
                     onClick={()=>scrollToTop()}
                   >
                    About Us
                   </Link>
                   <Link
                     className="dropdown-item"
                     style={{ color: "#00539C" }}
                     to="/contactus"
                     onClick={()=>scrollToTop()}
                   >
                    Contact Us
                   </Link>
                 </li>
               </ul>
             </li>
              
                <li className="nav-item dropdown drpmenu" style={{marginLeft:10}}>
               
                  <Link
                    className="nav-link dropdown-toggle"
                    to="#"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Students
                  </Link>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    <li className="homenavdrop">
                      <Link
                        className="dropdown-item"
                        style={{ color: "#00539C" }}
                        to="studentHowitswork"
                        onClick={()=>scrollToTop()}
                      >
                        How it Works
                      </Link>
                      <Link
                        className="dropdown-item"
                        style={{ color: "#00539C" }}
                        to="/companysearch"
                        onClick={()=>scrollToTop()}
                      >
                        Our Employers
                      </Link>
                      {JSON.parse(sessionStorage.getItem("UserType"))=="Student"?<>
                      <a
                        className="dropdown-item"
                        
                        style={{ color: "#00539C",cursor:"pointer" }}
                        
                        onClick={handleRetake}
                      >
                        Career Readiness Test
                      </a>
                      </>:<>
                      {JSON.parse(sessionStorage.getItem("UserType"))=="Company"||JSON.parse(sessionStorage.getItem("UserType"))=="Employee"?<>
                     
                      </>:<>
                      
                      <Link
                        className="dropdown-item"
                        
                        style={{ color: "#00539C" }}
                        to="/emplyoabilityone"
                        onClick={()=>scrollToTop()}
                      >
                        Career Readiness Test
                      </Link>
                      </>}
                      </>}
                     
                     
                    </li>
                  </ul>
                </li>
                <li className="nav-item dropdown drpmenu" style={{marginLeft:10}}>
                  <Link
                    className="nav-link dropdown-toggle"
                    to="#"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    onClick={()=>scrollToTop()}
                  >
                    Companies
                  </Link>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    <li className="homenavdrop">
                   
                     
                      <Link
                    
                        className="dropdown-item"
                        style={{ color: "#00539C" }}
                        to="/company"
                        onClick={()=>scrollToTop()}
                      >
                        How it Works
                      </Link>
                      <a
                        className="dropdown-item"
                        target="_blank"
                        style={{ color: "#00539C" }}
                        href="https://calendly.com/gigeconome"
                        onClick={()=>scrollToTop()}
                      >
                        Book a Demo
                      </a>
                      <Link
                    
                    className="dropdown-item"
                    style={{ color: "#00539C" }}
                    to="/studentsearch"
                    onClick={()=>scrollToTop()}
                  >
                    Available Student Profiles
                  </Link>
                    </li>
                  </ul>
                </li>

                <li className="nav-item dropdown drpmenu" style={{marginLeft:10,display:"none"}}>
                  <Link
                    className="nav-link dropdown-toggle"
                    to="#"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Companies
                  </Link>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    <li>
                      <Link to="company" onClick={()=>scrollToTop()} style={{ color: "#00539C" }}>
                        How it Works
                      </Link>
                    </li>
                    <li>
                      <Link to="company" onClick={()=>scrollToTop()} style={{ color: "#00539C" }}>
                        How it Works
                      </Link>
                    </li>
                  </ul>
                </li>

                <li className="nav-item dropdown drpmenu" style={{marginLeft:10}}>
                  <Link
                    className="nav-link dropdown-toggle"
                    to="#"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    onClick={()=>scrollToTop()}
                  >
                    Educators
                  </Link>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    <li className="homenavdrop">
                    <Link
                    
                    className="dropdown-item"
                    style={{ color: "#00539C" }}
                    to="/educators"
                    onClick={()=>scrollToTop()}
                  >
                    How it Works
                     </Link>
                     
                      <Link
                    
                        className="dropdown-item"
                        style={{ color: "#00539C" }}
                        to="/requestademo"
                        onClick={()=>scrollToTop()}
                      >
                        Request a Demo
                      </Link>
                      <Link
                        className="dropdown-item"
                        style={{ color: "#00539C" }}
                        to="/companysearch"
                        onClick={()=>scrollToTop()}
                      >
                        Our Employers
                      </Link>
                    </li>
                  </ul>
                </li>

               
              
              </div>
             
            
              <Link
            to="/postaproject"
            className="get-started-btn scrollto btn postaprojectbigscreen"
           
            id="project"
            style={{
              /* padding: '8px 25px', */
              /* height: '7px', */
              marginLeft: "30px",
              background: "#00539C",
              color: "#ffd563",
              borderRadius: "20px",
              padding: "5px 25px",
              whiteSpace: "nowrap",
              transition: "0.3s",
              fontSize: "14px",
              fontWeight: 600,
              display: "inline-block",
              cursor: "pointer",
              marginTop:"1%",
              fontFamily:"Gotham",
              border:"2px solid #00539C"
            }}
          >
            REQUEST DEMO
          </Link>
              
          
            
          {JSON.parse(sessionStorage.getItem("nameData"))==null?<>
       
         
       <Link
            to="/signup"
            className="get-started-btn scrollto btn mt-2 postaprojectbigscreen"
          
            onClick={()=>scrollToTop()}
            style={{
              /* padding: '8px 25px', */
              /* height: '7px', */
              marginLeft: "30px",
              background: "white",
              color: "black",
              borderRadius: "20px",
              padding: "5px 25px",
              whiteSpace: "nowrap",
              transition: "0.3s",
              fontSize: "14px",
              fontWeight: 600,
              display: "inline-block",
              cursor: "pointer",
              border:"2px solid #fed65e",
              fontFamily:"Gotham"
            }}
          >
            Sign Up
          </Link>
          
          <button
           
            className="get-started-btn scrollto btn mt-2 postaprojectbigscreen"
        
            onClick={(e) => {
              login();
            }}
            style={{
              /* padding: '8px 25px', */
              /* height: '7px', */
              marginLeft: "30px",
              background: "white",
              color: "black",
              borderRadius: "20px",
              padding: "5px 25px",
              whiteSpace: "nowrap",
              transition: "0.3s",
              fontSize: "14px",
              fontWeight: 600,
              display: "inline-block",
              cursor: "pointer",
              border:"2px solid #fed65e",
              fontFamily:"Gotham"
            }}
          >
            Login
          </button>
       </>:<>
       <div class="dropdown bigcompanysearch">
   <AccountCircleIcon   style={{fontSize:40,color:'#00539C',marginLeft:40,marginTop:2}} />
         &nbsp;&nbsp; 
         
         <span
          style={{position:"absolute",fontWeight:"bold",width:"100%",marginTop:"12%",display:"contents"}}> Hi, {JSON.parse(sessionStorage.getItem("nameData"))}</span>
          
  <div class="dropdown-content">
    {JSON.parse(sessionStorage.getItem("UserType"))=="Company"
     ||JSON.parse(sessionStorage.getItem("UserType"))=="Employee"
    ?<>
    <Link to="/CompanyProfile" style={{color:"#00539C",marginTop:10,fontSize:16,borderBottom:"1px solid #3A3939"}}>My Profile</Link> 
    <Link to="/companymessage" style={{color:"#00539C",marginTop:10,fontSize:16,borderBottom:"1px solid #3A3939"}}>Message</Link>
    <Link to="/companyopportunity" style={{color:"#00539C",paddingTop:10,fontSize:16,borderBottom:"1px solid #3A3939"}}>Opportunites</Link>
    </>:<>
    <Link to="/studentdashboard" style={{color:"#00539C",marginTop:10,fontSize:16,borderBottom:"1px solid #3A3939"}}>My Profile</Link> 
    <Link to="/studentmessage" style={{color:"#00539C",marginTop:10,fontSize:16,borderBottom:"1px solid #3A3939"}}>Message</Link>
    <Link to="/studentopportunity" style={{color:"#00539C",paddingTop:10,fontSize:16,borderBottom:"1px solid #3A3939"}}>Opportunites</Link>
    </>}
   
   
    
    <Link to="/educators" style={{color:"#00539C",marginTop:10,fontSize:16,borderBottom:"1px solid #3A3939"}}>Educators</Link>
    <a onClick={()=>logout()} style={{color:"#00539C",marginTop:10,fontSize:16,cursor:"pointer"}}>Sign out</a>
  </div>
</div>
       </>} 
       
          {/*  */}
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}
export default Header;
