import React, { Component } from "react";
import Header from "../Header/Header";
import Login1 from "../../images/Login-28.png";
import Login2 from "../../images/Login-29.png";
import Login3 from "../../images/Login-30.png";
import Login4 from "../../images/Login-31.png";
import MainIcon from "../../images/Post a Project-13.png";
import Footer from "../Footer/Footer";
import swal from "sweetalert";
import { GoogleLogin } from "react-google-login";
import Studentsignup_two from "./Studentsignup_two.js";
import api from "./../../config.js";
import "./signup.css";
import Signupthanks from "../../images/signupthanks.PNG";
import { Link } from "react-router-dom";
const responseGoogle = (response) => {
  console.log(response);
};
class SuccessReg extends React.Component {
  render() {
    return (
      <div>
        <div>
          <Header />
        </div>
        <main id="main" style={{ background: "#303030", clear: "both" }}>
          <br />
          <br />
          {/* ======= About Section ======= */}
          <section id="clients" className="clients">
            <div className="container" data-aos="fade-up">
              <style
                type="text/css"
                dangerouslySetInnerHTML={{
                  __html:
                    "\n    .boxxlr{\n     border: 0px solid green;\n    background: #edf8f2;\n    margin-right: 2.5%;\n    margin-bottom: 7%;\n    color: #000;\n    margin-left: 9%;\n    width: 100%;\n    }\n/*.boxxlr:hover {\n    border: 0px solid green;\n    background: green;\n    margin-right: 1%;\n    margin-bottom: 1%;\n     color: #fff;\n}*/\n\n.boxclrtitle {\nfont-size: 12px;font-weight: bold;\n}\n\n.boxclrtitle1 {\nfont-size: 18px;font-weight: bold;\n}\n/*.boxxlr .boxclrtitle:hover {\nfont-size: 12px;font-weight: bold;\n}*/\n\n\n  ",
                }}
              />
              <div className="row no-gutters">
                <div className="content col-xl-12 d-flex align-items-center">
                  <div className="content col-xl-12 d-flex align-items-center">
                    <div
                      className="row thankspage"
                      style={{
                        display: "flex",
                        justifyContent: "center" /* alignItems: 'center', */,
                        background: "#fff",
                        borderRadius: "20px",
                        margin: "auto",
                      
                      }}
                    >
                       <Link to="/" id="close">X</Link>
                      <img
                        src={MainIcon}
                        style={{ width: "17%", marginTop: "6%" }}
                      />

                      <div className="row h-100 row align-items-center">
                     
                        <div className="col-md-10" style={{ margin: "auto" }}>
                         <img src={Signupthanks} id="signupthanks" />
                        </div>
                       
                       
                        <div className="mt-5"></div>
                        <div className="mt-5"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        {/* End #main */}

        <Footer />
      </div>
    );
  }
}

export default SuccessReg;
