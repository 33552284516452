import React, { useState } from "react";
import FacebookLogin from "react-facebook-login";
import Header from "../Header/Header";
import LinkedIN from "../../images/company1.png";
import Twitter from "../../images/twitter.png";
import Facebook1 from "../../images/fb.png";
import Instagram from "../../images/insta.png";
import Plussymbol from "../../images/plus symbol.png";
import "./dashboard.css";

import "react-responsive-modal/styles.css"
import 'react-accessible-accordion/dist/fancy-example.css';
import { Link } from "react-router-dom";
import Footer from "../Footer/Footer";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';


function close(){
  window.location.reload();
 
}
const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth' // for smoothly scrolling
  });
};
class GradOppIndividual extends React.Component {

   

  constructor() {
    //let data=window.location.pathname.slice(26);
   // let data1=window.location.pathname.slice(23,25)
   // console.log(data1)
   var newURL=window.location.pathname;
   var splitURL=newURL.toString().split("/");
   var data1=splitURL[3]
   var data=splitURL[2]
   let decodeduserid = window.atob(data);
    let decodedpgoid = window.atob(data1);
   //console.log(decodedpgoid)
    super();
    this.state = {
        userid:decodeduserid,
        pgoprojectid:decodedpgoid,
        jobsummary:"",
        rolesandresponse:"",
        skillsrequired:"",
        technicalskills:"",
        empskillsneeded:"",
        eduandqualifreq:"",
        hiringprocess:"",
        docrequiredname:"",
        empsoftskillsname:"",
        deadlinedate:"",
        employmenttype:"",
        mindegreelevel:"",
        siteurlname:"",
        mindesgreeaward:"",
        workpermit:"",
        jobtitle:"",
        opptypename:"",
        posteddate:"",
        locationname:"",
        save:1,
        departmentname:"",
        apply:1,
        follow:1,
        cpylogo:"",
        cpyname:"",
        cpyorgsize:"",
        cpyindustryname:"",
        cpylocation:"",
        cpywebsitename:"",
        cpysummary:"",
        cpyinstagram:"",
        cpyfacebook:"",
        cpytwitter:"",
        cpylinkedin:"",
        rangeramt1:"",
        rangeamt2:"",
        moreOpp:[]

    
    }
    this.handleSave=this.handleSave.bind(this)
    this.handleSaveclose=this.handleSaveclose.bind(this)
    this.handleapply=this.handleapply.bind(this)
    this.handleapplyclose=this.handleapplyclose.bind(this)
    this.handleFollow=this.handleFollow.bind(this)
    this.handleFollowclose=this.handleFollowclose.bind(this)
    this.hanldelCompany=this.hanldelCompany.bind(this)
   this.handleJobtitle=this.handleJobtitle.bind(this)
  }

  handleJobtitle(userid,pgoprojectid){
    let data = window.location.pathname.slice(0, 22);
    let encodeduserid = window.btoa(userid);
    let encodedpgoid = window.btoa(pgoprojectid);
    window.open(`${data}/${encodeduserid}/${encodedpgoid}`,"_blank")
    
  
  }
  hanldelCompany(id){
    window.open(`./viewgigprofile/${id}`)
  }
  handleapply(){
    this.setState({apply:0})
      }
      handleapplyclose(){
        this.setState({apply:1})
          }
          handleFollow(){
            this.setState({follow:0})
            console.log("error")
              }
              handleFollowclose(){
                this.setState({follow:1})
                  }

                  handleSave(){
                    this.setState({save:0})
                    }
                    handleSaveclose(){
                      this.setState({save:1})
                      }


   
componentDidMount() {
    const pdata = { userid:this.state.userid,
        addcpydata:"getpreviewPGOdetails",
        pgoprojectid:this.state.pgoprojectid
      };
    console.log(pdata)
     fetch("https://www.gigeconome.com:5200/api/addcpy_profiledata", {
       method: "POST",
    
       body: JSON.stringify(pdata), // data can be `string` or {object}!
    
       headers: { "Content-Type": "application/json" },
     })
       .then((res) => res.json())
    
       .catch((error) => console.error("Error:", error))
    
       .then((response) => {
       console.log(response)
       this.setState({
        jobsummary:response.jobsummary,
        rolesandresponse:response.rolesandresponse,
        skillsrequired:response.skillsrequired,
        technicalskills:response.technicalskills,
        empskillsneeded:response.empskillsneeded,
        eduandqualifreq:response.eduandqualifreq,
        hiringprocess:response.hiringprocess,
        rangeramt1:response.rangeramt1,
        rangeamt2:response.rangeamt2,
       
        deadlinedate:response.deadlinedatetext,
        employmenttype:response.employmenttype,
        mindegreelevel:response.mindegreelevel,
        mindesgreeaward:response.mindesgreeaward,
        workpermit:response.workpermit,
        jobtitle:response.jobtitle,
        opptypename:response.opptypename,
        posteddate:response.posteddate,
        locationname:response.locationname,
        departmentname:response.departmentname
       })
       if(response.docrequiredname==null||response.docrequiredname==""){
        console.log("empty")

       }
       else{
        this.setState({docrequiredname:response.docrequiredname.split(","),})
       }
       if(response.empsoftskillsname==null||response.empsoftskillsname==""){
        console.log("empty")

       }
       else{
        this.setState({ empsoftskillsname:response.empsoftskillsname.split(","),})
       }
       if(response.siteurlname==null||response.siteurlname==""){
        console.log("empty")
       }
       else{
        this.setState({siteurlname:response.siteurlname})
       }
       });
 if(sessionStorage.getItem("Publishstatus")==1){
   this.setState({publishstatus:1})
 }
 else{
  this.setState({publishstatus:2})
 }



const cdata = { userid: this.state.userid,pgoprojectid:this.state.pgoprojectid };
fetch("https://www.gigeconome.com:5200/api/getcpyPGOsingleinfo", {
  method: "POST",

  body: JSON.stringify(cdata), // data can be `string` or {object}!

  headers: { "Content-Type": "application/json" },
})
  .then((res) => res.json())

  .catch((error) => console.error("Error:", error))

  .then((response) => {
  
    response.result.map(e=>{
      this.setState({cpylogo:e.cpylogo,
                  cpyname:e.cpyname,
                  cpysummary:e.cpysummary,
                  cpyindustryname:e.industryname,
                  cpyorgsize:e.orgsizename,
                  cpywebsitename:e.cpywebsite,
                  cpylocation:e.cpylocationname,
                  cpyinstagram:e.cpypinterest,
                  cpyfacebook:e.cpyfacebook,
                  cpytwitter:e.cpytwitter,
                  cpylinkedin:e.cpylinkedin

      })
    })
  });





  const data_two={userid:this.state.userid,pgoprojectid:this.state.pgoprojectid}
  fetch('https://www.gigeconome.com:5200/api/getcpyPGOmoresearch', { method: 'POST', 

  body: JSON.stringify(data_two), // data can be `string` or {object}!

  headers:{ 'Content-Type': 'application/json' } })

  .then(res => res.json())

  .catch(error => console.error('Error:', error))

  .then((response)=>{
    console.log(response)
  if(response.message=="No Search Result Found."){
    console.log("error")
  }
  else{
    this.setState({moreOpp:response.result})
    
  }

   
      })

   






}


  render() {
  
   

    return (
      <div>
       
        
    <div className="bigcompanysearch">
    <Header />
    </div>
        <div className="mscreenava">
          <Header />
        </div>

        <main 
        className="cpymainboxdashboard"
        id="stubackground_img" style={{ backgroundColor: "#333333",marginLeft:"-0.1%" }}>
          <br />
          <br />

          <div className="container-fluid">
          
            <div class="row" style={{ marginTop: "15%" }}>
              <div class="col-sm-8">
                <div
                  class="card"
                  style={{
                    backgroundColor: "white",
                    border: "none",
                    borderRadius: 1,
                    height:"auto",
                    marginBottom:"3%"
                  }}
                  
                >
                   <div className="row">
           
             <div class="col-sm-11" style={{height:"auto",paddingBottom:"3%",marginLeft:"3%"}}>
             <div className="row mt-4">
               <div class="col-sm-9">
                <span style={{fontFamily:"GothamLight",fontWeight:"bold",fontSize:13}}>
                  {this.state.opptypename==null||this.state.opptypename==""?<>
                  Not Found
                  </>:<>{this.state.opptypename}</>}
                  </span> <br/>
                <h4 style={{color:"#00539CFF"}}>{this.state.jobtitle==null||this.state.jobtitle==""?<>
                Not Found
                </>:<>{this.state.jobtitle}</>}</h4>
               </div>
              
               <div class="col-sm-3 posteddate">
                <span>
                  {this.state.posteddate==""||this.state.posteddate==null?<>
                  Not Found
                  </>:<>Posted {this.state.posteddate} Days Ago</>}
                </span>
               </div>
                </div>
                <div className="row mt-3">
                <div className="col-sm-4">
                <i className="fa fa-map-marker"></i> <span style={{fontFamily:"GothamLight",fontWeight:"bold"}}>&nbsp;{this.state.locationname}</span> <br/>
                <i class="fa fa-cog" aria-hidden="true"></i><span style={{fontFamily:"GothamLight",fontWeight:"bold"}}>&nbsp;{this.state.departmentname==""?<>Bachelor's</>:<>{this.state.departmentname}</>}</span><br/>
                <i className="fa fa-hourglass"></i><span style={{fontFamily:"GothamLight",fontWeight:"bold"}}>&nbsp;Deadline: {this.state.deadlinedate}</span>
                </div>
                <div className="col-sm-4">
                <i class="fa fa-money" aria-hidden="true"></i><span style={{fontFamily:"GothamLight",fontWeight:"bold"}}>&nbsp;
                {this.state.rangeramt1==""||this.state.rangeramt1==null?<>
                Not Found
                </>:<> {this.state.rangeramt1} - {this.state.rangeamt2}</>}

               </span> <br/>
                <i class="fa fa-clock-o" aria-hidden="true"></i><span style={{fontFamily:"GothamLight",fontWeight:"bold"}}>&nbsp;{this.state.employmenttype}</span> <br/>
                <i class="fa fa-user" aria-hidden="true"></i><span style={{fontFamily:"GothamLight",fontWeight:"bold"}}>&nbsp;10 Vacancies</span>

                </div>
                </div>
                <div className="row mt-3">
                <div className="col-sm-3">
                  {this.state.save==1?<>
                    <button className="saveoppbtn" style={{color:"rgb(0, 83, 156)",border:"2px solid rgb(0, 83, 156)"}} onClick={this.handleSave}><i class="fa fa-plus" aria-hidden="true"></i>&nbsp;Save Opportunity</button>
                  </>:<><button className="saveoppbtnn" style={{background:"rgb(0, 83, 156)"}} onClick={this.handleSaveclose}><i class="fa fa-plus" aria-hidden="true"></i>&nbsp;Save Opportunity</button></>}
                    
                </div>
                <div className="col-sm-3">
                  {this.state.follow==1?<>
                    <button className="saveoppbtn" style={{color:"rgb(0, 83, 156)",border:"2px solid rgb(0, 83, 156)"}} onClick={this.handleFollow}><i  class="fa fa-star" aria-hidden="true"></i>&nbsp;Follow Employer</button>
                  </>:<> <button className="saveoppbtnn" style={{background:"rgb(0, 83, 156)"}} onClick={this.handleFollowclose}><i  class="fa fa-star" aria-hidden="true"></i>&nbsp;Follow Employer</button></>}
                   
                </div>
                <div className="col-sm-3">
                  {this.state.apply==1?<>
                    <a href={this.state.siteurlname} target="_blank"> <button className="saveoppbtn" style={{color:"rgb(0, 83, 156)",border:"2px solid rgb(0, 83, 156)"}} onClick={this.handleapply}><i class="fa fa-pencil"  aria-hidden="true"></i>&nbsp;Apply Now</button></a>
                  </>:<><a href={this.state.siteurlname} target="_blank"><button className="saveoppbtnn" style={{background:"rgb(0, 83, 156)"}} onClick={this.handleapplyclose}><i class="fa fa-pencil"  aria-hidden="true"></i>&nbsp;Apply Now</button></a></>}
                    
                </div>
                </div>
                <hr/>
                <div class="row">
              <div class="col-sm-10"></div>
              <div class="col-sm-2 bigcompanysearch" style={{textAlign:"right"}}>
              <i class="fa fa-circle" aria-hidden="true" style={{color:"#00539CFF"}}></i>&nbsp;=&nbsp;<span style={{fontSize:14,fontFamily:"GothamLight",fontWeight:"bold",verticalAlign:"top"}}>preferred</span>
              </div>
            </div>
            <div className="row">
               <div class="col-sm-4">
               
                <h5 style={{color:"#00539CFF"}}>Eligible Applicants</h5>
               </div>
              
                </div>
                <div className="row mt-2">
               <div class="col-sm-3">
               
                <h6>Work Permit Required?</h6> 
               </div>
               <div className="col-sm-3"><span style={{fontWeight:"bold",fontFamily:"GothamLight",marginLeft:"1%"}}>
               {this.state.workpermit==null||this.state.workpermit==""?<>
               Not Found
               </>:<>{this.state.workpermit}</>}
                </span></div>
              
                </div>
                <div className="row mt-1">
               <div class="col-sm-3">
               
                <h6>Minimum Degree Level</h6> 
               </div>
               <div className="col-sm-3"><span style={{fontWeight:"bold",fontFamily:"GothamLight",marginLeft:"1%"}}>
                {this.state.mindegreelevel==null||this.state.mindegreelevel==""?<>Not Found</>:<>{this.state.mindegreelevel}</>}
                </span></div>
              
                </div>
                <div className="row mt-1">
               <div class="col-sm-3">
               
                <h6>Minimum Awarad</h6> 
               </div>
               <div className="col-sm-1"><span style={{fontWeight:"bold",fontFamily:"GothamLight",marginLeft:"2%"}}>
                {this.state.mindesgreeaward==null||this.state.mindesgreeaward==""?<>Not Found</>:<>{this.state.mindesgreeaward}</>}
                </span></div>
              
                </div>
                <div className="row mt-1">
               <div class="col-sm-8">
               
                <h6>Accepted Disciplines</h6> 
               </div>

                </div>
                <div className="row mt-1">
                {/*<div className="col-sm-4">
               <i class="fa fa-circle" aria-hidden="true" style={{color:"#00539CFF"}}></i>&nbsp;&nbsp;<span style={{fontWeight:"bold",fontFamily:"GothamLight"}}>Mechanical Engineering</span>
                </div>
                <div className="col-sm-4">
               <i class="fa fa-circle" aria-hidden="true" style={{color:"#00539CFF"}}></i>&nbsp;&nbsp;<span style={{fontWeight:"bold",fontFamily:"GothamLight"}}>Mechanical Engineering</span>
                </div>
                <div className="col-sm-4">
               <i class="fa fa-circle" aria-hidden="true" style={{color:"#00539CFF"}}></i>&nbsp;&nbsp;<span style={{fontWeight:"bold",fontFamily:"GothamLight"}}>Mechanical Engineering</span>
                </div> */}
                </div>
                <div className="row mt-1">
                {/* <div className="col-sm-4">
               <i class="fa fa-circle" aria-hidden="true" style={{color:"#00539CFF"}}></i>&nbsp;&nbsp;<span style={{fontWeight:"bold",fontFamily:"GothamLight"}}>Mechanical Engineering</span>
                </div>
                <div className="col-sm-4">
               <i class="fa fa-circle" aria-hidden="true" style={{color:"#00539CFF"}}></i>&nbsp;&nbsp;<span style={{fontWeight:"bold",fontFamily:"GothamLight"}}>Mechanical Engineering</span>
                </div>
                <div className="col-sm-4">
               <i class="fa fa-circle" aria-hidden="true" style={{color:"#00539CFF"}}></i>&nbsp;&nbsp;<span style={{fontWeight:"bold",fontFamily:"GothamLight"}}>Mechanical Engineering</span>
                </div>*/}
                </div>  
                <div className="row mt-3">
               <div class="col-sm-8">
               
                <h6>Employability Skills</h6> 
               </div>

                </div>  
                <div className="row">
                {this.state.empsoftskillsname==""?null
                      
                      
                      :<> {this.state.empsoftskillsname.map((item) =>
                        <div className="col-sm-3">
                          <button className="tagsbtn" style={{background:"rgb(0, 83, 156)"}}>{item}</button>
                        </div>
                        
                      )}</>}
                  
                </div>
                 
                <br/>
                <hr/>
                
                <div className="row">
               <div class="col-sm-4">
               
                <h5 style={{color:"#00539CFF"}}>Job Summary</h5>
               </div>
              
                </div>  
                <div className="row">
                    <div className="col-sm-12">
                <span style={{fontFamily:"GothamLight",fontWeight:"bold"}}>{this.state.jobsummary}</span>
                    </div>
                </div> 
                <br/>
                <div className="row">
               <div class="col-sm-4">
               
                <h5 style={{color:"#00539CFF"}}>Role and Responsibilities</h5>
               </div>
              
                </div>  
                <div className="row">
                    <div className="col-sm-12">
                <span style={{fontFamily:"GothamLight",fontWeight:"bold"}}>{this.state.rolesandresponse}</span>
                    </div>
                </div> 
                <br/>
                <div className="row">
               <div class="col-sm-4">
               
                <h5 style={{color:"#00539CFF"}}>Skills Required</h5>
               </div>
              
                </div>  
                <div className="row">
                    <div className="col-sm-12">
                <span style={{fontFamily:"GothamLight",fontWeight:"bold"}}>{this.state.skillsrequired}</span>
                    </div>
                </div>  
                <br/>
                <div className="row">
               <div class="col-sm-4">
               
                <h5 style={{color:"#00539CFF"}}>Technical Skills</h5>
               </div>
              
                </div>  
                <div className="row">
                    <div className="col-sm-12">
                <span style={{fontFamily:"GothamLight",fontWeight:"bold"}}>{this.state.technicalskills}</span>
                    </div>
                </div>   
                <br/>
                <div className="row">
               <div class="col-sm-4">
               
                <h5 style={{color:"#00539CFF"}}>Employability Skills</h5>
               </div>
              
                </div>  
                <div className="row">
                    <div className="col-sm-12">
                <span style={{fontFamily:"GothamLight",fontWeight:"bold"}}>{this.state.empskillsneeded}</span>
                    </div>
                </div>  
                <br/>
                <div className="row">
               <div class="col-sm-12">
               
                <h5 style={{color:"#00539CFF"}}>Education and Qualifications</h5>
               </div>
              
                </div>  
                <div className="row">
                    <div className="col-sm-12">
                <span style={{fontFamily:"GothamLight",fontWeight:"bold"}}>{this.state.eduandqualifreq}</span>
                    </div>
                </div>
                
                <hr/>
                <br/>
                <div className="row">
               <div class="col-sm-12">
               
                <h5 style={{color:"#00539CFF"}}>Hiring Process</h5>
               </div>
              
                </div>  
                <div className="row">
                    <div className="col-sm-12">
                <span style={{fontFamily:"GothamLight",fontWeight:"bold"}}>{this.state.hiringprocess}</span>
                    </div>
                </div>
                <br/>
                <div className="row">
               <div class="col-sm-12">
               
                <h6 style={{color:"#00539CFF"}}>Application Documents Required</h6>
               </div>
              
                </div>  
                <div className="row mt-2">
                   
                    
                    {this.state.docrequiredname==""?null
                      
                      
                      :<> {this.state.docrequiredname.map((item) =>
                        <div className="col-sm-4">
                          <i class="fa fa-file" aria-hidden="true"></i>&nbsp;
                        <span style={{fontFamily:"GothamLight",fontWeight:"bold"}}>{item}</span>
                        </div>
                      )}</>}
                   
                   
                    
                </div>
                <br/>
                <hr/>
                <div className="row mt-2">
                <div className="col-sm-3"></div>
                <div className="col-sm-6">
                  {this.state.siteurlname==null||this.state.siteurlname==""?<>
                  <button className="applynowbtn" style={{background:"#00539CFF"}}><i className="fa fa-pencil"></i>&nbsp;&nbsp;Apply Now</button>
                  </>:<>
                 <a href={this.state.siteurlname} target="_blank"><button className="applynowbtn" style={{background:"#00539CFF"}}><i className="fa fa-pencil"></i>&nbsp;&nbsp;Apply Now</button></a> 
                  </>}
                    
                </div>
                <div className="col-sm-3"></div>
                </div>
                <div className="row mt-3">
                <div className="col-sm-3"></div>
                <div className="col-sm-3">
                <button className="saveoppbtn saveoppbtnmargin" style={{color:"#00539CFF",border:"2px solid #00539CFF"}}><i class="fa fa-plus" aria-hidden="true"></i>&nbsp;&nbsp;Save Opportunity</button>
                </div>
                <div className="col-sm-3">
                <button className="saveoppbtn" style={{color:"#00539CFF",border:"2px solid #00539CFF"}}><i class="fa fa-star" aria-hidden="true"></i>&nbsp;&nbsp;Follow Employer</button> 
                </div>
                <div className="col-sm-3"></div>
                </div>
             </div>
             </div>

       
                </div>
              </div>
              <div class="col-sm-4">
                <div
                  class="card bigcompanysearch"
                  style={{
                    backgroundColor: "white",
                    border: "none",
                    borderRadius: 1,
                   height:"auto"
                  }}
                  
                >
                  <h5 style={{color:"#00539CFF"}}>Employer Details</h5>
                  <hr/>
               <div className="row">
                <div className="col-sm-4">
                  <img src={this.state.cpylogo} style={{width:"100%",border:"2px solid #00539CFF"}} />
                </div>
                <div className="col-sm-8">
                <div style={{wordBreak:"break-all",marginTop:"1%",textAlign:"left"}}>
                <Link
                       to={{ 
                       
                        pathname: `/viewgigprofile/${this.state.userid}`, 
                         
                      }}><h6 class="cpynamehover" style={{fontWeight:"bold",color:"#00539CFF"}}>{this.state.cpyname}</h6></Link>
                  </div> 
          <div style={{wordBreak:"break-all",marginTop:"2%",textAlign:"left"}}><i class="fa fa-industry" aria-hidden="true" style={{color:"#00539CFF",fontSize:14}}></i><span style={{fontWeight:"bold",fontFamily:"GothamLight",marginLeft:"3%",fontSize:14}}>{this.state.cpyindustryname}</span></div> 
          <div style={{wordBreak:"break-all",marginTop:"2%",textAlign:"left"}}><i class="fa fa-users" aria-hidden="true" style={{color:"#00539CFF",fontSize:14}}></i><span style={{fontWeight:"bold",fontFamily:"GothamLight",marginLeft:"3%",fontSize:14}}>{this.state.cpyorgsize}</span></div> 
          <div style={{wordBreak:"break-all",marginTop:"2%",textAlign:"left",marginLeft:"1%"}}><i class="fa fa-map-marker" aria-hidden="true" style={{color:"#00539CFF",fontSize:14}}></i><span style={{fontWeight:"bold",fontFamily:"GothamLight",marginLeft:"3%",fontSize:14}}>{this.state.cpylocation}</span></div>  
          <div style={{wordBreak:"break-all",marginTop:"2%",textAlign:"left"}}><i class="fa fa-globe" aria-hidden="true" style={{color:"#00539CFF",fontSize:14}}></i><span style={{fontWeight:"bold",fontFamily:"GothamLight",marginLeft:"3%",fontSize:14}}>{this.state.cpywebsitename}</span></div> 
                </div>
                
               </div>
              <h6 style={{marginTop:"5%",}}>Company Summary</h6>
               <hr />
               <div style={{marginTop:"0%",lineHeight:"15px"}}>
               <span style={{fontWeight:"bold",fontFamily:"GothamLight",fontSize:14}}>{this.state.cpysummary}</span>
               </div>
              <div className="row" style={{marginTop:"5%"}}>
                <div className="col-sm-3 text-center">
                  <a href={this.state.cpyinstagram} target="_blank">
                  <img src={Instagram} style={{width:"40%",border:"2px solid rgb(167, 167, 167)",padding:"2%"}} />
                  </a>
                  
                </div>
                <div className="col-sm-3 text-center">
                  <a href={this.state.cpyfacebook} target="_blank">
                  <img src={Facebook1} style={{width:"40%",border:"2px solid rgb(167, 167, 167)",padding:"4%"}} />
                  </a>
              
                </div>
                <div className="col-sm-3 text-center">
                  <a href={this.state.cpytwitter} target="_blank">
                  <img src={Twitter} style={{width:"40%",border:"2px solid rgb(167, 167, 167)",padding:"4%"}} />
                  </a>
               
                </div>
                <div className="col-sm-3 text-center">
                  <a href={this.state.cpylinkedin} target="_blank">
                  <img src={LinkedIN} style={{width:"40%",border:"2px solid rgb(167, 167, 167)",padding:"4%"}} />
                  </a>
                
                </div>
              </div>
              <div className="row" style={{marginTop:"5%"}}>
               
              
                <div className="col-sm-6 text-center">
                
               
                </div>
                <div className="col-sm-6" style={{textAlign:"right"}}>
                <Link
                       to={{ 
                       
                        pathname: `/viewgigprofile/${this.state.userid}`, 
                         
                      }}className="viewcpypage" style={{color:"rgb(167, 167, 167)",fontWeight:"bold",fontFamily:"GothamLight",fontSize:"14px"}}>View full company page<i className="fa fa-arrow-right" style={{color:"rgb(167, 167, 167)",marginLeft:"2%"}}></i></Link>
                
                </div>
              </div>
      
                </div>
                <div className="mscreenava">
             <Accordion allowZeroExpanded preExpanded={[1]}>
            <AccordionItem uuid={1}>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    
                    <div  id="cert_text">
                <a style={{paddingLeft:"1%",}} id="geogra_text">Employer Details
               </a>
    
             </div>
            
              
                 
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                <div className="row">
                <div className="col-sm-4">
                  <img src={this.state.cpylogo} style={{width:"100%",border:"2px solid #00539CFF"}} />
                </div>
                <div className="col-sm-8">
                <div style={{wordBreak:"break-all",marginTop:"1%",textAlign:"left"}}><h6 style={{fontWeight:"bold",color:"#00539CFF"}}>{this.state.cpyname}</h6></div> 
          <div style={{wordBreak:"break-all",marginTop:"2%",textAlign:"left"}}><i class="fa fa-industry" aria-hidden="true" style={{color:"#00539CFF",fontSize:14}}></i><span style={{fontWeight:"bold",fontFamily:"GothamLight",marginLeft:"3%",fontSize:14}}>{this.state.cpyindustryname}</span></div> 
          <div style={{wordBreak:"break-all",marginTop:"2%",textAlign:"left"}}><i class="fa fa-users" aria-hidden="true" style={{color:"#00539CFF",fontSize:14}}></i><span style={{fontWeight:"bold",fontFamily:"GothamLight",marginLeft:"3%",fontSize:14}}>{this.state.cpyorgsize}</span></div> 
          <div style={{wordBreak:"break-all",marginTop:"2%",textAlign:"left",marginLeft:"1%"}}><i class="fa fa-map-marker" aria-hidden="true" style={{color:"#00539CFF",fontSize:14}}></i><span style={{fontWeight:"bold",fontFamily:"GothamLight",marginLeft:"3%",fontSize:14}}>{this.state.cpylocation}</span></div>  
          <div style={{wordBreak:"break-all",marginTop:"2%",textAlign:"left"}}><i class="fa fa-globe" aria-hidden="true" style={{color:"#00539CFF",fontSize:14}}></i><span style={{fontWeight:"bold",fontFamily:"GothamLight",marginLeft:"3%",fontSize:14}}>{this.state.cpywebsitename}</span></div> 
                </div>
                
               </div>
              <h6 style={{marginTop:"5%",}}>Company Summary</h6>
               <hr />
               <div style={{marginTop:"0%",lineHeight:"15px"}}>
               <span style={{fontWeight:"bold",fontFamily:"GothamLight",fontSize:14}}>{this.state.cpysummary}</span>
               </div>
             
              <div className="row" style={{marginTop:"5%"}}>
               
              
                <div className="col-sm-6 text-center">
                
               
                </div>
                <div className="col-sm-6" style={{textAlign:"right"}}>
                <Link
                       to={{ 
                       
                        pathname: `/viewgigprofile/${this.state.userid}`, 
                         
                      }}className="viewcpypage" style={{color:"rgb(167, 167, 167)",fontWeight:"bold",fontFamily:"GothamLight",fontSize:"14px"}}>View full company page<i className="fa fa-arrow-right" style={{color:"rgb(167, 167, 167)",marginLeft:"2%"}}></i></Link>
                
                </div>
              </div>
                </AccordionItemPanel>
            </AccordionItem>
            </Accordion>
             </div>
             <div className="mscreenava">
             <Accordion allowZeroExpanded preExpanded={[1]}>
            <AccordionItem uuid={1}>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    
                    <div  id="cert_text">
                <a style={{paddingLeft:"1%",}} id="geogra_text">More Opportunities from this Employer
               </a>
    
             </div>
            
              
                 
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                {this.state.moreOpp.map((e)=>(
                <div style={{height:"auto",border:"1px solid",paddingBottom:"3%",marginBottom:"3%"}}>
                  <div className="row">
                  <div className="col-sm-6" style={{margin:"3%",wordBreak:"break-all"}}>
                  {e.posteddate==0 || e.posteddate==1?<>
                    <h6 style={{fontSize:15,color:"#fd9f68"}}>
                    RECENTLY &nbsp;
                    <span style={{fontSize:15,color:"rgb(167, 167, 167)"}}>added</span>
                    </h6>
                    </>:<> <h6 style={{fontSize:15,color:"rgb(167, 167, 167)"}}>
                     Posted {e.posteddate} days ago.
                      </h6></>}
                    <h5 style={{color:"#00539CFF",fontSize:16}}>{e.jobtitle}</h5>
                  </div>
                 
                  <div className="col-sm-4" style={{margin:"3%"}}>
                   
                    <img src={e.cpylogo} style={{width:"90%",border:"2px solid #00539CFF"}} />
                  </div>
                  </div>
                  <div className="col-md-12" style={{textAlign:"justify",marginLeft:"1%"}}>
            <div style={{width:"33%",display:"inline-flex",marginLeft:"2%"}}>
               <i class="fa fa-map-marker" aria-hidden="true" style={{marginTop:"6%",fontSize:12,fontWeight:"bold",
               marginLeft:"2%"}}></i> <span style={{fontFamily:"Gotham",marginLeft:"4%",fontSize:13,textAlign:"left"}}>{e.locationname==null||e.locationname==""?<>Not Found</>:<>{e.locationname}</>}</span>
            </div>
            <div style={{display:"inline-flex",width:"55%",marginLeft:"10%"}}>
               <i class="fa fa fa-money" aria-hidden="true" style={{marginTop:"5%",fontSize:12,fontWeight:"bold"}}></i> <span style={{fontFamily:"Gotham",marginLeft:"3%",fontSize:13,textAlign:"left"}}>{e.rangeamt2==null||e.rangeamt2==""?<>Not Found</>:<>{e.rangeramt1} - {e.rangeamt2}</>}</span>
            </div>
            
           </div>
           <div className="col-md-12" style={{textAlign:"justify",marginLeft:"1%"}}>
            <div style={{width:"38%",display:"inline-flex",marginLeft:"3%"}}>
               <i class="fa fa-graduation-cap" aria-hidden="true" style={{marginTop:"8%",marginLeft:"-1%",fontSize:12,fontWeight:"bold"}}></i> <span style={{fontFamily:"Gotham",fontSize:13,marginLeft:"2%",textAlign:"left"}}>{e.getdiscipline==null || e.getdiscipline==""?<><span>Not Found</span></>:<>{e.getdiscipline.split(",")[0]}</>}</span>
            </div>
            <div style={{display:"inline-flex",marginLeft:"4%"}}>
               <i class="fa fa-trophy" aria-hidden="true" style={{marginTop:"23%",marginLeft:"3%",fontSize:12,fontWeight:"bold"}}></i> <span style={{fontFamily:"Gotham",marginLeft:"35%",fontSize:13,marginTop:"-6%",textAlign:"left"}}>{e.mindesgreeaward==null||e.mindesgreeaward==""?<>
               Not Found
               </>:<>{e.mindesgreeaward}</>}</span>
            </div>
            
           </div>
           <div className="col-md-12" style={{textAlign:"justify",marginLeft:"1%"}}>
            <div style={{width:"36%",display:"inline-flex",marginLeft:"3%"}}>
               <i class="fa fa-cog" aria-hidden="true" style={{marginTop:"7%",fontSize:12,fontWeight:"bold"}}></i> <span style={{fontFamily:"Gotham",marginLeft:"4%",fontSize:13,textAlign:"left"}}>{e.opptypename}</span>
            </div>
            <div style={{display:"inline-flex",width:"26%",marginLeft:"7%"}}>
               <i class="fa fa-clock-o" aria-hidden="true" style={{marginTop:"8%",fontSize:12}}></i> <span style={{fontFamily:"Gotham",marginLeft:"4%",fontSize:13,textAlign:"left"}}>{e.employmenttype==null||e.employmenttype==""?<>
               Not Found
               </>:<>{e.employmenttype}</>}</span>
            </div>
            
           </div>
           <div className="col-md-12" style={{textAlign:"justify",marginLeft:"1%"}}>
            <div style={{width:"36%",display:"inline-flex",marginLeft:"3%"}}>
               <i class="fa fa-hourglass" aria-hidden="true" style={{marginTop:"7%",fontSize:12,fontWeight:"bold"}}></i> <span style={{fontFamily:"Gotham",marginLeft:"4%",fontSize:13,textAlign:"left"}}>{e.deadlinedate==null||e.deadlinedate==""?<>
               Not Found
               </>:<>{e.deadlinedate}</>}</span>
            </div>
            <div style={{display:"inline-flex",width:"26%",visibility:"hidden"}}>
               <i   class="fa fa-hourglass" aria-hidden="true" style={{marginTop:"2%",fontSize:12}}></i> <span style={{fontFamily:"Gotham",marginLeft:"4%",fontSize:13,textAlign:"left"}}>{e.deadlinedate==null||e.deadlinedate==""?<>
               Not Found
               </>:<>{e.deadlinedate}</>}</span>
            </div>
            
           </div>
                </div>
              ))}
              <div className="row" style={{marginTop:"3%",}}>
      <div className="col-sm-12" style={{textAlign:"left",lineHeight:"15px"}}>
        {this.state.moreOpp.length==0?<>
        
        </>:<> <Link to="/gradopportunity" onClick={()=>scrollToTop()}> <span style={{fontWeight:"bold",fontFamily:"GothamLight",fontSize:13,color:"#a7a7a7",cursor:"pointer"}}>View all our Graduate Opportunties<i class="fa fa-arrow-right" aria-hidden="true" style={{color:"#a7a7a7",marginLeft:"2%"}}></i></span></Link></>}
       
       
      </div>

    </div>
                </AccordionItemPanel>
            </AccordionItem>
            </Accordion>
             </div>
                <div
                  class="card bigcompanysearch mt-3"
                  style={{
                    backgroundColor: "white",
                    border: "none",
                    borderRadius: 1,
                   height:"68em",
                   marginBottom:"5%",
                   overflowY:"scroll"
                  }}
                  
                >
                  <h5 style={{color:"#00539CFF"}}>More Opportunities from this Employer</h5>
                  <hr/>
            
              {this.state.moreOpp.map((e)=>(
                <div style={{height:"auto",border:"1px solid",paddingBottom:"3%",marginBottom:"3%"}}>
                  <div className="row">
                  <div className="col-sm-6" style={{margin:"3%",wordBreak:"break-all"}}>
                    {e.posteddate==0 || e.posteddate==1?<>
                    <h6 style={{fontSize:15,color:"#fd9f68"}}>
                    RECENTLY &nbsp;
                    <span style={{fontSize:15,color:"rgb(167, 167, 167)"}}>added</span>
                    </h6>
                    </>:<> <h6 style={{fontSize:15,color:"rgb(167, 167, 167)"}}>
                     Posted {e.posteddate} days ago.
                      </h6></>}
                   
                    <h5 class="cpynamehover" onClick={()=>this.handleJobtitle(e.userid,e.pgoprojectid)} style={{color:"#00539CFF",fontSize:16}}>{e.jobtitle}</h5>
                  </div>
                 
                  <div className="col-sm-4" style={{margin:"3%"}}>
                   
                    <img src={e.cpylogo} style={{width:"90%",border:"2px solid #00539CFF",height:65}} />
                  </div>
                  <div className="col-sm-1" style={{marginLeft:"-10%"}}> <img src={Plussymbol} style={{height:35,marginTop:"20%"}} /></div>
                  </div>
                  <div className="col-md-12" style={{textAlign:"justify",marginLeft:"1%"}}>
            <div style={{width:"36%",display:"inline-flex",marginLeft:"3%"}}>
               <i class="fa fa-map-marker" aria-hidden="true" style={{marginTop:"1%",fontSize:12,fontWeight:"bold",
               marginLeft:"2%"}}></i> <span style={{fontFamily:"Gotham",marginLeft:"4%",fontSize:13,textAlign:"left"}}>{e.locationname==null||e.locationname==""?<>Not Found</>:<>{e.locationname}</>}</span>
            </div>
            <div style={{display:"inline-flex",width:"50%",marginLeft:"2%"}}>
               <i class="fa fa fa-money" aria-hidden="true" style={{marginTop:"1%",fontSize:12,fontWeight:"bold"}}></i> <span style={{fontFamily:"Gotham",marginLeft:"3%",fontSize:13,textAlign:"left"}}>{e.rangeamt2==null||e.rangeamt2==""?<>Not Found</>:<>{e.rangeramt1} - {e.rangeamt2}</>}</span>
            </div>
            
           </div>
           <div className="col-md-12" style={{textAlign:"justify",marginLeft:"1%"}}>
            <div style={{width:"30%",display:"inline-flex",marginLeft:"3%"}}>
               <i class="fa fa-graduation-cap" aria-hidden="true" style={{marginTop:"2%",marginLeft:"-1%",fontSize:12,fontWeight:"bold"}}></i> <span style={{fontFamily:"Gotham",fontSize:13,marginLeft:"2%",textAlign:"left"}}>{e.getdiscipline==null || e.getdiscipline==""?<><span>Not Found</span></>:<>{e.getdiscipline.split(",")[0]}</>}</span>
            </div>
            <div style={{display:"inline-flex",marginLeft:"8%"}}>
               <i class="fa fa-trophy" aria-hidden="true" style={{marginTop:"6%",marginLeft:"3%",fontSize:12,fontWeight:"bold"}}></i> <span style={{fontFamily:"Gotham",marginLeft:"35%",fontSize:13,marginTop:"-6%",textAlign:"left"}}>{e.mindesgreeaward==null||e.mindesgreeaward==""?<>
               Not Found
               </>:<>{e.mindesgreeaward}</>}</span>
            </div>
            
           </div>
           <div className="col-md-12" style={{textAlign:"justify",marginLeft:"1%"}}>
            <div style={{width:"36%",display:"inline-flex",marginLeft:"3%"}}>
               <i class="fa fa-cog" aria-hidden="true" style={{marginTop:"2%",fontSize:12,fontWeight:"bold"}}></i> <span style={{fontFamily:"Gotham",marginLeft:"4%",fontSize:13,textAlign:"left"}}>{e.opptypename}</span>
            </div>
            <div style={{display:"inline-flex",width:"26%",marginLeft:"2%"}}>
               <i class="fa fa-clock-o" aria-hidden="true" style={{marginTop:"2%",fontSize:12}}></i> <span style={{fontFamily:"Gotham",marginLeft:"4%",fontSize:13,textAlign:"left"}}>{e.employmenttype==null||e.employmenttype==""?<>
               Not Found
               </>:<>{e.employmenttype}</>}</span>
            </div>
            
           </div>
           <div className="col-md-12" style={{textAlign:"justify",marginLeft:"1%"}}>
            <div style={{width:"36%",display:"inline-flex",marginLeft:"3%"}}>
               <i class="fa fa-hourglass" aria-hidden="true" style={{marginTop:"2%",fontSize:12,fontWeight:"bold"}}></i> <span style={{fontFamily:"Gotham",marginLeft:"4%",fontSize:13,textAlign:"left"}}>{e.deadlinedate==null||e.deadlinedate==""?<>
               Not Found
               </>:<>{e.deadlinedate}</>}</span>
            </div>
            <div style={{display:"inline-flex",width:"26%",visibility:"hidden"}}>
               <i   class="fa fa-hourglass" aria-hidden="true" style={{marginTop:"2%",fontSize:12}}></i> <span style={{fontFamily:"Gotham",marginLeft:"4%",fontSize:13,textAlign:"left"}}>{e.deadlinedate==null||e.deadlinedate==""?<>
               Not Found
               </>:<>{e.deadlinedate}</>}</span>
            </div>
            
           </div>
                </div>
              ))}
              <div className="row" style={{marginTop:"3%",}}>
      <div className="col-sm-12" style={{textAlign:"left",lineHeight:"15px"}}>
        {this.state.moreOpp.length==0?<>
        </>:<><Link to="/gradopportunity" onClick={()=>scrollToTop()}> <span style={{fontWeight:"bold",fontFamily:"GothamLight",fontSize:13,color:"#a7a7a7",cursor:"pointer"}}>View all our Graduate Opportunties<i class="fa fa-arrow-right" aria-hidden="true" style={{color:"#a7a7a7",marginLeft:"2%"}}></i></span></Link></>}
        
       
      </div>

    </div>
                </div>                
              </div>
           
            
             
            
            </div>
          </div>
        
     
         
        </main>
      <Footer/>
        {/* ======= About Section ======= */}
        {/* End #main */}
      </div>
    );
  }
}

export default GradOppIndividual;
