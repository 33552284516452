import React, { useState } from "react";
import { Redirect, Switch, Route, Link } from "react-router-dom";
import "./dashboard.css";
import SummaryLogo from "../../images/plogo.PNG";
import Logo from "../../images/logo.png";
import DashboardMain from "./DashboardMain";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import ReactPaginate from 'react-paginate';
import BackgroundImage from "../../images/companyDashboard.jpg";
import CompanyOppMenu from "./CompanyOppMenu";
import axios from "axios";
import LoadingIcon from "../../images/loading2.gif";
import { WindowRounded } from "@mui/icons-material";
import CompanyNav from "./CompanyNav";
//Datatable Modules
 
class CompanyOpportunity extends React.Component {
  constructor(props) {
    super(props);
    let comments = JSON.parse(sessionStorage.getItem("someData"));
    this.state = {
    userid:comments,
    subjecttitle:"",
    postprojectid:"",
    senderid:"",
    sentdate:"",
    sendername:"",
    projname:"",
    projteamsize:"",
    projtype:"",
    projidea:"",
    projdescription:"",
    projsize:"",
    projpostedtype:"",
    categoryname:"",
    subcategoryname:"",
    departmentname:"",
    locationname:"",
    startdate:"",
    OptimalfrequencyProject:"",
    projdescription:"",
    skillname:"",
    languagename:"",
    opentoallintrested:"",
    estimatedProjecttolast:"",
    studenttypename:"",
    price:"",
    sentdate:"",
    cpylogo:"",
    companyname:"",
    cpyindestryname:"",
    cpyfoundedyear:"",
    cpywebsite:"",
    cpylocation:"",
    companysize:"",
    opportunitiestype:"",
    SubjectData:[],
    SubjectID:0,
    DegreeData:[],
    showEmail:0,
    companysummary:"",
    openEmail:0,
    Subjecttitle:"",
    messagestatus:2,
    searchtext:"",
    totalsearchcount:"",
    SenderID:"",
    PostAProjectID:"",
    StudentUserID:"",
    emailCompose:0,
    emailCc:0,
    emailBcc:0,
    tabcontent:0,
    reply:0,
    projectcount:"",
    opportunitycount:"",
    matchcount:"",
    usrmailboxid:0,
    archive:0,
    project:0,
    opportunity:0,
    matchedId:"",
    supportcontent:0,
    archivetotalcount:"",
    proposalid:"",
    ticketid:"",
    proposal:0,
    displayallopportunity:1,
    cpybanner:"",
    selectOpportunity:[],
    DataisLoaded: false,
    allopportunity:1,
    offset: 0,
    data: [],
    perPage: 10,
    currentPage: 0,
    datalimit:50,
    totalcount:10,
    pendingresult:0,
    displayoppstatus:0,
    apiopp:0,
    pastresult:0,
    reject:0,
    searchtext:"",
    projectrelatedoppsearch:0,
    fixedtermoppsearch:0,
    projectfiltersearch:0,
    oppfiltersearch:0,
    pastsearchproject:0,
    pastsearchopp:0,
    sortorder:1,
    viewinfo:0,
    alloppsearchbar:0,
    totalcounttwentyfive:"",
    totalcountten:"",
    totalcountfifty:"",
    totalcounthundred:"",
    showdata:0,
    pagenumber:1,
    totalpage:0,
    abc:"",
    showPagination:0


    };
    this.handlePageClick = this.handlePageClick.bind(this);
   this.handleProposal=this.handleProposal.bind(this)
   this.handleAllOpp=this.handleAllOpp.bind(this)
   this.receivedData=this.receivedData.bind(this)
   this.showFilter=this.showFilter.bind(this)
   this.handlePending=this.handlePending.bind(this)
   this.handlePast=this.handlePast.bind(this)
   this.handleReject=this.handleReject.bind(this)
   this.ViewDetails=this.ViewDetails.bind(this)
   this.handleSearch=this.handleSearch.bind(this)
   this.showFilterCreateaproposal=this.showFilterCreateaproposal.bind(this)
  this.showFilterPastopp=this.showFilterPastopp.bind(this)
  this.handleSearchProposal=this.handleSearchProposal.bind(this)
  this.showFilterproposal=this.showFilterproposal.bind(this)
  this.showFilterPastOppSearch=this.showFilterPastOppSearch.bind(this)
  this.handleSearchPast=this.handleSearchPast.bind(this)
  this.showFilterOpp=this.showFilterOpp.bind(this)
  this.showsortOpp=this.showsortOpp.bind(this)
  this.showFilterDropdownOpp=this.showFilterDropdownOpp.bind(this)
  this.showFilterOppViewproposal=this.showFilterOppViewproposal.bind(this)
  this.showFilterDropdownpending=this.showFilterDropdownpending.bind(this)
    this.showFilterOpppending=this.showFilterOpppending.bind(this)
    this.showFilterDropdownpast=this.showFilterDropdownpast.bind(this)
    this.showFilterOpppast=this.showFilterOpppast.bind(this)
  this.showFilterDropdownreject=this.showFilterDropdownreject.bind(this)
    this.showFilterOppreject=this.showFilterOppreject.bind(this)
    this.handleProposalFilter=this.handleProposalFilter.bind(this)
    this.handlePendingFilter=this.handlePendingFilter.bind(this)
}



  showFilterDropdownpending(e){
    if(e.target.value=="0"){
      this.setState({proposal:0,displayallopportunity:3,pendingresult:1,pastresult:0,reject:0,projectfiltersearch:0,})
      const sdata = {
     
        addcpydata:"getsearchcpyPendinginfo",
        userid:this.state.userid,
        sortorder:this.state.sortorder,
        viewinfo:this.state.viewinfo,
        searchtext:this.state.searchtext,
        pagenumber:1,
        showdata:0,
        totalpage:1
    
        
         
        
    
       }
       console.log(sdata)
      // this.setState({DataisLoaded:true,})
      axios
          .post(`https://www.gigeconome.com:5200/api/addcpy_profiledata`,sdata)
          .then(res => {
            //console.log(res)
       
              const data = res.data.result;
              console.log(data)
              this.setState({totalcount:res.data.showingmsg,})
              if(res.data.status==0){
                console.log("empty")
               this.setState({postData:"abc"})
              }
              else{
                
                const slice = data.slice(0, 0 + this.state.perPage)
                const postData = slice.map(e => 
                
                  <React.Fragment>
               
               <div className="row">
              <div className="col-sm-1"></div>
                <div className="col-sm-10 displayallopp">
                 <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Title of the Project: </span>
                  <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"3%"}}>{e.titleofproject}</span><br /><br />
                  <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Date Posted: </span>
                  <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"9%"}}>{e.dateposted}</span>
                  <button className="btn btn-success" onClick={()=>this.ViewDetails(e.postid,e.opportunitytype)} style={{fontFamily:"GothamLight",fontWeight:"bold",color:"white",width:"20%",backgroundColor:"rgb(0,147,69)",float:"right",marginTop:"2%"}}>View Details </button> <br /><br />
                  <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Opportunity Type: </span>
                  <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"4%"}}>{e.opportunitytype}</span><br /><br />
                  <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Status: </span>
                  <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"14.5%"}}>{e.poststatus}</span>
                  </div>  
    
              </div>
              
              
              
              
             
                       
                       
                    
                   </React.Fragment>
                )
      
                if(this.state.showdata==0){
                 this.setState({
                   pageCount: Math.ceil(res.data.totalrow/ res.data.totalcount),
                  
                   postData, DataisLoaded: true,   
               },()=>{
                 console.log(this.state.pageCount)
               })
               }
               else{
                 this.setState({
                   pageCount: Math.ceil(res.data.totalrow/ this.state.showdata),
                  
                   postData, DataisLoaded: true,   
               },()=>{
                 console.log(this.state.pageCount)
               })
               }
           
              }
             
          });
    
     
    }
    
    if(e.target.value=="10"){
     
      this.setState({proposal:0,displayallopportunity:3,pendingresult:1,pastresult:0,reject:0,projectfiltersearch:0,})
      this.setState({showdata:e.target.value,totalcounttwentyfive:e.target.value,pageCount:2,currentPage:0,offset:0,pageCount:""},()=>{
      //  console.log(this.state.offset)
        const sdata = {
    
          addcpydata:"getsearchcpyPendinginfo",
          userid:this.state.userid,
          sortorder:this.state.sortorder,
          viewinfo:this.state.viewinfo,
          searchtext:this.state.searchtext,
          showdata:e.target.value,
          pagenumber:1,
          totalpage:Math.ceil(this.state.abc/ 10)
          
           
          
      
         }
         console.log(sdata)
         //this.setState({DataisLoaded:true})
        axios
            .post(`https://www.gigeconome.com:5200/api/addcpy_profiledata`,sdata)
            .then(res => {
              //console.log(res)
         
                const data = res.data.result;
                console.log(res.data)
                this.setState({totalcount:res.data.showingmsg, perPage:res.data.totalcount})
                if(res.data.status==0){
                  console.log("empty")
                this.setState({postData:"abc"})
                }
                else{
                
                  const slice = data.slice(0, 0 + this.state.perPage)
                  const postData = slice.map(e => 
                  
                    <React.Fragment>
                 
                 <div className="row">
                <div className="col-sm-1"></div>
                  <div className="col-sm-10 displayallopp">
                   <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Title of the Project: </span>
                    <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"3%"}}>{e.titleofproject}</span><br /><br />
                    <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Date Posted: </span>
                    <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"9%"}}>{e.dateposted}</span>
                    <button className="btn btn-success" style={{fontFamily:"GothamLight",fontWeight:"bold",color:"white",width:"20%",backgroundColor:"rgb(0,147,69)",float:"right",marginTop:"2%"}}
                    onClick={()=>this.ViewDetails(e.postid,e.opportunitytype)}
                    >View Details </button> <br /><br />
                    <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Opportunity Type: </span>
                    <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"4%"}}>{e.opportunitytype}</span><br /><br />
                    <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Status: </span>
                    <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"14.5%"}}>{e.poststatus}</span>
                    </div>  
    
                </div>
                
                
                
                
               
                         
                         
                      
                     </React.Fragment>
                  )
        
                  if(this.state.showdata==0){
                    this.setState({
                      pageCount: Math.ceil(res.data.totalrow/ res.data.totalcount),
                     
                      postData, DataisLoaded: true,   
                  },()=>{
                    console.log(this.state.pageCount)
                  })
                  }
                  else{
                    this.setState({
                      pageCount: Math.ceil(res.data.totalrow/ this.state.showdata),
                     
                      postData, DataisLoaded: true,   
                  },()=>{
                    console.log(this.state.pageCount)
                  })
                  }
                }
               
            });
      })
    } 
    if(e.target.value=="25"){
     
      this.setState({proposal:0,displayallopportunity:3,pendingresult:1,pastresult:0,reject:0,projectfiltersearch:0})
        this.setState({showdata:e.target.value,totalcounttwentyfive:e.target.value,pageCount:2,currentPage:0,offset:0,pageCount:""},()=>{
        //  console.log(this.state.offset)
          const sdata = {
      
            addcpydata:"getsearchcpyPendinginfo",
            userid:this.state.userid,
            sortorder:this.state.sortorder,
            viewinfo:this.state.viewinfo,
            searchtext:this.state.searchtext,
            showdata:e.target.value,
            pagenumber:1,
            totalpage:Math.ceil(this.state.abc/ 25)
            
             
            
        
           }
           console.log(sdata)
           //this.setState({DataisLoaded:true})
          axios
              .post(`https://www.gigeconome.com:5200/api/addcpy_profiledata`,sdata)
              .then(res => {
                //console.log(res)
           
                  const data = res.data.result;
                  console.log(res.data)
                  this.setState({totalcount:res.data.showingmsg, perPage:res.data.totalcount})
                  if(res.data.status==0){
                    console.log("empty")
                  this.setState({postData:"abc"})
                  }
                  else{
                  
                    const slice = data.slice(0, 0 + this.state.perPage)
                    const postData = slice.map(e => 
                    
                      <React.Fragment>
                   
                   <div className="row">
                  <div className="col-sm-1"></div>
                    <div className="col-sm-10 displayallopp">
                     <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Title of the Project: </span>
                      <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"3%"}}>{e.titleofproject}</span><br /><br />
                      <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Date Posted: </span>
                      <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"9%"}}>{e.dateposted}</span>
                      <button className="btn btn-success" style={{fontFamily:"GothamLight",fontWeight:"bold",color:"white",width:"20%",backgroundColor:"rgb(0,147,69)",float:"right",marginTop:"2%"}}
                      onClick={()=>this.ViewDetails(e.postid,e.opportunitytype)}
                      >View Details </button> <br /><br />
                      <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Opportunity Type: </span>
                      <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"4%"}}>{e.opportunitytype}</span><br /><br />
                      <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Status: </span>
                      <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"14.5%"}}>{e.poststatus}</span>
                      </div>  
      
                  </div>
                  
                  
                  
                  
                 
                           
                           
                        
                       </React.Fragment>
                    )
          
                    if(this.state.showdata==0){
                      this.setState({
                        pageCount: Math.ceil(res.data.totalrow/ res.data.totalcount),
                       
                        postData, DataisLoaded: true,   
                    },()=>{
                      console.log(this.state.pageCount)
                    })
                    }
                    else{
                      this.setState({
                        pageCount: Math.ceil(res.data.totalrow/ this.state.showdata),
                       
                        postData, DataisLoaded: true,   
                    },()=>{
                      console.log(this.state.pageCount)
                    })
                    }
                  }
                 
              });
        })
      } 
    
    
      if(e.target.value=="50"){
     
        this.setState({proposal:0,displayallopportunity:3,pendingresult:1,pastresult:0,reject:0,projectfiltersearch:0})
          this.setState({showdata:e.target.value,totalcounttwentyfive:e.target.value,pageCount:2,currentPage:0,offset:0,pageCount:""},()=>{
          //  console.log(this.state.offset)
            const sdata = {
        
              addcpydata:"getsearchcpyPendinginfo",
              userid:this.state.userid,
              sortorder:this.state.sortorder,
              viewinfo:this.state.viewinfo,
              searchtext:this.state.searchtext,
              showdata:e.target.value,
              pagenumber:1,
              totalpage:Math.ceil(this.state.abc/ 50)
              
               
              
          
             }
             console.log(sdata)
             //this.setState({DataisLoaded:true})
            axios
                .post(`https://www.gigeconome.com:5200/api/addcpy_profiledata`,sdata)
                .then(res => {
                  //console.log(res)
             
                    const data = res.data.result;
                    console.log(res.data)
                    this.setState({totalcount:res.data.showingmsg, perPage:res.data.totalcount})
                    if(res.data.status==0){
                      console.log("empty")
                    this.setState({postData:"abc"})
                    }
                    else{
                    
                      const slice = data.slice(0, 0 + this.state.perPage)
                      const postData = slice.map(e => 
                      
                        <React.Fragment>
                     
                     <div className="row">
                    <div className="col-sm-1"></div>
                      <div className="col-sm-10 displayallopp">
                       <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Title of the Project: </span>
                        <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"3%"}}>{e.titleofproject}</span><br /><br />
                        <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Date Posted: </span>
                        <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"9%"}}>{e.dateposted}</span>
                        <button className="btn btn-success" style={{fontFamily:"GothamLight",fontWeight:"bold",color:"white",width:"20%",backgroundColor:"rgb(0,147,69)",float:"right",marginTop:"2%"}}
                        onClick={()=>this.ViewDetails(e.postid,e.opportunitytype)}
                        >View Details </button> <br /><br />
                        <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Opportunity Type: </span>
                        <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"4%"}}>{e.opportunitytype}</span><br /><br />
                        <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Status: </span>
                        <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"14.5%"}}>{e.poststatus}</span>
                        </div>  
        
                    </div>
                    
                    
                    
                    
                   
                             
                             
                          
                         </React.Fragment>
                      )
            
                      if(this.state.showdata==0){
                        this.setState({
                          pageCount: Math.ceil(res.data.totalrow/ res.data.totalcount),
                         
                          postData, DataisLoaded: true,   
                      },()=>{
                        console.log(this.state.pageCount)
                      })
                      }
                      else{
                        this.setState({
                          pageCount: Math.ceil(res.data.totalrow/ this.state.showdata),
                         
                          postData, DataisLoaded: true,   
                      },()=>{
                        console.log(this.state.pageCount)
                      })
                      }
                    }
                   
                });
          })
        }
        if(e.target.value=="100"){
     
          this.setState({proposal:0,displayallopportunity:3,pendingresult:1,pastresult:0,reject:0,projectfiltersearch:0})
            this.setState({showdata:e.target.value,totalcounttwentyfive:e.target.value,pageCount:2,currentPage:0,offset:0,pageCount:""},()=>{
            //  console.log(this.state.offset)
              const sdata = {
          
                addcpydata:"getsearchcpyPendinginfo",
                userid:this.state.userid,
                sortorder:this.state.sortorder,
                viewinfo:this.state.viewinfo,
                searchtext:this.state.searchtext,
                showdata:e.target.value,
                pagenumber:1,
                totalpage:Math.ceil(this.state.abc/ 100)
                
                 
                
            
               }
               console.log(sdata)
               //this.setState({DataisLoaded:true})
              axios
                  .post(`https://www.gigeconome.com:5200/api/addcpy_profiledata`,sdata)
                  .then(res => {
                    //console.log(res)
               
                      const data = res.data.result;
                      console.log(res.data)
                      this.setState({totalcount:res.data.showingmsg, perPage:res.data.totalcount})
                      if(res.data.status==0){
                        console.log("empty")
                      this.setState({postData:"abc"})
                      }
                      else{
                      
                        const slice = data.slice(0, 0 + this.state.perPage)
                        const postData = slice.map(e => 
                        
                          <React.Fragment>
                       
                       <div className="row">
                      <div className="col-sm-1"></div>
                        <div className="col-sm-10 displayallopp">
                         <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Title of the Project: </span>
                          <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"3%"}}>{e.titleofproject}</span><br /><br />
                          <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Date Posted: </span>
                          <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"9%"}}>{e.dateposted}</span>
                          <button className="btn btn-success" style={{fontFamily:"GothamLight",fontWeight:"bold",color:"white",width:"20%",backgroundColor:"rgb(0,147,69)",float:"right",marginTop:"2%"}}
                          onClick={()=>this.ViewDetails(e.postid,e.opportunitytype)}
                          >View Details </button> <br /><br />
                          <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Opportunity Type: </span>
                          <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"4%"}}>{e.opportunitytype}</span><br /><br />
                          <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Status: </span>
                          <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"14.5%"}}>{e.poststatus}</span>
                          </div>  
          
                      </div>
                      
                      
                      
                      
                     
                               
                               
                            
                           </React.Fragment>
                        )
              
                        if(this.state.showdata==0){
                          this.setState({
                            pageCount: Math.ceil(res.data.totalrow/ res.data.totalcount),
                           
                            postData, DataisLoaded: true,   
                        },()=>{
                          console.log(this.state.pageCount)
                        })
                        }
                        else{
                          this.setState({
                            pageCount: Math.ceil(res.data.totalrow/ this.state.showdata),
                           
                            postData, DataisLoaded: true,   
                        },()=>{
                          console.log(this.state.pageCount)
                        })
                        }
                      }
                     
                  });
            })
          }  
      }


      showFilterDropdownreject(e){
        if(e.target.value=="0"){
          this.setState({proposal:0,displayallopportunity:5,pendingresult:0,
            pastresult:0,reject:1,postMessage:""})
          const sdata = {
         
            addcpydata:"getsearchcpyRejectinfo",
            userid:this.state.userid,
            sortorder:this.state.sortorder,
            viewinfo:this.state.viewinfo,
            searchtext:this.state.searchtext,
            pagenumber:1,
            showdata:0,
            totalpage:1
        
            
             
            
        
           }
           console.log(sdata)
          // this.setState({DataisLoaded:true,})
          axios
              .post(`https://www.gigeconome.com:5200/api/addcpy_profiledata`,sdata)
              .then(res => {
                //console.log(res)
           
                  const data = res.data.result;
                  console.log(data)
                  this.setState({totalcount:res.data.message})
                  if(res.data.status==0){
                    console.log("empty")
                   this.setState({postData:"abc"})
                  }
                  else{
                    
                    const slice = data.slice(0, 0 + this.state.perPage)
                    const postData = slice.map(e => 
                    
                      <React.Fragment>
                   
                   <div className="row">
                  <div className="col-sm-1"></div>
                    <div className="col-sm-10 displayallopp">
                     <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Title of the Project: </span>
                      <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"3%"}}>{e.titleofproject}</span><br /><br />
                      <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Date Posted: </span>
                      <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"9%"}}>{e.dateposted}</span>
                      <button className="btn btn-success" onClick={()=>this.ViewDetails(e.postid,e.opportunitytype)} style={{fontFamily:"GothamLight",fontWeight:"bold",color:"white",width:"20%",backgroundColor:"rgb(0,147,69)",float:"right",marginTop:"2%"}}>View Details </button> <br /><br />
                      <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Opportunity Type: </span>
                      <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"4%"}}>{e.opportunitytype}</span><br /><br />
                      <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Status: </span>
                      <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"14.5%"}}>{e.poststatus}</span>
                      </div>  
        
                  </div>
                  
                  
                  
                  
                 
                           
                           
                        
                       </React.Fragment>
                    )
          
                    if(this.state.showdata==0){
                     this.setState({
                       pageCount: Math.ceil(res.data.totalrow/ res.data.totalcount),
                      
                       postData, DataisLoaded: true,   
                   },()=>{
                     console.log(this.state.pageCount)
                   })
                   }
                   else{
                     this.setState({
                       pageCount: Math.ceil(res.data.totalrow/ this.state.showdata),
                      
                       postData, DataisLoaded: true,   
                   },()=>{
                     console.log(this.state.pageCount)
                   })
                   }
               
                  }
                 
              });
        
         
        }
        
        if(e.target.value=="10"){
         
          this.setState({proposal:0,displayallopportunity:5,pendingresult:0,
            pastresult:0,reject:1,postMessage:""})
          this.setState({showdata:e.target.value,totalcounttwentyfive:e.target.value,pageCount:2,currentPage:0,offset:0,pageCount:""},()=>{
          //  console.log(this.state.offset)
            const sdata = {
        
              addcpydata:"getsearchcpyRejectinfo",
              userid:this.state.userid,
              sortorder:this.state.sortorder,
              viewinfo:this.state.viewinfo,
              searchtext:this.state.searchtext,
              showdata:e.target.value,
              pagenumber:1,
              totalpage:Math.ceil(this.state.abc/ 10)
              
               
              
          
             }
             console.log(sdata)
             //this.setState({DataisLoaded:true})
            axios
                .post(`https://www.gigeconome.com:5200/api/addcpy_profiledata`,sdata)
                .then(res => {
                  //console.log(res)
             
                    const data = res.data.result;
                    console.log(res.data)
                    this.setState({totalcount:res.data.showingmsg, perPage:res.data.totalcount})
                    if(res.data.status==0){
                      console.log("empty")
                    this.setState({postData:"abc"})
                    }
                    else{
                    
                      const slice = data.slice(0, 0 + this.state.perPage)
                      const postData = slice.map(e => 
                      
                        <React.Fragment>
                     
                     <div className="row">
                    <div className="col-sm-1"></div>
                      <div className="col-sm-10 displayallopp">
                       <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Title of the Project: </span>
                        <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"3%"}}>{e.titleofproject}</span><br /><br />
                        <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Date Posted: </span>
                        <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"9%"}}>{e.dateposted}</span>
                        <button className="btn btn-success" style={{fontFamily:"GothamLight",fontWeight:"bold",color:"white",width:"20%",backgroundColor:"rgb(0,147,69)",float:"right",marginTop:"2%"}}
                        onClick={()=>this.ViewDetails(e.postid,e.opportunitytype)}
                        >View Details </button> <br /><br />
                        <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Opportunity Type: </span>
                        <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"4%"}}>{e.opportunitytype}</span><br /><br />
                        <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Status: </span>
                        <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"14.5%"}}>{e.poststatus}</span>
                        </div>  
        
                    </div>
                    
                    
                    
                    
                   
                             
                             
                          
                         </React.Fragment>
                      )
            
                      if(this.state.showdata==0){
                        this.setState({
                          pageCount: Math.ceil(res.data.totalrow/ res.data.totalcount),
                         
                          postData, DataisLoaded: true,   
                      },()=>{
                        console.log(this.state.pageCount)
                      })
                      }
                      else{
                        this.setState({
                          pageCount: Math.ceil(res.data.totalrow/ this.state.showdata),
                         
                          postData, DataisLoaded: true,   
                      },()=>{
                        console.log(this.state.pageCount)
                      })
                      }
                    }
                   
                });
          })
        } 
        if(e.target.value=="25"){
         
          this.setState({proposal:0,displayallopportunity:5,pendingresult:0,
            pastresult:0,reject:1,postMessage:""})
            this.setState({showdata:e.target.value,totalcounttwentyfive:e.target.value,pageCount:2,currentPage:0,offset:0,pageCount:""},()=>{
            //  console.log(this.state.offset)
              const sdata = {
          
                addcpydata:"getsearchcpyRejectinfo",
                userid:this.state.userid,
                sortorder:this.state.sortorder,
                viewinfo:this.state.viewinfo,
                searchtext:this.state.searchtext,
                showdata:e.target.value,
                pagenumber:1,
                totalpage:Math.ceil(this.state.abc/ 25)
                
                 
                
            
               }
               console.log(sdata)
               //this.setState({DataisLoaded:true})
              axios
                  .post(`https://www.gigeconome.com:5200/api/addcpy_profiledata`,sdata)
                  .then(res => {
                    //console.log(res)
               
                      const data = res.data.result;
                      console.log(res.data)
                      this.setState({totalcount:res.data.showingmsg, perPage:res.data.totalcount})
                      if(res.data.status==0){
                        console.log("empty")
                      this.setState({postData:"abc"})
                      }
                      else{
                      
                        const slice = data.slice(0, 0 + this.state.perPage)
                        const postData = slice.map(e => 
                        
                          <React.Fragment>
                       
                       <div className="row">
                      <div className="col-sm-1"></div>
                        <div className="col-sm-10 displayallopp">
                         <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Title of the Project: </span>
                          <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"3%"}}>{e.titleofproject}</span><br /><br />
                          <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Date Posted: </span>
                          <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"9%"}}>{e.dateposted}</span>
                          <button className="btn btn-success" style={{fontFamily:"GothamLight",fontWeight:"bold",color:"white",width:"20%",backgroundColor:"rgb(0,147,69)",float:"right",marginTop:"2%"}}
                          onClick={()=>this.ViewDetails(e.postid,e.opportunitytype)}
                          >View Details </button> <br /><br />
                          <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Opportunity Type: </span>
                          <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"4%"}}>{e.opportunitytype}</span><br /><br />
                          <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Status: </span>
                          <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"14.5%"}}>{e.poststatus}</span>
                          </div>  
          
                      </div>
                      
                      
                      
                      
                     
                               
                               
                            
                           </React.Fragment>
                        )
              
                        if(this.state.showdata==0){
                          this.setState({
                            pageCount: Math.ceil(res.data.totalrow/ res.data.totalcount),
                           
                            postData, DataisLoaded: true,   
                        },()=>{
                          console.log(this.state.pageCount)
                        })
                        }
                        else{
                          this.setState({
                            pageCount: Math.ceil(res.data.totalrow/ this.state.showdata),
                           
                            postData, DataisLoaded: true,   
                        },()=>{
                          console.log(this.state.pageCount)
                        })
                        }
                      }
                     
                  });
            })
          } 
        
        
          if(e.target.value=="50"){
         
            this.setState({proposal:0,displayallopportunity:5,pendingresult:0,
              pastresult:0,reject:1,postMessage:""})
              this.setState({showdata:e.target.value,totalcounttwentyfive:e.target.value,pageCount:2,currentPage:0,offset:0,pageCount:""},()=>{
              //  console.log(this.state.offset)
                const sdata = {
            
                  addcpydata:"getsearchcpyRejectinfo",
                  userid:this.state.userid,
                  sortorder:this.state.sortorder,
                  viewinfo:this.state.viewinfo,
                  searchtext:this.state.searchtext,
                  showdata:e.target.value,
                  pagenumber:1,
                  totalpage:Math.ceil(this.state.abc/ 50)
                  
                   
                  
              
                 }
                 console.log(sdata)
                 //this.setState({DataisLoaded:true})
                axios
                    .post(`https://www.gigeconome.com:5200/api/addcpy_profiledata`,sdata)
                    .then(res => {
                      //console.log(res)
                 
                        const data = res.data.result;
                        console.log(res.data)
                        this.setState({totalcount:res.data.showingmsg, perPage:res.data.totalcount})
                        if(res.data.status==0){
                          console.log("empty")
                        this.setState({postData:"abc"})

                        }
                        else{
                        
                          const slice = data.slice(0, 0 + this.state.perPage)
                          const postData = slice.map(e => 
                          
                            <React.Fragment>
                         
                         <div className="row">
                        <div className="col-sm-1"></div>
                          <div className="col-sm-10 displayallopp">
                           <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Title of the Project: </span>
                            <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"3%"}}>{e.titleofproject}</span><br /><br />
                            <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Date Posted: </span>
                            <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"9%"}}>{e.dateposted}</span>
                            <button className="btn btn-success" style={{fontFamily:"GothamLight",fontWeight:"bold",color:"white",width:"20%",backgroundColor:"rgb(0,147,69)",float:"right",marginTop:"2%"}}
                            onClick={()=>this.ViewDetails(e.postid,e.opportunitytype)}
                            >View Details </button> <br /><br />
                            <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Opportunity Type: </span>
                            <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"4%"}}>{e.opportunitytype}</span><br /><br />
                            <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Status: </span>
                            <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"14.5%"}}>{e.poststatus}</span>
                            </div>  
            
                        </div>
                        
                        
                        
                        
                       
                                 
                                 
                              
                             </React.Fragment>
                          )
                
                          if(this.state.showdata==0){
                            this.setState({
                              pageCount: Math.ceil(res.data.totalrow/ res.data.totalcount),
                             
                              postData, DataisLoaded: true,   
                          },()=>{
                            console.log(this.state.pageCount)
                          })
                          }
                          else{
                            this.setState({
                              pageCount: Math.ceil(res.data.totalrow/ this.state.showdata),
                             
                              postData, DataisLoaded: true,   
                          },()=>{
                            console.log(this.state.pageCount)
                          })
                          }
                        }
                       
                    });
              })
            }
            if(e.target.value=="100"){
         
              this.setState({proposal:0,displayallopportunity:5,pendingresult:0,
                pastresult:0,reject:1,postMessage:""})
                this.setState({showdata:e.target.value,totalcounttwentyfive:e.target.value,pageCount:2,currentPage:0,offset:0,pageCount:""},()=>{
                //  console.log(this.state.offset)
                  const sdata = {
              
                    addcpydata:"getsearchcpyRejectinfo",
                    userid:this.state.userid,
                    sortorder:this.state.sortorder,
                    viewinfo:this.state.viewinfo,
                    searchtext:this.state.searchtext,
                    showdata:e.target.value,
                    pagenumber:1,
                    totalpage:Math.ceil(this.state.abc/ 100)
                    
                     
                    
                
                   }
                   console.log(sdata)
                   //this.setState({DataisLoaded:true})
                  axios
                      .post(`https://www.gigeconome.com:5200/api/addcpy_profiledata`,sdata)
                      .then(res => {
                        //console.log(res)
                   
                          const data = res.data.result;
                          console.log(res.data)
                          this.setState({totalcount:res.data.showingmsg, perPage:res.data.totalcount})
                          if(res.data.status==0){
                            console.log("empty")
                          this.setState({postData:"abc"})
                          }
                          else{
                          
                            const slice = data.slice(0, 0 + this.state.perPage)
                            const postData = slice.map(e => 
                            
                              <React.Fragment>
                           
                           <div className="row">
                          <div className="col-sm-1"></div>
                            <div className="col-sm-10 displayallopp">
                             <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Title of the Project: </span>
                              <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"3%"}}>{e.titleofproject}</span><br /><br />
                              <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Date Posted: </span>
                              <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"9%"}}>{e.dateposted}</span>
                              <button className="btn btn-success" style={{fontFamily:"GothamLight",fontWeight:"bold",color:"white",width:"20%",backgroundColor:"rgb(0,147,69)",float:"right",marginTop:"2%"}}
                              onClick={()=>this.ViewDetails(e.postid,e.opportunitytype)}
                              >View Details </button> <br /><br />
                              <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Opportunity Type: </span>
                              <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"4%"}}>{e.opportunitytype}</span><br /><br />
                              <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Status: </span>
                              <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"14.5%"}}>{e.poststatus}</span>
                              </div>  
              
                          </div>
                          
                          
                          
                          
                         
                                   
                                   
                                
                               </React.Fragment>
                            )
                  
                            if(this.state.showdata==0){
                              this.setState({
                                pageCount: Math.ceil(res.data.totalrow/ res.data.totalcount),
                               
                                postData, DataisLoaded: true,   
                            },()=>{
                              console.log(this.state.pageCount)
                            })
                            }
                            else{
                              this.setState({
                                pageCount: Math.ceil(res.data.totalrow/ this.state.showdata),
                               
                                postData, DataisLoaded: true,   
                            },()=>{
                              console.log(this.state.pageCount)
                            })
                            }
                          }
                         
                      });
                })
              }  
          }
    
    
      showFilterDropdownpast(e){
        if(e.target.value=="0"){
         
 this.setState({proposal:0,displayallopportunity:4,
  pastresult:1,pendingresult:0,reject:0})
const sdata = {

 
  addcpydata:"getsearchcpyPastinfo",
  userid:this.state.userid,
  sortorder:this.state.sortorder,
  viewinfo:this.state.viewinfo,
  searchtext:this.state.searchtext,
  pagenumber:1,
  showdata:0,
  totalpage:1
        
            
             
            
        
           }
           console.log(sdata)
          // this.setState({DataisLoaded:true,})
          axios
              .post(`https://www.gigeconome.com:5200/api/addcpy_profiledata`,sdata)
              .then(res => {
                //console.log(res)
           
                  const data = res.data.result;
                  console.log(data)
                  this.setState({totalcount:res.data.showingmsg,})
                  if(res.data.status==0){
                    console.log("empty")
                   this.setState({postData:"abc"})
                  }
                  else{
                    
                    const slice = data.slice(0, 0 + this.state.perPage)
                    const postData = slice.map(e => 
                    
                      <React.Fragment>
                   
                   <div className="row">
                  <div className="col-sm-1"></div>
                    <div className="col-sm-10 displayallopp">
                     <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Title of the Project: </span>
                      <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"3%"}}>{e.titleofproject}</span><br /><br />
                      <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Date Posted: </span>
                      <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"9%"}}>{e.dateposted}</span>
                      <button className="btn btn-success" onClick={()=>this.ViewDetails(e.postid,e.opportunitytype)} style={{fontFamily:"GothamLight",fontWeight:"bold",color:"white",width:"20%",backgroundColor:"rgb(0,147,69)",float:"right",marginTop:"2%"}}>View Details </button> <br /><br />
                      <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Opportunity Type: </span>
                      <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"4%"}}>{e.opportunitytype}</span><br /><br />
                      <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Status: </span>
                      <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"14.5%"}}>{e.poststatus}</span>
                      </div>  
        
                  </div>
                  
                  
                  
                  
                 
                           
                           
                        
                       </React.Fragment>
                    )
          
                    if(this.state.showdata==0){
                     this.setState({
                       pageCount: Math.ceil(res.data.totalrow/ res.data.totalcount),
                      
                       postData, DataisLoaded: true,   
                   },()=>{
                     console.log(this.state.pageCount)
                   })
                   }
                   else{
                     this.setState({
                       pageCount: Math.ceil(res.data.totalrow/ this.state.showdata),
                      
                       postData, DataisLoaded: true,   
                   },()=>{
                     console.log(this.state.pageCount)
                   })
                   }
               
                  }
                 
              });
        
         
        }
        
        if(e.target.value=="10"){
         
          this.setState({proposal:0,displayallopportunity:4,
            pastresult:1,pendingresult:0,reject:0})
          this.setState({showdata:e.target.value,totalcounttwentyfive:e.target.value,pageCount:2,currentPage:0,offset:0,pageCount:""},()=>{
          //  console.log(this.state.offset)
            const sdata = {
        
              addcpydata:"getsearchcpyPastinfo",
              userid:this.state.userid,
              sortorder:this.state.sortorder,
              viewinfo:this.state.viewinfo,
              searchtext:this.state.searchtext,
              showdata:e.target.value,
              pagenumber:1,
              totalpage:Math.ceil(this.state.abc/ 10)
              
               
              
          
             }
             console.log(sdata)
             //this.setState({DataisLoaded:true})
            axios
                .post(`https://www.gigeconome.com:5200/api/addcpy_profiledata`,sdata)
                .then(res => {
                  //console.log(res)
             
                    const data = res.data.result;
                    console.log(res.data)
                    this.setState({totalcount:res.data.showingmsg, perPage:res.data.totalcount})
                    if(res.data.status==0){
                      console.log("empty")
                    this.setState({postData:"abc"})
                    this.setState({totalcount:res.data.message})
                    }
                    else{
                    
                      const slice = data.slice(0, 0 + this.state.perPage)
                      const postData = slice.map(e => 
                      
                        <React.Fragment>
                     
                     <div className="row">
                    <div className="col-sm-1"></div>
                      <div className="col-sm-10 displayallopp">
                       <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Title of the Project: </span>
                        <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"3%"}}>{e.titleofproject}</span><br /><br />
                        <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Date Posted: </span>
                        <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"9%"}}>{e.dateposted}</span>
                        <button className="btn btn-success" style={{fontFamily:"GothamLight",fontWeight:"bold",color:"white",width:"20%",backgroundColor:"rgb(0,147,69)",float:"right",marginTop:"2%"}}
                        onClick={()=>this.ViewDetails(e.postid,e.opportunitytype)}
                        >View Details </button> <br /><br />
                        <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Opportunity Type: </span>
                        <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"4%"}}>{e.opportunitytype}</span><br /><br />
                        <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Status: </span>
                        <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"14.5%"}}>{e.poststatus}</span>
                        </div>  
        
                    </div>
                    
                    
                    
                    
                   
                             
                             
                          
                         </React.Fragment>
                      )
            
                      if(this.state.showdata==0){
                        this.setState({
                          pageCount: Math.ceil(res.data.totalrow/ res.data.totalcount),
                         
                          postData, DataisLoaded: true,   
                      },()=>{
                        console.log(this.state.pageCount)
                      })
                      }
                      else{
                        this.setState({
                          pageCount: Math.ceil(res.data.totalrow/ this.state.showdata),
                         
                          postData, DataisLoaded: true,   
                      },()=>{
                        console.log(this.state.pageCount)
                      })
                      }
                    }
                   
                });
          })
        } 
        if(e.target.value=="25"){
         
          this.setState({proposal:0,displayallopportunity:4,
            pastresult:1,pendingresult:0,reject:0})
            this.setState({showdata:e.target.value,totalcounttwentyfive:e.target.value,pageCount:2,currentPage:0,offset:0,pageCount:""},()=>{
            //  console.log(this.state.offset)
              const sdata = {
          
                addcpydata:"getsearchcpyPastinfo",
                userid:this.state.userid,
                sortorder:this.state.sortorder,
                viewinfo:this.state.viewinfo,
                searchtext:this.state.searchtext,
                showdata:e.target.value,
                pagenumber:1,
                totalpage:Math.ceil(this.state.abc/ 25)
                
                 
                
            
               }
               console.log(sdata)
               //this.setState({DataisLoaded:true})
              axios
                  .post(`https://www.gigeconome.com:5200/api/addcpy_profiledata`,sdata)
                  .then(res => {
                    //console.log(res)
               
                      const data = res.data.result;
                      console.log(res.data)
                      this.setState({totalcount:res.data.showingmsg, perPage:res.data.totalcount})
                      if(res.data.status==0){
                        console.log("empty")
                      this.setState({postData:"abc"})
                      }
                      else{
                      
                        const slice = data.slice(0, 0 + this.state.perPage)
                        const postData = slice.map(e => 
                        
                          <React.Fragment>
                       
                       <div className="row">
                      <div className="col-sm-1"></div>
                        <div className="col-sm-10 displayallopp">
                         <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Title of the Project: </span>
                          <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"3%"}}>{e.titleofproject}</span><br /><br />
                          <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Date Posted: </span>
                          <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"9%"}}>{e.dateposted}</span>
                          <button className="btn btn-success" style={{fontFamily:"GothamLight",fontWeight:"bold",color:"white",width:"20%",backgroundColor:"rgb(0,147,69)",float:"right",marginTop:"2%"}}
                          onClick={()=>this.ViewDetails(e.postid,e.opportunitytype)}
                          >View Details </button> <br /><br />
                          <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Opportunity Type: </span>
                          <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"4%"}}>{e.opportunitytype}</span><br /><br />
                          <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Status: </span>
                          <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"14.5%"}}>{e.poststatus}</span>
                          </div>  
          
                      </div>
                      
                      
                      
                      
                     
                               
                               
                            
                           </React.Fragment>
                        )
              
                        if(this.state.showdata==0){
                          this.setState({
                            pageCount: Math.ceil(res.data.totalrow/ res.data.totalcount),
                           
                            postData, DataisLoaded: true,   
                        },()=>{
                          console.log(this.state.pageCount)
                        })
                        }
                        else{
                          this.setState({
                            pageCount: Math.ceil(res.data.totalrow/ this.state.showdata),
                           
                            postData, DataisLoaded: true,   
                        },()=>{
                          console.log(this.state.pageCount)
                        })
                        }
                      }
                     
                  });
            })
          } 
        
        
          if(e.target.value=="50"){
         
            this.setState({proposal:0,displayallopportunity:4,
              pastresult:1,pendingresult:0,reject:0})
              this.setState({showdata:e.target.value,totalcounttwentyfive:e.target.value,pageCount:2,currentPage:0,offset:0,pageCount:""},()=>{
              //  console.log(this.state.offset)
                const sdata = {
            
                  addcpydata:"getsearchcpyPastinfo",
                  userid:this.state.userid,
                  sortorder:this.state.sortorder,
                  viewinfo:this.state.viewinfo,
                  searchtext:this.state.searchtext,
                  showdata:e.target.value,
                  pagenumber:1,
                  totalpage:Math.ceil(this.state.abc/ 50)
                  
                   
                  
              
                 }
                 console.log(sdata)
                 //this.setState({DataisLoaded:true})
                axios
                    .post(`https://www.gigeconome.com:5200/api/addcpy_profiledata`,sdata)
                    .then(res => {
                      //console.log(res)
                 
                        const data = res.data.result;
                        console.log(res.data)
                        this.setState({totalcount:res.data.showingmsg, perPage:res.data.totalcount})
                        if(res.data.status==0){
                          console.log("empty")
                        this.setState({postData:"abc"})
                        }
                        else{
                        
                          const slice = data.slice(0, 0 + this.state.perPage)
                          const postData = slice.map(e => 
                          
                            <React.Fragment>
                         
                         <div className="row">
                        <div className="col-sm-1"></div>
                          <div className="col-sm-10 displayallopp">
                           <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Title of the Project: </span>
                            <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"3%"}}>{e.titleofproject}</span><br /><br />
                            <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Date Posted: </span>
                            <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"9%"}}>{e.dateposted}</span>
                            <button className="btn btn-success" style={{fontFamily:"GothamLight",fontWeight:"bold",color:"white",width:"20%",backgroundColor:"rgb(0,147,69)",float:"right",marginTop:"2%"}}
                            onClick={()=>this.ViewDetails(e.postid,e.opportunitytype)}
                            >View Details </button> <br /><br />
                            <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Opportunity Type: </span>
                            <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"4%"}}>{e.opportunitytype}</span><br /><br />
                            <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Status: </span>
                            <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"14.5%"}}>{e.poststatus}</span>
                            </div>  
            
                        </div>
                        
                        
                        
                        
                       
                                 
                                 
                              
                             </React.Fragment>
                          )
                
                          if(this.state.showdata==0){
                            this.setState({
                              pageCount: Math.ceil(res.data.totalrow/ res.data.totalcount),
                             
                              postData, DataisLoaded: true,   
                          },()=>{
                            console.log(this.state.pageCount)
                          })
                          }
                          else{
                            this.setState({
                              pageCount: Math.ceil(res.data.totalrow/ this.state.showdata),
                             
                              postData, DataisLoaded: true,   
                          },()=>{
                            console.log(this.state.pageCount)
                          })
                          }
                        }
                       
                    });
              })
            }
            if(e.target.value=="100"){
         
            
 this.setState({proposal:0,displayallopportunity:4,
  pastresult:1,pendingresult:0,reject:0})
                this.setState({showdata:e.target.value,totalcounttwentyfive:e.target.value,pageCount:2,currentPage:0,offset:0,pageCount:""},()=>{
                //  console.log(this.state.offset)
                  const sdata = {
              
                    addcpydata:"getsearchcpyPastinfo",
                    userid:this.state.userid,
                    sortorder:this.state.sortorder,
                    viewinfo:this.state.viewinfo,
                    searchtext:this.state.searchtext,
                    showdata:e.target.value,
                    pagenumber:1,
                    totalpage:Math.ceil(this.state.abc/ 100)
                    
                     
                    
                
                   }
                   console.log(sdata)
                   //this.setState({DataisLoaded:true})
                  axios
                      .post(`https://www.gigeconome.com:5200/api/addcpy_profiledata`,sdata)
                      .then(res => {
                        //console.log(res)
                   
                          const data = res.data.result;
                          console.log(res.data)
                          this.setState({totalcount:res.data.showingmsg, perPage:res.data.totalcount})
                          if(res.data.status==0){
                            console.log("empty")
                          this.setState({postData:"abc"})
                          }
                          else{
                          
                            const slice = data.slice(0, 0 + this.state.perPage)
                            const postData = slice.map(e => 
                            
                              <React.Fragment>
                           
                           <div className="row">
                          <div className="col-sm-1"></div>
                            <div className="col-sm-10 displayallopp">
                             <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Title of the Project: </span>
                              <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"3%"}}>{e.titleofproject}</span><br /><br />
                              <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Date Posted: </span>
                              <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"9%"}}>{e.dateposted}</span>
                              <button className="btn btn-success" style={{fontFamily:"GothamLight",fontWeight:"bold",color:"white",width:"20%",backgroundColor:"rgb(0,147,69)",float:"right",marginTop:"2%"}}
                              onClick={()=>this.ViewDetails(e.postid,e.opportunitytype)}
                              >View Details </button> <br /><br />
                              <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Opportunity Type: </span>
                              <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"4%"}}>{e.opportunitytype}</span><br /><br />
                              <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Status: </span>
                              <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"14.5%"}}>{e.poststatus}</span>
                              </div>  
              
                          </div>
                          
                          
                          
                          
                         
                                   
                                   
                                
                               </React.Fragment>
                            )
                  
                            if(this.state.showdata==0){
                              this.setState({
                                pageCount: Math.ceil(res.data.totalrow/ res.data.totalcount),
                               
                                postData, DataisLoaded: true,   
                            },()=>{
                              console.log(this.state.pageCount)
                            })
                            }
                            else{
                              this.setState({
                                pageCount: Math.ceil(res.data.totalrow/ this.state.showdata),
                               
                                postData, DataisLoaded: true,   
                            },()=>{
                              console.log(this.state.pageCount)
                            })
                            }
                          }
                         
                      });
                })
              }  
          }
  showFilterOppViewproposal(e){
    this.setState({proposal:1,
      projectfiltersearch:0,
      displayallopportunity:2,pendingresult:0,pastresult:0,reject:0,viewinfo:e.target.value})
    const sdata = {
 
     addcpydata:"getsearchcpyopen4proposalinfo",
     userid:this.state.userid,
     sortorder:this.state.sortorder,
     viewinfo:e.target.value,
     searchtext:this.state.searchtext,
     pagenumber:this.state.currentPage+1,
     showdata:this.state.showdata,
     totalpage:this.state.pageCount

     
      
     
 
    }
    console.log(sdata)
   // this.setState({DataisLoaded:true,})
   axios
       .post(`https://www.gigeconome.com:5200/api/addcpy_profiledata`,sdata)
       .then(res => {
         //console.log(res)
    
           const data = res.data.result;
           //console.log(data)
           
           if(res.data.status==0){
             //console.log("empty")
            this.setState({postData:"abc"})
            this.setState({totalcount:res.data.message,})
           }
           else{
            this.setState({totalcount:res.data.showingmsg,})
             const slice = data.slice(0, 0 + this.state.perPage)
             const postData = slice.map(e => 
             
               <React.Fragment>
            
            <div className="row">
           <div className="col-sm-1"></div>
             <div className="col-sm-10 displayallopp">
              <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Title of the Project: </span>
               <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"3%"}}>{e.titleofproject}</span><br /><br />
               <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Date Posted: </span>
               <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"9%"}}>{e.dateposted}</span>
               <button className="btn btn-success" onClick={()=>this.ViewDetails(e.postid,e.opportunitytype)} style={{fontFamily:"GothamLight",fontWeight:"bold",color:"white",width:"20%",backgroundColor:"rgb(0,147,69)",float:"right",marginTop:"2%"}}>View Details </button> <br /><br />
               <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Opportunity Type: </span>
               <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"4%"}}>{e.opportunitytype}</span><br /><br />
               <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Status: </span>
               <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"14.5%"}}>{e.poststatus}</span>
               </div>  

           </div>
           
           
           
           
          
                    
                    
                 
                </React.Fragment>
             )
   
             if(this.state.showdata==0){
              this.setState({
                pageCount: Math.ceil(res.data.totalrow/ res.data.totalcount),
               
                postData, DataisLoaded: true,   
            },()=>{
              console.log(this.state.pageCount)
            })
            }
            else{
              this.setState({
                pageCount: Math.ceil(res.data.totalrow/ this.state.showdata),
               
                postData, DataisLoaded: true,   
            },()=>{
              console.log(this.state.pageCount)
            })
            }
        
           }
          
       });
  }


  showFilterOpppending(e){
    this.setState({proposal:0,displayallopportunity:3,pendingresult:1,pastresult:0,reject:0,viewinfo:e.target.value})
    const sdata = {
 
     addcpydata:"getsearchcpyPendinginfo",
     userid:this.state.userid,
     sortorder:this.state.sortorder,
     viewinfo:e.target.value,
     searchtext:this.state.searchtext,
     pagenumber:this.state.currentPage+1,
     showdata:this.state.showdata,
     totalpage:this.state.pageCount

     
      
     
 
    }
    console.log(sdata)
   // this.setState({DataisLoaded:true,})
   axios
       .post(`https://www.gigeconome.com:5200/api/addcpy_profiledata`,sdata)
       .then(res => {
         //console.log(res)
    
           const data = res.data.result;
           console.log(data)
           
           if(res.data.status==0){
             console.log("empty")
            this.setState({postData:"abc"})
            this.setState({totalcount:res.data.message,})
           }
           else{
            this.setState({totalcount:res.data.showingmsg,})
             const slice = data.slice(0, 0 + this.state.perPage)
             const postData = slice.map(e => 
             
               <React.Fragment>
            
            <div className="row">
           <div className="col-sm-1"></div>
             <div className="col-sm-10 displayallopp">
              <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Title of the Project: </span>
               <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"3%"}}>{e.titleofproject}</span><br /><br />
               <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Date Posted: </span>
               <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"9%"}}>{e.dateposted}</span>
               <button className="btn btn-success" onClick={()=>this.ViewDetails(e.postid,e.opportunitytype)} style={{fontFamily:"GothamLight",fontWeight:"bold",color:"white",width:"20%",backgroundColor:"rgb(0,147,69)",float:"right",marginTop:"2%"}}>View Details </button> <br /><br />
               <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Opportunity Type: </span>
               <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"4%"}}>{e.opportunitytype}</span><br /><br />
               <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Status: </span>
               <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"14.5%"}}>{e.poststatus}</span>
               </div>  

           </div>
           
           
           
           
          
                    
                    
                 
                </React.Fragment>
             )
   
             if(this.state.showdata==0){
              this.setState({
                pageCount: Math.ceil(res.data.totalrow/ res.data.totalcount),
               
                postData, DataisLoaded: true,   
            },()=>{
              console.log(this.state.pageCount)
            })
            }
            else{
              this.setState({
                pageCount: Math.ceil(res.data.totalrow/ this.state.showdata),
               
                postData, DataisLoaded: true,   
            },()=>{
              console.log(this.state.pageCount)
            })
            }
        
           }
          
       });
  }



  showFilterOpppast(e){
    this.setState({proposal:0,displayallopportunity:4,
      pastresult:1,pendingresult:0,reject:0,viewinfo:e.target.value})
    const sdata = {
 
     addcpydata:"getsearchcpyPastinfo",
     userid:this.state.userid,
     sortorder:this.state.sortorder,
     viewinfo:e.target.value,
     searchtext:this.state.searchtext,
     pagenumber:this.state.currentPage+1,
     showdata:this.state.showdata,
     totalpage:this.state.pageCount

     
      
     
 
    }
    console.log(sdata)
   // this.setState({DataisLoaded:true,})
   axios
       .post(`https://www.gigeconome.com:5200/api/addcpy_profiledata`,sdata)
       .then(res => {
         //console.log(res)
    
           const data = res.data.result;
           console.log(data)
          
           if(res.data.status==0){
            // console.log("empty")
            this.setState({postData:"abc"})
            this.setState({totalcount:res.data.message,})
           }
           else{
            this.setState({totalcount:res.data.showingmsg,})
             const slice = data.slice(0, 0 + this.state.perPage)
             const postData = slice.map(e => 
             
               <React.Fragment>
            
            <div className="row">
           <div className="col-sm-1"></div>
             <div className="col-sm-10 displayallopp">
              <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Title of the Project: </span>
               <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"3%"}}>{e.titleofproject}</span><br /><br />
               <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Date Posted: </span>
               <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"9%"}}>{e.dateposted}</span>
               <button className="btn btn-success" onClick={()=>this.ViewDetails(e.postid,e.opportunitytype)} style={{fontFamily:"GothamLight",fontWeight:"bold",color:"white",width:"20%",backgroundColor:"rgb(0,147,69)",float:"right",marginTop:"2%"}}>View Details </button> <br /><br />
               <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Opportunity Type: </span>
               <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"4%"}}>{e.opportunitytype}</span><br /><br />
               <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Status: </span>
               <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"14.5%"}}>{e.poststatus}</span>
               </div>  

           </div>
           
           
           
           
          
                    
                    
                 
                </React.Fragment>
             )
   
             if(this.state.showdata==0){
              this.setState({
                pageCount: Math.ceil(res.data.totalrow/ res.data.totalcount),
               
                postData, DataisLoaded: true,   
            },()=>{
              console.log(this.state.pageCount)
            })
            }
            else{
              this.setState({
                pageCount: Math.ceil(res.data.totalrow/ this.state.showdata),
               
                postData, DataisLoaded: true,   
            },()=>{
              console.log(this.state.pageCount)
            })
            }
        
           }
          
       });
  }

  showFilterOppreject(e){
    this.setState({proposal:0,displayallopportunity:5,pendingresult:0,
      pastresult:0,reject:1,postMessage:"",viewinfo:e.target.value})
    const sdata = {
 
     addcpydata:"getsearchcpyRejectinfo",
     userid:this.state.userid,
     sortorder:this.state.sortorder,
     viewinfo:e.target.value,
     searchtext:this.state.searchtext,
     pagenumber:this.state.currentPage+1,
     showdata:this.state.showdata,
     totalpage:this.state.pageCount

     
      
     
 
    }
    console.log(sdata)
   // this.setState({DataisLoaded:true,})
   axios
       .post(`https://www.gigeconome.com:5200/api/addcpy_profiledata`,sdata)
       .then(res => {
         //console.log(res)
    
           const data = res.data.result;
           console.log(data)
          
           if(res.data.status==0){
             console.log("empty")
            this.setState({postData:"abc"})
            this.setState({totalcount:res.data.message,})
           }
           else{
            this.setState({totalcount:res.data.showingmsg,})
             const slice = data.slice(0, 0 + this.state.perPage)
             const postData = slice.map(e => 
             
               <React.Fragment>
            
            <div className="row">
           <div className="col-sm-1"></div>
             <div className="col-sm-10 displayallopp">
              <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Title of the Project: </span>
               <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"3%"}}>{e.titleofproject}</span><br /><br />
               <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Date Posted: </span>
               <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"9%"}}>{e.dateposted}</span>
               <button className="btn btn-success" onClick={()=>this.ViewDetails(e.postid,e.opportunitytype)} style={{fontFamily:"GothamLight",fontWeight:"bold",color:"white",width:"20%",backgroundColor:"rgb(0,147,69)",float:"right",marginTop:"2%"}}>View Details </button> <br /><br />
               <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Opportunity Type: </span>
               <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"4%"}}>{e.opportunitytype}</span><br /><br />
               <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Status: </span>
               <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"14.5%"}}>{e.poststatus}</span>
               </div>  

           </div>
           
           
           
           
          
                    
                    
                 
                </React.Fragment>
             )
   
             if(this.state.showdata==0){
              this.setState({
                pageCount: Math.ceil(res.data.totalrow/ res.data.totalcount),
               
                postData, DataisLoaded: true,   
            },()=>{
              console.log(this.state.pageCount)
            })
            }
            else{
              this.setState({
                pageCount: Math.ceil(res.data.totalrow/ this.state.showdata),
               
                postData, DataisLoaded: true,   
            },()=>{
              console.log(this.state.pageCount)
            })
            }
        
           }
          
       });
  }
  showFilterDropdownOpp(e){
    //console.log(e.target.value)
    //this.setState({perpage:0,offset:"",totalcounttwentyfive:"",currentPage:""})
   if(e.target.value==0){
  window.location.reload()
    const sdata = {
  
      addcpydata:"getsearchcpyallopportunitiesinfo",
      userid:this.state.userid,
      sortorder:this.state.sortorder,
      viewinfo:this.state.viewinfo,
      searchtext:this.state.searchtext,
      showdata:this.state.showdata,
      pagenumber:this.state.currentPage+1,
      totalpage:this.state.pageCount
      
       
      
  
     }
     console.log(sdata)
     //this.setState({DataisLoaded:true})
    axios
        .post(`https://www.gigeconome.com:5200/api/addcpy_profiledata`,sdata)
        .then(res => {
          //console.log(res)
     
            const data = res.data.result;
            console.log(res.data)
            this.setState({totalcount:res.data.showingmsg,perPage:res.data.totalcount})
            if(res.data.status==0){
              console.log("empty")
              this.setState({displayallopportunity:1,apiopp:1})
            }
            else{
              this.setState({displayallopportunity:0,proposal:0})
              const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
              const postData = slice.map(e => 
              
                <React.Fragment>
             
             <div className="row">
            <div className="col-sm-1"></div>
              <div className="col-sm-10 displayallopp">
               <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Title of the Project: </span>
                <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"3%"}}>{e.titleofproject}</span><br /><br />
                <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Date Posted: </span>
                <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"9%"}}>{e.dateposted}</span>
                <button className="btn btn-success" style={{fontFamily:"GothamLight",fontWeight:"bold",color:"white",width:"20%",backgroundColor:"rgb(0,147,69)",float:"right",marginTop:"2%"}}
                onClick={()=>this.ViewDetails(e.postid,e.opportunitytype)}
                >View Details </button> <br /><br />
                <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Opportunity Type: </span>
                <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"4%"}}>{e.opportunitytype}</span><br /><br />
                <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Status: </span>
                <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"14.5%"}}>{e.poststatus}</span>
                </div>  

            </div>
            
            
            
            
           
                     
                     
                  
                 </React.Fragment>
              )
    
              this.setState({
                pageCount: Math.ceil(res.data.totalrow / res.data.totalcount),
                 
                  postData, DataisLoaded: true,   
              })
            }
           
        });
      
   
   }
    
  if(e.target.value=="10"){
this.setState({offset:0,pageCount:0,currentPage:1,showPagination:10})
    
      const sdata = {
  
        addcpydata:"getsearchcpyallopportunitiesinfo",
        userid:this.state.userid,
        sortorder:this.state.sortorder,
        viewinfo:this.state.viewinfo,
        searchtext:this.state.searchtext,
        showdata:e.target.value,
        pagenumber:1,
        totalpage:Math.ceil(this.state.abc/ 10)
        
         
        
    
       }
       console.log(sdata)
       //this.setState({DataisLoaded:true})
      axios
          .post(`https://www.gigeconome.com:5200/api/addcpy_profiledata`,sdata)
          .then(res => {
            //console.log(res)
       
              const data = res.data.result;
           //   console.log(res.data)
              this.setState({totalcount:res.data.showingmsg,showdata:e.target.value})
              if(res.data.status==0){
                console.log("empty")
                this.setState({displayallopportunity:1,apiopp:1})
              }
              else{
                this.setState({displayallopportunity:0,proposal:0})
                const slice = data.slice(0, 0 + this.state.perPage)
                console.log(slice)
                const postData = slice.map(e => 
                
                  <React.Fragment>
               
               <div className="row">
              <div className="col-sm-1"></div>
                <div className="col-sm-10 displayallopp">
                 <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Title of the Project: </span>
                  <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"3%"}}>{e.titleofproject}</span><br /><br />
                  <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Date Posted: </span>
                  <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"9%"}}>{e.dateposted}</span>
                  <button className="btn btn-success" style={{fontFamily:"GothamLight",fontWeight:"bold",color:"white",width:"20%",backgroundColor:"rgb(0,147,69)",float:"right",marginTop:"2%"}}
                  onClick={()=>this.ViewDetails(e.postid,e.opportunitytype)}
                  >View Details </button> <br /><br />
                  <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Opportunity Type: </span>
                  <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"4%"}}>{e.opportunitytype}</span><br /><br />
                  <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Status: </span>
                  <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"14.5%"}}>{e.poststatus}</span>
                  </div>  
  
              </div>
              
              
              
              
             
                       
                       
                    
                   </React.Fragment>
                )
      
                if(this.state.showdata==0){
                  this.setState({
                    pageCount: Math.ceil(res.data.totalrow/ res.data.totalcount),
                   
                    postData, DataisLoaded: true,   
                },()=>{
                  console.log(this.state.pageCount)
                })
                }
                else{
                  this.setState({
                    pageCount: Math.ceil(res.data.totalrow/ this.state.showdata),
                   
                    postData, DataisLoaded: true,   
                },()=>{
                  console.log(this.state.pageCount)
                })
                }
              }
             
          });
    }
   
 if(e.target.value=="25"){

      this.setState({showdata:e.target.value,totalcounttwentyfive:e.target.value,offset:0,pageCount:""},()=>{
      //  console.log(this.state.offset)
        const sdata = {
  
          addcpydata:"getsearchcpyallopportunitiesinfo",
          userid:this.state.userid,
          sortorder:this.state.sortorder,
          viewinfo:this.state.viewinfo,
          searchtext:this.state.searchtext,
          showdata:e.target.value,
          pagenumber:1,
          totalpage:Math.ceil(this.state.abc/ 25)
          
           
          
      
         }
         console.log(sdata)
         //this.setState({DataisLoaded:true})
        axios
            .post(`https://www.gigeconome.com:5200/api/addcpy_profiledata`,sdata)
            .then(res => {
              //console.log(res)
         
                const data = res.data.result;
                console.log(res.data)
                this.setState({totalcount:res.data.showingmsg, perPage:res.data.totalcount})
                if(res.data.status==0){
                  console.log("empty")
                  this.setState({displayallopportunity:1,apiopp:1})
                }
                else{
                  this.setState({displayallopportunity:0,proposal:0})
                  const slice = data.slice(0, 0 + this.state.perPage)
                  const postData = slice.map(e => 
                  
                    <React.Fragment>
                 
                 <div className="row">
                <div className="col-sm-1"></div>
                  <div className="col-sm-10 displayallopp">
                   <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Title of the Project: </span>
                    <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"3%"}}>{e.titleofproject}</span><br /><br />
                    <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Date Posted: </span>
                    <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"9%"}}>{e.dateposted}</span>
                    <button className="btn btn-success" style={{fontFamily:"GothamLight",fontWeight:"bold",color:"white",width:"20%",backgroundColor:"rgb(0,147,69)",float:"right",marginTop:"2%"}}
                    onClick={()=>this.ViewDetails(e.postid,e.opportunitytype)}
                    >View Details </button> <br /><br />
                    <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Opportunity Type: </span>
                    <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"4%"}}>{e.opportunitytype}</span><br /><br />
                    <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Status: </span>
                    <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"14.5%"}}>{e.poststatus}</span>
                    </div>  
    
                </div>
                
                
                
                
               
                         
                         
                      
                     </React.Fragment>
                  )
        
                  if(this.state.showdata==0){
                    this.setState({
                      pageCount: Math.ceil(res.data.totalrow/ res.data.totalcount),
                     
                      postData, DataisLoaded: true,   
                  },()=>{
                    console.log(this.state.pageCount)
                  })
                  }
                  else{
                    this.setState({
                      pageCount: Math.ceil(res.data.totalrow/ this.state.showdata),
                    
                      postData, DataisLoaded: true,   
                  },()=>{
                    console.log(this.state.pageCount)
                   // console.log(this.state.currentPage)
                  })
                  }
                }
               
            });
      })
    } 
    
    /* else if(e.target.value=="25"){
      //console.log(this.state.offset)
      this.setState({totalcounttwentyfive:"",offset:"",currentPage:"",perpage:""})
      this.setState({perPage:e.target.value,totalcounttwentyfive:e.target.value},()=>{
        console.log(this.state.perPage) 
        this.receivedData()
      })
    } */
   
    if(e.target.value=="50"){
      this.setState({showdata:e.target.value,totalcounttwentyfive:e.target.value,offset:0,pageCount:""},()=>{
        //  console.log(this.state.offset)
          const sdata = {
    
            addcpydata:"getsearchcpyallopportunitiesinfo",
            userid:this.state.userid,
            sortorder:this.state.sortorder,
            viewinfo:this.state.viewinfo,
            searchtext:this.state.searchtext,
            showdata:e.target.value,
            pagenumber:1,
            totalpage:Math.ceil(this.state.abc/ 50)
            
             
            
        
           }
           console.log(sdata)
           //this.setState({DataisLoaded:true})
          axios
              .post(`https://www.gigeconome.com:5200/api/addcpy_profiledata`,sdata)
              .then(res => {
                //console.log(res)
           
                  const data = res.data.result;
                  console.log(res.data)
                  this.setState({totalcount:res.data.showingmsg, perPage:res.data.totalcount})
                  if(res.data.status==0){
                    console.log("empty")
                    this.setState({displayallopportunity:1,apiopp:1})
                  }
                  else{
                    this.setState({displayallopportunity:0,proposal:0})
                    const slice = data.slice(0, 0 + this.state.perPage)
                    const postData = slice.map(e => 
                    
                      <React.Fragment>
                   
                   <div className="row">
                  <div className="col-sm-1"></div>
                    <div className="col-sm-10 displayallopp">
                     <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Title of the Project: </span>
                      <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"3%"}}>{e.titleofproject}</span><br /><br />
                      <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Date Posted: </span>
                      <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"9%"}}>{e.dateposted}</span>
                      <button className="btn btn-success" style={{fontFamily:"GothamLight",fontWeight:"bold",color:"white",width:"20%",backgroundColor:"rgb(0,147,69)",float:"right",marginTop:"2%"}}
                      onClick={()=>this.ViewDetails(e.postid,e.opportunitytype)}
                      >View Details </button> <br /><br />
                      <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Opportunity Type: </span>
                      <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"4%"}}>{e.opportunitytype}</span><br /><br />
                      <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Status: </span>
                      <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"14.5%"}}>{e.poststatus}</span>
                      </div>  
      
                  </div>
                  
                  
                  
                  
                 
                           
                           
                        
                       </React.Fragment>
                    )
          
                    if(this.state.showdata==0){
                      this.setState({
                        pageCount: Math.ceil(res.data.totalrow/ res.data.totalcount),
                       
                        postData, DataisLoaded: true,   
                    },()=>{
                      console.log(this.state.pageCount)
                    })
                    }
                    else{
                      this.setState({
                        pageCount: Math.ceil(res.data.totalrow/ this.state.showdata),
                      
                        postData, DataisLoaded: true,   
                    },()=>{
                      console.log(this.state.pageCount)
                     // console.log(this.state.currentPage)
                    })
                    }
                  }
                 
              });
        })
    }
   if(e.target.value=="100"){
    this.setState({showdata:e.target.value,totalcounttwentyfive:e.target.value,offset:0,pageCount:""},()=>{
      //  console.log(this.state.offset)
        const sdata = {
  
          addcpydata:"getsearchcpyallopportunitiesinfo",
          userid:this.state.userid,
          sortorder:this.state.sortorder,
          viewinfo:this.state.viewinfo,
          searchtext:this.state.searchtext,
          showdata:e.target.value,
          pagenumber:1,
          totalpage:Math.ceil(this.state.abc/ 100)
          
           
          
      
         }
         console.log(sdata)
         //this.setState({DataisLoaded:true})
        axios
            .post(`https://www.gigeconome.com:5200/api/addcpy_profiledata`,sdata)
            .then(res => {
              //console.log(res)
         
                const data = res.data.result;
                console.log(res.data)
                this.setState({totalcount:res.data.showingmsg, perPage:res.data.totalcount})
                if(res.data.status==0){
                  console.log("empty")
                  
                  this.setState({displayallopportunity:1,apiopp:1})
                }
                else{
                  this.setState({displayallopportunity:0,proposal:0})
                  const slice = data.slice(0, 0 + this.state.perPage)
                  const postData = slice.map(e => 
                  
                    <React.Fragment>
                 
                 <div className="row">
                <div className="col-sm-1"></div>
                  <div className="col-sm-10 displayallopp">
                   <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Title of the Project: </span>
                    <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"3%"}}>{e.titleofproject}</span><br /><br />
                    <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Date Posted: </span>
                    <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"9%"}}>{e.dateposted}</span>
                    <button className="btn btn-success" style={{fontFamily:"GothamLight",fontWeight:"bold",color:"white",width:"20%",backgroundColor:"rgb(0,147,69)",float:"right",marginTop:"2%"}}
                    onClick={()=>this.ViewDetails(e.postid,e.opportunitytype)}
                    >View Details </button> <br /><br />
                    <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Opportunity Type: </span>
                    <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"4%"}}>{e.opportunitytype}</span><br /><br />
                    <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Status: </span>
                    <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"14.5%"}}>{e.poststatus}</span>
                    </div>  
    
                </div>
                
                
                
                
               
                         
                         
                      
                     </React.Fragment>
                  )
        
                  if(this.state.showdata==0){
                    this.setState({
                      pageCount: Math.ceil(res.data.totalrow/ res.data.totalcount),
                     
                      postData, DataisLoaded: true,   
                  },()=>{
                    console.log(this.state.pageCount)
                  })
                  }
                  else{
                    this.setState({
                      pageCount: Math.ceil(res.data.totalrow/ this.state.showdata),
                    
                      postData, DataisLoaded: true,   
                  },()=>{
                    console.log(this.state.pageCount)
                   // console.log(this.state.currentPage)
                  })
                  }
                }
               
            });
      })
    }
  }
  showsortOpp(e){
    if(this.state.displayallopportunity==0){
    //  console.log(this.state.currentPage)
      this.setState({sortorder:e.target.value,alloppsearchbar:1},()=>{
        const sdata = {
     
          addcpydata:"getsearchcpyallopportunitiesinfo",
          userid:this.state.userid,
          sortorder:this.state.sortorder,
          viewinfo:this.state.viewinfo,
          searchtext:this.state.searchtext,
          showdata:this.state.showdata,
          pagenumber:this.state.pagenumber,
          totalpage:this.state.pageCount
         
           
          
      
         }
         console.log(sdata)
        // this.setState({DataisLoaded:true,})
        axios
            .post(`https://www.gigeconome.com:5200/api/addcpy_profiledata`,sdata)
            .then(res => {
              //console.log(res)
         
                const data = res.data.result;
                console.log(data)
                this.setState({totalcount:res.data.showingmsg})
                if(res.data.status==0){
               
                  this.setState({totalcount:res.data.message,postData:"abc"})
                }
                else{
                  
                  const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
                  const postData = slice.map(e => 
                  
                    <React.Fragment>
                 
                 <div className="row">
                <div className="col-sm-1"></div>
                  <div className="col-sm-10 displayallopp">
                   <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Title of the Project: </span>
                    <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"3%"}}>{e.titleofproject}</span><br /><br />
                    <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Date Posted: </span>
                    <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"9%"}}>{e.dateposted}</span>
                    <button  onClick={()=>this.ViewDetails(e.postid,e.opportunitytype)} className="btn btn-success" style={{fontFamily:"GothamLight",fontWeight:"bold",color:"white",width:"20%",backgroundColor:"rgb(0,147,69)",float:"right",marginTop:"2%"}}>View Details </button> <br /><br />
                    <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Opportunity Type: </span>
                    <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"4%"}}>{e.opportunitytype}</span><br /><br />
                    <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Status: </span>
                    <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"14.5%"}}>{e.poststatus}</span>
                    </div>  
      
                </div>
                
                
                
                
               
                         
                         
                      
                     </React.Fragment>
                  )
        
                  this.setState({
                      pageCount: Math.ceil(res.data.totalrow/ res.data.totalcount),
                     
                      postData, DataisLoaded: true,   
                  })
                }
               
            });
      })
     
    }
    if(this.state.proposal==1){
      this.setState({sortorder:e.target.value,alloppsearchbar:1},()=>{
        const sdata = {
     
          addcpydata:"getsearchcpyopen4proposalinfo",
          userid:this.state.userid,
          sortorder:this.state.sortorder,
          viewinfo:this.state.viewinfo,
          searchtext:this.state.searchtext,
          showdata:this.state.showdata,
          pagenumber:this.state.pagenumber,
          totalpage:this.state.pageCount
         
           
          
      
         }
         console.log(sdata)
        // this.setState({DataisLoaded:true,})
        axios
            .post(`https://www.gigeconome.com:5200/api/addcpy_profiledata`,sdata)
            .then(res => {
              //console.log(res)
         
                const data = res.data.result;
                console.log(data)
                this.setState({totalcount:res.data.showingmsg})
                if(res.data.status==0){
                  console.log("empty")
                  this.setState({postData:"abc",totalcount:res.data.message})
                }
                else{
                  
                  const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
                  const postData = slice.map(e => 
                  
                    <React.Fragment>
                 
                 <div className="row">
                <div className="col-sm-1"></div>
                  <div className="col-sm-10 displayallopp">
                   <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Title of the Project: </span>
                    <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"3%"}}>{e.titleofproject}</span><br /><br />
                    <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Date Posted: </span>
                    <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"9%"}}>{e.dateposted}</span>
                    <button  onClick={()=>this.ViewDetails(e.postid,e.opportunitytype)} className="btn btn-success" style={{fontFamily:"GothamLight",fontWeight:"bold",color:"white",width:"20%",backgroundColor:"rgb(0,147,69)",float:"right",marginTop:"2%"}}>View Details </button> <br /><br />
                    <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Opportunity Type: </span>
                    <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"4%"}}>{e.opportunitytype}</span><br /><br />
                    <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Status: </span>
                    <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"14.5%"}}>{e.poststatus}</span>
                    </div>  
      
                </div>
                
                
                
                
               
                         
                         
                      
                     </React.Fragment>
                  )
        
                  this.setState({
                      pageCount: Math.ceil(res.data.totalrow/ res.data.totalcount),
                     
                      postData, DataisLoaded: true,   
                  })
                }
               
            });
      })
     
    }
    if(this.state.pendingresult==1){
      this.setState({sortorder:e.target.value,alloppsearchbar:1},()=>{
        const sdata = {
     
          addcpydata:"getsearchcpyPendinginfo",
          userid:this.state.userid,
          sortorder:this.state.sortorder,
          viewinfo:this.state.viewinfo,
          searchtext:this.state.searchtext,
          showdata:this.state.showdata,
          pagenumber:this.state.pagenumber,
          totalpage:this.state.pageCount
         
           
          
      
         }
         console.log(sdata)
        // this.setState({DataisLoaded:true,})
        axios
            .post(`https://www.gigeconome.com:5200/api/addcpy_profiledata`,sdata)
            .then(res => {
              //console.log(res)
         
                const data = res.data.result;
                console.log(data)
                this.setState({totalcount:res.data.showingmsg})
                if(res.data.status==0){
                  console.log("empty")
                  this.setState({postData:"abc",totalcount:res.data.message})
                }
                else{
                  
                  const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
                  const postData = slice.map(e => 
                  
                    <React.Fragment>
                 
                 <div className="row">
                <div className="col-sm-1"></div>
                  <div className="col-sm-10 displayallopp">
                   <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Title of the Project: </span>
                    <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"3%"}}>{e.titleofproject}</span><br /><br />
                    <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Date Posted: </span>
                    <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"9%"}}>{e.dateposted}</span>
                    <button  onClick={()=>this.ViewDetails(e.postid,e.opportunitytype)} className="btn btn-success" style={{fontFamily:"GothamLight",fontWeight:"bold",color:"white",width:"20%",backgroundColor:"rgb(0,147,69)",float:"right",marginTop:"2%"}}>View Details </button> <br /><br />
                    <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Opportunity Type: </span>
                    <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"4%"}}>{e.opportunitytype}</span><br /><br />
                    <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Status: </span>
                    <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"14.5%"}}>{e.poststatus}</span>
                    </div>  
      
                </div>
                
                
                
                
               
                         
                         
                      
                     </React.Fragment>
                  )
        
                  this.setState({
                      pageCount: Math.ceil(res.data.totalrow/ res.data.totalcount),
                     
                      postData, DataisLoaded: true,   
                  })
                }
               
            });
      })
     
    }



    if(this.state.pastresult==1){
      
 this.setState({proposal:0,displayallopportunity:4,
  pastresult:1,pendingresult:0,reject:0})
      this.setState({sortorder:e.target.value,alloppsearchbar:1},()=>{
        const sdata = {
     
          addcpydata:"getsearchcpyPastinfo",
          userid:this.state.userid,
          sortorder:this.state.sortorder,
          viewinfo:this.state.viewinfo,
          searchtext:this.state.searchtext,
          pagenumber:this.state.currentPage+1,
          showdata:this.state.showdata,
          totalpage:this.state.pageCount
         
           
          
      
         }
         console.log(sdata)
        // this.setState({DataisLoaded:true,})
        axios
            .post(`https://www.gigeconome.com:5200/api/addcpy_profiledata`,sdata)
            .then(res => {
              //console.log(res)
         
                const data = res.data.result;
                console.log(data)
                this.setState({totalcount:res.data.showingmsg})
                if(res.data.status==0){
                 
                  this.setState({totalcount:res.data.message})
                  this.setState({postData:"abc"})
                }
                else{
                  
                  const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
                  const postData = slice.map(e => 
                  
                    <React.Fragment>
                 
                 <div className="row">
                <div className="col-sm-1"></div>
                  <div className="col-sm-10 displayallopp">
                   <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Title of the Project: </span>
                    <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"3%"}}>{e.titleofproject}</span><br /><br />
                    <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Date Posted: </span>
                    <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"9%"}}>{e.dateposted}</span>
                    <button  onClick={()=>this.ViewDetails(e.postid,e.opportunitytype)} className="btn btn-success" style={{fontFamily:"GothamLight",fontWeight:"bold",color:"white",width:"20%",backgroundColor:"rgb(0,147,69)",float:"right",marginTop:"2%"}}>View Details </button> <br /><br />
                    <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Opportunity Type: </span>
                    <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"4%"}}>{e.opportunitytype}</span><br /><br />
                    <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Status: </span>
                    <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"14.5%"}}>{e.poststatus}</span>
                    </div>  
      
                </div>
                
                
                
                
               
                         
                         
                      
                     </React.Fragment>
                  )
        
                  this.setState({
                      pageCount: Math.ceil(res.data.totalrow/ res.data.totalcount),
                     
                      postData, DataisLoaded: true,   
                  })
                }
               
            });
      })
     
    }

    if(this.state.reject==1){
      
      this.setState({proposal:0,displayallopportunity:5,pendingresult:0,
        pastresult:0,reject:1,postMessage:""})
           this.setState({sortorder:e.target.value,alloppsearchbar:1},()=>{
             const sdata = {
          
               addcpydata:"getsearchcpyRejectinfo",
               userid:this.state.userid,
               sortorder:this.state.sortorder,
               viewinfo:this.state.viewinfo,
               searchtext:this.state.searchtext,
               pagenumber:this.state.currentPage+1,
               showdata:this.state.showdata,
               totalpage:this.state.pageCount
              
                
               
           
              }
              console.log(sdata)
             // this.setState({DataisLoaded:true,})
             axios
                 .post(`https://www.gigeconome.com:5200/api/addcpy_profiledata`,sdata)
                 .then(res => {
                   //console.log(res)
              
                     const data = res.data.result;
                     console.log(data)
                     this.setState({totalcount:res.data.showingmsg})
                     if(res.data.status==0){
                       console.log("empty")
                       this.setState({postData:"abc"})
                       this.setState({totalcount:res.data.message})
                     }
                     else{
                       
                       const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
                       const postData = slice.map(e => 
                       
                         <React.Fragment>
                      
                      <div className="row">
                     <div className="col-sm-1"></div>
                       <div className="col-sm-10 displayallopp">
                        <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Title of the Project: </span>
                         <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"3%"}}>{e.titleofproject}</span><br /><br />
                         <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Date Posted: </span>
                         <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"9%"}}>{e.dateposted}</span>
                         <button  onClick={()=>this.ViewDetails(e.postid,e.opportunitytype)} className="btn btn-success" style={{fontFamily:"GothamLight",fontWeight:"bold",color:"white",width:"20%",backgroundColor:"rgb(0,147,69)",float:"right",marginTop:"2%"}}>View Details </button> <br /><br />
                         <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Opportunity Type: </span>
                         <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"4%"}}>{e.opportunitytype}</span><br /><br />
                         <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Status: </span>
                         <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"14.5%"}}>{e.poststatus}</span>
                         </div>  
           
                     </div>
                     
                     
                     
                     
                    
                              
                              
                           
                          </React.Fragment>
                       )
             
                       this.setState({
                           pageCount: Math.ceil(res.data.totalrow/ res.data.totalcount),
                          
                           postData, DataisLoaded: true,   
                       })
                     }
                    
                 });
           })
          
         }
  }
  showFilterOpp(e){
   
      this.setState({viewinfo:e.target.value,alloppsearchbar:1,totalcounttwentyfive:""},()=>{
     
        const sdata = {
   
          addcpydata:"getsearchcpyallopportunitiesinfo",
          userid:this.state.userid,
          sortorder:this.state.sortorder,
          viewinfo:e.target.value,
          searchtext:this.state.searchtext,
          showdata:this.state.showdata,
          pagenumber:this.state.pagenumber,
          totalpage:this.state.pageCount
         
           
          
      
         }
         console.log(sdata)
        // this.setState({DataisLoaded:true,})
        axios
            .post(`https://www.gigeconome.com:5200/api/addcpy_profiledata`,sdata)
            .then(res => {
              //console.log(res)
         
                const data = res.data.result;
                console.log(data)
                this.setState({totalcount:res.data.showingmsg})
                if(res.data.status==0){
                  console.log("empty")
                  this.setState({totalcount:res.data.message,postData:"abc"})
                }
                else{
                  
                  const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
                  const postData = slice.map(e => 
                  
                    <React.Fragment>
                 
                 <div className="row">
                <div className="col-sm-1"></div>
                  <div className="col-sm-10 displayallopp">
                   <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Title of the Project: </span>
                    <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"3%"}}>{e.titleofproject}</span><br /><br />
                    <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Date Posted: </span>
                    <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"9%"}}>{e.dateposted}</span>
                    <button  onClick={()=>this.ViewDetails(e.postid,e.opportunitytype)} className="btn btn-success" style={{fontFamily:"GothamLight",fontWeight:"bold",color:"white",width:"20%",backgroundColor:"rgb(0,147,69)",float:"right",marginTop:"2%"}}>View Details </button> <br /><br />
                    <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Opportunity Type: </span>
                    <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"4%"}}>{e.opportunitytype}</span><br /><br />
                    <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Status: </span>
                    <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"14.5%"}}>{e.poststatus}</span>
                    </div>  
      
                </div>
                
                
                
                
               
                         
                         
                      
                     </React.Fragment>
                  )
        
                  if(this.state.showdata==0){
                    this.setState({
                      pageCount: Math.ceil(res.data.totalrow/ res.data.totalcount),
                     
                      postData, DataisLoaded: true,   
                  },()=>{
                    console.log(this.state.pageCount)
                  })
                  }
                  else{
                    this.setState({
                      pageCount: Math.ceil(res.data.totalrow/ this.state.showdata),
                     
                      postData, DataisLoaded: true,   
                  },()=>{
                    console.log(this.state.pageCount)
                  })
                  }
              
                }
               
            });
      
      
     
    })
   
    
   
  
  }
  showFilterPastOppSearch(e){
    if(e.target.value=="Project Related Opportunities"){
      this.setState({pastsearchproject:1,pastsearchopp:1})
           const sdata = {
   
      addcpydata:"getcpyclosedopptyPAPinfo",
      userid:this.state.userid,
      datalimit:this.state.datalimit
      
       
      
  
     }
    // this.setState({DataisLoaded:true,})
    axios
        .post(`https://www.gigeconome.com:5200/api/addcpy_profiledata`,sdata)
        .then(res => {
          //console.log(res)
     
            const data = res.data.result;
            console.log(data)
            this.setState({totalcount:res.data.totalcount})
            if(res.data.status==0){
              console.log("empty")
              this.setState({})
            }
            else{
              
              const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
              const postData = slice.map(e => 
              
                <React.Fragment>
             
             <div className="row">
            <div className="col-sm-1"></div>
              <div className="col-sm-10 displayallopp">
               <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Title of the Project: </span>
                <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"3%"}}>{e.titleofproject}</span><br /><br />
                <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Date Posted: </span>
                <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"9%"}}>{e.dateposted}</span>
                <button  onClick={()=>this.ViewDetails(e.postid,e.opportunitytype)} className="btn btn-success" style={{fontFamily:"GothamLight",fontWeight:"bold",color:"white",width:"20%",backgroundColor:"rgb(0,147,69)",float:"right",marginTop:"2%"}}>View Details </button> <br /><br />
                <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Opportunity Type: </span>
                <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"4%"}}>{e.opportunitytype}</span><br /><br />
                <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Status: </span>
                <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"14.5%"}}>{e.poststatus}</span>
                </div>  
  
            </div>
            
            
            
            
           
                     
                     
                  
                 </React.Fragment>
              )
    
              this.setState({
                  pageCount: Math.ceil(data.length / this.state.perPage),
                 
                  postData, DataisLoaded: true,   
              })
            }
           
        });
  
  
    }
    else if(e.target.value=="Fixed Term Opportunities"){
      this.setState({projectrelatedoppsearch:0,fixedtermoppsearch:1,oppfiltersearch:1})
           const sdata = {
   
      addcpydata:"getcpyclosedopptyPAOinfo",
      userid:this.state.userid,
      datalimit:this.state.datalimit
      
       
      
  
     }
    // this.setState({DataisLoaded:true,})
    axios
        .post(`https://www.gigeconome.com:5200/api/addcpy_profiledata`,sdata)
        .then(res => {
          //console.log(res)
     
            const data = res.data.result;
            console.log(data)
            this.setState({totalcount:res.data.totalcount})
            if(res.data.status==0){
              //console.log("empty")
              this.setState({})
             
            }
            else{
              
              const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
              const postData = slice.map(e => 
              
                <React.Fragment>
             
             <div className="row">
            <div className="col-sm-1"></div>
              <div className="col-sm-10 displayallopp">
               <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Title of the Project: </span>
                <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"3%"}}>{e.titleofproject}</span><br /><br />
                <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Date Posted: </span>
                <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"9%"}}>{e.dateposted}</span>
                <button  onClick={()=>this.ViewDetails(e.postid,e.opportunitytype)} className="btn btn-success" style={{fontFamily:"GothamLight",fontWeight:"bold",color:"white",width:"20%",backgroundColor:"rgb(0,147,69)",float:"right",marginTop:"2%"}}>View Details </button> <br /><br />
                <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Opportunity Type: </span>
                <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"4%"}}>{e.opportunitytype}</span><br /><br />
                <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Status: </span>
                <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"14.5%"}}>{e.poststatus}</span>
                </div>  
  
            </div>
            
            
            
            
           
                     
                     
                  
                 </React.Fragment>
              )
    
              this.setState({
                  pageCount: Math.ceil(data.length / this.state.perPage),
                 
                  postData, DataisLoaded: true,   
              })
            }
           
        });
  
  
    }
    else if(e.target.value=="Long Term Opportunities"){
      this.setState({projectrelatedoppsearch:0,fixedtermoppsearch:0,})
           const sdata = {
    
      addcpydata:"getcpyallLTOinfo",
      userid:this.state.userid,
      datalimit:this.state.datalimit
      
       
      
    
     }
    // this.setState({DataisLoaded:true,})
    axios
        .post(`https://www.gigeconome.com:5200/api/addcpy_profiledata`,sdata)
        .then(res => {
          //console.log(res)
     
            const data = res.data.result;
            //console.log(data)
            this.setState({totalcount:res.data.totalcount})
            if(res.data.status==0){
              console.log("empty")
             
            }
            else{
              
              const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
              const postData = slice.map(e => 
              
                <React.Fragment>
             
             <div className="row">
            <div className="col-sm-1"></div>
              <div className="col-sm-10 displayallopp">
               <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Title of the Project: </span>
                <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"3%"}}>{e.titleofproject}</span><br /><br />
                <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Date Posted: </span>
                <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"9%"}}>{e.dateposted}</span>
                <button  onClick={()=>this.ViewDetails(e.postid,e.opportunitytype)} className="btn btn-success" style={{fontFamily:"GothamLight",fontWeight:"bold",color:"white",width:"20%",backgroundColor:"rgb(0,147,69)",float:"right",marginTop:"2%"}}>View Details </button> <br /><br />
                <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Opportunity Type: </span>
                <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"4%"}}>{e.opportunitytype}</span><br /><br />
                <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Status: </span>
                <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"14.5%"}}>{e.poststatus}</span>
                </div>  
    
            </div>
            
            
            
            
           
                     
                     
                  
                 </React.Fragment>
              )
    
              this.setState({
                  pageCount: Math.ceil(data.length / this.state.perPage),
                 
                  postData, DataisLoaded: true,   
              })
            }
           
        });
    
    
    }
    else{
      console.log("error")
    }
   
  }
  showFilterproposal(e){
if(e.target.value=="0"){
  const sdata = {
 
    addcpydata:"getsearchcpyopen4proposalinfo",
    userid:this.state.userid,
    sortorder:this.state.sortorder,
    viewinfo:this.state.viewinfo,
    searchtext:this.state.searchtext,
    pagenumber:1,
    showdata:0,
    totalpage:1

    
     
    

   }
   console.log(sdata)
  // this.setState({DataisLoaded:true,})
  axios
      .post(`https://www.gigeconome.com:5200/api/addcpy_profiledata`,sdata)
      .then(res => {
        //console.log(res)
   
          const data = res.data.result;
          console.log(data)
          this.setState({totalcount:res.data.showingmsg,})
          if(res.data.status==0){
            console.log("empty")
           this.setState({postData:"abc"})
          }
          else{
            
            const slice = data.slice(0, 0 + this.state.perPage)
            const postData = slice.map(e => 
            
              <React.Fragment>
           
           <div className="row">
          <div className="col-sm-1"></div>
            <div className="col-sm-10 displayallopp">
             <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Title of the Project: </span>
              <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"3%"}}>{e.titleofproject}</span><br /><br />
              <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Date Posted: </span>
              <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"9%"}}>{e.dateposted}</span>
              <button className="btn btn-success" onClick={()=>this.ViewDetails(e.postid,e.opportunitytype)} style={{fontFamily:"GothamLight",fontWeight:"bold",color:"white",width:"20%",backgroundColor:"rgb(0,147,69)",float:"right",marginTop:"2%"}}>View Details </button> <br /><br />
              <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Opportunity Type: </span>
              <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"4%"}}>{e.opportunitytype}</span><br /><br />
              <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Status: </span>
              <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"14.5%"}}>{e.poststatus}</span>
              </div>  

          </div>
          
          
          
          
         
                   
                   
                
               </React.Fragment>
            )
  
            if(this.state.showdata==0){
             this.setState({
               pageCount: Math.ceil(res.data.totalrow/ res.data.totalcount),
              
               postData, DataisLoaded: true,   
           },()=>{
             console.log(this.state.pageCount)
           })
           }
           else{
             this.setState({
               pageCount: Math.ceil(res.data.totalrow/ this.state.showdata),
              
               postData, DataisLoaded: true,   
           },()=>{
             console.log(this.state.pageCount)
           })
           }
       
          }
         
      });

 
}

if(e.target.value=="10"){
 
this.setState({proposal:1,
      projectfiltersearch:0,
      displayallopportunity:2,pendingresult:0,pastresult:0,reject:0})
  this.setState({showdata:e.target.value,totalcounttwentyfive:e.target.value,pageCount:2,currentPage:0,offset:0,pageCount:""},()=>{
  //  console.log(this.state.offset)
    const sdata = {

      addcpydata:"getsearchcpyopen4proposalinfo",
      userid:this.state.userid,
      sortorder:this.state.sortorder,
      viewinfo:this.state.viewinfo,
      searchtext:this.state.searchtext,
      showdata:e.target.value,
      pagenumber:1,
      totalpage:Math.ceil(this.state.abc/ 10)
      
       
      
  
     }
     console.log(sdata)
     //this.setState({DataisLoaded:true})
    axios
        .post(`https://www.gigeconome.com:5200/api/addcpy_profiledata`,sdata)
        .then(res => {
          //console.log(res)
     
            const data = res.data.result;
            console.log(res.data)
            this.setState({totalcount:res.data.showingmsg, perPage:res.data.totalcount})
            if(res.data.status==0){
              console.log("empty")
            this.setState({postData:"abc"})
            }
            else{
            
              const slice = data.slice(0, 0 + this.state.perPage)
              const postData = slice.map(e => 
              
                <React.Fragment>
             
             <div className="row">
            <div className="col-sm-1"></div>
              <div className="col-sm-10 displayallopp">
               <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Title of the Project: </span>
                <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"3%"}}>{e.titleofproject}</span><br /><br />
                <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Date Posted: </span>
                <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"9%"}}>{e.dateposted}</span>
                <button className="btn btn-success" style={{fontFamily:"GothamLight",fontWeight:"bold",color:"white",width:"20%",backgroundColor:"rgb(0,147,69)",float:"right",marginTop:"2%"}}
                onClick={()=>this.ViewDetails(e.postid,e.opportunitytype)}
                >View Details </button> <br /><br />
                <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Opportunity Type: </span>
                <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"4%"}}>{e.opportunitytype}</span><br /><br />
                <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Status: </span>
                <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"14.5%"}}>{e.poststatus}</span>
                </div>  

            </div>
            
            
            
            
           
                     
                     
                  
                 </React.Fragment>
              )
    
              if(this.state.showdata==0){
                this.setState({
                  pageCount: Math.ceil(res.data.totalrow/ res.data.totalcount),
                 
                  postData, DataisLoaded: true,   
              },()=>{
                console.log(this.state.pageCount)
              })
              }
              else{
                this.setState({
                  pageCount: Math.ceil(res.data.totalrow/ this.state.showdata),
                 
                  postData, DataisLoaded: true,   
              },()=>{
                console.log(this.state.pageCount)
              })
              }
            }
           
        });
  })
} 
if(e.target.value=="25"){
 
  this.setState({proposal:1,
        projectfiltersearch:0,
        displayallopportunity:2,pendingresult:0,pastresult:0,reject:0})
    this.setState({showdata:e.target.value,totalcounttwentyfive:e.target.value,pageCount:2,currentPage:0,offset:0,pageCount:""},()=>{
    //  console.log(this.state.offset)
      const sdata = {
  
        addcpydata:"getsearchcpyopen4proposalinfo",
        userid:this.state.userid,
        sortorder:this.state.sortorder,
        viewinfo:this.state.viewinfo,
        searchtext:this.state.searchtext,
        showdata:e.target.value,
        pagenumber:1,
        totalpage:Math.ceil(this.state.abc/ 25)
        
         
        
    
       }
       console.log(sdata)
       //this.setState({DataisLoaded:true})
      axios
          .post(`https://www.gigeconome.com:5200/api/addcpy_profiledata`,sdata)
          .then(res => {
            //console.log(res)
       
              const data = res.data.result;
              console.log(res.data)
              this.setState({totalcount:res.data.showingmsg, perPage:res.data.totalcount})
              if(res.data.status==0){
                console.log("empty")
              this.setState({postData:"abc"})
              }
              else{
              
                const slice = data.slice(0, 0 + this.state.perPage)
                const postData = slice.map(e => 
                
                  <React.Fragment>
               
               <div className="row">
              <div className="col-sm-1"></div>
                <div className="col-sm-10 displayallopp">
                 <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Title of the Project: </span>
                  <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"3%"}}>{e.titleofproject}</span><br /><br />
                  <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Date Posted: </span>
                  <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"9%"}}>{e.dateposted}</span>
                  <button className="btn btn-success" style={{fontFamily:"GothamLight",fontWeight:"bold",color:"white",width:"20%",backgroundColor:"rgb(0,147,69)",float:"right",marginTop:"2%"}}
                  onClick={()=>this.ViewDetails(e.postid,e.opportunitytype)}
                  >View Details </button> <br /><br />
                  <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Opportunity Type: </span>
                  <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"4%"}}>{e.opportunitytype}</span><br /><br />
                  <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Status: </span>
                  <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"14.5%"}}>{e.poststatus}</span>
                  </div>  
  
              </div>
              
              
              
              
             
                       
                       
                    
                   </React.Fragment>
                )
      
                if(this.state.showdata==0){
                  this.setState({
                    pageCount: Math.ceil(res.data.totalrow/ res.data.totalcount),
                   
                    postData, DataisLoaded: true,   
                },()=>{
                  console.log(this.state.pageCount)
                })
                }
                else{
                  this.setState({
                    pageCount: Math.ceil(res.data.totalrow/ this.state.showdata),
                   
                    postData, DataisLoaded: true,   
                },()=>{
                  console.log(this.state.pageCount)
                })
                }
              }
             
          });
    })
  } 


  if(e.target.value=="50"){
 
    this.setState({proposal:1,
          projectfiltersearch:0,
          displayallopportunity:2,pendingresult:0,pastresult:0,reject:0})
      this.setState({showdata:e.target.value,totalcounttwentyfive:e.target.value,pageCount:2,currentPage:0,offset:0,pageCount:""},()=>{
      //  console.log(this.state.offset)
        const sdata = {
    
          addcpydata:"getsearchcpyopen4proposalinfo",
          userid:this.state.userid,
          sortorder:this.state.sortorder,
          viewinfo:this.state.viewinfo,
          searchtext:this.state.searchtext,
          showdata:e.target.value,
          pagenumber:1,
          totalpage:Math.ceil(this.state.abc/ 50)
          
           
          
      
         }
         console.log(sdata)
         //this.setState({DataisLoaded:true})
        axios
            .post(`https://www.gigeconome.com:5200/api/addcpy_profiledata`,sdata)
            .then(res => {
              //console.log(res)
         
                const data = res.data.result;
                console.log(res.data)
                this.setState({totalcount:res.data.showingmsg, perPage:res.data.totalcount})
                if(res.data.status==0){
                  console.log("empty")
                this.setState({postData:"abc"})
                }
                else{
                
                  const slice = data.slice(0, 0 + this.state.perPage)
                  const postData = slice.map(e => 
                  
                    <React.Fragment>
                 
                 <div className="row">
                <div className="col-sm-1"></div>
                  <div className="col-sm-10 displayallopp">
                   <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Title of the Project: </span>
                    <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"3%"}}>{e.titleofproject}</span><br /><br />
                    <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Date Posted: </span>
                    <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"9%"}}>{e.dateposted}</span>
                    <button className="btn btn-success" style={{fontFamily:"GothamLight",fontWeight:"bold",color:"white",width:"20%",backgroundColor:"rgb(0,147,69)",float:"right",marginTop:"2%"}}
                    onClick={()=>this.ViewDetails(e.postid,e.opportunitytype)}
                    >View Details </button> <br /><br />
                    <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Opportunity Type: </span>
                    <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"4%"}}>{e.opportunitytype}</span><br /><br />
                    <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Status: </span>
                    <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"14.5%"}}>{e.poststatus}</span>
                    </div>  
    
                </div>
                
                
                
                
               
                         
                         
                      
                     </React.Fragment>
                  )
        
                  if(this.state.showdata==0){
                    this.setState({
                      pageCount: Math.ceil(res.data.totalrow/ res.data.totalcount),
                     
                      postData, DataisLoaded: true,   
                  },()=>{
                    console.log(this.state.pageCount)
                  })
                  }
                  else{
                    this.setState({
                      pageCount: Math.ceil(res.data.totalrow/ this.state.showdata),
                     
                      postData, DataisLoaded: true,   
                  },()=>{
                    console.log(this.state.pageCount)
                  })
                  }
                }
               
            });
      })
    }
    if(e.target.value=="100"){
 
      this.setState({proposal:1,
            projectfiltersearch:0,
            displayallopportunity:2,pendingresult:0,pastresult:0,reject:0})
        this.setState({showdata:e.target.value,totalcounttwentyfive:e.target.value,pageCount:2,currentPage:0,offset:0,pageCount:""},()=>{
        //  console.log(this.state.offset)
          const sdata = {
      
            addcpydata:"getsearchcpyopen4proposalinfo",
            userid:this.state.userid,
            sortorder:this.state.sortorder,
            viewinfo:this.state.viewinfo,
            searchtext:this.state.searchtext,
            showdata:e.target.value,
            pagenumber:1,
            totalpage:Math.ceil(this.state.abc/ 100)
            
             
            
        
           }
           console.log(sdata)
           //this.setState({DataisLoaded:true})
          axios
              .post(`https://www.gigeconome.com:5200/api/addcpy_profiledata`,sdata)
              .then(res => {
                //console.log(res)
           
                  const data = res.data.result;
                  console.log(res.data)
                  this.setState({totalcount:res.data.showingmsg, perPage:res.data.totalcount})
                  if(res.data.status==0){
                    console.log("empty")
                  this.setState({postData:"abc"})
                  }
                  else{
                  
                    const slice = data.slice(0, 0 + this.state.perPage)
                    const postData = slice.map(e => 
                    
                      <React.Fragment>
                   
                   <div className="row">
                  <div className="col-sm-1"></div>
                    <div className="col-sm-10 displayallopp">
                     <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Title of the Project: </span>
                      <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"3%"}}>{e.titleofproject}</span><br /><br />
                      <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Date Posted: </span>
                      <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"9%"}}>{e.dateposted}</span>
                      <button className="btn btn-success" style={{fontFamily:"GothamLight",fontWeight:"bold",color:"white",width:"20%",backgroundColor:"rgb(0,147,69)",float:"right",marginTop:"2%"}}
                      onClick={()=>this.ViewDetails(e.postid,e.opportunitytype)}
                      >View Details </button> <br /><br />
                      <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Opportunity Type: </span>
                      <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"4%"}}>{e.opportunitytype}</span><br /><br />
                      <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Status: </span>
                      <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"14.5%"}}>{e.poststatus}</span>
                      </div>  
      
                  </div>
                  
                  
                  
                  
                 
                           
                           
                        
                       </React.Fragment>
                    )
          
                    if(this.state.showdata==0){
                      this.setState({
                        pageCount: Math.ceil(res.data.totalrow/ res.data.totalcount),
                       
                        postData, DataisLoaded: true,   
                    },()=>{
                      console.log(this.state.pageCount)
                    })
                    }
                    else{
                      this.setState({
                        pageCount: Math.ceil(res.data.totalrow/ this.state.showdata),
                       
                        postData, DataisLoaded: true,   
                    },()=>{
                      console.log(this.state.pageCount)
                    })
                    }
                  }
                 
              });
        })
      }  
  }
  handleSearchPast (){

    if(this.state.pastsearchproject==1){
      const sdata = {
   
        addcpydata:"searchcpyclosedopptyPAPinfo",
        userid:this.state.userid,
        datalimit:this.state.datalimit,
        searchtext:this.state.searchtext
        
         
        
    
       }
      // this.setState({DataisLoaded:true,})
      axios
          .post(`https://www.gigeconome.com:5200/api/addcpy_profiledata`,sdata)
          .then(res => {
            //console.log(res)
       
              const data = res.data.result;
              console.log(data)
              this.setState({totalcount:res.data.totalcount})
              if(res.data.status==0){
                console.log("empty")
                this.setState({})
              }
              else{
                
                const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
                const postData = slice.map(e => 
                
                  <React.Fragment>
               
               <div className="row">
              <div className="col-sm-1"></div>
                <div className="col-sm-10 displayallopp">
                 <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Title of the Project: </span>
                  <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"3%"}}>{e.titleofproject}</span><br /><br />
                  <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Date Posted: </span>
                  <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"9%"}}>{e.dateposted}</span>
                  <button  onClick={()=>this.ViewDetails(e.postid,e.opportunitytype)} className="btn btn-success" style={{fontFamily:"GothamLight",fontWeight:"bold",color:"white",width:"20%",backgroundColor:"rgb(0,147,69)",float:"right",marginTop:"2%"}}>View Details </button> <br /><br />
                  <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Opportunity Type: </span>
                  <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"4%"}}>{e.opportunitytype}</span><br /><br />
                  <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Status: </span>
                  <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"14.5%"}}>{e.poststatus}</span>
                  </div>  
    
              </div>
              
              
              
              
             
                       
                       
                    
                   </React.Fragment>
                )
      
                this.setState({
                    pageCount: Math.ceil(data.length / this.state.perPage),
                   
                    postData, DataisLoaded: true,   
                })
              }
             
          });
    
    
    }
    else  if(this.state.pastsearchopp==1){
      const sdata = {
   
        addcpydata:"searchcpyongoingopptyPAOinfo",
        userid:this.state.userid,
        datalimit:this.state.datalimit,
        searchtext:this.state.searchtext
        
         
        
    
       }
      // this.setState({DataisLoaded:true,})
      axios
          .post(`https://www.gigeconome.com:5200/api/addcpy_profiledata`,sdata)
          .then(res => {
            //console.log(res)
       
              const data = res.data.result;
              console.log(data)
              this.setState({totalcount:res.data.totalcount})
              if(res.data.status==0){
                console.log("empty")
                this.setState({})
              }
              else{
                
                const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
                const postData = slice.map(e => 
                
                  <React.Fragment>
               
               <div className="row">
              <div className="col-sm-1"></div>
                <div className="col-sm-10 displayallopp">
                 <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Title of the Project: </span>
                  <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"3%"}}>{e.titleofproject}</span><br /><br />
                  <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Date Posted: </span>
                  <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"9%"}}>{e.dateposted}</span>
                  <button  onClick={()=>this.ViewDetails(e.postid,e.opportunitytype)} className="btn btn-success" style={{fontFamily:"GothamLight",fontWeight:"bold",color:"white",width:"20%",backgroundColor:"rgb(0,147,69)",float:"right",marginTop:"2%"}}>View Details </button> <br /><br />
                  <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Opportunity Type: </span>
                  <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"4%"}}>{e.opportunitytype}</span><br /><br />
                  <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Status: </span>
                  <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"14.5%"}}>{e.poststatus}</span>
                  </div>  
    
              </div>
              
              
              
              
             
                       
                       
                    
                   </React.Fragment>
                )
      
                this.setState({
                    pageCount: Math.ceil(data.length / this.state.perPage),
                   
                    postData, DataisLoaded: true,   
                })
              }
             
          });
    
    
    }
    else {
      //this.setState({projectrelatedoppsearch:0,fixedtermoppsearch:0,})
           const sdata = {
    
      addcpydata:"getcpyallLTOinfo",
      userid:this.state.userid,
      datalimit:this.state.datalimit
      
       
      
    
     }
    // this.setState({DataisLoaded:true,})
    axios
        .post(`https://www.gigeconome.com:5200/api/addcpy_profiledata`,sdata)
        .then(res => {
          //console.log(res)
     
            const data = res.data.result;
            //console.log(data)
            this.setState({totalcount:res.data.totalcount})
            if(res.data.status==0){
              console.log("empty")
             
            }
            else{
              
              const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
              const postData = slice.map(e => 
              
                <React.Fragment>
             
             <div className="row">
            <div className="col-sm-1"></div>
              <div className="col-sm-10 displayallopp">
               <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Title of the Project: </span>
                <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"3%"}}>{e.titleofproject}</span><br /><br />
                <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Date Posted: </span>
                <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"9%"}}>{e.dateposted}</span>
                <button  onClick={()=>this.ViewDetails(e.postid,e.opportunitytype)} className="btn btn-success" style={{fontFamily:"GothamLight",fontWeight:"bold",color:"white",width:"20%",backgroundColor:"rgb(0,147,69)",float:"right",marginTop:"2%"}}>View Details </button> <br /><br />
                <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Opportunity Type: </span>
                <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"4%"}}>{e.opportunitytype}</span><br /><br />
                <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Status: </span>
                <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"14.5%"}}>{e.poststatus}</span>
                </div>  
    
            </div>
            
            
            
            
           
                     
                     
                  
                 </React.Fragment>
              )
    
              this.setState({
                  pageCount: Math.ceil(data.length / this.state.perPage),
                 
                  postData, DataisLoaded: true,   
              })
            }
           
        });
    
    
    }
   
  }


 handleSearchProposal (){

    if(this.state.projectfiltersearch==1){
      const sdata = {
   
        addcpydata:"searchcpyongoingopptyPAPinfo",
        userid:this.state.userid,
        datalimit:this.state.datalimit,
        searchtext:this.state.searchtext
        
         
        
    
       }
      // this.setState({DataisLoaded:true,})
      axios
          .post(`https://www.gigeconome.com:5200/api/addcpy_profiledata`,sdata)
          .then(res => {
            //console.log(res)
       
              const data = res.data.result;
              //console.log(data)
              this.setState({totalcount:res.data.totalcount})
              if(res.data.status==0){
                console.log("empty")
                this.setState({postData:"abc",totalcount:res.data.message})
              }
              else{
                
                const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
                const postData = slice.map(e => 
                
                  <React.Fragment>
               
               <div className="row">
              <div className="col-sm-1"></div>
                <div className="col-sm-10 displayallopp">
                 <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Title of the Project: </span>
                  <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"3%"}}>{e.titleofproject}</span><br /><br />
                  <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Date Posted: </span>
                  <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"9%"}}>{e.dateposted}</span>
                  <button  onClick={()=>this.ViewDetails(e.postid,e.opportunitytype)} className="btn btn-success" style={{fontFamily:"GothamLight",fontWeight:"bold",color:"white",width:"20%",backgroundColor:"rgb(0,147,69)",float:"right",marginTop:"2%"}}>View Details </button> <br /><br />
                  <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Opportunity Type: </span>
                  <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"4%"}}>{e.opportunitytype}</span><br /><br />
                  <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Status: </span>
                  <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"14.5%"}}>{e.poststatus}</span>
                  </div>  
    
              </div>
              
              
              
              
             
                       
                       
                    
                   </React.Fragment>
                )
      
                this.setState({
                    pageCount: Math.ceil(data.length / this.state.perPage),
                   
                    postData, DataisLoaded: true,   
                })
              }
             
          });
    
    
    }
    else  if(this.state.oppfiltersearch==1){
      const sdata = {
   
        addcpydata:"searchcpyongoingopptyPAOinfo",
        userid:this.state.userid,
        datalimit:this.state.datalimit,
        searchtext:this.state.searchtext
        
         
        
    
       }
      // this.setState({DataisLoaded:true,})
      axios
          .post(`https://www.gigeconome.com:5200/api/addcpy_profiledata`,sdata)
          .then(res => {
            //console.log(res)
       
              const data = res.data.result;
              console.log(data)
              this.setState({totalcount:res.data.totalcount})
              if(res.data.status==0){
                console.log("empty")
                this.setState({postData:"abc",totalcount:res.data.message})
              }
              else{
                
                const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
                const postData = slice.map(e => 
                
                  <React.Fragment>
               
               <div className="row">
              <div className="col-sm-1"></div>
                <div className="col-sm-10 displayallopp">
                 <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Title of the Project: </span>
                  <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"3%"}}>{e.titleofproject}</span><br /><br />
                  <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Date Posted: </span>
                  <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"9%"}}>{e.dateposted}</span>
                  <button  onClick={()=>this.ViewDetails(e.postid,e.opportunitytype)} className="btn btn-success" style={{fontFamily:"GothamLight",fontWeight:"bold",color:"white",width:"20%",backgroundColor:"rgb(0,147,69)",float:"right",marginTop:"2%"}}>View Details </button> <br /><br />
                  <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Opportunity Type: </span>
                  <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"4%"}}>{e.opportunitytype}</span><br /><br />
                  <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Status: </span>
                  <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"14.5%"}}>{e.poststatus}</span>
                  </div>  
    
              </div>
              
              
              
              
             
                       
                       
                    
                   </React.Fragment>
                )
      
                this.setState({
                    pageCount: Math.ceil(data.length / this.state.perPage),
                   
                    postData, DataisLoaded: true,   
                })
              }
             
          });
    
    
    }
    else {
      //this.setState({projectrelatedoppsearch:0,fixedtermoppsearch:0,})
           const sdata = {
    
      addcpydata:"getcpyallLTOinfo",
      userid:this.state.userid,
      datalimit:this.state.datalimit
      
       
      
    
     }
    // this.setState({DataisLoaded:true,})
    axios
        .post(`https://www.gigeconome.com:5200/api/addcpy_profiledata`,sdata)
        .then(res => {
          //console.log(res)
     
            const data = res.data.result;
            //console.log(data)
            this.setState({totalcount:res.data.totalcount})
            if(res.data.status==0){
              this.setState({postData:"abc",totalcount:res.data.message})
             
            }
            else{
              
              const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
              const postData = slice.map(e => 
              
                <React.Fragment>
             
             <div className="row">
            <div className="col-sm-1"></div>
              <div className="col-sm-10 displayallopp">
               <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Title of the Project: </span>
                <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"3%"}}>{e.titleofproject}</span><br /><br />
                <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Date Posted: </span>
                <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"9%"}}>{e.dateposted}</span>
                <button  onClick={()=>this.ViewDetails(e.postid,e.opportunitytype)} className="btn btn-success" style={{fontFamily:"GothamLight",fontWeight:"bold",color:"white",width:"20%",backgroundColor:"rgb(0,147,69)",float:"right",marginTop:"2%"}}>View Details </button> <br /><br />
                <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Opportunity Type: </span>
                <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"4%"}}>{e.opportunitytype}</span><br /><br />
                <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Status: </span>
                <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"14.5%"}}>{e.poststatus}</span>
                </div>  
    
            </div>
            
            
            
            
           
                     
                     
                  
                 </React.Fragment>
              )
    
              this.setState({
                  pageCount: Math.ceil(data.length / this.state.perPage),
                 
                  postData, DataisLoaded: true,   
              })
            }
           
        });
    
    
    }
   
  }
  showFilterPastopp(e){
    this.setState({datalimit:""})
    if(e.target.value=="All"){
      this.setState({datalimit:""})
      this.setState({datalimit:0},()=>{
        this.handlePast()
      })
     // console.log("All")
    }
    else{
      this.setState({datalimit:e.target.value},()=>{
        this.handlePast()
        
      })
      //console.log("Hello")
    }
   
  }
  showFilterCreateaproposal(e){
    if(e.target.value=="Project Related Opportunities"){
      this.setState({projectrelatedoppsearch:1,projectfiltersearch:1})
           const sdata = {
   
      addcpydata:"getcpyongoingopptyPAPinfo",
      userid:this.state.userid,
      datalimit:this.state.datalimit
      
       
      
  
     }
    // this.setState({DataisLoaded:true,})
    axios
        .post(`https://www.gigeconome.com:5200/api/addcpy_profiledata`,sdata)
        .then(res => {
          //console.log(res)
     
            const data = res.data.result;
            console.log(data)
            this.setState({totalcount:res.data.totalcount})
            if(res.data.status==0){
              console.log("empty")
              this.setState({})
            }
            else{
              
              const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
              const postData = slice.map(e => 
              
                <React.Fragment>
             
             <div className="row">
            <div className="col-sm-1"></div>
              <div className="col-sm-10 displayallopp">
               <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Title of the Project: </span>
                <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"3%"}}>{e.titleofproject}</span><br /><br />
                <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Date Posted: </span>
                <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"9%"}}>{e.dateposted}</span>
                <button  onClick={()=>this.ViewDetails(e.postid,e.opportunitytype)} className="btn btn-success" style={{fontFamily:"GothamLight",fontWeight:"bold",color:"white",width:"20%",backgroundColor:"rgb(0,147,69)",float:"right",marginTop:"2%"}}>View Details </button> <br /><br />
                <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Opportunity Type: </span>
                <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"4%"}}>{e.opportunitytype}</span><br /><br />
                <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Status: </span>
                <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"14.5%"}}>{e.poststatus}</span>
                </div>  
  
            </div>
            
            
            
            
           
                     
                     
                  
                 </React.Fragment>
              )
    
              this.setState({
                  pageCount: Math.ceil(data.length / this.state.perPage),
                 
                  postData, DataisLoaded: true,   
              })
            }
           
        });
  
  
    }
    else if(e.target.value=="Fixed Term Opportunities"){
      this.setState({projectrelatedoppsearch:0,fixedtermoppsearch:1,oppfiltersearch:1})
           const sdata = {
   
      addcpydata:"getcpyongoingopptyPAOinfo",
      userid:this.state.userid,
      datalimit:this.state.datalimit
      
       
      
  
     }
    // this.setState({DataisLoaded:true,})
    axios
        .post(`https://www.gigeconome.com:5200/api/addcpy_profiledata`,sdata)
        .then(res => {
          //console.log(res)
     
            const data = res.data.result;
            console.log(data)
            this.setState({totalcount:res.data.totalcount})
            if(res.data.status==0){
              //console.log("empty")
              this.setState({})
             
            }
            else{
              
              const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
              const postData = slice.map(e => 
              
                <React.Fragment>
             
             <div className="row">
            <div className="col-sm-1"></div>
              <div className="col-sm-10 displayallopp">
               <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Title of the Project: </span>
                <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"3%"}}>{e.titleofproject}</span><br /><br />
                <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Date Posted: </span>
                <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"9%"}}>{e.dateposted}</span>
                <button  onClick={()=>this.ViewDetails(e.postid,e.opportunitytype)} className="btn btn-success" style={{fontFamily:"GothamLight",fontWeight:"bold",color:"white",width:"20%",backgroundColor:"rgb(0,147,69)",float:"right",marginTop:"2%"}}>View Details </button> <br /><br />
                <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Opportunity Type: </span>
                <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"4%"}}>{e.opportunitytype}</span><br /><br />
                <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Status: </span>
                <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"14.5%"}}>{e.poststatus}</span>
                </div>  
  
            </div>
            
            
            
            
           
                     
                     
                  
                 </React.Fragment>
              )
    
              this.setState({
                  pageCount: Math.ceil(data.length / this.state.perPage),
                 
                  postData, DataisLoaded: true,   
              })
            }
           
        });
  
  
    }
    else if(e.target.value=="Long Term Opportunities"){
      this.setState({projectrelatedoppsearch:0,fixedtermoppsearch:0,})
           const sdata = {
    
      addcpydata:"getcpyallLTOinfo",
      userid:this.state.userid,
      datalimit:this.state.datalimit
      
       
      
    
     }
    // this.setState({DataisLoaded:true,})
    axios
        .post(`https://www.gigeconome.com:5200/api/addcpy_profiledata`,sdata)
        .then(res => {
          //console.log(res)
     
            const data = res.data.result;
            //console.log(data)
            this.setState({totalcount:res.data.totalcount})
            if(res.data.status==0){
              console.log("empty")
             
            }
            else{
              
              const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
              const postData = slice.map(e => 
              
                <React.Fragment>
             
             <div className="row">
            <div className="col-sm-1"></div>
              <div className="col-sm-10 displayallopp">
               <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Title of the Project: </span>
                <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"3%"}}>{e.titleofproject}</span><br /><br />
                <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Date Posted: </span>
                <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"9%"}}>{e.dateposted}</span>
                <button  onClick={()=>this.ViewDetails(e.postid,e.opportunitytype)} className="btn btn-success" style={{fontFamily:"GothamLight",fontWeight:"bold",color:"white",width:"20%",backgroundColor:"rgb(0,147,69)",float:"right",marginTop:"2%"}}>View Details </button> <br /><br />
                <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Opportunity Type: </span>
                <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"4%"}}>{e.opportunitytype}</span><br /><br />
                <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Status: </span>
                <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"14.5%"}}>{e.poststatus}</span>
                </div>  
    
            </div>
            
            
            
            
           
                     
                     
                  
                 </React.Fragment>
              )
    
              this.setState({
                  pageCount: Math.ceil(data.length / this.state.perPage),
                 
                  postData, DataisLoaded: true,   
              })
            }
           
        });
    
    
    }
    else{
      console.log("error")
    }

  }
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
     
    });
    
  };
  
  handleSearch(){
    if(this.state.displayallopportunity==0){
      const searchdata = {
  
        addcpydata:"getsearchcpyallopportunitiesinfo",
        userid:this.state.userid,
        searchtext:this.state.searchtext,
        showdata:this.state.showdata,
        sortorder:this.state.sortorder,
        viewinfo:this.state.viewinfo,
        pagenumber:this.state.pagenumber,
        totalpage:this.state.totalpage
        
         
        
    
       }
      // console.log(searchdata)
       this.setState({DataisLoaded:true})
      axios
          .post(`https://www.gigeconome.com:5200/api/addcpy_profiledata`,searchdata)
          .then(res => {
            console.log(res)
       
              const data = res.data.result;
              //console.log(data)
              this.setState({totalcount:res.data.showingmsg})
              if(res.data.status==0){
                //console.log("empty")
                this.setState({totalcount:res.data.message})
                this.setState({postData:"abc"})
              }
              else{
                this.setState({displayallopportunity:0,proposal:0})
                const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
                const postData = slice.map(e => 
                
                  <React.Fragment>
               
               <div className="row">
              <div className="col-sm-1"></div>
                <div className="col-sm-10 displayallopp">
                 <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Title of the Project: </span>
                  <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"3%"}}>{e.titleofproject}</span><br /><br />
                  <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Date Posted: </span>
                  <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"9%"}}>{e.dateposted}</span>
                  <button className="btn btn-success" style={{fontFamily:"GothamLight",fontWeight:"bold",color:"white",width:"20%",backgroundColor:"rgb(0,147,69)",float:"right",marginTop:"2%"}}
                  onClick={()=>this.ViewDetails(e.postid,e.opportunitytype)}
                  >View Details </button> <br /><br />
                  <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Opportunity Type: </span>
                  <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"4%"}}>{e.opportunitytype}</span><br /><br />
                  <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Status: </span>
                  <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"14.5%"}}>{e.poststatus}</span>
                  </div>  
  
              </div>
              
              
              
              
             
                       
                       
                    
                   </React.Fragment>
                )
      
                this.setState({
                    pageCount: Math.ceil(data.length / this.state.perPage),
                   
                    postData, DataisLoaded: true,   
                })
              }
             
          });
    }
    if(this.state.proposal==1){
     // this.setState({postData:"abc"})
      const searchdata = {
  
        addcpydata:"getsearchcpyopen4proposalinfo",
        userid:this.state.userid,
        searchtext:this.state.searchtext,
        showdata:this.state.showdata,
        sortorder:this.state.sortorder,
        viewinfo:this.state.viewinfo,
        pagenumber:this.state.currentPage+1,
        totalpage:this.state.pageCount
        
        
         
        
    
       }
      console.log(searchdata)
       this.setState({DataisLoaded:true})
      axios
          .post(`https://www.gigeconome.com:5200/api/addcpy_profiledata`,searchdata)
          .then(res => {
            //console.log(res)
       
              const data = res.data.result;
              console.log(res.data)
              this.setState({totalcount:res.data.showingmsg})
              if(res.data.status==0){
                //console.log("empty")
                this.setState({totalcount:res.data.message})
                this.setState({postData:"abc"})
              }
              else{
               // this.setState({displayallopportunity:0,proposal:0})
                const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
                const postData = slice.map(e => 
                
                  <React.Fragment>
               
               <div className="row">
              <div className="col-sm-1"></div>
                <div className="col-sm-10 displayallopp">
                 <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Title of the Project: </span>
                  <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"3%"}}>{e.titleofproject}</span><br /><br />
                  <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Date Posted: </span>
                  <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"9%"}}>{e.dateposted}</span>
                  <button className="btn btn-success" style={{fontFamily:"GothamLight",fontWeight:"bold",color:"white",width:"20%",backgroundColor:"rgb(0,147,69)",float:"right",marginTop:"2%"}}
                  onClick={()=>this.ViewDetails(e.postid,e.opportunitytype)}
                  >View Details </button> <br /><br />
                  <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Opportunity Type: </span>
                  <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"4%"}}>{e.opportunitytype}</span><br /><br />
                  <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Status: </span>
                  <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"14.5%"}}>{e.poststatus}</span>
                  </div>  
  
              </div>
              
              
              
              
             
                       
                       
                    
                   </React.Fragment>
                )
      
                this.setState({
                    pageCount: Math.ceil(data.length / this.state.perPage),
                   
                    postData, DataisLoaded: true,   
                })
              }
             
          });
    }




    if(this.state.reject==1){
      const searchdata = {
  
        addcpydata:"getsearchcpyRejectinfo",
        userid:this.state.userid,
        searchtext:this.state.searchtext,
        showdata:this.state.showdata,
        sortorder:this.state.sortorder,
        viewinfo:this.state.viewinfo,
        pagenumber:this.state.currentPage+1,
        totalpage:this.state.pageCount
        
        
         
        
    
       }
      // console.log(searchdata)
       this.setState({DataisLoaded:true})
      axios
          .post(`https://www.gigeconome.com:5200/api/addcpy_profiledata`,searchdata)
          .then(res => {
            //console.log(res)
       
              const data = res.data.result;
            //  console.log(res.data)
              this.setState({totalcount:res.data.showingmsg})
              if(res.data.status==0){
               // console.log("empty")
               this.setState({totalcount:res.data.message})
               this.setState({postData:"abc"})
              }
              else{
               // this.setState({displayallopportunity:0,proposal:0})
                const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
                const postData = slice.map(e => 
                
                  <React.Fragment>
               
               <div className="row">
              <div className="col-sm-1"></div>
                <div className="col-sm-10 displayallopp">
                 <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Title of the Project: </span>
                  <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"3%"}}>{e.titleofproject}</span><br /><br />
                  <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Date Posted: </span>
                  <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"9%"}}>{e.dateposted}</span>
                  <button className="btn btn-success" style={{fontFamily:"GothamLight",fontWeight:"bold",color:"white",width:"20%",backgroundColor:"rgb(0,147,69)",float:"right",marginTop:"2%"}}
                  onClick={()=>this.ViewDetails(e.postid,e.opportunitytype)}
                  >View Details </button> <br /><br />
                  <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Opportunity Type: </span>
                  <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"4%"}}>{e.opportunitytype}</span><br /><br />
                  <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Status: </span>
                  <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"14.5%"}}>{e.poststatus}</span>
                  </div>  
  
              </div>
              
              
              
              
             
                       
                       
                    
                   </React.Fragment>
                )
      
                this.setState({
                    pageCount: Math.ceil(data.length / this.state.perPage),
                   
                    postData, DataisLoaded: true,   
                })
              }
             
          });
    }
    if(this.state.pendingresult==1){
      const searchdata = {
  
        addcpydata:"getsearchcpyPendinginfo",
        userid:this.state.userid,
        searchtext:this.state.searchtext,
        showdata:this.state.showdata,
        sortorder:this.state.sortorder,
        viewinfo:this.state.viewinfo,
        pagenumber:this.state.currentPage+1,
        totalpage:this.state.pageCount
        
         
        
    
       }
       console.log(searchdata)
       this.setState({DataisLoaded:true})
      axios
          .post(`https://www.gigeconome.com:5200/api/addcpy_profiledata`,searchdata)
          .then(res => {
            //console.log(res)
       
              const data = res.data.result;
              console.log(res.data)
              this.setState({totalcount:res.data.showingmsg})
              if(res.data.status==0){
               // console.log("empty")
               this.setState({postData:"abc"})
                this.setState({totalcount:res.data.message})
              }
              else{
               // this.setState({displayallopportunity:0,proposal:0})
                const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
                const postData = slice.map(e => 
                
                  <React.Fragment>
               
               <div className="row">
              <div className="col-sm-1"></div>
                <div className="col-sm-10 displayallopp">
                 <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Title of the Project: </span>
                  <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"3%"}}>{e.titleofproject}</span><br /><br />
                  <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Date Posted: </span>
                  <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"9%"}}>{e.dateposted}</span>
                  <button className="btn btn-success" style={{fontFamily:"GothamLight",fontWeight:"bold",color:"white",width:"20%",backgroundColor:"rgb(0,147,69)",float:"right",marginTop:"2%"}}
                  onClick={()=>this.ViewDetails(e.postid,e.opportunitytype)}
                  >View Details </button> <br /><br />
                  <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Opportunity Type: </span>
                  <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"4%"}}>{e.opportunitytype}</span><br /><br />
                  <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Status: </span>
                  <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"14.5%"}}>{e.poststatus}</span>
                  </div>  
  
              </div>
              
              
              
              
             
                       
                       
                    
                   </React.Fragment>
                )
      
                this.setState({
                    pageCount: Math.ceil(data.length / this.state.perPage),
                   
                    postData, DataisLoaded: true,   
                })
              }
             
          });
    }

    if(this.state.pastresult==1){
      this.setState({proposal:0,displayallopportunity:4,
        pastresult:1,pendingresult:0,reject:0})
      const searchdata = {
     
       
        addcpydata:"getsearchcpyPastinfo",
        userid:this.state.userid,
        sortorder:this.state.sortorder,
        viewinfo:this.state.viewinfo,
        searchtext:this.state.searchtext,
        pagenumber:this.state.currentPage+1,
        showdata:this.state.showdata,
        totalpage:this.state.pageCount
         
        
    
       }
     //  console.log(searchdata)
       this.setState({DataisLoaded:true})
      axios
          .post(`https://www.gigeconome.com:5200/api/addcpy_profiledata`,searchdata)
          .then(res => {
            //console.log(res)
       
              const data = res.data.result;
             // console.log(res.data)
              this.setState({totalcount:res.data.showingmsg})
              if(res.data.status==0){
               // console.log("empty")
               this.setState({totalcount:res.data.message})
               this.setState({postData:"abc"})
              }
              else{
               // this.setState({displayallopportunity:0,proposal:0})
                const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
                const postData = slice.map(e => 
                
                  <React.Fragment>
               
               <div className="row">
              <div className="col-sm-1"></div>
                <div className="col-sm-10 displayallopp">
                 <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Title of the Project: </span>
                  <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"3%"}}>{e.titleofproject}</span><br /><br />
                  <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Date Posted: </span>
                  <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"9%"}}>{e.dateposted}</span>
                  <button className="btn btn-success" style={{fontFamily:"GothamLight",fontWeight:"bold",color:"white",width:"20%",backgroundColor:"rgb(0,147,69)",float:"right",marginTop:"2%"}}
                  onClick={()=>this.ViewDetails(e.postid,e.opportunitytype)}
                  >View Details </button> <br /><br />
                  <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Opportunity Type: </span>
                  <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"4%"}}>{e.opportunitytype}</span><br /><br />
                  <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Status: </span>
                  <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"14.5%"}}>{e.poststatus}</span>
                  </div>  
  
              </div>
              
              
              
              
             
                       
                       
                    
                   </React.Fragment>
                )
      
                this.setState({
                    pageCount: Math.ceil(data.length / this.state.perPage),
                   
                    postData, DataisLoaded: true,   
                })
              }
             
          });
    }

    
    if(this.state.projectrelatedoppsearch==1){
      const searchdata = {
  
        addcpydata:"getcpyallPAOinfo",
        userid:this.state.userid,
        datalimit:this.state.datalimit,
        searchtext:this.state.searchtext
        
         
        
    
       }
     //  console.log(searchdata)
       this.setState({DataisLoaded:true})
      axios
          .post(`https://www.gigeconome.com:5200/api/addcpy_profiledata`,searchdata)
          .then(res => {
            //console.log(res)
       
              const data = res.data.result;
             // console.log(res.data)
              this.setState({totalcount:res.data.showingmsg})
              if(res.data.status==0){
                //console.log("empty")
                this.setState({totalcount:res.data.message})
                this.setState({postData:"abc"})
              }
              else{
               // this.setState({displayallopportunity:0,proposal:0})
                const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
                const postData = slice.map(e => 
                
                  <React.Fragment>
               
               <div className="row">
              <div className="col-sm-1"></div>
                <div className="col-sm-10 displayallopp">
                 <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Title of the Project: </span>
                  <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"3%"}}>{e.titleofproject}</span><br /><br />
                  <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Date Posted: </span>
                  <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"9%"}}>{e.dateposted}</span>
                  <button className="btn btn-success" style={{fontFamily:"GothamLight",fontWeight:"bold",color:"white",width:"20%",backgroundColor:"rgb(0,147,69)",float:"right",marginTop:"2%"}}
                  onClick={()=>this.ViewDetails(e.postid,e.opportunitytype)}
                  >View Details </button> <br /><br />
                  <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Opportunity Type: </span>
                  <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"4%"}}>{e.opportunitytype}</span><br /><br />
                  <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Status: </span>
                  <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"14.5%"}}>{e.poststatus}</span>
                  </div>  
  
              </div>
              
              
              
              
             
                       
                       
                    
                   </React.Fragment>
                )
      
                this.setState({
                    pageCount: Math.ceil(data.length / this.state.perPage),
                   
                    postData, DataisLoaded: true,   
                })
              }
             
          });
    }

    if(this.state.fixedtermoppsearch==1){
      const searchdata = {
  
        addcpydata:"searchtcpyallPAPinfo",
        userid:this.state.userid,
        datalimit:this.state.datalimit,
        searchtext:this.state.searchtext
        
         
        
    
       }
     //  console.log(searchdata)
       this.setState({DataisLoaded:true})
      axios
          .post(`https://www.gigeconome.com:5200/api/addcpy_profiledata`,searchdata)
          .then(res => {
            //console.log(res)
       
              const data = res.data.result;
             // console.log(res.data)
              this.setState({totalcount:res.data.showingmsg})
              if(res.data.status==0){
                this.setState({totalcount:res.data.message})
                this.setState({postData:"abc"})
               
              }
              else{
               // this.setState({displayallopportunity:0,proposal:0})
                const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
                const postData = slice.map(e => 
                
                  <React.Fragment>
               
               <div className="row">
              <div className="col-sm-1"></div>
                <div className="col-sm-10 displayallopp">
                 <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Title of the Project: </span>
                  <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"3%"}}>{e.titleofproject}</span><br /><br />
                  <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Date Posted: </span>
                  <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"9%"}}>{e.dateposted}</span>
                  <button className="btn btn-success" style={{fontFamily:"GothamLight",fontWeight:"bold",color:"white",width:"20%",backgroundColor:"rgb(0,147,69)",float:"right",marginTop:"2%"}}
                  onClick={()=>this.ViewDetails(e.postid,e.opportunitytype)}
                  >View Details </button> <br /><br />
                  <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Opportunity Type: </span>
                  <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"4%"}}>{e.opportunitytype}</span><br /><br />
                  <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Status: </span>
                  <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"14.5%"}}>{e.poststatus}</span>
                  </div>  
  
              </div>
              
              
              
              
             
                       
                       
                    
                   </React.Fragment>
                )
      
                this.setState({
                    pageCount: Math.ceil(data.length / this.state.perPage),
                   
                    postData, DataisLoaded: true,   
                })
              }
             
          });
    }
  }
ViewDetails(postid,opportunitytype){
  if(opportunitytype=="Post A Project"){
    sessionStorage.setItem("PostID",JSON.stringify(postid))
    //window.location.href=`./ProjectSummary`
    window.open(`./ProjectSummary`,"_blank")
  }
  else if(opportunitytype=="Post an Opportunity"){
    sessionStorage.setItem("PostOPP",JSON.stringify(postid))
  
    window.open(`./OpportunitySummary`,"_blank")
  }
  else if(opportunitytype=="Graduate Opportunities"){
    let encodeduserid = window.btoa(this.state.userid);
    let encodedpgoid = window.btoa(postid);
    window.open(`./gradoppindividualpage/${encodeduserid}/${encodedpgoid}`)
  }
  else{
    console.log(opportunitytype)
  }
  //sessionStorage.setItem("PostID",JSON.stringify(postid))
}
  showFilter(e){
    this.setState({DataisLoaded:true})
    if(e.target.value=="All"){
//console.log(e.target.value)
if(this.state.proposal==1){
  console.log(this.state.proposal)
   this.setState({datalimit:"0"},()=>{
     this.handleProposal()
   })
 }
 
 if(this.state.displayallopportunity==0 || this.state.displayallopportunity==2){
//console.log(this.state.displayallopportunity)
this.setState({datalimit:""})
   if(e.target.value=="All"){
     this.setState({datalimit:"0"},()=>{
       this.receivedData()
     })
    
   }
 else{
   this.setState({datalimit:e.target.value},()=>{
     this.receivedData()
     
   })
}

}
else if(this.state.pendingresult==1){
 this.setState({datalimit:""})
 if(e.target.value=="All"){
        this.setState({datalimit:0},()=>{
          this.handlePending()
        })
        
      }
      else{
        this.setState({datalimit:e.target.value},()=>{
          this.handlePending()
          
        })
      }

      }
      else if(this.state.pastresult==1){
      this.setState({datalimit:""})
      if(e.target.value=="All"){
        this.setState({datalimit:0},()=>{
          this.handlePast()
        })
        
      }
      else{
        this.setState({datalimit:e.target.value},()=>{
          this.handlePast()
          
        })
      }

      }
      else if(this.state.reject==1){
      this.setState({datalimit:""})
      console.log(this.state.reject)
      if(e.target.value=="All"){
        this.setState({datalimit:0},()=>{
          this.handleReject()
        })
        
      }else{
        this.setState({datalimit:e.target.value},()=>{
          this.handleReject()
          
        })
      }

      }
      else{
        console.log("empty")
      }
    }
  else if(e.target.value=="Project Related Opportunities"){
    this.setState({projectrelatedoppsearch:1})
         const sdata = {
 
    addcpydata:"getcpyallPAPinfo",
    userid:this.state.userid,
    datalimit:this.state.datalimit
    
     
    

   }
  // this.setState({DataisLoaded:true,})
  axios
      .post(`https://www.gigeconome.com:5200/api/addcpy_profiledata`,sdata)
      .then(res => {
        //console.log(res)
   
          const data = res.data.result;
          //console.log(data)
          this.setState({totalcount:res.data.totalcount})
          if(res.data.status==0){
            console.log("empty")
           
          }
          else{
            
            const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
            const postData = slice.map(e => 
            
              <React.Fragment>
           
           <div className="row">
          <div className="col-sm-1"></div>
            <div className="col-sm-10 displayallopp">
             <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Title of the Project: </span>
              <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"3%"}}>{e.titleofproject}</span><br /><br />
              <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Date Posted: </span>
              <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"9%"}}>{e.dateposted}</span>
              <button  onClick={()=>this.ViewDetails(e.postid,e.opportunitytype)} className="btn btn-success" style={{fontFamily:"GothamLight",fontWeight:"bold",color:"white",width:"20%",backgroundColor:"rgb(0,147,69)",float:"right",marginTop:"2%"}}>View Details </button> <br /><br />
              <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Opportunity Type: </span>
              <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"4%"}}>{e.opportunitytype}</span><br /><br />
              <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Status: </span>
              <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"14.5%"}}>{e.poststatus}</span>
              </div>  

          </div>
          
          
          
          
         
                   
                   
                
               </React.Fragment>
            )
  
            this.setState({
                pageCount: Math.ceil(data.length / this.state.perPage),
               
                postData, DataisLoaded: true,   
            })
          }
         
      });


  }
  else if(e.target.value=="Fixed Term Opportunities"){
    this.setState({projectrelatedoppsearch:0,fixedtermoppsearch:1})
         const sdata = {
 
    addcpydata:"getcpyallPAOinfo",
    userid:this.state.userid,
    datalimit:this.state.datalimit
    
     
    

   }
  // this.setState({DataisLoaded:true,})
  axios
      .post(`https://www.gigeconome.com:5200/api/addcpy_profiledata`,sdata)
      .then(res => {
        //console.log(res)
   
          const data = res.data.result;
          //console.log(data)
          this.setState({totalcount:res.data.totalcount})
          if(res.data.status==0){
            console.log("empty")
           
          }
          else{
            
            const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
            const postData = slice.map(e => 
            
              <React.Fragment>
           
           <div className="row">
          <div className="col-sm-1"></div>
            <div className="col-sm-10 displayallopp">
             <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Title of the Project: </span>
              <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"3%"}}>{e.titleofproject}</span><br /><br />
              <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Date Posted: </span>
              <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"9%"}}>{e.dateposted}</span>
              <button  onClick={()=>this.ViewDetails(e.postid,e.opportunitytype)} className="btn btn-success" style={{fontFamily:"GothamLight",fontWeight:"bold",color:"white",width:"20%",backgroundColor:"rgb(0,147,69)",float:"right",marginTop:"2%"}}>View Details </button> <br /><br />
              <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Opportunity Type: </span>
              <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"4%"}}>{e.opportunitytype}</span><br /><br />
              <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Status: </span>
              <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"14.5%"}}>{e.poststatus}</span>
              </div>  

          </div>
          
          
          
          
         
                   
                   
                
               </React.Fragment>
            )
  
            this.setState({
                pageCount: Math.ceil(data.length / this.state.perPage),
               
                postData, DataisLoaded: true,   
            })
          }
         
      });


  }
  else if(e.target.value=="Long Term Opportunities"){
    this.setState({projectrelatedoppsearch:0,fixedtermoppsearch:0,})
         const sdata = {
  
    addcpydata:"getcpyallLTOinfo",
    userid:this.state.userid,
    datalimit:this.state.datalimit
    
     
    
  
   }
  // this.setState({DataisLoaded:true,})
  axios
      .post(`https://www.gigeconome.com:5200/api/addcpy_profiledata`,sdata)
      .then(res => {
        //console.log(res)
   
          const data = res.data.result;
          //console.log(data)
          this.setState({totalcount:res.data.totalcount})
          if(res.data.status==0){
            console.log("empty")
           
          }
          else{
            
            const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
            const postData = slice.map(e => 
            
              <React.Fragment>
           
           <div className="row">
          <div className="col-sm-1"></div>
            <div className="col-sm-10 displayallopp">
             <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Title of the Project: </span>
              <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"3%"}}>{e.titleofproject}</span><br /><br />
              <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Date Posted: </span>
              <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"9%"}}>{e.dateposted}</span>
              <button  onClick={()=>this.ViewDetails(e.postid,e.opportunitytype)} className="btn btn-success" style={{fontFamily:"GothamLight",fontWeight:"bold",color:"white",width:"20%",backgroundColor:"rgb(0,147,69)",float:"right",marginTop:"2%"}}>View Details </button> <br /><br />
              <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Opportunity Type: </span>
              <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"4%"}}>{e.opportunitytype}</span><br /><br />
              <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Status: </span>
              <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"14.5%"}}>{e.poststatus}</span>
              </div>  
  
          </div>
          
          
          
          
         
                   
                   
                
               </React.Fragment>
            )
  
            this.setState({
                pageCount: Math.ceil(data.length / this.state.perPage),
               
                postData, DataisLoaded: true,   
            })
          }
         
      });
  
  
  }
    else{
      if(this.state.proposal==1){
        console.log(this.state.proposal)
         this.setState({datalimit:""},()=>{
           this.handleProposal()
         })
       }
      else if(this.state.displayallopportunity==0 || this.state.displayallopportunity==2){
      //console.log(this.state.displayallopportunity)
      this.setState({datalimit:""})
         if(e.target.value=="All"){
           this.setState({datalimit:0},()=>{
             this.receivedData()
           })
          
         }
       else{
         this.setState({datalimit:e.target.value},()=>{
           this.receivedData()
           
         })
      }
      
      }
      else if(this.state.pendingresult==1){
       this.setState({datalimit:""})
       if(e.target.value=="All"){
              this.setState({datalimit:0},()=>{
                this.handlePending()
              })
              
            }
            else{
              this.setState({datalimit:e.target.value},()=>{
                this.handlePending()
                
              })
            }
      
            }
            else if(this.state.pastresult==1){
            this.setState({datalimit:""})
            if(e.target.value=="All"){
              this.setState({datalimit:0},()=>{
                this.handlePast()
              })
              
            }
            else{
              this.setState({datalimit:e.target.value},()=>{
                this.handlePast()
                
              })
            }
      
            }
            else if(this.state.reject==1){
            this.setState({datalimit:""})
            console.log(this.state.reject)
            if(e.target.value=="All"){
              this.setState({datalimit:0},()=>{
                this.handleReject()
              })
              
            }else{
              this.setState({datalimit:e.target.value},()=>{
                this.handleReject()
                
              })
            }
      
            }
            else{
              console.log("empty")
            }
    }
  }

/* All Opportunity API Call  starts*/

  handleAllOpp(){
    this.setState({proposal:0,displayallopportunity:1,
      totalcount:"",showdata:"",viewinfo:"", searchtext:"",
      pendingresult:0,pastresult:0,reject:0,alloppsearchbar:0},()=>{
    this.receivedData()
   // console.log(this.state.displayallopportunity)
    })
 }
  /* All Opportunity API Call  Ends*/

  /* Open for Proposal Filter Api Starts*/
  handleProposalFilter(){
    this.setState({proposal:1,
    
     
      projectfiltersearch:0, 
      displayallopportunity:2,pendingresult:0,pastresult:0,reject:0,},()=>{

        const sdata = {
 
          addcpydata:"getsearchcpyopen4proposalinfo",
          userid:this.state.userid,
          sortorder:this.state.sortorder,
          viewinfo:this.state.viewinfo,
          searchtext:this.state.searchtext,
          pagenumber:this.state.currentPage+1,
          showdata:this.state.showdata,
          totalpage:this.state.pageCount
     
          
           
          
      
         }
         console.log(sdata)
        // this.setState({DataisLoaded:true,})
        axios
            .post(`https://www.gigeconome.com:5200/api/addcpy_profiledata`,sdata)
            .then(res => {
              console.log(res.data)
         
                const data = res.data.result;
              //  console.log(data)
                this.setState({totalcount:res.data.showingmsg,})
                if(res.data.status==0){
                  this.setState({postData:"abc"})
                  this.setState({totalcount:res.data.message})
                }
                else{
                  
                  const slice = data.slice(0, 0 + this.state.perPage)
                  const postData = slice.map(e => 
                  
                    <React.Fragment>
                 
                 <div className="row">
                <div className="col-sm-1"></div>
                  <div className="col-sm-10 displayallopp">
                   <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Title of the Project: </span>
                    <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"3%"}}>{e.titleofproject}</span><br /><br />
                    <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Date Posted: </span>
                    <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"9%"}}>{e.dateposted}</span>
                    <button className="btn btn-success" onClick={()=>this.ViewDetails(e.postid,e.opportunitytype)} style={{fontFamily:"GothamLight",fontWeight:"bold",color:"white",width:"20%",backgroundColor:"rgb(0,147,69)",float:"right",marginTop:"2%"}}>View Details </button> <br /><br />
                    <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Opportunity Type: </span>
                    <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"4%"}}>{e.opportunitytype}</span><br /><br />
                    <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Status: </span>
                    <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"14.5%"}}>{e.poststatus}</span>
                    </div>  
     
                </div>
                
                
                
                
               
                         
                         
                      
                     </React.Fragment>
                  )
        
                 
                
                   this.setState({
                     pageCount: Math.ceil(res.data.totalrow/ 10),
                    
                     postData, DataisLoaded: true,   
                 },()=>{
                   console.log(this.state.pageCount)
                 })
                             
                }
               
            });
      })
    


   }

  /* Open for Proposal Filter Api Ends*/
  /* Open for Proposal API Call  starts*/
  handleProposal(){
    this.setState({proposal:1,
     searchtext:"",
      projectfiltersearch:0, 
      totalcount:"",
      showdata:"",
      currentPage:"",
     
      displayallopportunity:2,pendingresult:0,pastresult:0,reject:0,},()=>{

        const sdata = {
 
          addcpydata:"getsearchcpyopen4proposalinfo",
          userid:this.state.userid,
          sortorder:this.state.sortorder,
          viewinfo:this.state.viewinfo,
          searchtext:this.state.searchtext,
          pagenumber:this.state.currentPage+1,
          showdata:this.state.showdata,
          totalpage:this.state.pageCount
     
          
           
          
      
         }
         console.log(sdata)
        // this.setState({DataisLoaded:true,})
        axios
            .post(`https://www.gigeconome.com:5200/api/addcpy_profiledata`,sdata)
            .then(res => {
              //console.log(res)
         
                const data = res.data.result;
                console.log(data)
                this.setState({totalcount:res.data.showingmsg,})
                if(res.data.status==0){
                  this.setState({postData:"abc"})
               this.setState({totalcount:res.data.message})
                }
                else{
                  
                  const slice = data.slice(0, 0 + this.state.perPage)
                  const postData = slice.map(e => 
                  
                    <React.Fragment>
                 
                 <div className="row">
                <div className="col-sm-1"></div>
                  <div className="col-sm-10 displayallopp">
                   <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Title of the Project: </span>
                    <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"3%"}}>{e.titleofproject}</span><br /><br />
                    <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Date Posted: </span>
                    <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"9%"}}>{e.dateposted}</span>
                    <button className="btn btn-success" onClick={()=>this.ViewDetails(e.postid,e.opportunitytype)} style={{fontFamily:"GothamLight",fontWeight:"bold",color:"white",width:"20%",backgroundColor:"rgb(0,147,69)",float:"right",marginTop:"2%"}}>View Details </button> <br /><br />
                    <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Opportunity Type: </span>
                    <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"4%"}}>{e.opportunitytype}</span><br /><br />
                    <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Status: </span>
                    <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"14.5%"}}>{e.poststatus}</span>
                    </div>  
     
                </div>
                
                
                
                
               
                         
                         
                      
                     </React.Fragment>
                  )
        
                  if(this.state.showdata==0){
                   this.setState({
                     pageCount: Math.ceil(res.data.totalrow/ res.data.totalcount),
                    
                     postData, DataisLoaded: true,   
                 },()=>{
                   console.log(this.state.pageCount)
                 })
                 }
                 else{
                   this.setState({
                     pageCount: Math.ceil(res.data.totalrow/ this.state.showdata),
                    
                     postData, DataisLoaded: true,   
                 },()=>{
                   console.log(this.state.pageCount)
                 })
                 }
             
                }
               
            });
      })
    


   }
  
  /* Open for Proposal API Call  Ends*/

/* Pending Filter API Call Starts */

handlePendingFilter(){
  this.setState({proposal:0,displayallopportunity:3,pendingresult:1,pastresult:0,reject:0,},()=>{
    const sdata = {

      addcpydata:"getsearchcpyPendinginfo",
     userid:this.state.userid,
     sortorder:this.state.sortorder,
     viewinfo:this.state.viewinfo,
     searchtext:this.state.searchtext,
     pagenumber:this.state.currentPage+1,
     showdata:this.state.showdata,
     totalpage:this.state.pageCount

      
       
      
  
     }
     console.log(sdata)
    // this.setState({DataisLoaded:true,})
    axios
        .post(`https://www.gigeconome.com:5200/api/addcpy_profiledata`,sdata)
        .then(res => {
          //console.log(res)
     
            const data = res.data.result;
            //console.log(data)
            this.setState({totalcount:res.data.showingmsg,})
            if(res.data.status==0){
              this.setState({postData:"abc"})
               this.setState({totalcount:res.data.message})
             
            }
            else{
              
              const slice = data.slice(0, 0 + this.state.perPage)
              const postData = slice.map(e => 
              
                <React.Fragment>
             
             <div className="row">
            <div className="col-sm-1"></div>
              <div className="col-sm-10 displayallopp">
               <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Title of the Project: </span>
                <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"3%"}}>{e.titleofproject}</span><br /><br />
                <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Date Posted: </span>
                <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"9%"}}>{e.dateposted}</span>
                <button className="btn btn-success"  onClick={()=>this.ViewDetails(e.postid,e.opportunitytype)} style={{fontFamily:"GothamLight",fontWeight:"bold",color:"white",width:"20%",backgroundColor:"rgb(0,147,69)",float:"right",marginTop:"2%"}}>View Details </button> <br /><br />
                <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Opportunity Type: </span>
                <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"4%"}}>{e.opportunitytype}</span><br /><br />
                <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Status: </span>
                <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"14.5%"}}>{e.poststatus}</span>
                </div>  
 
            </div>
            
            
            
            
           
                     
                     
                  
                 </React.Fragment>
              )
    
              if(this.state.showdata==0){
                this.setState({
                  pageCount: Math.ceil(res.data.totalrow/ res.data.totalcount),
                 
                  postData, DataisLoaded: true,   
              },()=>{
                console.log(this.state.pageCount)
              })
              }
              else{
                this.setState({
                  pageCount: Math.ceil(res.data.totalrow/ this.state.showdata),
                 
                  postData, DataisLoaded: true,   
              },()=>{
                console.log(this.state.pageCount)
              })
              }
            }
           
        });
  })
  

}

 /* Pending Filter API Call Ends */

  /* Pending API Call Starts */
  handlePending(){
    this.setState({proposal:0,displayallopportunity:3,pendingresult:1,pastresult:0,reject:0,viewinfo:"",totalcount:"",showdata:"",viewinfo:"", searchtext:"",},()=>{
      const sdata = {
 
        addcpydata:"getsearchcpyPendinginfo",
       userid:this.state.userid,
       sortorder:this.state.sortorder,
       viewinfo:this.state.viewinfo,
       searchtext:this.state.searchtext,
       pagenumber:this.state.currentPage+1,
       showdata:this.state.showdata,
       totalpage:this.state.pageCount
  
        
         
        
    
       }
       console.log(sdata)
      // this.setState({DataisLoaded:true,})
      axios
          .post(`https://www.gigeconome.com:5200/api/addcpy_profiledata`,sdata)
          .then(res => {
            //console.log(res)
       
              const data = res.data.result;
              //console.log(data)
              this.setState({totalcount:res.data.showingmsg,})
              if(res.data.status==0){
                this.setState({postData:"abc"})
                this.setState({totalcount:res.data.message})
               
              }
              else{
                
                const slice = data.slice(0, 0 + this.state.perPage)
                const postData = slice.map(e => 
                
                  <React.Fragment>
               
               <div className="row">
              <div className="col-sm-1"></div>
                <div className="col-sm-10 displayallopp">
                 <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Title of the Project: </span>
                  <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"3%"}}>{e.titleofproject}</span><br /><br />
                  <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Date Posted: </span>
                  <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"9%"}}>{e.dateposted}</span>
                  <button className="btn btn-success"  onClick={()=>this.ViewDetails(e.postid,e.opportunitytype)} style={{fontFamily:"GothamLight",fontWeight:"bold",color:"white",width:"20%",backgroundColor:"rgb(0,147,69)",float:"right",marginTop:"2%"}}>View Details </button> <br /><br />
                  <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Opportunity Type: </span>
                  <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"4%"}}>{e.opportunitytype}</span><br /><br />
                  <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Status: </span>
                  <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"14.5%"}}>{e.poststatus}</span>
                  </div>  
   
              </div>
              
              
              
              
             
                       
                       
                    
                   </React.Fragment>
                )
      
                if(this.state.showdata==0){
                  this.setState({
                    pageCount: Math.ceil(res.data.totalrow/ res.data.totalcount),
                   
                    postData, DataisLoaded: true,   
                },()=>{
                  console.log(this.state.pageCount)
                })
                }
                else{
                  this.setState({
                    pageCount: Math.ceil(res.data.totalrow/ this.state.showdata),
                   
                    postData, DataisLoaded: true,   
                },()=>{
                  console.log(this.state.pageCount)
                })
                }
              }
             
          });
    })
    
  
  }

   /* Pending API Call Ends */


/* Past Opportunities API Call Starts */
handlePast(){
  this.setState({proposal:0,displayallopportunity:4, searchtext:"",
    pastresult:1,pendingresult:0,reject:0},()=>{
      const sdata = {
 
   
        addcpydata:"getsearchcpyPastinfo",
        userid:this.state.userid,
        sortorder:this.state.sortorder,
        viewinfo:this.state.viewinfo,
        searchtext:this.state.searchtext,
        pagenumber:this.state.currentPage+1,
        showdata:this.state.showdata,
        totalpage:this.state.pageCount
        
         
        
    
       }
       console.log(sdata)
      // this.setState({DataisLoaded:true,})
      axios
          .post(`https://www.gigeconome.com:5200/api/addcpy_profiledata`,sdata)
          .then(res => {
            //console.log(res)
       
              const data = res.data.result;
              //console.log(data)
              this.setState({totalcount:res.data.showingmsg})
              if(res.data.status==0){
                console.log("empty")
               this.setState({postData:"abc"})
               this.setState({totalcount:res.data.message})
              }
              else{
                
                const slice = data.slice(0, 0 + this.state.perPage)
                const postData = slice.map(e => 
                
                  <React.Fragment>
               
               <div className="row">
              <div className="col-sm-1"></div>
                <div className="col-sm-10 displayallopp">
                 <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Title of the Project: </span>
                  <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"3%"}}>{e.titleofproject}</span><br /><br />
                  <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Date Posted: </span>
                  <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"9%"}}>{e.dateposted}</span>
                  <button  onClick={()=>this.ViewDetails(e.postid,e.opportunitytype)} className="btn btn-success" style={{fontFamily:"GothamLight",fontWeight:"bold",color:"white",width:"20%",backgroundColor:"rgb(0,147,69)",float:"right",marginTop:"2%"}}>View Details </button> <br /><br />
                  <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Opportunity Type: </span>
                  <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"4%"}}>{e.opportunitytype}</span><br /><br />
                  <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Status: </span>
                  <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"14.5%"}}>{e.poststatus}</span>
                  </div>  
    
              </div>
              
              
              
              
             
                       
                       
                    
                   </React.Fragment>
                )
      
                if(this.state.showdata==0){
                  this.setState({
                    pageCount: Math.ceil(res.data.totalrow/ res.data.totalcount),
                   
                    postData, DataisLoaded: true,   
                },()=>{
                  console.log(this.state.pageCount)
                })
                }
                else{
                  this.setState({
                    pageCount: Math.ceil(res.data.totalrow/ this.state.showdata),
                   
                    postData, DataisLoaded: true,   
                },()=>{
                  console.log(this.state.pageCount)
                })
                }
              }
             
          });
    })
  


}


 /* Past Opportunities API Call Ends */



 /* Reject API Call Starts */
 handleReject(){
  this.setState({proposal:0,displayallopportunity:5,pendingresult:0,
    pastresult:0,reject:1,postMessage:"",totalcount:"",showdata:"",viewinfo:"",searchtext:""},()=>{
      const sdata = {
 
        addcpydata:"getsearchcpyRejectinfo",
        userid:this.state.userid,
        sortorder:this.state.sortorder,
        viewinfo:this.state.viewinfo,
        searchtext:this.state.searchtext,
        pagenumber:this.state.currentPage+1,
        showdata:this.state.showdata,
        totalpage:this.state.pageCount
        
         
        
    
       }
       console.log(sdata)
      // this.setState({DataisLoaded:true,})
      axios
          .post(`https://www.gigeconome.com:5200/api/addcpy_profiledata`,sdata)
          .then(res => {
            //console.log(res)
       
              const data = res.data.result;
              //console.log(data)
              this.setState({totalcount:res.data.showingmsg})
              if(res.data.status==0){
                this.setState({postData:"abc"})
               this.setState({totalcount:res.data.message})
               
              }
              else{
                
                const slice = data.slice(0, 0 + this.state.perPage)
                const postData = slice.map(e => 
                
                  <React.Fragment>
               
               <div className="row">
              <div className="col-sm-1"></div>
                <div className="col-sm-10 displayallopp">
                 <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Title of the Project: </span>
                  <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"3%"}}>{e.titleofproject}</span><br /><br />
                  <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Date Posted: </span>
                  <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"9%"}}>{e.dateposted}</span>
                  <button  onClick={()=>this.ViewDetails(e.postid,e.opportunitytype)} className="btn btn-success" style={{fontFamily:"GothamLight",fontWeight:"bold",color:"white",width:"20%",backgroundColor:"rgb(0,147,69)",float:"right",marginTop:"2%"}}>View Details </button> <br /><br />
                  <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Opportunity Type: </span>
                  <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"4%"}}>{e.opportunitytype}</span><br /><br />
                  <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Status: </span>
                  <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"14.5%"}}>{e.poststatus}</span> <br/> <br/>
                  <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Rejected By : </span>
                  <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"8.5%"}}>{e.rejectedby}</span> <br/>
                  
                  </div>  
    
              </div>
              
              
              
              
             
                       
                       
                    
                   </React.Fragment>
                )
      
                if(this.state.showdata==0){
                  this.setState({
                    pageCount: Math.ceil(res.data.totalrow/ res.data.totalcount),
                   
                    postData, DataisLoaded: true,   
                },()=>{
                  console.log(this.state.pageCount)
                })
                }
                else{
                  this.setState({
                    pageCount: Math.ceil(res.data.totalrow/ this.state.showdata),
                   
                    postData, DataisLoaded: true,   
                },()=>{
                  console.log(this.state.pageCount)
                })
                }
            
              }
             
          }); 
    
    })
 
 
  
}



 /* Reject API Call API Call Ends */


  receivedData() {
    //this.setState({currentPage:1})
    //console.log(this.state.currentPage+1)
    const sdata = {
  
      addcpydata:"getsearchcpyallopportunitiesinfo",
      userid:this.state.userid,
      sortorder:this.state.sortorder,
      viewinfo:this.state.viewinfo,
      searchtext:this.state.searchtext,
      showdata:this.state.showdata,
      pagenumber:this.state.currentPage+1,
      totalpage:this.state.pageCount
      
       
      
  
     }
     console.log(sdata)
     //this.setState({DataisLoaded:true})
    axios
        .post(`https://www.gigeconome.com:5200/api/addcpy_profiledata`,sdata)
        .then(res => {
          //console.log(res)
     
            const data = res.data.result;
            console.log(res.data)
            this.setState({totalcount:res.data.showingmsg, perPage:res.data.totalcount,abc:res.data.totalrow})
            if(res.data.status==0){
              console.log("empty")
              this.setState({displayallopportunity:1,apiopp:1})
            }
            else{
              this.setState({displayallopportunity:0,proposal:0})
              const slice = data.slice(0, 0 + this.state.perPage)
              const postData = slice.map(e => 
              
                <React.Fragment>
             
             <div className="row">
            <div className="col-sm-1"></div>
              <div className="col-sm-10 displayallopp">
               <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Title of the Project: </span>
                <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"3%"}}>{e.titleofproject}</span><br /><br />
                <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Date Posted: </span>
                <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"9%"}}>{e.dateposted}</span>
                <button className="btn btn-success" style={{fontFamily:"GothamLight",fontWeight:"bold",color:"white",width:"20%",backgroundColor:"rgb(0,147,69)",float:"right",marginTop:"2%"}}
                onClick={()=>this.ViewDetails(e.postid,e.opportunitytype)}
                >View Details </button> <br /><br />
                <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Opportunity Type: </span>
                <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"4%"}}>{e.opportunitytype}</span><br /><br />
                <span style={{fontSize:18,fontFamily:"Gotham",color:"rgb(0,147,69)"}}>Status: </span>
                <span style={{fontSize:14,fontFamily:"Gotham",paddingLeft:"14.5%"}}>{e.poststatus}</span>
                </div>  

            </div>
            
            
            
            
           
                     
                     
                  
                 </React.Fragment>
              )
    if(this.state.showdata==0){
      this.setState({
        pageCount: Math.ceil(res.data.totalrow/ res.data.totalcount),
       
        postData, DataisLoaded: true,   
    },()=>{
      console.log(this.state.pageCount)
    })
    }
    else{
      this.setState({
        pageCount: Math.ceil(res.data.totalrow/ this.state.showdata),
       
        postData, DataisLoaded: true,   
    },()=>{
      console.log(this.state.pageCount)
    })
    }
           
          }
           
        });
  }
  handlePageClick = (e) => {
    if(this.state.proposal==1){
      const selectedPage = e.selected;
      console.log(selectedPage)
     const offset = selectedPage * this.state.perPage;
     console.log(this.state.pageCount)
     window.scrollTo({
       top: 0,
       behavior: 'smooth' // for smoothly scrolling
     });
     this.setState({
         currentPage: selectedPage,
         offset: offset
     }, () => {
       //console.log(this.state.offset)
         this.handleProposalFilter()
     });
    }
   if(this.state.displayallopportunity==0){
    const selectedPage = e.selected;
    console.log(selectedPage)
   const offset = selectedPage * this.state.perPage;
   //console.log(this.state.pageCount)
   window.scrollTo({
     top: 0,
     behavior: 'smooth' // for smoothly scrolling
   });
   this.setState({
       currentPage: selectedPage,
       offset: offset
   }, () => {
     //console.log(this.state.offset)
       this.receivedData()
   });
   }
   if(this.state.pendingresult==1){
    const selectedPage = e.selected;
    console.log(selectedPage)
   const offset = selectedPage * this.state.perPage;
   //console.log(this.state.pageCount)
   window.scrollTo({
     top: 0,
     behavior: 'smooth' // for smoothly scrolling
   });
   this.setState({
       currentPage: selectedPage,
       offset: offset
   }, () => {
     //console.log(this.state.offset)
       this.handlePendingFilter()
   });
   }

   if(this.state.pastresult==1){
    const selectedPage = e.selected;
    console.log(selectedPage)
   const offset = selectedPage * this.state.perPage;
   //console.log(this.state.pageCount)
   window.scrollTo({
     top: 0,
     behavior: 'smooth' // for smoothly scrolling
   });
   this.setState({
       currentPage: selectedPage,
       offset: offset
   }, () => {
     //console.log(this.state.offset)
       this.handlePast()
   });
   }

   if(this.state.reject==1){
    const selectedPage = e.selected;
    console.log(selectedPage)
   const offset = selectedPage * this.state.perPage;
   //console.log(this.state.pageCount)
   window.scrollTo({
     top: 0,
     behavior: 'smooth' // for smoothly scrolling
   });
   this.setState({
       currentPage: selectedPage,
       offset: offset
   }, () => {
     //console.log(this.state.offset)
       this.handleReject()
   });
   }
  };

 

 componentDidMount(){
this.receivedData()
    const data_ten={userid:this.state.userid}
    fetch('https://www.gigeconome.com:5200/api/getempcompanydetails', { method: 'POST', 
  
    body: JSON.stringify(data_ten), // data can be `string` or {object}!
  
    headers:{ 'Content-Type': 'application/json' } })
  
    .then(res => res.json())
  
    .catch(error => console.error('Error:', error))
  
    .then((json)=>{
     
    this.setState({
   
     cpybanner:json.result.cpybanner,
      empmessage:json.message
    })
                     
            
        })

        /* Get All Opportunity Data API Call 

        const getoppdata={
          addcpydata:"getcpyallopportunitiesinfo",
          userid:this.state.userid}
        fetch('https://www.gigeconome.com:5200/api/addcpy_profiledata', { method: 'POST', 
      
        body: JSON.stringify(getoppdata), // data can be `string` or {object}!
      
        headers:{ 'Content-Type': 'application/json' } })
      
        .then(res => res.json())
      
        .catch(error => console.error('Error:', error))
      
        .then((response)=>{
         
       
                if(response.status==0){
                  console.log("empty")
                  this.setState({displayallopportunity:1})
                }
                else{
                  this.setState({selectOpportunity:response.result,displayallopportunity:0},()=>{
                    //console.log(this.state.selectOpportunity)
                  })
                }        
                
            })
            */
 }
  render() {
    const { editorState } = this.state;
    return (
      <div>
<CompanyNav />
        <div style={{ marginTop: 130 }}>
        <CompanyOppMenu />
        </div>
      
     

        <main style={{ backgroundColor: "#333333",
        backgroundImage:this.state.cpybanner==""
        ?`url('${BackgroundImage}')`:`url('${this.state.cpybanner}')`,
        
               backgroundRepeat:this.state.postData==""?null:"no-repeat",
                
                backgroundSize:this.state.postData==""?null:"contain",
               
        borderBottom:"6px solid rgb(0,147,69)" }}>
        <br /><br />
        
        <div className="container">
          <div className="py-2" style={{padding: '2%'}}>
            <div className="row">
             
              
             
              <div className={this.state.tabcontent==0 || this.state.supportcontent==0?"col-sm-12":"col-sm-10"} style={{background: '#fff',marginTop:150,
              
              
            width:this.state.tabcontent==1 || this.state.supportcontent==1?"82%":null,
            marginLeft:this.state.tabcontent==1 || this.state.supportcontent==1?"1%":null
            
            }}>

            {/* Opportunity Box Start */}
                <div className="row"> 
                <div className="col-sm-1">
             </div>
                <div className={this.state.displayallopportunity==0 || this.state.displayallopportunity==1?"col-sm-2 oppheading":"col-sm-2 withoutclickoppheading"} >
                    <span onClick={this.handleAllOpp}>All Opportunities</span>        
                </div>
                <div className={this.state.proposal==1?"col-sm-2 oppheading":"col-sm-2 withoutclickoppheading"}>
                <span onClick={this.handleProposal}>Open for Proposal</span> 
                </div>
                <div className={this.state.pendingresult==1?"col-sm-2 oppheading":"col-sm-2 withoutclickoppheading"}>
                <span onClick={this.handlePending}>Pending</span> 
                </div>
                <div className={this.state.pastresult==1?"col-sm-2 oppheading":"col-sm-2 withoutclickoppheading"}>
                <span onClick={this.handlePast}>Past Opportunities</span> 
                </div>
                <div className={this.state.reject==1?"col-sm-2 oppheading":"col-sm-2 withoutclickoppheading"}>
                <span onClick={this.handleReject}>Rejected</span> 
                </div>
                <div className="col-sm-1">

                </div>
                </div>
                <div className="row" style={{marginTop:"3%"}}> 
                <div className="col-sm-1">
             </div>
             {this.state.displayallopportunity==0?<>
              <div className="col-sm-5 mt-4" style={{marginLeft:"-1%"}}>
                
              {this.state.alloppsearchbar==1?<>
                <div class="input-group">
          <input type="text" name="searchtext"
          class="form-control"
          onChange = {this.handleChange}
         
                        
          autoComplete="off"
          style={{height:39,border:"1px solid"}}
          placeholder="Search Opportunities" />
          <div class="input-group-append">
          <button class="btn btn-secondary"
         onClick={(e)=>this.showFilterOpp(e)}
          type="submit" style={{borderLeft:"none",backgroundColor:"white",color:"black",
          height:39,marginLeft:"0%",borderRadius:"1px 0px 1px 0px"}}>
            <i class="fa fa-search"></i>
          </button>
          </div>
          </div>
              </>:<>
              <div class="input-group">
          <input type="text" name="searchtext"
          class="form-control"
          onChange = {this.handleChange}
          onKeyPress={event => {
            if (event.key === "Enter") {
              this.handleSearch();
            }
          }}
                        
          autoComplete="off"
          style={{height:39,border:"1px solid"}}
          placeholder="Search Opportunities" />
          <div class="input-group-append">
          <button class="btn btn-secondary"
         onClick={this.handleSearch}
          type="submit" style={{borderLeft:"none",backgroundColor:"white",color:"black",
          height:39,marginLeft:"0%",borderRadius:"1px 0px 1px 0px"}}>
            <i class="fa fa-search"></i>
          </button>
          </div>
          </div>
              </>}
               
                </div>
                <div className="col-sm-1 mt-4" style={{padding:"0.5%"}}>
                <span style={{fontSize:14,fontFamily:"GothamLight",fontWeight:"bold",padding:"0.5%"}}>Sort : </span>
                </div>
                <div className="col-sm-2 mt-4" style={{marginLeft:"-5%",width:"10%"}}>
                <select className="form-select"
              
                onChange={(e)=>this.showsortOpp(e)}>
              
              <option value="1">Earliest</option>
              <option value="2">Latest</option>
              
            
          </select>
                </div>
                <div className="col-sm-1 mt-4" style={{padding:"0.5%"}}>
                <span style={{fontSize:14,fontFamily:"GothamLight",fontWeight:"bold"}}>Show : </span>
               
                </div>
                <div className="col-sm-1 mt-4" style={{marginLeft:"-4%"}}>
                <select className="form-select" onChange={(e)=>this.showFilterDropdownOpp(e)}>
              
                    <option value="0">All</option>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                   
                  
                </select>
                </div>
                <div className="col-sm-1 mt-4" style={{padding:"0.5%"}}>
                <span style={{fontSize:14,fontFamily:"GothamLight",fontWeight:"bold"}}>View : </span>
               
                </div>
                <div className="col-sm mt-4" style={{marginLeft:"-5%"}}>
                <select className="form-select" style={{width:"60%"}} onChange={(e)=>this.showFilterOpp(e)}>
                <option value="0">All</option>
                <option value="1">Project Related Opportunities</option>
                <option value="2">Fixed Term Opportunities</option>
                <option value="3">Long Term Opportunities</option>
            
                </select>
                </div>
                </>:null}
                




                {this.state.proposal==1?<>
              <div className="col-sm-5 mt-4" style={{marginLeft:"-1%"}}>
                
                {this.state.projectfiltersearch==1 || this.state.oppfiltersearch==1?<>

                  <div class="input-group">
          <input type="text" name="searchtext"
          class="form-control"
          onChange = {this.handleChange}
          onKeyPress={event => {
            if (event.key === "Enter") {
              this.handleSearchProposal();
            }
          }}
                        
          autoComplete="off"
          style={{height:39,border:"1px solid"}}
          placeholder="Search Opportunities" />
          <div class="input-group-append">
          <button class="btn btn-secondary"
         onClick={this.handleSearchProposal}
          type="submit" style={{borderLeft:"none",backgroundColor:"white",color:"black",
          height:39,marginLeft:"0%",borderRadius:"1px 0px 1px 0px"}}>
            <i class="fa fa-search"></i>
          </button>
          </div>
          </div>
                </>:<>
                
                <div class="input-group">
          <input type="text" name="searchtext"
          class="form-control"
          onChange = {this.handleChange}
          onKeyPress={event => {
            if (event.key === "Enter") {
              this.handleSearch();
            }
          }}
                        
          autoComplete="off"
          style={{height:39,border:"1px solid"}}
          placeholder="Search Opportunities" />
          <div class="input-group-append">
          <button class="btn btn-secondary"
         onClick={this.handleSearch}
          type="submit" style={{borderLeft:"none",backgroundColor:"white",color:"black",
          height:39,marginLeft:"0%",borderRadius:"1px 0px 1px 0px"}}>
            <i class="fa fa-search"></i>
          </button>
          </div>
          </div>
                </>}
              
            
                </div>
                <div className="col-sm-1 mt-4" style={{padding:"0.5%"}}>
                <span style={{fontSize:14,fontFamily:"GothamLight",fontWeight:"bold",padding:"0.5%"}}>Sort : </span>
                </div>
                <div className="col-sm-2 mt-4" style={{marginLeft:"-5%",width:"10%"}}>
                <select className="form-select"
              
                onChange={(e)=>this.showsortOpp(e)}>
              
              <option value="1">Earliest</option>
              <option value="2">Latest</option>
              
            
          </select>
                </div>
                <div className="col-sm-1 mt-4" style={{padding:"0.5%"}}>
                <span style={{fontSize:14,fontFamily:"GothamLight",fontWeight:"bold"}}>Show : </span>
               
                </div>
                <div className="col-sm-1 mt-4" style={{marginLeft:"-4%"}}>
                <select className="form-select" onChange={(e)=>this.showFilterproposal(e)}>
              
                    <option value="0">All</option>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                   
                  
                </select>
                </div>
                <div className="col-sm-1 mt-4" style={{padding:"0.5%"}}>
                <span style={{fontSize:14,fontFamily:"GothamLight",fontWeight:"bold"}}>View : </span>
               
                </div>
                <div className="col-sm mt-4" style={{marginLeft:"-5%"}}>
                <select className="form-select" style={{width:"60%"}} onChange={(e)=>this.showFilterOppViewproposal(e)}>
                <option value="0">All</option>
                <option value="1">Project Related Opportunities</option>
                <option value="2">Fixed Term Opportunities</option>
                <option value="3">Long Term Opportunities</option>
            
                </select>
                </div>
                </>:null}

                {this.state.pendingresult==1?<>
              <div className="col-sm-5 mt-4" style={{marginLeft:"-1%"}}>
              <div class="input-group">
          <input type="text" name="searchtext"
          class="form-control"
          onChange = {this.handleChange}
          onKeyPress={event => {
            if (event.key === "Enter") {
              this.handleSearch();
            }
          }}
                        
          autoComplete="off"
          style={{height:39,border:"1px solid"}}
          placeholder="Search Opportunities" />
          <div class="input-group-append">
          <button class="btn btn-secondary"
         onClick={this.handleSearch}
          type="submit" style={{borderLeft:"none",backgroundColor:"white",color:"black",
          height:39,marginLeft:"0%",borderRadius:"1px 0px 1px 0px"}}>
            <i class="fa fa-search"></i>
          </button>
          </div>
          </div>
                </div>


                <div className="col-sm-1 mt-4" style={{padding:"0.5%"}}>
                <span style={{fontSize:14,fontFamily:"GothamLight",fontWeight:"bold",padding:"0.5%"}}>Sort : </span>
                </div>
                <div className="col-sm-2 mt-4" style={{marginLeft:"-5%",width:"10%"}}>
                <select className="form-select"
              
                onChange={(e)=>this.showsortOpp(e)}>
              
              <option value="1">Earliest</option>
              <option value="2">Latest</option>
              
            
          </select>
                </div>
                <div className="col-sm-1 mt-4" style={{padding:"0.5%"}}>
                <span style={{fontSize:14,fontFamily:"GothamLight",fontWeight:"bold"}}>Show : </span>
               
                </div>
                <div className="col-sm-1 mt-4" style={{marginLeft:"-4%"}}>
                <select className="form-select" onChange={(e)=>this.showFilterDropdownpending(e)}>

              
                    <option value="0">All</option>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                   
                  
                </select>
                </div>
                <div className="col-sm-1 mt-4" style={{padding:"0.5%"}}>
                <span style={{fontSize:14,fontFamily:"GothamLight",fontWeight:"bold"}}>View : </span>
               
                </div>
                <div className="col-sm mt-4" style={{marginLeft:"-5%"}}>
                <select className="form-select" style={{width:"60%"}} onChange={(e)=>this.showFilterOpppending(e)}>
                <option value="0">All</option>
                <option value="1">Project Related Opportunities</option>
                <option value="2">Fixed Term Opportunities</option>
                <option value="3">Long Term Opportunities</option>
            
                </select>
                </div>
                </>:null}
                {this.state.pastresult==1?<>
                  <div className="col-sm-5 mt-4" style={{marginLeft:"-1%"}}>
              <div class="input-group">
          <input type="text" name="searchtext"
          class="form-control"
          onChange = {this.handleChange}
          onKeyPress={event => {
            if (event.key === "Enter") {
              this.handleSearch();
            }
          }}
                        
          autoComplete="off"
          style={{height:39,border:"1px solid"}}
          placeholder="Search Opportunities" />
          <div class="input-group-append">
          <button class="btn btn-secondary"
         onClick={this.handleSearch}
          type="submit" style={{borderLeft:"none",backgroundColor:"white",color:"black",
          height:39,marginLeft:"0%",borderRadius:"1px 0px 1px 0px"}}>
            <i class="fa fa-search"></i>
          </button>
          </div>
          </div>
                </div>


                <div className="col-sm-1 mt-4" style={{padding:"0.5%"}}>
                <span style={{fontSize:14,fontFamily:"GothamLight",fontWeight:"bold",padding:"0.5%"}}>Sort : </span>
                </div>
                <div className="col-sm-2 mt-4" style={{marginLeft:"-5%",width:"10%"}}>
                <select className="form-select"
              
                onChange={(e)=>this.showsortOpp(e)}>
              
              <option value="1">Earliest</option>
              <option value="2">Latest</option>
              
            
          </select>
                </div>
                <div className="col-sm-1 mt-4" style={{padding:"0.5%"}}>
                <span style={{fontSize:14,fontFamily:"GothamLight",fontWeight:"bold"}}>Show : </span>
               
                </div>
                <div className="col-sm-1 mt-4" style={{marginLeft:"-4%"}}>
                <select className="form-select" onChange={(e)=>this.showFilterDropdownpast(e)}>

              
                    <option value="0">All</option>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                   
                  
                </select>
                </div>
                <div className="col-sm-1 mt-4" style={{padding:"0.5%"}}>
                <span style={{fontSize:14,fontFamily:"GothamLight",fontWeight:"bold"}}>View : </span>
               
                </div>
                <div className="col-sm mt-4" style={{marginLeft:"-5%"}}>
                <select className="form-select" style={{width:"60%"}} onChange={(e)=>this.showFilterOpppast(e)}>
                <option value="0">All</option>
                <option value="1">Project Related Opportunities</option>
                <option value="2">Fixed Term Opportunities</option>
                <option value="3">Long Term Opportunities</option>
            
                </select>
                </div>
                </>:null}

                {this.state.reject==1?<>
                  <div className="col-sm-5 mt-4" style={{marginLeft:"-1%"}}>
              <div class="input-group">
          <input type="text" name="searchtext"
          class="form-control"
          onChange = {this.handleChange}
          onKeyPress={event => {
            if (event.key === "Enter") {
              this.handleSearch();
            }
          }}
                        
          autoComplete="off"
          style={{height:39,border:"1px solid"}}
          placeholder="Search Opportunities" />
          <div class="input-group-append">
          <button class="btn btn-secondary"
         onClick={this.handleSearch}
          type="submit" style={{borderLeft:"none",backgroundColor:"white",color:"black",
          height:39,marginLeft:"0%",borderRadius:"1px 0px 1px 0px"}}>
            <i class="fa fa-search"></i>
          </button>
          </div>
          </div>
                </div>


                <div className="col-sm-1 mt-4" style={{padding:"0.5%"}}>
                <span style={{fontSize:14,fontFamily:"GothamLight",fontWeight:"bold",padding:"0.5%"}}>Sort : </span>
                </div>
                <div className="col-sm-2 mt-4" style={{marginLeft:"-5%",width:"10%"}}>
                <select className="form-select"
              
                onChange={(e)=>this.showsortOpp(e)}>
              
              <option value="1">Earliest</option>
              <option value="2">Latest</option>
              
            
          </select>
                </div>
                <div className="col-sm-1 mt-4" style={{padding:"0.5%"}}>
                <span style={{fontSize:14,fontFamily:"GothamLight",fontWeight:"bold"}}>Show : </span>
               
                </div>
                <div className="col-sm-1 mt-4" style={{marginLeft:"-4%"}}>
                <select className="form-select" onChange={(e)=>this.showFilterDropdownreject(e)}>

              
                    <option value="0">All</option>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                   
                  
                </select>
                </div>
                <div className="col-sm-1 mt-4" style={{padding:"0.5%"}}>
                <span style={{fontSize:14,fontFamily:"GothamLight",fontWeight:"bold"}}>View : </span>
               
                </div>
                <div className="col-sm mt-4" style={{marginLeft:"-5%"}}>
                <select className="form-select" style={{width:"60%"}} onChange={(e)=>this.showFilterOppreject(e)}>
                <option value="0">All</option>
                <option value="1">Project Related Opportunities</option>
                <option value="2">Fixed Term Opportunities</option>
                <option value="3">Long Term Opportunities</option>
            
                </select>
                </div>
                </>:null}
                </div>
                {this.state.displayallopportunity==1 &&this.state.apiopp==1  ?<>
               
                <div className="row">
                    <div className="col-sm-1"></div>
                    <div className="col-sm-10 insidecontent">
                        <div className="row">
                        <div className="col-sm-2"></div>
                        <div className="col-sm-8" style={{marginTop:"10%",textAlign:"center"}}>
                            <span  style={{fontFamily:"Gotham",fontSize:24,marginTop:"10%"}}>Create a New Opportunity</span> <br />
                      <span style={{fontFamily:"GothamLight",fontSize:15,fontWeight:"bold"}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do</span> <br/> <br/><br/>
                            <Link to="/postaproject" className="opportunitydashboardbtn">Post a Opportunity</Link>                        
                        </div>
                        <div className="col-sm-2"></div>
                        </div>
                    </div>

                </div>
            </>:<>
           
              <div className="row">
              <h6 className="searchresultopp">
                {this.state.totalcount==0?<>No Opportunities Found</>:<>
               {this.state.totalcounttwentyfive=="25" || this.state.totalcounttwentyfive=="10" 
               ?<>
 {this.state.totalcount} 
                </>:<>
                {this.state.totalcount} 
                </>} 
               
                </>}
                </h6> 

            </div>
            {this.state.DataisLoaded==false?<>
              <div class="text-center">
         <img src={LoadingIcon} className="loadingicon"/> </div>
            </>:
            
            <>
            {this.state.postData=="abc"?<>
          <span style={{visibility:"hidden"}} >{this.state.postData}</span> 
            </>:<>{this.state.postData}</>}
            </>}
            
           {this.state.postData=="" || this.state.postData=="abc"?<>
           <div className="row" style={{visibility:"hidden"}} >
             <div className="col-sm-4"></div>
             <div className="col-sm-4" style={{marginTop:"5%",marginLeft:"-10%"}}>
           
              <ReactPaginate
                  previousLabel={"prev"}
                  nextLabel={"next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  initialPage={0}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}/>
       
             </div>
             <div className="col-sm-4"></div>
           </div>
           </>:<>
           <div className="row">
             <div className="col-sm-4"></div>
             <div className="col-sm-4" style={{marginTop:"5%",marginLeft:"-10%"}}>
           
              <ReactPaginate
                  previousLabel={"prev"}
                  nextLabel={"next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  initialPage={0}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}/>
       
             </div>
             <div className="col-sm-4"></div>
           </div>
           
           </>}
        
            
           
            
           
           
            </>}

            


          
          
         
      

 





             {/* Compose Email Design Starts */}



             {this.state.emailCompose==1?<>
             <div className="row">
               <div className="col-sm-4"></div>
               <div className="col-sm-4" style={{marginTop:"5%",marginLeft:"8%"}}>
               <span style={{fontSize:25,fontFamily:"Gotham"}}>Create a Proposal</span>
               </div>
               <div className="col-sm-4"></div>
             </div>
            <div className="row">
              <div className="col-sm-1">

              </div>
              <div className="col-sm-10 mt-4" style={{border:"1px solid rgb(0,147,69)",height:"43rem"}}>
                  <div className="row mt-4">
                  <div className="col-sm-1">
                     
                     </div>
                    <div className="col-sm-1 mt-3">
                      <span style={{fontFamily:"GothamLight",fontWeight:"bold",fontSize:20}}>Date : </span> <br/>
                      
                    </div>
                    <div className="col-sm-3 mt-3">
                     <input type="date" className="form-control" style={{border:"1px solid rgb(0,147,69)"}}/>
                    </div>
                    <div className="col-sm-1">
                     
                     </div>
                    <div className="col-sm-2 mt-3">
                      <span style={{fontFamily:"GothamLight",fontWeight:"bold",fontSize:20}}>Price Type :  </span>
                    </div>
                    <div className="col-sm-3 mt-3">
                     <select className="form-select" style={{border:"1px solid rgb(0,147,69)"}}>
                     <option>Select</option>
                     <option>Fixed</option>
                     <option>Hourly</option>
                     <option>Custom</option></select>
                    
                    </div>
                  </div>
                  <div className="row mt-4">
                  <div className="col-sm-1">
                     
                     </div>
                    <div className="col-sm-2 mt-3">
                      <span style={{fontFamily:"GothamLight",fontWeight:"bold",fontSize:20}}>Proposal ID : </span> <br/>
                      
                    </div>
                    <div className="col-sm-3 mt-3">
                     <input type="text" className="form-control" style={{border:"1px solid rgb(0,147,69)"}} value={this.state.proposalid} disabled/>
                    </div>
                  
                    <div className="col-sm-5 mt-3">
                     
                    </div>
                    
                  </div>
                  <hr className="messsagehr" />
                  <div className="row">
                    <div className="col-sm-1"></div>
                    <div className="col-sm-4 mt-3">
                      <span style={{fontFamily:"GothamLight",fontWeight:"bold",fontSize:20}}>Item </span> <br/>
                      <input className="form-control mt-2" style={{border:"1px solid rgb(0,147,69)"}} placeholder="Enter The Item" />
                    </div>
                    <div className="col-sm-1"></div>
                    <div className="col-sm-2 mt-3">
                    <span style={{fontFamily:"GothamLight",fontWeight:"bold",fontSize:20}}>Amount </span>
                    <select className="form-select mt-2" style={{border:"1px solid rgb(0,147,69)"}}>
                      <option>GBP</option>
                    </select>
                    </div>
                    <div className="col-sm-3 mt-3">
                     <br />
                      <input className="form-control" style={{marginTop:"6%",border:"1px solid rgb(0,147,69)"}} type="number" min="0" placeholder="Enter The Amount" />
                      
                    </div>
                   
                  </div>
                  <div className="row">
                    <div className="col-sm-1"></div>
                    <div className="col-sm-10">
                      <textarea className="form-control mt-4" style={{border:"1px solid rgb(0,147,69)"}} placeholder="Type Message..." rows="5">

                      </textarea>
                    </div>
                    <div className="col-sm-1"></div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-sm-1"></div>
                    <div className="col-sm-3">
                      <span style={{fontSize:20,fontFamily:"GothamLight",fontWeight:"bold"}}>Attachment : </span>
                    </div>

                    <div className="col-sm-4">
                      <input type="file" style={{border:"1px solid rgb(0,147,69)"}} className="form-control" />
                    </div>
                    <div className="col-sm-1"></div>
                  </div>
                  <div className="row">
               <div className="col-sm-4"></div>
               <div className="col-sm-4" style={{marginTop:"10%"}}>
              <button className="btn btn-success" style={{fontFamily:"Gotham",width:"100%",backgroundColor:"rgb(0,147,69)"}}>Send Proposal</button>
               </div>
               <div className="col-sm-4"></div>
             </div>
                </div>
                <div className="col-sm-1">

              </div>

            </div>
          
            
         
           

             
             
             </>:<></>}
             
              {this.state.showEmail==1?<>
                <h3 style={{fontSize:20,
    fontFamily:"Gotham",padding:"2%"}}
   
    >{this.state.Subjecttitle}</h3> 
    {/* Project Summary Starts */}
   <div style={{padding:"2%"}}>
  <hr/>
   <h6 style={{fontFamily:"Gotham"}}><span>From: {this.state.sendername}</span> 
<span style={{float:"right"}}>Date:{this.state.sentdate}</span></h6>
<hr/> 
   
            <div>
              <img src={Logo} style={{width:"20%"}} />
            </div> <br/>
            <span style={{fontFamily:"Gotham",fontSize:15}}>
            Good News! Your Profile is a match for this Project Proposal.

            </span><br/>
            <span style={{fontFamily:"Gotham",fontSize:15}}>
            If You are interested in this project, please reply.


            </span> <br /> <br />
            <span style={{fontFamily:"Gotham",fontSize:15}}>
            Requester:   <Link 
                         target="_blank"
                         to={{ 
                          
                           pathname: `/companypublishprofile/${this.state.senderid}`, 
                            
                         }} style={{fontFamily:"GothamBold",fontSize:15,textDecoration:"underline",color:"black"}}>
                           {this.state.sendername}
                           </Link>
            
         
            </span>
            <br />
            <span style={{fontFamily:"Gotham",fontSize:15}}>
            Company Name: 
            
            <Link 
                         target="_blank"
                         to={{ 
                          
                           pathname: `/viewgigprofile/${this.state.senderid}`, 
                            
                         }} style={{fontFamily:"GothamBold",fontSize:15,textDecoration:"underline",color:"black"}}>
                           {this.state.companyname}
                           </Link>
            
            </span>
            <br />
            <span style={{fontFamily:"Gotham",fontSize:15}}>
            Department: <span style={{fontFamily:"GothamBold",fontSize:15,textDecoration:"underline"}}>{this.state.departmentname}
            </span>
            </span>
            <br />
            <span style={{fontFamily:"Gotham",fontSize:15}}>
            Company Summary: 
            </span> <br/>
            <span style={{fontFamily:"GothamBold",fontSize:15}}>{this.state.companysummary}
           <Link target="_blank" to={{ 
                       
                       pathname: `/companypublishprofile/${this.state.senderid}`, 
                        
                     }}>….Click below to learn more about the Employer</Link> 
            </span>
    <div class="row">
              <div class="project mt-4" style={{fontSize:25,}}>Project Summary</div>
            </div>
            
    <div class="row mt-5" id="preview_contain">
              <div class="row">
               
                         
               
        
            <div class="row" style={{paddingLeft:"4%",paddingTop:"3%"}}>
              <div class="col-sm-3" style={{width:"21%",boxShadow:"0px 0px 10px 5px #888888"}}>
              <div
                  class="card"
                  style={{
                    backgroundColor: "white",
                    height: "100%",
                    border: "none",
                    borderRadius: 1,
                  }}
                 
                >
                  {this.state.cpylogo==""?<>
                  <img src={SummaryLogo} style={{width:"75%",margin:"14%"}}/>
                  </>:<>
                  <img src={this.state.cpylogo} style={{width:"90%",marginTop:"40%"}}/>
                  </>}
                
             
                

                   
                
                </div>






              </div>
              <div class="col-sm-9" style={{backgroundColor:"white",boxShadow:"0px 0px 10px 5px #888888",marginLeft:"4%"}}>
                <div
                  class="card"
                  style={{
                    backgroundColor: "white",
                    height: "100%",
                    border: "none",
                    borderRadius: 1,
                  }}
                  
                >
                  <div className="row">
                    
                    <div className="col-sm-5">
                      <h3 style={{color:"rgb(0,147,69)",fontFamily:"Gotham"
                    ,paddingLeft:"7%",fontSize:14
                    }}>{this.state.companyname}</h3>
                    
                    </div>
                    <div className="col-sm-1">

                    </div>
                    <div className="col-sm-6">
                      <h5 style= {{color:"rgb(0,147,69)",fontFamily:"Gotham",
                      paddingTop:"0%",textAlign:"right",marginRight:"20%"
                  
                    }}> <i class="fa fa-globe"></i> &nbsp; 
                    
                    <span style={{marginLeft:"-1%",fontSize:14}}> {this.state.cpywebsite==""?null:<>{this.state.cpywebsite}</>}</span></h5>
                    
                    </div>
                  </div>
                 
                  <div className="row">
                    <div className="col-sm-5">
                    <h5 style= {{fontFamily:"Gotham",
                      paddingTop:"5%",paddingLeft:"4%",
                      fontSize:14
                  
                    }}> <i class="fa fa-cog" style={{color:"rgb(0,147,69)"}}></i> &nbsp; {this.state.departmentname}</h5>
                    
                    </div>
                    <div className="col-sm-3">

                    </div>
                    <div className="col-sm-4">
                      <h5 style= {{fontFamily:"Gotham",
                      paddingTop:"5%", fontSize:14,
                      marginRight:"8%",textAlign:"left"
                  
                    }}> <i class="fa fa-map-marker" style={{color:"rgb(0,147,69)"}}></i> &nbsp; {this.state.locationname}</h5>
                    
                    </div>
                  </div>

                  <div className="row">
                  {this.state.cpyfoundedyear==null?<>
                  <div className="col-sm-5">
                    <h5 style= {{fontFamily:"Gotham",
                      paddingTop:"5%",paddingLeft:"4%", fontSize:14
                  
                    }}> <i class="fa fa-calendar" style={{color:"rgb(0,147,69)"}}></i> &nbsp; Founder Year Not Avaliable </h5>
                    
                    </div>
                  </>:<>

                  <div className="col-sm-5">
                    <h5 style= {{fontFamily:"Gotham",
                      paddingTop:"5%",paddingLeft:"4%", fontSize:14
                  
                    }}> <i class="fa fa-calendar" style={{color:"rgb(0,147,69)"}}></i> &nbsp; Founded {this.state.cpyfoundedyear}</h5>
                    
                    </div>
                  </>}
               
                    <div className="col-sm-3">

                    </div>
                    <div className="col-sm-4">
                      <h5 style= {{fontFamily:"Gotham",
                      paddingTop:"5%", fontSize:14,textAlign:"left",marginRight:"8%"
                  
                    }}> <i class="fa fa-user" style={{color:"rgb(0,147,69)"}}></i> &nbsp; {this.state.companysize}</h5>
                    
                    </div>
                  </div>

                  <div class="container-fluid" style={{marginTop:"1%"}}>
                <div class="row">
                {this.state.opportunitiestype==null?<>
                  <span style={{fontFamily:"GothamLight",fontWeight:"bold"}}>Opportunities Not Avaliable.</span>
                </>:<>
                
                {this.state.opportunitiestype.split(",")
  .map((item, i) =>  <div class="col-sm-3 divopptypepostmessage">  {item}  </div>)}
                </>}
                  
                
                  
                  
                </div>
</div>
               

             
            
              
                </div>               
</div>
</div>
             
                <div className="mt-5">
                  <div className="row" style={{paddingLeft:"2%"}}>
                   
                    <div class="col-sm-7">
                      <h4 style={{fontFamily:"Gotham",fontSize:16}}>
                        Name of the Project :{" "}
                        {this.state.projname==""?null:<>
                        <b style={{ fontFamily: "GothamLight" }}>
                          {this.state.projname}
                        </b>{" "}
                        </>}
                       
                      </h4>
                    </div>
                    
                    <div class="col-sm-2">
                      <b style={{ color: "rgb(0, 147, 69)", fontSize: 16 }}>
                       {this.state.price==""?null:<>$ {this.state.price}</>}
                      </b>
                    </div>
                  </div>
                </div>
                <div className="mt-3">
                  <div className="row" style={{paddingLeft:"2%"}}>
                   
                    <div class="col-sm-6">
                      <h4 style={{ fontSize: 16, fontWeight: "bold" }}>
                        Category :{" "}
                        {this.state.categoryname==""?null:<>
                        <b style={{ fontFamily: "GothamLight" }}>
                          {this.state.categoryname}
                        </b>{" "}
                        
                        </>}
                       
                      </h4>
                    </div>
                    <div class="col-sm-1"></div>
                    <div class="col-sm-5">
                      <h4 style={{ fontSize: 16, fontWeight: "bold" }}>
                        Sub Category :{" "}
                        {this.state.subcategoryname==""?null:<>
                        <b style={{ fontFamily: "GothamLight" }}>
                         {this.state.subcategoryname}
                        </b>{" "}
                        
                        </>}
                       
                      </h4>
                    </div>
                  </div>
                </div>{" "}
                <div className="mt-3">
                  <div className="row" style={{paddingLeft:"2%"}}>
                    
                    <div class="col-sm-6">
                      <h4 style={{ fontSize: 16, fontWeight: "bold" }}>
                        Department :{" "}
                        {this.state.departmentname==""?null:<>
                        
                        <b style={{ fontFamily: "GothamLight" }}>
                         {this.state.departmentname}
                        </b>{" "}
                        </>}
                       
                      </h4>
                    </div>
                    <div class="col-sm-1"></div>
                    <div class="col-sm-5">
                      <h4 style={{ fontSize: 16, fontWeight: "bold" }}>
                        Project Team Size :{" "}
                        {this.state.projteamsize==""?null:<>
                        <b style={{ fontFamily: "GothamLight" }}>{this.state.projteamsize}</b>{" "}
                        </>}
                       
                      </h4>
                    </div>
                  </div>
                  <div className="row mt-3" style={{paddingLeft:"2%"}}>
                   
                    <div class="col-sm-6">
                      <h4 style={{ fontSize: 16, fontWeight: "bold" }}>
                        Type of Project :{" "}
                        {this.state.projtype==""?null:<>
                        <b style={{ fontFamily: "GothamLight" }}>
                         {this.state.projtype}
                        </b>{" "}
                        </>}
                       
                      </h4>
                    </div>
                    <div class="col-sm-1"></div>
                    <div class="col-sm-5">
                      <h4 style={{ fontSize: 16, fontWeight: "bold" }}>
                        Size of the Project :{" "}
                        {this.state.projsize==""?null:<>
                        <b style={{ fontFamily: "GothamLight" }}>
                          {this.state.projsize}
                        </b>{" "}
                        </>}
                       
                      </h4>
                    </div>
                  </div>
                  <div className="row mt-3" style={{paddingLeft:"2%"}}>
                   
                    <div class="col-sm-6">
                      <h4 style={{ fontSize: 16, fontWeight: "bold" }}>
                        Duration of the Project :{" "}
                        <div className="mt-2">
                          <b style={{ fontFamily: "GothamLight" }}>
                            {this.state.estimatedProjecttolast==""?null:<>
                            {this.state.estimatedProjecttolast}
                            </>}
                          </b>{" "}
                        </div>
                      </h4>
                    </div>
                    <div class="col-sm-1"></div>
                    <div class="col-sm-5">
                      <h4 style={{ fontSize: 16, fontWeight: "bold" }}>
                        Type of Project Posted :{" "}
                        <div className="mt-2">
                          {this.state.projpostedtype==""?null:<>
                          <b style={{ fontFamily: "GothamLight" }}>
                            {this.state.projpostedtype}
                          </b>{" "}
                          </>}
                         
                        </div>
                      </h4>
                    </div>
                  </div>
                  <div className="row mt-3" style={{paddingLeft:"2%"}}>
                   
                    <div class="col-sm-6">
                      <h4 style={{ fontSize: 16,fontFamily:"Gotham" }}>
                        New idea/Concept:{" "}
                        <div className="mt-2">
                          {this.state.projidea==""?null:<>
                          <b style={{ fontFamily: "GothamLight" }}>
                            {this.state.projidea}
                          </b>{" "}
                          </>}
                          
                        </div>
                      </h4>
                    </div>
                    <div class="col-sm-1"></div>
                    <div class="col-sm-5">
                      <h4 style={{ fontSize: 16, fontWeight: "bold" }}>
                        Location of Assignment :
                        <div className="mt-2">
                          {this.state.locationname==""?null:
                          
                          <> <b style={{ fontFamily: "GothamLight" }}>{this.state.locationname}</b>{" "}</>
                          }
                         
                        </div>
                      </h4>
                    </div>
                  </div>
                  <div className="row mt-3" style={{paddingLeft:"2%"}}>
                   
                    <div class="col-sm-6">
                      <h4 style={{ fontSize: 16, fontWeight: "bold" }}>
                        Start date :{" "}
                        {this.state.startdate==""?null:<>
                        <b style={{ fontFamily: "GothamLight" }}>{this.state.startdate}</b>{" "}
                        </>}
                        
                      </h4>
                    </div>
                    <div class="col-sm-1"></div>
                    <div class="col-sm-5">
                      <h4 style={{ fontSize: 16, fontWeight: "bold" }}>
                        Optimal Frequency of Project :{" "}
                        {this.state.OptimalfrequencyProject==""?null:<>
                        <b style={{ fontFamily: "GothamLight" }}>
                          {this.state.OptimalfrequencyProject}
                        </b>{" "}
                        </>}
                       
                      </h4>
                    </div>
                  </div>
                  {this.state.studenttypename==null?null:<>
                    <div className="row mt-4" style={{paddingLeft:"2%"}}>
                   
                    <div class="col-sm-10">
                      <h4
                        style={{
                          fontSize: 18,
                          fontFamily:"Gotham"
                        }}
                      >
                        Preferred Student Type : 
                      </h4>
                      <div className="row">
                            {this.state.studenttypename.split(",")
                      .map((item, i) =>  <div class="col-sm-3" style={{paddingTop:"2%"}}>  
                     <i className="fa fa-circle" style={{color:"rgb(0,147,69)"}}>
                      <span style={{color:"black",fontFamily:"GothamLight",fontWeight:"bold"}}>&nbsp;&nbsp;{item}</span> 
                      </i>
                      </div>)}  

                      </div>
                    

                    
                    </div>
                  </div>
                  
                  </>}


                  {this.state.SubjectID==0?null:<>
                    <div className="row mt-4" style={{paddingLeft:"2%"}}>
                   
                    <div class="col-sm-10">
                      <h4
                        style={{
                          fontSize: 18,
                          fontFamily:"Gotham"
                        }}
                      >
                        Eligible Subject and Degrees :
                      </h4>
                      <div style={{paddingTop:"1%"}}>
                      {this.createElements()}
                    </div>
                </div>
                </div>
                  </>}
               


                  <div className="row mt-5" style={{paddingLeft:"2%"}}>
                    
                    <div class="col-sm-10">
                      <h4
                        style={{
                          fontSize: 16,
                          textDecoration: "underline",
                          fontWeight: "bold",
                        }}
                      >
                        Tell us More about the Project:
                      </h4>
                      {this.state.projdescription==""?null
                    :<>
                      <div
                        style={{
                          fontFamily: "GothamLight",
                          fontWeight: "bold",
                        }}
                      >
                       {this.state.projdescription}
                      </div>
                    
                    </>  
                    }
                    
                    </div>
                  </div>
                  <div className="row mt-3" style={{paddingLeft:"2%"}}>
                    
                    <div class="col-sm-10">
                      <h4
                        style={{
                          fontSize: 16,
                          textDecoration: "underline",
                          fontWeight: "bold",
                        }}
                      >
                        Skills Required:
                      </h4>
                      <div class="row mt-3">
                    
                             {this.state.skillname.split(",")
     .map((item, i) =>  <div class="col-sm-4">  
     <button class="sk_btn">{item}</button>
     </div>)}  

                
                      </div>
                    </div>
                  </div>
                  <div className="row mt-4" style={{paddingLeft:"2%"}}>
                    
                    <div class="col-sm-7">
                      <h4 style={{ fontSize: 16, fontWeight: "bold" }}>
                        Mastered Language :{" "}
                        <div className="row" style={{marginTop:"2%"}}>
                        {this.state.languagename.split(",")
                        .map((item, i) =>  <div class="col-sm-2">  
                        <button class="sk_btn">{item}</button>
                        </div>)} 

                        </div>
                         

                      </h4>
                    </div>
                    <div class="col-sm-1"></div>
                  </div>
                  <div className="row mt-4" style={{paddingLeft:"2%"}}>
                    
                    <div class="col-sm-7">
                      <h4 style={{ fontSize: 16, fontWeight: "bold" }}>
                        Open to all intrsted candidates :{" "}

                        <b style={{ fontFamily: "GothamLight" }}>
                          &nbsp;&nbsp; {this.state.opentoallintrested==""?null:<>{this.state.opentoallintrested}</>}
                        </b>{" "}
                      </h4>
                    </div>
                    <div class="col-sm-1"></div>
                  </div>
                  <div className="row mt-4" style={{paddingLeft:"2%"}}>
               
                    <div class="col-sm-7 mt-1">
                      <h4 style={{ fontSize: 16,fontFamily:"Gotham" }}>
                        Attachment of Additional Material Shown : &nbsp;&nbsp;{" "}
                        
                      </h4>
                    </div>
                    <div class="col-sm-3"><input
                          type="text"
                          disabled
                          className="form-control"
                          placeholder={this.state.addDocuments}
                          id="stepone"
                        /></div>
                  </div>
               
                  <div className="row mt-5"></div>
                </div>
                <div class="col-sm-1"></div>
              </div>
            </div>
            </div> 
        {/*Project Summary Ends */}
        <div className="mt-5">
          {this.state.reply==0?<>
            <span style={{fontFamily:"Gotham",fontSize:15}}>Interested? Click on the  link below to respond to the employer</span><br />
                    <button className="btn btn-success" style={{color:"white",width:"30%",height:50,fontFamily:"Gotham",backgroundColor:"rgb(0,147,69)",marginTop:"1%",marginBottom:"2%"}}
                    onClick={this.handleReply}
                    >Reply</button> 
                     <button className="btn btn-success" style={{color:"white",width:"30%",height:50,fontFamily:"Gotham",backgroundColor:"rgb(0,147,69)",marginTop:"1%",marginBottom:"2%",marginLeft:"5%"}}
                   
                    >Send Proposal</button>
          
          </>:<>
          <div className="row">
            <div className="col-sm-12">
            <input placeholder="To"
            value= {this.state.sendername}
            className="form-control" />
            </div>
           
           
          
          </div>
         
           
          <div className="row mt-3">
            <div className="col-sm-12">
          <textarea className="form-control" rows="4" placeholder="Type Here....."></textarea>

            </div>
            <button className="btn btn-success mt-3" style={{backgroundColor:"rgb(0,147,69)",color:"white",fontFamily:"Gotham",width:120,marginLeft:"2%"}}>Send</button>
          </div>
          <div className="row mt-5"></div>
          </>}
                   
                    </div>
    
    </>:<> <br/> <br/><br/><br/> <br/><br/><br/> <br/><br/>
 
{this.state.showEmail==2?<>
  <span style={{fontFamily:"Gotham",fontSize:25,marginLeft:"35%"}}>  Select an item to read</span>

</>:<></>}
    
    
    </>}


                    
              </div>
              </div>
            </div>
          
        </div>
      </main>
        
      </div>
    );
  }
}

export default CompanyOpportunity;
