import React, { useState } from "react";



import "./modal.css";
const style = {
  control: (base, state) => ({
    ...base,
    border: "1px solid gray",
    borderRadius:"1px",
   fontSize:15,
   
    // This line disable the blue border
    boxShadow: state.isFocused ? 0 : 0,
    "&:hover": {
      border: "1px solid gray",
      borderRadius:"1px",
     
    }
  }),
 
};

function close(){
  window.location.reload();
}
export default class LanguagesModal extends React.Component {
  handleClick = () => {
    window.location.reload();
  }
  constructor() {
    let comments = JSON.parse(sessionStorage.getItem("someData"));
    let Languagess = JSON.parse(sessionStorage.getItem("Language"));
    super();
    this.state = {
      isOpen: false,

      selectOptions:[],
      id: "",
      name: "",
      dropdownlist_input: "languages",
      addstudata: "stu_language",
      language_level:"",
      language:Languagess,
      userid: comments,
      success:"",
      display_language:[],
      showModal: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  openModal = () => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });
  
  componentDidMount() {
    this.getOptions();
  }
  async getOptions() {
    const data_one = { dropdownlist_input: this.state.dropdownlist_input };

    fetch("https://www.gigeconome.com:5200/api/getdropdownlist", {
      method: "POST",

      body: JSON.stringify(data_one), // data can be `string` or {object}!

      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())

      .catch((error) => console.error("Error:", error))

      .then((json) => {
        const data = json.result;
        console.log(data)
        
       
        this.setState({ selectOptions: data });
      
      });
      const data_two={userid:this.state.userid}
    fetch('https://www.gigeconome.com:5200/api/getlanguserinfo', { method: 'POST', 
  
    body: JSON.stringify(data_two), // data can be `string` or {object}!
  
    headers:{ 'Content-Type': 'application/json' } })
  
    .then(res => res.json())
  
    .catch(error => console.error('Error:', error))
  
    .then((json)=>{
     console.log(json.result)
     this.setState({display_language:json.result})
           
        })
  }

  handleOnchange=(e)=>{
    this.setState({
      [e.target.name]: e.target.value,
     
    });
  }

  openModal = () => {
    this.setState({ showModal: true });
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };
 handleSubmit = (event) => {
    event.preventDefault()
   
    const data = {

      userid: this.state.userid,
      addstudata: this.state.addstudata,
      language_level:this.state.language_level,
      language:this.state.language

    }
  
    let BaseUrl = JSON.parse(sessionStorage.getItem("baseurl"));

      fetch( "https://www.gigeconome.com:5200/api/addstu_profiledata", {
        method: "POST",

        body: JSON.stringify(data), // data can be `string` or {object}!

        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())

        .catch((error) => console.error("Error:", error))

        .then((response) => {
          console.log(response)
         
          this.setState({
            success:response.message
          })
          
        });
        const data_two = {

          userid: this.state.userid,
          
    
        }
      
    
    
          
    }
    
  
  render() {
    const{success}=this.state
  const {selectOptions}=this.state
  const {display_language}=this.state
    return (
      <>
        {this.state.showModal && (
        <div class="ft-modal" id="ft-language-modal">
          <div class="ft-modal-content">
            <div class="ft-modal-header">
              <div class="header">
                <h5
                  class="modal-title"
                  id="exampleModalLabel"
                  style={{
                    textAlign: "center",
                    fontSize: 25,
                    fontWeight: "bold",
                  }}
                >
                  Languages
                </h5>
                <hr />
              </div>
            </div>
            <div class="ft-modal-body">
              <div
                style={{
                  color: "rgb(0, 147, 69)",
                 
                  textAlign: "center",
                }}
              >
                Please Select the Following
              </div>
              <div class="row mt-4">
                <div class="col-sm-3"></div>
                <div class="col-sm-7">
                <form onSubmit = {this.handleSubmit}>
                {selectOptions.length == 0
                    ? "Loading languages..."
                    : 

                    <select class="form-select" name="language" onChange={this. handleOnchange}>
                      <option>Select</option>
                    {selectOptions.map(fbb =>
                    
                      <option key={fbb.key} value={fbb.id}>{fbb.name}</option>
                      
                    )}
                  </select>
                    }
                   <select
                    class="form-select mt-4"
                   name="language_level"
                   onChange={this. handleOnchange}
                  >
                    <option selected>Level</option>
                    <option >Limited Working Proficiency</option>
                    <option >Full Professional Proficiency</option>
                    <option >Native or Bilingual</option>
                  </select>
                  <div style={{textAlign:'center' }}>
                  <button
                    style={{ backgroundColor: "rgb(0, 147, 69)",marginTop:"10%" }}
                    className="btn btn-success"
                  >
                    Confirm
                  </button>
                  
                  </div><div style={{marginTop:"5%"}}>  <span style={{color:"rgb(0,147,69)",fontWeight:"bold",}}>
                    {success}
                  </span></div>
                  {display_language
                    ? <div>
                    {display_language.map(fbb =>
                    <>
                    
                    
                     <b style={{fontSize:15}}>{fbb.name}</b><br />
                     <a style={{fontSize:15,marginTop:"-2%"}}>{fbb.level}</a><br />
                    
                    
                    </>
              
                 
                 
               )};
                 </div>
                    :null 

                  
                      
                   
                
                  
                    }
              </form>
                </div>
               
               
              </div>{" "}
              <div class="row mt-2">
                <div class="col-sm-3"></div>
                <div class="col-sm-7">
                 
                </div>
                <div class="col-sm-1"></div>
              </div>
              <div class="row mt-4">
                <div class="col-sm-5"></div>
                <div class="col-sm-3 ">
                 
                  
                </div>
                <div class="col-sm-3"></div>
              </div>
           
              <div class="modal-footer mt-4">
                <div style={{ margin: "auto" }}>
                  <button onClick={this.handleClick}
                    style={{ backgroundColor: "rgb(0, 147, 69)", width: 90,marginLeft:"40%" }}
                    className="btn btn-success"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
            <div class="ft-modal-footer">
              <a class="ft-modal-close" data-dismiss="modal"
                     onClick={this.closeModal}>
                [&#10006;]{" "}
              </a>
            </div>
          </div>
        </div>
        )}
        <a
          
          className="btn-success btn-sm editbtnlanguage mt-3"
          href="#ft-language-modal"
          style={{
            margin:"auto",
            width: 100,
            backgroundColor: "rgb(0, 147, 69)",
            fontFamily: "GothamLight",
            textAlign: "center",
            display:"block"
          }}
          onClick={this.openModal}
        >
          Add
        </a>
      </>
    );
  }
}
