import React, { useState } from "react";
import Swal from 'sweetalert2';
import "./modal.css";
function close(){
    window.location.reload();
  }
export default class  EducationAddMore extends React.Component{
    constructor() {
        super();
        let comments = JSON.parse(sessionStorage.getItem("someData"));
       
       console.log(comments)
        this.state = {
          
        
         
         
            userid: comments,
            addstudata :"education",
           
            dropdownlist_input : "nationality",
           
            selectOptions : [],
            selectSchool:[],
            selectQualification:[],
            selectUniversity:[],
            country:"",
            qualification:"",
            degree:"",
            unversity:"",
            yrofpassingstart:"",
            yrofpassingend:"",
            geomessage:"",
            selectCourse:[],
            edutodate:"",
            showModal: false,
    
      
      }
      this.handleSubmit = this.handleSubmit.bind(this);
    }
    
    handleSubmit = (event) => {
      event.preventDefault()
     

      if(this.state.edutodate==null){
        const data = {
  
          userid: this.state.userid,
          addstudata: this.state.addstudata,
          country:this.state.country,
          qualification:this.state.qualification,
          degree:this.state.degree,
          unversity:this.state.unversity,
          yrofpassingstart:this.state.yrofpassingstart,
          yrofpassingend:this.state.yrofpassingend,
          edutodate:"No"
       
  
      }
    
 fetch( "https://www.gigeconome.com:5200/api/addstu_profiledata", {
        method: "POST",

        body: JSON.stringify(data), // data can be `string` or {object}!

        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())

        .catch((error) => console.error("Error:", error))

        .then((response) => {
            this.setState({geomessage:response.message})
            //window.location.reload();
         
          
        })
        
      
      }
    else{
      const data = {
  
        userid: this.state.userid,
        addstudata: this.state.addstudata,
        country:this.state.country,
        qualification:this.state.qualification,
        degree:this.state.degree,
        unversity:this.state.unversity,
        yrofpassingstart:this.state.yrofpassingstart,
        yrofpassingend:this.state.yrofpassingend,
        edutodate:this.state.edutodate
     

    }
  
    let BaseUrl = JSON.parse(sessionStorage.getItem("baseurl"));

      fetch( "https://www.gigeconome.com:5200/api/addstu_profiledata", {
        method: "POST",

        body: JSON.stringify(data), // data can be `string` or {object}!

        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())

        .catch((error) => console.error("Error:", error))

        .then((response) => {
            this.setState({geomessage:response.message})
            window.location.reload();
         
          
        });

       
    
    }
    }
   
    openModal = () => {
      this.setState({ showModal: true });
    };
  
    closeModal = () => {
      this.setState({ showModal: false });
    };
  componentDidMount(){
    this.getOptions()
}
async getOptions(){
  const data_one={dropdownlist_input:this.state.dropdownlist_input,
  
  }

  let BaseUrl = JSON.parse(sessionStorage.getItem('baseurl'));

  fetch( 'https://www.gigeconome.com:5200/api/getdropdownlist', { method: 'POST', 

   body: JSON.stringify(data_one), // data can be `string` or {object}!

   headers:{ 'Content-Type': 'application/json' } })

   .then(res => res.json())

   .catch(error => console.error('Error:', error))

   .then((json)=>{
    const data = json.result
    
    this.setState({selectOptions: data})
   })
   const data_two={dropdownlist_input:"country"
  
   }
 
   //let BaseUrl = JSON.parse(sessionStorage.getItem('baseurl'));
 
   fetch( 'https://www.gigeconome.com:5200/api/getdropdownlist', { method: 'POST', 
 
    body: JSON.stringify(data_two), // data can be `string` or {object}!
 
    headers:{ 'Content-Type': 'application/json' } })
 
    .then(res => res.json())
 
    .catch(error => console.error('Error:', error))
 
    .then((json)=>{
     const data = json.result
     
     this.setState({selectSchool: data})
    })

  


    const qual_data={dropdownlist_input:"qualification"
  
}

//let BaseUrl = JSON.parse(sessionStorage.getItem('baseurl'));

fetch( 'https://www.gigeconome.com:5200/api/getdropdownlist', { method: 'POST', 

 body: JSON.stringify(qual_data), // data can be `string` or {object}!

 headers:{ 'Content-Type': 'application/json' } })

 .then(res => res.json())

 .catch(error => console.error('Error:', error))

 .then((json)=>{
  const data = json.result
  
  this.setState({selectQualification: data})
 })



 const course_data={dropdownlist_input:"coursenew"
  
}

//let BaseUrl = JSON.parse(sessionStorage.getItem('baseurl'));

fetch( 'https://www.gigeconome.com:5200/api/getdropdownlist', { method: 'POST', 

 body: JSON.stringify(course_data), // data can be `string` or {object}!

 headers:{ 'Content-Type': 'application/json' } })

 .then(res => res.json())

 .catch(error => console.error('Error:', error))

 .then((json)=>{
  const data = json.result
  
  this.setState({selectCourse: data})
 })
 const university_data={dropdownlist_input:"university"
  
}

//let BaseUrl = JSON.parse(sessionStorage.getItem('baseurl'));

fetch( 'https://www.gigeconome.com:5200/api/getdropdownlist', { method: 'POST', 

 body: JSON.stringify(university_data), // data can be `string` or {object}!

 headers:{ 'Content-Type': 'application/json' } })

 .then(res => res.json())

 .catch(error => console.error('Error:', error))

 .then((json)=>{
  const data = json.result
  
  this.setState({selectUniversity: data})
 })
  }
    handleChange = (e) => {
      this.setState({
        [e.target.name]: e.target.value,
       
      });
      
    };
render(){
  const{geomessage}=this.state
  const{selectOptions}=this.state
  const{selectSchool}=this.state
  const {selectQualification}=this.state;
  const {selectUniversity}=this.state;
  const {selectCourse}=this.state;
  return (
    <>
     {this.state.showModal && (
      <div class="ft-modal" id="ft-educationaddmore-modal">
        <div class="ft-modal-content" >
          <div class="ft-modal-header">
            <div class="header">
              <h5
                class="modal-title"
                id="exampleModalLabel"
                style={{
                  textAlign: "center",
                  fontSize: 25,
                  fontWeight: "bold",
                }}
              >
              Add Education
              </h5>
              <hr />
            </div>
          </div>
          <div class="ft-modal-body">


          <form onSubmit={this.handleSubmit} style={{fontSize:17}}>
          <div class="row">
                      <div class="col-sm-1"></div>
                      <div class="col-sm-5">
                       <span  style={{fontFamily:"GothamLight",
                       fontWeight:"bold",display:"flex"}}>Country</span> 
                        <div class="mt-1">

                        {selectSchool.length == 0
                    ? "Loading Country Name..."
                    : 

                    <select class="form-select"   name ="country" 
                    onChange = {this.handleChange}>
                      <option >Select</option>
                    {selectSchool.map(fbb =>
                    
                      <option key={fbb.key} value={fbb.id}>{fbb.name}</option>
                      
                    )};
                  </select>
                    } 
                        </div>
                        
                      </div>
                      <div class="col-sm-5">
                       <label style={{display:"flex"}}>Qualification</label> 

                        <div class="mt-1">{selectQualification.length == 0
                    ? "Loading Qualification Name..."
                    : 

                    <select class="form-select"   name ="qualification" 
                    onChange = {this.handleChange}>
                      <option>Select</option>
                    {selectQualification.map(fbb =>
                    
                      <option key={fbb.key} value={fbb.id}>{fbb.name}</option>
                      
                    )};
                  </select>
                    } </div>
                        
                      </div>
                      <div class="col-sm-1"></div>
                    </div>
                    <div class="row" style={{marginTop:"2%"}}>
                      <div class="col-sm-1"></div>
                      <div class="col-sm-10">
                      <label style={{display:"flex"}}>Degree</label>
                      <div class="mt-1">{selectCourse.length == 0
                    ? "Loading Qualification Name..."
                    : 

                    <select class="form-select"   name ="degree" 
                    onChange = {this.handleChange}>
                      <option>Select</option>
                    {selectCourse.map(fbb =>
                    
                      <option key={fbb.key} value={fbb.id}>{fbb.subcategoryname}</option>
                      
                    )};
                  </select>
                    } </div>
                      </div>

                      <div class="col-sm-1"></div>
                    </div>
                    <div class="row" style={{marginTop:"2%"}}>
                      <div class="col-sm-1"></div>
                      <div class="col-sm-10">
                      <label style={{display:"flex"}}>University/College</label>
                        <div class="mt-2">
                        <div class="form-group">
                  
                        {selectUniversity.length == 0
                    ? "Loading University Name..."
                    : 

                    <select class="form-select"   name ="unversity" 
                    onChange = {this.handleChange}>
                      <option>Select</option>
                    {selectUniversity.map(fbb =>
                    
                      <option key={fbb.key} value={fbb.id}>{fbb.name}</option>
                      
                    )};
                  </select>
                    }
                  </div>
                        </div>
                      </div>
                      <div class="col-sm-1"></div>
                    </div>
                    <div class="row" style={{marginTop:"2%"}}>
                      <div class="col-sm-1"></div>
                      <div class="col-sm-3">
                        <span style={{fontFamily:"GothamLight",fontWeight:"bold",display:"flex"}}>Start</span>
                        <div>
                        <input type="text" class="form-control"
                      
                      placeholder="Select Year"
                      onFocus={(e) =>
                        (e.currentTarget.type = "month")
                      }
                      onBlur={(e) =>
                        (e.currentTarget.type = "text")
                      }
                      name ="yrofpassingstart" 
                    onChange = {this.handleChange}
                      />
                  
                        </div>
                      </div>
                      <div class="col-sm-3">
                      <label style={{display:"flex"}}> End</label>
                        <div class="mt-1">
                      <input type="text" class="form-control"
                       
                      placeholder="Select Year"
                      onFocus={(e) =>
                        (e.currentTarget.type = "month")
                      }
                      onBlur={(e) =>
                        (e.currentTarget.type = "text")
                      }
                      name ="yrofpassingend" 
                      onChange = {this.handleChange}
                     
                      />
                        </div>
                      </div>
                      <div class="col-sm-5"></div>
                    </div>
                    <div class="row" style={{marginTop:"2%"}}>
                      <div class="col-sm-1"></div>
                      <div class="col-sm-3">
                        <input
                          type="checkbox"
                          name ="edutodate" 
                          onChange = {this.handleChange}
                          value="Yes"
                        />
                         <label > &nbsp;To Date</label>
                      </div>
                      <div class="col-sm-3"></div>
                      <div class="col-sm-5"></div>
                    </div>
                    <div class="row">
                      <div class="col-sm-8"></div>
                      <div class="col-sm-2">
                        <button id="education_btn" 
                        onClick={()=>close()}
                        className="btn-secondary">
                          Cancel
                        </button>
                      </div>
                      <div class="col-sm-1">
                        <button
                          className="btn-success"
                          style={{ backgroundColor: "rgb(0, 147, 69)" }}
                          id="education_btn"
                          type="submit"
                        >
                          Save
                        </button>
                      </div>
                      <div class="col-sm-1"></div>
                      
                    </div><span 
                    style={{color:"rgb(0,147,69)",display:"flex",fontSize:15,marginTop:"5%",fontWeight:"bold"}}>{geomessage}</span>
                    <div class="ft-modal-footer">
            <a class="ft-modal-close" data-dismiss="modal"
                     onClick={this.closeModal}>
              [&#10006;]{" "}
            </a>
          </div>    </form>
        </div>
        </div>
      </div>
     )}
      
      <a
          id="add_btntwo"
          className="btn-success btn-sm"
          href="#ft-educationaddmore-modal"
          style={{
           
            width: 100,
            backgroundColor: "rgb(0, 147, 69)",
            fontFamily: "GothamLight",
            textAlign: "center",
          }}
          onClick={this.openModal}
        >
          Add More
        </a>
    </>
  );
}
}
